import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { SesionService } from '../sesion/sesion.service';

@Injectable({
  providedIn: 'root',
})
export class AuditoriaGuard implements CanActivate {
  constructor(private sesionService: SesionService, private router: Router) {}

  canActivate(): boolean {
    if (
      this.sesionService.isGoogleUserSignedIn() &&
      this.sesionService.getPermisos() &&
      this.sesionService.getPermisos().ga === 1
    ) {
      return true;
    } else {
      this.router.navigate(['/']);
      return false;
    }
  }
}

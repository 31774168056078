<ngx-spinner></ngx-spinner>

<div class="container espacio">
  <div class="d-flex justify-content-between mb-3">
    <h1>Gestión de Acciones</h1>
  </div>

  <div class="card">
    <p-table id="tabla-acciones" #dt1 [value]="acciones" dataKey="id" [showCurrentPageReport]="true" styleClass="p-datatable-responsive-demo" [rows]="rows" [totalRecords]="accionesTotales" [columns]="cols" [lazy]="true" (onLazyLoad)="nextPage($event, dt1)"
                [paginator]="true" [globalFilterFields]="cols" sortField="id" [sortOrder]="1" currentPageReportTemplate="Página {{paginaActual}} de {{paginasTotales}}" [rowsPerPageOptions]="accionesPorPagina">
                <ng-template pTemplate="caption" class="caption">
                  <button class="btn boton-excel" (click)="exportarDatos()"><i class="fa fa-file-excel-o" aria-hidden="true"></i> EXPORTAR</button>
                </ng-template>
                <ng-template pTemplate="header">
                  <tr>
                    <th scope="{{cols[0].field}}" pSortableColumn="{{cols[0].field}}" style="width: 10% !important;">
                      <div class="p-d-flex p-jc-between p-ai-center">
                          {{cols[0].header}}
                          <p-sortIcon ariaLabel="Activar para ordenar" ariaLabelDesc="Activar para ordenar en orden descendiente" ariaLabelAsc="Activar para ordenar en orden ascendiente" field="{{cols[0].field}}"></p-sortIcon>
                      </div>
                  </th>
                  <th scope="{{cols[1].field}}" pSortableColumn="{{cols[1].field}}">
                      <div class="p-d-flex p-jc-between p-ai-center">
                          {{cols[1].header}}
                          <p-sortIcon ariaLabel="Activar para ordenar" ariaLabelDesc="Activar para ordenar en orden descendiente" ariaLabelAsc="Activar para ordenar en orden ascendiente" field="{{cols[1].field}}"></p-sortIcon>
                      </div>
                  </th>
                  <th scope="{{cols[2].field}}" style="width: 30% !important;">
                      <div class="p-d-flex p-jc-between p-ai-center">
                          {{cols[2].header}}
                      </div>
                  </th>
                  <th scope="{{cols[3].field}}" style="width: 10% !important;">
                      <div class="p-d-flex p-jc-between p-ai-center" pTooltip="{{cols[3].toolTip}}" tooltipPosition="top">
                          {{cols[3].header}}
                      </div>
                  </th>
                  <th scope="{{cols[4].field}}" style="width: 10% !important;">
                      <div class="p-d-flex p-jc-between p-ai-center" pTooltip="{{cols[4].toolTip}}" tooltipPosition="top">
                          {{cols[4].header}}
                      </div>
                  </th>
                  <th scope="{{cols[5].field}}" style="width: 10% !important;">
                      <div class="p-d-flex p-jc-between p-ai-center" pTooltip="{{cols[5].toolTip}}" tooltipPosition="top">
                          {{cols[5].header}}
                      </div>
                  </th>
                  <th scope="{{cols[6].field}}" style="width: 10% !important;">
                      <div class="p-d-flex p-jc-between p-ai-center" pTooltip="{{cols[6].toolTip}}" tooltipPosition="top">
                          {{cols[6].header}}
                      </div>
                  </th>
                  <th scope="{{cols[7].field}}" style="width: 10% !important;">
                      <div class="p-d-flex p-jc-between p-ai-center" pTooltip="{{cols[7].toolTip}}" tooltipPosition="top">
                          {{cols[7].header}}
                      </div>
                  </th>
                  <th scope="{{cols[8].field}}" style="width: 10% !important;">
                      <div class="p-d-flex p-jc-between p-ai-center" pTooltip="{{cols[8].toolTip}}" tooltipPosition="top">
                          {{cols[8].header}}
                      </div>
                  </th>
                  <th scope="{{cols[9].field}}" style="width: 10% !important;">
                    <div class="p-d-flex p-jc-between p-ai-center" pTooltip="{{cols[9].toolTip}}" tooltipPosition="top">
                        {{cols[9].header}}
                    </div>
                </th>
                <th scope="{{cols[10].field}}" style="width: 10% !important;">
                  <div class="p-d-flex p-jc-between p-ai-center" pTooltip="{{cols[10].toolTip}}" tooltipPosition="top">
                      {{cols[10].header}}
                  </div>
              </th>
                  </tr>
              </ng-template>
      <ng-template pTemplate="body" let-accion let-i="rowIndex">
        <tr>
          <td><span class="p-column-title">ID</span>{{accion.id}} </td>
          <td><span class="p-column-title">ACCIÓN</span> {{accion.nombre}}</td>
          <td><span class="p-column-title">DESCRIPCIÓN</span> {{accion.descripcion}}</td>
          <td class="simbolos"><span class="p-column-title">ADMINISTRADOR</span>
            <i class="simbolos" [ngClass]="checkRol(accion.roles, 'ADMINISTRADOR') ? 'fa fa-check' : 'fa fa-times'" aria-hidden="true"></i>
          </td>
          <td class="simbolos"><span class="p-column-title">ADMINISTRADOR LOCAL</span>
            <i class="simbolos" [ngClass]="checkRol(accion.roles, 'ADMINISTRADOR LOCAL') ? 'fa fa-check' : 'fa fa-times'"
              aria-hidden="true"></i>
          </td>
          <td class="simbolos"><span class="p-column-title">AUTORIZADOR</span>
            <i class="simbolos" [ngClass]="checkRol(accion.roles, 'AUTORIZADOR') ? 'fa fa-check' : 'fa fa-times'"
              aria-hidden="true"></i>
          </td>
          <td class="simbolos"><span class="p-column-title">INFORMADOR</span>
            <i class="simbolos" [ngClass]="checkRol(accion.roles, 'INFORMADOR') ? 'fa fa-check' : 'fa fa-times'"
              aria-hidden="true"></i>
          </td>
          <td class="simbolos"><span class="p-column-title">SOLICITANTE</span>
            <i class="simbolos" [ngClass]="checkRol(accion.roles, 'SOLICITANTE') ? 'fa fa-check' : 'fa fa-times'"
              aria-hidden="true"></i>
          </td>
          <td class="simbolos"><span class="p-column-title">EDITOR</span>
            <i class="simbolos" [ngClass]="checkRol(accion.roles, 'EDITOR') ? 'fa fa-check' : 'fa fa-times'"
              aria-hidden="true"></i>
          </td>
          <td class="simbolos"><span class="p-column-title">AUDITOR</span>
            <i class="simbolos" [ngClass]="checkRol(accion.roles, 'AUDITOR') ? 'fa fa-check' : 'fa fa-times'"
              aria-hidden="true"></i>
          </td>
          <td class="simbolos"><span class="p-column-title">LETRADO EXTERNO</span>
            <i class="simbolos" [ngClass]="checkRol(accion.roles, 'LETRADOEXTERNO') ? 'fa fa-check' : 'fa fa-times'"
              aria-hidden="true"></i>
          </td>
        </tr>
        <hr class="item-list-separator">
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="7">No hay resultados</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="paginatorleft">
      </ng-template>
      <ng-template pTemplate="paginatorright">
        <p class="pageFooterLeft">Resultados totales: {{accionesTotales}}</p>
      </ng-template>
    </p-table>
  </div>
</div>

<ngx-spinner></ngx-spinner>

<div class="container espacio">
    <div class="d-flex justify-content-between mb-3">
        <h1>Gestión de mis peticiones</h1>
    </div>

    <div class="card">

        <div class="borde">
            <!-- Filtro de la tabla peticiones actuaciones -->
            <app-filtro-peticion (filtrosPeticion)="recibirFiltros($event)" (loading)="finishLoading($event)"></app-filtro-peticion>
            <!-- Tabla de peticiones actuaciones -->
            <app-tabla-peticiones [datosFiltro]="datosFiltro" [isLoading]="isLoading"></app-tabla-peticiones>
        </div>
    </div>
</div>
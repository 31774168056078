import {
  Component,
  EventEmitter,
  Injector,
  OnInit,
  Output,
} from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { NgxSpinnerService } from 'ngx-spinner';
import { forkJoin } from 'rxjs';
import { Actividades } from 'src/app/modelos/actividades';
import { Ambito } from 'src/app/modelos/ambito';
import { Puesto } from 'src/app/modelos/puesto';
import { Rol } from 'src/app/modelos/rol';
import { UsuarioFiltros } from 'src/app/modelos/usuario-filtros';
import { ActividadesService } from 'src/app/servicios/actividades/actividades.service';
import { PuestosService } from 'src/app/servicios/puestos/puestos.service';
import { RolesService } from 'src/app/servicios/rol/roles.service';
import { TerritoriosService } from 'src/app/servicios/territorios/territorios.service';
import { Empresa } from 'src/app/modelos/empresa';
import { EmpresasService } from 'src/app/servicios/empresas/empresas.service';
import { Zona } from 'src/app/modelos/zona';
import { Region } from 'src/app/modelos/region';
import { UsuariosService } from '../../../servicios/usuarios/usuarios.service';
import { LineasVenta } from 'src/app/modelos/lineas-venta';
import { LineasVentaService } from 'src/app/servicios/lineas-venta/lineas-venta.service';
import { TipoSolicitante } from 'src/app/modelos/tipo-solicitante';
import { TipoSolicitanteService } from 'src/app/servicios/tipo-solicitante/tipo-solicitante.service';

@Component({
  selector: 'app-filtro-usuario',
  templateUrl: './filtro-usuario.component.html',
  styleUrls: ['./filtro-usuario.component.scss'],
})
export class FiltroUsuarioComponent implements OnInit {
  private tipoSolicitanteService!: TipoSolicitanteService;
  private rolesService!: RolesService;
  private territoriosService!: TerritoriosService;
  private puestosService!: PuestosService;
  private actividadesService!: ActividadesService;
  private empresasService!: EmpresasService;
  private spinner!: NgxSpinnerService;
  private usuarioService!: UsuariosService;
  private lineasVentaService!: LineasVentaService;
  public listaSolicitantes: TipoSolicitante[] = [];
  public listaRoles: Rol[] = [];
  public listaZonas: Zona[] = [];
  public listaRegiones: Region[] = [];
  public listaAmbitos: Ambito[] = [];
  public listaProvincias: Ambito[] = [];
  public listaPuestos: Puesto[] = [];
  public listaActividades: Actividades[] = [];
  public listaEmpresas: Empresa[] = [];
  public listaEmpresasFiltradas: Empresa[] = [];
  public listaLineasVenta: LineasVenta[] = [];

  public cargasCombos: any[] = [];
  public formulario!: FormGroup;
  public disabledAmbitos = true;
  public disabledProvincias = true;
  public disabledEsFlexiplan = true;

  public visible = true;
  public selectable = true;
  public removable = true;
  public addOnBlur = true;
  public esEmpresaFlexiplan = false;
  public readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  public listaApellidos: any[] = [];
  public listaDNIs: any[] = [];
  public listaEmails: any[] = [];
  public listaNombres: any[] = [];

  public estadoActivo = true;
  public esDniPrimeraBusqueda = true;
  public esApePrimeraBusqueda = true;
  public esEmaPrimeraBusqueda = true;
  public esNamePrimeraBusqueda = true;

  public avanzado = false;
  public textoBoton = 'Más filtros';

  //private codigoFlexiplan = 87;

  public datosFiltro!: UsuarioFiltros;

  // Para enviar datos fuera, sólo se hará cuando lancemos un evento. Así que debe haber un disparador
  @Output() desdeFiltroUsuario = new EventEmitter();

  constructor(private fb: FormBuilder, private injector: Injector) {
    this.loadInitialProperties();
  }

  ngOnInit(): void {
    this.initForm();
    this.initServices();
  }

  loadInitialProperties(): void {
    this.tipoSolicitanteService = this.injector.get<TipoSolicitanteService>(TipoSolicitanteService);
    this.rolesService = this.injector.get<RolesService>(RolesService);
    this.territoriosService =
      this.injector.get<TerritoriosService>(TerritoriosService);
    this.puestosService = this.injector.get<PuestosService>(PuestosService);
    this.actividadesService =
      this.injector.get<ActividadesService>(ActividadesService);
    this.empresasService = this.injector.get<EmpresasService>(EmpresasService);
    this.spinner = this.injector.get<NgxSpinnerService>(NgxSpinnerService);
    this.usuarioService = this.injector.get<UsuariosService>(UsuariosService);
    this.lineasVentaService =
      this.injector.get<LineasVentaService>(LineasVentaService);

    this.datosFiltro = this.usuarioService.getDatosFiltros();
  }

  initForm(): void {
    if (this.datosFiltro) {
      this.formulario = this.initFormWithFilters();
    } else {
      this.formulario = this.initEmptyForm();
    }

    this.formulario.controls.flexiplanActivo.valueChanges.subscribe(
      (newValue) => {
        if (newValue === true) {
          const empresaFlexiPlan = this.filterEmpresas(true);
          this.formulario.controls.empresas.setValue(empresaFlexiPlan);
          this.formulario.controls.tiposSolicitantes.setValue([]);
          this.formulario.controls.zonas.setValue([]);
          this.formulario.controls.ambitos.setValue([]);
          this.formulario.controls.provincias.setValue([]);
          this.listaEmpresasFiltradas = this.filterEmpresas(true);
        }
      }
    );
  }

  initEmptyForm(): FormGroup {
    return this.fb.group({
      email: [[]],
      variosEmails: [[]],
      empresas: [[]],
      tiposSolicitantes: [[]],
      lineasVenta: [[]],
      dni: [[]],
      variosDNIs: [[]],
      apellido: [[]],
      variosApellidos: [[]],
      roles: [[]],
      puestos: [[]],
      actividades: [[]],
      zonas: [[]],
      regiones: [[]],
      ambitos: [[]],
      provincias: [[]],
      estado: ['activos', []],
      fechaAltaInicio: [''],
      fechaAltaFin: [''],
      fechaBajaInicio: [''],
      fechaBajaFin: [''],
      nombre: [[]],
      variosNombres: [[]],
      flexiplanActivo: [false],
    });
  }

  initFormWithFilters(): FormGroup {
    this.desdeFiltroUsuario.emit(this.datosFiltro);
    return this.fb.group({
      email: [[]],
      variosEmails: [this.datosFiltro.emails ? this.datosFiltro.emails : []],
      empresas: [this.datosFiltro.empresas],
      tiposSolicitantes: [this.datosFiltro.tiposSolicitantes],
      lineasVenta: [this.datosFiltro.lineasVenta],
      dni: [[]],
      variosDNIs: [this.datosFiltro.dnis ? this.datosFiltro.dnis : []],
      apellido: [[]],
      variosApellidos: [this.datosFiltro.apellidos],
      roles: [this.datosFiltro.roles],
      puestos: [this.datosFiltro.puestos],
      actividades: [this.datosFiltro.actividades],
      zonas: [this.datosFiltro.zonas],
      ambitos: [this.datosFiltro.ambitos],
      provincias: [this.datosFiltro.provincias],
      estado: [this.datosFiltro.estado, []],
      fechaAltaInicio: [this.datosFiltro.fechaAltaInicio],
      fechaAltaFin: [this.datosFiltro.fechaAltaFin],
      fechaBajaInicio: [this.datosFiltro.fechaBajaInicio],
      fechaBajaFin: [this.datosFiltro.fechaBajaFin],
      nombre: [[]],
      variosNombres: [this.datosFiltro.nombres ? this.datosFiltro.nombres : []],
      flexiplanActivo: [this.datosFiltro.flexiplanActivo],
    });
  }

  private filterEmpresas(filterActive: boolean): Empresa[] {
    const listaNoFlexiplan = [] as Empresa[];
    this.listaEmpresas.forEach((empresa: Empresa) => {
      listaNoFlexiplan.push(empresa);
    });
    return listaNoFlexiplan;
  }

  initServices(): void {
    this.spinner.show();
    this.cargasCombos.push(this.empresasService.getEmpresas());
    this.cargasCombos.push(this.rolesService.getRoles());
    this.cargasCombos.push(this.puestosService.getPuestos());
    this.cargasCombos.push(this.territoriosService.getZonas());
    this.cargasCombos.push(this.actividadesService.getActividades());
    this.cargasCombos.push(this.territoriosService.getRegiones());
    this.cargasCombos.push(this.lineasVentaService.getLineasVenta());
    this.cargasCombos.push(this.territoriosService.getAmbitos());
    this.cargasCombos.push(this.territoriosService.getProvincias());
    this.cargasCombos.push(this.tipoSolicitanteService.getTipoSolicitantes());

    const modoFlexiplan =
      this.datosFiltro && this.datosFiltro.flexiplanActivo === true;
    forkJoin(this.cargasCombos).subscribe(
      (resp: any) => {
        this.listaEmpresas = resp[0] as Empresa[];
        this.listaRoles = resp[1];
        this.listaPuestos = resp[2];
        this.listaZonas = resp[3];
        this.listaActividades = resp[4];
        this.listaRegiones = resp[5];
        this.listaLineasVenta = resp[6];
        this.listaAmbitos = resp[7];
        this.listaProvincias = resp[8];
        this.listaSolicitantes = resp[9];
        this.listaEmpresasFiltradas = this.filterEmpresas(
          modoFlexiplan ? modoFlexiplan : false
        );
        this.spinner.hide();
      },
      (error) => {
        console.error(error);
        this.spinner.hide();
      }
    );
  }

  /*cambioEmpresa(): void {
    const empresa = this.formulario.get('empresas')?.value as Empresa;
    this.spinner.show();
    this.formulario.clearValidators();
    this.disabledEsFlexiplan = false;

    // En BBDD flexiplan ett tiene el id 87
    if (empresa.id === 87) {
      this.formulario.controls.zonas.setValue([]);
      this.formulario.controls.ambitos.setValue([]);
      this.formulario.controls.provincias.setValue([]);
    } else {
      this.formulario.controls.regiones.setValue([]);
      this.formulario.controls.provincias.setValue([]);
    }
  }*/

  cambioZona(): void {
    const zonas = this.formulario.get('zonas')?.value as Zona[];
    const idsZonas: number[] = [];

    for (const zona of zonas) {
      idsZonas.push(zona.id);
    }
    if (idsZonas.length === 0) {
      this.listaAmbitos = [];
      this.disabledAmbitos = true;
      this.formulario.controls.ambitos.setValue([]);
      this.formulario.controls.provincias.setValue([]);
    } else {
      this.spinner.show();
      this.territoriosService.getAmbitosZona(idsZonas).subscribe(
        (resp) => {
          this.listaAmbitos = resp;
          this.disabledAmbitos = false;
          this.spinner.hide();
        },
        (error) => {
          console.error(error);
          this.spinner.hide();
        }
      );
    }
  }

  cambioAmbito(): void {
    const ambitos = this.formulario.get('ambitos')?.value as Ambito[];
    const idAmbitos: number[] = [];

    for (const ambito of ambitos) {
      idAmbitos.push(ambito.id);
    }
    if (idAmbitos.length === 0) {
      this.listaProvincias = [];
      this.disabledProvincias = true;
      this.formulario.controls.provincias.setValue([]);
    } else {
      this.spinner.show();
      this.territoriosService.getProvinciasAmbito(idAmbitos).subscribe(
        (provincias) => {
          this.listaProvincias = provincias;
          this.disabledProvincias = false;
          this.spinner.hide();
        },
        (error) => {
          console.error(error);
          this.spinner.hide();
        }
      );
    }
  }

  cambioRegion(): void {
    const regiones = this.formulario.get('regiones')?.value as Region[];
    const idRegiones: number[] = [];

    for (const region of regiones) {
      idRegiones.push(region.id);
    }
    if (idRegiones.length === 0) {
      this.listaProvincias = [];
      this.disabledProvincias = true;
      this.formulario.controls.provincias.setValue([]);
    } else {
      this.spinner.show();
      this.territoriosService.getProvinciasRegiones(idRegiones).subscribe(
        (resp) => {
          this.listaProvincias = resp;
          this.disabledProvincias = false;
          this.spinner.hide();
        },
        (error) => {
          console.error(error);
          this.spinner.hide();
        }
      );
    }
  }

  /**
   * Aplicar filtros de búsqueda
   * @returns the usuarios
   */
  buscarUsuarios(): UsuarioFiltros {
    return {
      emails: this.formulario.get('variosEmails')?.value,
      nombres: this.formulario.get('variosNombres')?.value,
      estado: this.formulario.get('estado')?.value,
      empresas: this.formulario.get('empresas')?.value,
      tiposSolicitantes: this.formulario.get('tiposSolicitantes')?.value,
      dnis: this.formulario.get('variosDNIs')?.value,
      apellidos: this.formulario.get('variosApellidos')?.value,
      roles: this.formulario.get('roles')?.value,
      puestos: this.formulario.get('puestos')?.value,
      zonas: this.formulario.get('zonas')?.value,
      provincias: this.formulario.get('provincias')?.value,
      ambitos: this.formulario.get('ambitos')?.value,
      actividades: this.formulario.get('actividades')?.value,
      lineasVenta: this.formulario.get('lineasVenta')?.value,
      fechaAltaInicio: this.formulario.get('fechaAltaInicio')?.value,
      fechaAltaFin: this.formulario.get('fechaAltaFin')?.value,
      fechaBajaInicio: this.transformDateString(
        this.formulario.get('fechaBajaInicio')?.value
      ),
      fechaBajaFin: this.transformDateString(
        this.formulario.get('fechaBajaFin')?.value
      ),
      flexiplanActivo: this.formulario.get('flexiplanActivo')?.value,
    };
  }

  transformDateString(date: string): string {
    if (date) {
      const arrDate = date.split('-');
      return arrDate[2] + '-' + arrDate[1] + '-' + arrDate[0];
    } else {
      return '';
    }
  }

  /**
   * Buscar usuarios por valores del filtro
   */
  buscarFiltro(): any {
    this.datosFiltro = this.buscarUsuarios();
    this.usuarioService.setDatosFiltros(this.datosFiltro);
    this.desdeFiltroUsuario.emit(this.datosFiltro);
  }

  /**
   * Limpiar el formulario
   */
  reset(): any {
    this.resetearListas();
    this.buscarFiltro();
    this.esDniPrimeraBusqueda = true;
    this.esApePrimeraBusqueda = true;
    this.esEmaPrimeraBusqueda = true;
    this.esNamePrimeraBusqueda = true;
  }

  resetearListas(): void {
    this.listaDNIs = [];
    this.listaEmails = [];
    this.listaNombres = [];
    this.listaApellidos = [];
    this.formulario.controls.nombre.setValue([]);
    this.formulario.controls.apellido.setValue([]);
    this.formulario.controls.dni.setValue([]);
    this.formulario.controls.email.setValue([]);
    this.formulario.controls.variosDNIs.setValue([]);
    this.formulario.controls.variosEmails.setValue([]);
    this.formulario.controls.variosNombres.setValue([]);
    this.formulario.controls.variosApellidos.setValue([]);
    this.formulario.controls.actividades.setValue([]);
    this.formulario.controls.empresas.setValue([]);
    this.formulario.controls.tiposSolicitantes.setValue([]);
    this.formulario.controls.provincias.setValue([]);
    this.formulario.controls.zonas.setValue([]);
    this.formulario.controls.ambitos.setValue([]);
    this.formulario.controls.roles.setValue([]);
    this.formulario.controls.puestos.setValue([]);
    this.formulario.controls.regiones.setValue([]);
    this.formulario.controls.flexiplanActivo.setValue(false);
    this.formulario.controls.fechaAltaInicio.setValue('');
    this.formulario.controls.fechaAltaFin.setValue('');
    this.formulario.controls.fechaBajaInicio.setValue('');
    this.formulario.controls.fechaBajaFin.setValue('');
  }

  buscarEmail(event: any): any {
    const cadena = event.target.value;
    if (!cadena || cadena.length < 3) {
      this.listaEmails = [];
      this.esEmaPrimeraBusqueda = true;
    } else {
      this.usuarioService
        .buscarEmailForm(cadena)
        .subscribe((resp: string[]) => {
          this.listaEmails = resp.map((email: string) => ({
            email,
            state: false,
          }));
          this.esEmaPrimeraBusqueda = false;
        });
    }
  }

  buscarNombre(event: any): any {
    const cadena = event.target.value;
    if (!cadena || cadena.length < 3) {
      this.listaNombres = [];
      this.esNamePrimeraBusqueda = true;
    } else {
      this.usuarioService
        .buscarNombreForm(cadena)
        .subscribe((resp: string[]) => {
          this.listaNombres = resp.map((nombre: string) => ({
            nombre,
            state: false,
          }));
          this.esNamePrimeraBusqueda = false;
        });
    }
  }

  buscarApellido(event: any): any {
    const cadena = event.target.value;
    if (!cadena || cadena.length < 3) {
      this.listaApellidos = [];
      this.esApePrimeraBusqueda = true;
    } else {
      this.usuarioService
        .buscarApellidoForm(cadena)
        .subscribe((resp: string[]) => {
          this.listaApellidos = resp.map((apellido: string) => ({
            apellido,
            state: false,
          }));
          this.esApePrimeraBusqueda = false;
        });
    }
  }

  buscarDni(event: any): any {
    const cadena = event.target.value;
    if (!cadena || cadena.length < 3) {
      this.listaDNIs = [];
      this.esApePrimeraBusqueda = true;
    } else {
      this.usuarioService.buscarDniForm(cadena).subscribe((resp: string[]) => {
        this.listaDNIs = resp.map((dni: string) => ({ dni, state: false }));
        this.esDniPrimeraBusqueda = false;
      });
    }
  }

  addDNI(item: any, index: number): void {
    if (!this.formulario.get('variosDNIs')?.value.includes(item.dni)) {
      this.formulario.get('variosDNIs')?.value.push(item.dni);
    }
    this.removeDNI(index);
  }

  addApellido(item: any, index: number): void {
    if (
      !this.formulario.get('variosApellidos')?.value.includes(item.apellido)
    ) {
      this.formulario.get('variosApellidos')?.value.push(item.apellido);
    }
    this.removeApellido(index);
  }

  addNombre(item: any, index: number): void {
    if (!this.formulario.get('variosNombres')?.value.includes(item.nombre)) {
      this.formulario.get('variosNombres')?.value.push(item.nombre);
    }
    this.removeNombre(index);
  }

  addEmail(item: any, index: number): void {
    if (!this.formulario.get('variosEmails')?.value.includes(item.email)) {
      this.formulario.get('variosEmails')?.value.push(item.email);
    }
    this.removeEmail(index);
  }

  removeApellidoForm(apellidoEliminar: string, index: number): void {
    this.formulario.get('variosApellidos')?.value.splice(index, 1);
    this.listaApellidos.push({ apellido: apellidoEliminar, state: false });
  }

  removeNombreForm(nombreEliminar: string, index: number): void {
    this.formulario.get('variosNombres')?.value.splice(index, 1);
    this.listaNombres.push({ nombre: nombreEliminar, state: false });
  }

  removeDniForm(dniEliminar: string, index: number): void {
    this.formulario.get('variosDNIs')?.value.splice(index, 1);
    this.listaDNIs.push({ dni: dniEliminar, state: false });
  }

  removeEmailForm(emailEliminar: string, index: number): void {
    this.formulario.get('variosEmails')?.value.splice(index, 1);
    this.listaEmails.push({ email: emailEliminar, state: false });
  }

  removeApellido(index: number): void {
    this.listaApellidos.splice(index, 1);
  }

  removeNombre(index: number): void {
    this.listaNombres.splice(index, 1);
  }

  removeDNI(index: number): void {
    this.listaDNIs.splice(index, 1);
  }

  removeEmail(index: number): void {
    this.listaEmails.splice(index, 1);
  }

  /**
   * Funcion que valida si una fecha fin es anterior a la de inicio
   *
   * @param fechaInicio the fecha inicio formulario
   * @param fechaFin the fecha fin formulario
   * @returns true or false
   */
  validarFechas(fechaInicio: string, fechaFin: string): boolean {
    if (fechaInicio && fechaFin) {
      if (
        this.getDate(fechaInicio).getTime() > this.getDate(fechaFin).getTime()
      ) {
        return true;
      }
    }
    return false;
  }

  getDate(date: string): Date {
    const arrDate = date.split('-');
    return new Date(+arrDate[0], +arrDate[1] - 1, +arrDate[2]);
  }

  /**
   * Cambia el estado del radio button, para que se muestre fecha de baja o fecha de alta
   * @param event the evento
   */
  cambiarEstado(estado: string): void {
    if (estado === 'activos') {
      this.estadoActivo = true;
    }
    if (estado === 'inactivos') {
      this.estadoActivo = false;
    }
  }

  /**
   * Cambiar atributo para mostrar el filtro de avanzado o no
   */
  mostrarAvanzado(): void {
    if (this.avanzado) {
      this.avanzado = false;
      this.textoBoton = 'Más filtros';
    } else {
      this.avanzado = true;
      this.textoBoton = 'Menos filtros';
    }
  }
}

<div class="container espacio">
  <div class="d-flex justify-content-between mb-3">
    <h1>Gestión de Empresas</h1>
  </div>
  <p-accordion [multiple]="true" *ngIf="empresas && ambitos.length > 0">
    <p-accordionTab
      *ngFor="let empresa of empresas; index as j"
      [header]="empresa.descripcion"
      [selected]="true"
    >
      <div class="row mb-2 mt-1">
        <div class="col-sm-12 col-lg-4">
          <label for="empNoFlexId" style="font-weight: bold">Código:</label>
          <span id="empNoFlexId">{{ empresa.id }}</span>
        </div>
        <div class="col-sm-12 col-lg-6">
          <label for="empNoFlexDesc" style="font-weight: bold">Empresa: </label>
          <span id="empNoFlexDesc">{{ empresa.descripcion }}</span>
        </div>
      </div>
      <div
        class="row mb-2 mt-1"
        *ngFor="let noflexi of ambitosNoFlexiplan; index as i"
      >
        <div class="col-sm-12 col-lg-4">
          <hr />
          <label for="noFlexiZona" style="font-weight: bold">Zona:</label>
          <span id="noFlexiZona">{{ noflexi.zona }}</span>
        </div>
        <div class="col-sm-12 col-lg-4">
          <label for="noFlexiAmbitos" style="font-weight: bold">Ámbitos:</label>
          <p-dropdown
            id="noFlexiAmbitos"
            [options]="noflexi.ambitos"
            optionLabel="ambito"
            [(ngModel)]="ambitos[j][i]"
            [style]="{ width: '55%' }"
          ></p-dropdown>
        </div>
        <div class="col-sm-12 col-lg-4">
          <label for="noFlexiProv" style="font-weight: bolder"
            >Provincias:</label
          >
          <p-dropdown
            id="noFlexiProv"
            *ngIf="
              !ambitos ||
              !ambitos[j][i] ||
              ambitos[j][i].provincias.length === 0
            "
            [options]="noflexi.ambitos[0].provincias"
            [style]="{ width: '55%' }"
          ></p-dropdown>
          <p-dropdown
            *ngIf="ambitos && ambitos[j][i] && ambitos[j][i].provincias"
            [options]="ambitos[j][i].provincias"
            [style]="{ width: '55%' }"
          ></p-dropdown>
        </div>
      </div>
    </p-accordionTab>
  </p-accordion>
</div>

import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AuditoriaPeticionRequest } from 'src/app/modelos/auditoria';
import { UsuariosService } from 'src/app/servicios/usuarios/usuarios.service';
import { Constantes } from 'src/app/utils/constantes';
import Utils from 'src/app/utils/utils';

@Component({
  selector: 'app-filtro-auditoria',
  templateUrl: './filtro-auditoria.component.html',
  styleUrls: ['./filtro-auditoria.component.scss'],
})
export class FiltroAuditoriaComponent implements OnInit {
  @Output() buscar = new EventEmitter<AuditoriaPeticionRequest>();
  listaEmails: any[] = [];
  esEmaPrimeraBusqueda = true;
  removable = true;
  requestFilters: AuditoriaPeticionRequest = {};
  formulario: FormGroup;
  constructor(
    private usuarioService: UsuariosService,
    public constantes: Constantes
  ) {
    this.formulario = new FormGroup({
      idPeticion: new FormControl(),
      usuario: new FormControl(),
      variosEmails: new FormControl([]),
      initDate: new FormControl(),
      endDate: new FormControl(),
      acciones: new FormControl(),
    });
  }

  ngOnInit(): void {
    //on init
  }

  public validarFechas(fechaInicio: string, fechaFin: string): boolean {
    if (fechaInicio && fechaFin) {
      if (
        Utils.getDate(fechaInicio).getTime() > Utils.getDate(fechaFin).getTime()
      ) {
        this.formulario.get('endDate')?.setErrors({ incorrect: true });
        return true;
      } else {
        this.formulario.get('endDate')?.setErrors(null);
      }
    }
    return false;
  }

  buscarEmail(event: any): any {
    const cadena = event.target.value;
    if (!cadena || cadena.length < 3) {
      this.listaEmails = [];
      this.esEmaPrimeraBusqueda = true;
    } else {
      this.usuarioService
        .buscarEmailForm(cadena)
        .subscribe((resp: string[]) => {
          this.listaEmails = resp.map((email: string) => ({
            email,
            state: false,
          }));
          this.esEmaPrimeraBusqueda = false;
        });
    }
  }

  removeEmailForm(emailEliminar: string, index: number): void {
    this.formulario.get('variosEmails')?.value.splice(index, 1);
    this.listaEmails.push({ email: emailEliminar, state: false });
  }

  addEmail(item: any, index: number): void {
    if (!this.formulario.get('variosEmails')?.value.includes(item.email)) {
      this.formulario.get('variosEmails')?.value.push(item.email);
    }
    this.removeEmail(index);
  }

  removeEmail(index: number): void {
    this.listaEmails.splice(index, 1);
  }

  search(): void {
    const values = this.formulario.getRawValue();
    this.requestFilters.id = values.idPeticion;
    this.requestFilters.emails =
      values.variosEmails.length === 0 ? null : values.variosEmails;
    this.requestFilters.acciones = values.acciones;
    this.requestFilters.fechaInicio = values.initDate;
    this.requestFilters.fechaFin = values.endDate;
    this.buscar.emit(this.requestFilters);
  }

  clean(): void {
    this.formulario.reset();
    this.formulario.get('variosEmails')?.setValue([]);
    this.esEmaPrimeraBusqueda = true;
    this.listaEmails = [];
    this.search();
  }
}

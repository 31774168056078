import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { MensajeComponent } from 'src/app/componentes/mensaje/mensaje.component';
import { Constantes } from 'src/app/utils/constantes';
import { Peticion } from '../../../../modelos/peticion';
import { PeticionService } from '../../../../servicios/peticion/peticion.service';
import { environment } from 'src/environments/environment';
import { NotificacionesService } from 'src/app/servicios/notificaciones/notificaciones.service';
import { UsuariosService } from 'src/app/servicios/usuarios/usuarios.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Notificador } from '../../../../modelos/notificador';

declare let $: any;

@Component({
  selector: 'app-crear-peticion-notificaciones',
  templateUrl: './crear-peticion-notificaciones.component.html',
  styleUrls: ['./crear-peticion-notificaciones.component.css'],
})
export class CrearPeticionNotificacionesComponent implements OnInit {
  activeIndex = 4;
  public patronemail!: string;

  peticion!: Peticion;

  public formulario!: FormGroup;
  public modificable = true;

  @ViewChild('mensaje', { static: false })
  public mensaje!: MensajeComponent;

  listaNotificadores: any = [];
  trabajadorAfectado: any;

  listaEmailsFiltered: any[] = [];
  listaOtros: any[] = [];
  messageOtros = 'No hay resultados o se ha excedido el limite de emails';

  private usuarioService!: UsuariosService;
  private spinner!: NgxSpinnerService;

  // cambiar cuando exista un servicio que lo devuelva
  codigoAccion = 5;

  constructor(
    private router: Router,
    public peticionService: PeticionService,
    private fb: FormBuilder,
    public constantes: Constantes,
    private notificacionesService: NotificacionesService,
    private inject: Injector
  ) {
    this.loadInitialProperties();
  }

  loadInitialProperties(): void {
    this.usuarioService = this.inject.get<UsuariosService>(UsuariosService);
    this.spinner = this.inject.get<NgxSpinnerService>(NgxSpinnerService);
  }

  ngOnInit(): void {
    this.spinner.show();
    this.peticion = this.peticionService.getPeticion();
    this.modificable = this.peticionService.esModificable();
    this.notificacionesService
      .obtenerListaNotificadores(this.peticion?.idPeticion)
      .subscribe(
        (result) => {
          this.listaNotificadores = result.listaNotificadores;
          console.log(this.listaNotificadores);
          this.trabajadorAfectado = result.trabajadorAfectado;
          this.cargarFormulario();
          this.cambioEmailNotificadores();
          this.spinner.hide();
        },
        (error: any) => {
          console.log(error);
          this.spinner.hide();
          this.mensaje.showError(error);
        }
      );
  }

  cargarFormulario(): void {
    this.peticion = this.peticionService.getPeticion();
    this.patronemail = environment.PATRON_EMAIL_DOMINIO;

    if (
      this.peticion.notificadoresLst !== undefined &&
      this.peticion.notificadoresLst != null
    ) {
      for (const e of this.peticion.notificadoresLst) {
        if (e.formulario === 'otros') {
          this.listaOtros.push(e.emailOtros);
        }
      }
    }
    this.formulario = this.fb.group({
      gruposoporte: [
        { value: this.peticion?.grupoSoporte?.descripcion, disabled: true },
      ],
      destinatario: [
        { value: this.peticion?.usuarioDestinatario?.email, disabled: true },
      ],
      trabajador: [{ value: this.trabajadorAfectado?.email, disabled: true }],
      otros: [{ value: this.listaOtros }],
    });

    for (const e of this.listaNotificadores) {
      const i = 'notificador' + e.idForm;
      const v =
        e.valorDefecto !== undefined && e.valorDefecto !== null
          ? e.valorDefecto
          : null;
      this.formulario.addControl(i, new FormControl(v));
      $('#nombre' + e.idForm).text(v === null ? '' : v.nombre);
    }
  }

  filterOtros(event: any): void {
    if (this.listaOtros.length < 3) {
      this.usuarioService.buscarEmailForm(event.query).subscribe((data) => {
        this.listaEmailsFiltered = data;
      });
    } else {
      this.listaEmailsFiltered = [];
    }
  }

  nextPage(): void {
    this.peticion = this.peticionService.getPeticion();
    if (
      (this.peticion &&
        (this.peticion.notificadoresLst === null ||
          this.peticion.notificadoresLst?.length === 0)) ||
      this.formulario.dirty
    ) {
      this.mostrarModalConfirmacion('peticiones/crear/confirmacion');
    } else {
      this.router.navigate(['peticiones/crear/confirmacion']);
    }
  }

  previousPage(): void {
    this.peticion = this.peticionService.getPeticion();
    if (
      (this.peticion &&
        (this.peticion.notificadoresLst === null ||
          this.peticion.notificadoresLst?.length === 0)) ||
      this.formulario.dirty
    ) {
      this.mostrarModalConfirmacion('peticiones/crear/adjuntar');
    } else {
      this.router.navigate(['peticiones/crear/adjuntar']);
    }
  }

  guardarNotificacion(): void {
    this.peticion = this.peticionService.getPeticion();
    if (
      (this.peticion &&
        (this.peticion.notificadoresLst === null ||
          this.peticion.notificadoresLst?.length === 0)) ||
      this.formulario.dirty
    ) {
      this.mostrarModalConfirmacion('peticiones/crear/notificaciones');
    } else {
      this.mensaje.showMensaje('Está todo actualizado.');
    }
  }

  mostrarModalConfirmacion(ruta: string): void {
    if (this.peticion && this.peticionService.esModificable()) {
      this.onConfirmModal(1, ruta);
    } else {
      this.router.navigate([ruta]);
    }
  }

  public onConfirmModal(event: any, ruta: string): void {
    this.mensaje.cerrar();
    if (event) {
      const email =
        this.peticion?.usuarioDestinatario?.email !== undefined
          ? this.peticion?.usuarioDestinatario?.email
          : '';
      const nombre =
        this.peticion?.usuarioDestinatario?.nombre !== undefined
          ? this.peticion?.usuarioDestinatario?.nombre
          : '';
      const idPeticion =
        this.peticion?.idPeticion !== undefined
          ? this.peticion?.idPeticion
          : '';
      let listaNotificadores: Notificador[] = [];
      listaNotificadores = this.annadirNotificador(
        this.codigoAccion,
        idPeticion,
        nombre,
        email,
        'destinatario',
        listaNotificadores
      );
      listaNotificadores = this.annadirNotificadoresDinamicos(
        email,
        nombre,
        listaNotificadores,
        idPeticion
      );
      listaNotificadores = this.comprobarOtrosNotificadores(
        listaNotificadores,
        idPeticion
      );
      listaNotificadores = this.annadirNotificadorTrabajador(
        email,
        nombre,
        listaNotificadores,
        idPeticion
      );
      this.peticion.notificadoresLst = listaNotificadores;
      this.peticionService.setPeticion(this.peticion);
      this.actualizarPeticion(ruta);
    }
  }

  private comprobarOtrosNotificadores(
    listaNotificadores: Notificador[],
    idPeticion: string
  ): Notificador[] {
    if (this.formulario.get('otros')?.value != null) {
      for (const e of this.formulario.get('otros')?.value) {
        listaNotificadores = this.annadirNotificadorOtros(
          this.codigoAccion,
          idPeticion,
          e,
          'otros',
          listaNotificadores
        );
      }
    }
    return listaNotificadores;
  }

  private annadirNotificadorTrabajador(
    email: string,
    nombre: string,
    listaNotificadores: Notificador[],
    idPeticion: string
  ): Notificador[] {
    if (this.trabajadorAfectado) {
      email =
        this.trabajadorAfectado?.email !== undefined
          ? this.trabajadorAfectado?.email
          : '';
      nombre =
        this.trabajadorAfectado?.nombre !== undefined
          ? this.trabajadorAfectado?.nombre
          : '';
      listaNotificadores = this.annadirNotificador(
        this.codigoAccion,
        idPeticion,
        nombre,
        email,
        'afectado',
        listaNotificadores
      );
    }
    return listaNotificadores;
  }

  private actualizarPeticion(ruta: string): void {
    this.spinner.show();
    this.peticionService
      .actualizarPeticion(this.peticionService.getPeticion())
      .subscribe(
        (result: Peticion) => {
          if (result) {
            this.spinner.hide();
            this.mensaje.showMensaje(
              'La petición se ha actualizado de forma correcta',
              ruta
            );
            this.peticionService.setPeticion(result);
          } else {
            this.spinner.hide();
            this.mensaje.showError('No se ha podido actualizar la petición');
          }
        },
        (error) => {
          this.spinner.hide();
          this.mensaje.showError(error);
        }
      );
  }

  private annadirNotificadoresDinamicos(
    email: string,
    nombre: string,
    listaNotificadores: Notificador[],
    idPeticion: string
  ): Notificador[] {
    if (listaNotificadores != null) {
      for (const e of this.listaNotificadores) {
        const i = 'notificador' + e.idForm;
        if (
          this.formulario.get(i)?.value !== null &&
          this.formulario.get(i)?.value.email !== null &&
          this.formulario.get(i)?.value.email !== ''
        ) {
          email = this.formulario.get(i)?.value.email;
          nombre = this.formulario.get(i)?.value.nombre;
          listaNotificadores = this.annadirNotificador(
            this.codigoAccion,
            idPeticion,
            nombre,
            email,
            'dinamico',
            listaNotificadores
          );
        }
      }
    }
    return listaNotificadores;
  }

  annadirNotificador(
    codigoAccion: number,
    codigoPeticion: string,
    nombreUsuario: string,
    emailUsuario: string,
    tipoCombo: string,
    listaNotificadores: Notificador[]
  ): Notificador[] {
    const notificadorDestinatario: Notificador = {
      codAccion: codigoAccion,
      codPeticion: codigoPeticion,
      formulario: tipoCombo,
      usuario: {
        email: emailUsuario,
        nombre: nombreUsuario,
      },
    };
    listaNotificadores.push(notificadorDestinatario);
    return listaNotificadores;
  }

  annadirNotificadorOtros(
    codigoAccion: number,
    codigoPeticion: string,
    email: string,
    tipoCombo: string,
    listaNotificadores: Notificador[]
  ): Notificador[] {
    const notificadorDestinatario: Notificador = {
      codAccion: codigoAccion,
      codPeticion: codigoPeticion,
      formulario: tipoCombo,
      emailOtros: email,
    };
    listaNotificadores.push(notificadorDestinatario);
    return listaNotificadores;
  }

  cambioEmailNotificadores(): void {
    Object.keys(this.formulario.controls).forEach((key) => {
      if (key.includes('notificador')) {
        const id = key.split('notificador')[1];
        const value =
          this.formulario.get('notificador' + id)?.value === null
            ? ''
            : this.formulario.get('notificador' + id)?.value.nombre;
        $('#nombre' + id).text(value);
      }
    });
  }
}

<div class="container">
  <fieldset>
    <legend>Buscar por ...</legend>
    <form [formGroup]="formulario" *ngIf="formulario">
      <div class="row espacio-vertical">
        <div class="col-lg-2">
          <p-radioButton
            name="estado"
            value="activos"
            label="Activos"
            formControlName="estado"
            inputId="activos"
            (click)="cambiarEstado('activos')"
            [checked]
          ></p-radioButton>
        </div>
        <div class="col-lg-2">
          <p-radioButton
            name="estado"
            value="inactivos"
            label="Inactivos"
            formControlName="estado"
            inputId="inactivos"
            (click)="cambiarEstado('inactivos')"
          ></p-radioButton>
        </div>
      </div>

      <div class="row">
        <div class="mb-3 col-sm-12 col-lg-6">
          <div>
            <label for="apellido" class="form-label">Email</label>
            <div class="col-sm-12 col-lg-7 p-0">
              <input
                type="text"
                autocomplete="off"
                class="form-control"
                id="email"
                name="email"
                formControlName="email"
                placeholder="Introduzca emails a buscar"
                (input)="buscarEmail($event)"
              />
              <small
                class="form-text text-danger"
                *ngIf="
                  listaEmails.length === 0 &&
                  !esEmaPrimeraBusqueda &&
                  this.formulario.get('variosEmails')?.value.length === 0
                "
                >* No se han encontrado resultados</small
              >
            </div>
            <div class="col-sm-12 col-lg-12 mb-12">
              <mat-chip
                class="selected-chip mr-3 mb-2"
                *ngFor="
                  let emailInput of this.formulario.get('variosEmails')?.value;
                  let i = index
                "
                [removable]="removable"
                (removed)="removeEmailForm(emailInput, i)"
              >
                {{ emailInput }}
                <mat-icon matChipRemove *ngIf="removable">X</mat-icon>
              </mat-chip>
            </div>
            <div class="col-sm-12 col-lg-12">
              <mat-chip-list>
                <mat-chip
                  class="mr-5 mb-2"
                  *ngFor="let email of listaEmails; let i = index"
                  [selected]="email.state"
                  selectable
                  multiple
                  [removable]="removable"
                  (removed)="removeEmail(i)"
                  [value]="email"
                  [multiple]="true"
                  (click)="email.state = !email.state; addEmail(email, i)"
                >
                  {{ email.email }}
                  <mat-icon matChipRemove *ngIf="removable">X</mat-icon>
                </mat-chip>
              </mat-chip-list>
            </div>
          </div>
        </div>
        <div class="mb-3 col-sm-12 col-lg-6">
          <div>
            <label for="apellido" class="form-label">Nombre</label>
            <div class="col-sm-12 col-lg-7 p-0">
              <input
                type="text"
                autocomplete="off"
                class="form-control"
                id="nombre"
                name="nombre"
                formControlName="nombre"
                placeholder="Introduzca Nombres a buscar"
                (input)="buscarNombre($event)"
              />
              <small
                class="form-text text-danger"
                *ngIf="
                  listaNombres.length === 0 &&
                  !esNamePrimeraBusqueda &&
                  this.formulario.get('variosNombres')?.value.length === 0
                "
                >* No se han encontrado resultados</small
              >
            </div>
            <div class="col-sm-12 col-lg-12 mb-12">
              <mat-chip
                class="selected-chip mr-3 mb-2"
                *ngFor="
                  let nombreInput of this.formulario.get('variosNombres')
                    ?.value;
                  let i = index
                "
                [removable]="removable"
                (removed)="removeNombreForm(nombreInput, i)"
              >
                {{ nombreInput }}
                <mat-icon matChipRemove *ngIf="removable">X</mat-icon>
              </mat-chip>
            </div>
            <div class="col-sm-12 col-lg-12">
              <mat-chip-list>
                <mat-chip
                  *ngFor="let nombre of listaNombres; let i = index"
                  [selected]="nombre.state"
                  selectable
                  multiple
                  [removable]="removable"
                  (removed)="removeNombre(i)"
                  [value]="nombre"
                  [multiple]="true"
                  (click)="nombre.state = !nombre.state; addNombre(nombre, i)"
                >
                  {{ nombre.nombre }}
                  <mat-icon matChipRemove *ngIf="removable">X</mat-icon>
                </mat-chip>
              </mat-chip-list>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="mb-3 col-sm-12 col-lg-6">
          <div>
            <label for="apellido" class="form-label">DNI/NIE</label>
            <mat-chip-list #chipList aria-label="Selección DNIs">
              <div class="col-sm-12 col-lg-7">
                <input
                  type="text"
                  autocomplete="off"
                  class="form-control apellido"
                  id="dni"
                  name="dni"
                  formControlName="dni"
                  placeholder="Introduzca DNIs a buscar"
                  (input)="buscarDni($event)"
                  maxlength="9"
                />
                <small
                  class="form-text text-danger"
                  *ngIf="
                    listaDNIs.length === 0 &&
                    !esDniPrimeraBusqueda &&
                    this.formulario.get('variosDNIs')?.value.length === 0
                  "
                  >* No se han encontrado resultados</small
                >
              </div>
              <div class="col-sm-12 col-lg-12 mb-4">
                <mat-chip
                  class="selected-chip"
                  *ngFor="
                    let dniInput of this.formulario.get('variosDNIs')?.value;
                    let i = index
                  "
                  [removable]="removable"
                  (removed)="removeDniForm(dniInput, i)"
                >
                  {{ dniInput }}
                  <mat-icon matChipRemove *ngIf="removable">X</mat-icon>
                </mat-chip>
              </div>
              <div class="col-sm-12 col-lg-12">
                <mat-chip-list>
                  <mat-chip
                    *ngFor="let dni of listaDNIs; let i = index"
                    [selected]="dni.state"
                    selectable
                    multiple
                    [removable]="removable"
                    (removed)="removeDNI(i)"
                    [value]="dni"
                    [multiple]="true"
                    (click)="dni.state = !dni.state; addDNI(dni, i)"
                  >
                    {{ dni.dni }}
                    <mat-icon matChipRemove *ngIf="removable">X</mat-icon>
                  </mat-chip>
                </mat-chip-list>
              </div>
            </mat-chip-list>
          </div>
        </div>
        <div class="mb-3 col-sm-12 col-lg-6">
          <div>
            <label for="apellido" class="form-label">Apellidos</label>
            <mat-chip-list #chipList aria-label="Selección Apellidos">
              <div class="col-sm-12 col-lg-7">
                <input
                  type="text"
                  autocomplete="off"
                  class="form-control apellido"
                  id="apellido"
                  name="apellido"
                  formControlName="apellido"
                  placeholder="Introduzca Apellidos a buscar"
                  (input)="buscarApellido($event)"
                />
                <small
                  class="form-text text-danger"
                  *ngIf="
                    listaApellidos.length === 0 &&
                    !esApePrimeraBusqueda &&
                    this.formulario.get('variosApellidos')?.value.length === 0
                  "
                  >* No se han encontrado resultados</small
                >
              </div>
              <div class="col-sm-12 col-lg-12 mb-4">
                <mat-chip
                  class="selected-chip"
                  *ngFor="
                    let apellidoInput of this.formulario.get('variosApellidos')
                      ?.value;
                    let i = index
                  "
                  [removable]="removable"
                  (removed)="removeApellidoForm(apellidoInput, i)"
                >
                  {{ apellidoInput }}
                  <mat-icon matChipRemove *ngIf="removable">X</mat-icon>
                </mat-chip>
              </div>
              <div class="col-sm-12 col-lg-12">
                <mat-chip-list>
                  <mat-chip
                    *ngFor="let apellido of listaApellidos; let i = index"
                    [selected]="apellido.state"
                    selectable
                    multiple
                    [removable]="removable"
                    (removed)="removeApellido(i)"
                    [value]="apellido"
                    [multiple]="true"
                    (click)="
                      apellido.state = !apellido.state; addApellido(apellido, i)
                    "
                  >
                    {{ apellido.apellido }}
                    <mat-icon matChipRemove *ngIf="removable">X</mat-icon>
                  </mat-chip>
                </mat-chip-list>
              </div>
            </mat-chip-list>
          </div>
        </div>
      </div>

      <button
        class="btn btn-outline-info customButton fa fa-filter filtros"
        (click)="mostrarAvanzado()"
      >
        {{ textoBoton }}
      </button>
      <div class="avanzado" *ngIf="avanzado">
        <div class="row">
          <div class="mb-3 col-sm-12 col-lg-6">
            <label for="tiposSolicitantes" class="form-label">Tipo Solicitante</label>
            <p-multiSelect
              id="tiposSolicitantes"
              filterPlaceHolder="Buscar"
              [defaultLabel]="'Selección de tipos de solicitante'"
              [virtualScroll]="true"
              [options]="listaSolicitantes"
              optionLabel="descripcion"
              formControlName="tiposSolicitantes"
              styleClass="form-control p-0"
            ></p-multiSelect>
          </div>
          <div class="mb-3 col-sm-12 col-lg-6">
            <label for="empresa" class="form-label">Empresa</label>
            <p-multiSelect
              id="empresa"
              styleClass="form-control p-0"
              placeholder="Seleccione una empresa"
              [options]="listaEmpresasFiltradas"
              formControlName="empresas"
              [disabled]="
                this.formulario.get('flexiplanActivo')?.value === true
              "
              optionLabel="descripcion"
              [filter]="true"
            >
            </p-multiSelect>
          </div>
        </div>

        <div class="row">
          <div class="mb-3 col-sm-12 col-lg-6">
            <label for="actividades" class="form-label">Actividades</label>
            <p-multiSelect
              id="actividades"
              filterPlaceHolder="Buscar"
              [defaultLabel]="'Seleccione actividades'"
              [virtualScroll]="true"
              [options]="listaActividades"
              optionLabel="descripcion"
              formControlName="actividades"
              styleClass="form-control p-0"
            ></p-multiSelect>
          </div>
          <div class="mb-3 col-sm-12 col-lg-6">
            <label for="empresa" class="form-label">Lineas de venta</label>
            <p-multiSelect
              id="lineasVenta"
              filterPlaceHolder="Buscar"
              [defaultLabel]="'Seleccione líneas de venta'"
              [virtualScroll]="true"
              [options]="listaLineasVenta"
              optionLabel="descripcion"
              formControlName="lineasVenta"
              styleClass="form-control p-0"
            ></p-multiSelect>
          </div>
        </div>

        <div class="row">
          <div class="mb-3 col-sm-12 col-lg-6">
            <label for="rol" class="form-label">Rol</label>
            <p-multiSelect
              id="rol"
              filterPlaceHolder="Buscar"
              [defaultLabel]="'Selección de roles'"
              [virtualScroll]="true"
              [options]="listaRoles"
              optionLabel="descripcion"
              formControlName="roles"
              styleClass="form-control p-0"
            ></p-multiSelect>
          </div>
          <div class="mb-3 col-sm-12 col-lg-6">
            <label for="perfil" class="form-label">Perfil</label>
            <p-multiSelect
              id="perfil"
              filterPlaceHolder="Buscar"
              [defaultLabel]="'Selección de perfiles'"
              [virtualScroll]="true"
              [options]="listaPuestos"
              optionLabel="descripcion"
              formControlName="puestos"
              styleClass="form-control
                                    p-0"
            ></p-multiSelect>
          </div>
        </div>

        <!-- Caso Provincias para Flexiplan-->
        <div
          class="row"
          *ngIf="
            this.formulario.get('flexiplanActivo')?.value === true;
            else provinciaFlexiplan
          "
        >
          <div class="mb-3 col-sm-12 col-lg-6">
            <label for="regiones" class="form-label">Región</label>
            <p-multiSelect
              id="regiones"
              filterPlaceHolder="Buscar"
              [defaultLabel]="'Seleccione región'"
              [virtualScroll]="true"
              [options]="listaRegiones"
              optionLabel="descripcion"
              formControlName="regiones"
              styleClass="form-control
                                    p-0"
            ></p-multiSelect>
          </div>
          <div class="mb-3 col-sm-12 col-lg-6">
            <label for="provincias" class="form-label">Provincias</label>
            <p-multiSelect
              id="provincias"
              filterPlaceHolder="Buscar"
              [defaultLabel]="'Seleccione provincias'"
              [virtualScroll]="true"
              [options]="listaProvincias"
              optionLabel="descripcion"
              formControlName="provincias"
              styleClass="form-control p-0"
            ></p-multiSelect>
          </div>
        </div>
        <!-- Caso Provincias para NO Flexiplan-->
        <ng-template #provinciaFlexiplan>
          <div class="row">
            <div class="mb-3 col-sm-12 col-lg-4">
              <label for="zonas" class="form-label">Zonas</label>
              <p-multiSelect
                id="zonas"
                filterPlaceHolder="Buscar"
                [defaultLabel]="'Seleccione zonas'"
                [virtualScroll]="true"
                [options]="listaZonas"
                optionLabel="descripcion"
                formControlName="zonas"
                styleClass="form-control p-0"
              ></p-multiSelect>
            </div>
            <div class="mb-3 col-sm-12 col-lg-4">
              <label for="ambitos" class="form-label">Ámbitos</label>
              <p-multiSelect
                id="ambitos"
                filterPlaceHolder="Buscar"
                [defaultLabel]="'Seleccione ámbitos'"
                [virtualScroll]="true"
                [options]="listaAmbitos"
                optionLabel="descripcion"
                formControlName="ambitos"
                styleClass="form-control p-0"
              ></p-multiSelect>
            </div>
            <div class="mb-3 col-sm-12 col-lg-4">
              <label for="provincias" class="form-label">Provincias</label>
              <p-multiSelect
                id="provincias"
                filterPlaceHolder="Buscar"
                [defaultLabel]="'Seleccione provincias'"
                [virtualScroll]="true"
                [options]="listaProvincias"
                optionLabel="descripcion"
                formControlName="provincias"
                styleClass="form-control p-0"
              ></p-multiSelect>
            </div>
          </div>
        </ng-template>

        <div class="row">
          <div class="mb-3 col-sm-12 col-lg-6">
            <label for="fechaAltaInicio" class="form-label"
              >Fecha alta inicio</label
            >
            <input
              class="form-control"
              type="date"
              value=""
              id="fechaAltaInicio"
              formControlName="fechaAltaInicio"
              trim="blur"
            />
          </div>
          <div class="mb-3 col-sm-12 col-lg-6">
            <label for="fechaAltaFin" class="form-label">Fecha alta fin</label>
            <input
              class="form-control"
              type="date"
              value=""
              id="fechaAltaFin"
              formControlName="fechaAltaFin"
              trim="blur"
            />
            <small
              class="form-text text-danger"
              *ngIf="
                formulario.get('fechaAltaFin')!.touched &&
                validarFechas(
                  formulario.get('fechaAltaInicio')?.value,
                  formulario.get('fechaAltaFin')?.value
                )
              "
              >* La fecha alta fin debe ser mayor que la fecha alta
              inicio</small
            >
          </div>
        </div>

        <div class="row" [hidden]="estadoActivo">
          <div class="mb-3 col-sm-12 col-lg-6">
            <label for="fechaBajaInicio" class="form-label"
              >Fecha baja inicio</label
            >
            <input
              class="form-control"
              type="date"
              value=""
              id="fechaBajaInicio"
              formControlName="fechaBajaInicio"
              trim="blur"
            />
          </div>

          <div class="mb-3 col-sm-12 col-lg-6">
            <label for="fechaBajaFin" class="form-label">Fecha baja fin</label>
            <input
              class="form-control"
              type="date"
              value=""
              id="fechaBajaFin"
              formControlName="fechaBajaFin"
              trim="blur"
            />
            <small
              class="form-text text-danger"
              *ngIf="
                formulario.get('fechaBajaFin')!.touched &&
                validarFechas(
                  formulario.get('fechaBajaInicio')?.value,
                  formulario.get('fechaBajaFin')?.value
                )
              "
              >* La fecha baja fin debe ser mayor que la fecha baja
              inicio</small
            >
          </div>
        </div>
      </div>

      <div class="mt-2 mb-3 d-flex izquierda">
        <button
          class="btn limpiarBoton customButton fa fa-eraser"
          (click)="reset()"
        >
          Limpiar
        </button>
        <button
          type="submit"
          class="btn btn-outline-primary customButton fa fa-search buscar"
          (click)="buscarFiltro()"
        >
          Buscar
        </button>
      </div>
    </form>
  </fieldset>
</div>

<div class="d-flex justify-content-end mb-3">
  <button
    [disabled]="usuarioLogin.rol?.id == 7"
    class="btn btn-outline-primary customButton fa fa-plus-circle"
    (click)="redirectToCreateUser()"
  >
    Crear Usuario
  </button>
</div>

<p-table
  id="tabla-usuarios"
  #dt1
  [value]="usuarios"
  dataKey="email"
  [showCurrentPageReport]="true"
  styleClass="p-datatable-responsive-demo"
  [rows]="rows"
  [totalRecords]="usuariosTotales"
  [columns]="cols"
  [lazy]="true"
  (onLazyLoad)="nextPage($event, dt1)"
  [paginator]="true"
  [globalFilterFields]="cols"
  sortField="nombre"
  [sortOrder]="1"
  currentPageReportTemplate="Página {{ paginaActual }} de {{ paginasTotales }}"
  [rowsPerPageOptions]="usuariosPorPagina"
  [(first)]="first"
>
  <ng-template pTemplate="caption" class="caption">
    <button class="btn boton-excel" (click)="exportarDatos()">
      <i class="fa fa-file-excel-o" aria-hidden="true"></i> EXPORTAR
    </button>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th
        *ngFor="let col of cols"
        scope="{{ col.field }}"
        pSortableColumn="{{ col.field }}"
      >
        <div
          class="p-d-flex p-jc-between p-ai-center"
          *ngIf="col.field !== 'acciones'"
        >
          {{ col.header }}
          <p-sortIcon
            ariaLabel="Activar para ordenar"
            ariaLabelDesc="Activar para ordenar en orden descendiente"
            ariaLabelAsc="Activar para ordenar en orden ascendiente"
            [field]="col.field"
          ></p-sortIcon>
        </div>
        <div
          class="p-d-flex p-jc-between p-ai-center"
          *ngIf="col.field === 'acciones'"
        >
          {{ col.header }}
        </div>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-usuario let-i="rowIndex">
    <tr>
      <td><span class="p-column-title">ESTADO</span>
        <span class="d-flex justify-content-center align-items-center etiquetaEstado cAmarillo" *ngIf="usuario.fechaBaja === usuario.fechaAlta">Pte. Alta</span>
        <span class="d-flex justify-content-center align-items-center etiquetaEstado cRojo" *ngIf="usuario.fechaBaja !== null && usuario.fechaAlta !== null && usuario.idBaja !== null">Inactivo</span>
        <span class="d-flex justify-content-center align-items-center etiquetaEstado cVerde" *ngIf="usuario.fechaBaja === null && usuario.fechaAlta !== null">Activo</span>
      </td>
      <td><span class="p-column-title">NOMBRE</span> {{ usuario.nombre }}</td>
      <td>
        <span class="p-column-title">APELLIDOS</span> {{ usuario.apellido }}
      </td>
      <td>
        <span class="p-column-title">ROL</span> {{ usuario.rol.descripcion }}
      </td>
      <td>
        <span class="p-column-title">PERFIL</span
        >{{ usuario.puesto.descripcion }}
      </td>
      <td>
        <span class="p-column-title">ZONA / REGIÓN</span>
        <ng-container
          *ngIf="usuario.zonas && usuario.zonas.length > 0; else regiones"
        >
          <ng-container *ngFor="let zona of usuario.zonas; index as i">
            <p *ngIf="i <= 3">
              <span *ngIf="i < 3">{{ zona.descripcion }}</span>
              <span style="color: red" *ngIf="i === 3"
                >... <br />(Ver más datos en Editar)</span
              >
            </p>
          </ng-container>
        </ng-container>
        <ng-template #regiones>
          <ng-container *ngIf="usuario.regiones">
            <ng-container *ngFor="let region of usuario.regiones; index as i">
              <p *ngIf="i <= 3">
                <span *ngIf="i < 3">{{ region.descripcion }}</span>
                <span style="color: red" *ngIf="i === 3"
                  >... <br />(Ver más datos en Editar)</span
                >
              </p>
            </ng-container>
          </ng-container>
        </ng-template>
      </td>
      <td>
        <span class="p-column-title">ACTIVIDAD</span>
        <ng-container *ngFor="let actividad of usuario.actividades; index as i">
          <p *ngIf="i <= 3">
            <span *ngIf="i < 3">{{ actividad.descripcion }}</span>
            <span style="color: red" *ngIf="i === 3"
              >... <br />(Ver más datos en Editar)</span
            >
          </p>
        </ng-container>
      </td>
      <td
        *ngIf="!usuario.fechaBaja && usuarioLogin.rol?.id !== 7"
        class="acciones accion-izquierda text-center"
      >
        <button
          pTooltip="Editar"
          tooltipPosition="right"
          class="btn btn-sm btn-outline-info"
          (click)="redirectToModifyUser(usuario.email)"
        >
          <em class="fa fa-pencil"></em>
        </button>
        <button
          pTooltip="Auditoria"
          tooltipPosition="right"
          class="btn btn-sm btn-outline-primary visualizar"
          (click)="eventoIrAuditoria(usuario.email)"
        >
          <em class="fa fa-id-card"></em>
        </button>
        <button
          pTooltip="Desactivar"
          tooltipPosition="right"
          class="btn btn-sm btn-outline-danger"
          (click)="eliminarUsuario(usuario.email, i)"
        >
          <em class="fa fa-power-off"></em>
        </button>
      </td>
      <td *ngIf="usuarioLogin.rol?.id == 7" class="acciones text-center">
        <button
          pTooltip="Visualizar Usuario"
          tooltipPosition="right"
          data-original-title="Visualizar Usuario"
          class="btn btn-sm btn-outline-info visualizar"
          (click)="redirectToModifyUser(usuario.email)"
        >
          <em class="fa fa-eye"></em>
        </button>
      </td>
      <td *ngIf="usuario.fechaBaja" class="acciones">
        <button
          pTooltip="Activar"
          tooltipPosition="right"
          data-original-title="Reactivar"
          class="btn btn-sm btn-outline-success"
          (click)="activarUsuario(usuario.email, i)"
        >
          <em class="fa fa-reply"></em>
        </button>
      </td>
    </tr>
    <hr class="item-list-separator" />
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="7">No hay usuarios</td>
    </tr>
  </ng-template>
  <ng-template pTemplate="paginatorleft"> </ng-template>
  <ng-template pTemplate="paginatorright">
    <p class="pageFooterLeft">Usuarios totales: {{ usuariosTotales }}</p>
  </ng-template>
</p-table>

import { Injectable } from '@angular/core';

@Injectable()
export class UtilsForms {


  public ponerEstiloObligatorio($: any, id: string, tipo: string): void {
    switch (tipo) {
      case 'dropdown': {
        $(id).children().css('border-color', 'red');
        $(id).children().css('box-shadow', ' 0 0 0 0.2rem rgb(255 0 12 / 25%)');
        break;
      }
      case 'textarea':
      case 'input': {
        $(id).css('border-color', 'red');
        $(id).css('box-shadow', ' 0 0 0 0.2rem rgb(255 0 12 / 25%)');
        break;
      }
      default: {
         break;
      }
   }
  }

  public quitarEstiloObligatorio($: any, id: string, tipo: string): void {
    switch (tipo) {
      case 'dropdown': {
        $(id).children().css('border-color', '');
        $(id).children().css('box-shadow', '');
        break;
      }
      case 'textarea':
      case 'input': {
        $(id).css('border-color', '');
        $(id).css('box-shadow', '');
        break;
      }
      default: {
         break;
      }
   }
  }
}

<app-mensaje #mensaje> </app-mensaje>
<app-modal-notificar
  id="modal-notificar"
  #modalnotificar
  (emitirNotificar)="reload()"
></app-modal-notificar>
<app-modal-autorizar
  id="modal-autorizar"
  #modalAutorizar
  (emitirAutorizar)="reload()"
></app-modal-autorizar>
<app-modal-artica
  id="modal-artica"
  #modalArtica
  (emitirArtica)="articaRecibida($event)"
></app-modal-artica>
<div class="d-flex">
  <button
    class="btn customButton p-2 mt-2 mr-2 mb-2 ml-2"
    [ngClass]="{ active: visualSeleccionada === 1 }"
    (click)="activarVista(1)"
  >
    Vista Básica
  </button>
  <button
    class="btn customButton p-2 mt-2 mr-2 mb-2"
    [ngClass]="{ active: visualSeleccionada === 2 }"
    (click)="activarVista(2)"
  >
    Vista Avanzada
  </button>
  <button
    class="btn customButton p-2 mt-2 mb-2"
    [ngClass]="{ active: visualSeleccionada === 3 }"
    (click)="activarVista(3)"
  >
    Vista SLA
  </button>
</div>
<p-table
  id="tabla-peticiones"
  #dt1
  [value]="peticiones"
  dataKey="idPeticion"
  styleClass="p-datatable-responsive-demo"
  [columns]="cols"
  [paginator]="true"
  [rows]="rows"
  [totalRecords]="peticionesTotales"
  [showCurrentPageReport]="true"
  currentPageReportTemplate="Página {{ paginaActual }} de {{ paginasTotales }}"
  [globalFilterFields]="cols"
  sortField="idPeticion"
  [sortOrder]="1"
  [rowsPerPageOptions]="peticionesPorPagina"
  [lazy]="true"
  (onLazyLoad)="nextPage($event, dt1)"
  [(first)]="first"
>
  <ng-template pTemplate="caption" class="caption"
    ><button class="btn customButton boton-excel" (click)="exportarDatos()">
      <i class="fa fa-file-excel-o" aria-hidden="true"></i> EXPORTAR
    </button>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <ng-container *ngFor="let col of cols; let i = index">
        <th
          *ngIf="col.visible"
          scope="{{ col.field }}"
          pSortableColumn="{{ col.field }}"
          [pTooltip]="col.toolTip"
          [style]="col.style"
        >
          <div class="p-d-flex p-jc-between p-ai-center">
            {{ col.header }}
            <p-sortIcon
              *ngIf="col.sortable"
              field="{{ col.field }}"
            ></p-sortIcon>
          </div>
        </th>
        <ng-template #accionesHeader>
          <th
            *ngIf="col.visible"
            scope="{{ col.field }}"
            id="accionesHeader"
            [style]="col.style"
          >
            <div class="p-d-flex p-jc-between p-ai-center">
              {{ col.header }}
            </div>
          </th>
        </ng-template>
      </ng-container>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-peticionActuacion let-i="rowIndex">
    <tr>
      <td>
        <span class="p-column-title">ID</span>
        <div>
          {{ peticionActuacion.idPeticion }}
          <i
            class="fa fa-info-circle"
            style="color: #002664"
            *ngIf="peticionActuacion.informada"
            pTooltip="Petición informada"
            aria-hidden="true"
          ></i>
        </div>
      </td>
      <td>
        <span class="p-column-title">TIPO</span>
        {{ peticionActuacion.descTipoPeticion }}
      </td>
      <ng-container *ngIf="+visualSeleccionada === 1">
        <td>
          <span class="p-column-title">ESTADO</span>
          {{ peticionActuacion.descEstado }}
        </td>
        <td
          [pTooltip]="
            peticionActuacion.nombreDestinatario +
            ' ' +
            peticionActuacion.apellidoDestinatario
          "
          tooltipPosition="top"
        >
          <span class="p-column-title">DESTINATARIO</span>
          {{ peticionActuacion.idDestinatario | stringFormatPipeName }}
        </td>
        <td
          [pTooltip]="
            peticionActuacion.nombrePeticionario +
            ' ' +
            peticionActuacion.apellidoPeticionario
          "
          tooltipPosition="top"
        >
          <span class="p-column-title">SOLICITANTE</span>
          {{ peticionActuacion.idPeticionario | stringFormatPipeName }}
        </td>
        <td>
          <span class="p-column-title">FECHA SOLICITUD</span>
          {{ peticionActuacion.fechaSolicitud | dateFormatPipeYear }}
        </td>
      </ng-container>
      <ng-container *ngIf="+visualSeleccionada === 2">
        <td>
          <span class="p-column-title">ÁREA DESTINO</span>
          {{ peticionActuacion.areaDestino }}
        </td>
        <td>
          <span class="p-column-title">PROVINCIA</span>
          {{ peticionActuacion.descProvincia }}
        </td>
      </ng-container>
      <ng-container
        *ngIf="+visualSeleccionada === 3 && peticionActuacion.calcularSLA === 1"
      >
        <td>
          <span class="p-column-title">TIEMPO CONSUMIDO RESPUESTA</span>
          <span class="containerTimeSLA">
            <span class="mr-2"
              >{{
                peticionActuacion.tiempoConsumidoRespuesta | hoursToDaysPipe
              }}
              d</span
            >
            <p-progressBar
              [value]="peticionActuacion.porcentajeRespuestaSLA"
              class="progressBarContainer"
              [ngClass]="{
                'progressBar-green':
                  peticionActuacion.porcentajeRespuestaSLA < 50,
                'progressBar-yellow':
                  peticionActuacion.porcentajeRespuestaSLA > 50 &&
                  peticionActuacion.porcentajeRespuestaSLA < 100,
                'progressBar-red':
                  peticionActuacion.porcentajeRespuestaSLA > 100
              }"
            ></p-progressBar>
            <span class="ml-2"
              >{{
                peticionActuacion.tiempoTotalRespuesta | hoursToDaysPipe
              }}
              d</span
            >
          </span>
        </td>
        <td>
          <span class="p-column-title">TIEMPO CONSUMIDO RESOLUCIÓN</span>
          <span class="containerTimeSLA">
            <span class="mr-2"
              >{{
                peticionActuacion.tiempoConsumidoResolucion | hoursToDaysPipe
              }}
              d</span
            >
            <p-progressBar
              [value]="peticionActuacion.porcentajeResolucionSLA"
              class="progressBarContainer"
              [ngClass]="{
                'progressBar-green':
                  peticionActuacion.porcentajeResolucionSLA < 50,
                'progressBar-yellow':
                  peticionActuacion.porcentajeResolucionSLA > 50 &&
                  peticionActuacion.porcentajeResolucionSLA < 100,
                'progressBar-red':
                  peticionActuacion.porcentajeResolucionSLA > 100
              }"
            ></p-progressBar>
            <span class="ml-2"
              >{{
                peticionActuacion.tiempoTotalResolucion | hoursToDaysPipe
              }}
              d</span
            >
          </span>
        </td>
      </ng-container>
      <ng-container
        *ngIf="+visualSeleccionada === 3 && peticionActuacion.calcularSLA === 0"
      >
        <td>
          <span class="p-column-title">TIEMPO CONSUMIDO RESPUESTA</span>
          <div class="progress contentProgressBar progressBar-size">
            <div
              role="progressbar "
              class="progress-bar progress-bar-striped progressBar-gray"
            ></div>
          </div>
        </td>
        <td>
          <span class="p-column-title">TIEMPO CONSUMIDO RESOLUCIÓN</span>
          <div class="progress contentProgressBar progressBar-size">
            <div
              role="progressbar "
              class="progress-bar progress-bar-striped progressBar-gray"
            ></div>
          </div>
        </td>
      </ng-container>
      <td class="text-center acciones">
        <ng-container *ngFor="let accion of peticionActuacion.acciones">
          <button
            pTooltip="{{ accion.tooltip }}"
            [class]="accion.clases"
            [ngClass]="{ red: peticionActuacion.fechaArtica === null }"
            (click)="ejecutarAccion(accion.id, peticionActuacion.idPeticion)"
          >
            <em class="{{ accion.icono }}"></em>
          </button>
        </ng-container>
      </td>
    </tr>
    <hr class="item-list-separator" />
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="7">No hay registros</td>
    </tr>
  </ng-template>
  <ng-template pTemplate="paginatorleft"> </ng-template>
  <ng-template pTemplate="paginatorright">
    <p class="pageFooterLeft">Peticiones totales: {{ peticionesTotales }}</p>
  </ng-template>
</p-table>

<button class="close" mat-button (click)="close()">X</button>
<div class="pb-2 pr-3 pl-3">
  <h2 mat-dialog-title>{{ data.title }}</h2>
  <hr />
  <mat-dialog-content class="">
    <div *ngIf="data.isListInfo; else dataSimple">
      <ul class="info">
        <li *ngFor="let item of data.msg">{{ item }}</li>
      </ul>
    </div>
    <ng-template #dataSimple>
      {{ data.msg }}
    </ng-template>
  </mat-dialog-content>

  <mat-dialog-actions class="d-flex justify-content-center mt-3">
    <button
      *ngIf="!data.buttons || data.buttons.length === 0; else printButtons"
      class="mat-raised-button btn btn-primary"
      (click)="close()"
    >
      Aceptar
    </button>
  </mat-dialog-actions>
</div>

<ng-template #printButtons>
  <button
    *ngFor="let button of data.buttons"
    [class]="'mr-2 mat-raised-button btn ' + button.class"
    (click)="button.action ? accion(button.action) : close()"
  >
    {{ button.title }}
  </button>
</ng-template>

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Constantes } from 'src/app/utils/constantes';

@Injectable({
  providedIn: 'root',
})
export class Slaservice {
  constructor(private http: HttpClient, private constantes: Constantes) {}

  public obtenerSlas(): Observable<any> {
    const url: string = this.constantes.ENDPOINT_API_SLA;
    return this.http.get(url);
  }

  public exportar(): Observable<any> {
    return this.http.get(
      this.constantes.ENDPOINT_API_EXPORTAR + '/exportar-sla'
    );
  }

  public exportarPrioridades(): Observable<any> {
    return this.http.get(
      this.constantes.ENDPOINT_API_EXPORTAR + '/exportar-prioridades'
    );
  }
}

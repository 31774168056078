import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Constantes } from 'src/app/utils/constantes';
import { HistoricoContrato } from 'src/app/modelos/historico-contrato';

@Injectable({
  providedIn: 'root'
})
export class ApiSgcService {

  constructor(private http: HttpClient, private constantes: Constantes) {}

  public obtenerSecuenciales(dni: string): Observable<HistoricoContrato[]> {
    const url = this.constantes.ENDPOINT_API_SGC_HISTORICO_CONTRATO.replace('{dni}', dni);
    return this.http.get<HistoricoContrato[]>(url);
  }

  public exportar(datos: HistoricoContrato[]): Observable<any> {
    return this.http.post(this.constantes.ENDPOINT_API_EXPORTAR + '/exportar-secuenciales', datos);
  }
}

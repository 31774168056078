import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Constantes } from '../../utils/constantes';
import { Observable } from 'rxjs';
import { Estado } from '../../modelos/estado';

@Injectable({
  providedIn: 'root'
})
export class EstadosService {

  constructor(
    private http: HttpClient,
    private constantes: Constantes) { }

  getEstados(): Observable<Estado[]> {
  return this.http.get<Estado[]>(this.constantes.ENDPOINT_API_ESTADOS);
  }

  public exportar(): Observable<any> {
    return this.http.get(this.constantes.ENDPOINT_API_EXPORTAR + '/exportar-estados');
  }
}

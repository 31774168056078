import { Component, OnInit, ViewChild, Injector } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Peticion } from 'src/app/modelos/peticion';
import { Usuario } from 'src/app/modelos/usuario';
import { PeticionService } from '../../../../servicios/peticion/peticion.service';
import { UsuariosService } from '../../../../servicios/usuarios/usuarios.service';
import { SesionService } from '../../../../servicios/sesion/sesion.service';
import { MensajeComponent } from 'src/app/componentes/mensaje/mensaje.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { LineasVenta } from '../../../../modelos/lineas-venta';
import { forkJoin } from 'rxjs';
import { ConveniosService } from '../../../../servicios/convenios/convenios.service';
import { Convenio } from '../../../../modelos/convenio';
import { Constantes } from 'src/app/utils/constantes';
import { CatalogosService } from '../../../../servicios/catalogos/catalogos.service';
import { Catalogo } from '../../../../modelos/catalogo';
import { LineasVentaService } from '../../../../servicios/lineas-venta/lineas-venta.service';
import { Actividades } from '../../../../modelos/actividades';
import { Empresa } from '../../../../modelos/empresa';
import { Ambito } from '../../../../modelos/ambito';
import { Zona } from '../../../../modelos/zona';
import { TerritoriosService } from '../../../../servicios/territorios/territorios.service';
import { Region } from '../../../../modelos/region';
import { GrupossoporteService } from '../../../../servicios/grupossoporte/grupossoporte.service';
import { GruposSoporte } from '../../../../modelos/grupossoporte';
import { Router } from '@angular/router';
import { Provincia } from '../../../../modelos/provincia';
import Utils from '../../../../utils/utils';
import { UtilsForms } from '../../../../utils/utils-forms';
import { UsuarioSimple } from '../../../../modelos/usuario';
import { ModalNotificarComponent } from '../../../modals/modal-notificar/modal-notificar.component';
import { Urgencia } from '../../../../modelos/urgencia';
import { UrgenciasService } from '../../../../servicios/urgencias/urgencias.service';

declare let $: any;

@Component({
  selector: 'app-crear-peticion-identificacion',
  templateUrl: './crear-peticion-identificacion.component.html',
  styleUrls: ['./crear-peticion-identificacion.component.scss'],
})
export class CrearPeticionIdentificacionComponent implements OnInit {
  peticion!: Peticion;
  public formulario!: FormGroup;
  public fechaTopeForm!: Date;
  public modificable = false;
  public dni!: string;
  public nombre!: string;
  public apellido!: string;
  public mail!: string;
  public cat?: number;

  activeIndex = 0;
  usuario!: Usuario;
  usuarioDest!: Usuario;
  public ruta = 'peticiones/crear/observaciones';

  @ViewChild('mensaje', { static: false })
  public mensaje!: MensajeComponent;

  @ViewChild('modalnotificar', { static: false })
  public modalnotificar!: ModalNotificarComponent;

  listaLineasVenta: LineasVenta[] = [];
  listaConvenios: Convenio[] = [];
  listaAreasDestino: string[] = [];
  listaTipoPeticiones: Catalogo[] = [];
  listaProvincias: Provincia[] = [];
  listaActividades: Actividades[] = [];
  listaEmpresas: Empresa[] = [];
  listaAmbitos: Ambito[] = [];
  listaZonas: Zona[] = [];
  listaRegiones: Region[] = [];
  listaGruposSoporte: GruposSoporte[] = [];
  listaDestinatarios: UsuarioSimple[] = [];
  listaUrgencias: Urgencia[] = [];
  mostraFechaResolucion = false;
  esMenorFechaHoy = false;

  mofificarEnPendienteValoracion = false;
  clienteAfectado = false;

  public peticionService!: PeticionService;
  private usuarioService!: UsuariosService;
  private sessionService!: SesionService;
  private spinner!: NgxSpinnerService;
  private convenioService!: ConveniosService;
  private catalogoService!: CatalogosService;
  private lineaVentaService!: LineasVentaService;
  private territorrioService!: TerritoriosService;
  private gruposSoporteService!: GrupossoporteService;
  private urgenciaService!: UrgenciasService;
  private utilsforms!: UtilsForms;
  private catalogo!: Catalogo | undefined;

  private listaInputOB = [
    'email',
    'dnitrabajador',
    'nombretrabajador',
    'apellidostrabajador',
    'cliente',
  ];

  private listaDropdownOB = [
    'area',
    'tipopeticion',
    'lineasVentas',
    'actividades',
    'empresa',
    'ambitos',
    'zonas',
    'region',
    'provincias',
    'gruposoporte',
    'destinatariosolicitud',
    'email',
    'dnitrabajador',
    'nombretrabajador',
    'apellidostrabajador',
    'urgencia',
    'cliente',
  ];

  private automatismoNoAutorizados = ['820.70', '820.30', '820.69'];

  constructor(
    private fb: FormBuilder,
    private inject: Injector,
    public constantes: Constantes,
    private router: Router
  ) {
    this.initServiceProperties();
  }

  ngOnInit(): void {
    this.modificable = this.peticionService.esModificable();
    this.usuario = this.sessionService.getUser(this.spinner);
    this.listaLineasVenta = this.usuario.lineasVentas as LineasVenta[];
    this.listaProvincias = this.usuario.provincias as Provincia[];
    this.comprobarAreaDestino();
    this.cargarFormulario();
    this.inicializarCamposTrabajadorAfectado();
  }

  comprobarUsuarioAreaDestino(): boolean {
    let valido = false;
    if (
      (this.usuario.tipoSolicitante?.id === 2 && this.usuario.rol?.id === 1) ||
      this.usuario.rol?.id === 2 ||
      this.usuario.rol?.id === 6
    ) {
      valido = true;
    }
    return valido;
  }

  public comprobarAreaDestino(): void {
    if (
      this.comprobarUsuarioAreaDestino() &&
      this.constantes.listaAreaDestino.length < 3
    ) {
      this.constantes.listaAreaDestino.push('Central P.E.');
    }
  }

  public comprobarClienteProyecto(catalogo: Catalogo): void {
    if (catalogo.clienteAfectado === 1) {
      this.formulario.controls.cliente.setValidators([
        Validators.maxLength(100),
        Validators.required,
      ]);
      this.clienteAfectado = true;
    } else {
      this.formulario.controls.cliente.setValue('');
      this.formulario.controls.cliente.setValidators([
        Validators.maxLength(100),
      ]);
      this.formulario.controls.cliente.setErrors(null);
      this.clienteAfectado = false;
    }
    this.formulario.controls.cliente.updateValueAndValidity();
  }

  public toUpperCase(event: any): void {
    this.formulario.controls.dnitrabajador.setValue(
      event.target.value.toUpperCase()
    );
  }

  public catalogoPeticionTrabajadorAfectado(): boolean {
    return this.catalogo?.trabajadorAfectado === 1;
  }

  public marcarcadoTrabajadorAfectado(): boolean {
    return this.formulario?.get('checktrabajadorafectado')?.value;
  }

  public campoTrabajadorAfectadoRelleno(): boolean {
    return (
      this.formulario?.get('dnitrabajador')?.value ||
      this.formulario?.get('nombretrabajador')?.value ||
      this.formulario?.get('apellidostrabajador')?.value
    );
  }

  public inicializarCamposTrabajadorAfectado(): void {
    if (this.catalogoPeticionTrabajadorAfectado()) {
      this.formulario.get('checktrabajadorafectado')?.setValue(true);
      this.formulario.controls.checktrabajadorafectado.disable();
      this.cambioTrabajadorAfectado();
    } else {
      if (this.campoTrabajadorAfectadoRelleno()) {
        this.formulario.get('checktrabajadorafectado')?.setValue(true);
      } else {
        this.formulario.get('checktrabajadorafectado')?.setValue(false);
      }
      this.formulario.controls.checktrabajadorafectado.enable();
      this.cambioTrabajadorAfectado();
    }
    this.formulario.updateValueAndValidity();
  }

  private configurarCamposTrabajadorAfectado(): void {
    if (this.marcarcadoTrabajadorAfectado()) {
      this.formulario.controls.nombretrabajador.setValidators(
        Validators.required
      );
      this.formulario.controls.apellidostrabajador.setValidators(
        Validators.required
      );
      this.formulario.controls.dnitrabajador.setValidators([
        Validators.pattern('^([0-9]{8}[A-Z])|[XYZ][0-9]{7}[A-Z]$'),
        Validators.required,
      ]);
    } else {
      this.formulario.controls.dnitrabajador.setValue('');
      this.formulario.controls.nombretrabajador.setValue('');
      this.formulario.controls.apellidostrabajador.setValue('');
      this.formulario.controls.email.setValue('');
      this.formulario.controls.nombretrabajador.setValidators([]);
      this.formulario.controls.apellidostrabajador.setValidators([]);
      this.formulario.controls.dnitrabajador.setValidators([]);
      this.formulario.controls.nombretrabajador.setErrors(null);
      this.formulario.controls.apellidostrabajador.setErrors(null);
      this.formulario.controls.dnitrabajador.setErrors(null);
    }
  }

  private mostrarError(error: any): void {
    this.mensaje.showError(error);
    this.spinner.hide();
  }

  initServiceProperties(): void {
    this.peticionService = this.inject.get<PeticionService>(PeticionService);
    this.usuarioService = this.inject.get<UsuariosService>(UsuariosService);
    this.sessionService = this.inject.get<SesionService>(SesionService);
    this.spinner = this.inject.get<NgxSpinnerService>(NgxSpinnerService);
    this.convenioService = this.inject.get<ConveniosService>(ConveniosService);
    this.catalogoService = this.inject.get<CatalogosService>(CatalogosService);
    this.lineaVentaService =
      this.inject.get<LineasVentaService>(LineasVentaService);
    this.territorrioService =
      this.inject.get<TerritoriosService>(TerritoriosService);
    this.gruposSoporteService =
      this.inject.get<GrupossoporteService>(GrupossoporteService);
    this.urgenciaService = this.inject.get<UrgenciasService>(UrgenciasService);
    this.utilsforms = this.inject.get<UtilsForms>(UtilsForms);
  }

  private cargarConvenios(): void {
    this.urgenciaService.getUrgencias().subscribe((result) => {
      this.listaUrgencias = result;
      if (this.peticion) {
        this.formulario.controls.urgencia.setValue(
          this.getUrgencia(this.peticion?.urgencia || 0)
        );
      } else {
        this.formulario.controls.urgencia.setValue(this.listaUrgencias[0]);
      }
      this.formulario.updateValueAndValidity();
    });
    if (this.peticion) {
      if (!this.modificable && this.peticion?.convenio) {
        this.listaConvenios.push(this.peticion?.convenio);
        this.formulario.get('convenio')?.setValue(this.peticion?.convenio.id);
      } else {
        this.convenioService
          .getConvenios(
            this.peticion.actividad?.id as number,
            this.peticion.provincia?.id as number
          )
          .subscribe((result) => {
            this.listaConvenios = result;
            if (this.peticion?.convenio) {
              this.formulario
                .get('convenio')
                ?.setValue(this.getConvenioActivo());
            }
          });
      }
    }
  }

  private getConvenioActivo(): any {
    const convenioActivo = this.listaConvenios.find((convenio) => {
      return convenio.id === this.peticion?.convenio?.id;
    });

    return convenioActivo ? convenioActivo.id : null;
  }

  private getDNITrabajadorAfectadoValidators(): Validators {
    if (this.catalogoPeticionTrabajadorAfectado()) {
      return [
        Validators.pattern('^([0-9]{8}[A-Z])|[XYZ][0-9]{7}[A-Z]$'),
        Validators.required,
      ];
    } else {
      return [Validators.pattern('^([0-9]{8}[A-Z])|[XYZ][0-9]{7}[A-Z]$')];
    }
  }

  private cargarFormulario(): void {
    this.peticion = this.peticionService.getPeticion();
    this.existeFechaResolucion();
    let clienteP = false;
    if (this.peticion && this.peticion.catalogo) {
      clienteP = this.peticion.catalogo?.clienteAfectado === 1 ? true : false;
    }
    this.clienteAfectado = clienteP;
    this.formulario = this.fb.group({
      lineasVentas: [
        this.peticion?.lineaVenta as LineasVenta,
        Validators.required,
      ],
      actividades: [
        { value: this.peticion?.actividad as Actividades, disabled: true },
        Validators.required,
      ],
      empresa: [
        { value: this.peticion?.empresa as Empresa, disabled: true },
        Validators.required,
      ],
      provincias: [this.peticion?.provincia as Provincia, Validators.required],
      region: [
        { value: this.peticion?.region, disabled: true },
        Validators.required,
      ],
      ambitos: [
        { value: this.peticion?.ambito, disabled: true },
        Validators.required,
      ],
      zonas: [
        { value: this.peticion?.zona, disabled: true },
        Validators.required,
      ],
      convenio: this.peticion?.convenio as Convenio,

      checktrabajadorafectado: false,
      dnitrabajador: [
        this.peticion?.dniTrabajador,
        this.getDNITrabajadorAfectadoValidators(),
      ],
      nombretrabajador: [
        this.peticion?.nombreTrabajador,
        this.marcarcadoTrabajadorAfectado() ? Validators.required : null,
      ],
      apellidostrabajador: [
        this.peticion?.apellidosTrabajador,
        this.marcarcadoTrabajadorAfectado() ? Validators.required : null,
      ],
      email: [
        this.peticion?.emailTrabajador,
        [Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,5}$')],
      ],
      cliente: [
        this.peticion?.cliente,
        clienteP
          ? [Validators.maxLength(100), Validators.required]
          : [Validators.maxLength(100)],
      ],
      denominacion: [
        this.peticion?.denominacionServicio,
        [Validators.maxLength(100)],
      ],

      dni: [
        { value: this.usuario?.dni, disabled: true },
        [
          Validators.pattern('^([0-9]{8}[A-Z])|[XYZ][0-9]{7}[A-Z]$'),
          Validators.required,
        ],
      ],
      puesto: [
        { value: this.usuario.puesto?.descripcion, disabled: true },
        Validators.required,
      ],
      nombre: [
        {
          value: this.usuario?.nombre + ' ' + this.usuario?.apellido,
          disabled: true,
        },
        Validators.required,
      ],
      tipo: [
        { value: this.usuario?.tipoSolicitante?.descripcion, disabled: true },
        Validators.required,
      ],
      area: [this.peticion?.areaDestino, Validators.required],
      tipopeticion:
        this.peticion && this.peticion.catalogo
          ? [
              { value: this.peticion.catalogo, disabled: false },
              Validators.required,
            ]
          : [{ value: '', disabled: true }, Validators.required],
      urgencia: [this.listaUrgencias[0], Validators.required],
      gruposoporte: [
        { value: this.peticion?.grupoSoporte, disabled: true },
        Validators.required,
      ],
      destinatariosolicitud: [
        this.peticion?.usuarioDestinatario
          ? ({
              email: this.peticion?.usuarioDestinatario?.email,
              nombre: this.peticion.usuarioDestinatario?.nombre,
              apellido: this.peticion.usuarioDestinatario?.apellido,
            } as UsuarioSimple)
          : { value: '', disabled: true },
        Validators.required,
      ],

      fechatope: [this.peticion?.fechaResolucion ? this.fechaTopeForm : null],
      destinatario: [
        this.peticion?.usuarioDestinatario?.nombre +
          ' ' +
          this.peticion?.usuarioDestinatario?.apellido,
      ],
    });
    this.cargarConvenios();
    if (this.peticion) {
      this.catalogo = this.peticion?.catalogo;
      this.formulario.controls.urgencia.setValue(
        this.getUrgencia(this.peticion.urgencia || 0)
      );
      this.formulario.controls.urgencia.updateValueAndValidity();
      this.cargarListas();
    }
  }

  existeFechaResolucion(): void {
    if (this.peticion && this.peticion.catalogo?.fechaResolucion === 1) {
      this.mostraFechaResolucion = true;
    }
    if (this.peticion && this.peticion.fechaResolucion) {
      this.fechaTopeForm = new Date(this.peticion.fechaResolucion);
    }
  }

  esPeticionEnPendienteValoracionModificable(): void {
    if (
      !this.peticionService.esModificable() &&
      this.peticionService.getRutaModificable()
    ) {
      this.mofificarEnPendienteValoracion = true;
      if (
        this.usuario.rol?.id === 1 ||
        this.usuario.rol?.id === 2 ||
        this.usuario.rol?.id === 6
      ) {
        this.formulario.get('destinatariosolicitud')?.enable();
      }
      this.formulario.get('dnitrabajador')?.enable();
      this.formulario.get('nombretrabajador')?.enable();
      this.formulario.get('apellidostrabajador')?.enable();
      this.formulario.get('email')?.enable();
      if (!this.catalogoPeticionTrabajadorAfectado()) {
        this.formulario.get('checktrabajadorafectado')?.enable();
      }
    }
  }

  getUrgencia(id: number): any {
    for (const urgencia of this.listaUrgencias) {
      if (urgencia.id === id) {
        return urgencia;
      }
    }
  }

  private cargarListas(): void {
    this.spinner.show();
    const llamadas = [];
    llamadas.push(
      this.catalogoService.obtenerTiposPeticiones(this.peticion.areaDestino)
    );
    if (this.peticion.lineaVenta) {
      llamadas.push(
        this.lineaVentaService.obtenerDetallesLineaVenta(
          this.peticion.lineaVenta.id
        )
      );
    }
    if (this.peticion.provincia) {
      llamadas.push(
        this.territorrioService.obtenerDetalleProvincia(
          String(this.peticion.provincia.id),
          this.sessionService.getUser().email
        )
      );
    }
    if (this.peticion.catalogo) {
      llamadas.push(
        this.gruposSoporteService.getGruposSoporte(
          '0',
          String(Number.MAX_SAFE_INTEGER),
          'descripcion',
          'desc',
          this.sessionService.getUser().rol?.id,
          this.peticion.catalogo.id
        )
      );
    }
    if (
      this.peticion.zona &&
      this.peticion.ambito &&
      this.peticion.provincia &&
      this.peticion.grupoSoporte &&
      this.peticion.lineaVenta &&
      this.peticion.actividad
    ) {
      llamadas.push(
        this.usuarioService.buscarDestinatarios(
          this.peticion.zona.id,
          this.peticion.ambito.id,
          0,
          this.peticion.provincia.id,
          this.peticion.grupoSoporte.id as number,
          this.peticion.lineaVenta.id,
          this.peticion.actividad.id
        )
      );
    }
    if (
      this.peticion.region &&
      this.peticion.provincia &&
      this.peticion.grupoSoporte &&
      this.peticion.lineaVenta &&
      this.peticion.actividad
    ) {
      llamadas.push(
        this.usuarioService.buscarDestinatarios(
          0,
          0,
          this.peticion.region.id,
          this.peticion.provincia.id,
          this.peticion.grupoSoporte.id as number,
          this.peticion.lineaVenta.id,
          this.peticion.actividad.id
        )
      );
    }

    if (this.peticion.provincia && this.peticion.lineaVenta) {
      this.listaProvincias.push(this.peticion.provincia);
      this.listaLineasVenta.push(this.peticion.lineaVenta);
    }
    this.formulario.controls.provincias.setValue(this.peticion.provincia);
    this.formulario.controls.lineasVentas.setValue(this.peticion.lineaVenta);

    forkJoin(llamadas).subscribe(
      (resp) => {
        this.listaTipoPeticiones = resp[0];
        this.listaActividades = resp[1].actividades;
        this.listaEmpresas = resp[1].empresas;
        this.listaRegiones = resp[2].regiones;
        this.listaAmbitos = resp[2].ambitos;
        this.listaGruposSoporte = resp[3].results;
        this.listaDestinatarios = resp[4];

        if (this.peticion.ambito) {
          const zona = resp[2].ambitos.find(
            (ambito: any) =>
              this.peticion.ambito && ambito.id === this.peticion.ambito.id
          ).zona;
          this.listaZonas = [zona];
        }
        this.formulario.clearValidators();
        if (this.peticionService.esModificable()) {
          this.habilitarCampos();
        } else {
          this.formulario.disable();
          this.formulario.controls.tipo.disable();
        }
        this.formulario.updateValueAndValidity();
        this.calcularSiTrabajador();
        this.esPeticionEnPendienteValoracionModificable();
      },
      (error) => {
        this.mostrarError(error);
      },
      () => {
        this.spinner.hide();
      }
    );
  }

  private habilitarCampos(): void {
    this.formulario.controls.actividades.enable();
    this.formulario.controls.empresa.enable();
    this.formulario.controls.ambitos.enable();
    this.formulario.controls.zonas.enable();
    this.formulario.controls.gruposoporte.enable();
  }

  private calcularSiTrabajador(): void {
    const ok =
      Utils.isInformedString(this.peticion.dniTrabajador || '') ||
      Utils.isInformedString(this.peticion.nombreTrabajador || '') ||
      Utils.isInformedString(this.peticion.apellidosTrabajador || '');
    this.formulario.controls.checktrabajadorafectado.setValue(
      ok || Utils.isInformedString(this.peticion.emailTrabajador || '')
    );
  }

  obtenerFormValidacion(valor: any, validacion: boolean): any {
    if (validacion) {
      return [{ value: valor, disabled: validacion }, Validators.required];
    } else {
      return [{ value: valor, disabled: validacion }];
    }
  }

  guardarIdentificacion(): void {
    this.validarCampoyMarcar();
    this.peticion = this.peticionService.getPeticion();
    if (!this.peticion && this.formulario.valid) {
      this.ruta = 'peticiones/crear/identificacion';
      this.mensaje.showConfirmacionPeticion(
        '¿Desea guardar la petición en Modo Borrador?'
      );
    } else if (!this.formulario.valid) {
      this.mensaje.showErrorPeticion(
        'Revisa la petición, hay campos incorrectos o incompletos'
      );
    } else {
      if (this.peticion && this.formulario.dirty) {
        this.cambioObjectoRequest();
        this.actualizarPeticionForm('peticiones/crear/identificacion');
      } else {
        this.mensaje.showMensaje('Está todo actualizado.');
      }
    }
  }

  private actualizarDestinatarioPeticionForm(): void {
    if (this.peticion.idPeticion) {
      this.dni = this.formulario.get('dnitrabajador')?.value;
      this.nombre = this.formulario.get('nombretrabajador')?.value;
      this.apellido = this.formulario.get('apellidostrabajador')?.value;
      this.mail = this.formulario.get('email')?.value;
      let cambio = this.cambioDestinatario();
      const nuevoDestinatario = this.formulario.get('destinatariosolicitud')
        ?.value as Usuario;
      this.modalnotificar.mostrarModalEditar(
        this.peticion.idPeticion,
        this.constantes.ACCION_EDITAR,
        'Cambiar destinatario',
        nuevoDestinatario,
        [this.dni, this.nombre, this.apellido, this.mail, cambio]
      );
    }
  }

  private actualizarPeticionForm(ruta: string): void {
    this.spinner.show();
    this.peticionService
      .actualizarPeticion(this.peticionService.getPeticion())
      .subscribe(
        (resPeticion) => {
          this.spinner.hide();
          this.peticionService.setPeticion(resPeticion);
          this.mensaje.showMensaje('Petición actualizada correctamente.');
          if (ruta) {
            this.router.navigate([ruta]);
          }
        },
        (error: any) => {
          this.spinner.hide();
          this.mensaje.showError(
            'No se ha podido guardar la petición.' + error.error
          );
        }
      );
  }

  nextPage(): void {
    this.peticion = this.peticionService.getPeticion();

    let dni: String = this.formulario.get('dnitrabajador')?.value;
    let email: String = this.formulario.get('email')?.value;
    let catalogo: Catalogo = this.formulario.get('tipopeticion')?.value;
    let peticionario: Usuario = this.sessionService.getUser();

    if (!this.peticion) {
      this.validarCampoyMarcar();
      this.spinner.show();
      if (
        (dni === peticionario.dni || email === peticionario.email) &&
        this.automatismoNoAutorizados.includes(catalogo.id!)
      ) {
        this.mensaje.showErrorPeticion(
          'ERROR: Contacte con el departamento de RRHH. Los datos de usuario peticionario y trabajador afectado no pueden coincidir.'
        );
      } else if (this.formulario.valid) {
        this.mensaje.showConfirmacionPeticion(
          '¿Desea guardar la petición en Modo Borrador?'
        );
      } else {
        this.mensaje.showErrorPeticion(
          'Revisa la petición, hay campos incorrectos o incompletos'
        );
      }
      this.spinner.hide();
    }

    if (this.peticion) {
      this.flujoPeticionExisteCambioPagina();
    }
  }

  private flujoPeticionExisteCambioPagina(): void {
    console.log('flujoPeticionExisteCambioPagina');
    if (!this.formulario.valid && this.formulario.dirty) {
      this.mensaje.showErrorPeticion(
        'Revisa la petición, hay campos incorrectos o incompletos'
      );
    } else {
      this.cambioObjectoRequest();
      console.log('this.mofificarEnPendienteValoracion');
      console.log(this.mofificarEnPendienteValoracion);
      if (this.mofificarEnPendienteValoracion) {
        this.flujoAccionModificar();
      } else {
        if (this.peticionService.esModificable()) {
          this.actualizarPeticionForm('peticiones/crear/observaciones');
        } else {
          this.router.navigate(['peticiones/crear/observaciones']);
        }
      }
    }
  }

  private flujoAccionModificar(): void {
    if (
      this.cambioDestinatario() ||
      this.cambioDniTrabajador() ||
      this.cambioNombreTrabajador() ||
      this.cambioApellidoTrabajador() ||
      this.cambioEmailTrabajador()
    ) {
      this.actualizarDestinatarioPeticionForm();
    } else {
      this.router.navigate(['peticiones/crear/observaciones']);
    }
  }

  validarCampoyMarcar(): void {
    for (const campo of this.listaDropdownOB) {
      if (!this.formulario.get(campo)?.valid) {
        const tipo = this.listaInputOB.includes(campo) ? 'input' : 'dropdown';
        this.utilsforms.ponerEstiloObligatorio($, '#' + campo, tipo);
      } else {
        this.quitarEstiloOB(campo);
      }
    }
  }

  public onConfirmModal(event: any, rutaConfirm?: string): void {
    this.mensaje.cerrar();
    if (event) {
      this.cambioObjectoRequest();
      this.spinner.show();
      this.peticionService
        .guardarPeticion(this.peticionService.getPeticion())
        .subscribe(
          (result: Peticion) => {
            this.spinner.hide();
            if (result) {
              this.ruta = rutaConfirm
                ? rutaConfirm
                : 'peticiones/crear/identificacion';
              this.mensaje.showMensaje(
                'La petición se ha guardado de forma correcta',
                this.ruta
              );
              this.peticionService.setPeticion(result);
            } else {
              this.mensaje.showError('No se ha podido guardar la petición');
            }
          },
          (error) => {
            this.spinner.hide();
            this.mensaje.showError(error);
          }
        );
    }
  }

  cambioObjectoRequest(): void {
    let peticion = {
      usuarioPeticionario: this.usuario,
      usuarioDestinatario: {
        email:
          this.formulario.get('destinatariosolicitud')?.value.email || null,
      } as Usuario,
      dniTrabajador: this.formulario.get('dnitrabajador')?.value,
      nombreTrabajador: this.formulario.get('nombretrabajador')?.value,
      apellidosTrabajador: this.formulario.get('apellidostrabajador')?.value,
      emailTrabajador: this.formulario.get('email')?.value,
      cliente: this.formulario.get('cliente')?.value,
      denominacionServicio: this.formulario.get('denominacion')?.value,
      lineaVenta: this.formulario.get('lineasVentas')?.value,
      actividad: this.formulario.get('actividades')?.value,
      empresa: this.formulario.get('empresa')?.value,
      provincia: this.formulario.get('provincias')?.value,
      ambito: this.formulario.get('ambitos')?.value,
      region: this.formulario.get('region')?.value,
      zona: this.formulario.get('zonas')?.value,
      catalogo: this.formulario.get('tipopeticion')?.value,
      convenio: this.formulario.get('convenio')?.value
        ? { id: this.formulario.get('convenio')?.value }
        : null,
      areaDestino: this.formulario.get('area')?.value,
      grupoSoporte: this.formulario.get('gruposoporte')?.value,
      urgencia: this.formulario.get('urgencia')?.value.id,
      fechaResolucion: this.formulario.get('fechatope')?.value,
    } as Peticion;
    if (this.peticion) {
      peticion = {
        ...peticion,
        idPeticion: this.peticion.idPeticion,
        estado: this.peticion.estado,
        descripcion: this.peticion.descripcion,
        fechaDesde: this.peticion.fechaDesde,
        fechaHasta: this.peticion.fechaHasta,
        fechaEfectividad: this.peticion.fechaEfectividad,
        usuarioDestinatario: {
          email: this.formulario.get('destinatariosolicitud')?.value.email,
          nombre: this.formulario.get('destinatariosolicitud')?.value.nombre,
          apellido: this.formulario.get('destinatariosolicitud')?.value
            .apellido,
        },
      };
    }

    if (
      this.peticion &&
      this.peticion.catalogo?.datosEfectividad !==
        peticion.catalogo?.datosEfectividad &&
      peticion.catalogo?.datosEfectividad === 0
    ) {
      peticion.fechaDesde = null;
      peticion.fechaHasta = null;
      peticion.fechaEfectividad = null;
    }
    if (
      this.peticion &&
      peticion.catalogo?.datosEfectividad === 1 &&
      peticion.catalogo.tipoFechaEfectividad === 'Fecha'
    ) {
      peticion.fechaDesde = null;
      peticion.fechaHasta = null;
    }

    if (
      this.peticion &&
      peticion.catalogo?.datosEfectividad === 1 &&
      peticion.catalogo.tipoFechaEfectividad === 'Rango'
    ) {
      peticion.fechaEfectividad = null;
    }

    if (
      this.peticion &&
      this.peticion.notificadoresLst &&
      this.peticion.notificadoresLst?.length > 0
    ) {
      peticion.notificadoresLst = this.peticion.notificadoresLst;
    }
    if (!this.mofificarEnPendienteValoracion) {
      this.peticionService.setPeticion(peticion);
    }
  }

  cambioLineaVenta(): void {
    this.spinner.show();
    const idLinea = this.formulario.get('lineasVentas')?.value.id;
    this.lineaVentaService.obtenerDetallesLineaVenta(idLinea).subscribe(
      (resp) => {
        this.listaActividades = resp.actividades;
        this.listaEmpresas = resp.empresas;
        this.formulario.get('actividades')?.enable();
        if (this.listaActividades.length > 0) {
          this.formulario
            .get('actividades')
            ?.setValue(this.listaActividades[0]);
        }
        this.formulario.get('empresa')?.enable();
        if (this.listaEmpresas.length > 0) {
          this.formulario.get('empresa')?.setValue(this.listaEmpresas[0]);
        }
        this.calcularConvenios(true);
        this.mostrarDestinatarios(false);
        this.formulario.get('actividades')?.setValue(resp.actividades[0]);
        this.formulario.get('empresa')?.setValue(resp.empresas[0]);
        this.validarCampoyMarcar();
      },
      (errorLinea) => {
        this.mensaje.showError(errorLinea);
        this.spinner.hide();
      }
    );
  }

  cambioProvincias(): void {
    this.spinner.show();
    const idProvincia = this.formulario.get('provincias')?.value.id;
    this.listaZonas = [];
    this.formulario.get('zonas')?.disable();
    this.territorrioService
      .obtenerDetalleProvincia(idProvincia, this.sessionService.getUser().email)
      .subscribe(
        (resp) => {
          this.listaAmbitos = resp.ambitos;
          this.formulario.get('ambitos')?.enable();
          this.formulario.get('ambitos')?.setValue(resp.ambitos[0]);
          if (this.listaAmbitos.length > 0) {
            this.formulario.get('ambitos')?.setValue(this.listaAmbitos[0]);
            this.listaZonas.push(this.listaAmbitos[0].zona);
            this.formulario.get('zonas')?.setValue(this.listaAmbitos[0].zona);
            this.formulario.get('zonas')?.enable();
          }

          this.calcularConvenios(true);
          this.mostrarDestinatarios(false);
          this.validarCampoyMarcar();
        },
        (errorProvincia) => {
          this.mensaje.showError(errorProvincia);
          this.spinner.hide();
        }
      );
  }

  cambioAmbitos(): void {
    this.validarCampoyMarcar();
    this.spinner.show();
    this.listaZonas = [this.formulario.get('ambitos')?.value.zona];
    this.formulario
      .get('zonas')
      ?.setValue(this.formulario.get('ambitos')?.value.zona);
    if (this.peticionService.esModificable()) {
      this.formulario.get('zonas')?.enable();
    }
    this.spinner.hide();
  }

  cambioActividad(): void {
    this.calcularConvenios(true);
  }

  cambioAreaDestinoResponse(): void {
    this.spinner.show();
    const area = this.formulario.get('area')?.value;
    console.log('area: ', area);
    this.catalogoService.obtenerTiposPeticiones(area).subscribe(
      (resp) => {
        console.log('resp: ', resp);
        this.listaTipoPeticiones = resp;
        if (this.peticionService.esModificable()) {
          this.formulario.controls.tipopeticion.enable();
        } else {
          this.formulario.disable();
        }
        this.validarCampoyMarcar();
        this.spinner.hide();
      },
      (errorCatalogo) => {
        this.mensaje.showError(errorCatalogo);
        this.spinner.hide();
      }
    );
  }

  cambioAreaDestino(): void {
    this.cambioAreaDestinoResponse();
    this.listaGruposSoporte = [];
    this.listaDestinatarios = [];
    this.formulario.controls.tipopeticion.setValue('');
    this.formulario.controls.gruposoporte.setValue(this.listaGruposSoporte);
    this.formulario.controls.gruposoporte.disable();
    this.formulario.controls.destinatariosolicitud.setValue(
      this.listaDestinatarios
    );
    this.formulario.controls.destinatariosolicitud.disable();
    this.formulario.controls.destinatario.setValue('');
  }

  cambioTipoPeticion(catalogo: Catalogo): void {
    this.spinner.show();
    this.catalogo = catalogo;
    this.inicializarCamposTrabajadorAfectado();
    this.comprobarClienteProyecto(catalogo);
    this.gruposSoporteService
      .getGruposSoporte(
        '0',
        String(Number.MAX_SAFE_INTEGER),
        'descripcion',
        'desc',
        this.sessionService.getUser().rol?.id,
        catalogo.id
      )
      .subscribe(
        (resp: any) => {
          this.listaGruposSoporte = resp.results;
          this.formulario.get('gruposoporte')?.enable();
          if (this.listaGruposSoporte.length > 0) {
            this.formulario.controls.gruposoporte.setValue(
              this.listaGruposSoporte[0]
            );
          }
          this.cambiarGrupoSoporte();
          this.mostrarFechaResolucion(catalogo);
          this.spinner.hide();
          this.validarCampoyMarcar();
        },
        (errorGrupo) => {
          this.mensaje.showError(errorGrupo);
          this.spinner.hide();
        }
      );
  }

  mostrarFechaResolucion(catalogo: Catalogo): void {
    this.esMenorFechaHoy = false;
    this.mostraFechaResolucion = false;
    if (catalogo.fechaResolucion === 1) {
      this.mostraFechaResolucion = true;
    }
    this.formulario.get('fechatope')?.setValue(null);
  }

  fechaHoyMayorFechaResolucion(): boolean {
    const fechaTope = this.formulario.get('fechatope')?.value;

    const fechaHoyDia = new Date().getDate();
    const fechaHoyMes = new Date().getMonth() + 1;
    const fechaHoyAnio = new Date().getFullYear();
    const fechaHoyString = fechaHoyAnio + '-' + fechaHoyMes + '-' + fechaHoyDia;
    const fechaHoy = new Date(fechaHoyString);
    if (fechaTope < fechaHoy) {
      this.formulario.get('fechatope')?.setValue(null);
      this.esMenorFechaHoy = true;
    } else {
      this.esMenorFechaHoy = false;
    }
    return this.esMenorFechaHoy;
  }

  cambiarGrupoSoporte(): void {
    this.spinner.show();
    this.mostrarDestinatarios(true);
  }

  calcularConvenios(hideSpinner: boolean): void {
    const idActividad = this.formulario.get('actividades')?.value?.id;
    const idProvincia = this.formulario.get('provincias')?.value?.id;
    if (idActividad !== undefined && idProvincia !== undefined) {
      this.spinner.show();
      // modificar por condición que evalue aquellas actividades que sean Departamento
      this.formulario.controls.convenio.setValue(null);
      if (this.formulario.get('lineasVentas')?.value?.id === 0) {
        this.formulario.controls.convenio.disable();
        if (hideSpinner === true) {
          this.spinner.hide();
        }
      } else {
        this.formulario.controls.convenio.enable();
        this.convenioService
          .getConvenios(idActividad, idProvincia)
          .subscribe((convenios) => {
            this.listaConvenios = convenios;
            if (hideSpinner === true) {
              this.spinner.hide();
            }
          });
      }
    } else {
      if (hideSpinner === true) {
        this.spinner.hide();
      }
    }
  }

  mostrarDestinatarios(hideSpinner: boolean): void {
    let idZona = this.formulario.get('zonas')?.value?.id;
    let idAmbito = this.formulario.get('ambitos')?.value?.id;
    let idRegion = this.formulario.get('region')?.value?.id;
    let idProvincia = this.formulario.get('provincias')?.value?.id;
    let idLinea = this.formulario.get('lineasVentas')?.value?.id;
    let idActividad = this.formulario.get('actividades')?.value?.id;
    let idGrupo = this.formulario.get('gruposoporte')?.value?.id;
    if ((idZona !== undefined && idAmbito !== undefined) || idRegion) {
      ({
        idZona,
        idAmbito,
        idRegion,
        idProvincia,
        idGrupo,
        idLinea,
        idActividad,
      } = this.comprobarValoresDestinatatio(
        idZona,
        idAmbito,
        idRegion,
        idProvincia,
        idGrupo,
        idLinea,
        idActividad
      ));
      this.usuarioService
        .buscarDestinatarios(
          idZona,
          idAmbito,
          idRegion,
          idProvincia,
          idGrupo,
          idLinea,
          idActividad
        )
        .subscribe(
          (resp: any) => {
            this.handleMostrarDestinatarios(resp, hideSpinner);
          },
          (errordestinatario: any) => {
            this.mensaje.showError(errordestinatario);
            this.formulario.controls.destinatariosolicitud.reset();
            this.formulario.controls.destinatariosolicitud.updateValueAndValidity();
            this.formulario.markAllAsTouched();
            this.spinner.hide();
          }
        );
    } else {
      this.formulario.controls.destinatariosolicitud.reset();
      this.formulario.controls.destinatariosolicitud.updateValueAndValidity();
      this.formulario.markAllAsTouched();
      this.calcularConvenios(true);
      this.validarCampoyMarcar();
      if (hideSpinner) {
        this.spinner.hide();
      }
    }
  }

  private comprobarValoresDestinatatio(
    idZona: any,
    idAmbito: any,
    idRegion: any,
    idProvincia: any,
    idGrupo: any,
    idLinea: any,
    idActividad: any
  ): any {
    idZona = idZona ? idZona : 0;
    idAmbito = idAmbito ? idAmbito : 0;
    idRegion = idRegion ? idRegion : 0;
    idProvincia = idProvincia ? idProvincia : 0;
    idGrupo = idGrupo ? idGrupo : 0;
    idLinea = idLinea ? idLinea : 0;
    idActividad = idActividad ? idActividad : 0;
    return {
      idZona,
      idAmbito,
      idRegion,
      idProvincia,
      idGrupo,
      idLinea,
      idActividad,
    };
  }

  private handleMostrarDestinatarios(resp: any, hideSpinner: boolean): void {
    if (resp) {
      this.listaDestinatarios = resp;
      if (this.peticionService.esModificable()) {
        this.formulario.controls.destinatario.reset();
        this.formulario.controls.destinatariosolicitud.reset();
        this.formulario.controls.destinatariosolicitud.enable();
      }
    } else {
      this.listaDestinatarios = [];
      this.formulario.controls.destinatariosolicitud.setValue(
        this.listaDestinatarios
      );
      this.formulario.controls.destinatario.reset();
    }

    this.formulario.controls.destinatariosolicitud.updateValueAndValidity();
    this.formulario.controls.destinatario.updateValueAndValidity();
    this.formulario.markAllAsTouched();
    this.validarCampoyMarcar();
    if (hideSpinner) {
      this.spinner.hide();
    }
  }

  cambioTrabajadorAfectado(): void {
    this.configurarCamposTrabajadorAfectado();
  }

  cambioDniTrabajador(): boolean {
    this.dni = this.formulario.get('dnitrabajador')?.value;
    let cambio = true;
    if (
      this.formulario.get('dnitrabajador')?.value ===
      this.peticion.dniTrabajador
    ) {
      cambio = false;
    }
    if (this.dni) {
      this.formulario.controls.dnitrabajador.setValue(this.dni);
    }
    return cambio;
  }

  cambioNombreTrabajador(): boolean {
    this.nombre = this.formulario?.get('nombretrabajador')?.value;
    let cambio = true;
    if (
      this.formulario.get('nombretrabajador')?.value ===
      this.peticion.nombreTrabajador
    ) {
      cambio = false;
    }
    if (this.nombre) {
      this.formulario.controls.nombretrabajador.setValue(this.nombre);
    }
    return cambio;
  }

  cambioApellidoTrabajador(): boolean {
    this.apellido = this.formulario?.get('apellidostrabajador')?.value;
    let cambio = true;
    if (
      this.formulario.get('apellidostrabajador')?.value ===
      this.peticion.apellidosTrabajador
    ) {
      cambio = false;
    }
    if (this.apellido) {
      this.formulario.controls.apellidostrabajador.setValue(this.apellido);
    }
    return cambio;
  }

  cambioEmailTrabajador(): boolean {
    this.mail = this.formulario.get('email')?.value;
    let cambio = true;
    if (this.formulario.get('email')?.value === this.peticion.emailTrabajador) {
      cambio = false;
    }
    if (this.mail) {
      this.formulario.controls.email.setValue(this.mail);
    }
    return cambio;
  }

  cambioDestinatario(): boolean {
    const email = this.formulario.get('destinatariosolicitud')?.value.email;
    const destinatario = this.listaDestinatarios.find(
      (d: any) => d.email === email
    );
    let cambio = true;
    if (
      this.formulario.get('destinatariosolicitud')?.value.email ===
      this.peticion.usuarioDestinatario?.email
    ) {
      cambio = false;
    }
    if (destinatario) {
      this.formulario.controls.destinatario.setValue(
        destinatario.nombre + ' ' + destinatario.apellido
      );
    }
    return cambio;
  }

  quitarEstiloOB(id: any): void {
    const tipo = this.listaInputOB.includes(id) ? 'input' : 'dropdown';
    this.utilsforms.quitarEstiloObligatorio($, '#' + id, tipo);
  }

  notificadores(idPeticion: string): void {
    this.peticionService.obtenerPeticion(idPeticion).subscribe((resultado) => {
      this.peticionService.setPeticion(resultado);
    });
    this.router.navigate(['peticiones/crear/observaciones']);
  }

  cancelarCambioDestinatario(): void {
    const usuario = this.peticionService.getPeticion().usuarioDestinatario;
    if (usuario) {
      this.formulario.controls.destinatariosolicitud.setValue({
        email: usuario.email,
        nombre: usuario.nombre,
        apellido: usuario.apellido,
      } as UsuarioSimple);
      this.formulario.controls.destinatario.setValue(
        usuario.nombre + ' ' + usuario.apellido
      );
    }
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Constantes } from 'src/app/utils/constantes';

@Injectable({
  providedIn: 'root'
})
export class InformesdatastudioService {

  constructor(
    private http: HttpClient,
    private constantes: Constantes
  ) { }

  public getInformesDatastudio(): Observable<any> {
    const url: string = this.constantes.ENDPOINT_API_INFORMESDATASTUDIO;
    return this.http.get(url);
  }

}

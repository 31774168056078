import { Component } from '@angular/core';
import { ConvenioFiltro } from 'src/app/modelos/convenio-filtro';

@Component({
  selector: 'app-convenios-principal',
  templateUrl: './convenios-principal.component.html',
  styleUrls: ['./convenios-principal.component.scss']
})
export class ConveniosPrincipalComponent {

  public datosDelFiltro!: ConvenioFiltro;


  recibirFiltros(event: any): any{
    this.datosDelFiltro = event;
  }
}

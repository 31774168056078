import { Component, OnInit, ViewChild } from '@angular/core';
import { PeticionService } from '../../../servicios/peticion/peticion.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { Peticion } from '../../../modelos/peticion';
import { MensajeComponent } from '../../mensaje/mensaje.component';
import { Puesto } from '../../../modelos/puesto';
import { Accion } from '../../../modelos/accion';
import { SesionService } from '../../../servicios/sesion/sesion.service';
import { forkJoin } from 'rxjs';
import { AccionPeticionConstantes } from '../../../utils/acciones-peticion-constantes';
import { ModalNotificarComponent } from '../../modals/modal-notificar/modal-notificar.component';
import { Aclaracion } from '../../../modelos/aclaracion';
import { Constantes } from 'src/app/utils/constantes';
import { ModalAutorizarComponent } from '../../modals/modal-autorizar/modal-autorizar.component';
import { ModalArticaComponent } from '../../modals/modal-artica/modal-artica.component';
import { Informacion } from '../../../modelos/informacion';

@Component({
  selector: 'app-landpage-peticion',
  templateUrl: './landpage-peticion.component.html',
  styleUrls: ['./landpage-peticion.component.css']
})
export class LandpagePeticionComponent implements OnInit {

  peticion!: Peticion;
  perfiles: Puesto[] = [];
  acciones: Accion[] = [];
  accionesDetalle: Accion[] = [];
  aclaraciones: Aclaracion[] = [];
  informaciones: Informacion [] = [];
  textoBotonVolver = '';

  accionNominaSGC:number = 34;
  accionSecuencialSGC:number = 35;

  @ViewChild('mensaje', { static: false })
  public mensaje!: MensajeComponent;

  @ViewChild('modalnotificar', { static: false })
  public modalnotificar!: ModalNotificarComponent;

  @ViewChild('modalAutorizar', { static: false })
  public modalAutorizar!: ModalAutorizarComponent;

  @ViewChild('modalArtica', { static: false })
  public modalArtica!: ModalArticaComponent;


  constructor(private peticionService: PeticionService,
              private spinner: NgxSpinnerService,
              private sesionService: SesionService,
              public constantes: Constantes,
              private accionesConstantes: AccionPeticionConstantes,
              private router: Router) { }

  ngOnInit(): void {
    if (this.peticionService.getPeticion()) {
      this.spinner.show();
      this.loadInfo();
      this.textoToolTipBoton();
    }
  }

  private loadInfo(): void{
    this.peticion = this.peticionService.getPeticion();
    this.loadServices();
  }

  private loadServices(): void{
    if (this.peticion.idPeticion && this.peticion.grupoSoporte?.id){
      this.spinner.show();
      const llamadas = [];
      llamadas.push(this.peticionService.getPerfilesNotificacion(this.peticion.idPeticion, this.peticion.grupoSoporte.id));
      llamadas.push(this.peticionService.getPeticionActuacion(this.peticion.idPeticion, this.sesionService.getUser().email));
      llamadas.push(this.peticionService.obtenerAclaraciones(this.peticion.idPeticion));
      llamadas.push(this.peticionService.obtenerInformaciones(this.peticion.idPeticion));
      forkJoin(llamadas).subscribe(resp => {
        this.perfiles = resp[0];
        this.acciones = resp[1];
        this.aclaraciones = resp[2];
        this.informaciones = resp[3];
        this.eliminarBotonAccionConTrabajadorAfectado(this.accionNominaSGC);
        this.eliminarBotonAccionConTrabajadorAfectado(this.accionSecuencialSGC);
        this.obtenerEstilosAccionesDetalle();
        this.spinner.hide();
      },
      error => {
        console.log(error);
        this.mensaje.showError(error);
        this.spinner.hide();
      });
    }
  }

  private textoToolTipBoton(): void{
    const usuario = this.sesionService.getUser();
    if (this.peticion.usuarioPeticionario?.email === usuario.email){
      this.textoBotonVolver = 'Ir a Mis peticiones';
    }else{
      this.textoBotonVolver = 'Ir a Control de Actuaciones';
    }
  }

  private eliminarBotonAccionConTrabajadorAfectado(numBoton:number): void {
    if(this.acciones) {
      this.acciones.forEach((accion: Accion, index: number) => {
        const accionReaded = this.accionesConstantes.ACCIONES.find(
          (a: Accion) => a.id === accion.id
        );
        if (accionReaded !== undefined && numBoton == accionReaded.id && this.noTrabajadorAfectado(this.peticion.dniTrabajador)) {
          this.acciones.splice(index, 1);
        }
      });
    }
  }

  private noTrabajadorAfectado(dniTrabajador:string | undefined): boolean {
    return (dniTrabajador === undefined || dniTrabajador === null || dniTrabajador === '') ? true : false;
  }

  private obtenerEstilosAccionesDetalle(): void {
    if (this.acciones) {
      this.accionesDetalle = [];
      this.acciones.forEach((accion: Accion) => {
        const accionReaded = this.accionesConstantes.ACCIONES.find((a: Accion) => a.id === accion.id);
        if (accionReaded) {
          if (accionReaded.id === this.constantes.ACCION_ARTICA && this.peticion.fechaArtica === null){
            accionReaded.clases = accionReaded.clases?.concat(' red');
          }
          this.accionesDetalle.push(accionReaded);
        }
      });
    }
  }


  public ejecutarAccion(idAccion: number): void{
    this.peticionService.setControlActuaciones(true);
    switch (idAccion){
      case this.constantes.ACCION_VISUALIZAR:
          this.router.navigate([`/peticiones/crear/confirmacion/${this.peticion.idPeticion}`]);
          break;
      case this.constantes.ACCION_EDITAR:
          this.router.navigate([`/peticiones/modificar/identificacion/${this.peticion.idPeticion}`]);
          break;
      case this.constantes.ACCION_COMENTAR:
          this.router.navigate([`/peticiones/${this.peticion.idPeticion}`]);
          break;
      case this.constantes.ACCION_ACLARACION:
          this.router.navigate([`/peticiones/${this.peticion.idPeticion}/aclaraciones`]);
          break;
      case this.constantes.ACCION_RESPONDER:
          this.mostrarModalResponder(this.peticion.idPeticion ||  '', idAccion);
          break;
      case this.constantes.ACCION_RECHAZAR:
          this.router.navigate([`/peticiones/${this.peticion.idPeticion}/cierre-peticion/13`]);
          break;
      case this.constantes.ACCION_RESOLVER_CERRAR:
          this.router.navigate([`/peticiones/${this.peticion.idPeticion}/cierre-peticion/14`]);
          break;
      case this.constantes.ACCION_SOLICITAR_AUTORIZACION:
          this.callModalAutorizar(this.peticion.idPeticion || '');
          break;
      case this.constantes.ACCION_INFORMAR:
          this.router.navigate([`/peticiones/${this.peticion.idPeticion}/informaciones`]);
          break;
      case this.constantes.ACCION_AUTORIZAR:
          this.router.navigate([`/peticiones/${this.peticion.idPeticion}/autorizaciones`]);
          break;
      case this.constantes.ACCION_ARTICA:
          this.mostrarModalArtica(this.peticion.idPeticion || '');
          break;
      case this.constantes.ACCION_AS400WEB:
          window.open("https://as400web.eulen.com/EulenP/entry", "_blank", "noopener");
          break;
      case this.constantes.ACCION_INFORMACION_EMPLEADO:
          this.router.navigate([`/informacion-empleado/${this.peticion.dniTrabajador}`]);
          break;
      default:
    }
  }

  public callModalAutorizar(idPeticion: string): void{
    this.modalAutorizar.mostrarModal(idPeticion);
  }


  public mostrarModalResponder(idPeticion: string, idAccion: number): void{
    this.modalnotificar.mostrarModalResponder(idPeticion, idAccion, 'Responder Petición', 'CURSO');
  }


  notificadores(idPeticion: string): void {
    console.log(idPeticion);
    this.loadInfo();
  }

  reload(): void{
    this.ngOnInit();
  }

  public volver(): void{
    const usuario = this.sesionService.getUser();
    if (this.peticion.usuarioPeticionario?.email === usuario.email){
      // Si se ha llamado desde Gestion de mis peticiones
      this.router.navigate(['/peticiones']);
    }else{
      // Si ha sido llamado desde el PS (Control de actuaciones)
      this.router.navigate(['/peticiones/actuaciones']);
    }
  }

  public mostrarModalArtica(idPeticion: string): void {
    this.modalArtica.mostrarModal(idPeticion);
  }

  articaRecibida(idPeticion: string): void {
    if (idPeticion){
     this.peticion.fechaArtica = new Date();
    }
  }

}

<nav id="sidebar" class="active">
  <div class="sidebar-header">
    <h3>MENÚ</h3>
  </div>
  <ul class="list-unstyled">
    <li
      id="au"
      *ngIf="
        sessionService.getPermisos() && sessionService.getPermisos().au === 1
      "
      class="nav-item espacio"
      (click)="displayMenu($event)"
    >
      <span class="titulo">Administración de Usuarios (AU)</span>
      <ul class="list-unstyled">
        <li class="nav-item" routerLinkActive="activo">
          <a
            class="nav-link"
            [routerLink]="['/usuarios']"
            (click)="resetFiltrosUsuario()"
            ><em class="fa fa-user"></em>Gestión de Usuarios</a
          >
        </li>
      </ul>
    </li>
    <li
      id="gp"
      class="nav-item espacio"
      *ngIf="
        sessionService.getPermisos() && sessionService.getPermisos().gp === 1
      "
      (click)="displayMenu($event)"
    >
      <span class="titulo">Gestión de Peticiones (GP)</span>
      <ul class="list-unstyled">
        <li
          class="nav-item"
          routerLinkActive="activo"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <a
            class="nav-link"
            [routerLink]="['/peticiones/crear/identificacion']"
            (click)="resetPeticion()"
            ><em class="fa fa-file-text-o"></em>Crear Petición</a
          >
        </li>
        <li
          class="nav-item"
          routerLinkActive="activo"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <a
            class="nav-link"
            [routerLink]="['/peticiones']"
            (click)="resetFiltrosPeticion()"
            ><em class="fa fa-files-o"></em>Gestión de mis peticiones</a
          >
        </li>
      </ul>
    </li>
    <li
      id="gp"
      class="nav-item espacio"
      *ngIf="
        sessionService.getPermisos() && sessionService.getPermisos().ps === 1
      "
      (click)="displayMenu($event)"
    >
      <span class="titulo">Panel de Seguimiento (PS)</span>
      <ul class="list-unstyled">
        <li
          class="nav-item"
          routerLinkActive="activo"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <a
            class="nav-link"
            [routerLink]="['/peticiones/actuaciones']"
            (click)="resetFiltrosActuacion()"
            ><em class="fa fa-briefcase"></em>Control de actuaciones</a
          >
        </li>
      </ul>
    </li>
    <li
      id="ga"
      class="nav-item espacio"
      *ngIf="
        sessionService.getPermisos() && sessionService.getPermisos().ga === 1
      "
      (click)="displayMenu($event)"
    >
      <span class="titulo">Gestion de auditoria (GA)</span>
      <ul class="list-unstyled">
        <li
          class="nav-item"
          routerLinkActive="activo"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <a class="nav-link" [routerLink]="['/auditoria']"
            ><em class="fa fa-files-o"></em>Peticiones</a
          >
        </li>
      </ul>
    </li>

    <li
      id="gp"
      class="nav-item espacio"
      *ngIf="
        sessionService.getPermisos() && sessionService.getPermisos().kpi === 1
      "
      (click)="displayMenu($event)"
    >
      <span class="titulo">Ind. de Rendimiento (KPI)</span>
      <ul class="list-unstyled">
        <li
          class="nav-item"
          routerLinkActive="activo"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <a class="nav-link" [routerLink]="['/informes']"
            ><em class="fa fa-pie-chart"></em>Informes</a
          >
        </li>
      </ul>
    </li>
    <li
      id="ag"
      class="nav-item espacio"
      *ngIf="
        sessionService.getPermisos() && sessionService.getPermisos().ag === 1
      "
      (click)="displayMenu($event)"
    >
      <span class="titulo">Administración General (AG)</span>
      <ul class="list-unstyled">
        <li class="nav-item" routerLinkActive="activo">
          <a class="nav-link" [routerLink]="['/grupos-soporte']"
            ><em class="fa fa-users"></em>Grupos Soporte</a
          >
        </li>
        <li class="nav-item" routerLinkActive="activo">
          <a class="nav-link" [routerLink]="['/roles']"
            ><em class="fa fa-user-circle-o"></em>Roles</a
          >
        </li>
        <li class="nav-item" routerLinkActive="activo">
          <a
            class="nav-link"
            [routerLink]="['/puestos']"
            routerLinkActive="router-link-active"
          >
            <em class="fa fa-desktop"></em>Perfiles</a
          >
        </li>
        <li class="nav-item" routerLinkActive="activo">
          <a
            class="nav-link"
            [routerLink]="['/empresas']"
            routerLinkActive="router-link-active"
          >
            <em class="fa fa-building"></em> Empresas
          </a>
        </li>
        <li class="nav-item" routerLinkActive="activo">
          <a class="nav-link inactivo" href="#"
            ><em class="fa fa-check-circle"></em>Líneas de Venta</a
          >
        </li>
        <li class="nav-item" routerLinkActive="activo">
          <a
            class="nav-link"
            [routerLink]="['/notificaciones']"
            (click)="resetFiltrosNotificacion()"
            routerLinkActive="router-link-active"
          >
            <em class="fa fa-envelope"></em> Notificaciones
          </a>
        </li>
        <li class="nav-item" routerLinkActive="activo">
          <a
            class="nav-link"
            [routerLink]="['/catalogos']"
            routerLinkActive="router-link-active"
            ><em class="fa fa-book"></em>Catálogo de peticiones</a
          >
        </li>
        <li class="nav-item" routerLinkActive="activo">
          <a
            class="nav-link"
            [routerLink]="['/sla']"
            routerLinkActive="router-link-active"
            ><em class="fa fa-check-circle"></em>SLA's y prioridades</a
          >
        </li>
        <li class="nav-item" routerLinkActive="activo">
          <a
            class="nav-link"
            [routerLink]="['/convenios']"
            routerLinkActive="router-link-active"
            (click)="resetFiltrosConvenio()"
          >
            <em class="fa fa-handshake-o"></em>Convenios</a
          >
        </li>
        <li class="nav-item" routerLinkActive="activo">
          <a class="nav-link" [routerLink]="['/estados']"
            ><em class="fa fa-exchange"></em>Estados</a
          >
        </li>
        <li class="nav-item" routerLinkActive="activo">
          <a class="nav-link" [routerLink]="['/acciones']"
            ><em class="fa fa-list"></em>Acciones</a
          >
        </li>
      </ul>
    </li>
  </ul>
</nav>

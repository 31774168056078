import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { GruposSoporte } from 'src/app/modelos/grupossoporte';
import { SesionService } from 'src/app/servicios/sesion/sesion.service';
import { Constantes } from '../../../utils/constantes';
import { GrupossoporteService } from '../../../servicios/grupossoporte/grupossoporte.service';
import { Table } from 'primeng/table';
import { ButtonModalInfo, ModalInfo } from 'src/app/modelos/moda-info';
import { MatDialog } from '@angular/material/dialog';
import { ModalInfoComponent } from '../../modals/modal-info/modal-info.component';
import { MensajeComponent } from '../../mensaje/mensaje.component';
import Utils from 'src/app/utils/utils';

@Component({
  selector: 'app-tabla-grupossoporte',
  templateUrl: './tabla-grupossoporte.component.html',
  styleUrls: ['./tabla-grupossoporte.component.scss']
})
export class TablaGrupossoporteComponent implements OnInit {

  @ViewChild('mensaje', { static: false })
  public mensaje!: MensajeComponent;
  grupossoporte: GruposSoporte[] = [];
  gruposSoportePorPagina!: number[];
  first = 0;
  rows = 25;
  paginasTotales = 0;
  paginaActual = 0;
  gruposSoporteTotales = 0;
  cols!: any[];

  lastSortField!: any;

  constructor(
    private sesionService: SesionService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private constantes: Constantes,
    private grupossoporteservice: GrupossoporteService,
    public dialog: MatDialog) {
      this.cols = [{field: 'descripcion', header: 'DESCRIPCION'},
      {field: 'gruposDestinariosPuestosLst', header: 'DESTINATARIO PETICIONES'},
      {field: 'gruposOtrosPuestosLst', header: 'OTROS INTEGRANTES'},
      {field: 'detalles', header: 'DETALLES'},
      {field: 'acciones', header: 'ACCIONES'}];
      this.gruposSoportePorPagina = this.constantes.numeroUsuariosPorPagina;
     }

  ngOnInit(): void {
    this.getGruposSoporte(this.first, this.rows);
  }

  getGruposSoporte(page: number, size: number, sort?: string, direction?: string): any {
    this.spinner.show();
    this.grupossoporte = [];
    this.grupossoporteservice.getGruposSoporte(String(Math.abs(page)), String(size), sort, direction).subscribe((data: any) => {
      this.cargarDatosTabla(data);
      this.spinner.hide();
    }, (error: any) => {
      console.error(error);
      this.spinner.hide();
      this.mensaje.showError(error);
    });
  }

  private cargarDatosTabla(data: any): any {
    this.grupossoporte = data.results;
    this.paginaActual = data.currentPage;
    this.paginasTotales = data.totalPages;
    this.gruposSoporteTotales = data.totalResults;
    this.customSort(this.lastSortField);
  }

  nextPage(e: any, table: Table): void {
    if (this.rows !== e.rows){
      this.rows = e.rows;
      e.first = 0;
      this.paginaActual = 1;
      const offset = ((e.first) / this.rows) + 1;
      table.first = offset;
      this.first = offset;
      this.rows = e.rows;
      this.getGruposSoporte(offset, this.rows);
    }else{
      if ((e.sortField && !this.lastSortField) || (e.sortField && this.hasChanged(e.sortField, e.sortOrder))) {
        this.lastSortField = {
          sortField: e.sortField,
          sortOrder: e.sortOrder
        };
        this.customSort(this.lastSortField);
        table.first = (this.paginaActual - 1) * this.rows;
      }else{
        const offset = ((e.first) / this.rows) + 1;
        this.rows = e.rows;
        this.first = offset;
        this.getGruposSoporte(offset, this.rows);
      }
    }
  }

  private customSort(sort: any): void {
    if (sort && this.grupossoporte) {
      this.grupossoporte.sort((a: GruposSoporte, b: GruposSoporte) => {
        switch (sort.sortField) {
          case this.cols[0].field:
            return Utils.sortString(
              Utils.stringNotNull(a.descripcion),
              Utils.stringNotNull(b.descripcion),
              sort.sortOrder
            );
          case this.cols[1].field:
            return Utils.sortListField(
              a.gruposDestinariosPuestosLst?.map(puesto => puesto.descripcion) || [''],
              b.gruposDestinariosPuestosLst?.map(puesto => puesto.descripcion) || [''],
              sort.sortOrder
            );
            case this.cols[2].field:
            return Utils.sortListField(
              a.gruposOtrosPuestosLst?.map(puesto => puesto.descripcion) || [''],
              b.gruposOtrosPuestosLst?.map(puesto => puesto.descripcion) || [''],
              sort.sortOrder);
              case this.cols[3].field:
            return Utils.sortString(
              Utils.stringNotNull(a.detalles),
              Utils.stringNotNull(b.detalles),
              sort.sortOrder);
          default:
            return sort.sortOrder;
        }
      });
    }
  }

  redirectToCreateGrupoSoporte(): void{
    this.router.navigate(['/grupos-soporte/crear']);
  }

  exportarDatos(): void{
    this.spinner.show();
    this.grupossoporteservice.exportar().subscribe(urlRespuesta => {
      if (urlRespuesta){
        // Abrir nueva pantalla con el resultado del exportar
        this.spinner.hide();
        window.open(urlRespuesta.url, '_blank', 'location=yes, height=570, width=520, scrollbars=yes, status=yes');
      }else{
        const data = {
          title: 'Información',
          msg: 'No se ha podido hacer la exportación ...'
        };
        this.dialog.open(ModalInfoComponent, {disableClose: true, autoFocus: false,  data});
      }
    });
  }

  modififcarGrupoSoporte(id: string): void {
    this.router.navigate(['grupos-soporte/editar', id]);
  }

  eliminarGrupoSoporte(id: string, descripcion: string, index: number): void {
    const data = {
      title: 'Eliminar Grupo Soporte',
      msg: `¿Desea eliminar el Grupo Soporte ${descripcion}?`,
      buttons: [{title: 'Aceptar', class: 'btn-success', action: '1'}, {title: 'Cancelar', class: 'btn-danger'}] as ButtonModalInfo[]
    } as ModalInfo;
    this.dialog.open(ModalInfoComponent, {disableClose: true, autoFocus: false,  data}).afterClosed().subscribe(resp => {
      if (resp){
        this.grupossoporteservice.deleteGrupoSoporte(id, this.sesionService.getUser().email).subscribe((response: any) => {
          this.mensaje.showMensaje('El grupo se ha eliminado de forma correcta');
          this.ngOnInit();
        }, (error: any) => {
          console.log(error);
          this.mensaje.showError(error);
        });
      }
    });
  }

  private hasChanged(sort: string, order: number): boolean {
    return this.lastSortField.sortField !== sort || this.lastSortField.sortOrder !== order;
  }


}

import { Component } from '@angular/core';
import { PeticionFiltos } from '../../../modelos/peticion';

@Component({
  selector: 'app-peticiones-principal',
  templateUrl: './peticiones-principal.component.html',
  styleUrls: ['./peticiones-principal.component.css']
})
export class PeticionesPrincipalComponent {

  public datosFiltro!: PeticionFiltos;

  public isLoading = true;

  recibirFiltros(event: any): void{
    this.datosFiltro = event;
  }

  finishLoading(event: any): void{
    this.isLoading = event;
  }


}

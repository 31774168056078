<app-mensaje #mensaje>
</app-mensaje>
<ngx-spinner></ngx-spinner>
<div class="container espacio">
    <div class="d-flex justify-content-between mb-3">
        <h1>Gestión Grupos de Soporte</h1>
    </div>

    <div class="card">
        <div>
            <div class="d-flex justify-content-end mb-3">
                <button class="btn btn-outline-primary customButton fa fa-plus-circle" (click)="redirectToCreateGrupoSoporte()">  Crear Grupo Soporte</button>
            </div>

            <p-table id="tabla-grupossoporte" #dt1 [value]="grupossoporte" dataKey="descripcion" [showCurrentPageReport]="true" styleClass="p-datatable-responsive-demo" [paginator]="true" [rows]="rows" [totalRecords]="gruposSoporteTotales" [columns]="cols" (onLazyLoad)="nextPage($event, dt1)"
                [lazy]="true" [globalFilterFields]="cols" sortField='descripcion' [sortOrder]="1" currentPageReportTemplate="Página {{paginaActual + 1}} de {{paginasTotales}}" [rowsPerPageOptions]="gruposSoportePorPagina" [autoLayout]="true">

                <ng-template pTemplate="caption" class="caption">
                    <button class="btn customButton boton-excel" (click)="exportarDatos()"><i class="fa fa-file-excel-o" aria-hidden="true"></i>  EXPORTAR</button>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th *ngFor="let col of cols" scope="{{col.field}}" pSortableColumn="{{col.field}}">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                {{col.header}}
                                <p-sortIcon *ngIf="col.field !== 'acciones'" field="{{col.field}}"></p-sortIcon>
                            </div>
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-gruposoporte let-i="rowIndex">
                    <tr>
                        <td><span class="p-column-title">DESCRIPCION</span> {{gruposoporte.descripcion}}</td>
                        <td>
                            <span class="p-column-title">DESTINATARIO PETICIONES</span>
                            <ng-container>
                                <p *ngFor="let p of gruposoporte.gruposDestinariosPuestosLst; index as i">
                                    <span *ngIf="i < 3">{{p.descripcion}}</span>
                                    <span style="color:red" *ngIf="i === 3 ">... <br>(Ver más datos en Editar)</span>
                                </p>
                            </ng-container>
                        </td>
                        <td>
                            <span class="p-column-title">OTROS INTEGRANTES</span>
                            <ng-container>
                                <p *ngFor="let p1 of gruposoporte.gruposOtrosPuestosLst; index as i">
                                    <span *ngIf="i < 3">{{p1.descripcion}}</span>
                                    <span style="color:red" *ngIf="i === 3 ">... <br>(Ver más datos en Editar)</span>
                                </p>
                            </ng-container>
                        </td>
                        <td><span class="p-column-title">DETALLES</span> {{gruposoporte.detalles}}</td>
                        <td class="acciones" id="accionesHeader">
                            <button pTooltip="Editar" tooltipPosition="right" class="btn btn-sm btn-outline-info" (click)="modififcarGrupoSoporte(gruposoporte.id)"><em class="fa fa-pencil"></em></button>
                            <button pTooltip="Eliminar" tooltipPosition="right" class="btn btn-sm btn-outline-danger" (click)="eliminarGrupoSoporte(gruposoporte.id, gruposoporte.descripcion, i)"><em class="fa fa-trash"></em></button>
                        </td>
                    </tr>
                    <hr class="item-list-separator">
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="7">No hay registros</td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="paginatorleft">
                </ng-template>
                <ng-template pTemplate="paginatorright">
                    <p class="pageFooterLeft">Grupos totales: {{gruposSoporteTotales}}</p>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>

import { Component } from '@angular/core';
import { SesionService } from '../../servicios/sesion/sesion.service';
import { Router } from '@angular/router';
import { PeticionService } from '../../servicios/peticion/peticion.service';
import {
  Peticion,
  PeticionActuacionFiltros,
  PeticionFiltos,
} from '../../modelos/peticion';
import { UsuariosService } from '../../servicios/usuarios/usuarios.service';
import { UsuarioFiltros } from 'src/app/modelos/usuario-filtros';
import { NotificacionesService } from '../../servicios/notificaciones/notificaciones.service';
import { ConveniosService } from 'src/app/servicios/convenios/convenios.service';
import { ConvenioFiltro } from 'src/app/modelos/convenio-filtro';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css'],
})
export class MenuComponent {
  peticion!: Peticion;

  constructor(
    public sessionService: SesionService,
    private router: Router,
    private peticionService: PeticionService,
    private usuarioService: UsuariosService,
    private notificacionService: NotificacionesService,
    private convenioService: ConveniosService
  ) {}

  isAdmin(): boolean {
    return this.sessionService.isAdmin();
  }

  displayMenu(event: any): void {
    if (event) {
      document.getElementById('sidebar')?.setAttribute('class', 'active');
    }
  }

  resetPeticion(): void {
    this.peticionService.setPeticion(this.peticion);
    this.router.navigate(['/peticiones/crear/identificacion']);
  }

  resetFiltrosActuacion(): void {
    this.peticionService.setDatosFiltrosActuacion(
      undefined as unknown as PeticionActuacionFiltros
    );
  }

  resetFiltrosPeticion(): void {
    this.peticionService.setDatosFiltrosPeticion(
      undefined as unknown as PeticionFiltos
    );
  }

  resetFiltrosUsuario(): void {
    this.usuarioService.setDatosFiltros(undefined as unknown as UsuarioFiltros);
  }

  resetFiltrosNotificacion(): void {
    this.notificacionService.setDatosFiltrosNotificacion(
      undefined as unknown as UsuarioFiltros
    );
  }

  resetFiltrosConvenio(): void {
    this.convenioService.setFiltros(undefined as unknown as ConvenioFiltro);
  }
}

import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { Actividades } from 'src/app/modelos/actividades';
import { ConvenioFiltro } from 'src/app/modelos/convenio-filtro';
import { Provincia } from 'src/app/modelos/provincia';
import { ActividadesService } from 'src/app/servicios/actividades/actividades.service';
import { ConveniosService } from 'src/app/servicios/convenios/convenios.service';
import { TerritoriosService } from 'src/app/servicios/territorios/territorios.service';

@Component({
  selector: 'app-filtro-convenios',
  templateUrl: './filtro-convenios.component.html',
  styleUrls: ['./filtro-convenios.component.scss'],
})
export class FiltroConveniosComponent implements OnInit {
  public datosFiltro!: ConvenioFiltro;

  @Output() desdeFiltroConvenios = new EventEmitter();

  public formulario!: FormGroup;
  public listaDenominaciones: any[] = [];
  public listaProvincias!: Provincia[];
  public listaActividades!: Actividades[];
  public listaAmbitos: any[] = [];

  public estadoActivo = true;
  public conveniosBuscados = false;
  public removable = true;

  constructor(
    private fb: FormBuilder,
    private provinciasService: TerritoriosService,
    private actividadesService: ActividadesService,
    private conveniosService: ConveniosService,
    private spinner: NgxSpinnerService
  ) {
    this.listaAmbitos = [
      { id: 1, descripcion: 'Estatal' },
      { id: 2, descripcion: 'Autonomica' },
      { id: 3, descripcion: 'Provincial' },
      { id: 4, descripcion: 'Local' },
      { id: 5, descripcion: 'Interprovincial' },
    ];
  }

  ngOnInit(): void {
    this.datosFiltro = this.conveniosService.datosFiltros;
    this.initForm();
  }

  private cargarProvincias(): void {
    this.provinciasService.getProvincias().subscribe((data) => {
      this.listaProvincias = data;
    });
  }

  private cargarActividades(): void {
    this.actividadesService.getActividades().subscribe((data) => {
      this.listaActividades = data;
    });
  }

  private initForm(): void {
    this.cargarActividades();
    this.cargarProvincias();
    if (this.datosFiltro) {
      this.formulario = this.initFormWithFilters();
      this.buscarFiltro(true);
    } else {
      this.formulario = this.initEmptyForm();
    }
    this.spinner.hide();
  }

  initEmptyForm(): FormGroup {
    return this.fb.group({
      convenio: [[]],
      variosConvenios: [[]],
      provincia: [[]],
      ambito: [],
      actividad: [[]],
      estado: ['activos', []],
    });
  }

  initFormWithFilters(): FormGroup {
    this.desdeFiltroConvenios.emit(this.datosFiltro);
    return this.fb.group({
      convenio: [[]],
      variosConvenios: [
        this.datosFiltro.convenio ? this.datosFiltro.convenio : [],
      ],
      provincia: [this.datosFiltro.provincia],
      ambito: [this.datosFiltro.ambito],
      actividad: [this.datosFiltro.actividad],
      estado: [
        this.datosFiltro.activo === 'activos' ? 'activos' : 'inactivos',
        [],
      ],
    });
  }

  public reset(): void {
    this.formulario.reset();
    this.listaDenominaciones = [];
    this.formulario.get('variosConvenios')?.setValue([]);
    this.formulario.get('estado')?.setValue('activos');
    this.conveniosBuscados = false;
    this.buscarFiltro(true);
  }

  public buscarFiltro(primeraCarga: boolean): void {
    this.datosFiltro = this.buscarConvenios(primeraCarga);
    this.conveniosService.setFiltros(this.datosFiltro);
    this.desdeFiltroConvenios.emit(this.datosFiltro);
  }

  public buscarConvenios(primeraCarga: boolean): ConvenioFiltro {
    return {
      convenio: this.formulario.get('variosConvenios')?.value,
      provincia: this.formulario.get('provincia')?.value,
      ambito: this.formulario.get('ambito')?.value,
      actividad: this.formulario.get('actividad')?.value,
      activo: this.formulario.get('estado')?.value,
      currentPage: primeraCarga ? this.getCurrentPage() : 1,
      recordsPerPage: this.datosFiltro
        ? this.datosFiltro.recordsPerPage
        : undefined,
    };
  }

  private getCurrentPage(): number | undefined {
    return this.datosFiltro ? this.datosFiltro.currentPage : undefined;
  }

  buscarConvenio(event: any): any {
    const cadena = event.target.value;
    this.conveniosBuscados = false;
    if (!cadena || cadena.length < 3) {
      this.listaDenominaciones = [];
    } else {
      this.conveniosService
        .buscarConvenio(cadena)
        .subscribe((resp: string[]) => {
          this.conveniosBuscados = true;
          this.listaDenominaciones = resp
            .filter(
              (convenio: string) =>
                !this.formulario.get('convenio')?.value.includes(convenio)
            )
            .map((convenio: string) => ({ convenio, state: false }));
        });
    }
  }

  removeConvenioForm(convenio: any, index: number): any {
    this.formulario.get('variosConvenios')?.value.splice(index, 1);
    this.listaDenominaciones.push({ convenio, state: false });
  }

  removeConvenio(index: number): void {
    this.listaDenominaciones.splice(index, 1);
  }

  addConvenio(item: any, index: number): void {
    if (
      !this.formulario.get('variosConvenios')?.value.includes(item.convenio)
    ) {
      this.formulario.get('variosConvenios')?.value.push(item.convenio);
    }
    this.removeConvenio(index);
  }

  cambiarEstado(estado: string): void {
    if (estado === 'activos') {
      this.estadoActivo = true;
    }
    if (estado === 'inactivos') {
      this.estadoActivo = false;
    }
  }
}

<app-mensaje #mensaje> </app-mensaje>
<ngx-spinner> </ngx-spinner>
<app-crear-peticion [activeIndex]="activeIndex"></app-crear-peticion>
<app-modal-artica
  id="modal-artica"
  #modalArtica
  (emitirArtica)="articaRecibida($event)"
></app-modal-artica>
<app-modal-autorizar
  id="modal-autorizar"
  #modalAutorizar
  (emitirAutorizar)="reload()"
></app-modal-autorizar>
<app-modal-notificar
  id="modal-notificar"
  #modalnotificar
  (emitirNotificar)="notificadores($event)"
></app-modal-notificar>
<div class="container mt-3">
  <div class="title">
    <h2 class="auditar">Resumen de la petición</h2>
    <button
      *ngIf="esAuditor"
      pTooltip="Auditoria"
      tooltipPosition="right"
      class="btn btn-md btn-outline-primary visualizar botongoogle customButton"
      (click)="auditar()"
    >
      <em class="fa fa-id-card"></em>
    </button>
    <ng-container *ngFor="let accion of accionesDetalle">
      <button
        id="botones"
        *ngIf="
          accion.id !== constantes.ACCION_ELIMINAR &&
          accion.id !== constantes.ACCION_VISUALIZAR
        "
        pTooltip="{{ accion.tooltip }}"
        [class]="accion.clases || ''"
        [ngClass]="{ red: peticion.fechaArtica === null }"
        (click)="ejecutarAccion(accion.id)"
      >
        <em class="{{ accion.icono }}"></em>
      </button>
    </ng-container>
  </div>
  <span class="atencion">ATENCIÓN: </span>
  <span class="leyenda"
    ><em
      >Estos son los datos esenciales de la petición, para ver y revisar con más
      detalle vuelva a los pasos anteriores.</em
    ></span
  >
  <app-detalle-peticion
    [peticion]="peticion"
    [perfiles]="perfiles"
    [informaciones]="informaciones"
    [aclaraciones]="aclaraciones"
    [comentarioModificable]="false"
  ></app-detalle-peticion>
  <div class="row acciones espacio" *ngIf="modificable">
    <div class="col acciones-botones">
      <button
        type="button"
        pTooltip="Peticion Borrador"
        tooltipPosition="right"
        class="btn btn-sm btn-outline-secondary"
        (click)="borrador()"
      >
        Borrador
      </button>
      <button
        type="button"
        pTooltip="{{ textoToolTipEnviar }}"
        tooltipPosition="right"
        class="btn btn-sm btn-outline-info"
        (click)="enviar()"
      >
        Enviar
      </button>
    </div>
  </div>
  <div class="row seccion-inferior">
    <div class="botones">
      <button
        type="submit"
        class="btn customButton ml-1"
        (click)="previousPage()"
      >
        Anterior
      </button>
      <button
        *ngIf="
          peticion &&
          peticion.subirArtica === 1 &&
          peticion.estado?.id !== 1 &&
          !esAuditor
        "
        id="anterior"
        type="submit"
        class="btn customButton btn-artica ml-5"
        [ngClass]="{
          red:
            peticion.fechaArtica === null || peticion.fechaArtica === undefined
        }"
        (click)="mostrarModalArtica('')"
      >
        Subir Documento a Ártica
      </button>
    </div>
  </div>
</div>

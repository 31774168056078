import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { PeticionService } from '../../../servicios/peticion/peticion.service';
import { SesionService } from '../../../servicios/sesion/sesion.service';

@Component({
  selector: 'app-crear-peticion',
  templateUrl: './crear-peticion.component.html',
  styleUrls: ['./crear-peticion.component.scss'],
})
export class CrearPeticionComponent implements OnInit {
  items!: MenuItem[];

  esModificable = true;
  esControlActuaciones = false;
  hasEmail = false;
  textoBotonVolver = '';

  private roles = [1, 2, 6]; //Roles: Administrador, administrador local y editor (destinatario)

  @Input()
  activeIndex!: number;

  constructor(
    private router: Router,
    private peticionService: PeticionService,
    private sesionService: SesionService
  ) {}

  ngOnInit(): void {
    this.esModificable = this.peticionService.esModificable();
    this.esControlActuaciones = this.peticionService.esControlActuaciones();
    this.hasEmail = this.peticionService.isFromUrlEmail();
    this.items = this.esModificable ? this.itemsSinRuta() : this.itemsConRuta();
    this.textoToolTipBoton();
  }

  private itemsSinRuta(): any[] {
    return [
      {
        icon: 'fa-link',
        label: 'Identificación',
      },
      {
        label: 'Descripción Solicitud',
      },
      {
        label: 'Datos Específicos',
      },
      {
        label: 'Adjuntar Ficheros',
      },
      {
        label: 'Notificaciones',
      },
      {
        label: 'Confirmación',
      },
    ];
  }

  private itemsConRuta(): any[] {
    return [
      {
        icon: 'fa-link',
        label: 'Identificación',
        routerLink: ['/peticiones/crear/identificacion'],
      },
      {
        label: 'Descripción Solicitud',
        routerLink: ['/peticiones/crear/observaciones'],
      },
      {
        label: 'Datos Específicos',
        routerLink: ['/peticiones/crear/datos'],
      },
      {
        label: 'Adjuntar Ficheros',
        routerLink: ['/peticiones/crear/adjuntar'],
      },
      {
        label: 'Notificaciones',
        routerLink: ['/peticiones/crear/notificaciones'],
      },
      {
        label: 'Confirmación',
        routerLink: ['/peticiones/crear/confirmacion'],
      },
    ];
  }

  private textoToolTipBoton(): void {
    if (this.esControlActuaciones) {
      this.textoBotonVolver = 'Ir a Control de Actuaciones';
    } else {
      this.textoBotonVolver = 'Ir a Mis peticiones';
    }
  }

  public volver(): void {
    let userRol = this.sesionService.getUser().rol?.id;
    // Si ha sido llamado desde el PS (Control de actuaciones)
    if (this.esControlActuaciones) {
      this.router.navigate(['/peticiones/actuaciones']);
    // Si ha sido llamado desde el Correo y tiene permisos para el PS (Control de actuaciones)
    } else if (this.hasEmail && this.checkUserRol(userRol)) {
      this.peticionService.setFromUrlEmail(false);
      this.router.navigate(['/peticiones/actuaciones']);
    // Si se ha llamado desde Gestion de mis peticiones
    } else {
      this.router.navigate(['/peticiones']);
    }
  }

  private checkUserRol(userRol: number | undefined): boolean {
    let isAllowed: boolean = false;
    if (userRol !== undefined && this.roles.includes(userRol)) {
      isAllowed = true;
    }
    return isAllowed;
  }

  public fijarElemento(event: any): void {
    event.preventDefault();
  }
}

<ngx-spinner></ngx-spinner>
<app-mensaje #mensaje></app-mensaje>
<div class="container">
  <app-crear-catalogo-base [activeIndex]="0"></app-crear-catalogo-base>
  <h5 class="mt-3 mb-4 title-header">Datos del Catálogo</h5>
  <form [formGroup]="formulario" *ngIf="formulario">
    <div class="row" *ngIf="catalogo && catalogo.editable !== 1">
      <div class="col alert alert-warning text-center" role="alert">
        Este tipo de petición no se puede Editar.
      </div>
    </div>
    <div class="row">
      <div class="mb-3 col-sm-12 col-lg-2">
        <label for="id" class="form-label"
          >ID<span class="text-danger"> *</span></label
        >
        <input
          class="form-control"
          id="id"
          placeholder="999.99"
          formControlName="id"
          trim="blur"
          maxlength="10"
        />
        <small
          class="form-text text-danger"
          *ngIf="
            !formulario.get('id')!.valid &&
            formulario.get('id')!.touched &&
            formulario.controls.id.errors?.required
          "
          >* El campo ID es obligatorio</small
        >
        <small
          class="form-text text-danger"
          *ngIf="
            !formulario.get('id')!.valid &&
            formulario.get('id')!.touched &&
            formulario.controls.id.errors?.pattern
          "
          >* Formato de ID incorrecto. Ejemplo: 999.99</small
        >
      </div>
      <div class="mb-3 col-sm-12 col-lg-8">
        <label for="descripcion" class="form-label"
          >Descripción Tipo de Petición<span class="text-danger">
            *</span
          ></label
        >
        <p-autoComplete
          styleClass="form-control p-0"
          id="descripcion"
          placeholder="Descripción..."
          formControlName="descripcion"
          (onBlur)="trimDescripcion()"
          [maxlength]="70"
          [suggestions]="catalogosFiltrados"
          (completeMethod)="filterCatalogos($event)"
        ></p-autoComplete>
        <small
          class="form-text text-danger"
          *ngIf="
            !formulario.get('descripcion')!.valid &&
            formulario.get('descripcion')!.touched &&
            formulario.controls.descripcion.errors?.required
          "
          >* El campo Descripción es obligatorio</small
        >
      </div>
      <div class="mb-3 col-sm-12 col-lg-2">
        <label for="areaDestino" class="form-label"
          >Área de Destino <span class="text-danger"> *</span></label
        >
        <p-dropdown
          id="areaDestino"
          styleClass="form-control p-0"
          [options]="listaAreasDestinos"
          formControlName="areaDestino"
        >
        </p-dropdown>
        <small
          class="form-text text-danger"
          *ngIf="
            !formulario.get('areaDestino')!.valid &&
            formulario.get('areaDestino')!.touched
          "
          >* El campo es obligatorio</small
        >
      </div>
    </div>
    <div class="row">
      <div class="mb-3 col-sm-12 col-lg-2">
        <label for="tipoFlujo" class="form-label"
          >Tipo de Flujo <span class="text-danger"> *</span></label
        >
        <p-dropdown
          id="tipoFlujo"
          styleClass="form-control p-0"
          [options]="listaTiposFlujos"
          formControlName="tipoFlujo"
        >
        </p-dropdown>
        <small
          class="form-text text-danger"
          *ngIf="
            !formulario.get('tipoFlujo')!.valid &&
            formulario.get('tipoFlujo')!.touched
          "
          >* El campo es obligatorio</small
        >
      </div>
      <div class="mb-3 col-sm-12 col-lg-2">
        <label for="impacto" class="form-label"
          >Impacto <span class="text-danger"> *</span></label
        >
        <p-dropdown
          id="impacto"
          styleClass="form-control p-0"
          [options]="listaImpactos"
          formControlName="impacto"
          optionLabel="descripcion"
        >
        </p-dropdown>
        <small
          class="form-text text-danger"
          *ngIf="
            !formulario.get('impacto')!.valid &&
            formulario.get('impacto')!.touched
          "
          >* El campo es obligatorio</small
        >
      </div>
      <div class="mb-3 col-sm-12 col-lg-8">
        <label for="urgencias" class="form-label"
          >Grupos Soporte<span class="text-danger"> *</span></label
        >
        <p-multiSelect
          id="urgencias"
          filterPlaceHolder="Buscar"
          [defaultLabel]="'Selección de grupos'"
          [virtualScroll]="true"
          [options]="listaGrupos"
          formControlName="grupos"
          optionLabel="descripcion"
          [filter]="true"
          styleClass="form-control p-0"
        ></p-multiSelect>
      </div>
      <div class="mb-3 col-sm-12 col-lg-3">
        <label for="descripcion" class="form-label"
          >Esfuerzo promedio resolución<span class="text-danger">
            *</span
          ></label
        >

        <input
          class="form-control"
          id="esfuerzo"
          placeholder="de 0 a 5"
          formControlName="esfuerzo"
          trim="blur"
          maxlength="1"
        />

        <small
          class="form-text text-danger"
          *ngIf="
            !formulario.get('esfuerzo')!.valid &&
            formulario.get('esfuerzo')!.touched
          "
          >* Formato del esfuerzo incorrecto. Números del 0 al 5</small
        >
      </div>

      <div class="mb-3 col-sm-12 col-lg-3">
        <label for="solicitante" class="form-label"
          >Tipo de solicitante <span class="text-danger"> *</span></label
        >
        <p-dropdown
          id="solicitante"
          styleClass="form-control p-0"
          [options]="listaTipoSolicitante"
          formControlName="solicitante"
        >
        </p-dropdown>
      </div>
    </div>
    <div class="row mt-4">
      <div class="mb-3 col-sm-12 col-lg-4">
        <div class="p-field-checkbox">
          <p-checkbox
            styleClass="p-0"
            [binary]="true"
            formControlName="subirArtica"
            label="Subir Ártica"
          ></p-checkbox>
        </div>
      </div>
      <div class="mb-3 col-sm-12 col-lg-4">
        <div class="p-field-checkbox">
          <p-checkbox
            styleClass="p-0"
            [binary]="true"
            formControlName="fechaResolucion"
            label="Fecha Estimada Resolución"
          ></p-checkbox>
        </div>
      </div>
      <div class="mb-3 col-sm-12 col-lg-4">
        <div class="p-field-checkbox">
          <p-checkbox
            styleClass="p-0"
            [binary]="true"
            formControlName="datosEspecificos"
            label="Datos Específicos"
          ></p-checkbox>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="mb-3 col-sm-12 col-lg-4">
        <div class="p-field-checkbox">
          <p-checkbox
            styleClass="p-0"
            [binary]="true"
            formControlName="trabajadorAfectado"
            label="Trabajador Afectado"
          ></p-checkbox>
        </div>
      </div>
      <div class="mb-3 col-sm-12 col-lg-4">
        <div class="p-field-checkbox">
          <p-checkbox
            styleClass="p-0"
            [binary]="true"
            formControlName="clienteAfectado"
            label="Cliente/Proyecto"
          ></p-checkbox>
        </div>
      </div>
      <div class="mb-3 col-sm-12 col-lg-4">
        <div class="p-field-checkbox">
          <p-checkbox
            styleClass="p-0"
            [binary]="true"
            formControlName="documentoObligatorio"
            label="Documento Obligatorio"
          ></p-checkbox>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="mb-3 col-sm-12 col-lg-4">
        <div class="p-field-checkbox">
          <p-checkbox
            styleClass="p-0"
            [binary]="true"
            formControlName="datosEfectividad"
            label="Datos Efectividad"
            (onChange)="cambioDatosEfectividad()"
          ></p-checkbox>
        </div>
      </div>
      <div class="mb-3 col-sm-12 col-lg-4">
        <div class="p-field-checkbox">
          <p-checkbox
            styleClass="p-0"
            [binary]="true"
            formControlName="calcularSLA"
            label="Calcular SLA"
          ></p-checkbox>
        </div>
      </div>
      <div
        class="mb-3 col-sm-12 col-lg-4"
        *ngIf="formulario.get('calcularSLA')?.value === true"
      >
        <div class="p-field-checkbox">
          <p-checkbox
            styleClass="p-0"
            [binary]="true"
            formControlName="escalado"
            label="Escalado"
          ></p-checkbox>
        </div>
      </div>
    </div>
    <div
      class="row mt-2"
      *ngIf="formulario.get('datosEfectividad')?.value === true"
    >
      <div class="mb-3 col-sm-12 col-lg-4">
        <label for="descripcionEfectividad" class="form-label"
          >Descripción Fecha<span class="text-danger"> *</span></label
        >
        <input
          class="form-control"
          id="descripcionEfectividad"
          placeholder="Introduzca una descripción..."
          formControlName="descripcionEfectividad"
          trim="blur"
          maxlength="50"
        />
        <small
          class="form-text text-danger"
          *ngIf="
            !formulario.get('descripcionEfectividad')!.valid &&
            formulario.get('descripcionEfectividad')!.touched &&
            formulario.controls.descripcionEfectividad.errors?.required
          "
          >* El campo Descripción Fecha es obligatorio</small
        >
      </div>
      <div class="mb-3 col-sm-12 col-lg-2">
        <label for="fechaRango" class="form-label"
          >Tipo Fecha<span class="text-danger"> *</span></label
        >
        <p-dropdown
          id="fechaRango"
          styleClass="form-control p-0"
          [options]="listaFechaRangos"
          formControlName="fechaRango"
          (onChange)="cambioFechaRango()"
        >
        </p-dropdown>
        <small
          class="form-text text-danger"
          *ngIf="
            !formulario.get('fechaRango')!.valid &&
            formulario.get('fechaRango')!.touched &&
            formulario.controls.fechaRango.errors?.required
          "
          >* El campo Fecha Rango es obligatorio</small
        >
      </div>
      <div class="mb-3 col-sm-12 col-lg-2 mt-4">
        <div class="p-field-checkbox">
          <p-checkbox
            class="customLabel"
            styleClass="p-0"
            [binary]="true"
            formControlName="obligatorioFecha"
            label="Obligatorio Fecha"
            [disabled]="true"
          ></p-checkbox>
        </div>
      </div>
      <div class="mb-3 col-sm-12 col-lg-2 mt-4">
        <div class="p-field-checkbox">
          <p-checkbox
            class="customLabel"
            styleClass="p-0"
            [binary]="true"
            formControlName="obligatorioDesde"
            label="Obligatorio Desde"
            [disabled]="true"
          ></p-checkbox>
        </div>
      </div>
      <div class="mb-3 col-sm-12 col-lg-2 mt-4">
        <div class="p-field-checkbox">
          <p-checkbox
            class="customLabel"
            styleClass="p-0"
            [binary]="true"
            formControlName="obligatorioHasta"
            label="Obligatorio Hasta"
            [disabled]="
              !catalogo ||
              (catalogo &&
                catalogo.tipoFechaEfectividad === listaFechaRangos[0])
            "
          ></p-checkbox>
        </div>
      </div>
    </div>
    <div class="row mt-1">
      <div class="mb-3 col-sm-12 col-lg-3">
        <div class="p-field-checkbox">
          <p-checkbox
            class="customLabel"
            styleClass="p-0"
            [binary]="true"
            formControlName="activo"
            label="Activar en Catálogo"
          ></p-checkbox>
        </div>
      </div>
    </div>

    <div class="botones derecha">
      <button
        type="submit"
        class="btn btn-outline-primary customButton"
        (click)="callModificarCatalogo()"
        *ngIf="modoEdicion; else modoCrear"
        [disabled]="
          (!formulario.dirty && catalogo.tipoFlujo !== listaTiposFlujos[1]) ||
          !formulario.valid
        "
      >
        {{ txtBtnFinal }}
      </button>

      <ng-template #modoCrear>
        <button
          type="submit"
          class="btn btn-outline-primary customButton"
          (click)="callCrearCatalogo()"
          [disabled]="!formulario.valid"
        >
          {{ txtBtnFinal }}
        </button>
      </ng-template>
    </div>
  </form>
</div>

import { Component, OnInit} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { Table } from 'primeng/table';
import { AccionPermisos } from 'src/app/modelos/accion-permisos';
import { Rol } from 'src/app/modelos/rol';
import { AccionesService } from 'src/app/servicios/acciones/acciones.service';
import { Constantes } from 'src/app/utils/constantes';
import Utils from 'src/app/utils/utils';
import { ModalInfoComponent } from '../modals/modal-info/modal-info.component';

@Component({
  selector: 'app-acciones',
  templateUrl: './acciones.component.html',
  styleUrls: ['./acciones.component.scss']
})
export class AccionesComponent implements OnInit {

  acciones: AccionPermisos[] = [];
  cols!: any[];
  first = 1;
  rows = 25;

  paginasTotales = 0;
  paginaActual = 1;
  accionesTotales = 0;
  accionesPorPagina!: number[];

  lastSortField!: any;

  constructor(
    private accionesService: AccionesService,
    private spinner: NgxSpinnerService,
    private dialog: MatDialog,
    private constantes: Constantes
  ) {
    this.cols = [
      {field: 'id', header: 'ID', toolTip: '', sortable: true},
      {field: 'nombre', header: 'ACCIÓN', toolTip: '', sortable: true},
      {field: 'descripcion', header: 'DESCRIPCIÓN', toolTip: '', sortable: false},
      {field: 'administrador', header: 'A', toolTip: 'Administrador'},
      {field: 'administradorLocal', header: 'AL', toolTip: 'Administrador local'},
      {field: 'autorizador', header: 'AT', toolTip: 'Autorizador'},
      {field: 'informador', header: 'I', toolTip: 'Informador'},
      {field: 'solicitante', header: 'S', toolTip: 'Solicitante'},
      {field: 'editor', header: 'E', toolTip: 'Editor'},
      {field: 'auditor', header: 'AD', toolTip: 'Auditor'},
      {field: 'letradoExterno', header: 'LE', toolTip: 'Letrado externo'},

    ];
    this.accionesPorPagina = this.constantes.numeroUsuariosPorPagina;
  }

  ngOnInit(): void {
    this.getAcciones(this.first);
  }

  getAcciones(page: number): void {
    this.spinner.show();
    this.acciones = [];
    this.accionesService.getAccionesRoles(page, this.rows).subscribe(
      (data: any) => {
        this.cargarDatosTabla(data);
        this.spinner.hide();
      },
      (error: any) => {
        console.error(error);
        this.spinner.hide();
      }
    );
  }

  private cargarDatosTabla(data: any): any {
    this.acciones = data.results;
    this.paginaActual = data.currentPage;
    this.paginasTotales = data.totalPages;
    this.accionesTotales = data.totalResults;
    this.customSort(this.lastSortField);
  }

  checkRol(roles: Rol[], value: string): boolean{
    return roles.some( x => x.descripcion === value );
  }

  nextPage(e: any, table: Table): void {
    if (this.rows !== e.rows){
      this.rows = e.rows;
      e.first = 0;
      this.paginaActual = 1;
      const offset = ((e.first) / this.rows) + 1;
      table.first = offset;
      this.first = offset;
      this.rows = e.rows;
      this.getAcciones(offset);
    }else{
      if ((e.sortField && !this.lastSortField) || (e.sortField && this.hasChanged(e.sortField, e.sortOrder))) {
        this.lastSortField = {
          sortField: e.sortField,
          sortOrder: e.sortOrder
        };
        this.customSort(this.lastSortField);
        table.first = (this.paginaActual - 1) * this.rows;
      }else{
        const offset = ((e.first) / this.rows) + 1;
        this.rows = e.rows;
        this.first = offset;
        this.getAcciones(offset);
      }
    }
  }

  private customSort(sort: any): void {
    if (sort && this.acciones) {
      this.acciones.sort((a: AccionPermisos, b: AccionPermisos) => {
        switch (sort.sortField) {
          case this.cols[0].field:
            return Utils.sortNumber(
              a.id,
              b.id,
              sort.sortOrder
            );
          case this.cols[1].field:
            return Utils.sortString(
              a.nombre,
              b.nombre,
              sort.sortOrder
            );
          default:
            return sort.sortOrder;
        }
      });
    }
  }

  private hasChanged(sort: string, order: number): boolean {
    return this.lastSortField.sortField !== sort || this.lastSortField.sortOrder !== order;
  }


  exportarDatos(): void {
    this.spinner.show();
    this.accionesService.exportar().subscribe(
      (data) => {
        // Abrir nueva pantalla con el resultado del exportar
        this.spinner.hide();
        window.open(data.url, '_blank', 'location=yes, height=570, width=520, scrollbars=yes, status=yes');
      },
      (error) => {
        console.error(error);
        this.spinner.hide();
        const data = {
          title: 'Información',
          msg: 'No se ha podido hacer la exportación ...'
        };
        this.dialog.open(ModalInfoComponent, {disableClose: true, autoFocus: false,  data});
      });
  }

}

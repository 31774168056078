import { Component, Injector, OnInit } from '@angular/core';
import { RolesService } from '../../../servicios/rol/roles.service';
import { Rol } from '../../../modelos/rol';
import { TerritoriosService } from '../../../servicios/territorios/territorios.service';
import { Ambito } from '../../../modelos/ambito';
import { NgxSpinnerService } from 'ngx-spinner';
import { forkJoin, Observable, of } from 'rxjs';
import { Usuario } from '../../../modelos/usuario';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PuestosService } from 'src/app/servicios/puestos/puestos.service';
import { Puesto } from 'src/app/modelos/puesto';
import { ActividadesService } from 'src/app/servicios/actividades/actividades.service';
import { Actividades } from 'src/app/modelos/actividades';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { SesionService } from 'src/app/servicios/sesion/sesion.service';
import { UsuariosService } from 'src/app/servicios/usuarios/usuarios.service';
import { ModalInfo } from 'src/app/modelos/moda-info';
import { ModalInfoComponent } from '../../modals/modal-info/modal-info.component';
import { EmpresasService } from 'src/app/servicios/empresas/empresas.service';
import { Empresa } from 'src/app/modelos/empresa';
import { Region } from 'src/app/modelos/region';
import { Zona } from 'src/app/modelos/zona';
import { Provincia } from 'src/app/modelos/provincia';
import { environment } from 'src/environments/environment';
import { TipoSolicitante } from '../../../modelos/tipo-solicitante';
import { TipoSolicitanteService } from '../../../servicios/tipo-solicitante/tipo-solicitante.service';
import { LineasVenta } from 'src/app/modelos/lineas-venta';
import { InformeDatastudio } from 'src/app/modelos/informesdatastudio';
import { LineasVentaService } from '../../../servicios/lineas-venta/lineas-venta.service';
import { pairwise, startWith } from 'rxjs/operators';
import { InformesdatastudioService } from '../../../servicios/informesdatastudio/informesdatastudio.service';

@Component({
  selector: 'app-crearusuario',
  templateUrl: './crearusuario.component.html',
  styleUrls: ['./crearusuario.component.scss'],
})
export class CrearusuarioComponent implements OnInit {
  public listaEmpresas: Empresa[] = [];
  public listaRoles: Rol[] = [];
  public listaRegiones: Region[] = [];
  public listaZonas: Zona[] = [];
  public listaAmbitos: Ambito[] = [];
  public listaProvincias: Provincia[] = [];
  public listaPuestos: Puesto[] = [];
  public listaActividades: Actividades[] = [];
  public listaTipoSolicitantes: TipoSolicitante[] = [];
  public listaLineasVenta: LineasVenta[] = [];
  public listaInformesDatastudio: InformeDatastudio[] = [];
  public cargasCombos: any[] = [];

  public formulario!: FormGroup;

  public usuario!: Usuario;
  public usuarioLogin!: Usuario;
  public userEmail: any;
  public title = '';
  public patronemail!: string;

  public modoEdicion = false;
  public primeraCarga = true;
  public disabledAmbitos = true;
  public disabledProvincias = true;

  private usuariosService!: UsuariosService;
  private empresasService!: EmpresasService;
  private rolesService!: RolesService;
  private territoriosService!: TerritoriosService;
  private puestosService!: PuestosService;
  private actividadesService!: ActividadesService;
  private tipoSolicitanteService!: TipoSolicitanteService;
  private lineasVentaService!: LineasVentaService;
  private sesionService!: SesionService;
  private spinner!: NgxSpinnerService;
  private informesdatastudioService!: InformesdatastudioService;

  constructor(
    private fb: FormBuilder,
    private inject: Injector,
    private router: Router,
    private acRoute: ActivatedRoute,
    public dialog: MatDialog
  ) {
    this.loadInitialProperties();
  }

  loadInitialProperties(): void {
    this.usuariosService = this.inject.get<UsuariosService>(UsuariosService);
    this.empresasService = this.inject.get<EmpresasService>(EmpresasService);
    this.rolesService = this.inject.get<RolesService>(RolesService);
    this.territoriosService =
      this.inject.get<TerritoriosService>(TerritoriosService);
    this.puestosService = this.inject.get<PuestosService>(PuestosService);
    this.actividadesService =
      this.inject.get<ActividadesService>(ActividadesService);
    this.tipoSolicitanteService = this.inject.get<TipoSolicitanteService>(
      TipoSolicitanteService
    );
    this.lineasVentaService =
      this.inject.get<LineasVentaService>(LineasVentaService);
    this.sesionService = this.inject.get<SesionService>(SesionService);
    this.spinner = this.inject.get<NgxSpinnerService>(NgxSpinnerService);
    this.informesdatastudioService = this.inject.get<InformesdatastudioService>(
      InformesdatastudioService
    );
  }

  ngOnInit(): void {
    this.userEmail = this.acRoute.snapshot.paramMap.get('email');
    this.patronemail = environment.PATRON_EMAIL_DOMINIO;
    this.usuarioLogin = this.sesionService.getUser();
    if (this.userEmail) {
      this.title = 'Editar Usuario';
      if (this.usuarioLogin.rol?.id === 7) {
        this.title = 'Visualizar usuario';
      }
      this.modoEdicion = true;
      this.usuariosService
        .getUsuario(this.userEmail)
        .subscribe((user: Usuario) => {
          this.usuario = user;
          this.usuario.emailEditor = this.sesionService.getUser().email;
          this.initServices();
          this.initForm();
        });
    } else {
      this.title = 'Crear Usuario';
      this.usuario = this.initUsuario();
      this.initForm();
      this.initServices();
    }
  }

  private initUsuario(): Usuario {
    return {
      email: '',
      nombre: '',
      apellido: '',
      dni: '',
      emailEditor: this.sesionService.getUser().email,
      actividades: [] as Actividades[],
      provincias: [] as Provincia[],
      ambitos: [] as Ambito[],
      regiones: [] as Region[],
      zonas: [] as Zona[],
      lineasVentas: [] as LineasVenta[],
    };
  }

  initForm(): void {
    const usuarioNombre = this.modoEdicion
      ? { value: this.usuario.email, disabled: true }
      : this.usuario.email;
    const usuarioDni = this.modoEdicion
      ? { value: this.usuario.dni, disabled: true }
      : this.usuario.dni;
    this.formulario = this.fb.group({
      email: [
        usuarioNombre,
        [Validators.required, Validators.pattern(this.patronemail)],
      ],
      nombre: [
        {
          value: this.usuario.nombre,
          disabled: this.usuarioLogin.rol?.id === 7,
        },
        [Validators.required],
      ],
      apellido: [
        {
          value: this.usuario.apellido,
          disabled: this.usuarioLogin.rol?.id === 7,
        },
        [Validators.required],
      ],
      dni: [
        usuarioDni,
        [
          Validators.pattern('^([0-9]{8}[A-Z])|[XYZ][0-9]{7}[A-Z]$'),
          Validators.required,
        ],
      ],
      rol: [
        { value: this.usuario.rol, disabled: this.usuarioLogin.rol?.id === 7 },
        [Validators.required],
      ],
      puesto: [
        {
          value: this.usuario.puesto,
          disabled: this.usuarioLogin.rol?.id === 7,
        },
        [Validators.required],
      ],
      empresa: [
        {
          value: this.usuario.empresa,
          disabled: this.usuarioLogin.rol?.id === 7,
        },
        [Validators.required],
      ],
      tipoSolicitante: [
        {
          value: this.usuario.tipoSolicitante,
          disabled: this.usuarioLogin.rol?.id === 7,
        },
        [Validators.required],
      ],
      actividades: [
        {
          value: this.usuario.actividades,
          disabled: this.usuarioLogin.rol?.id === 7,
        },
        [Validators.required],
      ],
      lineasVentas: [
        {
          value: this.usuario.lineasVentas,
          disabled: this.usuarioLogin.rol?.id === 7,
        },
      ],
      zonas: [
        {
          value: this.usuario.zonas,
          disabled: this.usuarioLogin.rol?.id === 7,
        },
        [Validators.required],
      ],
      ambitos: [
        {
          value: this.usuario.ambitos,
          disabled: this.usuarioLogin.rol?.id === 7,
        },
      ],
      regiones: [
        {
          value: this.usuario.regiones,
          disabled: this.usuarioLogin.rol?.id === 7,
        },
      ],
      provincias: [
        {
          value: this.usuario.provincias,
          disabled: this.usuarioLogin.rol?.id === 7,
        },
      ],
      informedatastudio: [
        {
          value: this.usuario.informeDatastudio,
          disabled: this.usuarioLogin.rol?.id === 7,
        },
      ],
    });
    if (this.formulario) {
      this.formulario.controls.empresa.valueChanges
        .pipe(startWith(null), pairwise())
        .subscribe(([oldValue, newValue]) => {
          this.cambioEmpresa(newValue);
        });
    }
  }

  initServices(): void {
    this.spinner.show();
    this.cargasCombos.push(this.rolesService.getRoles());
    this.cargasCombos.push(this.empresasService.getEmpresas());
    this.cargasCombos.push(this.puestosService.getPuestos());
    this.cargasCombos.push(this.actividadesService.getActividades());
    this.cargasCombos.push(this.tipoSolicitanteService.getTipoSolicitantes());
    this.cargasCombos.push(
      this.informesdatastudioService.getInformesDatastudio()
    );

    // si ademas, estamos en el modo editar
    if (this.userEmail) {
      this.cargasCombos.push(this.territoriosService.getRegiones());
      this.cargasCombos.push(this.territoriosService.getZonas());
    }

    forkJoin(this.cargasCombos).subscribe(
      (resp: any) => {
        this.listaRoles = resp[0];
        this.listaEmpresas = resp[1];
        this.listaPuestos = resp[2];
        this.listaActividades = resp[3];
        this.listaTipoSolicitantes = resp[4];
        this.listaInformesDatastudio = resp[5];
        this.cambioActividades();
        if (this.formulario) {
          if (this.userEmail) {
            this.listaRegiones = resp[6];
            this.listaZonas = resp[7];
            // buscar ambitos
            this.formulario.controls.zonas.setValidators(Validators.required);
            this.callGetAmbitosByZona().subscribe(
              (respAmbitos) => {
                this.handleCallGetAmbitosByZona(respAmbitos);
              },
              (error) => {
                this.spinner.hide();
                console.error(error);
              }
            );
          } else {
            this.spinner.hide();
          }
        }
      },
      (error) => {
        console.error(error);
        this.spinner.hide();
      }
    );
  }

  private handleCallGetAmbitosByZona(respAmbitos: any[]): void {
    if (respAmbitos) {
      this.listaAmbitos = respAmbitos;
      this.disabledAmbitos = false;
      // buscar provincias
      this.callGetProvinciasByAmbito().subscribe(
        (respProvincias) => {
          this.listaProvincias = respProvincias;
          this.disabledProvincias = false;
          this.primeraCarga = false;
          this.spinner.hide();
        },
        (error) => {
          this.spinner.hide();
          console.log(error);
        }
      );
    }
  }

  irAuditoria(usuario: string): void {
    this.router.navigate(['/usuarios'], {
      queryParams: { auditoria: true, usuario: usuario ? usuario : '' },
    });
  }

  cambioEmpresa(empresa: Empresa): void {
    this.spinner.show();
    if (this.formulario) {
      this.formulario.controls.provincias.setValue([]);
      this.formulario.controls.ambitos.setValue([]);
      this.disabledAmbitos = true;
      this.disabledProvincias = true;

      this.formulario.controls.regiones.clearValidators();
      this.formulario.controls.regiones.setValue([]);
      this.formulario.controls.regiones.setValidators(null);
      this.formulario.controls.regiones.setErrors(null);
      this.formulario.controls.zonas.setValue([]);
      this.formulario.controls.zonas.setValidators(Validators.required);
      this.formulario.updateValueAndValidity();
      this.territoriosService.getZonas().subscribe(
        (zonas) => {
          this.listaZonas = zonas as Zona[];
          this.formulario.updateValueAndValidity();
          this.spinner.hide();
        },
        () => {
          this.formulario.updateValueAndValidity();
          this.spinner.hide();
        }
      );
    }
  }

  private getActividadesSelected(): number[] {
    const idActividad: number[] = [];

    const actividades =
      this.primeraCarga && this.modoEdicion
        ? this.usuario.actividades
        : (this.formulario.get('actividades')?.value as Actividades[]);
    if (actividades) {
      for (const value of actividades) {
        this.listaActividades.forEach((actividad) => {
          if (actividad.id === value.id) {
            idActividad.push(value.id);
          }
        });
      }
    }
    return idActividad;
  }

  cambioActividades(): any {
    if (this.formulario) {
      if (this.formulario.get('actividades')?.value.length > 0) {
        this.spinner.show();
        const actividad = this.getActividadesSelected();
        this.lineasVentaService.getLineasVentaPorActividad(actividad).subscribe(
          (lineas: any) => {
            this.listaLineasVenta = lineas;
            if (this.modoEdicion && this.primeraCarga) {
              this.formulario.controls.lineasVentas.setValue(
                this.usuario.lineasVentas
              );
            }
            this.removeLineasVenta();
            this.formulario.updateValueAndValidity();
            this.spinner.hide();
          },
          () => {
            this.spinner.hide();
          }
        );
      } else {
        this.listaLineasVenta = [];
        this.formulario.controls.lineasVentas.setValue([]);
      }
    }
  }

  private callGetAmbitosByZona(): Observable<any> {
    const zonas = this.getIdZonasSelected();
    return zonas.length === 0
      ? of(undefined)
      : this.territoriosService.getAmbitosZona(zonas);
  }

  private getIdZonasSelected(): number[] {
    const idsZonas: number[] = [];
    const zonas = this.formulario.get('zonas')?.value as Zona[];
    for (const zona of zonas) {
      idsZonas.push(zona.id);
    }
    return idsZonas;
  }

  cambioZona(): void {
    if (this.formulario) {
      this.spinner.show();
      this.callGetAmbitosByZona().subscribe(
        (ambitos) => {
          if (ambitos) {
            this.listaAmbitos = ambitos;
            this.disabledAmbitos = false;
            this.removeAmbitosZona();
            this.cambioAmbito();
          } else {
            this.listaAmbitos = [];
            this.disabledAmbitos = true;
            this.formulario.controls.ambitos.setValue([]);
            this.formulario.controls.provincias.setValue([]);
          }
          this.spinner.hide();
        },
        (error) => {
          this.spinner.hide();
          console.error(error);
        }
      );
    }
  }

  private removeProvincias(): void {
    if (this.formulario) {
      const provinciasOld = this.formulario.get('provincias')
        ?.value as Provincia[];
      const provinciasNew = [] as Provincia[];
      provinciasOld.forEach((provincia) => {
        const id = provincia.id;
        this.listaProvincias.forEach((item) => {
          if (item.id === id) {
            provinciasNew.push(item);
          }
        });
      });
      this.formulario.controls.provincias.setValue(provinciasNew);
    }
  }

  private removeLineasVenta(): void {
    if (this.formulario) {
      const lineasOld = this.formulario.get('lineasVentas')
        ?.value as LineasVenta[];
      const lineasNew = [] as LineasVenta[];
      lineasOld.forEach((linea) => {
        const id = linea.id;
        this.listaLineasVenta.forEach((item) => {
          if (item.id === id) {
            lineasNew.push(item);
          }
        });
      });
      this.formulario.controls.lineasVentas.setValue(lineasNew);
    }
  }

  private removeAmbitosZona(): void {
    if (this.formulario) {
      const ambitosOld = this.formulario.get('ambitos')?.value as Ambito[];
      const ambitosNew = [] as Ambito[];
      ambitosOld.forEach((ambito) => {
        const id = ambito.id;
        this.listaAmbitos.forEach((item) => {
          if (item.id === id) {
            ambitosNew.push(item);
          }
        });
      });
      this.formulario.controls.ambitos.setValue(ambitosNew);
    }
  }

  private callGetProvinciasByAmbito(): Observable<any> {
    const ambitos = this.getAmbitosSelected();
    return ambitos.length === 0
      ? of(undefined)
      : this.territoriosService.getProvinciasAmbito(ambitos);
  }

  private getAmbitosSelected(): number[] {
    const idAbmbitos: number[] = [];
    const ambitos = this.formulario.get('ambitos')?.value as Ambito[];
    for (const value of ambitos) {
      this.listaAmbitos.forEach((ambito) => {
        if (ambito.id === value.id) {
          idAbmbitos.push(value.id);
        }
      });
    }
    return idAbmbitos;
  }

  cambioAmbito(): void {
    if (this.formulario) {
      this.spinner.show();
      this.callGetProvinciasByAmbito().subscribe(
        (res) => {
          if (res) {
            this.listaProvincias = res;
            this.disabledProvincias = false;
            this.spinner.hide();
            console.log(this.formulario);
            this.removeProvincias();
          } else {
            this.listaProvincias = [];
            this.disabledProvincias = true;
            this.formulario.controls.provincias.setValue([]);
          }
          this.spinner.hide();
        },
        (error) => {
          console.error(error);
          this.spinner.hide();
        }
      );
    }
  }

  cambioRegion(): void {
    if (this.formulario) {
      const regiones =
        this.primeraCarga && this.modoEdicion
          ? this.usuario.regiones
          : (this.formulario.get('regiones')?.value as Region[]);
      const idRegiones = regiones
        ? this.formulario.get('regiones')?.value.map((r: any) => r.id)
        : [];

      if (idRegiones.length === 0) {
        this.listaProvincias = [];
        this.disabledProvincias = true;
        this.formulario.controls.provincias.setValue([]);
      } else {
        this.spinner.show();
        this.disabledProvincias = false;
        this.territoriosService.getProvinciasRegiones(idRegiones).subscribe(
          (provincias) => {
            this.listaProvincias = provincias;
            this.removeProvincias();
            this.disabledProvincias = false;
            this.spinner.hide();
            if (this.primeraCarga) {
              this.primeraCarga = false;
            }
          },
          (error) => {
            console.error(error);
            this.spinner.hide();
          }
        );
      }
    }
  }

  crearUsuario(): Usuario {
    return {
      email: this.formulario.get('email')?.value,
      nombre: this.formulario.get('nombre')?.value,
      apellido: this.formulario.get('apellido')?.value,
      dni: this.formulario.get('dni')?.value,
      empresa: this.formulario.get('empresa')?.value,
      emailEditor: this.sesionService.getUser().email,
      rol: this.formulario.get('rol')?.value,
      puesto: this.formulario.get('puesto')?.value,
      provincias: this.formulario.get('provincias')?.value,
      regiones: this.formulario.get('regiones')?.value,
      zonas: this.formulario.get('zonas')?.value,
      ambitos: this.formulario.get('ambitos')?.value,
      actividades: this.formulario.get('actividades')?.value,
      tipoSolicitante: this.formulario.get('tipoSolicitante')?.value,
      lineasVentas: this.formulario.get('lineasVentas')?.value,
      informeDatastudio: this.formulario.get('informedatastudio')?.value,
    };
  }

  callCrearUsuario(): void {
    this.formulario.markAllAsTouched();
    if (this.formulario.valid) {
      const usuario = this.crearUsuario();
      this.spinner.show();
      this.usuariosService.validaUsuario(usuario.email).subscribe(
        () => {
          this.usuariosService.altaUsuario(usuario).subscribe(() => {
            this.spinner.hide();
            this.modalOperacionOK();
          });
        },
        (errorValUsuario: any) => {
          this.spinner.hide();
          const customError = {
            title: 'Error',
            msg: '',
          } as ModalInfo;
          switch (errorValUsuario.status) {
            case 400:
              customError.msg =
                "El email del usuario no cumple con el formato esperado: 'usuario@eulen.com'";
              break;
            case 409:
              if (errorValUsuario.error === '1') {
                customError.msg =
                  'El usuario existe en el sistema dado de baja. ¿Desea conservar la configuración que ya tenía o aplicar la nueva seleccionada?';
                customError.buttons = [
                  { title: 'Conservar', class: 'btn-warning', action: '1' },
                  { title: 'Crear Nueva', class: 'btn-primary', action: '2' },
                ];
              } else {
                customError.msg =
                  'No se puede realizar la acción. El email introducido ya existe en la Base de Datos.';
                customError.buttons = [
                  { title: 'Aceptar', class: 'btn-primary' },
                ];
              }
              break;
            default:
              customError.msg =
                'Ha ocurrido un error inesperado. Por favor, inténtelo más tarde.';
          }
          this.dialog
            .open(ModalInfoComponent, {
              disableClose: true,
              autoFocus: false,
              data: customError,
            })
            .afterClosed()
            .subscribe((resp) => {
              this.handleModalInfo(resp, usuario);
            });
        }
      );
    }
  }

  private handleModalInfo(resp: any, usuario: any): void {
    if (resp) {
      this.spinner.show();
      if (resp === '1') {
        this.usuariosService
          .activarUsuario(usuario.email, this.sesionService.getUser()?.email)
          .subscribe(() => {
            this.spinner.hide();
            this.modalOperacionOK();
          });
      } else {
        this.usuariosService
          .activarUsuario(usuario.email, this.sesionService.getUser()?.email)
          .subscribe(() => {
            this.usuariosService.actualizarUsuario(usuario).subscribe(() => {
              this.spinner.hide();
              this.modalOperacionOK();
            });
          });
      }
    }
  }

  modalOperacionOK(): void {
    const data = {
      title: 'Información',
      msg: 'Operación realizada correctamente...',
    };
    const dialog = this.dialog.open(ModalInfoComponent, {
      disableClose: true,
      autoFocus: false,
      data,
    });
    dialog.afterClosed().subscribe((res) => {
      this.router.navigate(['/usuarios']);
    });
  }

  redirect(): void {
    this.router.navigate(['/usuarios']);
  }

  callEditar(): void {
    this.formulario.markAllAsTouched();
    if (this.formulario.valid) {
      const usuario = this.crearUsuario();
      this.spinner.show();
      this.usuariosService.actualizarUsuario(usuario).subscribe(
        (res) => {
          this.formulario.updateValueAndValidity();
          this.spinner.hide();
          this.modalOperacionOK();
        },
        (error) => console.log(error)
      );
    }
  }

  callAdminGoogle(): void {
    if (this.formulario) {
      this.spinner.show('googleload');
      this.formulario.controls.nombre.setValue('');
      this.formulario.controls.apellido.setValue('');
      this.usuariosService
        .admingoogledirectory(this.formulario.get('email')?.value)
        .subscribe(
          (res) => {
            if (res.nombre) {
              this.formulario.controls.nombre.setValue(res.nombre);
              this.formulario.controls.apellido.setValue(res.apellidos);
              const data = {
                title: 'Información',
                msg: 'Se ha encontrado la información del usuario',
              };
              this.dialog.open(ModalInfoComponent, {
                disableClose: true,
                autoFocus: false,
                data,
              });
            } else {
              const data = {
                title: 'Información',
                msg: 'No se ha encontrado informacion sobre la dirección de email introducida',
              };
              this.dialog.open(ModalInfoComponent, {
                disableClose: true,
                autoFocus: false,
                data,
              });
            }
            this.spinner.hide('googleload');
          },
          (error) => {
            console.log(error);
            this.spinner.hide('googleload');
          }
        );
    }
  }
}

<app-modal-notificar
  id="modal-notificar"
  #modalnotificar
  (emitirNotificar)="notificadores($event)"
></app-modal-notificar>
<ngx-spinner></ngx-spinner>
<app-mensaje #mensaje></app-mensaje>
<div class="container espacio">
  <div class="d-flex justify-content-between mb-3">
    <h1>Reabrir petición</h1>
  </div>

  <div class="row">
    <div class="col-12">
      <form [formGroup]="formulario" *ngIf="formulario">
        <div class="descripcion">
          <div class="col-lg-8 seccion-comentario">
            <div class="row">
              <div class="col-sm-12 col-lg-6">
                <span
                  _ngcontent-sbf-c163
                  class="etiqueta font-weight-bold"
                  style="font-size: 1.1rem"
                  >Petición Nº:
                </span>
                <span
                  _ngcontent-sbf-c163
                  class="etiqueta font-weight-bold"
                  id="idPeticion"
                  class="texto"
                  >{{ peticion.idPeticion }}
                </span>
              </div>
              <div class="col-sm-12 col-lg-6">
                <span
                  _ngcontent-sbf-c163
                  class="etiqueta font-weight-bold"
                  style="font-size: 1rem"
                  >Tipo de petición:
                </span>
                <span
                  _ngcontent-sbf-c163
                  class="etiqueta font-weight-bold"
                  class="catalogo"
                  >{{ peticion.catalogo?.descripcion }}
                </span>
                <br />
              </div>
            </div>
            <hr _ngcontent-ewq-c163 />
            <div>
              <span
                _ngcontent-sbf-c163
                class="etiqueta font-weight-bold"
                style="font-size: 1rem"
                >Solicitante:
              </span>
              <span
                _ngcontent-sbf-c163
                class="etiqueta font-weight-bold"
                class="usuarioPeticionario"
                >{{ peticion.usuarioPeticionario?.email }}
              </span>
              <br />
              <hr _ngcontent-ewq-c163 />
            </div>
            <div>
              <span
                _ngcontent-sbf-c163
                class="etiqueta font-weight-bold"
                style="font-size: 1rem"
                >Descripcion:
              </span>
              <br />
              <span
                _ngcontent-sbf-c163
                class="etiqueta font-weight-bold"
                class="descripcion"
                >{{ peticion.descripcion }}
              </span>
              <br />
              <hr _ngcontent-ewq-c163 />
            </div>
            <div class="row">
              <div
                *ngIf="estaInformada"
                class="col alert alert-warning text-center"
                role="alert"
              >
                Esta petición todavía no ha sido informada
              </div>
            </div>
            <div
              class="form-group"
              style="margin-top: 3rem"
              *ngIf="permisoEscritura"
            >
              <span *ngIf="borrador" class="badge badge-success">Borrador</span>
              <div>
                <button
                  type="button"
                  class="btn btn-outline-secondary fa fa-eraser limpiar"
                  style="float: right"
                  (click)="limpiar()"
                >
                  Limpiar
                </button>
              </div>
              <h4>
                Comentario:
                <span *ngIf="motivoCierre === '13'" style="color: red">*</span>
              </h4>
              <textarea
                id="msg"
                class="form-control"
                formControlName="msg"
                rows="3"
                trim="blur"
                maxlength="3000"
                (keypress)="onKeyPress($event)"
                (keyup)="onKeyUp($event)"
                required
                (blur)="controlForm()"
              ></textarea>
              <div class="input-group">
                <span class="col col-lg-8"
                  >Máximo: 3000. Total: {{ contador }}
                </span>
                <small
                  class="form-text text-danger col col-lg-4 error-msg"
                  *ngIf="
                    !formulario.get('msg')!.valid &&
                    formulario.get('msg')!.touched &&
                    formulario.controls.msg.errors?.required &&
                    motivoCierre === '13'
                  "
                  >* El campo es obligatorio</small
                >
              </div>
              <button
                class="btn btn-outline-primary customButton btn-enviar col col-lg-3"
                [disabled]="deshabilitarBoton()"
                (click)="cerrarPeticion()"
              >
                Reabrir Petición
              </button>
              <button
                class="btn btn-success btn-enviar col col-lg-3"
                style="margin-right: 12px"
                [disabled]="!this.formulario.valid"
                (click)="crearReabrirBorrador()"
              >
                Guardar borrador
              </button>
              <button
                class="btn btn-danger btn-enviar col col-lg-3"
                style="margin-right: 12px"
                [disabled]="!this.formulario.valid"
                (click)="eliminarReabrirBorrador()"
              >
                Eliminar borrador
              </button>
              <p-toast></p-toast>
            </div>
          </div>
        </div>
      </form>
      <button class="btn btn-outline-secondary" (click)="volver()">
        Volver
      </button>
    </div>
  </div>
</div>

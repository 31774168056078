import {
  Component,
  EventEmitter,
  Injector,
  OnInit,
  Output,
  Inject,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from 'primeng/api';
import { ModalAccion } from 'src/app/modelos/moda-info';
import {
  NotificadoAccion,
  NotificadosAccion,
} from 'src/app/modelos/notificado-accion';
import { PeticionService } from 'src/app/servicios/peticion/peticion.service';
import { UsuariosService } from 'src/app/servicios/usuarios/usuarios.service';
import { SesionService } from '../../../servicios/sesion/sesion.service';
import { Peticion } from '../../../modelos/peticion';
import { Usuario } from '../../../modelos/usuario';
import { NuevoModificarDestinatarioRequest } from '../../../modelos/NuevoModificarDestinatarioRequest';
import { Constantes } from '../../../utils/constantes';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { timeout } from 'rxjs/operators';
import { SubjectsService } from 'src/app/servicios/subjects/subjects.service';
import { MensajeComponent } from '../../mensaje/mensaje.component';

@Component({
  selector: 'app-modal-notificar',
  templateUrl: './modal-notificar.component.html',
  styleUrls: ['./modal-notificar.component.scss'],
})
export class ModalNotificarComponent implements OnInit {
  @ViewChild('mensaje', { static: false })
  public mensaje!: MensajeComponent;

  private peticionService!: PeticionService;
  private messageService!: MessageService;
  private sesionService!: SesionService;
  private spinner!: NgxSpinnerService;
  private usuariosService!: UsuariosService;

  public notificados: NotificadoAccion[];
  public modalAccion: ModalAccion;
  public formulario!: FormGroup;
  public listaTotal: string[] = [];
  public idPeticionAccion!: string;
  public idPeticion!: string;
  public peticion!: Peticion;
  public divotros = true;
  public idAccion!: number;
  public msg!: string;
  public fecha!: Date;
  public files: any[] = [];
  public emailDestinatario!: string;
  public verModal = false;
  public dni!: string;
  public nombre!: string;
  public apellido!: string;
  public mail!: string;
  public cambio!: boolean;
  public borrador!: number;
  public esRechazo!: boolean;
  public esBorrador!: boolean;
  contador!: number;

  public listaEmailsFiltered: any[] = [];
  public listaOtros: any[] = [];
  public messageOtros =
    'No hay resultados o se ha excedido el limite de emails';

  public seleccionarTodos = true;

  @Output()
  emitirNotificar = new EventEmitter();

  @Output()
  emitirCancelar = new EventEmitter();

  constructor(
    private inject: Injector,
    private fb: FormBuilder,
    private router: Router,
    private constantes: Constantes,
    @Inject(DOCUMENT) private document: Document,
    private subjectsService: SubjectsService
  ) {
    this.initServiceProperties();
    this.notificados = [];
    this.seleccionarTodos = false;
    this.modalAccion = {
      mensaje: '',
      titulo: '',
      listaNotificados: [],
      listaAnadidos: [],
      isListInfo: true,
    };
  }

  ngOnInit(): void {
    this.initForm();
    this.contador = 0;
  }

  initServiceProperties(): void {
    this.peticionService = this.inject.get<PeticionService>(PeticionService);
    this.messageService = this.inject.get<MessageService>(MessageService);
    this.sesionService = this.inject.get<SesionService>(SesionService);
    this.spinner = this.inject.get<NgxSpinnerService>(NgxSpinnerService);
    this.usuariosService = this.inject.get<UsuariosService>(UsuariosService);
  }

  public initForm(): void {
    this.formulario = this.fb.group({
      notificados: [['']],
      otros: [[]],
      seleccionarTodos: [false],
      motivo_cambio: ['', [Validators.required, Validators.maxLength(3000)]],
    });
  }

  public mostrarModalAclaracion(
    peticion: Peticion,
    idAccion: number,
    accion: string,
    msg: string,
    files: any[],
    idBorrador: number,
    borrador: boolean
  ): void {
    this.document.body.style.overflowY = 'disabled';
    const mensajeVentana = 'Se va a realizar una aclaración sobre la petición.';
    const idPeticion = peticion.idPeticion;
    this.mostrarModal(idPeticion, idAccion, accion, mensajeVentana, null);
    this.msg = msg;
    this.files = files;
    this.borrador = idBorrador;
    this.esBorrador = borrador;
  }

  public mostrarModalMotivoCierre(
    peticion: Peticion,
    idAccion: number,
    accion: string,
    msg: string,
    files: any[],
    tupla: [idBorrador: number, esRechazo: boolean, borrador: boolean]
  ): void {
    const mensajeVentana = 'Se va a cerrar la petición.';
    const idPeticion = peticion.idPeticion;
    this.mostrarModal(idPeticion, idAccion, accion, mensajeVentana, null);
    this.msg = msg;
    this.files = files;
    this.borrador = tupla[0];
    this.esRechazo = tupla[1];
    this.esBorrador = tupla[2];
  }

  public mostrarModalResponder(
    idPeticion: string,
    idAccion: number,
    accion: string,
    estado: string
  ): void {
    const mensajeVentana = `La petición va a cambiar al estado: ${estado}.`;
    this.mostrarModal(idPeticion, idAccion, accion, mensajeVentana, null);
  }

  public mostrarModalReabrir(
    idPeticion: string,
    idAccion: number,
    accion: string,
    msg: string,
    files: null,
    idBorrador: number,
    borrador: boolean
  ): void {
    const mensajeVentana = `La petición se va a reabrir.`;
    this.mostrarModal(idPeticion, idAccion, accion, mensajeVentana, null);
    this.idPeticion = idPeticion;
    this.msg = msg;
    this.fecha = new Date();

    this.borrador = idBorrador;
    this.esBorrador = borrador;
  }

  public mostrarModal(
    idPeticion: string,
    idAccion: number,
    accion: string,
    mensajeVentana: string,
    nuevoDestinatario: Usuario | null
  ): void {
    this.document.body.style.overflowY = 'hidden';
    this.verModal = true;
    this.idPeticionAccion = idPeticion;
    this.idAccion = idAccion;
    this.listaTotal = [];
    this.peticionService
      .obtenerNotificadosPeticion(idPeticion, idAccion)
      .subscribe(
        (result: NotificadosAccion) => {
          this.mostrarMensajeNotificados(
            result,
            accion,
            mensajeVentana,
            nuevoDestinatario
          );
        },
        (error) => {
          this.mostrarError(error);
          timeout(3000);
          setTimeout(() => {
            this.verModal = false;
            this.document.body.style.overflowY = 'auto';
          }, 3000);
        }
      );
  }

  public mostrarModalAclarar(
    peticion: Peticion,
    idAccion: number,
    accion: string,
    mensajeVentana: string
  ): void {
    this.document.body.style.overflowY = 'hidden';
    this.verModal = true;
    this.idPeticionAccion = peticion.idPeticion || '';
    this.idAccion = idAccion;
    this.listaTotal = [];
    this.spinner.show();
    this.peticionService
      .obtenerNotificadosPeticion(peticion.idPeticion || '', idAccion)
      .subscribe(
        () => {
          this.spinner.hide();
          this.mostrarMensajeAclaracion(accion, mensajeVentana, peticion);
        },
        (error) => {
          this.mostrarError(error);
        }
      );
  }

  private mostrarMensajeAclaracion(
    accion: string,
    mensajeVetana: string,
    peticion?: Peticion
  ): void {
    this.initForm();
    this.formulario.disable();
    this.divotros = false;
    const emailUser = this.sesionService.getUser().email;
    if (emailUser === peticion?.usuarioDestinatario?.email) {
      this.notificados = [
        {
          nombrePerfil: 'Soliciante',
          email: peticion?.usuarioPeticionario?.email || '',
        },
      ];
    } else {
      this.notificados = [
        {
          nombrePerfil: 'Destinatario',
          email: peticion?.usuarioDestinatario?.email || '',
        },
      ];
    }
    this.listaTotal = this.notificados.map((e) => e.email);
    this.formulario.controls.notificados.setValue(this.listaTotal);
    this.showAccionPeticion(mensajeVetana, accion, []);
  }

  public mostrarModalEditar(
    idPeticion: string,
    idAccion: number,
    accion: string,
    destinatario: Usuario,
    tupla: [
      dni: string,
      nombre: string,
      apellido: string,
      mail: string,
      cambio: boolean
    ]
  ): void {
    this.emailDestinatario = destinatario.email;
    this.dni = tupla[0];
    this.nombre = tupla[1];
    this.apellido = tupla[2];
    this.mail = tupla[3];
    this.cambio = tupla[4];
    const mensajeVentana = 'Se va a modificar el destinatario de la petición.';
    if (tupla[4]) {
      this.mostrarModal(
        idPeticion,
        idAccion,
        accion,
        mensajeVentana,
        destinatario
      );
    } else {
      this.idPeticionAccion = idPeticion;
      this.modificarDestinatario();
    }
  }

  private mostrarMensajeNotificados(
    result: NotificadosAccion,
    accion: string,
    mensajeVentana: string,
    nuevoDestinatario: Usuario | null
  ): void {
    this.initForm();
    this.formulario.enable();
    this.listaOtros = [];
    this.divotros = true;
    // Si la pantalla solicitar es 1 se deshabilita el uso del modal; si 0 se habilita
    if (result.pantalla_solicitar === 0 || result.pantalla_solicitar === 1) {
      if (result.pantalla_solicitar === 1) {
        this.formulario.disable();
        this.divotros = false;
      }
      this.notificados = result.listaNotificados;

      // Si es un cambio de destinatario hay que cambiar el destinatario en la lista de notificados
      this.cambiarDestinatarioNotificacion(
        nuevoDestinatario,
        result.listaNotificados
      );

      this.listaTotal = [];
      if (this.listaTotal) {
        const i = result.listaNotificados.filter(
          (item) =>
            item.nombrePerfil === 'Solicitante' ||
            item.nombrePerfil === 'Destinatario'
        );
        if (i) {
          this.listaTotal = i.map((n) => n.email);
          mensajeVentana =
            mensajeVentana + ' Las siguientes personas serán notificadas: ';
        }
      }
      if (accion == 'Realizar Aclaración') {
        let user = this.sesionService.getUser().email;
        this.listaTotal = this.listaTotal.filter((item) => item !== user);
      }
      this.showAccionPeticion(mensajeVentana, accion, this.notificados);
    } else {
      this.notificados = [];
      this.listaTotal = [];
      this.divotros = false;
      this.showAccionPeticion(mensajeVentana, accion, this.notificados);
    }
  }

  filterOtros(event: any): void {
    if (this.listaOtros.length < 3) {
      this.usuariosService.buscarEmailForm(event.query).subscribe((data) => {
        this.listaEmailsFiltered = data;
      });
    } else {
      this.listaEmailsFiltered = [];
    }
  }

  private cambiarDestinatarioNotificacion(
    nuevoDestinatario: Usuario | null,
    listaNotificados: NotificadoAccion[]
  ): void {
    if (nuevoDestinatario) {
      const destinatarioNotificado = listaNotificados.filter(
        (item) => item.nombrePerfil === 'Destinatario'
      );
      if (
        destinatarioNotificado &&
        destinatarioNotificado.length > 0 &&
        destinatarioNotificado[0].email
      ) {
        destinatarioNotificado[0].email = nuevoDestinatario.email;
      }
    }
  }

  public cerrarModal(): void {
    this.messageService.clear();
    if (this.idAccion === this.constantes.ACCION_EDITAR) {
      this.emitirCancelar.emit();
    }
    this.verModal = false;
    this.document.body.style.overflowY = 'auto';
  }

  public showAccionPeticion(
    msg: string,
    title: string,
    notificados: any,
    anadidos?: any
  ): void {
    this.messageService.clear();

    this.modalAccion = {
      mensaje: msg,
      titulo: title,
      isListInfo: true,
      listaNotificados: notificados,
      listaAnadidos: anadidos,
    };
    this.messageService.add({ key: 'accion', severity: 'info', sticky: true });
  }

  public onKeyUp(event: any): void {
    this.contador = event.target.value.length;
  }

  public onKeyPress(event: any): any {
    if (event.keyCode === 39 || event.keyCode === 96 || event.keyCode === 180) {
      return false;
    }
    return true;
  }
  public controlForm(): void {
    if (this.formulario.get('motivo_cambio')?.value === '') {
      this.contador = 0;
    } else {
      this.contador = this.formulario.get('motivo_cambio')?.value.trim().length;
    }
  }

  public aceptarModal(): void {
    this.listaTotal = this.formulario.get('notificados')?.value;
    const otros = this.listaOtros;
    this.listaTotal = this.listaTotal.concat(otros);
    this.ejecutarAccion();
    this.verModal = false;
    this.document.body.style.overflowY = 'auto';
  }

  private ejecutarAccion(): void {
    switch (this.idAccion) {
      case this.constantes.ACCION_EDITAR:
        this.modificarDestinatario();
        break;
      case this.constantes.ACCION_ACLARACION:
        this.solicitarAclaracion();
        break;
      case this.constantes.ACCION_RESPONDER:
        this.responderPeticion();
        break;
      case this.constantes.ACCION_RECHAZAR:
        this.rechazarResolverPeticion(
          this.constantes.ENDPOINT_API_PETICION_RECHAZAR
        );
        break;
      case this.constantes.ACCION_RESOLVER_CERRAR:
        this.rechazarResolverPeticion(
          this.constantes.ENDPOINT_API_PETICION_RESOLVER
        );
        break;
      case this.constantes.ACCION_REABRIR:
        this.reabrir();
        break;
    }
  }

  private reabrir(): void {
    this.spinner.show();
    this.peticionService
      .reabrirPeticion(this.idPeticion, this.crearAccionRequestReabrir())
      .subscribe(
        () => {
          this.handleResponseOk();
          this.router.navigate(['peticiones/actuaciones']);
        },
        (error) => {
          this.mostrarError(error);
        }
      );
  }

  private responderPeticion(): void {
    this.spinner.show();
    this.peticionService
      .responderPeticion(this.idPeticionAccion, this.listaTotal)
      .subscribe(
        () => {
          this.handleResponseOk();
        },
        (error) => {
          this.mostrarError(error);
        }
      );
  }

  private solicitarAclaracion(): void {
    this.spinner.show();
    if (!this.esBorrador) {
      this.peticionService
        .aclararPeticion(
          this.idPeticionAccion,
          this.crearAccionRequest(),
          this.files
        )
        .subscribe(
          () => {
            this.handleResponseOk();
          },
          (error) => {
            this.mostrarError(error);
          }
        );
    } else {
      this.peticionService
        .actualizarAclaracionBorrador(
          this.idPeticionAccion,
          this.crearAccionRequest(),
          this.files
        )
        .subscribe(
          () => {
            this.handleResponseOk();
          },
          (error) => {
            this.mostrarError(error);
          }
        );
    }
  }

  private rechazarResolverPeticion(endpoint: string): void {
    this.spinner.show();
    this.peticionService
      .rechazarResolverPeticion(
        this.idPeticionAccion,
        this.crearAccionRequest(),
        this.files,
        endpoint
      )
      .subscribe(
        (result: Peticion) => {
          this.handleResponseOk();
          this.router.navigate(['peticiones/actuaciones']);
        },
        (error) => {
          this.mostrarError(error);
        }
      );
  }

  private mostrarError(error: any): void {
    console.log(error);
    this.spinner.hide();
    this.subjectsService.emitirEmitirError(error);
  }

  private handleResponseOk(): void {
    this.messageService.clear();
    this.emitirNotificar.emit(this.idPeticionAccion);
    this.initForm();
  }

  private crearAccionRequest(): any {
    console.log('Borrador, ', this.borrador);
    return {
      notificados: this.listaTotal,
      usuario: { email: this.sesionService.getUser().email } as Usuario,
      peticion: { idPeticion: this.idPeticionAccion } as Peticion,
      texto: this.msg,
      borrador: this.borrador,
      esRechazo: this.esRechazo,
      esBorrador: this.esBorrador,
    };
  }

  private crearAccionRequestReabrir(): any {
    return {
      notificados: this.listaTotal,
      usuario: { email: this.sesionService.getUser().email } as Usuario,
      peticion: { idPeticion: this.idPeticionAccion } as Peticion,
      texto: this.msg,
      fecha: this.fecha,
    };
  }

  private modificarDestinatario(): void {
    this.spinner.show();
    const request: NuevoModificarDestinatarioRequest = {
      emailDestinatario: this.emailDestinatario,
      notificados: this.listaTotal,
      dni: this.dni,
      nombre: this.nombre,
      apellido: this.apellido,
      mail: this.mail,
      cambio: this.cambio,
      motivo: this.formulario.get('motivo_cambio')?.value,
    };
    this.peticionService
      .actualizarDestinatarioSolicitud(this.idPeticionAccion, request)
      .subscribe(
        (resPeticion) => {
          this.handleResponseOk();
        },
        (error) => {
          this.mostrarError(error);
        },
        () => {
          this.spinner.hide();
        }
      );
  }

  public cambioSeleccionarTodos(): void {
    if (this.formulario.get('seleccionarTodos')?.value === true) {
      this.listaTotal = this.notificados.map((n) => n.email);
      this.formulario.controls.notificados.setValue(this.listaTotal);
    } else {
      this.listaTotal = [];
      this.formulario.controls.notificados.setValue([]);
    }
  }

  public controlNotificados(): void {
    if (
      this.formulario.get('notificados')?.value.length ===
      this.notificados.length
    ) {
      this.formulario.controls.seleccionarTodos.setValue(true);
    } else {
      this.formulario.controls.seleccionarTodos.setValue(false);
    }
  }
}

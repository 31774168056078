<app-mensaje #mensaje (customAceptar)="aceptar()"></app-mensaje>
<div class="d-flex justify-content-end mb-3">
  <button
    class="btn btn-outline-primary customButton fa fa-plus-circle"
    (click)="createConvenio()"
  >
    Crear Convenio
  </button>
</div>
<p-table
  id="tabla-convenios"
  #dt1
  [value]="convenios"
  dataKey="id"
  [showCurrentPageReport]="true"
  styleClass="p-datatable-responsive-demo"
  [rows]="rows"
  [totalRecords]="resultadosTotales"
  [columns]="cols"
  [lazy]="true"
  (onLazyLoad)="nextPage($event, dt1)"
  [paginator]="true"
  [globalFilterFields]="cols"
  sortField="denominacion"
  [sortOrder]="1"
  currentPageReportTemplate="Página {{ paginaActual }} de {{ paginasTotales }}"
  [rowsPerPageOptions]="resultadosPorPagina"
  [(first)]="first"
>
  <ng-template pTemplate="caption" class="caption">
    <button class="btn customButton boton-excel" (click)="exportarDatos()">
      <i class="fa fa-file-excel-o" aria-hidden="true"></i> EXPORTAR
    </button>
  </ng-template>

  <ng-template pTemplate="header">
    <tr>
      <th scope="{{ cols[0].field }}" pSortableColumn="{{ cols[0].field }}">
        <div class="p-d-flex p-jc-between p-ai-center">
          {{ cols[0].header }}
          <p-sortIcon
            ariaLabel="Activar para ordenar"
            ariaLabelDesc="Activar para ordenar en orden descendiente"
            ariaLabelAsc="Activar para ordenar en orden ascendiente"
            field="{{ cols[0].field }}"
          ></p-sortIcon>
        </div>
      </th>
      <th scope="{{ cols[1].field }}" pSortableColumn="{{ cols[1].field }}">
        <div class="p-d-flex p-jc-between p-ai-center">
          {{ cols[1].header }}
          <p-sortIcon
            ariaLabel="Activar para ordenar"
            ariaLabelDesc="Activar para ordenar en orden descendiente"
            ariaLabelAsc="Activar para ordenar en orden ascendiente"
            field="{{ cols[1].field }}"
          ></p-sortIcon>
        </div>
      </th>
      <th scope="{{ cols[2].field }}" pSortableColumn="{{ cols[2].field }}">
        <div class="p-d-flex p-jc-between p-ai-center">
          {{ cols[2].header }}
          <p-sortIcon
            ariaLabel="Activar para ordenar"
            ariaLabelDesc="Activar para ordenar en orden descendiente"
            ariaLabelAsc="Activar para ordenar en orden ascendiente"
            field="{{ cols[2].field }}"
          ></p-sortIcon>
        </div>
      </th>
      <th scope="{{ cols[3].field }}" pSortableColumn="{{ cols[3].field }}">
        <div
          class="p-d-flex p-jc-between p-ai-center"
          pTooltip="{{ cols[3].toolTip }}"
          tooltipPosition="top"
        >
          {{ cols[3].header }}
          <p-sortIcon
            ariaLabel="Activar para ordenar"
            ariaLabelDesc="Activar para ordenar en orden descendiente"
            ariaLabelAsc="Activar para ordenar en orden ascendiente"
            field="{{ cols[3].field }}"
          ></p-sortIcon>
        </div>
      </th>
      <th scope="{{ cols[4].field }}">
        <div
          class="p-d-flex p-jc-between p-ai-center"
          pTooltip="{{ cols[4].toolTip }}"
          tooltipPosition="top"
        >
          {{ cols[4].header }}
        </div>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-convenio let-i="rowIndex">
    <tr>
      <td>
        <span class="p-column-title">DENOMINACIÓN</span
        >{{ convenio.descripcion }}
      </td>
      <td>
        <span class="p-column-title">AMBITO TERRITORIAL</span
        >{{ ambitos[convenio.ambito - 1].descripcion }}
      </td>
      <td *ngIf="convenio.provincias[0]?.descripcion">
        <span class="p-column-title">PROVINCIAS</span>
        <ng-container>
          <p *ngFor="let p of convenio.provincias; index as i">
            <span *ngIf="i < 3">{{ p.descripcion }}</span>
            <span style="color: red" *ngIf="i === 3">...</span>
          </p>
        </ng-container>
      </td>
      <td>
        <span class="p-column-title">ACTIVIDADES</span>
        <ng-container>
          <p *ngFor="let p of convenio.actividades; index as i">
            <span *ngIf="i < 3">{{ p.descripcion }}</span>
            <span style="color: red" *ngIf="i === 3">...</span>
          </p>
        </ng-container>
      </td>

      <td class="acciones text-center" id="accionesHeader">
        <button
          pTooltip="Editar"
          tooltipPosition="right"
          class="btn btn-sm btn-outline-info"
          (click)="editarConvenio(convenio.id)"
        >
          <em class="fa fa-pencil"></em>
        </button>
        <button
          *ngIf="!convenio.idBaja"
          pTooltip="Desactivar"
          tooltipPosition="right"
          class="btn btn-sm btn-outline-danger"
          (click)="showModal(convenio)"
        >
          <em class="fa fa-power-off"></em>
        </button>

        <button
          *ngIf="convenio.idBaja"
          pTooltip="Activar"
          tooltipPosition="right"
          class="btn btn-sm btn-outline-success"
          (click)="showModal(convenio)"
        >
          <em class="fa fa-reply"></em>
        </button>
      </td>
    </tr>
    <hr class="item-list-separator" />
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="12">No hay resultados</td>
    </tr>
  </ng-template>
  <ng-template pTemplate="paginatorleft"> </ng-template>
  <ng-template pTemplate="paginatorright">
    <p class="pageFooterLeft">Resultados totales: {{ resultadosTotales }}</p>
  </ng-template>
</p-table>

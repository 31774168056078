import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { GruposSoporte } from '../../../modelos/grupossoporte';
import { NgxSpinnerService } from 'ngx-spinner';
import { GrupossoporteService } from '../../../servicios/grupossoporte/grupossoporte.service';
import { Catalogo } from '../../../modelos/catalogo';
import { CatalogosService } from '../../../servicios/catalogos/catalogos.service';
import { forkJoin } from 'rxjs';
import { MensajeComponent } from '../../mensaje/mensaje.component';
import Utils from '../../../utils/utils';
import { Impacto } from '../../../modelos/impacto';
import { ImpactosService } from '../../../servicios/impactos/impactos.service';

@Component({
  selector: 'app-crear-catalogo',
  templateUrl: './crear-catalogo.component.html',
  styleUrls: ['./crear-catalogo.component.css'],
})
export class CrearCatalogoComponent implements OnInit {
  idTipoPeticion!: string | null;
  modoEdicion!: boolean;

  formulario!: FormGroup;

  @ViewChild('mensaje', { static: true })
  public mensaje!: MensajeComponent;

  listaTiposFlujos = ['GENERICO', 'AUTOMATISMO', 'AUTORIZACION'];

  listaAreasDestinos = ['RRHH', 'PRL', 'Central P.E.'];

  listaImpactos: Impacto[] = [];

  listaFechaRangos = ['Fecha', 'Rango'];

  listaTipoSolicitante = ['P.E', 'P.O', 'Ambos'];
  tipoConvertido: number = 0;

  listaGrupos: GruposSoporte[] = [];
  listaCatalogos: Catalogo[] = [];
  catalogosFiltrados: string[] = [];

  catalogo!: Catalogo;

  txtBtn = '';

  txtBtnFinal = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private grupoSoporteService: GrupossoporteService,
    private catalogosService: CatalogosService,
    private impactosService: ImpactosService
  ) {}

  ngOnInit(): void {
    this.initProperties();
  }

  private initProperties(): void {
    this.idTipoPeticion = this.route.snapshot.paramMap.get('id');
    if (this.idTipoPeticion === null) {
      this.txtBtn = 'Crear Tipo de Petición';
      this.modoEdicion = false;
    } else {
      this.txtBtn = 'Guardar';
      this.modoEdicion = true;
    }
    this.txtBtnFinal = this.txtBtn;
    this.loadData();
  }

  private loadData(): void {
    this.spinner.show();
    const llamadas = [];
    llamadas.push(
      this.grupoSoporteService.getGruposSoporte(
        '0',
        String(Number.MAX_SAFE_INTEGER),
        'descripcion',
        'desc'
      )
    );
    llamadas.push(this.catalogosService.obtenerTiposPeticiones('', true));
    llamadas.push(this.impactosService.getImpactos());
    if (this.idTipoPeticion !== null) {
      llamadas.push(this.catalogosService.getCatalogo(this.idTipoPeticion));
    }
    forkJoin(llamadas).subscribe(
      (resp: any) => {
        this.listaGrupos = resp[0].results;
        this.listaCatalogos = resp[1];
        this.listaImpactos = resp[2];
        if (this.idTipoPeticion !== null) {
          this.catalogo = resp[3];
          if (this.catalogo === null) {
            this.router.navigate(['/catalogos/crear']);
          }
        }
        this.initForm();
        this.spinner.hide();
      },
      (error: any) => {
        this.spinner.hide();
        console.error(error);
        this.mensaje.showErrorPeticion(error.error);
      }
    );
  }

  private initForm(): void {
    if (this.catalogo) {
      this.formulario = this.initFormLoaded();
      if (Utils.numberToBoolean(this.catalogo.datosEfectividad)) {
        this.formulario.controls.descripcionEfectividad.setValidators([
          Validators.required,
        ]);
      }
      this.cambioTipoFlujo();
    } else {
      this.formulario = this.initFormEmpty();
    }

    this.formulario.controls.fechaRango.valueChanges.subscribe(() => {
      this.cambioFechaRango();
    });
    this.formulario.controls.datosEfectividad.valueChanges.subscribe(() => {
      this.cambioDatosEfectividad();
    });
    this.formulario.controls.calcularSLA.valueChanges.subscribe((value) => {
      if (!value) {
        this.formulario.controls.escalado.setValue(false);
      }
    });

    this.formulario.controls.tipoFlujo.valueChanges.subscribe((value) => {
      this.cambioTipoFlujo(value);
    });
  }

  initFormLoaded(): FormGroup {
    return this.fb.group({
      id: [
        { value: this.catalogo.id, disabled: true },
        [
          Validators.required,
          Validators.maxLength(10),
          Validators.pattern('^[0-9]{3}.[0-9]{2}$'),
        ],
      ],
      descripcion: [
        {
          value: this.catalogo.descripcion,
          disabled: this.catalogo.editable !== 1,
        },
        [Validators.required, Validators.maxLength(70)],
      ],
      areaDestino: [
        {
          value: this.catalogo.areaDestino,
          disabled: this.catalogo.editable !== 1,
        },
        [Validators.required],
      ],
      tipoFlujo: [
        {
          value: this.catalogo.tipoFlujo,
          disabled: this.catalogo.editable !== 1,
        },
        [Validators.required],
      ],
      impacto: [
        {
          value: this.catalogo.impacto,
          disabled: this.catalogo.editable !== 1,
        },
        [Validators.required],
      ],
      subirArtica: [
        {
          value: Utils.numberToBoolean(this.catalogo.subirArtica),
          disabled: this.catalogo.editable !== 1,
        },
        [Validators.required],
      ],
      trabajadorAfectado: [
        {
          value: Utils.numberToBoolean(this.catalogo.trabajadorAfectado),
          disabled: this.catalogo.editable !== 1,
        },
        [Validators.required],
      ],
      clienteAfectado: [
        {
          value: Utils.numberToBoolean(this.catalogo.clienteAfectado),
          disabled: this.catalogo.editable !== 1,
        },
        [Validators.required],
      ],
      documentoObligatorio: [
        {
          value: Utils.numberToBoolean(this.catalogo.documentoObligatorio),
          disabled: this.catalogo.editable !== 1,
        },
        [Validators.required],
      ],
      fechaResolucion: [
        {
          value: Utils.numberToBoolean(this.catalogo.fechaResolucion),
          disabled: this.catalogo.editable !== 1,
        },
        [Validators.required],
      ],
      calcularSLA: [
        {
          value: Utils.numberToBoolean(this.catalogo.calcularSLA),
          disabled: this.catalogo.editable !== 1,
        },
        [Validators.required],
      ],
      escalado: [
        {
          value: Utils.numberToBoolean(this.catalogo.escalado),
          disabled: this.catalogo.editable !== 1,
        },
        [Validators.required],
      ],
      datosEfectividad: [
        {
          value: Utils.numberToBoolean(this.catalogo.datosEfectividad),
          disabled: this.catalogo.editable !== 1,
        },
        [Validators.required],
      ],
      descripcionEfectividad: [
        {
          value: this.catalogo.descFechaEfectividad,
          disabled: this.catalogo.editable !== 1,
        },
      ],
      fechaRango: [
        {
          value: this.catalogo.tipoFechaEfectividad,
          disabled: this.catalogo.editable !== 1,
        },
      ],
      obligatorioFecha: [
        {
          value: Utils.numberToBoolean(this.catalogo.obligatorioFecha),
          disabled: this.catalogo.editable !== 1,
        },
      ],
      obligatorioDesde: [
        {
          visual: Utils.numberToBoolean(this.catalogo.obligatorioDesde),
          disabled: this.catalogo.editable !== 1,
        },
      ],
      obligatorioHasta: [
        {
          value: Utils.numberToBoolean(this.catalogo.obligatorioHasta),
          disabled: this.catalogo.editable !== 1,
        },
      ],
      datosEspecificos: [
        {
          value: Utils.numberToBoolean(this.catalogo.datosEspecificos),
          disabled: this.catalogo.editable !== 1,
        },
        [Validators.required],
      ],
      grupos: [
        {
          value: this.catalogo.gruposSoporte,
          disabled: this.catalogo.editable !== 1,
        },
        [Validators.required],
      ],
      esfuerzo: [
        {
          value: this.catalogo.tiempoPromedio,
          disabled: this.catalogo.editable !== 1,
        },
        [
          Validators.required,
          Validators.maxLength(1),
          Validators.max(5),
          Validators.min(0),
          Validators.pattern('^[0-5]*$'),
        ],
      ],
      solicitante: [
        {
          value: this.listaTipoSolicitante,
          disabled: this.catalogo.editable !== 1,
        },
        [Validators.required],
      ],
      activo: [
        { value: this.catalogo.activo, disabled: this.catalogo.editable !== 1 },
      ],
    });
  }

  initFormEmpty(): FormGroup {
    return this.fb.group({
      id: [
        '',
        [
          Validators.required,
          Validators.maxLength(10),
          Validators.pattern('^[0-9]{3}.[0-9]{2}$'),
        ],
      ],
      descripcion: ['', [Validators.required, Validators.maxLength(70)]],
      areaDestino: [this.listaAreasDestinos[0], [Validators.required]],
      tipoFlujo: [this.listaTiposFlujos[0], [Validators.required]],
      impacto: [this.listaImpactos[0], [Validators.required]],
      subirArtica: [false, [Validators.required]],
      trabajadorAfectado: [false, [Validators.required]],
      fechaResolucion: [false, [Validators.required]],
      calcularSLA: [false, [Validators.required]],
      escalado: [false, [Validators.required]],
      datosEfectividad: [false, [Validators.required]],
      descripcionEfectividad: [''],
      fechaRango: [''],
      obligatorioFecha: [false],
      obligatorioDesde: [false],
      obligatorioHasta: [false],
      datosEspecificos: [false, [Validators.required]],
      grupos: [[], [Validators.required]],
      solicitante: [this.listaTipoSolicitante[0], [Validators.required]],
      clienteAfectado: [false, [Validators.required]],
      documentoObligatorio: [false, [Validators.required]],
      esfuerzo: [
        '',
        [
          Validators.required,
          Validators.maxLength(1),
          Validators.max(5),
          Validators.min(0),
          Validators.pattern('^[0-5]*$'),
        ],
      ],
      activo: [false],
    });
  }

  cambioTipoFlujo(value?: string): void {
    if ((value || this.catalogo.tipoFlujo) === this.listaTiposFlujos[1]) {
      this.formulario.controls.activo.setValue(
        this.catalogo && this.catalogo.tipoFlujo === this.listaTiposFlujos[1]
          ? this.catalogo.activo
          : false
      );
      this.formulario.controls.activo.disable();
      this.txtBtnFinal = 'Siguiente';
    } else {
      this.formulario.controls.activo.enable();
      this.txtBtnFinal = this.txtBtn;
    }
  }

  cambioDatosEfectividad(): void {
    if (this.formulario.get('datosEfectividad')?.value === true) {
      this.formulario.controls.fechaRango.setValue(this.listaFechaRangos[0]);
      this.formulario.controls.descripcionEfectividad.setValidators([
        Validators.required,
      ]);
      this.formulario.controls.obligatorioFecha.setValidators([
        Validators.required,
      ]);
      this.formulario.controls.obligatorioDesde.setValidators([
        Validators.required,
      ]);
      this.formulario.controls.obligatorioHasta.setValidators([
        Validators.required,
      ]);
    } else {
      this.formulario.controls.obligatorioFecha.setValue(false);
      this.formulario.controls.obligatorioDesde.setValue([false]);
      this.formulario.controls.obligatorioHasta.setValue(false);
      this.formulario.controls.descripcionEfectividad.setValue(null);
      this.formulario.controls.fechaRango.setValue(null);
      this.formulario.controls.descripcionEfectividad.setValidators([]);
      this.formulario.controls.obligatorioFecha.setValidators([]);
      this.formulario.controls.obligatorioDesde.setValidators([]);
      this.formulario.controls.obligatorioHasta.setValidators([]);
    }
    this.formulario.clearAsyncValidators();
  }

  cambioFechaRango(): void {
    if (this.formulario.get('fechaRango')?.value === this.listaFechaRangos[0]) {
      this.formulario.controls.obligatorioFecha.setValue(true);
      this.formulario.controls.obligatorioDesde.setValue(false);
      this.formulario.controls.obligatorioHasta.setValue(false);
      this.formulario.controls.obligatorioHasta.disable();
    } else {
      if (
        this.formulario.get('fechaRango')?.value === this.listaFechaRangos[1]
      ) {
        this.formulario.controls.obligatorioFecha.setValue(false);
        this.formulario.controls.obligatorioDesde.setValue(true);
        this.formulario.controls.obligatorioHasta.enable();
      }
    }
    this.formulario.updateValueAndValidity();
  }

  public callModificarCatalogo(): void {
    if (
      !this.formulario.dirty &&
      this.catalogo.tipoFlujo === this.listaTiposFlujos[1]
    ) {
      this.router.navigate([`/catalogos/${this.catalogo.id}/automatismos`]);
    }
    this.spinner.show();
    this.catalogosService
      .modificarCatalogo(this.crearCatalogoObject())
      .subscribe(
        (resp: Catalogo) => {
          this.spinner.hide();
          this.mensaje.showMensaje(
            'Operación realizada correctamente...',
            this.calcularRedireccion(resp)
          );
        },
        (error) => {
          this.spinner.hide();
          this.mensaje.showErrorPeticion(error.error);
        }
      );
  }

  public callCrearCatalogo(): void {
    this.spinner.show();
    this.catalogosService.crearCatalogo(this.crearCatalogoObject()).subscribe(
      (respuesta: Catalogo) => {
        this.spinner.hide();
        this.mensaje.showMensaje(
          'Operación realizada correctamente...',
          this.calcularRedireccion(respuesta)
        );
      },
      (error) => {
        this.spinner.hide();
        this.mensaje.showErrorPeticion(error.error);
      }
    );
  }

  public tipoSolicitante(tipo: string) {
    if (tipo === 'P.O') {
      this.tipoConvertido = 1;
    } else if (tipo === 'P.E') {
      this.tipoConvertido = 2;
    }
    return this.tipoConvertido;
  }

  private crearCatalogoObject(): Catalogo {
    return {
      id: this.formulario.get('id')?.value,
      descripcion: this.formulario.get('descripcion')?.value,
      areaDestino: this.formulario.get('areaDestino')?.value,
      descFechaEfectividad: this.formulario.get('descripcionEfectividad')
        ?.value,
      obligatorioDesde: Utils.booleanToNumber(
        this.formulario.get('obligatorioDesde')?.value
      ),
      obligatorioHasta: Utils.booleanToNumber(
        this.formulario.get('obligatorioHasta')?.value
      ),
      obligatorioFecha: Utils.booleanToNumber(
        this.formulario.get('obligatorioFecha')?.value
      ),
      datosEfectividad: Utils.booleanToNumber(
        this.formulario.get('datosEfectividad')?.value
      ),
      calcularSLA: Utils.booleanToNumber(
        this.formulario.get('calcularSLA')?.value
      ),
      datosEspecificos: Utils.booleanToNumber(
        this.formulario.get('datosEspecificos')?.value
      ),
      impacto: this.formulario.get('impacto')?.value,
      subirArtica: Utils.booleanToNumber(
        this.formulario.get('subirArtica')?.value
      ),
      trabajadorAfectado: Utils.booleanToNumber(
        this.formulario.get('trabajadorAfectado')?.value
      ),
      clienteAfectado: Utils.booleanToNumber(
        this.formulario.get('clienteAfectado')?.value
      ),
      codSolicitante: this.tipoSolicitante(
        this.formulario.get('solicitante')?.value
      ),
      documentoObligatorio: Utils.booleanToNumber(
        this.formulario.get('documentoObligatorio')?.value
      ),
      tipoFlujo: this.formulario.get('tipoFlujo')?.value,
      activo: Utils.booleanToNumber(this.formulario.get('activo')?.value),
      tipoFechaEfectividad: this.formulario.get('fechaRango')?.value,
      escalado: Utils.booleanToNumber(this.formulario.get('escalado')?.value),
      gruposSoporte: this.formulario.get('grupos')?.value,
      fechaResolucion: Utils.booleanToNumber(
        this.formulario.get('fechaResolucion')?.value
      ),
      tiempoPromedio: this.formulario.get('esfuerzo')?.value,
    };
  }

  redirect(): void {
    this.router.navigate(['/catalogos']);
  }

  filterCatalogos(event: any): void {
    this.catalogosFiltrados = [];
    const query = event.query;
    this.listaCatalogos.forEach((catalogo: Catalogo) => {
      if (
        catalogo.descripcion?.toLowerCase().indexOf(query.toLowerCase()) === 0
      ) {
        this.catalogosFiltrados.push(catalogo.descripcion);
      }
    });
  }

  trimDescripcion(): void {
    this.formulario.controls.descripcion.setValue(
      this.formulario.get('descripcion')?.value.trim()
    );
  }

  calcularRedireccion(resp: Catalogo): string {
    return resp.tipoFlujo === this.listaTiposFlujos[1]
      ? `/catalogos/${resp.id}/automatismos`
      : '/catalogos';
  }
}

<ngx-spinner></ngx-spinner>

<div class="container espacio">
  <div class="d-flex justify-content-between mb-3">
    <h1>Gestión de usuarios</h1>
  </div>

  <div class="card">
    <div class="card">
      <p-tabMenu [model]="items" [activeItem]="activeItem"></p-tabMenu>
    </div>

    <div class="borde" *ngIf="mostrarUsuario">
      <!-- Filtro de la tabla usuarios -->
      <app-filtro-usuario
        (desdeFiltroUsuario)="recibirFiltros($event)"
      ></app-filtro-usuario>
      <hr />
      <!-- Tabla de usuarios -->
      <app-tabla-usuarios
        [datosFiltro]="datosDelFiltro"
        (irAuditoria)="irAuditoria($event)"
      ></app-tabla-usuarios>
    </div>

    <div class="borde" *ngIf="!mostrarUsuario">
      <app-usuarios-auditoria
        [emailUsuario]="emailUsaurio"
      ></app-usuarios-auditoria>
    </div>
  </div>
</div>

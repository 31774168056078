import { Component, Injector, OnInit, ViewChildren } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { Table } from 'primeng/table';
import { Impacto } from 'src/app/modelos/impacto';
import { Sla } from 'src/app/modelos/sla';
import { Urgencia } from 'src/app/modelos/urgencia';
import { Slaservice } from 'src/app/servicios/sla/sla.service';
import { ImpactosService } from 'src/app/servicios/impactos/impactos.service';
import { UrgenciasService } from 'src/app/servicios/urgencias/urgencias.service';
import { Constantes } from 'src/app/utils/constantes';
import { ModalInfoComponent } from '../../modals/modal-info/modal-info.component';

@Component({
  selector: 'app-prioridades',
  templateUrl: './tabla-prioridades.component.html',
  styleUrls: ['./tabla-prioridades.component.scss'],
})
export class TablaPrioridadesComponent implements OnInit {
  prioridades: Sla[] = [];
  urgencias: Urgencia[] = [];
  impactos: Impacto[] = [];
  cols!: any[];
  first = 1;
  rows = 25;
  urgenciaDesc: string[] = [];
  impactoDesc: string[] = [];
  prioridadesDesc: string[] = [];

  paginasTotales = 0;
  paginaActual = 1;
  resultadosTotales = 0;
  resultadosPorPagina!: number[];

  lastSortField!: any;

  @ViewChildren('dt1')
  public table!: Table;

  private SlaService!: Slaservice;

  constructor(
    private inject: Injector,
    private spinner: NgxSpinnerService,
    private constantes: Constantes,
    public dialog: MatDialog,
    private impactosService: ImpactosService,
    private urgenciasService: UrgenciasService
  ) {
    {
      this.cols = [
        {
          field: 'prioridad',
          header: 'PRIORIDAD DE UNA PETICIÓN',
        },
        { field: 'impacto', header: 'IMPACTO' },
        { field: 'urgencia', header: 'URGENCIA' },
      ];
    }
  }

  ngOnInit(): void {
    this.initServices();

    this.callSla(this.first);
  }

  private initServices(): void {
    this.SlaService = this.inject.get<Slaservice>(Slaservice);
  }

  callSla(page: number): void {
    this.spinner.show();
    this.prioridades = [];
    this.SlaService.obtenerSlas().subscribe(
      (data: any) => {
        this.cargarDatosTabla(data);
        this.spinner.hide();
      },
      (error: any) => {
        console.error(error);
        this.spinner.hide();
      }
    );

    this.impactosService.getImpactos().subscribe(
      (data: any) => {
        this.obtenerImpacto(data);
        this.spinner.hide();
      },
      (error: any) => {
        console.error(error);
        this.spinner.hide();
      }
    );

    this.urgenciasService.getUrgencias().subscribe(
      (data: any) => {
        this.obtenerUrgencia(data);
        this.spinner.hide();
      },
      (error: any) => {
        console.error(error);
        this.spinner.hide();
      }
    );
  }

  private cargarDatosTabla(data: any): any {
    this.prioridades = data;
    this.paginaActual = 1;
    this.paginasTotales = 1;
    this.resultadosTotales = data.length;

    for (let aux of this.prioridades) {
      this.prioridadesDesc.push(aux.descripcion);
    }
  }

  private obtenerImpacto(data: any): any {
    this.impactos = data;
    this.impactos.reverse();

    for (let aux of this.impactos) {
      this.impactoDesc.push(aux.descripcion);
    }
  }

  private obtenerUrgencia(data: any): any {
    this.urgencias = data;
    this.urgencias.reverse();

    for (let aux of this.urgencias) {
      this.urgenciaDesc.push(aux.descripcion);
    }
  }

  exportarPrioridades(): void {
    this.spinner.show();
    this.SlaService.exportarPrioridades().subscribe((urlRespuesta) => {
      if (urlRespuesta) {
        // Abrir nueva pantalla con el resultado del exportar
        this.spinner.hide();
        window.open(
          urlRespuesta.url,
          '_blank',
          'location=yes, height=570, width=520, scrollbars=yes, status=yes'
        );
      } else {
        const data = {
          title: 'Información',
          msg: 'No se ha podido hacer la exportación ...',
        };
        this.dialog.open(ModalInfoComponent, {
          disableClose: true,
          autoFocus: false,
          data,
        });
      }
    });
  }
}

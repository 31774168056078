import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Convenio } from 'src/app/modelos/convenio';
import {
  ConvenioFiltro,
  ConvenioFiltroRequest,
} from 'src/app/modelos/convenio-filtro';
import { Constantes } from 'src/app/utils/constantes';
import HttpClientUtils from 'src/app/utils/http-client-utils';

@Injectable({
  providedIn: 'root',
})
export class ConveniosService {
  constructor(private http: HttpClient, private constantes: Constantes) {}

  datosFiltros!: ConvenioFiltro;

  public setFiltros(filtros: ConvenioFiltro): void {
    this.datosFiltros = filtros;
  }

  public getFiltros(): ConvenioFiltro {
    return this.datosFiltros;
  }

  getConvenios(actividad: number, provincia: number): Observable<any> {
    const params = new HttpParams()
      .set('actividad', String(actividad))
      .append('provincia', String(provincia));

    return this.http.get(this.constantes.ENDPOINT_API_CONVENIOS, { params });
  }

  public obtenerConvenios(
    offset: string,
    limit: string,
    filtros: ConvenioFiltroRequest
  ): Observable<any> {
    const params = new HttpParams()
      .set('offset', offset)
      .append('limit', limit);
    return this.http.post(
      this.constantes.ENDPOINT_API_CONVENIOS_BUSCAR,
      filtros,
      { params }
    );
  }

  buscarConvenio(cadena: string): any {
    return this.http.get(
      this.constantes.ENDPOINT_API_CONVENIOS_BUSCAR_DENOMINACION.replace(
        '{denominacion}',
        cadena
      )
    );
  }

  public cambioEstado(request: Convenio): Observable<any> {
    return this.http.put<any>(
      `${this.constantes.ENDPOINT_API_CONVENIOS}/estado/${request.id}`,
      null
    );
  }

  public exportar(filtros: ConvenioFiltroRequest): Observable<any> {
    let params = HttpClientUtils.toHttpParams(filtros);
    params = params.append('offset', '0');
    return this.http.get(
      this.constantes.ENDPOINT_API_EXPORTAR + '/exportar-convenios',
      { params }
    );
  }

  public altaConvenio(request: Convenio): Observable<Convenio> {
    return this.http.post<Convenio>(
      this.constantes.ENDPOINT_API_CONVENIOS,
      request
    );
  }

  public editarConvenio(request: Convenio): Observable<Convenio> {
    return this.http.put<Convenio>(
      this.constantes.ENDPOINT_API_CONVENIOS,
      request
    );
  }

  public obtenerConvenio(idConvenio: string): Observable<Convenio> {
    return this.http.get<Convenio>(
      this.constantes.ENDPOINT_API_CONVENIOS_OBTENER.replace(
        '{idConvenio}',
        idConvenio
      )
    );
  }
}

import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  UrlSegment,
} from '@angular/router';
import { SesionService } from '../sesion/sesion.service';

@Injectable({
  providedIn: 'root',
})
export class AdministradorPeticionesGuard implements CanActivate {
  constructor(private sesionService: SesionService, private router: Router) {}

  public canActivate(route: ActivatedRouteSnapshot): boolean {
    if (this.sesionService.getUser() === null) {
      this.router.navigate(['/']);
      return false;
    }
    if (
      this.sesionService.getUser() &&
      !this.sesionService.isGoogleUserSignedIn()
    ) {
      return true;
    }

    let esRutaPS = false;
    route.url.forEach((segmentoUrl: UrlSegment) => {
      if (segmentoUrl.path === 'actuaciones') {
        esRutaPS = true;
      }
    });

    let esGP = false;
    if (route.url.length === 1 && route.url[0].path === 'peticiones') {
      esGP = true;
    }

    const validaPS = esRutaPS && this.sesionService.getPermisos().ps === 1;
    const validaGP = !esRutaPS && this.sesionService.getPermisos().gp === 1;
    const esAuditor = this.sesionService.getUser().rol?.id === 7;

    if (esAuditor) {
      if (!esGP) {
        return true;
      }
      this.router.navigate(['/']);
      return false;
    }

    if (!validaPS && !validaGP) {
      this.router.navigate(['/']);
      return false;
    } else {
      return true;
    }
  }
}

<app-mensaje #mensaje> </app-mensaje>
<ngx-spinner></ngx-spinner>
<app-modal-artica
  id="modal-artica"
  #modalArtica
  (emitirArtica)="articaRecibida($event)"
></app-modal-artica>
<div class="d-flex justify-content-end mb-3">
  <button
    class="btn btn-outline-primary customButton fa fa-plus-circle"
    (click)="redirectToCreatePeticion()"
  >
    Crear Petición
  </button>
</div>
<p-table
  id="tabla-peticiones"
  #dt1
  [value]="peticiones"
  dataKey="idPeticion"
  [showCurrentPageReport]="true"
  styleClass="p-datatable-responsive-demo"
  [rows]="rows"
  [totalRecords]="peticionesTotales"
  [columns]="cols"
  [lazy]="true"
  (onLazyLoad)="nextPage($event, dt1)"
  [paginator]="true"
  [globalFilterFields]="cols"
  sortField="descTipoPeticion"
  [sortOrder]="-1"
  currentPageReportTemplate="Página {{ paginaActual }} de {{ paginasTotales }}"
  [rowsPerPageOptions]="peticionesPorPagina"
  [(first)]="first"
>
  <ng-template pTemplate="caption" class="caption"
    ><button class="btn customButton boton-excel" (click)="exportarDatos()">
      <i class="fa fa-file-excel-o" aria-hidden="true"></i> EXPORTAR
    </button>
  </ng-template>

  <ng-template pTemplate="header">
    <tr>
      <ng-container *ngFor="let col of cols">
        <th
          *ngIf="col.field !== 'acciones'; else accionesHeader"
          scope="{{ col.field }}"
          pSortableColumn="{{ col.field }}"
          [style]="col.style"
        >
          <div class="p-d-flex p-jc-between p-ai-center">
            {{ col.header }}
            <p-sortIcon
              *ngIf="col.field !== 'acciones'"
              field="{{ col.field }}"
            ></p-sortIcon>
          </div>
        </th>
        <ng-template #accionesHeader>
          <th scope="{{ col.field }}">
            <div class="p-d-flex p-jc-between p-ai-center">
              {{ col.header }}
            </div>
          </th>
        </ng-template>
      </ng-container>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-peticion let-i="rowIndex">
    <tr>
      <td>
        <span class="p-column-title">NÚMERO DE PETICIÓN</span>
        {{ peticion.idPeticion }}
      </td>
      <td>
        <span class="p-column-title">TIPO DE PETICIÓN</span>
        {{ peticion.descTipoPeticion }}
      </td>
      <td>
        <span class="p-column-title">ESTADO</span> {{ peticion.descEstado }}
      </td>
      <td
        [pTooltip]="
          peticion.nombreDestinatario + ' ' + peticion.apellidoDestinatario
        "
        tooltipPosition="top"
      >
        <span class="p-column-title">DESTINATARIO</span>
        {{ peticion.idDestinatario | stringFormatPipeName }}
      </td>
      <td>
        <span class="p-column-title">LÍNEA DE VENTA</span>
        {{ peticion.descLineaVenta }}
      </td>
      <td>
        <span class="p-column-title">ÁREA DE DESTINO</span>
        {{ peticion.areaDestino }}
      </td>
      <td
        class="acciones text-center"
        *ngIf="
          peticion.codEstado === constantes.ESTADO_BORRADOR;
          else modoConsulta
        "
      >
        <button
          pTooltip="Editar"
          tooltipPosition="right"
          class="btn btn-sm btn-outline-info"
          (click)="redirectToPeticion(peticion.idPeticion)"
        >
          <em class="fa fa-pencil"></em>
        </button>
        <button
          pTooltip="Eliminar"
          tooltipPosition="right"
          class="btn btn-sm btn-outline-danger"
          (click)="eliminarPeticion(peticion.idPeticion)"
        >
          <em class="fa fa-trash"></em>
        </button>
      </td>
      <ng-template #modoConsulta>
        <td class="acciones text-center">
          <button
            pTooltip="Consultar"
            tooltipPosition="right"
            class="btn btn-sm btn-outline-info visualizar"
            (click)="redirectToPeticion(peticion.idPeticion)"
          >
            <em class="fa fa-eye"></em>
          </button>
          <button
            *ngIf="
              peticion.codEstado === constantes.ESTADO_PDTE_VALORACION ||
              peticion.codEstado === constantes.ESTADO_DEVUELTA_SOLICITANTE ||
              peticion.codEstado === constantes.ESTADO_EN_CURSO ||
              peticion.codEstado === constantes.ESTADO_RESUELTA_AUTORIZACION
            "
            pTooltip="Realizar aclaración"
            tooltipPosition="right"
            class="btn btn-sm btn-outline-info visualizar"
            (click)="redirectToAclaracion(peticion.idPeticion)"
          >
            <em class="fa fa-lightbulb-o"></em>
          </button>
          <button
            pTooltip="Editar"
            tooltipPosition="right"
            class="btn btn-sm btn-outline-info"
            (click)="redirectToPeticionModificable(peticion.idPeticion)"
          >
            <em class="fa fa-pencil"></em>
          </button>
          <button
            *ngIf="
              peticion.codEstado !== constantes.ESTADO_BORRADOR &&
              peticion.subirArtica === 1
            "
            pTooltip="Subir Documento a Ártica"
            tooltipPosition="right"
            class="btn btn-sm btn-outline-info btn-artica"
            [ngClass]="{ red: peticion.fechaArtica === null }"
            (click)="mostrarModalArtica(peticion.idPeticion)"
          >
            <em class="icono-artica"></em>
          </button>
        </td>
      </ng-template>
    </tr>
    <hr class="item-list-separator" />
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="7">No hay registros</td>
    </tr>
  </ng-template>
  <ng-template pTemplate="paginatorleft"> </ng-template>
  <ng-template pTemplate="paginatorright">
    <p class="pageFooterLeft">Peticiones totales: {{ peticionesTotales }}</p>
  </ng-template>
</p-table>

import { HttpClient, HttpRequest, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Puesto } from 'src/app/modelos/puesto';
import { Constantes } from 'src/app/utils/constantes';
import {
  Peticion,
  PeticionSimple,
  PeticionFiltos,
  PeticionActuacion,
  PeticionActuacionFiltros,
  PeticionActuacionFiltrosRequest,
  PeticionFiltosRequest,
  PeticionExportFiltro,
  PeticionInformante,
} from '../../modelos/peticion';
import HttpClientUtils from '../../utils/http-client-utils';
import {
  DatoEspecifico,
  ValorDatoGuardado,
} from '../../modelos/dato-especifico';
import { NuevoModificarDestinatarioRequest } from '../../modelos/NuevoModificarDestinatarioRequest';
import { Aclaracion } from 'src/app/modelos/aclaracion';
import { UsuarioSimple } from '../../modelos/usuario';
import { Informacion } from '../../modelos/informacion';
import { CierrePeticion } from 'src/app/modelos/cierrePeticion';

@Injectable({
  providedIn: 'root',
})
export class PeticionService {
  private peticion!: Peticion;
  private rutaModificable = false;
  private controlActuaciones = false;
  private fromUrlEmail = false;

  datosFiltrosActuacion!: PeticionActuacionFiltros;

  datosFiltrosPeticion!: PeticionFiltos;

  constructor(private http: HttpClient, private constantes: Constantes) {}

  public getPeticion(): Peticion {
    return this.peticion;
  }

  public setPeticion(pet: Peticion): void {
    this.peticion = pet;
  }

  public setRutaModificable(valor: boolean): void {
    this.rutaModificable = valor;
  }

  public getRutaModificable(): boolean {
    return this.rutaModificable;
  }

  public setControlActuaciones(valor: boolean): void {
    this.controlActuaciones = valor;
  }

  public esControlActuaciones(): boolean {
    return this.controlActuaciones;
  }

  public setFromUrlEmail(valor: boolean): void {
    this.fromUrlEmail = valor;
  }

  public isFromUrlEmail(): boolean {
    return this.fromUrlEmail;
  }

  public obtenerPeticion(idPeticion: any): Observable<Peticion> {
    const url: string = this.constantes.ENDPOINT_API_PETICION_EDITAR.replace(
      '{idPeticion}',
      idPeticion
    );
    return this.http.get<Peticion>(url);
  }

  public esModificable(): boolean {
    return (
      (this.peticion &&
        this.peticion.estado &&
        this.peticion.estado.id === 1) ||
      !this.peticion
    );
  }

  public setDatosFiltrosActuacion(datosFiltro: PeticionActuacionFiltros): void {
    this.datosFiltrosActuacion = datosFiltro;
  }

  public getDatosFiltrosActuacion(): PeticionActuacionFiltros {
    return this.datosFiltrosActuacion;
  }

  public setDatosFiltrosPeticion(datosFiltro: PeticionFiltos): void {
    this.datosFiltrosPeticion = datosFiltro;
  }

  public getDatosFiltrosPeticion(): PeticionFiltos {
    return this.datosFiltrosPeticion;
  }

  public obtenerPeticiones(
    offset: number,
    limit: number,
    filtros: PeticionFiltosRequest
  ): Observable<PeticionSimple[]> {
    let params = HttpClientUtils.toHttpParams(filtros);
    params = params.append('offset', String(offset));
    params = params.append('limit', String(limit));

    const url: string = this.constantes.ENDPOINT_API_PETICIONES;
    return this.http.get<PeticionSimple[]>(url, { params });
  }

  public guardarPeticion(peticionBorrador: Peticion): Observable<Peticion> {
    const url: string = this.constantes.ENDPOINT_API_PETICIONES;
    return this.http.post<Peticion>(url, peticionBorrador);
  }

  public actualizarPeticion(peticionBorrador: Peticion): Observable<Peticion> {
    const url: string = this.constantes.ENDPOINT_API_PETICIONES;
    return this.http.put<Peticion>(url, peticionBorrador);
  }

  public guardarFicheros(idPeticion: any, files: File[]): Observable<any> {
    const formData = new FormData();
    files.forEach((file) => {
      formData.append('files', file, file.name);
    });

    const options = {
      reportProgress: false,
    };
    const url: string =
      this.constantes.ENDPOINT_API_PETICIONES_FICHEROS.replace(
        '{idPeticion}',
        idPeticion
      );
    const req = new HttpRequest('POST', url, formData, options);
    return this.http.request(req);
  }

  public anexarFicheros(idPeticion: any, files: File[]): Observable<any> {
    const formData = new FormData();
    files.forEach((file) => {
      formData.append('files', file, file.name);
    });

    const options = {
      reportProgress: false,
    };
    const url: string =
      this.constantes.ENDPOINT_API_PETICIONES_FICHEROS.replace(
        '{idPeticion}',
        idPeticion
      );
    const req = new HttpRequest('PATCH', url, formData, options);
    return this.http.request(req);
  }

  public descargarFichero(
    idPeticion: string,
    idFichero: string
  ): Observable<string> {
    const url: string =
      this.constantes.ENDPOINT_API_PETICIONES_DESCARGAR_FICHERO.replace(
        '{idPeticion}',
        String(idPeticion)
      ).replace('{idFichero}', idFichero);
    return this.http.get<string>(url);
  }

  public visualizarFichero(
    idPeticion: string,
    idFichero: string
  ): Observable<string> {
    const url: string =
      this.constantes.ENDPOINT_API_PETICIONES_VISUALIZAR_FICHERO.replace(
        '{idPeticion}',
        String(idPeticion)
      ).replace('{idFichero}', idFichero);
    return this.http.get<string>(url);
  }

  public eliminarFichero(
    idPeticion: string,
    idFichero: string
  ): Observable<Peticion> {
    const url: string =
      this.constantes.ENDPOINT_API_PETICIONES_ELIMINAR_FICHERO.replace(
        '{idPeticion}',
        idPeticion
      ).replace('{idFichero}', idFichero);
    return this.http.delete<Peticion>(url);
  }

  public descargarFicheroComentarios(
    idPeticion: string,
    idFichero: string
  ): Observable<string> {
    const url: string =
      this.constantes.ENDPOINT_API_PETICIONES_DESCARGAR_FICHERO_COMENTARIOS.replace(
        '{idPeticion}',
        String(idPeticion)
      ).replace('{idFichero}', idFichero);
    return this.http.get<string>(url);
  }

  public eliminarFicheroComentarios(
    idPeticion: string,
    idFichero: string
  ): Observable<Peticion> {
    const url: string =
      this.constantes.ENDPOINT_API_PETICIONES_ELIMINAR_FICHERO_COMENTARIOS.replace(
        '{idPeticion}',
        idPeticion
      ).replace('{idFichero}', idFichero);
    return this.http.delete<Peticion>(url);
  }

  public obtenerPeticionesFechas(): Observable<number[]> {
    const url: string = this.constantes.ENDPOINT_API_PETICIONES_FECHAS;
    return this.http.get<number[]>(url);
  }

  public enviarPeticion(idPeticion: any): Observable<Peticion> {
    const url: string = this.constantes.ENDPOINT_API_PETICIONES_ENVIAR.replace(
      '{idPeticion}',
      idPeticion
    );
    return this.http.get<Peticion>(url);
  }

  public borradorPeticion(idPeticion: any): Observable<Peticion> {
    const url: string =
      this.constantes.ENDPOINT_API_PETICIONES_BORRADOR.replace(
        '{idPeticion}',
        idPeticion
      );
    return this.http.get<Peticion>(url);
  }

  public getPerfilesNotificacion(
    idPeticion: string,
    idGrupo: number
  ): Observable<any[]> {
    const url: string =
      this.constantes.ENDPOINT_API_PETICIONES_NOTIFICACION.replace(
        '{idPeticion}',
        idPeticion
      ).replace('{idGrupo}', idGrupo.toString());
    return this.http.get<Puesto[]>(url);
  }

  public eliminarPeticion(idPeticion: string): Observable<any> {
    const url: string =
      this.constantes.ENDPOINT_API_PETICIONES_ELIMINAR.replace(
        '{idPeticion}',
        idPeticion
      );
    return this.http.delete(url, { responseType: 'text' });
  }

  public getDatosEspecificos(
    codigoPeticion?: string
  ): Observable<DatoEspecifico[]> {
    const url = this.constantes.ENDPOINT_API_CATALOGO_DATOS_ESPECIFICOS.replace(
      '{codigoPeticion}',
      codigoPeticion + ''
    );
    return this.http.get<DatoEspecifico[]>(url);
  }

  public getDatosEspecificosConfig(
    codigoPeticion?: string
  ): Observable<DatoEspecifico[]> {
    const url =
      this.constantes.ENDPOINT_API_CATALOGO_DATOS_ESPECIFICOS_CONFIG.replace(
        '{codigoPeticion}',
        codigoPeticion + ''
      );
    return this.http.get<DatoEspecifico[]>(url);
  }

  public guardarDatosEspecificos(
    codigoPeticion: string,
    valores: ValorDatoGuardado[],
    cambio: string
  ): Observable<ValorDatoGuardado[]> {
    const url =
      this.constantes.ENDPOINT_API_CATALOGO_DATOS_ESPECIFICOS_CAMBIO.replace(
        '{codigoPeticion}',
        codigoPeticion
      ).replace('{cambio}', cambio);
    return this.http.post<ValorDatoGuardado[]>(url, { valores });
  }

  public getPeticionesActuaciones(
    offset: number,
    limit: number,
    peticionActuacion: PeticionActuacionFiltrosRequest
  ): Observable<PeticionActuacion[]> {
    let params = HttpClientUtils.toHttpParams(peticionActuacion);
    params = params.append('offset', String(offset));
    params = params.append('limit', String(limit));

    const url: string = this.constantes.ENDPOINT_API_PETICION_ACTUACIONES;
    return this.http.get<PeticionActuacion[]>(url, { params });
  }

  public getPeticionActuacion(
    idPeticion: string,
    email: string
  ): Observable<any[]> {
    const url: string = this.constantes.ENDPOINT_API_PETICION_ACTUACION.replace(
      '{idPeticion}',
      idPeticion
    );
    let params = new HttpParams();
    params = params.append('email', email);
    return this.http.get<any[]>(url, { params });
  }

  public obtenerNotificadosPeticion(
    idPeticion: string,
    idAccion: number
  ): Observable<any> {
    const url: string =
      this.constantes.ENDPOINT_API_PETICION_NOTIFICADOS.replace(
        '{idPeticion}',
        idPeticion
      ).replace('{codAccion}', idAccion.toString());
    return this.http.get(url);
  }

  public responderPeticion(
    idPeticion: string,
    listaNotificados: string[]
  ): Observable<any> {
    const url: string = this.constantes.ENDPOINT_API_PETICION_RESPONDER.replace(
      '{idPeticion}',
      idPeticion
    );
    let params = HttpClientUtils.toHttpParams(listaNotificados);
    params = params.append('notificados', String(listaNotificados));
    return this.http.get(url, { params });
  }

  public aclararPeticion(
    idPeticion: string,
    body: any,
    files: any[]
  ): Observable<any> {
    const formData = new FormData();
    formData.append('info', JSON.stringify(body));
    files.forEach((file) => {
      formData.append('files', file, file.name);
    });
    const options = {
      reportProgress: false,
    };
    const url: string = this.constantes.ENDPOINT_API_PETICION_ACLARAR.replace(
      '{idPeticion}',
      idPeticion
    );
    return this.http.post<any>(url, formData, options);
  }

  public aclararPeticionBorrador(
    idPeticion: string,
    body: any,
    files: any[]
  ): Observable<any> {
    const formData = new FormData();
    formData.append('info', JSON.stringify(body));
    files.forEach((file) => {
      formData.append('files', file, file.name);
    });
    const url: string =
      this.constantes.ENDPOINT_API_PETICION_ACLARAR_BORRADOR.replace(
        '{idPeticion}',
        idPeticion
      );
    return this.http.post<any>(url, formData);
  }

  public reabrirPeticionBorrador(
    idPeticion: string,
    body: any
  ): Observable<any> {
    const formData = new FormData();
    formData.append('info', JSON.stringify(body));
    const url: string =
      this.constantes.ENDPOINT_API_PETICION_REABRIR_BORRADOR.replace(
        '{idPeticion}',
        idPeticion
      );
    return this.http.post<any>(url, formData);
  }

  public cierrePeticionBorrador(
    idPeticion: string,
    body: any,
    files: any[]
  ): Observable<any> {
    const formData = new FormData();
    formData.append('info', JSON.stringify(body));
    files.forEach((file) => {
      formData.append('files', file, file.name);
    });
    const url: string =
      this.constantes.ENDPOINT_API_PETICION_CIERRE_BORRADOR.replace(
        '{idPeticion}',
        idPeticion
      );
    return this.http.post<any>(url, formData);
  }

  public eliminarCierrePeticionBorrador(
    idPeticion: string,
    cierre: string
  ): Observable<any> {
    const url: string =
      this.constantes.ENDPOINT_API_PETICION_CIERRE_BORRADOR_ELIMINAR.replace(
        '{idPeticion}',
        idPeticion
      ).replace('{cierre}', cierre);
    return this.http.delete(url, { responseType: 'text' });
  }

  public editarCierreBorrador(
    idPeticion: string,
    body: any,
    files: any[]
  ): Observable<any> {
    const formData = new FormData();
    formData.append('info', JSON.stringify(body));
    files.forEach((file) => {
      formData.append('files', file, file.name);
    });
    const url: string =
      this.constantes.ENDPOINT_API_PETICION_CIERRE_BORRADOR_EDITAR.replace(
        '{idPeticion}',
        idPeticion
      );
    return this.http.put<any>(url, formData);
  }

  public autorizarPeticionBorrador(
    idPeticion: string,
    body: any
  ): Observable<any> {
    const url: string =
      this.constantes.ENDPOINT_API_PETICION_AUTORIZAR_BORRADOR.replace(
        '{idPeticion}',
        idPeticion
      );
    return this.http.post<any>(url, body);
  }

  public eliminarAutorizarPeticionBorrador(
    idPeticion: string
  ): Observable<any> {
    const url: string =
      this.constantes.ENDPOINT_API_PETICION_AUTORIZAR_BORRADOR_ELIMINAR.replace(
        '{idPeticion}',
        idPeticion
      );
    return this.http.delete(url, { responseType: 'text' });
  }

  public autorizarInformacionBorrador(
    idPeticion: string,
    body: any
  ): Observable<any> {
    const url: string =
      this.constantes.ENDPOINT_API_PETICION_INFORMAR_BORRADOR.replace(
        '{idPeticion}',
        idPeticion
      );
    return this.http.post<any>(url, body);
  }

  public eliminarInformacionPeticionBorrador(
    idPeticion: string
  ): Observable<any> {
    const url: string =
      this.constantes.ENDPOINT_API_PETICION_INFORMAR_BORRADOR_ELIMINAR.replace(
        '{idPeticion}',
        idPeticion
      );
    return this.http.delete(url, { responseType: 'text' });
  }

  public eliminarAclararPeticionBorrador(
    idPeticion: string,
    usuario: string
  ): Observable<any> {
    const url: string =
      this.constantes.ENDPOINT_API_PETICION_ACLARAR_BORRADOR_ELIMINAR.replace(
        '{idPeticion}',
        idPeticion
      ).replace('{usuario}', usuario);
    return this.http.delete(url, { responseType: 'text' });
  }

  public eliminarReabrirPeticionBorrador(
    idPeticion: string,
    usuario: string
  ): Observable<any> {
    const url: string =
      this.constantes.ENDPOINT_API_PETICION_REABRIR_BORRADOR_ELIMINAR.replace(
        '{idPeticion}',
        idPeticion
      ).replace('{usuario}', usuario);
    return this.http.delete(url, { responseType: 'text' });
  }

  public actualizarAclaracionBorrador(
    idPeticion: string,
    body: any,
    files: any[]
  ): Observable<any> {
    const formData = new FormData();
    formData.append('info', JSON.stringify(body));
    files.forEach((file) => {
      formData.append('files', file, file.name);
    });
    const url: string =
      this.constantes.ENDPOINT_API_PETICION_ACLARAR_BORRADOR_ACTUALIZAR.replace(
        '{idPeticion}',
        idPeticion
      );
    return this.http.put<any>(url, formData);
  }

  public editarAclaracionBorrador(
    idPeticion: string,
    body: any,
    files: any[]
  ): Observable<any> {
    const formData = new FormData();
    formData.append('info', JSON.stringify(body));
    files.forEach((file) => {
      formData.append('files', file, file.name);
    });
    const url: string =
      this.constantes.ENDPOINT_API_PETICION_ACLARAR_BORRADOR_EDITAR.replace(
        '{idPeticion}',
        idPeticion
      );
    return this.http.put<any>(url, formData);
  }

  public editarReabrirBorrador(idPeticion: string, body: any): Observable<any> {
    const formData = new FormData();
    formData.append('info', JSON.stringify(body));
    const url: string =
      this.constantes.ENDPOINT_API_PETICION_REABRIR_BORRADOR_EDITAR.replace(
        '{idPeticion}',
        idPeticion
      );
    return this.http.put<any>(url, formData);
  }

  public eliminarFicheroAclaracion(
    idAclaracion: string,
    idFichero: string
  ): Observable<any> {
    const url: string =
      this.constantes.ENDPOINT_API_ACLARACIONES_ELIMINAR_FICHERO.replace(
        '{idAclaracion}',
        idAclaracion
      ).replace('{idFichero}', idFichero);
    return this.http.delete<any>(url);
  }

  public eliminarFicheroCierre(
    idCierre: string,
    idFichero: string
  ): Observable<any> {
    const url: string =
      this.constantes.ENDPOINT_API_CIERRE_ELIMINAR_FICHERO.replace(
        '{idCierre}',
        idCierre
      ).replace('{idFichero}', idFichero);
    return this.http.delete<any>(url);
  }

  public reabrirPeticion(id: string, body: any): Observable<any> {
    const formData = new FormData();
    formData.append('info', JSON.stringify(body));
    return this.http.put<any>(
      `${this.constantes.ENDPOINT_API_ACCIONES_REABRIR}/${id}`,
      formData
    );
  }

  public rechazarResolverPeticion(
    idPeticion: string,
    body: any,
    files: any[],
    endpoint: string
  ): Observable<Peticion> {
    const formData = new FormData();
    formData.append('info', JSON.stringify(body));
    files.forEach((file) => {
      formData.append('files', file, file.name);
    });
    const url: string = endpoint.replace('{idPeticion}', idPeticion);
    const options = {
      reportProgress: false,
    };
    return this.http.patch<Peticion>(url, formData, options);
  }

  public actualizarDestinatarioSolicitud(
    idPeticion: string,
    NuevoModificarDestinatarioRequest: NuevoModificarDestinatarioRequest
  ): Observable<any> {
    const url: string =
      this.constantes.ENDPOINT_API_PETICION_ACUALIZAR_DESTINATARIO.replace(
        '{idPeticion}',
        idPeticion
      );
    return this.http.put<NuevoModificarDestinatarioRequest>(
      url,
      NuevoModificarDestinatarioRequest
    );
  }

  public obtenerAclaraciones(idPeticion: string): Observable<Aclaracion[]> {
    const url: string = this.constantes.ENDPOINT_API_PETICION_ACLARAR.replace(
      '{idPeticion}',
      idPeticion
    );
    return this.http.get<Aclaracion[]>(url);
  }

  public obtenerCierres(idPeticion: string): Observable<CierrePeticion[]> {
    const url: string =
      this.constantes.ENDPOINT_API_PETICION_OBTENER_CIERRE.replace(
        '{idPeticion}',
        idPeticion
      );
    return this.http.get<CierrePeticion[]>(url);
  }

  public descargarFicheroAclaracion(
    idPeticion: string,
    idAclaracion: number,
    idFichero: string
  ): Observable<string> {
    const url: string =
      this.constantes.ENDPOINT_API_PETICION_ACLARAR_DESCARGAR_FICHERO.replace(
        '{idPeticion}',
        String(idPeticion)
      )
        .replace('{idAclaracion}', String(idAclaracion))
        .replace('{idFichero}', idFichero);
    return this.http.get<string>(url);
  }

  public visualizarFicheroAclaracion(
    idPeticion: string,
    idAclaracion: number,
    idFichero: string
  ): Observable<string> {
    const url: string =
      this.constantes.ENDPOINT_API_PETICION_ACLARAR_VISUALIZAR_FICHERO.replace(
        '{idPeticion}',
        String(idPeticion)
      )
        .replace('{idAclaracion}', String(idAclaracion))
        .replace('{idFichero}', idFichero);
    return this.http.get<string>(url);
  }

  public comentarPeticion(
    idPeticion: any,
    comentario: string,
    email: string,
    files: any[]
  ): Observable<Peticion> {
    const formData = new FormData();
    formData.append('info', comentario);
    files.forEach((file) => {
      formData.append('files', file, file.name);
    });
    const url: string = this.constantes.ENDPOINT_API_PETICION_COMENTAR.replace(
      '{idPeticion}',
      idPeticion
    );
    const params = new HttpParams().set('email', email);
    return this.http.post<Peticion>(url, formData, { params });
  }

  public obtenerAutorizantes(idPeticion: string): Observable<UsuarioSimple[]> {
    const url: string =
      this.constantes.ENDPOINT_API_PETICION_OBTENER_AUTORIZANTES.replace(
        '{idPeticion}',
        idPeticion
      );
    return this.http.get<UsuarioSimple[]>(url);
  }

  public obtenerInformantes(idPeticion: string): Observable<UsuarioSimple[]> {
    const url: string =
      this.constantes.ENDPOINT_API_PETICION_OBTENER_INFORMANTES.replace(
        '{idPeticion}',
        idPeticion
      );
    return this.http.get<UsuarioSimple[]>(url);
  }

  public solicitarAutorizacion(
    idPeticion: string,
    body: any
  ): Observable<Peticion> {
    const url: string =
      this.constantes.ENDPOINT_API_PETICION_SOLICITAR_AUTORIZACION.replace(
        '{idPeticion}',
        idPeticion
      );

    const options = {
      reportProgress: false,
    };
    return this.http.patch<Peticion>(url, body, options);
  }

  public obtenerInformaciones(idPeticion: string): Observable<Informacion[]> {
    const url: string =
      this.constantes.ENDPOINT_API_PETICION_INFORMACIONES.replace(
        '{idPeticion}',
        idPeticion
      );
    return this.http.get<Informacion[]>(url);
  }

  public obtenerPeticionInformaciones(
    idPeticion: string
  ): Observable<PeticionInformante> {
    const url: string =
      this.constantes.ENDPOINT_API_PETICION_INFORMACIONES_PETICIONES.replace(
        '{idPeticion}',
        idPeticion
      );
    return this.http.get<PeticionInformante>(url);
  }

  public informarPeticion(idPeticion: string, body: any): Observable<Peticion> {
    const url: string = this.constantes.ENDPOINT_API_PETICION_INFORMAR.replace(
      '{idPeticion}',
      idPeticion
    );

    const options = {
      reportProgress: false,
    };
    return this.http.patch<Peticion>(url, body, options);
  }

  public autorizarPeticion(
    idPeticion: string,
    body: any
  ): Observable<Peticion> {
    const url: string = this.constantes.ENDPOINT_API_PETICION_AUTORIZAR.replace(
      '{idPeticion}',
      idPeticion
    );

    const options = {
      reportProgress: false,
    };
    return this.http.patch<Peticion>(url, body, options);
  }

  public subirArticaPeticion(idPeticion: string): Observable<Peticion> {
    const url: string = this.constantes.ENDPOINT_API_PETICION_ARTICA.replace(
      '{idPeticion}',
      idPeticion
    );

    const options = {
      reportProgress: false,
    };
    return this.http.patch<Peticion>(url, options);
  }

  public obtenerEnlaceArticaPeticion(idPeticion: string): Observable<string> {
    const url: string =
      this.constantes.ENDPOINT_API_PETICION_ARTICA_ENLACE.replace(
        '{idPeticion}',
        idPeticion
      );
    return this.http.get<string>(url, { responseType: 'text' as 'json' });
  }

  buscarApellidoForm(apellido: any): Observable<any> {
    const params = new HttpParams().set('apellido', apellido);
    return this.http.get(this.constantes.ENDPOINT_API_PETICIONES_APELLIDO, {
      params,
    });
  }

  buscarDniForm(dni: any): Observable<any> {
    const params = new HttpParams().set('dni', dni);
    return this.http.get(this.constantes.ENDPOINT_API_PETICIONES_DNI, {
      params,
    });
  }

  buscarNombreForm(nombre: any): Observable<any> {
    const params = new HttpParams().set('nombre', nombre);
    return this.http.get(this.constantes.ENDPOINT_API_PETICIONES_NOMBRE, {
      params,
    });
  }

  public exportarControlActuaciones(
    filtros: PeticionExportFiltro
  ): Observable<any> {
    const params = HttpClientUtils.toHttpParams(filtros);
    return this.http.get(
      this.constantes.ENDPOINT_API_EXPORTAR + '/exportar-control-actuaciones',
      { params }
    );
  }

  public exportarGestionPeticiones(
    filtros: PeticionExportFiltro
  ): Observable<any> {
    const params = HttpClientUtils.toHttpParams(filtros);
    return this.http.get(
      this.constantes.ENDPOINT_API_EXPORTAR + '/exportar-gestion-peticiones',
      { params }
    );
  }
}

<ngx-spinner></ngx-spinner>
<app-mensaje #mensaje></app-mensaje>
<div class="container espacio">
  <div class="d-flex justify-content-between mb-3 mt-5">
    <h1>Autorización</h1>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="descripcion">
        <div class="col-lg-8 seccion-comentario">
          <div class="row">
            <div class="col-sm-12 col-lg-6">
              <span
                _ngcontent-sbf-c163
                class="etiqueta font-weight-bold"
                style="font-size: 1.1rem"
                >Petición Nº:
              </span>
              <span
                _ngcontent-sbf-c163
                class="etiqueta font-weight-bold"
                id="idPeticion"
                class="texto"
                >{{ peticion.idPeticion }}
              </span>
            </div>
            <div class="col-sm-12 col-lg-6">
              <span
                _ngcontent-sbf-c163
                class="etiqueta font-weight-bold"
                style="font-size: 1rem"
                >Tipo de petición:
              </span>
              <span
                _ngcontent-sbf-c163
                class="etiqueta font-weight-bold"
                class="catalogo"
                >{{ peticion.catalogo?.descripcion }}
              </span>
              <br />
            </div>
          </div>
          <hr _ngcontent-ewq-c163 />
          <div>
            <span
              _ngcontent-sbf-c163
              class="etiqueta font-weight-bold"
              style="font-size: 1rem"
              >Solicitante:
            </span>
            <span
              _ngcontent-sbf-c163
              class="etiqueta font-weight-bold"
              class="usuarioPeticionario"
              >{{ peticion.usuarioPeticionario?.email }}
            </span>
            <br />
            <hr _ngcontent-ewq-c163 />
          </div>
          <div>
            <span
              _ngcontent-sbf-c163
              class="etiqueta font-weight-bold"
              style="font-size: 1rem"
              >Descripcion:
            </span>
            <br />
            <span
              _ngcontent-sbf-c163
              class="etiqueta font-weight-bold"
              class="descripcion"
              >{{ peticion.descripcion }}
            </span>
            <br />
            <hr _ngcontent-ewq-c163 />
          </div>
          <div class="row">
            <app-lista-aclaraciones
              *ngIf="informaciones.length > 0"
              style="width: 100%"
              [aclaraciones]="informaciones"
            ></app-lista-aclaraciones>
            <div
              *ngIf="informaciones.length === 0"
              class="col alert alert-warning text-center"
              role="alert"
            >
              Esta petición todavía no ha sido informada
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-container *ngIf="esAutorizante">
    <div class="row">
      <div class="col-12">
        <form [formGroup]="formularioAutorizar" *ngIf="formularioAutorizar">
          <div class="descripcion">
            <div class="col-lg-8 seccion-comentario">
              <div class="form-group" style="margin-top: 3rem">
                <div class="mb-3 col-sm-12 col-lg-4 pl-0">
                  <label for="motivo" class="form-label"
                    >Motivo Autorización
                    <span style="color: red">*</span></label
                  >
                  <p-dropdown
                    id="motivo"
                    [options]="motivosAutorizaciones"
                    styleClass="form-control p-0"
                    formControlName="motivo"
                    [disabled]="!permisoAutorizar"
                    (onChange)="cambiarTextoBoton()"
                  >
                  </p-dropdown>
                  <small
                    class="form-text text-danger"
                    *ngIf="
                      !formularioAutorizar.get('motivo')!.valid &&
                      formularioAutorizar.get('motivo')!.touched
                    "
                    >* El campo motivo es obligatorio</small
                  >
                </div>
                <div>
                  <span *ngIf="borrador" class="badge badge-success"
                    >Borrador</span
                  >
                  <button
                    type="button"
                    class="btn btn-outline-secondary fa fa-eraser limpiar mb-3"
                    style="float: right"
                    (click)="limpiar()"
                    [disabled]="!permisoAutorizar"
                  >
                    Limpiar
                  </button>
                </div>
                <textarea
                  *ngIf="permisoAutorizar; else noPuedeAutorizar"
                  id="msg"
                  class="form-control"
                  formControlName="msg"
                  rows="3"
                  trim="blur"
                  maxlength="3000"
                  (keypress)="onKeyPress($event)"
                  (keyup)="onKeyUp($event)"
                  (blur)="controlFormAutorizar()"
                ></textarea>
                <ng-template #noPuedeAutorizar>
                  <textarea
                    id="msg"
                    class="form-control"
                    formControlName="msg"
                    rows="3"
                    trim="blur"
                    maxlength="3000"
                    (keypress)="onKeyPress($event)"
                    (keyup)="onKeyUp($event)"
                    disable
                  ></textarea>
                </ng-template>
                <div class="input-group">
                  <span class="col col-lg-8"
                    >Máximo: 3000. Total: {{ contadorAutorizar }}
                  </span>
                </div>
                <button
                  class="btn btn-outline-primary customButton btn-enviar col col-lg-3"
                  [disabled]="!permisoAutorizar"
                  style="float: right"
                  (click)="autorizar()"
                >
                  {{ textoAutorizar }}
                </button>
                <button
                  class="btn btn-danger btn-enviar col col-lg-3"
                  style="margin-right: 12px"
                  [disabled]="!this.formularioAutorizar.valid"
                  (click)="eliminarAutorizarBorrador()"
                >
                  Eliminar borrador
                </button>
                <button
                  class="btn btn-success btn-enviar col col-lg-3"
                  style="margin-right: 12px"
                  [disabled]="!this.formularioAutorizar.valid"
                  (click)="crearAutorizarBorrador()"
                >
                  Guardar borrador
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </ng-container>
  <button
    class="btn btn-outline-secondary mt-1"
    (click)="volver()"
    style="margin-bottom: 2rem"
  >
    Volver
  </button>
</div>

import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SelectItem } from 'primeng/api';
import { NgxSpinnerService } from 'ngx-spinner';
import { UsuariosAuditoria } from 'src/app/modelos/usuario-auditoria';
import { AuditoriasUsuarioService } from 'src/app/servicios/auditorias/auditorias-usuario.service';
import { Constantes } from 'src/app/utils/constantes';
import { MatDialog } from '@angular/material/dialog';
import { ModalInfoComponent } from '../../modals/modal-info/modal-info.component';
import { Table } from 'primeng/table';
import Utils from '../../../utils/utils';

@Component({
  selector: 'app-usuarios-auditoria',
  templateUrl: './usuarios-auditoria.component.html',
  styleUrls: ['./usuarios-auditoria.component.scss'],
})
export class UsuariosAuditoriaComponent implements OnInit {
  public titulo: string;
  public formulario!: FormGroup;

  cols: any[];
  first = 1;
  rows = 25;

  lastSortField!: any;

  paginasTotales = 0;
  paginaActual = 1;
  usuariosTotales = 0;
  usuariosPorPagina: number[] = [];
  listaAcciones: SelectItem[];
  accion!: SelectItem;
  usuarios: UsuariosAuditoria[];
  public constantes: Constantes;
  public email = '';
  public desdeDetalle = false;

  @Input()
  set emailUsuario(param: any) {
    this.email = param;
    if (this.email !== '') {
      this.desdeDetalle = true;
    }
  }

  constructor(
    private fb: FormBuilder,
    private auditoriaService: AuditoriasUsuarioService,
    private spinner: NgxSpinnerService,
    public dialog: MatDialog
  ) {
    this.titulo = 'Auditoría de Usuarios';
    this.usuarios = [];
    this.cols = [
      { field: 'actor', header: 'ACTOR' },
      { field: 'accion', header: 'ACCIÓN' },
      { field: 'afectado', header: 'AFECTADO' },
      { field: 'fecha', header: 'FECHA' },
      { field: 'observaciones', header: 'OBSERVACIONES' },
    ];

    this.listaAcciones = [
      { label: 'Crear', value: 'A' },
      { label: 'Editar', value: 'M' },
      { label: 'Eliminar', value: 'B' },
      { label: 'Reactivar', value: 'R' },
    ];

    this.constantes = new Constantes();
    this.usuariosPorPagina = this.constantes.numeroUsuariosPorPagina;
  }

  ngOnInit(): any {
    this.initForm(this.email);
    this.buscarFiltro(String(this.first));
  }

  initForm(usuario: string): any {
    this.formulario = this.fb.group({
      accion: [],
      email: [usuario ? this.email : ''],
      fechaDesde: [],
      fechaHasta: [],
    });
    if (this.desdeDetalle) {
      document.getElementById('email')?.setAttribute('disabled', 'true');
      this.formulario.get('email')?.disable();
    }
  }

  /**
   * Buscar usuarios del histórico de auditoría según el filtro
   */
  buscarFiltro(page: string): any {
    // Llamada al servicio para que me devuelva los resultados según acción
    if (page === '1'){
      this.first = 1;
    }
    this.usuarios = [];
    const accion =
      this.formulario.get('accion')?.value !== null
        ? this.formulario.get('accion')?.value.value
        : '';
    const email =
      this.formulario.get('email')?.value !== null
        ? this.formulario.get('email')?.value
        : '';
    const fechaDesde =
      this.formulario.get('fechaDesde')?.value !== null
        ? this.formulario.get('fechaDesde')?.value
        : '';
    const fechaHasta =
      this.formulario.get('fechaHasta')?.value !== null
        ? this.formulario.get('fechaHasta')?.value
        : '';
    this.auditoriaService
      .buscarUsuariosAccion(
        accion,
        email,
        fechaDesde,
        fechaHasta,
        page,
        String(this.rows)
      )
      .subscribe(
        (data: any) => {
          this.usuarios = data.results;
          this.paginaActual = data.currentPage;
          this.paginasTotales = data.totalPages;
          this.usuariosTotales = data.totalResults;
        },
        (error: any) => {
          console.error(error);
        }
      );
  }

  // Limpiar el formulario
  reset(): void {
    this.formulario.get('accion')?.setValue(null);
    document.getElementById('buscar')?.removeAttribute('disabled');
    if (this.desdeDetalle) {
      this.initForm(this.email);
    } else {
      this.initForm('');
    }
    this.buscarFiltro('1');
  }

  mostrarObservaciones(observaciones: any): any {
    const data = {
      title: 'Modificaciones realizadas',
      msg: observaciones,
      isListInfo: true,
    };
    const dialog = this.dialog.open(ModalInfoComponent, {
      disableClose: true,
      autoFocus: false,
      data,
    });
    dialog.afterClosed().subscribe();
  }

  private hasChanged(sort: string, order: number): boolean {
    return (
      this.lastSortField.sortField !== sort ||
      this.lastSortField.sortOrder !== order
    );
  }

  nextPage(e: any, table: Table): void {
    if (
      (e.sortField && !this.lastSortField) ||
      (e.sortField && this.hasChanged(e.sortField, e.sortOrder))
    ) {
      this.lastSortField = {
        sortField: e.sortField,
        sortOrder: e.sortOrder,
      };
      this.customSort(this.lastSortField);
      table.first = (this.paginaActual - 1) * this.rows;
    } else {
      const offset = e.first / e.rows + 1;
      this.rows = e.rows;
      this.buscarFiltro(String(offset));
    }
  }

  private customSort(sort: any): void {
    if (sort && this.usuarios) {
      this.usuarios.sort((a: UsuariosAuditoria, b: UsuariosAuditoria) => {
        switch (sort.sortField) {
          case this.cols[0].field:
            return Utils.sortString(a.emailActor, b.emailActor, sort.sortOrder);
          case this.cols[1].field:
            return Utils.sortString(a.accion, b.accion, sort.sortOrder);
          case this.cols[2].field:
            return Utils.sortString(
              a.emailAfectado,
              b.emailAfectado,
              sort.sortOrder
            );
          case this.cols[3].field:
            return Utils.sortString(a.fecha, b.fecha, sort.sortOrder);
          default:
            return sort.sortOrder;
        }
      });
    }
  }

  /**
   * Función que al pulsar en el campo email, a partir del tercer caracter empieza a buscar en la tabla
   * @param event the event
   */
  keyPress(): any {
    const value = this.formulario.get('email')?.value;
    if ((value && value.length >= 3) || value.length === 0) {
      this.buscarFiltro('1');
    }
  }
  public validarFechas(fechaInicio: string, fechaFin: string): boolean {
    let fechaInvalida = false;
    if (fechaInicio && fechaFin) {
      if (
        Utils.getDate(fechaInicio).getTime() > Utils.getDate(fechaFin).getTime()
      ) {
        document.getElementById('buscar')?.setAttribute('disabled', 'true');
        fechaInvalida = true;
      } else {
        document.getElementById('buscar')?.removeAttribute('disabled');
      }
    }
    return fechaInvalida;
  }
}

<ngx-spinner></ngx-spinner>
<app-mensaje #mensaje></app-mensaje>
<div class="container" *ngIf="formulario">
    <h5 class="mt-3 mb-4">{{title}}</h5>
    <form [formGroup]="formulario">
        <div class="row">
            <div class="mb-3 col-sm-12 col-lg-4">
                <label for="tipoPeticion" class="form-label">Tipo de Petición <span class="text-danger">*</span></label>
                <p-dropdown id="tipoPeticion" styleClass="form-control p-0" placeholder="Seleccione un tipo de petición" [options]="listaTiposPeticiones" formControlName="tipoPeticion" optionLabel="descripcion" [filter]="true">
                </p-dropdown>
                <small class="form-text text-danger" *ngIf="!formulario.get('tipoPeticion')!.valid && formulario.get('tipoPeticion')!.touched">* El campo es obligatorio</small>
            </div>
            <div class="mb-3 col-sm-12 col-lg-4">
                <label for="accion" class="form-label">Acción <span class="text-danger">*</span></label>
                <p-dropdown id="accion" styleClass="form-control p-0" placeholder="Seleccione una acción" [options]="listaAcciones" formControlName="accion" optionLabel="descripcion" [filter]="true">
                </p-dropdown>
                <small class="form-text text-danger" *ngIf="!formulario.get('accion')!.valid && formulario.get('accion')!.touched">* El campo es obligatorio</small>
            </div>
            <div class="mb-3 col-sm-12 col-lg-4">
                <label for="plantilla" class="form-label">Plantilla <span class="text-danger">*</span></label>
                <p-dropdown id="plantilla" styleClass="form-control p-0" placeholder="Seleccione una plantilla" [options]="listaPlantillas" formControlName="plantilla" optionLabel="descripcion" [filter]="true">
                </p-dropdown>
                <small class="form-text text-danger" *ngIf="!formulario.get('plantilla')!.valid && formulario.get('plantilla')!.touched">* El campo es obligatorio</small>
            </div>
        </div>
        <div class="row mt-4">
            <div class="mb-3 col-sm-12 col-lg-2">
                <div class="p-field-checkbox">
                    <p-checkbox styleClass="p-0" [binary]="true" formControlName="trabajador" label="Trabajador"></p-checkbox>
                </div>
            </div>
            <div class="mb-3 col-sm-12 col-lg-2">
                <div class="p-field-checkbox">
                    <p-checkbox styleClass="p-0" [binary]="true" formControlName="solicitante" label="Solicitante"></p-checkbox>
                </div>
            </div>
            <div class="mb-3 col-sm-12 col-lg-2">
                <div class="p-field-checkbox">
                    <p-checkbox styleClass="p-0" [binary]="true" formControlName="destinatario" label="Destinatario"></p-checkbox>
                </div>
            </div>
            <div class="mb-3 col-sm-12 col-lg-2">
                <div class="p-field-checkbox">
                    <p-checkbox styleClass="p-0" [binary]="true" formControlName="autorizador" label="Autorizador"></p-checkbox>
                </div>
            </div>
            <div class="mb-3 col-sm-12 col-lg-2">
                <div class="p-field-checkbox">
                    <p-checkbox styleClass="p-0" [binary]="true" formControlName="informantes" label="Informantes"></p-checkbox>
                </div>
            </div>
            <div class="mb-3 col-sm-12 col-lg-2">
                <div class="p-field-checkbox">
                    <p-checkbox styleClass="p-0" [binary]="true" formControlName="pantallaSolicitar" label="Pantalla Solicitar"></p-checkbox>
                </div>
            </div>
        </div>
        <ng-container>
            <div class="row mt-4">
                <h2 class="mb-3 col-sm-12 col-lg-4">Grupos notificados</h2>
            </div>
            <div class="row mt-1">
                <div class="mb-3 col-sm-12 col-lg-4" *ngFor="let grupoNotificado of listaGrupos">
                    <label for="accion" class="form-label">{{grupoNotificado.grupoSoporte.descripcion}}</label>
                    <p-multiSelect [id]="'grupo-'+grupoNotificado.grupoSoporte.id" filterPlaceHolder="Buscar" [defaultLabel]="'Seleccionar perfiles'" [virtualScroll]="true" [options]="grupoNotificado.perfilesNotificados" optionLabel="descripcion" [formControlName]="'grupo-'+grupoNotificado.grupoSoporte.id"
                        styleClass="form-control p-0"></p-multiSelect>
                </div>
            </div>
        </ng-container>
    </form>
    <div class=" mt-2 mb-3 d-flex justify-content-center">
        <button class="btn btn-outline-secondary mr-2" (click)="redirect()">Volver</button>
        <button type="submit" class="btn btn-outline-primary customButton" (click)="callEditar()" *ngIf="modoEdicion; else modoCrear" [disabled]="!formulario.dirty || !formulario.valid">Guardar</button>
        <ng-template #modoCrear>
            <button type="submit" class="btn btn-outline-primary customButton" (click)="callCrear()" [disabled]="!formulario.valid">Crear notificación</button>
        </ng-template>
    </div>
</div>

import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { Table } from 'primeng/table';
import { RolesService } from 'src/app/servicios/rol/roles.service';
import { Constantes } from 'src/app/utils/constantes';
import { ModalInfoComponent } from '../modals/modal-info/modal-info.component';
import { RolPermisos } from '../../modelos/rol-permisos';
import { GruposSoporte } from '../../modelos/grupossoporte';
import { GrupossoporteService } from '../../servicios/grupossoporte/grupossoporte.service';
import Utils from '../../utils/utils';

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.scss']
})
export class RolesComponent implements OnInit {


  roles: RolPermisos[] = [];
  cols!: any[];
  first = 1;
  rows = 25;

  listaGrupos: GruposSoporte[] = [];

  paginasTotales = 0;
  paginaActual = 1;
  rolesTotales = 0;
  rolesPorPagina!: number[];

  opcionesBoton: any[];
  resultado: any;

  lastSortField!: any;


  constructor(
    private rolesService: RolesService,
    private spinner: NgxSpinnerService,
    private constantes: Constantes,
    public dialog: MatDialog,
    private gruposSoporteService: GrupossoporteService) {
      this.cols = [
        {field: 'descripcion', header: 'DESCRIPCIÓN', tool: 'Rol'},
        {field: 'observaciones', header: 'OBSERVACIONES', tool: 'Qué puede hacer el rol'},
        {field: 'grupos', header: 'GRUPOS SOPORTE', tool: 'Grupos de Soporte'},
        {field: 'au', header: 'AU', tool: 'Administración de Usuarios'},
        {field: 'gp', header: 'GP', tool: 'Gestión de Peticiones'},
        {field: 'ps', header: 'PS', tool: 'Panel de Seguimiento'},
        {field: 'ga', header: 'GA', tool: 'Gestión de Auditoría'},
        {field: 'kpi', header: 'KPI', tool: 'Indicadores de Rendimiento'},
        {field: 'ag', header: 'AG', tool: 'Administración General'}
      ];
      this.rolesPorPagina = this.constantes.numeroUsuariosPorPagina;
      this.opcionesBoton = [{label: 'Si', value: 1}, {label: 'No', value: 0}];
     }


  ngOnInit(): void {
    this.getRoles(this.first);
  }
  getRoles(first: number): void {
    this.spinner.show();
    this.rolesService.getRolesPermisos().subscribe((data: any) => {
      this.gruposSoporteService.getGruposSoporte('0', String(Number.MAX_SAFE_INTEGER)).subscribe((respGrupos: any) => {
        this.listaGrupos = respGrupos.results;
      });
      this.cargarDatosTabla(data);
      this.spinner.hide();
    }, (error: any) => {
      console.error(error);
      this.roles = [];
      this.spinner.hide();
    });
  }
  cargarDatosTabla(data: any): any {
    this.roles = data;
    this.paginaActual = 1;
    this.paginasTotales = 1;
    this.rolesTotales = data.length;
    this.customSort(this.lastSortField);
  }

  nextPage(e: any, table: Table): void {
    if ((e.sortField && !this.lastSortField) || (e.sortField && this.hasChanged(e.sortField, e.sortOrder))) {
      this.lastSortField = {
        sortField: e.sortField,
        sortOrder: e.sortOrder
      };
      this.customSort(this.lastSortField);
      table.first = (this.paginaActual - 1) * this.rows;
    }else{
      const offset = (e.first / e.rows);
      this.rows = e.rows;
      this.getRoles(offset);
    }
  }

  private customSort(sort: any): void{
    if (sort && this.roles){
      this.roles.sort((a: RolPermisos, b: RolPermisos) => {
        return Utils.sortString(a.descripcion, b.descripcion, sort.sortOrder);
      });
    }
  }


  private hasChanged(sort: string, order: number): boolean {
    return this.lastSortField.sortField !== sort || this.lastSortField.sortOrder !== order;
  }

  exportarDatos(): void{
    this.spinner.show();
    this.rolesService.exportar().subscribe(urlRespuesta => {
      if (urlRespuesta){
        // Abrir nueva pantalla con el resultado del exportar
        this.spinner.hide();
        window.open(urlRespuesta.url, '_blank', 'location=yes, height=570, width=520, scrollbars=yes, status=yes');
      }else{
        const data = {
          title: 'Información',
          msg: 'No se ha podido hacer la exportación ...'
        };
        this.dialog.open(ModalInfoComponent, {disableClose: true, autoFocus: false,  data});
      }
    });
  }

  /**
   * Función que cambia el valor de un campo si este se ha cambiado en la tabla
   *
   * @param event el valor de la celda
   * @param index el indice (fila)
   * @param campo el nombre del campo a cambiar
   */
  cambioPermisos(index: string, rol: RolPermisos): void{
    this.spinner.show();
    this.rolesService.cambiarPermiso((index + 1), rol).subscribe((resp: any) => {
      this.spinner.hide();
    }, error => {
      console.log(error);
      this.spinner.hide();
      }
    );
  }


  mostrarObservaciones(observaciones: any): void{

    let listaObservaciones = [];
    listaObservaciones = observaciones.split('. ');
    const data = {
      title: 'Permisos que tiene el Rol',
      msg: listaObservaciones,
      isListInfo: true
    };
    const dialog = this.dialog.open(ModalInfoComponent, {disableClose: true, autoFocus: false,  data});
    dialog.afterClosed().subscribe();
  }
}

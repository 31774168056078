import { Injectable } from '@angular/core';
import { ModalError } from '../../modelos/modal-error';

@Injectable({
  providedIn: 'root',
})
export class HandlerErrorService {
  public handlerError(error: any, rutaEliminar?: string): ModalError {
    console.log(error);
    if (error.error !== undefined) {
      // error.error puede ser un objeto con los datos, de ahi el primer return, o una variable con el nombre del error
      if (error.error.message !== undefined) {
        return {
          mensaje: error.error.message,
          title: error.error.error || 'Operación no válida',
          code: error.error.status || error.status,
          inicio: false,
        };
      } else {
        return {
          mensaje: error.error,
          title: 'Operación no válida',
          code: error.code,
          inicio: false,
        };
      }
    }
    return {
      mensaje:
        'Se ha producido un error en la aplicación. Vuelva a intentarlo más tarde, disculpe las molestias.',
      title: 'Error interno',
      code: 500,
      inicio: false,
    };
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Constantes } from 'src/app/utils/constantes';

@Injectable({
  providedIn: 'root'
})
export class UrgenciasService {

  constructor( private http: HttpClient, private constantes: Constantes) { }


  public getUrgencias(): Observable<any> {
    const url: string = this.constantes.ENDPOINT_API_URGENCIAS;
    return this.http.get(url);
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FileUpload } from 'primeng/fileupload';
import { MensajeComponent } from 'src/app/componentes/mensaje/mensaje.component';
import { Fichero } from 'src/app/modelos/fichero';
import { Peticion } from 'src/app/modelos/peticion';
import { PeticionService } from '../../../../servicios/peticion/peticion.service';
import { NgxSpinnerService } from 'ngx-spinner';
import Utils from '../../../../utils/utils';
import { ButtonModalInfo, ModalInfo } from 'src/app/modelos/moda-info';
import { ModalInfoComponent } from 'src/app/componentes/modals/modal-info/modal-info.component';
import { MatDialog } from '@angular/material/dialog';
import { ModalArticaComponent } from '../../../modals/modal-artica/modal-artica.component';
import { Constantes } from '../../../../utils/constantes';
import { SesionService } from 'src/app/servicios/sesion/sesion.service';

@Component({
  selector: 'app-crear-peticion-adjuntar-ficheros',
  templateUrl: './crear-peticion-adjuntar-ficheros.component.html',
  styleUrls: ['./crear-peticion-adjuntar-ficheros.component.css'],
})
export class CrearPeticionAdjuntarFicherosComponent implements OnInit {
  @ViewChild('fileInput')
  fileInput!: FileUpload;

  activeIndex = 3;
  estado = 1;
  peticion!: Peticion;

  esAuditor = false;
  public uploadedFiles: Fichero[] = [];

  public documentoObligatorio: number;

  @ViewChild('mensaje', { static: false })
  public mensaje!: MensajeComponent;

  @ViewChild('modalArtica', { static: false })
  public modalArtica!: ModalArticaComponent;

  constructor(
    private router: Router,
    public peticionService: PeticionService,
    public dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private constantes: Constantes,
    private sesionService: SesionService
  ) {
    this.documentoObligatorio = 0;
  }

  ngOnInit(): void {
    this.peticion = this.peticionService.getPeticion();
    this.documentoObligatorio = (this.peticion.catalogo?.documentoObligatorio !== undefined) ? this.peticion.catalogo?.documentoObligatorio : 0;

    if (this.peticion && this.peticion.ficheros) {
      this.uploadedFiles = this.peticion.ficheros;
    }
    if (this.peticion && this.peticion.estado) {
      this.estado = this.peticion.estado?.id;
    }

    this.esAuditor =
      this.sesionService.getUser().rol?.id === this.constantes.ROL_AUDITOR;
  }

  nextPage(): void {
    this.hayFicherosSinSubir('peticiones/crear/notificaciones');
  }

  previousPage(): void {
    this.hayFicherosSinSubir('peticiones/crear/datos');
  }

  private hayFicherosSinSubir(ruta: string): void {
    if (this.fileInput && this.fileInput.files.length > 0) {
      const data = {
        title: 'Documentos',
        msg: `Tiene ficheros sin subir. ¿Desea guardarlos?`,
        buttons: [
          { title: 'Aceptar', class: 'btn-success', action: '1' },
          { title: 'Cancelar', class: 'btn-danger' },
        ] as ButtonModalInfo[],
      } as ModalInfo;
      this.dialog
        .open(ModalInfoComponent, {
          disableClose: true,
          autoFocus: false,
          data,
        })
        .afterClosed()
        .subscribe((resp) => {
          if (resp) {
            this.subirFicheros();
          }
        });
    } else {
      this.router.navigate([ruta]);
    }
  }

  subirFicheros(): void {
    if (this.fileInput && this.fileInput.files.length > 0) {
      this.fileInput.upload();
      if (this.peticion) {
        if (this.fileInput.files.length + this.uploadedFiles.length > 5) {
          this.mensaje.showErrorPeticion(
            'El número de ficheros supera el máximo: 5.'
          );
        } else {
          if (this.longitudPermitidaFicheros()) {
            // en funcion de si ya hemos creado ficheros llamaremos a un endpoint u otro
            this.spinner.show();
            this.crearFicheros();
          }
        }
      }
    }
  }

  private crearFicheros(): void {
    // llamar al back para guardar los ficheros
    if (this.uploadedFiles.length > 0) {
      this.peticionService
        .anexarFicheros(this.peticion.idPeticion, this.fileInput.files)
        .subscribe(
          (result: any) => {
            this.handleFicherosResponse(result);
          },
          (error) => {
            this.mostrarMensajeErrorSubidaFicheros(error.message);
          }
        );
    } else {
      this.peticionService
        .guardarFicheros(this.peticion.idPeticion, this.fileInput.files)
        .subscribe(
          (result: any) => {
            this.handleFicherosResponse(result);
          },
          (error) => {
            this.mostrarMensajeErrorSubidaFicheros(error.message);
          }
        );
    }
  }

  private mostrarMensajeErrorSubidaFicheros(mensaje: string): void {
    this.spinner.hide();
    this.mensaje.showErrorPeticion(
      'No se han podido guardar los documentos.\n' + mensaje
    );
  }

  private handleFicherosResponse(response: any): void {
    if (response && response.body) {
      this.peticionService.setPeticion(response.body);
      this.peticion = response.body;
      this.uploadedFiles = this.peticion?.ficheros || [];
      this.mensaje.showMensaje('Ficheros guardados satisfactoriamente.');
      this.fileInput.clear();
      this.spinner.hide();
    }
  }

  private longitudPermitidaFicheros(): boolean {
    for (const fichero of this.fileInput.files) {
      if (
        fichero.name.length > this.constantes.LONIGUTD_MAXIMA_NOMBRE_FICHERO
      ) {
        this.mensaje.showErrorPeticion(
          `El fichero: ${fichero.name} excede de ${this.constantes.LONIGUTD_MAXIMA_NOMBRE_FICHERO} caracteres`
        );
        return false;
      }
    }
    return true;
  }

  descargarFichero(fichero: Fichero): void {
    this.spinner.show();
    this.peticionService
      .descargarFichero(fichero.idPeticion, fichero.idFichero)
      .subscribe(
        (fileReceived: any) => {
          this.spinner.hide();
          Utils.base64ToFile(fileReceived.file, fichero.nombre);
        },
        (errorDescarga: any) => {
          this.mensaje.showErrorPeticion(
            'No se ha podido descargar el fichero ' + errorDescarga.error
          );
          this.spinner.hide();
        }
      );
  }

  visualizarFichero(fichero: Fichero): void {
    let base: string =
      this.constantes.ENDPOINT_API_PETICIONES_VISUALIZAR_FICHERO.replace(
        '{idPeticion}',
        String(fichero.idPeticion)
      ).replace('{idFichero}', fichero.idFichero);

    let contentType = Utils.calculateMediaType(fichero.nombre.split('.')[1]);
    let finalURL = base;
    if(contentType.includes('doc') || contentType.includes('xls')){
      finalURL = "https://view.officeapps.live.com/op/embed.aspx?src=" + base;
    }

    const link = document.createElement('a');
    link.href = finalURL;
    link.target = '_blank';
    link.click();
  }

  eliminarFichero(idPeticion: string, idFichero: string): void {
    this.spinner.show();
    this.peticionService.eliminarFichero(idPeticion, idFichero).subscribe(
      (result: Peticion) => {
        if (result) {
          this.spinner.hide();
          this.mensaje.showMensaje('Fichero eliminado satisfactoriamente.');
          this.peticionService.setPeticion(result);
          this.peticion = result;
          this.uploadedFiles = this.peticion?.ficheros || [];
        }
      },
      (error) => {
        this.spinner.hide();
        this.mensaje.showErrorPeticion(
          'No se ha podido eliminar el fichero. ' + error.error
        );
      }
    );
  }

  public fijarElemento(event: any): void {
    event.preventDefault();
  }

  public mostrarModalArtica(): void {
    this.modalArtica.mostrarModal(this.peticion.idPeticion || '');
  }

  articaRecibida(idPeticion?: string): void {
    if (idPeticion) {
      this.peticion.fechaArtica = new Date();
    }
  }
}

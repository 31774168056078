import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Urgencia } from '../modelos/urgencia';
import { Puesto } from '../modelos/puesto';
import { Accion } from '../modelos/accion';

@Injectable()
export class Constantes {
  public readonly orderTableNgPrime: Map<string, string> = new Map([
    ['1', 'asc'],
    ['-1', 'desc'],
  ]);

  public readonly listaAreaDestino: string[] = ['RRHH', 'PRL'];
  public readonly listaUrgencia: Urgencia[] = [
    { id: 3, descripcion: 'Baja' },
    { id: 2, descripcion: 'Media' },
    { id: 1, descripcion: 'Alta' },
  ];
  public readonly listaAcciones: Accion[] = [
    { id: 1, descripcion: 'Borrador' },
    { id: 2, descripcion: 'Editar' },
    { id: 4, descripcion: 'Eliminar' },
    { id: 5, descripcion: 'Solicitar' },
    { id: 6, descripcion: 'Comentar' },
    { id: 7, descripcion: 'Aclaración' },
    { id: 9, descripcion: 'Responder' },
    { id: 11, descripcion: 'Informar' },
    { id: 12, descripcion: 'Solicitar Autorización' },
    { id: 13, descripcion: 'Rechazar y Cerrar' },
    { id: 14, descripcion: 'Resolver y Cerrar' },
    { id: 16, descripcion: 'Autorizar' },
    { id: 23, descripcion: 'Ártica' },
    { id: 33, descripcion: 'Reabrir' },
    { id: 34, descripcion:  'Acceso a SGC' },
    { id: 35, descripcion:  'Informacion del Empleado SGC' },
  ];
  public readonly numeroUsuariosPorPagina: number[] = [25, 50, 75];
  public readonly listaPuestosTecnicos: Puesto[] = [
    { id: 24, descripcion: 'TÉCNICO RRHH' },
    { id: 25, descripcion: 'TÉCNICO PRL' },
  ];

  public readonly ACCION_EDITAR: number = 2;
  public readonly ACCION_VISUALIZAR: number = 3;
  public readonly ACCION_ELIMINAR: number = 4;
  public readonly ACCION_COMENTAR: number = 6;
  public readonly ACCION_ACLARACION: number = 7;
  public readonly ACCION_RESPONDER: number = 9;
  public readonly ACCION_DEVOLVER: number = 10;
  public readonly ACCION_INFORMAR: number = 11;
  public readonly ACCION_SOLICITAR_AUTORIZACION: number = 12;
  public readonly ACCION_RECHAZAR: number = 13;
  public readonly ACCION_RESOLVER_CERRAR: number = 14;
  public readonly ACCION_AUTORIZAR: number = 16;
  public readonly ACCION_ARTICA: number = 23;
  public readonly ACCION_REABRIR: number = 33;
  public readonly ACCION_AS400WEB: number = 34;
  public readonly ACCION_INFORMACION_EMPLEADO: number = 35;

  public readonly ESTADO_BORRADOR: number = 1;
  public readonly ESTADO_PDTE_VALORACION: number = 2;
  public readonly ESTADO_DEVUELTA_SOLICITANTE: number = 3;
  public readonly ESTADO_EN_CURSO: number = 4;
  public readonly ESTADO_CERRADA: number = 6;
  public readonly ESTADO_RESUELTA_AUTORIZACION: number = 8;

  public readonly AUTOMATISMO = 'AUTOMATISMO';

  public readonly TIPO_CATALOGO_CHECKLIST_CESION_ILEGAL = '820.14';

  public readonly LONIGUTD_MAXIMA_NOMBRE_FICHERO = 80;

  public readonly ROL_AUDITOR = 7;

  public readonly EULEN: string = '/eulen';
  public readonly API_ENDPOINT_ROLES: string = this.EULEN + '/v1/roles/';
  public readonly API_ENDPOINT_ROLES_PERMISOS: string =
    this.EULEN + '/v1/roles/permisos';
  public readonly API_ENDPOINT_ROLES_CAMBIAR: string =
    this.EULEN + '/v1/roles/{idPermiso}';
  public readonly API_ENDPOINT_ZONAS: string = this.EULEN + '/v1/zonas/';
  public readonly API_ENDPOINT_PUESTOS: string = this.EULEN + '/v1/puestos/';
  public readonly API_ENDPOINT_EMPRESAS: string = this.EULEN + '/v1/empresas/';
  public readonly API_ENDPOINT_ACTIVIDADES: string =
    this.EULEN + '/v1/actividades/';
  public readonly API_ENDPOINT_LINEAS_VENTA: string =
    this.EULEN + '/v1/lineas-ventas';
  public readonly API_ENDPOINT_PROVINCIAS: string =
    this.EULEN + '/v1/provincias';
  public readonly API_ENDPOINT_AMBITOS: string = this.EULEN + '/v1/ambitos';
  public readonly API_ENDPOINT_LINEAS_VENTA_ACTIVIDAD: string =
    this.EULEN + '/v1/actividades/{idActividad}/lineas-ventas';
  public readonly API_ENDPOINT_AMBITOS_ZONA: string =
    this.EULEN + '/v1/zonas/{idZona}/ambitos';
  public readonly API_ENDPOINT_AMBITOS_REGIONES: string =
    this.EULEN + '/v1/regiones/';
  public readonly API_ENDPOINT_AMBITOS_PROVINCIA: string =
    this.EULEN + '/v1/ambitos/{idAmbito}/provincias';
  public readonly API_ENDPOINT_USUARIOS: string = this.EULEN + '/v1/usuarios';
  public readonly API_ENDPOINT_NEW_USUARIO: string =
    this.EULEN + '/v1/usuarios/peticion-alta';
  public readonly API_ENDPOINT_LOGIN: string = this.EULEN + '/v1/login';
  public readonly API_ENDPOINT_REGIONES_PROVINCIAS: string =
    this.EULEN + '/v1/regiones/{idRegiones}/provincias';
  public readonly API_ENDPOINT_USUARIOS_BUSCAR: string =
    this.EULEN + '/v1/usuarios/buscar-filtro';
  public readonly API_ENDPOINT_USUARIOS_DNI: string =
    this.EULEN + '/v1/usuarios/buscar-dnis';
  public readonly API_ENDPOINT_USUARIOS_NOMBRE: string =
    this.EULEN + '/v1/usuarios/buscar-nombres';
  public readonly API_ENDPOINT_USUARIOS_APELLIDO: string =
    this.EULEN + '/v1/usuarios/buscar-apellidos';
  public readonly API_ENDPOINT_USUARIOS_EMAIL: string =
    this.EULEN + '/v1/usuarios/buscar-emails';
  public readonly API_ENDPOINT_USUARIOS_AUDITORIA_BUSCAR: string =
    this.EULEN + '/v1/auditorias/usuarios';
  public readonly API_ENDPOINT_PETICIONES_AUDITORIA: string =
    this.EULEN + '/v1/auditorias-peticion';
  public readonly API_ENDPOINT_EXPORTAR: string =
    this.EULEN + '/v1/spreadsheet';
  public readonly API_ENDPOINT_USUARIOS_EXPORTAR: string =
    this.EULEN + '/v1/spreadsheet/exportar-usuarios';
  public readonly API_ENDPOINT_AUDITORIA_EXPORTAR: string =
    this.EULEN + '/v1/spreadsheet/exportar-auditoria';
  public readonly API_ENDPOINT_TIPOS_SOLICITANTES: string =
    this.EULEN + '/v1/tipos-solicitantes';
  public readonly API_ENDPOINT_GRUPOS_SOPORTE: string =
    this.EULEN + '/v1/grupossoporte';
  public readonly API_ENDPOINT_URGENCIAS: string = this.EULEN + '/v1/urgencias';
  public readonly API_ENDPOINT_IMPACTOS: string = this.EULEN + '/v1/impactos';
  public readonly API_ENDPOINT_CONVENIO: string = this.EULEN + '/v1/convenios';
  public readonly API_ENDPOINT_SLA: string = this.EULEN + '/v1/sla';
  public readonly API_ENDPOINT_CONVENIO_OBTENER: string =
    this.EULEN + '/v1/convenios/obtenerConvenio/{idConvenio}';
  public readonly API_ENDPOINT_CONVENIO_BUSCAR: string =
    this.EULEN + '/v1/convenios/buscar-filtro';
  public readonly API_ENDPOINT_CONVENIO_BUSCAR_DENOMINACION: string =
    this.EULEN + '/v1/convenios/{denominacion}';
  public readonly API_ENDPOINT_EMPRESAS_TERRITORIOS: string =
    this.EULEN + '/v1/empresas/territorios';
  public readonly API_ENDPOINT_CATALOGO: string = this.EULEN + '/v1/catalogos';
  public readonly API_ENDPOINT_CREAR_DATOS_ESPECIFICOS: string =
    this.EULEN + '/v1/catalogos/crear/{idPeticion}/datos';
  public readonly API_ENDPOINT_COMBOS_VALORES: string =
    this.EULEN + '/v1/catalogos/comboValor';
  public readonly API_ENDPOINT_MODIFICAR_DATOS: string =
    this.EULEN + '/v1/catalogos/modificar/datos';
  public readonly API_ENDPOINT_ELIMINAR_DATOS: string =
    this.EULEN + '/v1/catalogos/eliminar/{id}/datos';
  public readonly API_ENDPOINT_CATALOGO_COD_TIPO: string =
    this.EULEN + '/v1/catalogos/{codTipoCatalogo}';
  public readonly API_ENDPOINT_CATALOGO_DATOS_ESPECIFICOS: string =
    this.EULEN + '/v1/peticiones/{codigoPeticion}/datos-especificos';
  public readonly API_ENDPOINT_CATALOGO_DATOS_ESPECIFICOS_CONFIG: string =
    this.EULEN + '/v1/peticiones/{codigoPeticion}/datos-especificos-config';
  public readonly API_ENDPOINT_CATALOGO_DATOS_ESPECIFICOS_CAMBIO: string =
    this.EULEN + '/v1/peticiones/{codigoPeticion}/datos-especificos/{cambio}';
  public readonly API_ENDPOINT_ESTADOS: string = this.EULEN + '/v1/estados';
  public readonly API_ENDPOINT_PETICION_EDITAR: string =
    this.EULEN + '/v1/peticiones/{idPeticion}';
  public readonly API_ENDPOINT_PETICION_COMENTAR: string =
    this.EULEN + '/v1/peticiones/{idPeticion}/comentar';
  public readonly API_ENDPOINT_PLANTILLAS: string =
    this.EULEN + '/v1/plantillas';
  public readonly API_ENDPOINT_NOTIFICACIONES: string =
    this.EULEN + '/v1/notificaciones';
  public readonly API_ENDPOINT_PETICIONES: string =
    this.EULEN + '/v1/peticiones';
  public readonly API_ENDPOINT_PETICIONES_FECHAS: string =
    this.EULEN + '/v1/peticiones/fechas';
  public readonly API_ENDPOINT_PETICIONES_ELIMINAR: string =
    this.EULEN + '/v1/peticiones/{idPeticion}';
  public readonly API_ENDPOINT_PETICIONES_FICHEROS: string =
    this.EULEN + '/v1/peticiones/{idPeticion}/ficheros';
  public readonly API_ENDPOINT_PETICIONES_ENVIAR: string =
    this.EULEN + '/v1/peticiones/{idPeticion}/enviar';
  public readonly API_ENDPOINT_PETICIONES_BORRADOR: string =
    this.EULEN + '/v1/peticiones/{idPeticion}/borrador';
  public readonly API_ENDPOINT_PETICIONES_ELIMINAR_FICHERO: string =
    this.EULEN + '/v1/peticiones/{idPeticion}/ficheros/{idFichero}';
  public readonly API_ENDPOINT_PETICIONES_ELIMINAR_FICHERO_COMENTARIOS: string =
    this.EULEN + '/v1/peticiones/{idPeticion}/comentar/ficheros/{idFichero}';
  public readonly API_ENDPOINT_ACLARACIONES_ELIMINAR_FICHERO: string =
    this.EULEN +
    '/v1/peticiones/aclaraciones/{idAclaracion}/ficheros/{idFichero}';
  public readonly API_ENDPOINT_CIERRE_ELIMINAR_FICHERO: string =
    this.EULEN + '/v1/peticiones/cierre/{idCierre}/ficheros/{idFichero}';
  public readonly API_ENDPOINT_PETICIONES_DESCARGAR_FICHERO: string =
    this.EULEN + '/v1/peticiones/{idPeticion}/ficheros/{idFichero}';
  public readonly API_ENDPOINT_PETICIONES_DESCARGAR_FICHERO_COMENTARIOS: string =
    this.EULEN + '/v1/peticiones/{idPeticion}/comentar/ficheros/{idFichero}';
  public readonly API_ENDPOINT_PETICIONES_VISUALIZAR_FICHERO: string =
    this.EULEN + '/v1/peticiones/{idPeticion}/ficheros/vista/{idFichero}';
  public readonly API_ENDPOINT_PETICIONES_VISUALIZAR_FICHERO_COMENTARIOS: string =
    this.EULEN +
    '/v1/peticiones/{idPeticion}/comentar/ficheros/vista/{idFichero}';
  public readonly API_ENDPOINT_PETICIONES_NOTIFICACION: string =
    this.EULEN + '/v1/notificaciones-grupos/{idPeticion}/{idGrupo}';
  public readonly API_ENDPOINT_PETICION_ACTUACIONES: string =
    this.EULEN + '/v1/peticiones/acciones';
  public readonly API_ENDPOINT_PETICIONES_ACTUACION: string =
    this.EULEN + '/v1/peticiones/{idPeticion}/acciones';
  public readonly API_ENDPOINT_PETICION_NOTIFICADOS: string =
    this.EULEN +
    '/v1/notificaciones/{idPeticion}/{codAccion}/notificadosflujogenerico';
  public readonly API_ENDPOINT_PETICION_RESPONDER: string =
    this.EULEN + '/v1/peticiones/{idPeticion}/responder';
  public readonly API_ENDPOINT_PETICION_RECHAZAR: string =
    this.EULEN + '/v1/peticiones/{idPeticion}/rechazar';
  public readonly API_ENDPOINT_PETICION_RESOLVER: string =
    this.EULEN + '/v1/peticiones/{idPeticion}/resolver';
  public readonly API_ENDPOINT_PETICION_ACLARAR: string =
    this.EULEN + '/v1/peticiones/{idPeticion}/aclaraciones';
  public readonly API_ENDPOINT_PETICION_OBTENER_CIERRE: string =
    this.EULEN + '/v1/peticiones/{idPeticion}/cierres';
  public readonly API_ENDPOINT_PETICION_ACLARAR_BORRADOR: string =
    this.EULEN + '/v1/peticiones/{idPeticion}/aclaraciones/borrador';
  public readonly API_ENDPOINT_PETICION_REABRIR_BORRADOR: string =
    this.EULEN + '/v1/peticiones/reabrir/{idPeticion}/borrador';
  public readonly API_ENDPOINT_PETICION_AUTORIZAR_BORRADOR: string =
    this.EULEN + '/v1/peticiones/{idPeticion}/autorizar/borrador';
  public readonly API_ENDPOINT_PETICION_INFORMAR_BORRADOR: string =
    this.EULEN + '/v1/peticiones/{idPeticion}/informaciones/borrador';
  public readonly API_ENDPOINT_PETICION_CIERRE_BORRADOR_ELIMINAR: string =
    this.EULEN + '/v1/peticiones/{idPeticion}/resolver/borrador/{cierre}';
  public readonly API_ENDPOINT_PETICION_CIERRE_BORRADOR: string =
    this.EULEN + '/v1/peticiones/{idPeticion}/resolver/borrador';
  public readonly API_ENDPOINT_PETICION_ACLARAR_BORRADOR_ACTUALIZAR: string =
    this.EULEN + '/v1/peticiones/{idPeticion}/aclaraciones/borrador/actualizar';
  public readonly API_ENDPOINT_PETICION_ACLARAR_BORRADOR_EDITAR: string =
    this.EULEN + '/v1/peticiones/{idPeticion}/aclaraciones/borrador/editar';
  public readonly API_ENDPOINT_PETICION_CIERRE_BORRADOR_EDITAR: string =
    this.EULEN + '/v1/peticiones/{idPeticion}/resolver/borrador/editar';
  public readonly API_ENDPOINT_PETICION_REABRIR_BORRADOR_EDITAR: string =
    this.EULEN + '/v1/peticiones/reabrir/{idPeticion}/borrador/editar';
  public readonly API_ENDPOINT_PETICION_ACLARAR_BORRADOR_ELIMINAR: string =
    this.EULEN +
    '/v1/peticiones/{idPeticion}/aclaraciones/borrador/usuario/{usuario}';
  public readonly API_ENDPOINT_PETICION_REABRIR_BORRADOR_ELIMINAR: string =
    this.EULEN +
    '/v1/peticiones/reabrir/{idPeticion}/borrador/usuario/{usuario}';
  public readonly API_ENDPOINT_PETICION_AUTORIZAR_BORRADOR_ELIMINAR: string =
    this.EULEN + '/v1/peticiones/{idPeticion}/autorizar/borrador/eliminar';
  public readonly API_ENDPOINT_PETICION_INFORMAR_BORRADOR_ELIMINAR: string =
    this.EULEN + '/v1/peticiones/{idPeticion}/informaciones/borrador/eliminar';
  public readonly API_ENDPOINT_PETICION_ACLARAR_DESCARGAR_FICHERO: string =
    this.EULEN +
    '/v1/peticiones/{idPeticion}/aclaraciones/{idAclaracion}/ficheros/{idFichero}';
  public readonly API_ENDPOINT_PETICION_ACLARAR_VISUALIZAR_FICHERO: string =
    this.EULEN +
    '/v1/peticiones/{idPeticion}/aclaraciones/{idAclaracion}/ficheros/vista/{idFichero}';
  public readonly API_ENDPOINT_PETICION_ACTUALIZAR_DESTINATARIO: string =
    this.EULEN + '/v1/peticiones/{idPeticion}/destinatario';
  public readonly API_ENDPOINT_PETICION_OBTENER_AUTORIZANTES: string =
    this.EULEN + '/v1/peticiones/{idPeticion}/autorizantes';
  public readonly API_ENDPOINT_PETICION_OBTENER_INFORMANTES: string =
    this.EULEN + '/v1/peticiones/{idPeticion}/informantes';
  public readonly API_ENDPOINT_PETICION_SOLICITAR_AUTORIZACION: string =
    this.EULEN + '/v1/peticiones/{idPeticion}/solicitar-autorizacion';
  public readonly API_ENDPOINT_PETICION_INFORMACIONES: string =
    this.EULEN + '/v1/peticiones/{idPeticion}/informaciones';
  public readonly API_ENDPOINT_PETICION_INFORMACIONES_PETICIONES: string =
    this.EULEN + '/v1/peticiones/{idPeticion}/informaciones/peticion';
  public readonly API_ENDPOINT_PETICION_INFORMAR: string =
    this.EULEN + '/v1/peticiones/{idPeticion}/informar';
  public readonly API_ENDPOINT_PETICION_AUTORIZAR: string =
    this.EULEN + '/v1/peticiones/{idPeticion}/autorizar';
  public readonly API_ENDPOINT_PETICION_ARTICA: string =
    this.EULEN + '/v1/peticiones/{idPeticion}/subir-artica';
  public readonly API_ENDPOINT_PETICION_ENLACE_ARTICA: string =
    this.EULEN + '/v1/peticiones/{idPeticion}/enlace-artica';
  public readonly API_ENDPOINT_ACCIONES_COMBO: string =
    this.EULEN + '/v1/acciones/combo';
  public readonly API_ENDPOINT_ACCIONES_ROLES: string =
    this.EULEN + '/v1/acciones/acciones-roles';
  public readonly API_ENDPOINT_ACCIONES_REABRIR: string =
    this.EULEN + '/v1/peticiones/reabrir';
  public readonly API_ENDPOINT_INFORMESDATASTUDIO: string =
    this.EULEN + '/v1/informesdatastudio';
  public readonly API_ENDPOINT_PETICIONES_APELLIDO: string =
    this.EULEN + '/v1/peticiones/buscar-apellidos';
  public readonly API_ENDPOINT_PETICIONES_DNI: string =
    this.EULEN + '/v1/peticiones/buscar-dnis';
  public readonly API_ENDPOINT_PETICIONES_NOMBRE: string =
    this.EULEN + '/v1/peticiones/buscar-nombres';
  public readonly API_ENDPOINT_SGC_UPDATE_TABLE: string =
    this.EULEN + '/v1/sgc/actualizar-tablas';
  public readonly API_ENDPOINT_SGC_SECUENCIALES: string =
    this.EULEN + '/v1/sgc/secuenciales/{dni}';

  public readonly ENDPOINT_API_ROLES: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_ROLES;

  public readonly ENDPOINT_API_ROLES_PERMISOS: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_ROLES_PERMISOS;

  public readonly ENDPOINT_API_ROLES_CAMBIAR: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_ROLES_CAMBIAR;

  public readonly ENDPOINT_API_ZONAS: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_ZONAS;

  public readonly ENDPOINT_API_PUESTOS: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_PUESTOS;

  public readonly ENDPOINT_API_PUESTOS_LIST: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_PUESTOS +
    'list';

  public readonly ENDPOINT_API_ACTIVIDADES: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_ACTIVIDADES;

  public readonly ENDPOINT_API_EMPRESAS: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_EMPRESAS;

  public readonly ENDPOINT_API_PROVINCIAS: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_PROVINCIAS;

  public readonly ENDPOINT_API_AMBITOS: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_AMBITOS;

  public readonly ENDPOINT_API_AMBITOS_ZONA: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_AMBITOS_ZONA;

  public readonly ENDPOINT_API_REGIONES: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_AMBITOS_REGIONES;

  public readonly ENDPOINT_API_AMBITOS_PROVINCIA: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_AMBITOS_PROVINCIA;

  public readonly ENDPOINT_API_USUARIOS: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_USUARIOS;

  public readonly ENDPOINT_API_NEW_USUARIO: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_NEW_USUARIO;

  public readonly ENDPOINT_API_GRUPOS_SOPORTE: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_GRUPOS_SOPORTE;

  public readonly ENDPOINT_API_URGENCIAS: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_URGENCIAS;

  public readonly ENDPOINT_API_IMPACTOS: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_IMPACTOS;

  public readonly ENDPOINT_API_GRUPOS_SOPORTE_NOTIFICADOS: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_GRUPOS_SOPORTE +
    '/notificados';

  public readonly ENDPOINT_API_USUARIOS_VALIDAR: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_USUARIOS +
    '/{idUser}/validar';

  public readonly ENDPOINT_API_USUARIOS_ACTIVAR: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_USUARIOS +
    '/{idUser}/activar';

  public readonly ENDPOINT_API_USUARIOS_ID: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_USUARIOS +
    '/{idUser}';

  public readonly ENDPOINT_API_USUARIOS_ID_FULL: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_USUARIOS +
    '/{idUser}/full';

  public readonly ENDPOINT_API_LOGIN: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_LOGIN +
    '/{idUser}';

  public readonly ENDPOINT_API_USUARIOS_BUSCAR_DESTINATARIOS: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_USUARIOS +
    '/buscar-destinatarios';

  public readonly ENDPOINT_API_USUARIOS_BUSCAR: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_USUARIOS_BUSCAR;

  public readonly ENDPOINT_API_USUARIOS_DNI: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_USUARIOS_DNI;

  public readonly ENDPOINT_API_USUARIOS_NOMBRE: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_USUARIOS_NOMBRE;

  public readonly ENDPOINT_API_USUARIOS_APELLIDO: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_USUARIOS_APELLIDO;

  public readonly ENDPOINT_API_USUARIOS_EMAIL: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_USUARIOS_EMAIL;

  public readonly ENDPOINT_API_USUARIOS_AUDITORIA_BUSCAR: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_USUARIOS_AUDITORIA_BUSCAR;

  public readonly ENDPOINT_API_PETICIONES_AUDITORIA: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_PETICIONES_AUDITORIA;

  public readonly ENDPOINT_API_USUARIOS_EXPORTAR: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_USUARIOS_EXPORTAR;

  public readonly ENDPOINT_API_AUDITORIA_PETICIONES_EXPORTAR: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_AUDITORIA_EXPORTAR;

  public readonly ENDPOINT_API_EXPORTAR: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_EXPORTAR;

  public readonly ENDPOINT_API_USUARIOS_LOGIN: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_USUARIOS +
    '/{idUser}/login';

  public readonly ENDPOINT_API_REGIONES_PROVINCIA: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_REGIONES_PROVINCIAS;

  public readonly ENDPOINT_API_TIPOS_SOLICITANTES: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_TIPOS_SOLICITANTES;

  public readonly ENDPOINT_API_LINEAS_VENTA: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_LINEAS_VENTA;

  public readonly ENDPOINT_API_LINEAS_VENTA_ACT: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_LINEAS_VENTA_ACTIVIDAD;

  public readonly ENDPOINT_API_CONVENIOS: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_CONVENIO;

  public readonly ENDPOINT_API_SLA: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_SLA;

  public readonly ENDPOINT_API_CONVENIOS_OBTENER: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_CONVENIO_OBTENER;

  public readonly ENDPOINT_API_CONVENIOS_BUSCAR: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_CONVENIO_BUSCAR;

  public readonly ENDPOINT_API_CONVENIOS_BUSCAR_DENOMINACION: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_CONVENIO_BUSCAR_DENOMINACION;

  public readonly ENDPOINT_API_CATALOGOS: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_CATALOGO;

  public readonly ENDPOINT_API_CREAR_DATOS_ESPECIFICOS: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_CREAR_DATOS_ESPECIFICOS;

  public readonly ENDPOINT_API_COMBOS_VALORES: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_COMBOS_VALORES;

  public readonly ENDPOINT_API_MODIFICAR_DATOS: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_MODIFICAR_DATOS;

  public readonly ENDPOINT_API_ELIMINAR_DATOS: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_ELIMINAR_DATOS;

  public readonly ENDPOINT_API_CATALOGOS_LIST: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_CATALOGO +
    '/list';

  public readonly ENDPOINT_API_CATALOGO_COD_TIPO: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_CATALOGO_COD_TIPO;

  public readonly ENDPOINT_API_CATALOGO_COD_TIPO_AUTOMATISMOS: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_CATALOGO_COD_TIPO +
    '/plantillas-automatismos';

  public readonly ENDPOINT_API_CATALOGO_DATOS_ESPECIFICOS: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_CATALOGO_DATOS_ESPECIFICOS;

  public readonly ENDPOINT_API_CATALOGO_DATOS_ESPECIFICOS_CONFIG: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_CATALOGO_DATOS_ESPECIFICOS_CONFIG;

  public readonly ENDPOINT_API_CATALOGO_DATOS_ESPECIFICOS_CAMBIO: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_CATALOGO_DATOS_ESPECIFICOS_CAMBIO;

  public readonly ENDPOINT_API_PETICIONES: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_PETICIONES;

  public readonly ENDPOINT_API_PETICION_EDITAR: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_PETICION_EDITAR;

  public readonly ENDPOINT_API_PETICION_COMENTAR: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_PETICION_COMENTAR;

  public readonly ENDPOINT_API_ESTADOS: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_ESTADOS;

  public readonly ENDPOINT_API_PETICIONES_FECHAS: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_PETICIONES_FECHAS;

  public readonly ENDPOINT_API_PETICIONES_ELIMINAR: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_PETICIONES_ELIMINAR;

  public readonly ENDPOINT_API_PETICIONES_FICHEROS: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_PETICIONES_FICHEROS;

  public readonly ENDPOINT_API_PETICIONES_DESCARGAR_FICHERO: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_PETICIONES_DESCARGAR_FICHERO;

  public readonly ENDPOINT_API_PETICIONES_DESCARGAR_FICHERO_COMENTARIOS: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_PETICIONES_DESCARGAR_FICHERO_COMENTARIOS;

  public readonly ENDPOINT_API_PETICIONES_VISUALIZAR_FICHERO: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_PETICIONES_VISUALIZAR_FICHERO;

  public readonly ENDPOINT_API_PETICIONES_VISUALIZAR_FICHERO_COMENTARIOS: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_PETICIONES_VISUALIZAR_FICHERO_COMENTARIOS;

  public readonly ENDPOINT_API_PETICIONES_ELIMINAR_FICHERO: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_PETICIONES_ELIMINAR_FICHERO;

  public readonly ENDPOINT_API_PETICIONES_ELIMINAR_FICHERO_COMENTARIOS: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_PETICIONES_ELIMINAR_FICHERO_COMENTARIOS;

  public readonly ENDPOINT_API_ACLARACIONES_ELIMINAR_FICHERO: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_ACLARACIONES_ELIMINAR_FICHERO;

  public readonly ENDPOINT_API_CIERRE_ELIMINAR_FICHERO: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_CIERRE_ELIMINAR_FICHERO;

  public readonly ENDPOINT_API_PETICIONES_ENVIAR: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_PETICIONES_ENVIAR;

  public readonly ENDPOINT_API_PETICIONES_BORRADOR: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_PETICIONES_BORRADOR;

  public readonly ENDPOINT_API_PETICIONES_NOTIFICACION: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_PETICIONES_NOTIFICACION;

  public readonly ENDPOINT_API_PETICIONES_APELLIDO: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_PETICIONES_APELLIDO;

  public readonly ENDPOINT_API_PETICIONES_DNI: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_PETICIONES_DNI;

  public readonly ENDPOINT_API_PETICIONES_NOMBRE: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_PETICIONES_NOMBRE;

  public readonly GOOGLE_POPUP_CLOSED_BY_USER = 'popup_closed_by_user';

  public readonly ID_ADMINISTRADOR: number = 1;
  public readonly ID_ADMINISTRADOR_LOCAL: number = 2;
  public readonly ID_AUTORIZANTE: number = 3;
  public readonly ID_INFORMANTE: number = 4;
  public readonly ID_EDITOR: number = 6;

  public readonly ENDPOINT_API_USUARIOS_ADMINGOOGLE: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_USUARIOS +
    '/{idUser}/admingoogledirectory';

  public readonly ENDPOINT_API_PLANTILLAS_REMPLAZAR: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_PLANTILLAS +
    '/remplazar';

  public readonly ENDPOINT_API_NOTIFICACIONES_NOTIFICADORES: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_NOTIFICACIONES +
    '/{idPeticion}/notificadores';

  public readonly ENDPOINT_API_NOTIFICACIONES_DETALLE: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_NOTIFICACIONES +
    '/{idTipoPeticion}/acciones/{idAccion}';

  public readonly ENDPOINT_API_EMPRESAS_TERRITORIOS: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_EMPRESAS_TERRITORIOS;

  public readonly ENDPOINT_API_PETICION_ACTUACIONES: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_PETICION_ACTUACIONES;

  public readonly ENDPOINT_API_PETICION_ACTUACION: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_PETICIONES_ACTUACION;

  public readonly ENDPOINT_API_PETICION_NOTIFICADOS: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_PETICION_NOTIFICADOS;

  public readonly ENDPOINT_API_PETICION_RESPONDER: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_PETICION_RESPONDER;

  public readonly ENDPOINT_API_PETICION_RESOLVER: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_PETICION_RESOLVER;

  public readonly ENDPOINT_API_PETICION_ACLARAR: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_PETICION_ACLARAR;

  public readonly ENDPOINT_API_PETICION_OBTENER_CIERRE: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_PETICION_OBTENER_CIERRE;

  public readonly ENDPOINT_API_PETICION_ACLARAR_BORRADOR: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_PETICION_ACLARAR_BORRADOR;

  public readonly ENDPOINT_API_PETICION_REABRIR_BORRADOR: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_PETICION_REABRIR_BORRADOR;

  public readonly ENDPOINT_API_PETICION_AUTORIZAR_BORRADOR: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_PETICION_AUTORIZAR_BORRADOR;

  public readonly ENDPOINT_API_PETICION_INFORMAR_BORRADOR: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_PETICION_INFORMAR_BORRADOR;

  public readonly ENDPOINT_API_PETICION_CIERRE_BORRADOR: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_PETICION_CIERRE_BORRADOR;

  public readonly ENDPOINT_API_PETICION_ACLARAR_BORRADOR_ACTUALIZAR: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_PETICION_ACLARAR_BORRADOR_ACTUALIZAR;

  public readonly ENDPOINT_API_PETICION_ACLARAR_BORRADOR_EDITAR: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_PETICION_ACLARAR_BORRADOR_EDITAR;

  public readonly ENDPOINT_API_PETICION_CIERRE_BORRADOR_EDITAR: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_PETICION_CIERRE_BORRADOR_EDITAR;

  public readonly ENDPOINT_API_PETICION_REABRIR_BORRADOR_EDITAR: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_PETICION_REABRIR_BORRADOR_EDITAR;

  public readonly ENDPOINT_API_PETICION_ACLARAR_BORRADOR_ELIMINAR: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_PETICION_ACLARAR_BORRADOR_ELIMINAR;

  public readonly ENDPOINT_API_PETICION_CIERRE_BORRADOR_ELIMINAR: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_PETICION_CIERRE_BORRADOR_ELIMINAR;

  public readonly ENDPOINT_API_PETICION_REABRIR_BORRADOR_ELIMINAR: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_PETICION_REABRIR_BORRADOR_ELIMINAR;

  public readonly ENDPOINT_API_PETICION_AUTORIZAR_BORRADOR_ELIMINAR: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_PETICION_AUTORIZAR_BORRADOR_ELIMINAR;

  public readonly ENDPOINT_API_PETICION_INFORMAR_BORRADOR_ELIMINAR: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_PETICION_INFORMAR_BORRADOR_ELIMINAR;

  public readonly ENDPOINT_API_PETICION_RECHAZAR: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_PETICION_RECHAZAR;

  public readonly ENDPOINT_API_PETICION_ACLARAR_DESCARGAR_FICHERO: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_PETICION_ACLARAR_DESCARGAR_FICHERO;

  public readonly ENDPOINT_API_PETICION_ACLARAR_VISUALIZAR_FICHERO: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_PETICION_ACLARAR_VISUALIZAR_FICHERO;

  public readonly ENDPOINT_API_PETICION_ACUALIZAR_DESTINATARIO: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_PETICION_ACTUALIZAR_DESTINATARIO;

  public readonly API_ENDPOINT_USUARIOS_TECNICOS: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_USUARIOS +
    '/buscar-tecnicos';

  public readonly ENDPOINT_API_PETICION_OBTENER_AUTORIZANTES: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_PETICION_OBTENER_AUTORIZANTES;

  public readonly ENDPOINT_API_PETICION_OBTENER_INFORMANTES: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_PETICION_OBTENER_INFORMANTES;

  public readonly ENDPOINT_API_PETICION_SOLICITAR_AUTORIZACION: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_PETICION_SOLICITAR_AUTORIZACION;

  public readonly ENDPOINT_API_PETICION_INFORMACIONES: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_PETICION_INFORMACIONES;

  public readonly ENDPOINT_API_PETICION_INFORMACIONES_PETICIONES: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_PETICION_INFORMACIONES_PETICIONES;

  public readonly ENDPOINT_API_PETICION_INFORMAR: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_PETICION_INFORMAR;

  public readonly ENDPOINT_API_PETICION_AUTORIZAR: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_PETICION_AUTORIZAR;

  public readonly ENDPOINT_API_PETICION_ARTICA: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_PETICION_ARTICA;

  public readonly ENDPOINT_API_PETICION_ARTICA_ENLACE: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_PETICION_ENLACE_ARTICA;

  public readonly ENDPOINT_API_ACCIONES_COMBO: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_ACCIONES_COMBO;

  public readonly ENDPOINT_API_ACCIONES_ROLES: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_ACCIONES_ROLES;

  public readonly ENDPOINT_API_ACCIONES_REABRIR: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_ACCIONES_REABRIR;

  public readonly ENDPOINT_API_NOTIFICACIONES: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_NOTIFICACIONES;

  public readonly ENDPOINT_API_PLANTILLAS: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_PLANTILLAS;

  public readonly ENDPOINT_API_INFORMESDATASTUDIO: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_INFORMESDATASTUDIO;

  public readonly ENDPOINT_API_SGC_HISTORICO_CONTRATO: string =
    environment.PROTOCOLO +
    environment.VERSION_PROJECT +
    environment.PROJECT_ID +
    this.API_ENDPOINT_SGC_SECUENCIALES;
}

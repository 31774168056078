import { Component, ViewChild } from '@angular/core';
import { SubjectsService } from './servicios/subjects/subjects.service';
import { ModalError } from './modelos/modal-error';
import { Constantes } from './utils/constantes';
import { LoginService } from './servicios/login/login.service';
import { MensajeComponent } from './componentes/mensaje/mensaje.component';
import { SesionService } from './servicios/sesion/sesion.service';
import { environment } from 'src/environments/environment';
import { PrimeNGConfig } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

declare let $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = environment.TITULO_MENU;

  public modalError: ModalError;

  @ViewChild('mensaje', { static: true })
  public mensaje!: MensajeComponent;

  constructor(
    private subjectsService: SubjectsService,
    public constantes: Constantes,
    private loginService: LoginService,
    private sesionService: SesionService,
    private config: PrimeNGConfig,
    private translateService: TranslateService
  ) {
    this.modalError = {
      title: '',
      mensaje: '',
      inicio: false,
      code: 1,
    };
  }

  translate(lang: string): void {
    this.translateService.use(lang);
    this.translateService
      .get('primeng')
      .subscribe((res: any) => this.config.setTranslation(res));
  }

  // tslint:disable-next-line:use-lifecycle-interface
  ngOnInit(): any {
    this.sesionService.setSesionCaducada(false);
    this.suscribirseASesionCaducada();
    this.subcribirseErrorresGenerales();
    this.translate('es');

    const usuario = this.sesionService.getUser();

    if (usuario !== null && usuario !== undefined) {
      this.loginService.getUserInfo();
    }
  }

  public suscribirseASesionCaducada(): void {
    this.subjectsService.getSesionCaducadaSubject().subscribe((error: any) => {
      if (error.status === 403) {
        this.sesionService.setSesionCaducada(true);
        this.handleSesionCaducadaError('Sesión', error.error);
      }
    });
  }

  public subcribirseErrorresGenerales(): void {
    this.subjectsService.getEmitirError().subscribe((error: any) => {
      $('#modalsession').modal('hide');
      this.mensaje.showError(error, '');
    });
  }

  public handleSesionCaducadaError(title: string, message: any): void {
    this.modalError = {
      title,
      mensaje: message,
      inicio: true,
      code: 1,
    };
    $('#modalsession').modal({ backdrop: 'static', keyboard: false });
  }

  public cerrarModal(): void {
    $('#modalsession').modal('toggle');
  }

  public cerrar(): void {
    localStorage.clear();
    let lastURL = document.location.hash;
    localStorage.setItem('lastURL', lastURL.slice(1));
    this.loginService.signOutLS(true);
    this.cerrarModal();
    window.location.reload();
  }
}

import { NgModule, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
registerLocaleData(localeEs);
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {
  HTTP_INTERCEPTORS,
  HttpClientModule,
  HttpClient,
} from '@angular/common/http';
import { Constantes } from './utils/constantes';
import { Interceptors } from './interceptors/interceptors';
import { environment } from '../environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// PrimeNG
import { CheckboxModule } from 'primeng/checkbox';
import { TableModule, EditableRow } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { MultiSelectModule } from 'primeng/multiselect';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { DropdownModule } from 'primeng/dropdown';
import { ToastModule } from 'primeng/toast';
import {
  ConfirmationService,
  MessageService,
  PrimeNGConfig,
} from 'primeng/api';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { TreeTableModule } from 'primeng/treetable';
import { DataViewModule } from 'primeng/dataview';
import { PanelModule } from 'primeng/panel';
import { InputMaskModule } from 'primeng/inputmask';
import { TabViewModule } from 'primeng/tabview';
import { RadioButtonModule } from 'primeng/radiobutton';
import { CalendarModule } from 'primeng/calendar';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { InputSwitchModule } from 'primeng/inputswitch';
import { TreeModule } from 'primeng/tree';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { FileUploadModule } from 'primeng/fileupload';
import { MatChipsModule } from '@angular/material/chips';
import { TabMenuModule } from 'primeng/tabmenu';
import { MatDialogModule } from '@angular/material/dialog';
import { StepsModule } from 'primeng/steps';
import { AccordionModule } from 'primeng/accordion';
import { BlockUIModule } from 'primeng/blockui';
import {
  DateFormatPipe,
  DateFormatPipeYear,
  StringFormatPipeName,
  HoursToDaysPipe,
} from './utils/date-pipe';
import { SelectButtonModule } from 'primeng/selectbutton';
import { ChipsModule } from 'primeng/chips';
import { AutoCompleteModule } from 'primeng/autocomplete';

import { MensajeComponent } from './componentes/mensaje/mensaje.component';
import { LoginService } from './servicios/login/login.service';
import { HandlerErrorService } from './servicios/errores/handler-error.service';
import { NgxSpinnerModule } from 'ngx-spinner';
import { CabeceraComponent } from './componentes/cabecera/cabecera.component';
import { MenuComponent } from './componentes/menu/menu.component';
import { CrearusuarioComponent } from './componentes/usuarios/crearusuario/crearusuario.component';
import { ModalInfoComponent } from './componentes/modals/modal-info/modal-info.component';
import { TablaUsuariosComponent } from './componentes/usuarios/tabla-usuarios/tabla-usuarios.component';
import { InputTrimDirective } from './directivas/input-trim/input-trim.directive';
import { FiltroUsuarioComponent } from './componentes/filtros/filtro-usuario/filtro-usuario.component';
import { UsuariosPrincipalComponent } from './componentes/usuarios/usuarios-principal/usuarios-principal.component';
import { UsuariosAuditoriaComponent } from './componentes/usuarios/usuarios-auditoria/usuarios-auditoria.component';
import { GrupossoporteService } from './servicios/grupossoporte/grupossoporte.service';
import { ApiSgcService } from './servicios/api-sgc/api-sgc.service';
import { Paginacion } from './utils/paginacion';
import { CreargruposoporteComponent } from './componentes/grupossoporte/creargruposoporte/creargruposoporte.component';
import { TooltipModule } from 'primeng/tooltip';
import { TablaGrupossoporteComponent } from './componentes/grupossoporte/tabla-grupossoporte/tabla-grupossoporte.component';
import { CrearPeticionComponent } from './componentes/peticiones/crear-peticion/crear-peticion.component';
import { CrearPeticionIdentificacionComponent } from './componentes/peticiones/crear-peticion/crear-peticion-identificacion/crear-peticion-identificacion.component';
import { CrearPeticionAdjuntarFicherosComponent } from './componentes/peticiones/crear-peticion/crear-peticion-adjuntar-ficheros/crear-peticion-adjuntar-ficheros.component';
import { CrearPeticionObservacionesComponent } from './componentes/peticiones/crear-peticion/crear-peticion-observaciones/crear-peticion-observaciones.component';
import { CrearPeticionDatosEspecificosComponent } from './componentes/peticiones/crear-peticion/crear-peticion-datos-especificos/crear-peticion-datos-especificos.component';
import { CrearPeticionNotificacionesComponent } from './componentes/peticiones/crear-peticion/crear-peticion-notificaciones/crear-peticion-notificaciones.component';
import { CrearPeticionConfirmacionComponent } from './componentes/peticiones/crear-peticion/crear-peticion-confirmacion/crear-peticion-confirmacion.component';
import { RolesComponent } from './componentes/roles/roles.component';
import { EmpresasComponent } from './componentes/empresas/empresas.component';
import { PrincipalComponent } from './componentes/principal/principal.component';
import { PeticionesPrincipalComponent } from './componentes/peticiones/peticiones-principal/peticiones-principal.component';
import { TablaPeticionesComponent } from './componentes/peticiones/tabla-peticiones/tabla-peticiones.component';
import { FiltroPeticionComponent } from './componentes/filtros/filtro-peticion/filtro-peticion.component';
import { UtilsForms } from './utils/utils-forms';
import { AccionPeticionConstantes } from './utils/acciones-peticion-constantes';
import { TablaPeticionesActuacionesComponent } from './componentes/peticion-actuacion/tabla-peticiones-actuaciones/tabla-peticiones-actuaciones.component';
import { FiltroPeticionActuacionComponent } from './componentes/filtros/filtro-peticion-actuacion/filtro-peticion-actuacion.component';
import { PeticionActuacionPrincipalComponent } from './componentes/peticion-actuacion/peticion-actuacion-principal/peticion-actuacion-principal.component';
import { DetallePeticionComponent } from './componentes/peticiones/detalle-peticion/detalle-peticion.component';
import { LandpagePeticionComponent } from './componentes/peticiones/landpage-peticion/landpage-peticion.component';
import { ModalNotificarComponent } from './componentes/modals/modal-notificar/modal-notificar.component';
import { AclaracionesComponent } from './componentes/peticiones/aclaraciones/aclaraciones.component';
import { ListaAclaracionesComponent } from './componentes/peticiones/lista-aclaraciones/lista-aclaraciones.component';
import { CierrePeticionComponent } from './componentes/peticiones/cierre-peticion/cierre-peticion.component';
import { ReabrirPeticionComponent } from './componentes/peticiones/reabrir-peticion/reabrir-peticion.component';
import { ModalAutorizarComponent } from './componentes/modals/modal-autorizar/modal-autorizar.component';
import { AutorizacionesComponent } from './componentes/peticiones/autorizaciones/autorizaciones.component';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ModalArticaComponent } from './componentes/modals/modal-artica/modal-artica.component';
import { FiltroNotificacionComponent } from './componentes/filtros/filtro-notificacion/filtro-notificacion.component';
import { TablaNotificacionesComponent } from './componentes/notificaciones/tabla-notificacciones/tabla-notificaciones.component';
import { NotificacionesPrincipalComponent } from './componentes/notificaciones/notificaciones-principal/notificaciones-principal.component';
import { EstadosComponent } from './componentes/estados/estados.component';
import { CrearNotificacionComponent } from './componentes/notificaciones/crear-notificacion/crear-notificacion.component';
import { TablaCatalogosComponent } from './componentes/catalogos/tabla-catalogos/tabla-catalogos.component';
import { ReplaceNullWithTextPipe } from './pipes/replace-null-with-text.pipe';
import { CrearCatalogoComponent } from './componentes/catalogos/crear-catalogo/crear-catalogo.component';
import { OnlynumberDirective } from './directivas/only-numbers.directive';
import { AccionesComponent } from './componentes/acciones/acciones.component';
import { PuestosComponent } from './componentes/puestos/puestos.component';
import { InformacionesComponent } from './componentes/peticiones/informaciones/informaciones.component';
import { ConveniosPrincipalComponent } from './componentes/convenios/convenios-principal/convenios-principal.component';
import { AutomatismosComponent } from './componentes/catalogos/automatismos/automatismos.component';
import { CrearCatalogoBaseComponent } from './componentes/catalogos/crear-catalogo-base/crear-catalogo-base.component';
import { InformesComponent } from './componentes/informes/informes.component';
import { FiltroConveniosComponent } from './componentes/filtros/filtro-convenios/filtro-convenios.component';
import { ConveniosComponent } from './componentes/convenios/tabla-convenios/convenios.component';
import { FiltroCatalogoComponent } from './componentes/filtros/filtro-catalogo/filtro-catalogo.component';
import { RouterUtils } from './utils/RouterUtils';
import { CrearConvenioComponent } from './componentes/convenios/crearconvenio/crearconvenio.component';
import { TablaAuditoriaComponent } from './componentes/auditoria/tabla-auditoria/tabla-auditoria.component';
import { AuditoriaPrincipalComponent } from './componentes/auditoria/auditoria-principal/auditoria-principal.component';
import { FiltroAuditoriaComponent } from './componentes/filtros/filtro-auditoria/filtro-auditoria.component';
import { SlaPrincipalComponent } from './componentes/sla/sla-principal/sla-principal.component';
import { TablaSlaComponent } from './componentes/sla/tabla-sla/tabla-sla.component';
import { TablaPrioridadesComponent } from './componentes/sla/tabla-prioridades/tabla-prioridades.component';
import { AltasComponent } from './componentes/nuevo-usuario/altas.component';
import { CatalogoDatosEspecificosComponent } from './componentes/catalogos/catalogo-datos-especificos/catalogo-datos-especificos.component';
import { InformacionEmpleadoComponent } from './componentes/informacion-empleado/informacion-empleado.component';

@NgModule({
  declarations: [
    AppComponent,
    MensajeComponent,
    CabeceraComponent,
    MenuComponent,
    CrearusuarioComponent,
    ModalInfoComponent,
    TablaUsuariosComponent,
    InputTrimDirective,
    FiltroUsuarioComponent,
    UsuariosPrincipalComponent,
    UsuariosAuditoriaComponent,
    DateFormatPipe,
    DateFormatPipeYear,
    HoursToDaysPipe,
    StringFormatPipeName,
    TablaGrupossoporteComponent,
    CreargruposoporteComponent,
    EmpresasComponent,
    CrearPeticionComponent,
    CrearPeticionIdentificacionComponent,
    CrearPeticionAdjuntarFicherosComponent,
    CrearPeticionObservacionesComponent,
    CrearPeticionDatosEspecificosComponent,
    CrearPeticionNotificacionesComponent,
    CrearPeticionConfirmacionComponent,
    RolesComponent,
    PrincipalComponent,
    PeticionesPrincipalComponent,
    TablaPeticionesComponent,
    FiltroPeticionComponent,
    TablaPeticionesActuacionesComponent,
    FiltroPeticionActuacionComponent,
    PeticionActuacionPrincipalComponent,
    DetallePeticionComponent,
    LandpagePeticionComponent,
    ModalNotificarComponent,
    AclaracionesComponent,
    ListaAclaracionesComponent,
    CierrePeticionComponent,
    ReabrirPeticionComponent,
    ModalAutorizarComponent,
    AutorizacionesComponent,
    ModalArticaComponent,
    TablaNotificacionesComponent,
    NotificacionesPrincipalComponent,
    FiltroNotificacionComponent,
    EstadosComponent,
    CrearNotificacionComponent,
    CrearNotificacionComponent,
    TablaCatalogosComponent,
    ReplaceNullWithTextPipe,
    CrearCatalogoComponent,
    OnlynumberDirective,
    AccionesComponent,
    InformacionesComponent,
    AutomatismosComponent,
    CrearCatalogoBaseComponent,
    PuestosComponent,
    InformacionesComponent,
    ConveniosComponent,
    ConveniosPrincipalComponent,
    InformesComponent,
    FiltroConveniosComponent,
    FiltroCatalogoComponent,
    CrearConvenioComponent,
    TablaAuditoriaComponent,
    AuditoriaPrincipalComponent,
    FiltroAuditoriaComponent,
    SlaPrincipalComponent,
    TablaSlaComponent,
    TablaPrioridadesComponent,
    AltasComponent,
    CatalogoDatosEspecificosComponent,
    InformacionEmpleadoComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    InputMaskModule,
    TableModule,
    CheckboxModule,
    ButtonModule,
    DialogModule,
    InputTextModule,
    InputTextareaModule,
    ToastModule,
    ScrollPanelModule,
    MessagesModule,
    MessageModule,
    ProgressSpinnerModule,
    DropdownModule,
    ConfirmDialogModule,
    TreeTableModule,
    DataViewModule,
    PanelModule,
    TabViewModule,
    RadioButtonModule,
    CalendarModule,
    InputSwitchModule,
    TreeModule,
    BreadcrumbModule,
    FileUploadModule,
    MatChipsModule,
    TabMenuModule,
    TooltipModule,
    SelectButtonModule,
    BlockUIModule,
    NgxSpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    MultiSelectModule,
    MatDialogModule,
    StepsModule,
    AccordionModule,
    ChipsModule,
    AutoCompleteModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    LoginService,
    HandlerErrorService,
    TranslateService,
    TableModule,
    CheckboxModule,
    EditableRow,
    Constantes,
    MessageService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Interceptors,
      multi: true,
    },
    { provide: LOCALE_ID, useValue: 'es-ES' },
    ConfirmationService,
    GrupossoporteService,
    Paginacion,
    UtilsForms,
    AccionPeticionConstantes,
    RouterUtils,
    HttpClient,
    ApiSgcService,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

<ngx-spinner></ngx-spinner>

<div class="container espacio">
    <div class="d-flex justify-content-between mb-3">
      <h1 class="mt-4">Informacion del Empleado - DNI : {{ dniTrabjadorAfectado }}</h1>
    </div>
    <div class="card mb-3">
      <div class="borde">
          <!-- Tabla de contratos historicos  -->
          <p-table id="tabla-catalogo" #dt1 [value]="historicosContratos" dataKey="email" [showCurrentPageReport]="true" styleClass="p-datatable-responsive-demo" [rows]="rows" [totalRecords]="resultadosTotales" [columns]="cols" [lazy]="true" (onLazyLoad)="nextPage($event, dt1)"
          [paginator]="true" [globalFilterFields]="cols" sortField="tipoPeticion" [sortOrder]="1" currentPageReportTemplate="Página {{paginaActual}} de {{paginasTotales}}" [rowsPerPageOptions]="registrosPorPagina" [(first)]="first">
              <ng-template pTemplate="caption" class="caption">
                <button class="btn excelButton" (click)="exportarDatos()"><i class="fa fa-file-excel-o" aria-hidden="true"></i> EXPORTAR</button>
              </ng-template>
              <ng-template pTemplate="header">
                  <tr>
                      <ng-container *ngFor="let col of cols">
                          <ng-container *ngIf="col.sortable; else noSortable">
                              <th *ngIf="col.visible" scope="{{col.field}}" pSortableColumn="{{col.field}}" [style]="col.style">
                                  <div class="p-d-flex p-jc-between p-ai-center">
                                      {{col.header}}
                                      <p-sortIcon *ngIf="col.sortable" ariaLabel="Activar para ordenar" ariaLabelDesc="Activar para ordenar en orden descendiente" ariaLabelAsc="Activar para ordenar en orden ascendiente" [field]="col.field"></p-sortIcon>
                                  </div>
                              </th>
                          </ng-container>
                          <ng-template #noSortable>
                              <th *ngIf="col.visible" scope="{{col.field}}" [style]="col.style">
                                  <div class="p-d-flex p-jc-between p-ai-center">
                                      {{col.header}}
                                  </div>
                              </th>
                          </ng-template>
                      </ng-container>
                  </tr>
              </ng-template>
              <ng-template pTemplate="body" let-historico let-i="rowIndex">
                  <tr>
                    <td><span class="p-column-title">Empresa</span>{{historico.empresa}} </td>
                    <td><span class="p-column-title">Provincia</span> {{historico.provincia}}</td>
                    <td><span class="p-column-title">Dni</span> {{historico.dni}}</td>
                    <td><span class="p-column-title">Secuencial</span> {{historico.secuencial}}</td>
                    <td><span class="p-column-title">Nombre</span> {{historico.nombre}}</td>
                    <td><span class="p-column-title">NAF</span> {{historico.naf}}</td>
                    <td><span class="p-column-title">Fecha Alta</span> {{historico.fechaAlta}}</td>
                    <td><span class="p-column-title">Fecha Baja</span> {{historico.fechaBaja}}</td>
                    <td><span class="p-column-title">MTBJ</span> {{historico.mtbj}}</td>
                    <td><span class="p-column-title">CTO</span> {{historico.cto}}</td>
                  </tr>
                  <hr class="item-list-separator">
              </ng-template>

              <ng-template pTemplate="emptymessage">
                  <tr>
                      <td colspan="7">No hay resultados</td>
                  </tr>
              </ng-template>
              <ng-template pTemplate="paginatorleft">
              </ng-template>
              <ng-template pTemplate="paginatorright">
                  <p class="pageFooterLeft">Resultados totales: {{resultadosTotales}}</p>
              </ng-template>
          </p-table>
      </div>
    </div>

    <div class="botones espacio mb-5 mt-2">
      <button
        id="anterior"
        type="submit"
        class="btn customButton"
        (click)="volver()"
      >
        Anterior
      </button>
    </div>
</div>

<p-table
  id="tabla-auditoria-general"
  #dt2
  [value]="auditorias"
  [lazy]="true"
  [showCurrentPageReport]="true"
  styleClass="p-datatable-responsive-demo"
  [paginator]="true"
  [rows]="rows"
  [totalRecords]="auditoriasTotales"
  [columns]="cols"
  [globalFilterFields]="cols"
  sortField="fechaHora"
  [sortOrder]="1"
  currentPageReportTemplate="Página {{ paginaActual }} de {{
    paginasTotales === 0 ? 1 : paginasTotales
  }}"
  [rowsPerPageOptions]="registrosPorPagina"
  (onLazyLoad)="nextPage($event, dt2)"
  [(first)]="first"
>
  <ng-template pTemplate="caption" class="caption">
    <button class="btn boton-excel" (click)="exportarDatos()">
      <i class="fa fa-file-excel-o" aria-hidden="true"></i> EXPORTAR
    </button></ng-template
  >
  <ng-template pTemplate="header">
    <tr>
      <th
        *ngFor="let col of cols"
        scope="{{ col.field }}"
        pSortableColumn="{{ col.field }}"
        pResizableColumn
      >
        <div
          class="p-d-flex p-jc-between p-ai-center"
          *ngIf="col.field !== 'observaciones'"
        >
          {{ col.header }}
          <p-sortIcon
            ariaLabel="Activar para ordenar"
            ariaLabelDesc="Activar para ordenar en orden descendiente"
            ariaLabelAsc="Activar para ordenar en orden ascendiente"
            [field]="col.field"
          ></p-sortIcon>
        </div>
        <div
          class="p-d-flex p-jc-between p-ai-center"
          *ngIf="col.field === 'observaciones'"
        >
          {{ col.header }}
        </div>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-auditoria let-i="rowIndex">
    <tr>
      <td>
        <span class="p-column-title">PETICIÓN</span>{{ auditoria.idPeticion }}
      </td>
      <td>
        <span class="p-column-title">USUARIO</span>{{ auditoria.emailActor }}
      </td>
      <td>
        <span class="p-column-title">FECHA</span
        >{{ auditoria.fechaHora | dateFormatPipe }}
      </td>
      <td><span class="p-column-title">ACCIÓN</span>{{ auditoria.accion }}</td>
      <td class="text-center">
        <span class="p-column-title"></span>
        <button
          *ngIf="auditoria.observaciones && auditoria.observaciones != ''"
          pTooltip="Detalles"
          tooltipPosition="right"
          class="btn btn-sm btn-outline-info"
          (click)="mostrarObservaciones(auditoria.observaciones)"
        >
          <em class="fa fa-eye"></em>
        </button>
      </td>
    </tr>
    <hr class="item-list-separator" />
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="7">No hay registros</td>
    </tr>
  </ng-template>
  <ng-template pTemplate="paginatorleft"> </ng-template>
  <ng-template pTemplate="paginatorright">
    <p class="pageFooterLeft">Registros totales: {{ auditoriasTotales }}</p>
  </ng-template>
</p-table>

<ngx-spinner></ngx-spinner>

<div class="container espacio">
    <div class="d-flex justify-content-between mb-3">
        <h1>Catálogo de peticiones</h1>
    </div>
    <div class="card">
      <app-filtro-catalogo (buscar)="loadData($event)"></app-filtro-catalogo>
    </div>
    <div class="card mb-3">
        <div class="borde">
            <!-- Tabla de catalogos  -->
            <div class="d-flex">
                <button class="btn customButton p-2 mt-2 mr-2 mb-2 ml-2" [ngClass]="{'active': visualSeleccionada===1}" (click)="activarVista(1)">Vista Básica</button>
                <button class="btn customButton p-2 mt-2 mb-2" [ngClass]="{'active': visualSeleccionada===2}" (click)="activarVista(2)">Vista Avanzada</button>
                <button class="btn btn-outline-primary customButton fa fa-plus-circle ml-auto p-2 mt-2 mb-2" (click)="crearCatalogo()">  Crear Catálogo</button>
            </div>
            <p-table id="tabla-catalogo" #dt1 [value]="catalogos" dataKey="email" [showCurrentPageReport]="true" styleClass="p-datatable-responsive-demo" [rows]="rows" [totalRecords]="resultadosTotales" [columns]="cols" [lazy]="true" (onLazyLoad)="nextPage($event, dt1)"
                [paginator]="true" [globalFilterFields]="cols" sortField="tipoPeticion" [sortOrder]="1" currentPageReportTemplate="Página {{paginaActual}} de {{paginasTotales}}" [rowsPerPageOptions]="registrosPorPagina" [(first)]="first">

                <ng-template pTemplate="caption" class="caption">
                    <button class="btn boton-excel" (click)="exportarDatos()"><i class="fa fa-file-excel-o" aria-hidden="true"></i> EXPORTAR</button>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <ng-container *ngFor="let col of cols">
                            <ng-container *ngIf="col.sortable; else noSortable">
                                <th *ngIf="col.visible" scope="{{col.field}}" pSortableColumn="{{col.field}}" [style]="col.style">
                                    <div class="p-d-flex p-jc-between p-ai-center">
                                        {{col.header}}
                                        <p-sortIcon *ngIf="col.sortable" ariaLabel="Activar para ordenar" ariaLabelDesc="Activar para ordenar en orden descendiente" ariaLabelAsc="Activar para ordenar en orden ascendiente" [field]="col.field"></p-sortIcon>
                                    </div>
                                </th>
                            </ng-container>
                            <ng-template #noSortable>
                                <th *ngIf="col.visible" scope="{{col.field}}" [style]="col.style">
                                    <div class="p-d-flex p-jc-between p-ai-center">
                                        {{col.header}}
                                    </div>
                                </th>
                            </ng-template>
                        </ng-container>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-catalogo let-i="rowIndex">
                    <tr>
                        <td><span class="p-column-title">ID</span>{{catalogo.id}} </td>
                        <td><span class="p-column-title">TIPO PETICIÓN</span> {{catalogo.descripcion}}</td>
                        <ng-container *ngIf="+visualSeleccionada === 1">
                            <td><span class="p-column-title">ÁREA DESTINO</span> {{catalogo.areaDestino}}</td>
                            <td><span class="p-column-title">TIPO FLUJO</span> {{catalogo.tipoFlujo}}</td>
                            <td><span class="p-column-title">ESFUERZO</span> {{catalogo.tiempoPromedio}}</td>
                            <td class="simbolos"><span class="p-column-title">ÁRTICA</span>
                                <i [ngClass]="catalogo.subirArtica === 1 ? 'fa fa-check' : 'fa fa-times'" aria-hidden="true"></i>
                            </td>
                            <td class="simbolos"><span class="p-column-title">ACTIVO</span>
                                <i [ngClass]="catalogo.activo === 1 ? 'fa fa-check' : 'fa fa-times'" aria-hidden="true"></i>
                            </td>
                        </ng-container>
                        <ng-container *ngIf="+visualSeleccionada === 2">
                            <td class="simbolos"><span class="p-column-title">DATOS ESPECÍFICOS</span>
                                <i class="simbolos" [ngClass]="catalogo.datosEspecificos === 1 ? 'fa fa-check' : 'fa fa-times'" aria-hidden="true"></i>
                            </td>
                            <td class="simbolos"><span class="p-column-title">DATOS EFECTIVIDAD</span>
                                <i class="simbolos" [ngClass]="catalogo.datosEfectividad === 1 ? 'fa fa-check' : 'fa fa-times'" aria-hidden="true"></i>
                            </td>
                            <td><span class="p-column-title">IMPACTO</span> {{catalogo.impacto}}</td>
                            <td class="simbolos"><span class="p-column-title">FECHA RESOLUCIÓN</span>
                                <i class="simbolos" [ngClass]="catalogo.fechaResolucion === 1 ? 'fa fa-check' : 'fa fa-times'" aria-hidden="true"></i>
                            </td>
                            <td *ngIf="catalogo.calcularSLA !== null" class="simbolos"><span class="p-column-title">SLA</span>
                                <i class="simbolos" [ngClass]="catalogo.calcularSLA === 1 ? 'fa fa-check' : 'fa fa-times'" aria-hidden="true"></i>
                            </td>
                            <td *ngIf="catalogo.calcularSLA === null" class="simbolos"><span class="p-column-title">SLA</span> N/A
                            </td>
                            <td *ngIf="catalogo.escalado !== null" class="simbolos"><span class="p-column-title">ESCALADO</span>
                                <i class="simbolos" [ngClass]="catalogo.escalado === 1 ? 'fa fa-check' : 'fa fa-times'" aria-hidden="true"></i>
                            </td>
                            <td *ngIf="catalogo.escalado === null" class="simbolos"><span class="p-column-title">ESCALADO</span> N/A
                            </td>
                        </ng-container>
                        <td class="acciones text-center">
                            <button pTooltip="Editar Peticion" tooltipPosition="right" class="btn btn-sm btn-outline-info" (click)="editarCatalogo(catalogo.id)"><em class="fa fa-pencil"></em></button>
                            <button *ngIf="catalogo.datosEspecificos === 1" pTooltip="Editar Datos Especificos" tooltipPosition="right" class="btn btn-sm btn-outline-info" (click)="editarDatosEspecificos(catalogo.id)"><em class="fa fa-paint-brush" aria-hidden="true"></em></button>
                        </td>
                    </tr>
                    <hr class="item-list-separator">
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="7">No hay resultados</td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="paginatorleft">
                </ng-template>
                <ng-template pTemplate="paginatorright">
                    <p class="pageFooterLeft">Resultados totales: {{resultadosTotales}}</p>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>

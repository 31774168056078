import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { forkJoin } from 'rxjs';
import { ButtonModalInfo, ModalInfo } from 'src/app/modelos/moda-info';
import { Peticion } from 'src/app/modelos/peticion';
import { Usuario } from 'src/app/modelos/usuario';
import { PeticionService } from 'src/app/servicios/peticion/peticion.service';
import { SesionService } from 'src/app/servicios/sesion/sesion.service';
import { Constantes } from 'src/app/utils/constantes';
import { MensajeComponent } from '../../mensaje/mensaje.component';
import { ModalInfoComponent } from '../../modals/modal-info/modal-info.component';
import { ModalNotificarComponent } from '../../modals/modal-notificar/modal-notificar.component';

@Component({
  selector: 'app-reabrir-peticion',
  templateUrl: './reabrir-peticion.component.html',
  styleUrls: ['./reabrir-peticion.component.scss'],
})
export class ReabrirPeticionComponent implements OnInit {
  @ViewChild('modalnotificar', { static: false })
  public modalnotificar!: ModalNotificarComponent;

  @ViewChild('mensaje', { static: false })
  public mensaje!: MensajeComponent;

  public peticionService!: PeticionService;
  private sessionService!: SesionService;
  private spinner!: NgxSpinnerService;

  public peticion!: Peticion;
  public formulario!: FormGroup;
  public contador!: number;
  public permisoEscritura!: boolean;
  public motivoCierre!: string;
  public accionCierre!: number;
  public textoMotivo!: string;
  public estaInformada: boolean;

  idBorrador!: number;
  reabrirBorrador: any;
  borrador!: boolean;

  constructor(
    private route: ActivatedRoute,
    private sesionService: SesionService,
    private inject: Injector,
    private fb: FormBuilder,
    private constantes: Constantes,
    public dialog: MatDialog
  ) {
    this.initServiceProperties();
    this.estaInformada = false;
  }

  ngOnInit(): void {
    this.contador = 0;
    this.loadServices();
    this.initForm();
    this.comprobarInformada();
  }

  initServiceProperties(): void {
    this.peticionService = this.inject.get<PeticionService>(PeticionService);
    this.sessionService = this.inject.get<SesionService>(SesionService);
    this.spinner = this.inject.get<NgxSpinnerService>(NgxSpinnerService);
  }

  private loadServices(): void {
    this.spinner.show();
    const usuarioLogado = this.sessionService.getUser();
    const llamadas = [];
    this.peticion = this.peticionService.getPeticion();

    llamadas.push(
      this.peticionService.obtenerAclaraciones(this.peticion.idPeticion || '')
    );
    llamadas.push(
      this.peticionService.obtenerCierres(this.peticion.idPeticion || '')
    );
    forkJoin(llamadas).subscribe(
      (resp: any) => {
        this.reabrirBorrador = resp[1].find(
          (element: any) => element.borradorReapertura
        );
        if (this.reabrirBorrador) {
          this.mostrarReabrirBorrador();
        } else {
          this.idBorrador = 0;
        }
        this.permisoEscritura =
          usuarioLogado.email === this.peticion.usuarioDestinatario?.email ||
          usuarioLogado.email === this.peticion.usuarioPeticionario?.email ||
          usuarioLogado.rol?.id === 1 ||
          usuarioLogado.rol?.id === 2;
        this.spinner.hide();
      },
      (error) => {
        console.error(error);
        this.spinner.hide();
      }
    );
  }

  private initForm(): void {
    this.formulario = this.fb.group({
      msg: this.comprobarObligatorio(),
    });
  }

  public comprobarInformada(): boolean {
    if (
      this.peticion.catalogo?.tipoFlujo === 'Autorización' &&
      this.peticion.informantes?.filter((i) => i.fechaVistoBueno !== null)
        .length === 0
    ) {
      this.estaInformada = true;
    }
    return this.estaInformada;
  }

  public comprobarObligatorio(): any {
    if (this.accionCierre === this.constantes.ACCION_REABRIR) {
      return ['', [Validators.maxLength(3000)]];
    } else {
      return ['', [Validators.required, Validators.maxLength(3000)]];
    }
  }

  public onKeyUp(event: any): void {
    this.contador = event.target.value.length;
  }

  public onKeyPress(event: any): any {
    if (event.keyCode === 39 || event.keyCode === 96 || event.keyCode === 180) {
      return false;
    }
    return true;
  }

  public limpiar(): void {
    this.formulario.controls.msg.setValue('');
    this.contador = 0;
  }

  private crearAccionRequestReabrir(): any {
    return {
      notificados: null,
      usuario: { email: this.sesionService.getUser().email } as Usuario,
      peticion: { idPeticion: this.peticion.idPeticion } as Peticion,
      texto: this.formulario.get('msg')?.value,
      fecha: '',
      borrador: this.idBorrador,
    };
  }

  public cerrarPeticion(): void {
    this.modalnotificar.mostrarModalReabrir(
      this.peticion.idPeticion,
      33,
      'Reabrir Petición',
      this.formulario.get('msg')?.value,
      null,
      this.idBorrador,
      this.borrador
    );
  }

  public notificadores(idPeticion: string): void {
    console.log(idPeticion);
    this.mensaje.showMensaje('Comentario realizado correctamente.');
    this.ngOnInit();
  }

  public volver(): void {
    window.history.back();
  }

  public deshabilitarBoton(): boolean {
    if (!this.formulario.valid && this.motivoCierre === '13') {
      return true;
    }
    return false;
  }

  public controlForm(): void {
    if (this.formulario.get('msg')?.value === '') {
      this.contador = 0;
    } else {
      this.contador = this.formulario.get('msg')?.value.trim().length;
    }
  }

  public crearReabrirBorrador(): void {
    this.spinner.show();
    if (this.reabrirBorrador) {
      this.peticionService
        .editarReabrirBorrador(
          this.peticion.idPeticion,
          this.crearAccionRequestReabrir()
        )
        .subscribe((resp: any) => {
          this.reabrirBorrador = resp;
          this.loadServices();
          this.spinner.hide();
        });
    } else {
      this.peticionService
        .reabrirPeticionBorrador(
          this.peticion.idPeticion,
          this.crearAccionRequestReabrir()
        )
        .subscribe((resp: any) => {
          this.reabrirBorrador = resp;
          this.loadServices();
          this.spinner.hide();
        });
    }
    this.borrador = true;
  }

  public eliminarReabrirBorrador(): void {
    const data = {
      title: 'Eliminar borrador',
      msg: `¿Desea eliminar al borrador?`,
      buttons: [
        { title: 'Aceptar', class: 'btn-success', action: '1' },
        { title: 'Cancelar', class: 'btn-danger' },
      ] as ButtonModalInfo[],
    } as ModalInfo;
    this.dialog
      .open(ModalInfoComponent, { disableClose: true, autoFocus: false, data })
      .afterClosed()
      .subscribe((resp) => {
        if (resp) {
          this.spinner.show();
          this.peticionService
            .eliminarReabrirPeticionBorrador(
              this.peticion.idPeticion,
              this.sesionService.getUser().email
            )
            .subscribe(() => {
              this.mensaje.showMensaje(
                'Borrador eliminado satisfactoriamente.'
              );
              this.spinner.hide();
            });
          this.formulario.controls.msg.setValue('');
          this.reabrirBorrador = undefined;
          this.borrador = false;
        }
      });
  }

  public mostrarReabrirBorrador() {
    if (this.reabrirBorrador.borradorReapertura != undefined) {
      this.formulario.controls.msg.setValue(
        this.reabrirBorrador.observacionReapertura
      );
      this.borrador = true;
      this.idBorrador = this.reabrirBorrador.id;
    } else {
      this.formulario.controls.msg.setValue('');
      this.borrador = false;
    }
  }
}

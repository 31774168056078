<div class="container">
  <app-crear-peticion [activeIndex]="activeIndex"></app-crear-peticion>
  <app-mensaje #mensaje></app-mensaje>
  <form [formGroup]="formulario" *ngIf="formulario">
    <div *ngFor="let e of datosEspecificosLst" class="form-group row">
      <div *ngIf="e.tipo === 'SEPARADOR'">
        <p-table id="tabla-peticiones" styleClass="p-datatable-responsive-demo">
          <ng-template pTemplate="caption" class="caption">
            <th id="datos-descripcion">{{ e.descripcion }} <br /></th>
          </ng-template>
        </p-table>
        <small
          *ngIf="e.ayuda !== null"
          class="smal text-left form-text text-secondary"
          >{{ e.ayuda }}</small
        >
        <br />
      </div>

      <div
        *ngIf="e.tipo === 'LABEL'"
        class="row alert alert-warning mx-auto text-center justify-content-center align-items-center"
        role="alert"
        [style.display]="checkRuleVisibility(e) ? 'flex' : 'none'"
      >
        <span id="{{ e.idHtml }}">{{ e.descripcion }}</span>
      </div>

      <div
        *ngIf="e.tipo !== 'SEPARADOR' && e.tipo !== 'LABEL'"
        class="col-md-3 mb-3 col-form-label"
        [style.display]="checkRuleVisibility(e) ? 'flex' : 'none'"
      >
        <label for="e.descripcion"
          >{{ e.descripcion }}
          <span *ngIf="e.obligatorioDin" class="text-danger">*</span></label
        >
      </div>
      <div
        *ngIf="e.tipo === 'INPUT'"
        class="col-md-4 mb-3 col-form-label"
        [style.display]="checkRuleVisibility(e) ? 'flex' : 'none'"
      >
        <input
          (focus)="quitarEstiloOB(e.idHtml, 'textarea')"
          class="form-control"
          id="{{ e.idHtml }}"
          formControlName="{{ e.idHtml }}"
          [required]="e.obligatorioDin"
          trim="blur"
        />
        <small
          class="form-text text-danger"
          *ngIf="
            formulario.get(e.idHtml)?.value.length >
            e.comboValores[0].descripcion.substring(12)
          "
        >
          * El campo excede el tamaño maximo</small
        >
        <small
          class="form-text text-danger"
          *ngIf="formulario.get(e.idHtml)?.errors?.pattern"
          >* Formato de Documento incorrecto</small
        >
      </div>

      <div
        *ngIf="e.tipo === 'INPUT_NUMBER'"
        class="col-md-4 mb-3 col-form-label"
        [style.display]="checkRuleVisibility(e) ? 'flex' : 'none'"
      >
        <input
          type="number"
          id="{{ e.idHtml }}"
          name="{{ e.idHtml }}"
          formControlName="{{ e.idHtml }}"
          min="0"
          [required]="e.obligatorioDin"
          class="form-control"
        />
        <small
          class="form-text text-danger"
          *ngIf="formulario.get(e.idHtml)?.errors?.pattern"
          >* Numero incorrecto</small
        >
      </div>

      <div
        *ngIf="tipoCombosDatosEspecificosEspeciales.includes(e.tipo)"
        class="col-md-4 mb-3"
        [style.display]="checkRuleVisibility(e) ? 'flex' : 'none'"
      >
        <div class="form-control p-0">
          <p-dropdown
            id="{{ e.idHtml }}"
            formControlName="{{ e.idHtml }}"
            [showClear]="!e.obligatorioDin"
            placeholder="Seleccione"
            [options]="e.comboValores"
            optionLabel="idDescripcion"
            styleClass="form-control p-0"
          >
          </p-dropdown>
        </div>
      </div>

      <div
        *ngIf="e.tipo === 'COMBO'"
        class="col-md-4 mb-3"
        [style.display]="checkRuleVisibility(e) ? 'flex' : 'none'"
      >
        <div class="form-control p-0">
          <p-dropdown
            id="{{ e.idHtml }}"
            formControlName="{{ e.idHtml }}"
            [showClear]="!e.obligatorioDin"
            placeholder="Seleccione"
            [options]="e.comboValores"
            optionLabel="descripcion"
            styleClass="form-control p-0"
            [required]="e.obligatorioDin"
          >
          </p-dropdown>
        </div>
      </div>

      <div
        *ngIf="e.tipo === 'CALENDARIO'"
        class="col-md-4 mb-3"
        [style.display]="checkRuleVisibility(e) ? 'flex' : 'none'"
      >
        <p-calendar
          [yearNavigator]="true"
          yearRange="1900:2060"
          [firstDayOfWeek]="1"
          [required]="e.obligatorioDin"
          [showButtonBar]="true"
          [showIcon]="true"
          dateFormat="dd-mm-yy"
          id="{{ e.idHtml }}"
          formControlName="{{ e.idHtml }}"
        ></p-calendar>
      </div>

      <div
        *ngIf="e.tipo !== 'SEPARADOR' && e.tipo !== 'LABEL'"
        class="col-md-4 mb-3 col-form-label"
        [style.display]="checkRuleVisibility(e) ? 'flex' : 'none'"
      >
        <label id="e.ayuda" for="e.ayuda">{{ e.ayuda }}</label>
      </div>
    </div>

    <div *ngIf="datosEspecificosLst.length === 0">
      <h3 style="text-align: center">
        Esta petición no tienen datos específicos asociados
      </h3>
    </div>

    <div class="derecha botones">
      <button
        *ngIf="modificable"
        type="submit"
        [disabled]="blockButtons || blockButtonsDates ? true : null"
        class="btn btn-outline-info"
        (click)="guardarDatosEspecificos()"
      >
        Borrador
      </button>
      <button
        type="submit"
        class="btn customButton"
        [disabled]="blockButtons || blockButtonsDates ? true : null"
        (click)="nextPage()"
      >
        Siguiente
      </button>
    </div>
    <div class="botones">
      <button type="submit" class="btn customButton" (click)="previousPage()">
        Anterior
      </button>
    </div>
  </form>
</div>

<!-- Modal -->
<div
  class="modal fade"
  id="modalDatoEspecifico"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          {{ modalError.title }}
        </h5>
      </div>
      <div class="modal-body">
        {{ modalError.mensaje }}
      </div>
      <div class="modal-footer">
        <button
          *ngIf="cambioPeticionManual"
          type="button"
          class="btn btn-primary"
          (click)="redirectToPeticionManual()"
        >
          Cambiar a Peticion Manual
        </button>
        <button type="button" class="btn btn-primary" (click)="cerrarModal()">
          Cerrar
        </button>
      </div>
    </div>
  </div>
</div>

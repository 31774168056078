import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Catalogo } from 'src/app/modelos/catalogo';
import { Constantes } from 'src/app/utils/constantes';
import {
  CatalogoFiltros,
  CatalogoFiltrosRequest,
} from '../../modelos/catalogo-filtros';
import { PlantillaAutomatismo } from '../../modelos/plantilla-automatismo';
import {
  DatoEspecifico,
  DatoEspecificoCatalogo,
} from 'src/app/modelos/dato-especifico';
import { InputComboValor } from 'src/app/modelos/combo-valor';

@Injectable({
  providedIn: 'root',
})
export class CatalogosService {
  constructor(private http: HttpClient, private constantes: Constantes) {}

  private filtros!: CatalogoFiltros;

  public setFiltros(filtros: CatalogoFiltros): void {
    this.filtros = filtros;
  }

  public getFiltros(): CatalogoFiltros {
    return this.filtros;
  }

  getCatalogos(): Observable<any> {
    return this.http.get(this.constantes.ENDPOINT_API_CATALOGOS);
  }

  obtenerTiposPeticiones(
    area?: string,
    descriptionMode?: boolean
  ): Observable<any> {
    const url = this.constantes.ENDPOINT_API_CATALOGOS;
    const params = new HttpParams()
      .set('area', area || '')
      .append(
        'descriptionMode',
        descriptionMode === undefined ? '' : String(descriptionMode)
      );
    return this.http.get(url, { params });
  }

  getCatalogo(codTipoCatalogo: string): Observable<Catalogo> {
    const url = this.constantes.ENDPOINT_API_CATALOGO_COD_TIPO.replace(
      '{codTipoCatalogo}',
      codTipoCatalogo
    );
    return this.http.get<Catalogo>(url);
  }

  getCatalogosListPaged(
    offset: number,
    limit: number,
    filtros: CatalogoFiltrosRequest
  ): Observable<any> {
    const params = new HttpParams()
      .set('offset', String(offset))
      .append('limit', String(limit));
    return this.http.post(
      this.constantes.ENDPOINT_API_CATALOGOS_LIST,
      filtros,
      { params }
    );
  }

  exportar(filtros: CatalogoFiltrosRequest): Observable<any> {
    return this.http.post(
      this.constantes.ENDPOINT_API_EXPORTAR + '/exportar-catalogo',
      filtros
    );
  }

  crearCatalogo(catalogo: Catalogo): Observable<Catalogo> {
    return this.http.post<Catalogo>(
      this.constantes.ENDPOINT_API_CATALOGOS,
      catalogo
    );
  }

  modificarCatalogo(catalogo: Catalogo): Observable<Catalogo> {
    return this.http.put<Catalogo>(
      this.constantes.ENDPOINT_API_CATALOGOS,
      catalogo
    );
  }

  crearDatoEspecifico(
    datos: DatoEspecificoCatalogo,
    idPeticion: any
  ): Observable<DatoEspecifico[]> {
    return this.http.post<DatoEspecifico[]>(
      this.constantes.ENDPOINT_API_CREAR_DATOS_ESPECIFICOS.replace(
        '{idPeticion}',
        String(idPeticion)
      ),
      datos
    );
  }

  modificarDatosEspecificos(
    datos: DatoEspecificoCatalogo[]
  ): Observable<DatoEspecificoCatalogo[]> {
    return this.http.put<DatoEspecificoCatalogo[]>(
      this.constantes.ENDPOINT_API_MODIFICAR_DATOS,
      { datos }
    );
  }

  eliminarDatosEspecificos(id: number): Observable<any> {
    const url: string = this.constantes.ENDPOINT_API_ELIMINAR_DATOS.replace(
      '{id}',
      id.toString()
    );
    return this.http.delete<any>(url);
  }

  getCatalogoAutomatismos(
    codTipoCatalogo: string
  ): Observable<PlantillaAutomatismo[]> {
    const url =
      this.constantes.ENDPOINT_API_CATALOGO_COD_TIPO_AUTOMATISMOS.replace(
        '{codTipoCatalogo}',
        codTipoCatalogo
      );
    return this.http.get<PlantillaAutomatismo[]>(url);
  }

  actualizarPlantillas(
    codTipoCatalogo: string,
    body: any
  ): Observable<PlantillaAutomatismo[]> {
    const url =
      this.constantes.ENDPOINT_API_CATALOGO_COD_TIPO_AUTOMATISMOS.replace(
        '{codTipoCatalogo}',
        codTipoCatalogo
      );
    return this.http.put<PlantillaAutomatismo[]>(url, body);
  }

  getCombosValores(): Observable<InputComboValor[]> {
    return this.http.get<InputComboValor[]>(
      this.constantes.ENDPOINT_API_COMBOS_VALORES
    );
  }
}

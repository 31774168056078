import { Component, OnInit, ViewChild, Injector } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Catalogo } from '../../../modelos/catalogo';
import { CatalogosService } from '../../../servicios/catalogos/catalogos.service';
import { forkJoin } from 'rxjs';
import { Accion } from '../../../modelos/accion';
import { AccionesService } from '../../../servicios/acciones/acciones.service';
import { PlantillaCombo } from '../../../modelos/plantilla';
import { PlantillaService } from '../../../servicios/plantilla/plantilla.service';
import { GrupossoporteService } from '../../../servicios/grupossoporte/grupossoporte.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { GruposSoporteNotificados, ComboGrupoSoporteNotificado } from '../../../modelos/grupossoporte';
import { NotificacionesService } from '../../../servicios/notificaciones/notificaciones.service';
import { NotificacionResponse } from '../../../modelos/notificacion';
import { MensajeComponent } from '../../mensaje/mensaje.component';
import Utils from '../../../utils/utils';

@Component({
  selector: 'app-crear-notificacion',
  templateUrl: './crear-notificacion.component.html',
  styleUrls: ['./crear-notificacion.component.css']
})
export class CrearNotificacionComponent implements OnInit {

  title = '';
  idTipoPeticion = '';

  formulario!: FormGroup;

  listaTiposPeticiones: Catalogo[] = [];
  listaAcciones: Accion[] = [];
  listaPlantillas: PlantillaCombo[] = [];
  listaGrupos: GruposSoporteNotificados[] = [];

  notificacion!: NotificacionResponse;

  modoEdicion = false;


  @ViewChild('mensaje', { static: true })
  public mensaje!: MensajeComponent;

  private catalogosService!: CatalogosService;
  private accionesService!: AccionesService;
  private plantillaService!: PlantillaService;
  private gruposSoporteService!: GrupossoporteService;
  private spinner!: NgxSpinnerService;
  private notificacionesService!: NotificacionesService;


  constructor(private acRoute: ActivatedRoute, private fb: FormBuilder, private inject: Injector, private router: Router) {
    this.loadInitialProperties();
  }


  private loadInitialProperties(): void {
    this.catalogosService = this.inject.get<CatalogosService>(CatalogosService);
    this.accionesService = this.inject.get<AccionesService>(AccionesService);
    this.plantillaService = this.inject.get<PlantillaService>(PlantillaService);
    this.gruposSoporteService = this.inject.get<GrupossoporteService>(GrupossoporteService);
    this.notificacionesService = this.inject.get<NotificacionesService>(NotificacionesService);
    this.spinner = this.inject.get<NgxSpinnerService>(NgxSpinnerService);
  }

  ngOnInit(): void {
    this.title = 'Crear Notificación';
    const idTipoPeticion = this.acRoute.snapshot.paramMap.get('idTipoPeticion');
    const idAccion = this.acRoute.snapshot.paramMap.get('idAccion');
    if (idTipoPeticion && idAccion){
      this.title = 'Editar Notificación';
      this.modoEdicion = true;
    }
    this.initServices(idTipoPeticion, idAccion);
  }

  private initServices(idTipoPeticion: string | null, idAccion: string | null): void {
    const llamadas = [];
    llamadas.push(this.catalogosService.getCatalogos());
    llamadas.push(this.accionesService.getAccionesCombo());
    llamadas.push(this.plantillaService.obtenerPlantillas());
    llamadas.push(this.gruposSoporteService.getGruposSoporteNotificados());
    if (idTipoPeticion !== null && idAccion !== null) {
      llamadas.push(this.notificacionesService.obtenerNotificacion(idTipoPeticion, +idAccion));
    }


    this.spinner.show();
    forkJoin(llamadas).subscribe(resp => {
      this.listaTiposPeticiones = resp[0] as Catalogo[];
      this.listaAcciones = resp[1] as Accion[];
      this.listaPlantillas = resp[2] as PlantillaCombo[];
      this.listaGrupos = resp[3] as GruposSoporteNotificados[];
      if (this.modoEdicion){
        this.notificacion = resp[4] as NotificacionResponse;
        this.initFormLoaded();
      } else {
        this.initForm();
      }
    }, () => {
      this.spinner.hide();
    });
  }

  private initFormLoaded(): void {
    this.formulario = this.fb.group({
      tipoPeticion: [{value: this.notificacion.catalogo, disabled: true}, [Validators.required]],
      accion: [{value: this.notificacion.accion, disabled: true}, [Validators.required]],
      plantilla: [this.notificacion.plantilla, [Validators.required]],
      trabajador:  [Utils.numberToBoolean(this.notificacion.trabajador)],
      solicitante:  [Utils.numberToBoolean(this.notificacion.solicitante)],
      destinatario:  [Utils.numberToBoolean(this.notificacion.destinatario)],
      autorizador:   [Utils.numberToBoolean(this.notificacion.autorizador)],
      informantes:  [Utils.numberToBoolean(this.notificacion.informantes)],
      pantallaSolicitar:  [Utils.numberToBoolean(this.notificacion.pantallaSolicitar)]
    });

    for (const grupoNotificacion of this.listaGrupos){
      let perfilesNotificados = [] as ComboGrupoSoporteNotificado[];
      const grupoNotificado = this.notificacion.
      gruposSoportes.find(g => g.grupoSoporte.id === grupoNotificacion.grupoSoporte.id) as GruposSoporteNotificados;

      if (grupoNotificado) {
        perfilesNotificados = grupoNotificado.perfilesNotificados;
      }
      this.formulario.addControl('grupo-' + grupoNotificacion.grupoSoporte.id, new FormControl(perfilesNotificados));
    }
    this.spinner.hide();
  }


  private initForm(): void {
    this.formulario = this.fb.group({
      tipoPeticion: [null, [Validators.required]],
      accion: [null, [Validators.required]],
      plantilla: [null, [Validators.required]],
      trabajador:  [false],
      solicitante:  [false],
      destinatario:  [false],
      autorizador:  [false],
      informantes:  [false],
      pantallaSolicitar:  [false]
    });

    for (const grupoNotificacion of this.listaGrupos){
      this.formulario.addControl('grupo-' + grupoNotificacion.grupoSoporte.id, new FormControl([]));
    }
    this.spinner.hide();
  }

  redirect(): void{
    this.router.navigate(['/notificaciones']);
  }

  callEditar(): void{

      this.spinner.show();
      this.notificacionesService.editarNotificacion(this.createNotificacionResponse()).subscribe(() => {
        this.spinner.hide();
        this.redirect();
      },
      error => {
        this.mensaje.showErrorPeticion(error.error);
        this.spinner.hide();
        console.error(error);
      });

  }

  callCrear(): void{

      this.spinner.show();
      this.notificacionesService.crearNotificacion(this.createNotificacionResponse()).subscribe(() => {
        this.spinner.hide();
        this.redirect();
      },
      error => {
        console.error(error.error);
        this.spinner.hide();
        this.mensaje.showErrorPeticion(error.error);
      });

  }

  private createNotificacionResponse(): NotificacionResponse {
    const obj = {
      catalogo: this.formulario.get('tipoPeticion')?.value,
      accion: this.formulario.get('accion')?.value,
      plantilla: this.formulario.get('plantilla')?.value,
      autorizador: Utils.booleanToNumber(this.formulario.get('autorizador')?.value),
      destinatario: Utils.booleanToNumber(this.formulario.get('destinatario')?.value),
      informantes: Utils.booleanToNumber(this.formulario.get('informantes')?.value),
      pantallaSolicitar: Utils.booleanToNumber(this.formulario.get('pantallaSolicitar')?.value),
      solicitante: Utils.booleanToNumber(this.formulario.get('solicitante')?.value),
      trabajador: Utils.booleanToNumber(this.formulario.get('trabajador')?.value),
    } as NotificacionResponse;

    const gruposSoportes = [] as GruposSoporteNotificados[];
    for (const key of Object.keys(this.formulario.controls)){
      if (key.includes('grupo-') && this.formulario.get(key)?.value.length !== 0){
        const idGrupo = +key.split('-')[1];
        const grupoNotificicado = this.listaGrupos.find(g => g.grupoSoporte.id === idGrupo);
        if (grupoNotificicado) {
          gruposSoportes.push({grupoSoporte: grupoNotificicado.grupoSoporte, perfilesNotificados: this.formulario.get(key)?.value});
        }
       }
    }
    return {...obj, gruposSoportes};
  }




}

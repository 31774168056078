<app-mensaje #mensaje> </app-mensaje>
<ng-container *ngIf="peticion">
  <div id="peticion" class="espacio">
    <div class="row">
      <div class="col-sm-12 col-lg-6">
        <span class="etiqueta font-weight-bold" style="font-size: 1.1rem"
          >Petición Nº: </span
        ><span class="texto" id="idPeticion">{{ peticion.idPeticion }}</span>
      </div>
    </div>
    <hr />
  </div>
  <div *ngIf="peticion.estado?.id !== constantes.ESTADO_BORRADOR">
    <h3>Datos Básicos Petición</h3>
    <div class="row">
      <div class="col-sm-12 col-lg-6">
        <span class="etiqueta">Estado: </span
        ><span class="texto" id="fechaCierre">{{
          peticion.estado?.nombre
        }}</span>
      </div>
      <div
        class="col-sm-12 col-lg-6"
        *ngIf="
          peticion.motivoAutorizacion &&
          peticion.motivoAutorizacion !== null &&
          peticion.borradorAutorizacion != 1
        "
      >
        <span class="etiqueta">Motivo Autorización: </span
        ><span
          class="texto"
          id="motivoAutorizacion"
          style="font-weight: bold"
          >{{ peticion.motivoAutorizacion }}</span
        >
      </div>
      <div
        class="col-sm-12 col-lg-6"
        *ngIf="
          peticion.observacionAutorizacion &&
          peticion.observacionAutorizacion !== null &&
          usuario.email != peticion.usuarioPeticionario?.email &&
          peticion.borradorAutorizacion != 1
        "
      >
        <span class="etiqueta">Comentario Autorización: </span
        ><span class="texto" id="observacionAutorizacion">{{
          peticion.observacionAutorizacion
        }}</span>
      </div>
    </div>
    <hr />
  </div>

  <div
    id="datosCreacionPeticion"
    class="espacio"
    *ngIf="
      peticion.usuarioPeticionario?.email !== null &&
      usuarioPeticionario !== null
    "
  >
    <h3>Datos Creación Petición</h3>
    <div class="row">
      <div class="col-sm-12 col-lg-6">
        <span class="etiqueta">Fecha-petición: </span
        ><span class="texto" id="fechaCreacion">{{
          peticion.fechaSolicitud | dateFormatPipe
        }}</span>
      </div>
      <div class="col-sm-12 col-lg-6">
        <span class="etiqueta">Solicitante: </span
        ><span class="texto" id="solicitanteNombre"
          >{{ usuarioPeticionario?.nombre }}
          {{ usuarioPeticionario?.apellido }}</span
        >
      </div>
    </div>
    <hr />
  </div>

  <div id="cierre" class="espacio" *ngIf="peticion.estado?.id === 6">
    <h3>Datos del cierre</h3>
    <div *ngFor="let cierre of peticion.cierres; index as i">
      <div class="row" *ngIf="cierre.borradorCierre != 1">
        <div class="col-sm-12 col-lg-6" *ngIf="cierre.borradorCierre != 1">
          <span class="etiqueta">Fecha de cierre: </span
          ><span class="texto" id="fechaCierre">{{
            cierre.fechaCierre | dateFormatPipe
          }}</span>
        </div>
        <div class="col-sm-12 col-lg-6" *ngIf="cierre.borradorCierre != 1">
          <span class="etiqueta">Motivo: </span
          ><span class="texto" id="motivoCierre" style="font-weight: bold">{{
            cierre.motivoCierre
          }}</span>
        </div>
        <div
          class="col-sm-12 col-lg-12"
          *ngIf="cierre.observacionCierre && cierre.borradorCierre != 1"
        >
          <span class="etiqueta">Comentario de cierre: </span
          ><span class="texto" id="observacionCierre"
            >{{ cierre.observacionCierre }}
          </span>
        </div>
        <p *ngIf="cierre.fechaReapertura && cierre.borradorReapertura != 1">
          <br />
        </p>
        <div
          class="col-sm-12 col-lg-12"
          *ngIf="cierre.fechaReapertura && cierre.borradorReapertura != 1"
        >
          <span class="etiqueta">Fecha de Reapertura: </span
          ><span class="texto" id="fechaReapertura">{{
            cierre.fechaReapertura | dateFormatPipe
          }}</span>
        </div>
        <div
          class="col-sm-12 col-lg-12"
          *ngIf="cierre.observacionReapertura && cierre.borradorReapertura != 1"
        >
          <span class="etiqueta">Comentario de Reapertura: </span
          ><span class="texto" id="observacionReapertura">{{
            cierre.observacionReapertura
          }}</span>
        </div>
        <div
          class="col-sm-12 col-lg-12"
          *ngIf="
            peticion.ficherosResolucion &&
            peticion.ficherosResolucion.length > 0 &&
            cierre.borradorCierre != 1
          "
        >
          <span class="etiqueta" *ngIf="cierreFichero(cierre.id)"
            >Ficheros resolución:
          </span>
          <ul
            class="list-group list-group-flush"
            *ngFor="let fichero of peticion.ficherosResolucion"
          >
            <li class="list-group-item" *ngIf="fichero.codCierre == cierre.id">
              <div
                class="document-link"
                (click)="descargarFicheroResolucion(fichero)"
              >
                <span
                  ><em class="fa fa-download"></em> {{ fichero.nombre }}</span
                >
              </div>
            </li>
          </ul>
        </div>
        <div
          class="col-sm-10 col-lg-10"
          style="margin-left: 8%"
          *ngIf="i !== peticion.cierres.length - 1"
        >
          <hr />
        </div>
      </div>
    </div>
    <hr />
  </div>

  <div id="datosDestino" class="espacio">
    <h3>Datos Destino</h3>
    <div class="row">
      <div class="col-sm-12 col-lg-6">
        <span class="etiqueta">Área Destino: </span
        ><span class="texto" id="areaDestino">{{ peticion.areaDestino }}</span>
      </div>
      <div class="col-sm-12 col-lg-6">
        <span class="etiqueta">Tipo de petición: </span
        ><span class="texto" id="catalogo">{{
          peticion.catalogo?.descripcion
        }}</span>
      </div>
    </div>
    <hr />
  </div>

  <div id="datosBasicos" class="espacio">
    <h3>Datos Básicos Peticionario</h3>
    <div class="row">
      <div class="col-sm-12 col-lg-6">
        <span class="etiqueta">Línea de venta: </span
        ><span class="texto" id="lineaVenta">{{
          peticion.lineaVenta?.descripcion
        }}</span>
      </div>
      <div class="col-sm-12 col-lg-6">
        <span class="etiqueta">Actividad / Departamento: </span
        ><span class="texto" id="actividad">{{
          peticion.actividad?.descripcion
        }}</span>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 col-lg-6" *ngIf="peticion.dniTrabajador">
        <span class="etiqueta">DNI Trabajador: </span
        ><span class="texto" id="dniTrabajador">{{
          peticion.dniTrabajador
        }}</span>
      </div>
      <div
        class="col-sm-12 col-lg-6"
        *ngIf="peticion.nombreTrabajador || peticion.apellidosTrabajador"
      >
        <span class="etiqueta">Trabajador: </span
        ><span class="texto" id="trabajador"
          >{{ peticion.nombreTrabajador }}
          {{ peticion.apellidosTrabajador }}</span
        >
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 col-lg-6" *ngIf="peticion.cliente">
        <span class="etiqueta">Cliente: </span
        ><span id="cliente">{{ peticion.cliente }}</span>
      </div>
      <div class="col-sm-12 col-lg-6" *ngIf="peticion.denominacionServicio">
        <span class="etiqueta">Denominación del Servicio: </span
        ><span class="texto" id="denominacion">{{
          peticion.denominacionServicio
        }}</span>
      </div>
    </div>
  </div>
  <hr />

  <div id="otrosDatos" class="espacio" *ngIf="peticion.fechaResolucion">
    <h3>Otros Datos</h3>
    <div class="row">
      <div class="col-sm-12 col-lg-6">
        <span class="etiqueta">Fecha Tope Resolución: </span
        ><span class="texto" id="fechaResolucion">{{
          peticion.fechaResolucion | dateFormatPipeYear
        }}</span>
      </div>
    </div>
    <hr />
  </div>

  <div
    id="ficheros"
    class="espacio"
    *ngIf="peticion.ficheros && peticion.ficheros.length > 0"
  >
    <h3>Ficheros Adjuntos</h3>
    <div class="row">
      <div class="col-sm-12 col-lg-8">
        <ul
          class="list-group list-group-flush"
          *ngFor="let fichero of peticion.ficheros"
        >
          <li class="list-group-item">
            <div
              class="document-link"
              (click)="descargarFicheroResolucion(fichero)"
            >
              <span>
                {{ fichero.nombre }} <em class="fa fa-download"></em
              ></span>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <hr />
  </div>

  <div id="datosEfectividad" class="espacio" *ngIf="datosEfectividad">
    <h3>Datos Efectividad</h3>
    <div class="row">
      <div class="col-sm-12 col-lg-12">
        <span *ngIf="peticion.fechaEfectividad" class="etiqueta"
          >{{ fechaTextoEfectividad }}: </span
        ><span class="texto" id="fechaEfectividad">{{
          peticion.fechaEfectividad | dateFormatPipeYear
        }}</span>
      </div>
      <div class="col-sm-12 col-lg-6">
        <span *ngIf="peticion.fechaDesde" class="etiqueta">Fecha Desde: </span
        ><span class="texto" id="fechaDesde">{{
          peticion.fechaDesde | dateFormatPipeYear
        }}</span>
      </div>
      <div class="col-sm-12 col-lg-6">
        <span *ngIf="peticion.fechaHasta" class="etiqueta">Fecha Hasta: </span
        ><span class="texto" id="fechaHasta">{{
          peticion.fechaHasta | dateFormatPipeYear
        }}</span>
      </div>
    </div>
    <hr />
  </div>

  <div
    id="datosSlas"
    class="espacio"
    *ngIf="
      peticion.calcularSLA === 1 &&
      peticion.tiempoConsumidoRespuestaSimple !== undefined &&
      peticion.tiempoTotalRespuestaSimple !== undefined &&
      peticion.porcentajeRespuestaSLA !== undefined &&
      peticion.tiempoConsumidoResolucionSimple !== undefined &&
      peticion.tiempoTotalResolucionSimple !== undefined &&
      peticion.porcentajeResolucionSLA !== undefined &&
      peticion.usuarioPeticionario?.email !== usuario.email
    "
  >
    <h3>Datos SLAs</h3>
    <div class="row">
      <div class="col-sm-12 col-lg-6">
        <span class="etiqueta">Tiempo consumido respuesta: </span>
        <div class="row containerSla">
          <div class="col-sm-6 col-lg-6">
            <div class="row ml-0 mr-4">
              <span class="mr-2"
                >{{
                  peticion.tiempoConsumidoRespuestaSimple | hoursToDaysPipe
                }}
                d</span
              >
              <p-progressBar
                [value]="peticion.porcentajeRespuestaSLA"
                class="contentProgressBar"
                [ngClass]="{
                  'progressBar-green': peticion.porcentajeRespuestaSLA < 50,
                  'progressBar-yellow':
                    peticion.porcentajeRespuestaSLA > 50 &&
                    peticion.porcentajeRespuestaSLA < 100,
                  'progressBar-red': peticion.porcentajeRespuestaSLA > 100
                }"
              ></p-progressBar>
              <span class="ml-2"
                >{{
                  peticion.tiempoTotalRespuestaSimple | hoursToDaysPipe
                }}
                d</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-lg-6">
        <span class="etiqueta">Tiempo consumido resolución: </span>
        <div class="row containerSla">
          <div class="col-sm-6 col-lg-6">
            <div class="row ml-0 mr-4">
              <span class="mr-2"
                >{{
                  peticion.tiempoConsumidoResolucionSimple | hoursToDaysPipe
                }}
                d</span
              >
              <p-progressBar
                [value]="peticion.porcentajeResolucionSLA"
                class="contentProgressBar"
                [ngClass]="{
                  'progressBar-green': peticion.porcentajeResolucionSLA < 50,
                  'progressBar-yellow':
                    peticion.porcentajeResolucionSLA > 50 &&
                    peticion.porcentajeResolucionSLA < 100,
                  'progressBar-red': peticion.porcentajeResolucionSLA > 100
                }"
              ></p-progressBar>
              <span class="ml-2"
                >{{
                  peticion.tiempoTotalResolucionSimple | hoursToDaysPipe
                }}
                d</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr />
  </div>

  <div id="notificaciones" class="espacio">
    <h3>Notificaciones</h3>
    <div class="row">
      <div class="col-sm-12 col-lg-6">
        <span class="etiqueta">Destinatario: </span
        ><span class="texto" id="destinatario">{{
          peticion.usuarioDestinatario?.email
        }}</span>
      </div>
      <div class="col-sm-12 col-lg-6" *ngIf="perfiles.length > 0">
        <span class="etiqueta">Perfiles a los que se va a notificar: </span>
        <ul class="list-group list-group-flush" *ngFor="let perfil of perfiles">
          <li class="list-group-item">{{ perfil.descripcion }}</li>
        </ul>
      </div>
    </div>
    <hr />
  </div>

  <div
    id="comentarios"
    class="espacio"
    *ngIf="
      peticion.estado?.id !== 1 &&
      (usuario.email === peticion.usuarioDestinatario?.email ||
        (usuario.puesto?.id === constantes.listaPuestosTecnicos[0].id &&
          peticion.areaDestino === constantes.listaAreaDestino[0]) ||
        (usuario.puesto?.id === constantes.listaPuestosTecnicos[1].id &&
          peticion.areaDestino === constantes.listaAreaDestino[1]) ||
        usuario.rol?.id === 1 ||
        usuario.rol?.id === 2)
    "
  >
    <h3>Comentarios</h3>
    <div class="row">
      <div class="col-sm-12 col-lg-12">
        <form [formGroup]="formulario" *ngIf="formulario">
          <div class="form-group">
            <textarea
              id="comentarios"
              class="form-control"
              formControlName="comentarios"
              [disabled]="!comentarioModificable"
              rows="5"
              trim="blur"
              maxlength="3000"
              (keypress)="onKeyPress($event)"
              (keyup)="onKeyUp($event)"
              (blur)="controlForm()"
            ></textarea>
            <span *ngIf="comentarioModificable"
              >Máximo: 3000. Total: {{ contador }}
            </span>
          </div>
          <div>
            <div *ngIf="comentarioModificable">
              <button
                style="float: right"
                id="guardar"
                type="submit"
                class="btn btn-outline-info"
                [disabled]="!formulario.valid"
                (click)="guardarComentario()"
              >
                Guardar comentario
              </button>
            </div>
            <div *ngIf="comentarioModificable">
              <p-fileUpload
                #fileInput
                name="uploadedFiles"
                [disabled]="uploadedFiles.length + fileInput.files.length >= 5"
                [ngClass]="{
                  active: uploadedFiles.length + fileInput.files.length >= 5
                }"
                [multiple]="true"
                [fileLimit]="5"
                [maxFileSize]="15728640"
                accept="image/*,.xls,.xlsx,.doc,.docx,.pdf"
                chooseLabel="Adjuntar"
                cancelLabel="Borrar"
                uploadLabel="Guardar"
                [showUploadButton]="false"
                [showCancelButton]="false"
                invalidFileLimitMessageSummary="Número máximo de ficheros excedidos: "
                invalidFileLimitMessageDetail="El límite es 5."
                invalidFileSizeMessageSummary="Tamaño máximo por fichero: "
                invalidFileSizeMessageDetail="El máximo es 15 MB por documento."
              >
              </p-fileUpload>
            </div>
          </div>

          <div class="espacio" *ngIf="uploadedFiles.length > 0">
            <p-table [value]="uploadedFiles">
              <ng-template pTemplate="header">
                <tr>
                  <th id="nombre">Nombre</th>
                  <th id="acciones" style="width: 20%">Acciones</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-file>
                <tr>
                  <td>{{ file.nombre }}</td>
                  <td class="acciones d-flex justify-content-center">
                    <div class="row">
                      <button
                        pTooltip="Descargar"
                        tooltipPosition="right"
                        class="btn btn-sm customButton mx-1"
                        (pointerdown)="fijarElemento($event)"
                        (click)="descargarFichero(file)"
                      >
                        <em class="fa fa-download"></em>
                      </button>

                      <button *ngIf="!file.nombre.includes('.xls')"
                        pTooltip="Visualizar"
                        tooltipPosition="right"
                        class="btn btn-sm btn-outline-info mx-1"
                        (pointerdown)="fijarElemento($event)"
                        (click)="visualizarFichero(file)"
                      >
                        <em class="fa fa-file"></em>
                      </button>

                      <button
                        *ngIf="estado === 1"
                        pTooltip="Eliminar"
                        tooltipPosition="right"
                        class="btn btn-sm btn-outline-danger mx-1"
                        (pointerdown)="fijarElemento($event)"
                        (click)="
                          eliminarFichero(file.idPeticion, file.idFichero)
                        "
                      >
                        <em class="fa fa-trash"></em>
                      </button>
                    </div>
                  </td>
                </tr>
                <hr class="item-list-separator" />
              </ng-template>
            </p-table>
          </div>

          <!-- En caso de tener que pintar el mensaje, hay que añadir el campo en esta zona.-->
        </form>
      </div>
    </div>
    <hr />
  </div>

  <div id="aclaraciones" class="espacio" *ngIf="aclaraciones.length > 0">
    <div class="row">
      <div class="col">
        <p-accordion>
          <p-accordionTab header="Aclaraciones" [selected]="true">
            <app-lista-aclaraciones
              style="width: 100%"
              [aclaraciones]="aclaraciones"
            ></app-lista-aclaraciones>
          </p-accordionTab>
        </p-accordion>
      </div>
    </div>
    <hr />
  </div>

  <div
    id="informaciones"
    class="espacio"
    *ngIf="
      informaciones.length > 0 &&
      usuario.email != peticion.usuarioPeticionario?.email
    "
  >
    <div class="row">
      <div class="col">
        <p-accordion>
          <p-accordionTab header="Informaciones" [selected]="true">
            <app-lista-aclaraciones
              style="width: 100%"
              [aclaraciones]="informaciones"
            ></app-lista-aclaraciones>
          </p-accordionTab>
        </p-accordion>
      </div>
    </div>
    <hr />
  </div>
</ng-container>

<p-table
  id="tabla-prioridades"
  #dt1
  [value]="prioridades"
  dataKey="id"
  [showCurrentPageReport]="true"
  styleClass="p-datatable-responsive-demo"
  [rows]="rows"
  [totalRecords]="resultadosTotales"
  [columns]="cols"
  [lazy]="true"
  [paginator]="false"
  [globalFilterFields]="cols"
  [sortOrder]="1"
  currentPageReportTemplate="Página {{ paginaActual }} de {{ paginasTotales }}"
  [rowsPerPageOptions]="resultadosPorPagina"
  [(first)]="first"
>
  <ng-template pTemplate="caption">
    <button class="btn boton-excel" (click)="exportarPrioridades()">
      <i class="fa fa-file-excel-o" aria-hidden="true"></i> EXPORTAR
    </button>
  </ng-template>

  <ng-template pTemplate="header">
    <tr>
      <th colspan="4" scope="{{ cols[0].field }}">
        <div class="p-d-flex p-jc-between p-ai-center">
          {{ cols[0].header }}
        </div>
      </th>
    </tr>

    <tr>
      <th rowspan="2" scope="{{ cols[1].field }}">
        <div class="p-d-flex p-jc-between p-ai-center">
          {{ cols[1].header }}
        </div>
      </th>

      <th colspan="3" scope="{{ cols[2].field }}">
        <div class="p-d-flex p-jc-between p-ai-center">
          {{ cols[2].header }}
        </div>
      </th>
    </tr>

    <tr>
      <th id="datos-1">
        <div class="p-d-flex p-jc-between p-ai-center">
          {{ urgenciaDesc[0] }} (High)
        </div>
      </th>

      <th id="datos-2">
        <div class="p-d-flex p-jc-between p-ai-center">
          {{ urgenciaDesc[1] }} (Medium)
        </div>
      </th>

      <th id="datos-3">
        <div class="p-d-flex p-jc-between p-ai-center">
          {{ urgenciaDesc[2] }} (Low)
        </div>
      </th>
    </tr>

    <tr>
      <th id="datos-4">
        <div class="p-d-flex p-jc-between p-ai-center">
          {{ impactoDesc[0] }} (High)
        </div>
      </th>

      <td>
        <div class="p-d-flex p-jc-between p-ai-center" id="alineado">
          {{ prioridadesDesc[0] }}
        </div>
      </td>

      <td>
        <div class="p-d-flex p-jc-between p-ai-center" id="alineado">
          {{ prioridadesDesc[1] }}
        </div>
      </td>

      <td>
        <div class="p-d-flex p-jc-between p-ai-center" id="alineado">
          {{ prioridadesDesc[2] }}
        </div>
      </td>
    </tr>

    <tr>
      <th id="datos-5">
        <div class="p-d-flex p-jc-between p-ai-center">
          {{ impactoDesc[1] }} (Medium)
        </div>
      </th>

      <td>
        <div class="p-d-flex p-jc-between p-ai-center" id="alineado">
          {{ prioridadesDesc[1] }}
        </div>
      </td>

      <td>
        <div class="p-d-flex p-jc-between p-ai-center" id="alineado">
          {{ prioridadesDesc[2] }}
        </div>
      </td>

      <td>
        <div class="p-d-flex p-jc-between p-ai-center" id="alineado">
          {{ prioridadesDesc[3] }}
        </div>
      </td>
    </tr>

    <tr>
      <th id="datos-6">
        <div class="p-d-flex p-jc-between p-ai-center">
          {{ impactoDesc[2] }} (Low)
        </div>
      </th>

      <td>
        <div class="p-d-flex p-jc-between p-ai-center" id="alineado">
          {{ prioridadesDesc[2] }}
        </div>
      </td>

      <td>
        <div class="p-d-flex p-jc-between p-ai-center" id="alineado">
          {{ prioridadesDesc[3] }}
        </div>
      </td>

      <td>
        <div class="p-d-flex p-jc-between p-ai-center" id="alineado">
          {{ prioridadesDesc[3] }}
        </div>
      </td>
    </tr>
  </ng-template>

  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="12">No hay resultados</td>
    </tr>
  </ng-template>
</p-table>

<app-modal-notificar
  id="modal-notificar"
  #modalnotificar
  (emitirNotificar)="notificadores($event)"
></app-modal-notificar>
<ngx-spinner></ngx-spinner>
<app-mensaje #mensaje></app-mensaje>
<div class="container espacio">
  <div class="d-flex justify-content-between mb-3">
    <h1>{{ textoMotivo }} petición</h1>
  </div>

  <div class="row">
    <div class="col-12">
      <form [formGroup]="formulario" *ngIf="formulario">
        <div class="descripcion">
          <div class="col-lg-8 seccion-comentario">
            <div class="row">
              <div class="col-sm-12 col-lg-6">
                <span
                  _ngcontent-sbf-c163
                  class="etiqueta font-weight-bold"
                  style="font-size: 1.1rem"
                  >Petición Nº:
                </span>
                <span
                  _ngcontent-sbf-c163
                  class="etiqueta font-weight-bold"
                  id="idPeticion"
                  class="texto"
                  >{{ peticion.idPeticion }}
                </span>
              </div>
              <div class="col-sm-12 col-lg-6">
                <span
                  _ngcontent-sbf-c163
                  class="etiqueta font-weight-bold"
                  style="font-size: 1rem"
                  >Tipo de petición:
                </span>
                <span
                  _ngcontent-sbf-c163
                  class="etiqueta font-weight-bold"
                  class="catalogo"
                  >{{ peticion.catalogo?.descripcion }}
                </span>
                <br />
              </div>
            </div>
            <hr _ngcontent-ewq-c163 />
            <div>
              <span
                _ngcontent-sbf-c163
                class="etiqueta font-weight-bold"
                style="font-size: 1rem"
                >Solicitante:
              </span>
              <span
                _ngcontent-sbf-c163
                class="etiqueta font-weight-bold"
                class="usuarioPeticionario"
                >{{ peticion.usuarioPeticionario?.email }}
              </span>
              <br />
              <hr _ngcontent-ewq-c163 />
            </div>
            <div>
              <span
                _ngcontent-sbf-c163
                class="etiqueta font-weight-bold"
                style="font-size: 1rem"
                >Descripcion:
              </span>
              <br />
              <span
                _ngcontent-sbf-c163
                class="etiqueta font-weight-bold"
                class="descripcion"
                >{{ peticion.descripcion }}
              </span>
              <br />
              <hr _ngcontent-ewq-c163 />
            </div>
          </div>
          <div class="row" *ngIf="peticion.cierres.length > 0">
            <div id="cierre" class="espacio">
              <div *ngFor="let cierre of peticion.cierres; index as i">
                <h3 *ngIf="cierre.borradorCierre != 1">
                  Datos de cierre anterior
                </h3>
                <div class="row" *ngIf="cierre.borradorCierre != 1">
                  <div class="col-sm-12 col-lg-6">
                    <span class="etiqueta">Fecha de cierre: </span
                    ><span class="texto" id="fechaCierre">{{
                      cierre.fechaCierre | dateFormatPipe
                    }}</span>
                  </div>
                  <div class="col-sm-12 col-lg-6">
                    <span class="etiqueta">Motivo: </span
                    ><span
                      class="texto"
                      id="motivoCierre"
                      style="font-weight: bold"
                      >{{ cierre.motivoCierre }}</span
                    >
                  </div>
                  <div
                    class="col-sm-12 col-lg-12"
                    *ngIf="cierre.observacionCierre"
                  >
                    <span class="etiqueta">Comentario de cierre: </span
                    ><span class="texto" id="observacionCierre"
                      >{{ cierre.observacionCierre }}
                    </span>
                  </div>
                  <p *ngIf="cierre.fechaReapertura"><br /></p>
                  <div
                    class="col-sm-12 col-lg-12"
                    *ngIf="cierre.fechaReapertura"
                  >
                    <span class="etiqueta">Fecha de Reapertura: </span
                    ><span class="texto" id="fechaReapertura">{{
                      cierre.fechaReapertura | dateFormatPipe
                    }}</span>
                  </div>
                  <div
                    class="col-sm-12 col-lg-12"
                    *ngIf="cierre.observacionReapertura"
                  >
                    <span class="etiqueta">Comentario de Reapertura: </span
                    ><span class="texto" id="observacionReapertura">{{
                      cierre.observacionReapertura
                    }}</span>
                  </div>
                  <div
                    class="col-sm-12 col-lg-12"
                    *ngIf="
                      peticion.ficherosResolucion &&
                      peticion.ficherosResolucion.length > 0
                    "
                  >
                    <span class="etiqueta" *ngIf="cierreFichero(cierre.id)"
                      >Ficheros resolución:
                    </span>
                    <ul
                      class="list-group list-group-flush"
                      *ngFor="let fichero of peticion.ficherosResolucion"
                    >
                      <li
                        class="list-group-item"
                        *ngIf="fichero.codCierre == cierre.id"
                      >
                        <div
                          class="document-link"
                          (click)="descargarFicheroResolucion(fichero)"
                        >
                          <span
                            ><em class="fa fa-download"></em>
                            {{ fichero.nombre }}</span
                          >
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div
                    class="col-sm-10 col-lg-8"
                    style="margin-left: 17%"
                    *ngIf="i !== peticion.cierres.length - 1"
                  >
                    <hr />
                  </div>
                </div>
              </div>
              <hr />
            </div>
          </div>
          <div class="row">
            <div
              *ngIf="estaInformada"
              class="col alert alert-warning text-center"
              role="alert"
            >
              Esta petición todavía no ha sido informada
            </div>
          </div>
          <div
            class="form-group"
            style="margin-top: 3rem"
            *ngIf="permisoEscritura"
          >
            <span *ngIf="borrador" class="badge badge-success">Borrador</span>
            <div>
              <button
                type="button"
                class="btn btn-outline-secondary fa fa-eraser limpiar"
                style="float: right"
                (click)="limpiar()"
              >
                Limpiar
              </button>
            </div>
            <h4>
              Comentario {{ textoMotivo }}:
              <span *ngIf="motivoCierre === '13'" style="color: red">*</span>
            </h4>
            <textarea
              id="msg"
              class="form-control"
              formControlName="msg"
              rows="3"
              trim="blur"
              maxlength="3000"
              (keypress)="onKeyPress($event)"
              (keyup)="onKeyUp($event)"
              required
              (blur)="controlForm()"
            ></textarea>
            <div class="input-group">
              <span class="col col-lg-8"
                >Máximo: 3000. Total: {{ contador }}
              </span>
              <small
                class="form-text text-danger col col-lg-4 error-msg"
                *ngIf="
                  !formulario.get('msg')!.valid &&
                  formulario.get('msg')!.touched &&
                  formulario.controls.msg.errors?.required &&
                  motivoCierre === '13'
                "
                >* El campo es obligatorio</small
              >
            </div>
            <div class="espacio" *ngIf="uploadedFiles.length > 0 && borrador">
              <p-table [value]="uploadedFiles">
                <ng-template pTemplate="header">
                  <tr>
                    <th id="nombre">Nombre de los ficheros del borrador</th>
                    <th id="acciones" style="width: 20%">Acciones</th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-file>
                  <tr>
                    <td>{{ file.nombre }}</td>
                    <td class="acciones d-flex justify-content-center">
                      <div class="row">
                        <button
                          pTooltip="Descargar"
                          tooltipPosition="right"
                          class="btn btn-sm customButton mx-1"
                          (pointerdown)="fijarElemento($event)"
                          (click)="descargarFicheroResolucion(file)"
                        >
                          <em class="fa fa-download"></em>
                        </button>

                        <button *ngIf="!file.nombre.includes('.xls')"
                          pTooltip="Visualizar"
                          tooltipPosition="right"
                          class="btn btn-sm btn-outline-info mx-1"
                          (pointerdown)="fijarElemento($event)"
                          (click)="visualizarFicheroResolucion(file)"
                        >
                          <em class="fa fa-file"></em>
                        </button>

                        <button
                          pTooltip="Eliminar"
                          tooltipPosition="right"
                          class="btn btn-sm btn-outline-danger mx-1"
                          (pointerdown)="fijarElemento($event)"
                          (click)="eliminarFichero(file.idFichero)"
                        >
                          <em class="fa fa-trash"></em>
                        </button>
                      </div>
                    </td>
                  </tr>
                  <hr class="item-list-separator" />
                </ng-template>
              </p-table>
            </div>
            <button
              class="btn btn-outline-primary customButton btn-enviar col col-lg-3"
              [disabled]="deshabilitarBoton()"
              (click)="cerrarPeticion()"
            >
              Cerrar Petición
            </button>
            <button
              class="btn btn-success btn-enviar col col-lg-3"
              style="margin-right: 12px"
              [disabled]="!this.formulario.valid"
              (click)="crearCierreBorrador()"
            >
              Guardar borrador
            </button>
            <button
              class="btn btn-danger btn-enviar col col-lg-3"
              style="margin-right: 12px"
              [disabled]="!this.formulario.valid"
              (click)="eliminarCierreBorrador()"
            >
              Eliminar borrador
            </button>
            <p-toast></p-toast>
            <div>
              <p-fileUpload
                #fileInput
                name="uploadedFiles"
                [disabled]="uploadedFiles.length + fileInput.files.length >= 5"
                [ngClass]="{
                  active: uploadedFiles.length + fileInput.files.length >= 5
                }"
                [multiple]="true"
                [fileLimit]="5"
                [maxFileSize]="15728640"
                accept="image/*,.xls,.xlsx,.doc,.docx,.pdf"
                chooseLabel="Adjuntar"
                cancelLabel="Borrar"
                uploadLabel="Guardar"
                [showUploadButton]="false"
                [showCancelButton]="false"
                invalidFileLimitMessageSummary="Número máximo de ficheros excedidos: "
                invalidFileLimitMessageDetail="El límite es 5."
                invalidFileSizeMessageSummary="Tamaño máximo por fichero: "
                invalidFileSizeMessageDetail="El máximo es 15 MB por documento."
              >
              </p-fileUpload>
            </div>
          </div>
        </div>
      </form>
      <button class="btn btn-outline-secondary" (click)="volver()">
        Volver
      </button>
    </div>
  </div>
</div>

<div class="contenedorModal" *ngIf="verModalAutorizar">
  <app-mensaje #mensaje> </app-mensaje>
  <ngx-spinner></ngx-spinner>
  <ngx-spinner
    name="googleload"
    template="<img src='assets/googleload.gif'/>"
    bdColor="rgba(239,239,239,255)"
  ></ngx-spinner>
  <p-toast
    styleClass="custom-toast-autorizar"
    position="center"
    key="accion"
    (onClose)="cerrarModal()"
    [baseZIndex]="1000"
  >
    <ng-template let-ModalAccion pTemplate="modalAccion">
      <div class="p-flex p-flex-column" style="flex: 1; padding: 1rem 2rem">
        <div style="text-align: center">
          <em class="pi pi-question-circle" style="font-size: 3em"></em>
          <h3>Solicitar Autorización</h3>
        </div>
        <p class="descripcion-accion">
          Seleccione al autorizante e informantes necesarios
        </p>
        <form [formGroup]="formulario" *ngIf="formulario">
          <div class="form-group row" id="informar">
            <div class="mb-3 col-sm-12 col-lg-12">
              <label for="informantes" class="col-form-label"
                >Informantes<span class="text-danger">*</span></label
              >
              <p-multiSelect
                id="informantes"
                filterPlaceHolder="Buscar informantes"
                [defaultLabel]="'Seleccione informante'"
                [virtualScroll]="true"
                [options]="listaInformantes"
                optionLabel="email"
                formControlName="informantes"
                styleClass="form-control
                              p-0"
              ></p-multiSelect>
            </div>
          </div>
          <div class="form-group row" id="autorizar">
            <div class="mb-3 col-sm-12 col-lg-12">
              <label for="autorizante" class="col-form-label"
                >Autorizante<span class="text-danger">*</span></label
              >
              <p-dropdown
                id="autorizante"
                [virtualScroll]="true"
                [options]="listaAutorizantes"
                placeholder="Seleccione un autorizador"
                optionLabel="email"
                formControlName="autorizante"
                styleClass="form-control p-0"
                [filter]="true"
              >
              </p-dropdown>
            </div>
          </div>
          <div class="row mt-5">
            <div class="col botones-modal">
              <button
                type="button"
                (click)="cerrarModal()"
                class="btn btn-danger danger cancel"
              >
                Cancelar
              </button>
              <button
                type="submit"
                [disabled]="!formulario.valid"
                (click)="aceptarModal()"
                class="btn btn-success success"
              >
                Aceptar
              </button>
            </div>
          </div>
        </form>
      </div>
    </ng-template>
  </p-toast>
</div>

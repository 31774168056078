import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Injector,
} from '@angular/core';
import { PeticionService } from '../../../servicios/peticion/peticion.service';
import { forkJoin } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Constantes } from '../../../utils/constantes';
import { PeticionFiltos } from '../../../modelos/peticion';
import { SesionService } from '../../../servicios/sesion/sesion.service';
import { CatalogosService } from '../../../servicios/catalogos/catalogos.service';
import { Catalogo } from '../../../modelos/catalogo';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActividadesService } from '../../../servicios/actividades/actividades.service';
import { Actividades } from '../../../modelos/actividades';
import { LineasVentaService } from '../../../servicios/lineas-venta/lineas-venta.service';
import { LineasVenta } from '../../../modelos/lineas-venta';
import { EstadosService } from '../../../servicios/estados/estados.service';
import { Estado } from '../../../modelos/estado';
import Utils from '../../../utils/utils';
import { UsuariosService } from '../../../servicios/usuarios/usuarios.service';

@Component({
  selector: 'app-filtro-peticion',
  templateUrl: './filtro-peticion.component.html',
  styleUrls: ['./filtro-peticion.component.css'],
})
export class FiltroPeticionComponent implements OnInit {
  @Output() filtrosPeticion = new EventEmitter();
  @Output() loading = new EventEmitter();

  public formulario!: FormGroup;
  public removable = true;

  listaAnios: number[] = [];
  listaAreas = this.constantes.listaAreaDestino;
  listaTiposPeticiones: Catalogo[] = [];
  listaActividades: Actividades[] = [];
  listaLineasVenta: LineasVenta[] = [];
  listaUrgencias = this.constantes.listaUrgencia;
  listaEstados: Estado[] = [];
  listaApellidos: any[] = [];
  apellidosBuscados = false;
  listaNombres: any[] = [];
  nombresBuscados = false;
  listaEmails: any[] = [];
  emailsBuscados = false;
  listaIdPeticiones: any[] = [];
  idPeticionesBuscadas = true;
  public avanzado = false;
  public textoBoton = 'Más filtros';
  public datosFiltro!: PeticionFiltos;

  private peticionService!: PeticionService;
  private sesionService!: SesionService;
  private catalogoService!: CatalogosService;
  private actividadService!: ActividadesService;
  private lineasService!: LineasVentaService;
  private estadoService!: EstadosService;
  private usuarioService!: UsuariosService;

  constructor(
    private injector: Injector,
    private fb: FormBuilder,
    private constantes: Constantes,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.initServices();
  }

  private initServicesProperties(): void {
    this.peticionService = this.injector.get<PeticionService>(PeticionService);
    this.sesionService = this.injector.get<SesionService>(SesionService);
    this.catalogoService =
      this.injector.get<CatalogosService>(CatalogosService);
    this.actividadService =
      this.injector.get<ActividadesService>(ActividadesService);
    this.lineasService =
      this.injector.get<LineasVentaService>(LineasVentaService);
    this.estadoService = this.injector.get<EstadosService>(EstadosService);
    this.usuarioService = this.injector.get<UsuariosService>(UsuariosService);

    this.datosFiltro = this.peticionService.datosFiltrosPeticion;
  }

  private initServices(): void {
    this.initServicesProperties();
    this.spinner.show();
    const llamadas = [];
    llamadas.push(this.peticionService.obtenerPeticionesFechas());
    llamadas.push(this.catalogoService.obtenerTiposPeticiones('', true));
    llamadas.push(this.actividadService.getActividades(true));
    llamadas.push(this.lineasService.getLineasVenta(true));
    llamadas.push(this.estadoService.getEstados());
    forkJoin(llamadas).subscribe((resp) => {
      this.listaAnios = resp[0];
      this.listaTiposPeticiones = resp[1];
      this.listaActividades = resp[2];
      this.listaLineasVenta = resp[3];
      this.listaEstados = resp[4];
      this.initForm();
    });
  }

  private initForm(): void {
    if (this.datosFiltro) {
      this.formulario = this.initFormWithFilters();
      this.buscarFiltro();
    } else {
      this.formulario = this.initEmptyForm();
    }
    this.loading.emit(false);
    this.spinner.hide();
  }

  initFormWithFilters(): FormGroup {
    this.filtrosPeticion.emit(this.datosFiltro);

    return this.fb.group({
      idPeticion: [this.datosFiltro.idPeticion],
      anio: [this.datosFiltro.anio, Validators.required],
      areas: [this.datosFiltro.areas],
      tipos: [this.datosFiltro.tipos],
      actividades: [this.datosFiltro.actividades],
      lineas: [this.datosFiltro.lineas],
      urgencias: [this.datosFiltro.urgencias],
      estados: [this.datosFiltro.estados],
      fechaSolicitudInicio: [this.datosFiltro.fechaSolicitudInicio],
      fechaSolicitudFin: [this.datosFiltro.fechaSolicitudFin],
      apellido: [''],
      apellidos: [
        this.datosFiltro.destinatarioApellidos
          ? this.datosFiltro.destinatarioApellidos
          : [],
      ],
      nombre: [''],
      nombres: [
        this.datosFiltro.destinatarioNombres
          ? this.datosFiltro.destinatarioNombres
          : [],
      ],
      email: [''],
      emails: [
        this.datosFiltro.destinatarioEmails
          ? this.datosFiltro.destinatarioEmails
          : [],
      ],
      dniTrabjAfec: [this.datosFiltro.dniTrabjAfec],
      cliente: [this.datosFiltro.cliente],
      denominacion: [this.datosFiltro.denominacion],
      busqueda: [this.datosFiltro.busqueda],
      ocultarPeticiones: [
        this.datosFiltro.ocultarPeticiones == 1 ? true : false,
      ],
    });
  }

  initEmptyForm(): FormGroup {
    return this.fb.group({
      idPeticion: [''],
      idPeticiones: [[]],
      ocultarPeticiones: [1],
      anio: [this.listaAnios[0]],
      areas: [[]],
      tipos: [[]],
      actividades: [[]],
      lineas: [[]],
      urgencias: [[]],
      estados: [[]],
      fechaSolicitudInicio: [''],
      fechaSolicitudFin: [''],
      apellido: [''],
      apellidos: [[]],
      nombre: [''],
      nombres: [[]],
      email: [''],
      emails: [[]],
      dniTrabjAfec: [''],
      cliente: [''],
      denominacion: [''],
      busqueda: [''],
    });
  }

  public reset(): void {
    this.formulario.reset();
    this.formulario.controls.idPeticion.setValue('');
    this.formulario.controls.anio.setValue(this.listaAnios[0]);
    this.formulario.controls.areas.setValue([]);
    this.formulario.controls.tipos.setValue([]);
    this.formulario.controls.actividades.setValue([]);
    this.formulario.controls.lineas.setValue([]);
    this.formulario.controls.urgencias.setValue([]);
    this.formulario.controls.estados.setValue([]);
    this.formulario.controls.fechaSolicitudInicio.setValue('');
    this.formulario.controls.fechaSolicitudFin.setValue('');
    this.formulario.controls.apellido.setValue('');
    this.formulario.controls.apellidos.setValue([]);
    this.formulario.controls.dniTrabjAfec.setValue('');
    this.formulario.controls.cliente.setValue('');
    this.formulario.controls.denominacion.setValue('');
    this.formulario.controls.busqueda.setValue('');
    this.formulario.controls.ocultarPeticiones.setValue(1);
    this.apellidosBuscados = false;
    this.listaApellidos = [];
    this.formulario.controls.nombre.setValue('');
    this.formulario.controls.nombres.setValue([]);
    this.nombresBuscados = false;
    this.listaNombres = [];
    this.formulario.controls.email.setValue('');
    this.formulario.controls.emails.setValue([]);
    this.emailsBuscados = false;
    this.listaEmails = [];
    this.formulario.controls.idPeticion.setValue('');
    this.formulario.controls.idPeticiones.setValue([]);
    this.idPeticionesBuscadas = true;
    this.listaIdPeticiones = [];
    this.buscarFiltro();
  }

  public buscarFiltro(): void {
    this.datosFiltro = this.buscarPeticiones();
    this.peticionService.setDatosFiltrosPeticion(this.datosFiltro);
    this.filtrosPeticion.emit(this.datosFiltro);
  }

  public buscarPeticiones(): PeticionFiltos {
    return {
      peticionario: this.sesionService.getUser().email,
      anio: this.formulario.get('anio')?.value,
      areas: this.formulario.get('areas')?.value,
      tipos: this.formulario.get('tipos')?.value,
      actividades: this.formulario.get('actividades')?.value,
      lineas: this.formulario.get('lineas')?.value,
      urgencias: this.formulario.get('urgencias')?.value,
      estados: this.formulario.get('estados')?.value,
      fechaSolicitudInicio: this.formulario.get('fechaSolicitudInicio')?.value,
      fechaSolicitudFin: this.formulario.get('fechaSolicitudFin')?.value,
      destinatarioApellidos: this.formulario.get('apellidos')?.value,
      destinatarioNombres: this.formulario.get('nombres')?.value,
      destinatarioEmails: this.formulario.get('emails')?.value,
      dniTrabjAfec: this.formulario.get('dniTrabjAfec')?.value,
      cliente: this.formulario.get('cliente')?.value,
      denominacion: this.formulario.get('denominacion')?.value,
      idPeticion: this.formulario.get('idPeticion')?.value,
      busqueda: this.formulario.get('busqueda')?.value,
      ocultarPeticiones: this.formulario.get('ocultarPeticiones')?.value
        ? 1
        : 0,
    };
  }

  public validarFechas(fechaInicio: string, fechaFin: string): boolean {
    if (fechaInicio && fechaFin) {
      if (
        Utils.getDate(fechaInicio).getTime() > Utils.getDate(fechaFin).getTime()
      ) {
        return true;
      }
    }
    return false;
  }

  buscarApellido(event: any): any {
    const cadena = event.target.value;
    this.apellidosBuscados = false;
    if (!cadena || cadena.length < 3) {
      this.listaApellidos = [];
    } else {
      this.usuarioService
        .buscarApellidoForm(cadena)
        .subscribe((resp: string[]) => {
          this.apellidosBuscados = true;
          this.listaApellidos = resp
            .filter(
              (apellido: string) =>
                !this.formulario.get('apellidos')?.value.includes(apellido)
            )
            .map((apellido: string) => ({ apellido, state: false }));
        });
    }
  }

  removeApellidoForm(apellido: string, index: number): void {
    this.formulario.get('apellidos')?.value.splice(index, 1);
    this.listaApellidos.push({ apellido, state: false });
  }

  removeApellido(index: number): void {
    this.listaApellidos.splice(index, 1);
    if (this.listaApellidos.length === 0) {
      this.formulario.controls.apellido.setValue('');
    }
  }

  addApellido(item: any, index: number): void {
    if (!this.formulario.get('apellidos')?.value.includes(item.apellido)) {
      this.formulario.get('apellidos')?.value.push(item.apellido);
    }
    this.removeApellido(index);
  }

  buscarNombres(event: any): any {
    const cadena = event.target.value;
    this.nombresBuscados = false;
    if (!cadena || cadena.length < 3) {
      this.listaNombres = [];
    } else {
      this.usuarioService
        .buscarNombreForm(cadena)
        .subscribe((resp: string[]) => {
          this.nombresBuscados = true;
          this.listaNombres = resp
            .filter(
              (nombre: string) =>
                !this.formulario.get('nombres')?.value.includes(nombre)
            )
            .map((nombre: string) => ({ nombre, state: false }));
        });
    }
  }

  removeNombreForm(nombre: string, index: number): void {
    this.formulario.get('nombres')?.value.splice(index, 1);
    this.listaNombres.push({ nombre, state: false });
  }

  removeNombre(index: number): void {
    this.listaNombres.splice(index, 1);
    if (this.listaNombres.length === 0) {
      this.formulario.controls.nombre.setValue('');
    }
  }

  addNombre(item: any, index: number): void {
    if (!this.formulario.get('nombres')?.value.includes(item.nombre)) {
      this.formulario.get('nombres')?.value.push(item.nombre);
    }
    this.removeNombre(index);
  }

  buscarEmails(event: any): any {
    const cadena = event.target.value;
    this.emailsBuscados = false;
    if (!cadena || cadena.length < 3) {
      this.listaEmails = [];
    } else {
      this.usuarioService
        .buscarEmailForm(cadena)
        .subscribe((resp: string[]) => {
          this.emailsBuscados = true;
          this.listaEmails = resp
            .filter(
              (email: string) =>
                !this.formulario.get('emails')?.value.includes(email)
            )
            .map((email: string) => ({ email, state: false }));
        });
    }
  }

  removeEmailForm(email: string, index: number): void {
    this.formulario.get('emails')?.value.splice(index, 1);
    this.listaEmails.push({ email, state: false });
  }

  removeEmail(index: number): void {
    this.listaEmails.splice(index, 1);
    if (this.listaEmails.length === 0) {
      this.formulario.controls.email.setValue('');
    }
  }

  addEmail(item: any, index: number): void {
    if (!this.formulario.get('emails')?.value.includes(item.email)) {
      this.formulario.get('emails')?.value.push(item.email);
    }
    this.removeEmail(index);
  }

  /**
   * Cambiar atributo para mostrar el filtro de avanzado o no
   */
  mostrarAvanzado(): void {
    if (this.avanzado) {
      this.avanzado = false;
      this.textoBoton = 'Más filtros';
    } else {
      this.avanzado = true;
      this.textoBoton = 'Menos filtros';
    }
  }
}

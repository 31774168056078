import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Constantes } from 'src/app/utils/constantes';
import { RolPermisos } from '../../modelos/rol-permisos';


@Injectable({
  providedIn: 'root'
})
export class RolesService {


  constructor(
    private http: HttpClient,
    private constantes: Constantes
  ) { }

  public getRoles(): Observable<any> {
    const url: string = this.constantes.ENDPOINT_API_ROLES;
    return this.http.get(url);
  }

  public getRolesPermisos(): Observable<any> {
    return this.http.get(this.constantes.ENDPOINT_API_ROLES_PERMISOS);
  }

  public exportar(): Observable<any> {
    return this.http.get(this.constantes.ENDPOINT_API_EXPORTAR + '/exportar-roles');
  }

  public cambiarPermiso(valor: string, rolPermiso: RolPermisos): Observable<any>  {
    const url: string = this.constantes.ENDPOINT_API_ROLES_CAMBIAR.replace('{idPermiso}', valor);
    return this.http.put(url, rolPermiso);
  }
}

import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { SesionService } from '../servicios/sesion/sesion.service';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { SubjectsService } from '../servicios/subjects/subjects.service';
import { HandlerErrorService } from '../servicios/errores/handler-error.service';


@Injectable()
export class Interceptors implements HttpInterceptor {

     private noRepetir: boolean;

    constructor(
      private sesionService: SesionService,
      private subjectsService: SubjectsService,
      private handlerError: HandlerErrorService) {
        this.noRepetir = false;
    }

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

      const token = 'Bearer ' + this.sesionService.getIdToken();
      request = request.clone({
        setHeaders: {
            Authorization: token
        }
    });

      console.log(request.url);
      return next.handle(request)
      .pipe(
        catchError((err: any) => {
            if (err instanceof HttpErrorResponse) {
                    try {
                        if (err.status === 403) {
                          this.noRepetir = true;
                          console.log(err);
                          this.subjectsService.emitirSesionCaducada(err);
                          this.sesionService.setSesionCaducada(true);
                        }
                        if (err.status === 401) {
                          this.noRepetir = true;
                          console.log(err.message);
                          this.subjectsService.emitirEmitirError(err);
                        }
                    } catch (e) { }
                    return throwError(err);
            }
            return throwError(err);
        })
    );
    }
}

import { Component, OnInit, Input } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { Router } from '@angular/router';

@Component({
  selector: 'app-crear-catalogo-base',
  templateUrl: './crear-catalogo-base.component.html',
  styleUrls: ['./crear-catalogo-base.component.scss']
})
export class CrearCatalogoBaseComponent implements OnInit {

  items!: MenuItem[];

  textoBotonVolver = '';

  @Input()
  activeIndex!: number;

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.loadInfo();
  }


  private loadInfo(): void {
    this.items = [{
        label: 'Datos del Catálogo'
      },
      {
        label: 'Plantillas de Automatismos'
      }
    ];
  }


  public volver(): void{
    this.router.navigate(['/catalogos']);
  }

  public fijarElemento(event: any): void{
    event.preventDefault();
  }

}

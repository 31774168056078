<ngx-spinner></ngx-spinner>

<div class="container espacio">
    <div class="d-flex justify-content-between mb-3">
        <h1>Gestión de Convenios</h1>
    </div>
    <div class="card">
        <div class="borde">
            <!-- Filtro de la tabla convenios -->
            <app-filtro-convenios (desdeFiltroConvenios)="recibirFiltros($event)"></app-filtro-convenios>
            <hr>
            <!-- Tabla de convenios -->
            <app-convenios [datosFiltro]="datosDelFiltro"></app-convenios>
        </div>
    </div>
</div>

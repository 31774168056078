<div class="container">
  <fieldset>
    <legend class="mb-0">Buscar por ...</legend>
    <form [formGroup]="formulario" *ngIf="formulario">
      <div class="row mb-4">
        <div class="col-md-2 radio">
          <p-radioButton
            name="activo"
            [value]="true"
            label="Activas"
            formControlName="activo"
            inputId="activos"
            labelStyleClass="mb-0"
            [checked]
          ></p-radioButton>
        </div>
        <div class="col-md-2 radio">
          <p-radioButton
            name="activo"
            [value]="false"
            label="Inactivas"
            formControlName="activo"
            inputId="inactivos"
            labelStyleClass="mb-0"
          ></p-radioButton>
        </div>
      </div>
      <div class="row">
        <div class="mb-3 col-sm-12 col-lg-8">
          <label for="tipoPeticion" class="form-label">Tipo Petición</label>
          <p-multiSelect
            id="tipoPeticion"
            filterPlaceHolder="Buscar"
            [defaultLabel]="'Seleccionar tipo de petición'"
            [virtualScroll]="true"
            [options]="listaTipoPeticiones"
            optionLabel="descripcion"
            formControlName="tipoPeticion"
            styleClass="form-control p-0"
          ></p-multiSelect>
        </div>
      </div>
      <div class="row">
        <div class="mb-3 col-sm-12 col-lg-4">
          <label for="accion" class="form-label">Acciones</label>
          <p-multiSelect
            id="accion"
            filterPlaceHolder="Buscar"
            [defaultLabel]="'Seleccionar acción'"
            [virtualScroll]="true"
            [options]="listaAcciones"
            optionLabel="descripcion"
            formControlName="accion"
            styleClass="form-control p-0"
          ></p-multiSelect>
        </div>
        <div class="mb-3 col-sm-12 col-lg-4">
          <label for="notificado" class="form-label">Por notificados</label>
          <p-multiSelect
            id="notificado"
            styleClass="form-control p-0"
            placeholder="Seleccionar por notificado"
            [options]="listaPorNotificados"
            formControlName="notificado"
            optionLabel="descripcion"
            [filter]="true"
          >
          </p-multiSelect>
        </div>
      </div>

      <div class="mt-3 mb-3 d-flex izquierda">
        <button
          class="btn limpiarBoton customButton fa fa-eraser"
          (click)="reset()"
        >
          Limpiar
        </button>
        <button
          type="submit"
          class="btn btn-outline-primary customButton fa fa-search buscar"
          (click)="buscarFiltro(false)"
        >
          Buscar
        </button>
      </div>
    </form>
  </fieldset>
</div>

import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChildren,
} from '@angular/core';
import { SesionService } from '../../../servicios/sesion/sesion.service';
import { Table } from 'primeng/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { UsuariosService } from 'src/app/servicios/usuarios/usuarios.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ModalInfoComponent } from '../../modals/modal-info/modal-info.component';
import { ButtonModalInfo, ModalInfo } from 'src/app/modelos/moda-info';
import { UsuarioFiltros } from 'src/app/modelos/usuario-filtros';
import { Constantes } from 'src/app/utils/constantes';
import { Usuario } from '../../../modelos/usuario';
import Utils from '../../../utils/utils';
import { UsuarioFiltrosRequest } from '../../../modelos/usuario-filtros';
import { Empresa } from '../../../modelos/empresa';
import { Rol } from 'src/app/modelos/rol';
import { TipoSolicitante } from 'src/app/modelos/tipo-solicitante';
import { Puesto } from '../../../modelos/puesto';
import { Zona } from '../../../modelos/zona';
import { Provincia } from 'src/app/modelos/provincia';
import { Ambito } from 'src/app/modelos/ambito';
import { Actividades } from 'src/app/modelos/actividades';
import { LineasVenta } from 'src/app/modelos/lineas-venta';

declare let $: any;

@Component({
  selector: 'app-tabla-usuarios',
  templateUrl: './tabla-usuarios.component.html',
  styleUrls: ['./tabla-usuarios.component.scss'],
})
export class TablaUsuariosComponent implements OnInit {
  usuarios: Usuario[] = [];
  cols!: any[];
  first = 1;
  rows = 25;

  paginasTotales = 0;
  paginaActual = 1;
  usuariosTotales = 0;
  usuariosPorPagina!: number[];
  constantes!: Constantes;

  @ViewChildren('dt1')
  public table!: Table;

  lastSortField!: any;
  public usuarioLogin!: Usuario;

  // tslint:disable-next-line:variable-name
  public _datosFiltro!: UsuarioFiltros;

  @Input()
  set datosFiltro(param: any) {
    this._datosFiltro = param;
    this.first = 1;
    if (param) {
      this.callUsuariosFiltros(this.first);
    }
  }

  get datosFiltro(): any {
    return this._datosFiltro;
  }

  @Output()
  public irAuditoria = new EventEmitter();

  constructor(
    private usuarioService: UsuariosService,
    private sesionService: SesionService,
    private spinner: NgxSpinnerService,
    private router: Router,
    public dialog: MatDialog
  ) {
    this.cols = [
      { field: 'estado', header: 'ESTADO' },
      { field: 'nombre', header: 'NOMBRE' },
      { field: 'apellidos', header: 'APELLIDOS' },
      { field: 'rol', header: 'ROL' },
      { field: 'perfil', header: 'PERFIL' },
      { field: 'zona', header: 'ZONA' },
      { field: 'actividad', header: 'ACTIVIDAD' },
      { field: 'acciones', header: 'ACCIONES' },
    ];

    this.constantes = new Constantes();
    this.usuariosPorPagina = this.constantes.numeroUsuariosPorPagina;
  }

  ngOnInit(): any {
    this.usuarioLogin = this.sesionService.getUser();
    if (this.usuarioService.getDatosFiltros()) {
      this.datosFiltro = this.usuarioService.getDatosFiltros();
    }
    if (this._datosFiltro) {
      if (this._datosFiltro.recordsPerPage) {
        this.rows = this._datosFiltro.recordsPerPage;
      }
      if (this._datosFiltro.currentPage) {
        this.first = this._datosFiltro.currentPage;
        this.paginaActual = this.first;
        if (this.table) {
          this.table.first = (this.paginaActual - 1) * this.rows;
        }
      }
      this.callUsuariosFiltros(this.first);
    } else {
      this.getUsuarios(this.first);
    }
  }

  getUsuarios(first: number): any {
    this.spinner.show();
    this.usuarioService
      .getUsuarios(String(Math.abs(first)), String(this.rows))
      .subscribe(
        (data: any) => {
          this.cargarDatosTabla(data);
          this.spinner.hide();
        },
        (error: any) => {
          console.error(error);
          this.usuarios = [];
          this.spinner.hide();
        }
      );
  }

  private cargarDatosTabla(data: any): any {
    this.usuarios = data.results;
    this.paginaActual = data.currentPage;
    this.paginasTotales = data.totalPages;
    this.usuariosTotales = data.totalResults;
    this.customSort(this.lastSortField);
    $('[data-toggle="tooltip"]').tooltip();
  }

  eliminarUsuario(email: string, index: number): void {
    const data = {
      title: 'Eliminar usuario',
      msg: `¿Desea eliminar al usuario ${email}?`,
      buttons: [
        { title: 'Aceptar', class: 'btn-success', action: '1' },
        { title: 'Cancelar', class: 'btn-danger' },
      ] as ButtonModalInfo[],
    } as ModalInfo;
    this.dialog
      .open(ModalInfoComponent, { disableClose: true, autoFocus: false, data })
      .afterClosed()
      .subscribe((resp) => {
        if (resp) {
          this.spinner.show();
          this.usuarioService
            .deleteUsuario(email, this.sesionService.getUser().email)
            .subscribe(
              () => {
                if (this._datosFiltro) {
                  this.callUsuariosFiltros(this.first);
                } else {
                  this.getUsuarios(this.first);
                }
              },
              (error: any) => {
                const result = {
                  title: 'Información de error',
                  msg: 'No es posible la baja: ' + error.error,
                };
                this.dialog.open(ModalInfoComponent, {
                  disableClose: true,
                  autoFocus: false,
                  data: result,
                });
                console.log(error);
                this.spinner.hide();
              }
            );
        }
      });
  }

  private callUsuariosFiltros(offset: number): any {
    this.spinner.show();

    const requestBody = {
      emails: this._datosFiltro.emails,
      nombres: this._datosFiltro.nombres,
      estado: this._datosFiltro.estado === 'activos' ? 'A' : 'B',
      empresas: this._datosFiltro.empresas?.map(
        (empresa: Empresa) => empresa.id
      ),
      dnis: this._datosFiltro.dnis,
      apellidos: this._datosFiltro.apellidos,
      tiposSolicitantes: this._datosFiltro.tiposSolicitantes?.map((tipo: TipoSolicitante) => tipo.id),
      roles: this._datosFiltro.roles?.map((rol: Rol) => rol.id),
      puestos: this._datosFiltro.puestos?.map((puesto: Puesto) => puesto.id),
      zonas: this._datosFiltro.zonas?.map((zona: Zona) => zona.id),
      provincias: this._datosFiltro.provincias?.map(
        (provincia: Provincia) => provincia.id
      ),
      ambitos: this._datosFiltro.ambitos?.map((ambito: Ambito) => ambito.id),
      actividades: this._datosFiltro.actividades?.map(
        (actividad: Actividades) => actividad.id
      ),
      lineasVenta: this._datosFiltro.lineasVenta?.map(
        (linea: LineasVenta) => linea.id
      ),
      fechaAltaInicio: this._datosFiltro.fechaAltaInicio,
      fechaAltaFin: this._datosFiltro.fechaAltaFin,
      fechaBajaInicio: this._datosFiltro.fechaBajaInicio,
      fechaBajaFin: this._datosFiltro.fechaBajaFin,
    } as UsuarioFiltrosRequest;

    this.usuarioService
      .buscarUsuariosPorFiltro(requestBody, String(offset), String(this.rows))
      .subscribe(
        (data: any) => {
          this.cargarDatosTabla(data);
          this.spinner.hide();
        },
        (error: any) => {
          console.error(error);
          this.spinner.hide();
        }
      );
  }

  private hasChanged(sort: string, order: number): boolean {
    return (
      this.lastSortField.sortField !== sort ||
      this.lastSortField.sortOrder !== order
    );
  }

  private inicializarFiltro(): void {
    if (!this.datosFiltro) {
      this._datosFiltro = { estado: 'activos' };
    }
  }

  private getUsers(offset: number): void {
    this.datosFiltro.currentPage = offset;
    this.datosFiltro.recordsPerPage = this.rows;
    this.usuarioService.setDatosFiltros(this.datosFiltro);
    if (this.datosFiltro) {
      this.callUsuariosFiltros(offset);
    } else {
      this.getUsuarios(offset);
    }
  }

  nextPage(e: any, table: Table): void {
    this.inicializarFiltro();
    if (this.rows !== e.rows) {
      this.rows = e.rows;
      e.first = 0;
      this.paginaActual = 1;
      const offset = e.first / this.rows + 1;
      table.first = offset;
      this.rows = e.rows;
      this.getUsers(offset);
    } else {
      // verificar si se ha seleccionado un filtro nuevo, en caso contrario, está paginando
      if (
        (e.sortField && !this.lastSortField) ||
        (e.sortField && this.hasChanged(e.sortField, e.sortOrder))
      ) {
        this.lastSortField = {
          sortField: e.sortField,
          sortOrder: e.sortOrder,
        };
        this.customSort(this.lastSortField);
        table.first = (this.paginaActual - 1) * this.rows;
        this.usuarioService.setDatosFiltros(this.datosFiltro);
      } else {
        const offset = e.first / this.rows + 1;
        this.rows = e.rows;
        this.getUsers(offset);
      }
    }
  }

  private calculateArray(usuario: Usuario): string[] {
    if (usuario.zonas && usuario.regiones) {
      const zonas = usuario.zonas.map((zona) => zona.descripcion);
      const regiones = usuario.regiones?.map((region) => region.descripcion);
      return usuario.zonas.length > 0 ? zonas : regiones;
    } else {
      return [''];
    }
  }

  private customSort(sort: any): void {
    if (sort && this.usuarios) {
      this.usuarios.sort((a: Usuario, b: Usuario) => {
        switch (sort.sortField) {
          case this.cols[0].field:
            return Utils.sortString(a.nombre, b.nombre, sort.sortOrder);
          case this.cols[1].field:
            return Utils.sortString(
              a?.apellido || '',
              b?.apellido || '',
              sort.sortOrder
            );
          case this.cols[2].field:
            return Utils.sortString(
              a?.rol?.descripcion || '',
              b?.rol?.descripcion || '',
              sort.sortOrder
            );
          case this.cols[3].field:
            return Utils.sortString(
              a?.puesto?.descripcion || '',
              b?.puesto?.descripcion || '',
              sort.sortOrder
            );
          case this.cols[4].field:
            return Utils.sortListField(
              this.calculateArray(a),
              this.calculateArray(b),
              sort.sortOrder
            );
          case this.cols[5].field:
            return Utils.sortListField(
              a?.actividades?.map((actividad) => actividad.descripcion) || [''],
              b?.actividades?.map((actividad) => actividad.descripcion) || [''],
              sort.sortOrder
            );
          default:
            return sort.sortOrder;
        }
      });
    }
  }

  redirectToCreateUser(): void {
    this.router.navigate(['/usuarios/crear']);
  }

  redirectToModifyUser(email: string): void {
    this.router.navigate(['usuarios/actualizar', email]);
  }

  eventoIrAuditoria(usuario: string): void {
    this.irAuditoria.emit(usuario);
  }

  exportarDatos(): void {
    // Hacer llamada al servicio
    if (!this.datosFiltro) {
      this.datosFiltro = {};
    }
    this.spinner.show();
    this.usuarioService.exportar(this.datosFiltro).subscribe((urlRespuesta) => {
      if (urlRespuesta) {
        // Abrir nueva pantalla con el resultado del exportar
        this.spinner.hide();
        window.open(
          urlRespuesta,
          '_blank',
          'location=yes, height=570, width=520, scrollbars=yes, status=yes'
        );
      } else {
        const data = {
          title: 'Información',
          msg: 'No se ha podido hacer la exportación ...',
        };
        this.dialog.open(ModalInfoComponent, {
          disableClose: true,
          autoFocus: false,
          data,
        });
      }
    });
  }

  /**
   * Reactivar usuario dado de baja
   * @param email the email
   */
  activarUsuario(email: string, index: number): void {
    const data = {
      title: 'Activar usuario',
      msg: `¿Desea activar al usuario ${email}?`,
      buttons: [
        { title: 'Aceptar', class: 'btn-success', action: '1' },
        { title: 'Cancelar', class: 'btn-danger' },
      ] as ButtonModalInfo[],
    } as ModalInfo;
    this.dialog
      .open(ModalInfoComponent, { disableClose: true, autoFocus: false, data })
      .afterClosed()
      .subscribe((resp) => {
        if (resp) {
          this.spinner.show();
          this.usuarioService
            .activarUsuario(email, this.sesionService.getUser()?.email)
            .subscribe(
              () => {
                // no añadimos el spinner hide, esta tarea la hará la pantalla de editar cuando cargue los combos
                this.redirectToModifyUser(email);
              },
              (error: any) => {
                console.log(error);
                this.spinner.hide();
              }
            );
        }
      });
  }
}

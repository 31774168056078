import {
  Component,
  Inject,
  Output,
  EventEmitter,
  ViewChild,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { PeticionService } from '../../../servicios/peticion/peticion.service';
import { UsuarioSimple } from '../../../modelos/usuario';
import { forkJoin } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { SubjectsService } from '../../../servicios/subjects/subjects.service';
import { MensajeComponent } from '../../mensaje/mensaje.component';

@Component({
  selector: 'app-modal-autorizar',
  templateUrl: './modal-autorizar.component.html',
  styleUrls: ['./modal-autorizar.component.css'],
})
export class ModalAutorizarComponent {
  verModalAutorizar = false;
  listaAutorizantes: UsuarioSimple[] = [];
  listaInformantes: UsuarioSimple[] = [];
  formulario!: FormGroup;
  idPeticion!: string;

  @Output()
  emitirAutorizar = new EventEmitter();

  @ViewChild('mensaje', { static: false })
  public mensaje!: MensajeComponent;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private peticionService: PeticionService,
    private spinner: NgxSpinnerService,
    private fb: FormBuilder,
    private messageService: MessageService,
    private subjectsService: SubjectsService
  ) {}

  public mostrarModal(idPeticion: string): void {
    this.idPeticion = idPeticion;
    this.messageService.clear();
    this.spinner.show();
    this.listaAutorizantes = [];
    this.listaInformantes = [];
    this.document.body.style.overflowY = 'hidden';
    this.verModalAutorizar = true;

    const llamadas = [];
    llamadas.push(this.peticionService.obtenerAutorizantes(idPeticion));
    llamadas.push(this.peticionService.obtenerInformantes(idPeticion));
    forkJoin(llamadas).subscribe(
      (resp) => {
        this.listaAutorizantes = resp[0];
        this.listaInformantes = resp[1];
        this.initForm();
      },
      (error) => {
        console.error(error);
        this.spinner.hide();
        this.subjectsService.emitirEmitirError(error);
      }
    );
  }

  private initForm(): void {
    this.formulario = this.fb.group({
      autorizante: [null, Validators.required],
      informantes: [[], Validators.required],
    });
    this.spinner.hide();
    this.messageService.add({ key: 'accion', severity: 'info', sticky: true });
  }

  public cerrarModal(): void {
    this.messageService.clear();
    this.verModalAutorizar = false;
    this.formulario.controls.autorizante.setValue(null);
    this.formulario.controls.informantes.setValue([]);
    this.document.body.style.overflowY = 'auto';
  }

  aceptarModal(): void {
    const informantes = this.formulario
      .get('informantes')
      ?.value.map((informante: UsuarioSimple) => informante.email);
    const authRequest = {
      informantes,
      autorizante: this.formulario.get('autorizante')?.value.email,
    };

    this.spinner.show();
    this.peticionService
      .solicitarAutorizacion(this.idPeticion, authRequest)
      .subscribe(
        () => {
          this.cerrarModal();
          this.emitirAutorizar.emit();
        },
        (error) => {
          console.error(error);
          this.spinner.hide();
          this.subjectsService.emitirEmitirError(error);
        }
      );
  }
}

<ngx-spinner></ngx-spinner>
<app-mensaje #mensaje></app-mensaje>
<div class="container margenmenu">
  <p-steps
    [model]="items"
    [(activeIndex)]="activeIndex"
    [readonly]="true"
  ></p-steps>
  <div class="acciones">
    <button
      class="btn btn-outline-secondary"
      pTooltip="{{ textoBotonVolver }}"
      (pointerdown)="fijarElemento($event)"
      (click)="volver()"
    >
      Volver
    </button>
  </div>
</div>

import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { Accion } from 'src/app/modelos/accion';
import { Catalogo } from 'src/app/modelos/catalogo';
import { AccionesService } from 'src/app/servicios/acciones/acciones.service';
import { CatalogosService } from 'src/app/servicios/catalogos/catalogos.service';
import { NotificacionFiltros } from '../../../modelos/notificacion-filtros';
import { NotificadosIntervinientes } from '../../../modelos/notificados-intervinientes';
import { NotificacionesService } from '../../../servicios/notificaciones/notificaciones.service';

@Component({
  selector: 'app-filtro-notificacion',
  templateUrl: './filtro-notificacion.component.html',
  styleUrls: ['./filtro-notificacion.component.css'],
})
export class FiltroNotificacionComponent implements OnInit {
  public datosFiltro!: NotificacionFiltros;

  // Para enviar datos fuera, sólo se hará cuando lancemos un evento. Así que debe haber un disparador
  @Output() desdeFiltroNotificacion = new EventEmitter();
  @Output() loading = new EventEmitter();

  public formulario!: FormGroup;
  public listaTipoPeticiones!: Catalogo[];
  public listaAcciones!: Accion[];
  public listaPorNotificados!: NotificadosIntervinientes[];

  constructor(
    private fb: FormBuilder,
    private catalogoService: CatalogosService,
    private notificacionService: NotificacionesService,
    private accionesService: AccionesService,
    private spinner: NgxSpinnerService
  ) {
    this.listaPorNotificados = [
      { id: 1, descripcion: 'Trabajador' },
      { id: 2, descripcion: 'Autorizador' },
      { id: 3, descripcion: 'Informantes' },
      { id: 4, descripcion: 'Solicitante' },
      { id: 5, descripcion: 'Destinatario' },
    ];
  }

  ngOnInit(): void {
    this.datosFiltro = this.notificacionService.datosFiltrosNotificacion;
    this.initForm();
  }

  private cargarCatalogos(): void {
    this.catalogoService
      .obtenerTiposPeticiones(undefined, true)
      .subscribe((result) => {
        this.listaTipoPeticiones = result;
      });
  }

  private cargarAcciones(): void {
    this.accionesService.getAccionesCombo().subscribe((result) => {
      this.listaAcciones = result;
    });
  }

  private initForm(): void {
    this.cargarCatalogos();
    this.cargarAcciones();
    if (this.datosFiltro) {
      this.formulario = this.initFormWithFilters();
      this.buscarFiltro(true);
    } else {
      this.formulario = this.initEmptyForm();
    }
    this.loading.emit(false);
    this.spinner.hide();
  }

  initEmptyForm(): FormGroup {
    return this.fb.group({
      tipoPeticion: [[]],
      accion: [[]],
      notificado: [],
      activo: true,
    });
  }

  initFormWithFilters(): FormGroup {
    this.desdeFiltroNotificacion.emit(this.datosFiltro);
    return this.fb.group({
      tipoPeticion: [
        this.datosFiltro.tipoPeticion ? this.datosFiltro.tipoPeticion : [],
      ],
      accion: [this.datosFiltro.accion ? this.datosFiltro.accion : []],
      notificado: [this.datosFiltro.notificado],
      activo: this.datosFiltro.activo !== null ? this.datosFiltro.activo : true,
    });
  }

  public reset(): void {
    this.formulario.reset();
    this.formulario.get('activo')?.setValue(true);
    this.buscarFiltro(true);
  }

  public buscarFiltro(primeraCarga: boolean): void {
    this.datosFiltro = this.buscarNotificaciones(primeraCarga);
    this.notificacionService.setDatosFiltrosNotificacion(this.datosFiltro);
    this.desdeFiltroNotificacion.emit(this.datosFiltro);
  }

  public buscarNotificaciones(primeraCarga: boolean): NotificacionFiltros {
    return {
      tipoPeticion: this.formulario.get('tipoPeticion')?.value,
      accion: this.formulario.get('accion')?.value,
      notificado: this.formulario.get('notificado')?.value,
      currentPage: primeraCarga ? this.getCurrentPage() : 1,
      recordsPerPage: this.datosFiltro
        ? this.datosFiltro.recordsPerPage
        : undefined,
      activo: this.formulario.get('activo')?.value,
    };
  }

  private getCurrentPage(): number | undefined {
    return this.datosFiltro ? this.datosFiltro.currentPage : undefined;
  }
}

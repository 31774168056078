export default class Utils {

  static sortString(a: string, b: string, sort: number): number {
    return (sort === 1) ? a.localeCompare(b, 'en') : b.localeCompare(a, 'en');
  }

  static sortNumber(a: number, b: number, sort: number): number {
    if (sort !== 1 ) {
      [a, b] = [b, a];
    }
    if (a < b) { return -1; }
    if (b > a) { return 1; }
    return 0;
  }

  static sortListField(arrayA: any[], arrayB: any[], sort: number): number{
    let comparacion = sort;
    let match = false;



    if (JSON.stringify(arrayA) === JSON.stringify(arrayB)){
      return 1;
    }

    if (arrayA.length < arrayB.length){
      const dif = arrayB.length - arrayA.length;
      for (let i = 0; i < dif; i++) {
        arrayA.push('0');
      }
    }

    if (arrayB.length < arrayA.length){
      const dif = arrayA.length - arrayB.length;
      for (let i = 0; i < dif; i++) {
        arrayB.push('0');
      }
    }

    arrayA.forEach( (a1: any, index: number) => {
      if (a1 !== arrayB[index] && !match){
        match = true;
        comparacion = this.sortString(a1, arrayB[index], sort);
      }
    });

    return comparacion;
  }

  static isInformedString(name: string): boolean{
    return (name !== undefined && name !== null && name !== '');
  }

  static isObjectNotNull(aux: any): boolean{
    return (aux !== undefined && aux !== null);
  }

  static base64ToFile(fileBase64: string, name: string): void{
    const source = `data:${this.calculateMediaType(name.split('.')[1])};base64,${fileBase64}`;
    const link = document.createElement('a');
    link.href = source;
    link.download = name;
    link.click();
  }

  static calculateMediaType(format: string): string{
    switch (format.toUpperCase()){
      case 'PDF':
        return 'application/pdf';
      case 'DOCX':
        return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
      case 'DOC':
        return 'application/msword';
      case 'XLSX':
        return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      case 'XLS':
        return 'application/vnd.ms-excel';
      default:
        return `image/${format}`;
    }
  }

  static getDate(date: string): Date{
    const arrDate = date.split('-');
    return new Date(+arrDate[0], +arrDate[1] - 1, +arrDate[2]);
  }

  static numberToBoolean(data: number | null | undefined): boolean {
    return data === 1;
  }

  static booleanToNumber(data: boolean): number {
    return data ? 1 : 0;
  }

  static stringNotNull(data: string | undefined): string {
    return data || '';
  }

  static numberNotNull(data: number | undefined): number {
    return data || 0;
  }

}

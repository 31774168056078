import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class Paginacion {

  public annadirParametrosPaginacion(page: string, size: string, sort?: string, direction?: string): HttpParams {

    let params = new HttpParams().set('', '');
    if (page !== undefined) {
      params = params.append('page', page);
    }
    if (size !== undefined) {
      params = params.append('size', size);
    }
    if (sort !== undefined && direction !== undefined) {
      params = params.append('sort', sort + ',' + direction);
    }

    return params;

  }

}

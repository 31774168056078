<div class="container">
    <h3>{{ titulo }}</h3>
    <form [formGroup]="formulario" *ngIf="formulario">
        <div class="row">
            <div class="mb-3 col-sm-12 col-lg-4">
                <label for="accion" class="form-label accionLabel">Acciones</label>
                <p-dropdown id="accion" styleClass="form-control p-0" placeholder="Seleccione una acción" [showClear]="true" [options]="listaAcciones" formControlName="accion" optionLabel="label" [filter]="true">
                </p-dropdown>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-auto">
                <label for="fechaDesde" class="form-label accionLabel">Fecha Desde</label
      >
      <input
        class="form-control"
        type="date"
        value=""
        id="fechaDesde"
        styleClass="form-control p-0"
        formControlName="fechaDesde"
        trim="blur"
      />
    </div>
    <div class="col-sm-auto">
      <label for="fechaHasta" class="form-label accionLabel"
        >Fecha Hasta</label
      >
      <input
        class="form-control"
        type="date"
        value=""
        id="fechaHasta"
        styleClass="form-control p-0"
        formControlName="fechaHasta"
        trim="blur"
      />
      <small
        class="form-text text-danger"
        *ngIf="
          formulario.get('fechaHasta')!.touched &&
          validarFechas(
            formulario.get('fechaDesde')?.value,
            formulario.get('fechaHasta')?.value
          )
        "
        >* La fecha Hasta debe ser mayor que la fecha Desde</small
      >
    </div>
  </div>
  <div class="mt-2 mb-3 d-flex izquierda">
    <button
      class="btn limpiarBoton customButton fa fa-eraser"
      (click)="reset()"
    >
      Limpiar
    </button>
    <button
      id="buscar"
      type="submit"
      class="btn btn-outline-primary customButton fa fa-search buscar"
      (click)="buscarFiltro('1')"
    >
      Buscar
    </button>
  </div>
</form>
<p-table id="tabla-auditoria-general" #dt2 [value]="usuarios" [lazy]="true" [showCurrentPageReport]="true" styleClass="p-datatable-responsive-demo" [paginator]="true" [rows]="rows" [totalRecords]="usuariosTotales" [columns]="cols" [globalFilterFields]="cols"
sortField="fechaHora" [sortOrder]="1" currentPageReportTemplate="Página {{ paginaActual }} de {{
paginasTotales === 0 ? 1 : paginasTotales
}}" [rowsPerPageOptions]="usuariosPorPagina" (onLazyLoad)="nextPage($event, dt2)" [(first)]="first" >
<ng-template pTemplate="caption" class="caption">
<form [formGroup]="formulario" *ngIf="formulario">
  <div class="d-flex justify-content-end">
    <div class="has-search">
      <span class="fa fa-search form-control-feedback"></span>
      <input
        [disabled]="!desdeDetalle"
        type="email"
        class="form-control"
        id="email"
        formControlName="email"
        autocomplete="off"
        placeholder="Email actor / afectado"
        (ngModelChange)="keyPress()"
      />
    </div>
  </div>
</form>
</ng-template>
<ng-template pTemplate="header">
<tr>
    <th *ngFor="let col of cols" scope="{{ col.field }}" pSortableColumn="{{ col.field }}" pResizableColumn>
        <div class="p-d-flex p-jc-between p-ai-center" *ngIf="col.field !== 'observaciones'">
            {{ col.header }}
            <p-sortIcon ariaLabel="Activar para ordenar" ariaLabelDesc="Activar para ordenar en orden descendiente" ariaLabelAsc="Activar para ordenar en orden ascendiente" [field]="col.field"></p-sortIcon>
        </div>
        <div class="p-d-flex p-jc-between p-ai-center" *ngIf="col.field === 'observaciones'">
            {{ col.header }}
        </div>
    </th>
</tr>
</ng-template>
<ng-template pTemplate="body" let-usuarios let-i="rowIndex" >
<tr>
  <td>
    <span class="p-column-title">ACTOR</span>{{ usuarios.emailActor }}
  </td>
  <td *ngIf="usuarios.accion == 'A'">
    <span class="p-column-title">ACCIÓN</span>Crear
  </td>
  <td *ngIf="usuarios.accion == 'B'">
    <span class="p-column-title">ACCIÓN</span>Eliminar
  </td>
  <td *ngIf="usuarios.accion == 'R'">
    <span class="p-column-title">ACCIÓN</span>Reactivar
  </td>
  <td *ngIf="usuarios.accion == 'M'">
    <span class="p-column-title">ACCIÓN</span>Editar
  </td>
  <td>
    <span class="p-column-title">AFECTADO</span>{{ usuarios.emailAfectado }}
  </td>
  <td>
    <span class="p-column-title">FECHA</span>{{ usuarios.fecha | dateFormatPipe }}
  </td>
  <td class="text-center">
    <span class="p-column-title"></span>
    <button *ngIf="usuarios.observaciones && usuarios.observaciones != ''" pTooltip="Detalles" tooltipPosition="right" class="btn btn-sm btn-outline-info" (click)="mostrarObservaciones(usuarios.observaciones)">
    <em class="fa fa-eye"></em>
    </button>
</td>
</tr>
<hr class="item-list-separator" />
</ng-template>
<ng-template pTemplate="emptymessage">
<tr>
    <td colspan="7">No hay registros</td>
</tr>
</ng-template>
<ng-template pTemplate="paginatorleft"> </ng-template>
  <ng-template pTemplate="paginatorright">
      <p class="pageFooterLeft">Registros totales: {{ usuariosTotales }}</p>
  </ng-template>
</p-table>
</div>

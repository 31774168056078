import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Constantes } from 'src/app/utils/constantes';

@Injectable({
  providedIn: 'root'
})
export class AuditoriasUsuarioService {

  constructor(
    private http: HttpClient,
    private constantes: Constantes
  ) { }

  buscarUsuariosAccion(accion: string, email: string, fechaDesde: string, fechaHasta: string,
                       offset: string, limit: string): Observable<any> {
    let params = new HttpParams().set('offset', offset).append('limit', limit);
    if (accion){
      params = params.append('accion', accion);
    }
    if (email){
      params = params.append('email', email);
    }
    if (fechaDesde){
      params = params.append('fechaDesde', fechaDesde);
    }
    if (fechaHasta){
      params = params.append('fechaHasta', fechaHasta);
    }
    return this.http.get(this.constantes.ENDPOINT_API_USUARIOS_AUDITORIA_BUSCAR, {params});
  }
}

import { Injectable, NgZone, Injector } from '@angular/core';
import { SesionService } from '../sesion/sesion.service';
import { Usuario } from 'src/app/modelos/usuario';
import { Router } from '@angular/router';
import { HandlerErrorService } from '../errores/handler-error.service';
import { UsuariosService } from '../usuarios/usuarios.service';
import { RolesService } from '../rol/roles.service';
import { RolPermisos } from '../../modelos/rol-permisos';
import { forkJoin } from 'rxjs';
import { UsuarioTerritorio } from '../../modelos/usuario';
import { NgxSpinnerService } from 'ngx-spinner';
import { SubjectsService } from '../subjects/subjects.service';
import {
  GoogleCredentials,
  GoogleSignInResponse,
} from 'src/app/modelos/google-login';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  //  private googleAuthService!: GoogleAuthService;
  private sessionService!: SesionService;
  private handleError!: HandlerErrorService;
  private usuariosService!: UsuariosService;
  private rolesService!: RolesService;
  private spinner!: NgxSpinnerService;
  private subjectsService!: SubjectsService;

  constructor(
    private injector: Injector,
    private ngZone: NgZone,
    private router: Router
  ) {
    this.initServices();
  }

  private initServices(): void {
    this.sessionService = this.injector.get<SesionService>(SesionService);
    this.handleError =
      this.injector.get<HandlerErrorService>(HandlerErrorService);
    this.usuariosService = this.injector.get<UsuariosService>(UsuariosService);
    this.rolesService = this.injector.get<RolesService>(RolesService);
    this.spinner = this.injector.get<NgxSpinnerService>(NgxSpinnerService);
    this.subjectsService = this.injector.get<SubjectsService>(SubjectsService);
  }

  public getUserInfo(): void {
    const usuario = this.sessionService.getUser();
    const llamadas = [];
    llamadas.push(this.usuariosService.getUsuarioFull(usuario.email));
    llamadas.push(this.rolesService.getRolesPermisos());
    llamadas.push(this.usuariosService.getTerritorios(usuario.email));
    forkJoin(llamadas).subscribe((resp) => {
      const rolPermiso = resp[1].find(
        (rol: RolPermisos) => rol.id === resp[0].rol?.id
      );
      this.sessionService.setUser(resp[0]);
      this.sessionService.setPermisos(rolPermiso);
      this.sessionService.setTerritorios(resp[2]);
      this.sessionService.setSesionCaducada(false);
    });
  }

  public signOutLS(caducado: boolean): void {
    this.ngZone.run(() => this.router.navigate(['/']));
    this.router.navigate(['/']);
    if (!caducado) {
      localStorage.clear();
    }
    sessionStorage.clear();
  }

  private signInSuccessHandler(
    googleSignInResponse: GoogleSignInResponse,
    usuario: Usuario,
    permisos: RolPermisos,
    territorios: UsuarioTerritorio[]
  ): void {
    this.sessionService.setIdToken(googleSignInResponse.credential);
    this.sessionService.setGoogleUserSignedIn(true);
    this.sessionService.setUser(usuario);
    this.sessionService.setPermisos(permisos);
    this.sessionService.setTerritorios(territorios);
    this.sessionService.setSesionCaducada(false);
    let oldUrl = localStorage.getItem('lastURL');
    if (oldUrl !== null) {
      this.ngZone.run(() => this.router.navigate([`/${oldUrl}`]));
    }
    this.spinner.hide();
  }

  // NEW
  public signInNEW(
    googleSignInResponse: GoogleSignInResponse
  ): Promise<GoogleCredentials> {
    const decodeCredentials: GoogleCredentials = this.decodeJwtResponse(
      googleSignInResponse.credential
    );
    this.spinner.show();
    return new Promise<GoogleCredentials>((resolve, reject) => {
      this.sessionService.setIdToken(googleSignInResponse.credential);
      this.sessionService.setGoogleCredentials(decodeCredentials);
      const llamadas = [];
      llamadas.push(
        this.usuariosService.getUsuarioLogin(
          decodeCredentials.email.toLowerCase()
        )
      );
      llamadas.push(this.rolesService.getRolesPermisos());
      llamadas.push(
        this.usuariosService.getTerritorios(
          decodeCredentials.email.toLowerCase()
        )
      );
      forkJoin(llamadas).subscribe(
        (resp) => {
          if (resp[0] === null) {
            this.spinner.hide();
            const error = {
              error: {
                message: 'El usuario no está dado de alta en la aplicación',
                error: 'Usuario no encontrado',
                status: 404,
              },
            };
            document.getElementById('sidebar')?.setAttribute('class', 'active');
            this.subjectsService.emitirEmitirError(error);
            this.ngZone.run(() => reject(error));
            this.router.navigate(['/']);
          } else {
            const rolPermiso = resp[1].find(
              (rol: RolPermisos) => rol.id === resp[0].rol?.id
            );
            this.signInSuccessHandler(
              googleSignInResponse,
              resp[0],
              rolPermiso,
              resp[2]
            );
            this.ngZone.run(() => resolve(decodeCredentials));
          }
        },
        (error) => {
          this.spinner.hide();
          this.sessionService.setSesionCaducada(true);
          this.handleError.handlerError(error);
          this.ngZone.run(() => reject(error));
        }
      );
    });
  }

  public decodeJwtResponse(token: string): GoogleCredentials {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map(function (c: any) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );
    return JSON.parse(jsonPayload) as GoogleCredentials;
  }
}

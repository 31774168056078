import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuditoriaPeticionRequest } from 'src/app/modelos/auditoria';
import { Constantes } from 'src/app/utils/constantes';

@Injectable({
  providedIn: 'root',
})
export class AuditoriaPeticionesService {
  constructor(private http: HttpClient, private constantes: Constantes) {}

  getAuditorias(
    request: AuditoriaPeticionRequest,
    offset: number,
    limit: number
  ): Observable<any> {
    return this.http.post<any>(
      this.constantes.ENDPOINT_API_PETICIONES_AUDITORIA,
      { ...request, offset, limit }
    );
  }

  exportar(filtros: AuditoriaPeticionRequest): Observable<any> {
    return this.http.post<any>(
      this.constantes.ENDPOINT_API_AUDITORIA_PETICIONES_EXPORTAR,
      {
        ...filtros,
        offset: 0,
        limit: 100000,
      }
    );
  }
}

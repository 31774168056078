import {
  Component,
  Input,
  OnInit,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Table } from 'primeng/table';
import { Notificacion } from 'src/app/modelos/notificacion';
import { NotificacionFiltros } from 'src/app/modelos/notificacion-filtros';
import { Constantes } from 'src/app/utils/constantes';
import { NotificacionesService } from '../../../servicios/notificaciones/notificaciones.service';
import { MensajeComponent } from '../../mensaje/mensaje.component';
import { NotificacionFiltroRequest } from '../../../modelos/notificacion-filtros';
import { Catalogo } from 'src/app/modelos/catalogo';
import { Accion } from '../../../modelos/accion';
import Utils from 'src/app/utils/utils';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ModalInfoComponent } from '../../modals/modal-info/modal-info.component';

declare let $: any;

@Component({
  selector: 'app-tabla-notificaciones',
  templateUrl: './tabla-notificaciones.component.html',
  styleUrls: ['./tabla-notificaciones.component.scss'],
})
export class TablaNotificacionesComponent implements OnInit {
  notificaciones: Notificacion[] = [];
  cols!: any[];
  first = 0;
  rows = 25;
  lastSortField!: any;
  paginasTotales = 0;
  paginaActual = 1;
  notificacionesTotales = 0;
  notificacionesPorPagina!: number[];
  loading = true;
  selectedNotification: any;
  @ViewChild('mensaje', { static: false })
  public mensaje!: MensajeComponent;

  @ViewChildren('dt1')
  public table!: Table;

  // tslint:disable-next-line:variable-name
  private _datosFiltro!: NotificacionFiltros;

  @Input()
  set datosFiltro(param: NotificacionFiltros) {
    this._datosFiltro = param;
    this.first = 1;
    if (param) {
      this.rows = this.datosFiltro.recordsPerPage || this.rows;
      this.getNotificaciones(this.first);
    }
  }

  get datosFiltro(): NotificacionFiltros {
    return this._datosFiltro;
  }

  constructor(
    private constantes: Constantes,
    private spinner: NgxSpinnerService,
    public dialog: MatDialog,
    private notificacionesService: NotificacionesService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.initFields();
    if (!this.notificacionesService.getDatosFiltrosNotificacion()) {
      this.getNotificaciones(this.first);
    } else {
      if (this.notificacionesService.getDatosFiltrosNotificacion()) {
        this.datosFiltro =
          this.notificacionesService.getDatosFiltrosNotificacion();
        if (this._datosFiltro) {
          this.calcularSiPaginacionPrevia();
        }
      }
    }
  }

  calcularSiPaginacionPrevia(): void {
    if (this._datosFiltro.recordsPerPage) {
      this.rows = this._datosFiltro.recordsPerPage;
    }
    if (this._datosFiltro.currentPage) {
      this.first = this._datosFiltro.currentPage;
      this.paginaActual = this.first;
      if (this.table) {
        this.table.first = (this.paginaActual - 1) * this.rows;
      }
    }
  }

  private initFields(): any {
    this.cols = [
      { field: 'tipoPeticion', header: 'ID TIPO PETICIÓN', toolTip: '' },
      { field: 'descPeticion', header: 'TIPO PETICIÓN', toolTip: '' },
      { field: 'accion', header: 'ACCIÓN', toolTip: '' },
      { field: 'plantilla', header: 'PLANTILLA', toolTip: '' },
      { field: 'trabajador', header: 'T', toolTip: 'Trabajador afectado' },
      { field: 'autorizador', header: 'A', toolTip: 'Autorizante' },
      { field: 'informantes', header: 'I', toolTip: 'Informantes' },
      { field: 'solicitante', header: 'S', toolTip: 'Solicitante' },
      { field: 'destinatario', header: 'D', toolTip: 'Destinatario' },
      { field: 'acciones', header: 'ACCIONES' },
    ];
    this.notificacionesPorPagina = this.constantes.numeroUsuariosPorPagina;
    this.datosFiltro = { activo: true };
  }

  getNotificaciones(first: number): any {
    const tipos = this.updateFiltroTipos();
    const acciones = this.updateFiltroActividades();
    const activo = this._datosFiltro.activo;
    console.log(this._datosFiltro);
    const request = {
      tipos,
      acciones,
      trabajador: this.updateFiltroCheckIntertiviniente(1),
      autorizador: this.updateFiltroCheckIntertiviniente(2),
      informantes: this.updateFiltroCheckIntertiviniente(3),
      solicitante: this.updateFiltroCheckIntertiviniente(4),
      destinatario: this.updateFiltroCheckIntertiviniente(5),
      activo,
    } as NotificacionFiltroRequest;

    this.spinner.show();
    this.notificacionesService
      .obtenerNotificaciones(first, this.rows, request)
      .subscribe(
        (data: any) => {
          this.cargarDatosTabla(data);
          if (this.table) {
            this.table.first = (this.paginaActual - 1) * this.rows;
          }
          this.spinner.hide();
        },
        (error: any) => {
          console.error(error);
          this.notificaciones = [];
          this.spinner.hide();
        }
      );
  }

  private updateFiltroCheckIntertiviniente(id: number): any {
    if (this._datosFiltro) {
      return this._datosFiltro.notificado?.find((trab) => trab.id === id) ===
        undefined
        ? undefined
        : 1;
    } else {
      return undefined;
    }
  }

  private updateFiltroTipos(): string[] {
    return this._datosFiltro && this._datosFiltro.tipoPeticion
      ? this._datosFiltro.tipoPeticion.map(
          (catalogo: Catalogo) => catalogo.id || ''
        )
      : [];
  }

  private updateFiltroActividades(): number[] {
    return this._datosFiltro && this._datosFiltro.accion
      ? this._datosFiltro.accion.map((actividad: Accion) => actividad.id)
      : [];
  }

  aceptar(): void {
    if (this.selectedNotification) {
      this.spinner.show();
      this.notificacionesService
        .cambioEstado(this.selectedNotification)
        .subscribe(
          () => {
            this.spinner.hide();
            this.selectedNotification = null;
            this.first = 1;
            this.getNotificaciones(this.first);
          },
          (error) => {
            console.log(error.message);
            this.selectedNotification = null;
            this.spinner.hide();
            this.mensaje.showErrorPeticion(
              'No se ha podido guardar la petición.'
            );
          }
        );
    }
  }
  showModal(notificacion: Notificacion): void {
    this.selectedNotification = notificacion;
    this.mensaje.showConfirmacionPeticion('¿Desea realizar la acción?');
  }

  private cargarDatosTabla(data: any): any {
    this.notificaciones = data.results;
    this.paginaActual = data.currentPage;

    this.paginasTotales = data.totalPages;
    this.notificacionesTotales = data.totalResults;
    this.customSort(this.lastSortField);
    $('[data-toggle="tooltip"]').tooltip();
  }

  nextPage(e: any, table: Table): void {
    if (this.rows !== e.rows) {
      this.rows = e.rows;
      e.first = 0;
      this.paginaActual = 1;
      const offset = e.first / this.rows + 1;
      table.first = offset;
      this.rows = e.rows;
      this.datosFiltro.currentPage = offset;
      this.datosFiltro.recordsPerPage = this.rows;
      this.notificacionesService.setDatosFiltrosNotificacion(this.datosFiltro);
      this.getNotificaciones(offset);
    } else {
      // verificar si se ha seleccionado un filtro nuevo, en caso contrario, está paginando
      if (
        (e.sortField && !this.lastSortField) ||
        (e.sortField && this.hasChanged(e.sortField, e.sortOrder))
      ) {
        this.lastSortField = {
          sortField: e.sortField,
          sortOrder: e.sortOrder,
        };
        this.customSort(this.lastSortField);
        this.notificacionesService.setDatosFiltrosNotificacion(
          this._datosFiltro
        );
        table.first = (this.paginaActual - 1) * this.rows;
      } else {
        const offset = e.first / this.rows + 1;
        this.rows = e.rows;
        this.datosFiltro.currentPage = offset;
        this.datosFiltro.recordsPerPage = this.rows;
        this.notificacionesService.setDatosFiltrosNotificacion(
          this.datosFiltro
        );
        this.getNotificaciones(offset);
      }
    }
  }

  private hasChanged(sort: string, order: number): boolean {
    return (
      this.lastSortField.sortField !== sort ||
      this.lastSortField.sortOrder !== order
    );
  }

  private customSort(sort: any): void {
    if (sort && this.notificaciones) {
      this.notificaciones.sort((a: Notificacion, b: Notificacion) => {
        switch (sort.sortField) {
          case this.cols[0].field:
            return Utils.sortString(
              a.tipoPeticion,
              b.tipoPeticion,
              sort.sortOrder
            );
          case this.cols[1].field:
            return Utils.sortString(
              a.descPeticion,
              b.descPeticion,
              sort.sortOrder
            );
          case this.cols[2].field:
            return Utils.sortString(a.accion, b.accion, sort.sortOrder);
          case this.cols[3].field:
            return Utils.sortString(a.plantilla, b.plantilla, sort.sortOrder);
          default:
            return sort.sortOrder;
        }
      });
    }
  }

  redirectToNotificacion(idTipoPeticion?: string, idAccion?: number): void {
    if (idTipoPeticion && idAccion) {
      this.router.navigate([
        '/notificaciones',
        idTipoPeticion,
        'acciones',
        idAccion,
      ]);
    } else {
      this.router.navigate(['/notificaciones/crear']);
    }
  }

  exportarDatos(): void {
    this.spinner.show();
    const tipos = this.updateFiltroTipos();
    const acciones = this.updateFiltroActividades();
    const request = {
      tipos,
      acciones,
      trabajador: this.updateFiltroCheckIntertiviniente(1),
      autorizador: this.updateFiltroCheckIntertiviniente(2),
      informantes: this.updateFiltroCheckIntertiviniente(3),
      solicitante: this.updateFiltroCheckIntertiviniente(4),
      destinatario: this.updateFiltroCheckIntertiviniente(5),
    } as NotificacionFiltroRequest;
    this.notificacionesService.exportar(request).subscribe((urlRespuesta) => {
      if (urlRespuesta) {
        // Abrir nueva pantalla con el resultado del exportar
        this.spinner.hide();
        window.open(
          urlRespuesta.url,
          '_blank',
          'location=yes, height=570, width=520, scrollbars=yes, status=yes'
        );
      } else {
        const data = {
          title: 'Información',
          msg: 'No se ha podido hacer la exportación ...',
        };
        this.spinner.hide();
        this.dialog.open(ModalInfoComponent, {
          disableClose: true,
          autoFocus: false,
          data,
        });
      }
    });
  }
}

<div class="container">
  <fieldset>
    <legend>Buscar por ...</legend>
    <form [formGroup]="formulario">
      <div class="row mb-3">
        <div class="col-sm-12 col-lg-6">
          <label for="id" class="form-label">Id de petición</label>
          <input
            type="text"
            autocomplete="off"
            class="form-control apellido"
            id="id"
            formControlName="idPeticion"
            placeholder="Buscar por id de petición"
          />
        </div>

        <div class="col-sm-12 col-lg-6">
          <div id="chips">
            <label for="usuario" class="form-label">Usuario</label>
            <mat-chip-list aria-label="Selección Emails">
              <div>
                <input
                  type="text"
                  autocomplete="off"
                  class="form-control apellido"
                  id="usuario"
                  formControlName="usuario"
                  placeholder="Introduzca usuario a buscar"
                  (input)="buscarEmail($event)"
                />
                <small
                  class="form-text text-danger"
                  *ngIf="
                    listaEmails.length === 0 &&
                    !esEmaPrimeraBusqueda &&
                    this.formulario.get('variosEmails')?.value.length === 0
                  "
                  >* No se han encontrado resultados</small
                >
              </div>
              <div class="col-sm-12 col-lg-12 mb-3">
                <mat-chip
                  class="selected-chip"
                  *ngFor="
                    let emailInput of this.formulario.get('variosEmails')
                      ?.value;
                    let i = index
                  "
                  [removable]="removable"
                  (removed)="removeEmailForm(emailInput, i)"
                >
                  {{ emailInput }}
                  <mat-icon matChipRemove *ngIf="removable">X</mat-icon>
                </mat-chip>
              </div>
              <div class="col-sm-12 col-lg-12">
                <mat-chip-list>
                  <mat-chip
                    *ngFor="let email of listaEmails; let i = index"
                    [selected]="email.state"
                    selectable
                    multiple
                    [removable]="removable"
                    (removed)="removeEmail(i)"
                    [value]="email"
                    [multiple]="true"
                    (click)="email.state = !email.state; addEmail(email, i)"
                  >
                    {{ email.email }}
                    <mat-icon matChipRemove *ngIf="removable">X</mat-icon>
                  </mat-chip>
                </mat-chip-list>
              </div>
            </mat-chip-list>
          </div>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-sm-12 col-lg-4">
          <label for="accion" class="form-label">Acciones</label>
          <p-multiSelect
            id="accion"
            filterPlaceHolder="Buscar"
            [defaultLabel]="'Seleccionar acción'"
            optionLabel="descripcion"
            optionValue="id"
            [options]="constantes.listaAcciones"
            formControlName="acciones"
            [filter]="true"
            styleClass="form-control p-0"
          ></p-multiSelect>
        </div>
        <div class="col-sm-12 col-lg-4">
          <label for="initDate" class="form-label">Fecha desde</label>
          <input
            class="form-control"
            type="date"
            id="initDate"
            formControlName="initDate"
            trim="blur"
          />
        </div>
        <div class="col-sm-12 col-lg-4">
          <label for="endDate" class="form-label">Fecha hasta</label>
          <input
            class="form-control"
            type="date"
            id="endDate"
            formControlName="endDate"
            trim="blur"
          />
          <small
            class="form-text text-danger"
            *ngIf="
              formulario.get('endDate')!.touched &&
              validarFechas(
                formulario.get('initDate')?.value,
                formulario.get('endDate')?.value
              )
            "
            >* La fecha hasta debe ser mayor que la fecha desde</small
          >
        </div>
      </div>
    </form>
  </fieldset>
  <div class="actions">
    <button
      class="btn limpiarBoton customButton fa fa-eraser"
      (click)="clean()"
    >
      Limpiar
    </button>
    <button
      class="btn btn-outline-primary customButton fa fa-search buscar"
      (click)="search()"
      [disabled]="formulario.invalid"
    >
      Buscar
    </button>
  </div>
</div>

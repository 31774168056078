import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Puesto } from 'src/app/modelos/puesto';
import { PuestosService } from 'src/app/servicios/puestos/puestos.service';
import { SesionService } from 'src/app/servicios/sesion/sesion.service';
import { GruposSoporte } from '../../../modelos/grupossoporte';
import { GrupossoporteService } from '../../../servicios/grupossoporte/grupossoporte.service';
import { MensajeComponent } from '../../mensaje/mensaje.component';

@Component({
  selector: 'app-creargruposoporte',
  templateUrl: './creargruposoporte.component.html',
  styleUrls: ['./creargruposoporte.component.css']
})
export class CreargruposoporteComponent implements OnInit {

  public formulario!: FormGroup;
  title = '';
  public grupoSoporte!: GruposSoporte;
  public listaPuestos: Puesto[] = [];
  @ViewChild('mensaje', { static: false })
  public mensaje!: MensajeComponent;
  idGrupoSoporte: any;
  edicion = false;

  constructor(private fb: FormBuilder,
              private puestosService: PuestosService,
              private spinner: NgxSpinnerService,
              private sesionService: SesionService,
              private gruposSoporteService: GrupossoporteService,
              private router: Router,
              private acRoute: ActivatedRoute ) { }

  ngOnInit(): void {
    this.spinner.show();
    this.idGrupoSoporte = this.acRoute.snapshot.paramMap.get('id');
    if (this.idGrupoSoporte) {
      this.edicion = true;
      this.title = 'Editar Grupo Soporte';
      this.gruposSoporteService.getGruposSoportebyId(this.idGrupoSoporte).subscribe(
        (resp: any) => {
          this.grupoSoporte = resp;
          this.initForm();
          this.initServices();
          this.spinner.hide();
        },
        error => {
          this.spinner.hide();
          this.mensaje.showError(error);
        });
    } else {
      this.title = 'Crear Grupo Soporte';
      this.grupoSoporte = this.initGrupoSoporte();
      this.initForm();
      this.initServices();
    }


  }

  initServices(): void{
    this.puestosService.getPuestos().subscribe(
      (resp: any) => {
        this.listaPuestos = resp;
        this.spinner.hide();
      },
      error => {
        this.spinner.hide();
        this.mensaje.showError(error);
      });
  }

  private initGrupoSoporte(): GruposSoporte{
    return {
      descripcion: '',
      gruposDestinariosPuestosLst: [],
      gruposOtrosPuestosLst: [],
      detalles: ''
    };
  }

  initForm(): void{
    this.formulario = this.fb.group({
      descripcion: [this.grupoSoporte.descripcion, [Validators.required, this.noWhitespaceValidator, Validators.maxLength(50)]],
      destinatarios: [this.grupoSoporte.gruposDestinariosPuestosLst, Validators.required],
      otros: [this.grupoSoporte.gruposOtrosPuestosLst, Validators.required],
      detalles: [this.grupoSoporte.detalles, [Validators.required, Validators.maxLength(250)]],
    });
    if (this.edicion) {
      this.formulario.get('descripcion')?.disable();
    }
  }

   noWhitespaceValidator(control: FormControl): any {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
}

  redirect(): void {
    this.router.navigate(['/grupos-soporte']);
  }

  callCrear(): void {
    this.gruposSoporteService.crearGrupoSoporte(this.crearObjetoServicios())
    .subscribe((resp: any) => {
      this.mensaje.showMensaje('El grupo se ha creado de forma correcta', '/grupos-soporte');
      this.spinner.hide();
    },
    error => {
      this.spinner.hide();
      this.mensaje.showError(error);
    });
  }

  callEditar(): void {
    const grupoSoporteAux = this.crearObjetoServicios();
    grupoSoporteAux.id = this.grupoSoporte.id;
    this.gruposSoporteService.editarGrupoSoporte(grupoSoporteAux)
    .subscribe((res) => {
      this.mensaje.showMensaje('El grupo se ha modificado de forma correcta', '/grupos-soporte');
      this.spinner.hide();
    },
    error => {
      this.spinner.hide();
      this.mensaje.showError(error);
    });
  }

   crearObjetoServicios(): GruposSoporte {
    this.spinner.show();
    return {
      descripcion: this.formulario.get('descripcion')?.value,
      idAlta: this.sesionService.getUser().email,
      idMod: this.sesionService.getUser().email,
      detalles: this.formulario.get('detalles')?.value,
      gruposDestinariosPuestosLst: this.formulario.get('destinatarios')?.value,
      gruposOtrosPuestosLst: this.formulario.get('otros')?.value
    };
  }

}

import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Injector,
} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Catalogo } from 'src/app/modelos/catalogo';
import { Estado } from 'src/app/modelos/estado';
import { LineasVenta } from 'src/app/modelos/lineas-venta';
import { ActividadesService } from 'src/app/servicios/actividades/actividades.service';
import { CatalogosService } from 'src/app/servicios/catalogos/catalogos.service';
import { EstadosService } from 'src/app/servicios/estados/estados.service';
import { LineasVentaService } from 'src/app/servicios/lineas-venta/lineas-venta.service';
import { PeticionService } from 'src/app/servicios/peticion/peticion.service';
import { SesionService } from 'src/app/servicios/sesion/sesion.service';
import { UsuariosService } from 'src/app/servicios/usuarios/usuarios.service';
import { PeticionActuacionFiltros, Peticion } from '../../../modelos/peticion';
import { Constantes } from '../../../utils/constantes';
import { NgxSpinnerService } from 'ngx-spinner';
import { forkJoin } from 'rxjs';
import { Actividades } from 'src/app/modelos/actividades';
import { Provincia } from 'src/app/modelos/provincia';
import Utils from 'src/app/utils/utils';
import { Puesto } from 'src/app/modelos/puesto';
import { TerritoriosService } from '../../../servicios/territorios/territorios.service';
import { Zona } from 'src/app/modelos/zona';
import { Ambito } from 'src/app/modelos/ambito';
import { Region } from 'src/app/modelos/region';

export interface Tecnico {
  email: string;
}

@Component({
  selector: 'app-filtro-peticion-actuacion',
  templateUrl: './filtro-peticion-actuacion.component.html',
  styleUrls: ['./filtro-peticion-actuacion.component.css'],
})
export class FiltroPeticionActuacionComponent implements OnInit {
  @Output() filtrosPeticion = new EventEmitter();
  @Output() loading = new EventEmitter();

  public formulario!: FormGroup;
  peticion!: Peticion;

  listaAnios: number[] = [];
  listaTecnicos: Tecnico[] = [];
  listaAreas = this.constantes.listaAreaDestino;
  listaPerfiles = this.constantes.listaPuestosTecnicos;
  listaTiposPeticiones: Catalogo[] = [];
  listaUrgencias = this.constantes.listaUrgencia;
  listaEstados: Estado[] = [];
  listaLineasVenta: LineasVenta[] = [];
  listaActividades: Actividades[] = [];
  listaEmails: any[] = [];
  listaProvincias: Provincia[] = [];
  listaZonas: Zona[] = [];
  listaRegiones: Region[] = [];
  public listaAmbitos: Ambito[] = [];
  emailsBuscados = false;

  editordefault: string[] = [];

  public avanzado = false;
  public textoBoton = 'Más filtros';

  private peticionService!: PeticionService;
  public sessionService!: SesionService;
  private catalogoService!: CatalogosService;
  private actividadService!: ActividadesService;
  private lineasService!: LineasVentaService;
  private estadoService!: EstadosService;
  private usuarioService!: UsuariosService;
  private territoriosService!: TerritoriosService;

  public esJefe = false;
  public esTecnico = false;

  public datosFiltro!: PeticionActuacionFiltros;

  public listaApellidos: any[] = [];
  public esApePrimeraBusqueda = true;
  public listaDNIs: any[] = [];
  public esDniPrimeraBusqueda = true;
  public listaNombres: any[] = [];
  public esNamePrimeraBusqueda = true;

  public removable = true;

  esAuditor = false;
  mockPeticionService: any;

  constructor(
    private fb: FormBuilder,
    private constantes: Constantes,
    private injector: Injector,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.initServices();

    if (this.sessionService.getUser().rol?.id === this.constantes.ROL_AUDITOR) {
      this.esAuditor = true;
    }
  }

  private initServicesProperties(): void {
    this.peticionService = this.injector.get<PeticionService>(PeticionService);
    this.sessionService = this.injector.get<SesionService>(SesionService);
    this.catalogoService =
      this.injector.get<CatalogosService>(CatalogosService);
    this.actividadService =
      this.injector.get<ActividadesService>(ActividadesService);
    this.lineasService =
      this.injector.get<LineasVentaService>(LineasVentaService);
    this.estadoService = this.injector.get<EstadosService>(EstadosService);
    this.usuarioService = this.injector.get<UsuariosService>(UsuariosService);
    this.territoriosService =
      this.injector.get<TerritoriosService>(TerritoriosService);
    this.esJefe =
      this.sessionService.getUser().puesto?.id === 2 ||
      this.sessionService.getUser().puesto?.id === 8 ||
      this.sessionService.getUser().rol?.id ===
        this.constantes.ID_ADMINISTRADOR_LOCAL;
    this.esTecnico =
      this.constantes.listaPuestosTecnicos.find(
        (puesto: Puesto) =>
          puesto.id === this.sessionService.getUser().puesto?.id
      ) !== undefined ||
      this.sessionService.getUser().rol?.id === this.constantes.ID_EDITOR ||
      this.sessionService.getUser().rol?.id ===
        this.constantes.ID_ADMINISTRADOR_LOCAL;

    this.datosFiltro = this.peticionService.datosFiltrosActuacion;
  }

  private initServices(): void {
    this.initServicesProperties();
    this.spinner.show();
    const llamadas = [];
    llamadas.push(this.peticionService.obtenerPeticionesFechas());
    llamadas.push(this.catalogoService.obtenerTiposPeticiones('', true));
    llamadas.push(this.actividadService.getActividades(true));
    llamadas.push(this.lineasService.getLineasVenta(true));
    llamadas.push(this.estadoService.getEstados());
    llamadas.push(this.territoriosService.getProvincias());
    llamadas.push(
      this.usuarioService.buscarTecnicos(
        this.sessionService.getUser().puesto?.id || 0,
        this.sessionService.getUser().email
      )
    );
    llamadas.push(this.territoriosService.getZonas());
    llamadas.push(this.territoriosService.getAmbitos());
    llamadas.push(this.territoriosService.getRegiones());

    forkJoin(llamadas).subscribe((resp) => {
      this.listaAnios = resp[0];
      this.listaTiposPeticiones = resp[1];
      this.listaActividades = resp[2];
      this.listaLineasVenta = resp[3];
      this.listaEstados = resp[4];
      this.listaProvincias = resp[5];
      this.listaTecnicos = this.transformTechnicalList(resp[6]);
      this.listaZonas = resp[7];
      this.listaAmbitos = resp[8];
      this.listaRegiones = resp[9];

      this.initForm();
    });
  }

  private initForm(): void {
    this.peticion = this.peticionService.getPeticion();
    if (this.datosFiltro) {
      this.formulario = this.initFormWithFilters();
      this.buscarFiltro();
    } else {
      this.formulario = this.initEmptyForm();

      if (
        this.sessionService.getUser().rol?.id === this.constantes.ID_EDITOR &&
        this.listaTecnicos.length !== 0
      ) {
        this.formulario
          .get('tecnico')
          ?.setValue(this.sessionService.getUser().email);
        this.editordefault.push(this.sessionService.getUser().email);
        this.buscarFiltro();
      }
    }
    this.loading.emit(false);
  }

  initFormWithFilters(): FormGroup {
    this.filtrosPeticion.emit(this.datosFiltro);
    if (this.datosFiltro.destinatario) {
      if (typeof this.datosFiltro.destinatario !== 'string') {
        this.datosFiltro.destinatario.forEach((des) =>
          this.editordefault.push(des)
        );
      } else {
        this.editordefault.push(this.datosFiltro.destinatario);
      }
    }
    return this.fb.group({
      anio: [this.datosFiltro.anio, Validators.required],
      areas: [this.datosFiltro.areas],
      perfiles: [this.datosFiltro.perfiles],
      tecnico: [this.datosFiltro.destinatario],
      idPeticion: [this.datosFiltro.idPeticion],
      tipos: [this.datosFiltro.tipos],
      estados: [this.datosFiltro.estados],
      urgencias: [this.datosFiltro.urgencias],
      provincias: [this.datosFiltro.provincias],
      lineas: [this.datosFiltro.lineas],
      actividades: [this.datosFiltro.actividades],
      email: [''],
      emails: [
        this.datosFiltro.solicitanteEmails
          ? this.datosFiltro.solicitanteEmails
          : [],
      ],
      zonas: [this.datosFiltro.zonas ? this.datosFiltro.zonas : []],
      ambitos: [this.datosFiltro.ambitos ? this.datosFiltro.ambitos : []],
      regiones: [this.datosFiltro.regiones ? this.datosFiltro.regiones : []],
      fechaAltaInicio: [this.datosFiltro.fechaAltaInicio],
      fechaAltaFin: [this.datosFiltro.fechaAltaFin],
      fechaCierreInicio: [this.datosFiltro.fechaCierreInicio],
      fechaCierreFin: [this.datosFiltro.fechaCierreFin],
      cliente: [this.datosFiltro.cliente],
      denominacion: [this.datosFiltro.denominacion],
      apellido: [[]],
      variosApellidos: [
        this.datosFiltro.apellidosTrabajadorAfectado
          ? this.datosFiltro.apellidosTrabajadorAfectado
          : [],
      ],
      dni: [[]],
      variosDNIs: [
        this.datosFiltro.dnisTrabajadorAfectado
          ? this.datosFiltro.dnisTrabajadorAfectado
          : [],
      ],
      nombre: [[]],
      variosNombres: [
        this.datosFiltro.nombresTrabajadorAfectado
          ? this.datosFiltro.nombresTrabajadorAfectado
          : [],
      ],
      busqueda: [this.datosFiltro.busqueda],
      ocultarPeticiones: [
        this.datosFiltro.ocultarPeticiones == 1 ? true : false,
      ],
    });
  }

  initEmptyForm(): FormGroup {
    return this.fb.group({
      anio: [Validators.required],
      areas: [[]],
      perfiles: [[]],
      tecnico: [[]],
      idPeticion: [[]],
      ocultarPeticiones: [1],
      tipos: [[]],
      estados: [[]],
      urgencias: [[]],
      provincias: [[]],
      lineas: [[]],
      actividades: [[]],
      email: [''],
      emails: [[]],
      zonas: [[]],
      ambitos: [[]],
      regiones: [[]],
      fechaAltaInicio: [''],
      fechaAltaFin: [''],
      fechaCierreInicio: [''],
      fechaCierreFin: [''],
      cliente: [''],
      denominacion: [''],
      apellido: [[]],
      variosApellidos: [[]],
      dni: [[]],
      variosDNIs: [[]],
      nombre: [[]],
      variosNombres: [[]],
      busqueda: [''],
    });
  }

  public reset(): void {
    this.formulario.reset();
    this.formulario.controls.anio.setValue(this.listaAnios[0]);
    this.formulario.controls.areas.setValue([]);
    this.formulario.controls.perfiles.setValue([]);
    this.formulario.controls.idPeticion.setValue('');
    this.formulario.controls.tipos.setValue([]);
    this.formulario.controls.estados.setValue([]);
    this.formulario.controls.urgencias.setValue([]);
    this.formulario.controls.provincias.setValue([]);
    this.formulario.controls.lineas.setValue([]);
    this.formulario.controls.actividades.setValue([]);
    this.formulario.controls.email.setValue('');
    this.formulario.controls.emails.setValue([]);
    this.formulario.controls.zonas.setValue([]);
    this.formulario.controls.ambitos.setValue([]);
    this.formulario.controls.regiones.setValue([]);
    this.formulario.controls.fechaAltaInicio.setValue('');
    this.formulario.controls.fechaAltaFin.setValue('');
    this.formulario.controls.fechaCierreInicio.setValue('');
    this.formulario.controls.fechaCierreFin.setValue('');
    this.formulario.controls.cliente.setValue('');
    this.formulario.controls.denominacion.setValue('');
    this.formulario.controls.apellido.setValue('');
    this.formulario.controls.variosApellidos.setValue([]);
    this.formulario.controls.dni.setValue('');
    this.formulario.controls.variosDNIs.setValue([]);
    this.formulario.controls.nombre.setValue('');
    this.formulario.controls.variosNombres.setValue([]);
    this.formulario.controls.busqueda.setValue('');
    this.formulario.controls.ocultarPeticiones.setValue(1);
    this.listaApellidos = [];
    this.listaDNIs = [];
    this.listaNombres = [];
    this.emailsBuscados = false;
    this.listaEmails = [];
    this.buscarFiltro();
    this.esApePrimeraBusqueda = true;
    this.esDniPrimeraBusqueda = true;
    this.esNamePrimeraBusqueda = true;
  }

  public buscarFiltro(): void {
    this.datosFiltro = this.buscarPeticiones();
    this.peticionService.setDatosFiltrosActuacion(this.datosFiltro);
    this.filtrosPeticion.emit(this.datosFiltro);
  }

  private buscarPeticiones(): PeticionActuacionFiltros {
    return {
      destinatario: this.formulario.get('tecnico')?.value,
      anio: this.formulario.get('anio')?.value,
      areas: this.formulario.get('areas')?.value,
      tipos: this.formulario.get('tipos')?.value,
      idPeticion: this.formulario.get('idPeticion')?.value,
      estados: this.formulario.get('estados')?.value,
      urgencias: this.formulario.get('urgencias')?.value,
      provincias: this.formulario.get('provincias')?.value,
      lineas: this.formulario.get('lineas')?.value,
      actividades: this.formulario.get('actividades')?.value,
      solicitanteEmails: this.formulario.get('emails')?.value,
      zonas: this.formulario.get('zonas')?.value,
      ambitos: this.formulario.get('ambitos')?.value,
      regiones: this.formulario.get('regiones')?.value,
      fechaAltaInicio: this.formulario.get('fechaAltaInicio')?.value,
      fechaAltaFin: this.formulario.get('fechaAltaFin')?.value,
      fechaCierreInicio: this.formulario.get('fechaCierreInicio')?.value,
      fechaCierreFin: this.formulario.get('fechaCierreFin')?.value,
      cliente: this.formulario.get('cliente')?.value,
      denominacion: this.formulario.get('denominacion')?.value,
      perfiles: this.formulario.get('perfiles')?.value,
      apellidosTrabajadorAfectado:
        this.formulario.get('variosApellidos')?.value,
      dnisTrabajadorAfectado: this.formulario.get('variosDNIs')?.value,
      nombresTrabajadorAfectado: this.formulario.get('variosNombres')?.value,
      busqueda: this.formulario.get('busqueda')?.value,
      ocultarPeticiones: this.formulario.get('ocultarPeticiones')?.value
        ? 1
        : 0,
    };
  }

  private transformTechnicalList(list: string[]): Tecnico[] {
    return list.map((tecnico) => {
      return {
        email: tecnico,
      } as Tecnico;
    });
  }

  public validarFechas(fechaInicio: string, fechaFin: string): boolean {
    if (fechaInicio && fechaFin) {
      if (
        Utils.getDate(fechaInicio).getTime() > Utils.getDate(fechaFin).getTime()
      ) {
        return true;
      }
    }
    return false;
  }

  mostrarAvanzado(): void {
    if (this.avanzado) {
      this.avanzado = false;
      this.textoBoton = 'Más filtros';
    } else {
      this.avanzado = true;
      this.textoBoton = 'Menos filtros';
    }
  }

  buscarEmails(event: any): any {
    const cadena = event.target.value;
    this.emailsBuscados = false;
    if (!cadena || cadena.length < 3) {
      this.listaEmails = [];
    } else {
      this.usuarioService
        .buscarEmailForm(cadena)
        .subscribe((resp: string[]) => {
          this.emailsBuscados = true;
          this.listaEmails = resp
            .filter(
              (email: string) =>
                !this.formulario.get('emails')?.value.includes(email)
            )
            .map((email: string) => ({ email, state: false }));
        });
    }
  }

  removeEmailForm(email: string, index: number): void {
    this.formulario.get('emails')?.value.splice(index, 1);
    this.listaEmails.push({ email, state: false });
  }

  removeEmail(index: number): void {
    this.listaEmails.splice(index, 1);
    if (this.listaEmails.length === 0) {
      this.formulario.controls.email.setValue('');
    }
  }

  addEmail(item: any, index: number): void {
    if (!this.formulario.get('emails')?.value.includes(item.email)) {
      this.formulario.get('emails')?.value.push(item.email);
    }
    this.removeEmail(index);
  }
  buscarApellido(event: any): any {
    const cadena = event.target.value;
    if (!cadena || cadena.length < 3) {
      this.listaApellidos = [];
      this.esApePrimeraBusqueda = true;
    } else {
      this.peticionService
        .buscarApellidoForm(cadena)
        .subscribe((resp: string[]) => {
          this.listaApellidos = resp.map((apellido: string) => ({
            apellido,
            state: false,
          }));
          this.esApePrimeraBusqueda = false;
        });
    }
  }

  removeApellidoForm(apellidoEliminar: string, index: number): void {
    this.formulario.get('variosApellidos')?.value.splice(index, 1);
    this.listaApellidos.push({ apellido: apellidoEliminar, state: false });
  }

  removeApellido(index: number): void {
    this.listaApellidos.splice(index, 1);
  }

  addApellido(item: any, index: number): void {
    if (
      !this.formulario.get('variosApellidos')?.value.includes(item.apellido)
    ) {
      this.formulario.get('variosApellidos')?.value.push(item.apellido);
    }
    this.removeApellido(index);
  }

  buscarDni(event: any): any {
    const cadena = event.target.value;
    if (!cadena || cadena.length < 3) {
      this.listaDNIs = [];
      this.esDniPrimeraBusqueda = true;
    } else {
      this.peticionService.buscarDniForm(cadena).subscribe((resp: string[]) => {
        this.listaDNIs = resp.map((dni: string) => ({ dni, state: false }));
        this.esDniPrimeraBusqueda = false;
      });
    }
  }

  removeDniForm(dniEliminar: string, index: number): void {
    this.formulario.get('variosDNIs')?.value.splice(index, 1);
    this.listaDNIs.push({ dni: dniEliminar, state: false });
  }

  removeDNI(index: number): void {
    this.listaDNIs.splice(index, 1);
  }

  addDNI(item: any, index: number): void {
    if (!this.formulario.get('variosDNIs')?.value.includes(item.dni)) {
      this.formulario.get('variosDNIs')?.value.push(item.dni);
    }
    this.removeDNI(index);
  }

  buscarNombre(event: any): any {
    const cadena = event.target.value;
    if (!cadena || cadena.length < 3) {
      this.listaNombres = [];
      this.esNamePrimeraBusqueda = true;
    } else {
      this.peticionService
        .buscarNombreForm(cadena)
        .subscribe((resp: string[]) => {
          this.listaNombres = resp.map((nombre: string) => ({
            nombre,
            state: false,
          }));
          this.esNamePrimeraBusqueda = false;
        });
    }
  }

  removeNombreForm(nombreEliminar: string, index: number): void {
    this.formulario.get('variosNombres')?.value.splice(index, 1);
    this.listaNombres.push({ nombre: nombreEliminar, state: false });
  }

  removeNombre(index: number): void {
    this.listaNombres.splice(index, 1);
  }

  addNombre(item: any, index: number): void {
    if (!this.formulario.get('variosNombres')?.value.includes(item.nombre)) {
      this.formulario.get('variosNombres')?.value.push(item.nombre);
    }
    this.removeNombre(index);
  }
}

<p-table
  id="tabla-sla"
  #dt1
  [value]="slas"
  [showCurrentPageReport]="true"
  styleClass="p-datatable-responsive-demo"
  [rows]="rows"
  [totalRecords]="resultadosTotales"
  [columns]="cols"
  [lazy]="true"
  [paginator]="true"
  [globalFilterFields]="cols"
  sortField="cod"
  [sortOrder]="0"
  currentPageReportTemplate="Página {{ paginaActual }} de {{ paginasTotales }}"
  [(first)]="first"
>
  <ng-template pTemplate="caption">
    <button class="btn boton-excel" (click)="exportarDatos()">
      <i class="fa fa-file-excel-o" aria-hidden="true"></i> EXPORTAR
    </button>
  </ng-template>

  <ng-template pTemplate="header">
    <tr>
      <th scope="{{ cols[0].field }}">
        <div class="p-d-flex p-jc-between p-ai-center">
          {{ cols[0].header }}
        </div>
      </th>
      <th scope="{{ cols[1].field }}">
        <div class="p-d-flex p-jc-between p-ai-center">
          {{ cols[1].header }}
        </div>
      </th>
      <th scope="{{ cols[2].field }}">
        <div class="p-d-flex p-jc-between p-ai-center">
          {{ cols[2].header }}
        </div>
      </th>
      <th scope="{{ cols[3].field }}">
        <div
          class="p-d-flex p-jc-between p-ai-center"
          pTooltip="{{ cols[3].toolTip }}"
          tooltipPosition="top"
        >
          {{ cols[3].header }}
        </div>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-sla let-i="rowIndex">
    <tr>
      <td>
        <span class="p-column-title">DESCRIPCIÓN</span>{{ sla.descripcion }}
      </td>
      <td>
        <span class="p-column-title">TIEMPO RESPUESTA</span
        >{{ sla.tiempoRespuesta }} h
      </td>
      <td>
        <span class="p-column-title">TIEMPO RESOLUCIÓN</span
        >{{ sla.tiempoResolucion }} h
      </td>
      <td>
        <span class="p-column-title">PERFIL ESCALADO</span>
        <ng-container>
          <p *ngFor="let p of sla.perfilesEscalados; index as i">
            <span>{{ p.descripcion }}</span>
          </p>
        </ng-container>
      </td>
    </tr>
    <hr class="item-list-separator" />
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="12">No hay resultados</td>
    </tr>
  </ng-template>
</p-table>

import { Component, Injector, OnInit, ViewChildren } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { Table } from 'primeng/table';
import { Sla } from 'src/app/modelos/sla';
import { Slaservice } from 'src/app/servicios/sla/sla.service';
import { Constantes } from 'src/app/utils/constantes';
import { ModalInfoComponent } from '../../modals/modal-info/modal-info.component';

@Component({
  selector: 'app-sla',
  templateUrl: './tabla-sla.component.html',
  styleUrls: ['./tabla-sla.component.scss'],
})
export class TablaSlaComponent implements OnInit {
  slas: Sla[] = [];
  cols!: any[];
  first = 1;
  rows = 25;

  paginasTotales = 0;
  paginaActual = 1;
  resultadosTotales = 0;
  resultadosPorPagina!: number[];

  lastSortField!: any;

  @ViewChildren('dt1')
  public table!: Table;

  private SlaService!: Slaservice;

  constructor(
    private inject: Injector,
    private spinner: NgxSpinnerService,
    private constantes: Constantes,
    public dialog: MatDialog
  ) {
    {
      this.cols = [
        {
          field: 'descripcion',
          header: 'DESCRIPCIÓN',
        },
        { field: 'tiempoRespuesta', header: 'TIEMPO RESPUESTA' },
        { field: 'tiempoResolucion', header: 'TIEMPO RESOLUCION' },
        { field: 'perfilesEscalados', header: 'PERFIL ESCALADO' },
      ];
      this.resultadosPorPagina = this.constantes.numeroUsuariosPorPagina;
    }
  }

  ngOnInit(): void {
    this.initServices();

    this.callSlaNoFiltros(this.first);
  }

  private initServices(): void {
    this.SlaService = this.inject.get<Slaservice>(Slaservice);
  }

  callSlaNoFiltros(page: number): void {
    this.spinner.show();
    this.slas = [];
    this.SlaService.obtenerSlas().subscribe(
      (data: any) => {
        this.cargarDatosTabla(data);
        this.spinner.hide();
      },
      (error: any) => {
        console.error(error);
        this.spinner.hide();
      }
    );
  }

  private cargarDatosTabla(data: any): any {
    this.slas = data;
    this.paginaActual = 1;
    this.paginasTotales = 1;
    this.resultadosTotales = data.length;
  }

  exportarDatos(): void {
    this.spinner.show();
    this.SlaService.exportar().subscribe((urlRespuesta) => {
      if (urlRespuesta) {
        // Abrir nueva pantalla con el resultado del exportar
        this.spinner.hide();
        window.open(
          urlRespuesta.url,
          '_blank',
          'location=yes, height=570, width=520, scrollbars=yes, status=yes'
        );
      } else {
        const data = {
          title: 'Información',
          msg: 'No se ha podido hacer la exportación ...',
        };
        this.dialog.open(ModalInfoComponent, {
          disableClose: true,
          autoFocus: false,
          data,
        });
      }
    });
  }
}

<div class="container">
  <fieldset>
    <legend>Buscar por ...</legend>
    <form [formGroup]="formulario" *ngIf="formulario">
      <div class="row mb-3">
        <div class="col-md-2 radio">
          <p-radioButton
            name="estado"
            value="activos"
            label="Activos"
            formControlName="estado"
            inputId="activos"
            (click)="cambiarEstado('activos')"
            [checked]
          ></p-radioButton>
        </div>
        <div class="col-md-2 radio">
          <p-radioButton
            name="estado"
            value="inactivos"
            label="Inactivos"
            formControlName="estado"
            inputId="inactivos"
            (click)="cambiarEstado('inactivos')"
          ></p-radioButton>
        </div>
      </div>
      <div class="row">
        <div class="mb-3 col-sm-12 col-lg-6">
          <label for="convenio" class="form-label">Denominación</label>
          <div class="col-sm-12 col-lg-7 p-0">
            <input
              type="text"
              autocomplete="off"
              class="form-control"
              id="convenio"
              name="convenio"
              formControlName="convenio"
              placeholder="Introduzca Denominación a buscar"
              (input)="buscarConvenio($event)"
            />
            <small
              class="form-text text-danger"
              *ngIf="
                listaDenominaciones.length === 0 &&
                conveniosBuscados &&
                this.formulario.get('variosConvenios')?.value.length === 0
              "
              >* No se han encontrado resultados</small
            >
          </div>
        </div>
      </div>
      <div class="row">
        <div class="mb-3 col-sm-12 col-lg-12">
          <mat-chip
            class="selected-chip mr-3 mb-2"
            *ngFor="
              let convenioInput of this.formulario.get('variosConvenios')
                ?.value;
              let i = index
            "
            [removable]="removable"
            (removed)="removeConvenioForm(convenioInput, i)"
          >
            {{ convenioInput }}
            <mat-icon matChipRemove *ngIf="removable">X</mat-icon>
          </mat-chip>
          <div class="row mt-3 col-sm-12 col-lg-12">
            <div>
              <mat-chip-list>
                <mat-chip
                  class="mr-5 mb-2"
                  *ngFor="let convenio of listaDenominaciones; let i = index"
                  [selected]="convenio.state"
                  selectable
                  multiple
                  [removable]="removable"
                  (removed)="removeConvenio(i)"
                  [value]="convenio"
                  [multiple]="true"
                  (click)="
                    convenio.state = !convenio.state; addConvenio(convenio, i)
                  "
                >
                  {{ convenio.convenio }}
                  <mat-icon matChipRemove *ngIf="removable">X</mat-icon>
                </mat-chip>
              </mat-chip-list>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="mb-3 col-sm-12 col-lg-4">
          <label for="provincia" class="form-label">Provincia</label>
          <p-multiSelect
            id="provincia"
            filterPlaceHolder="Buscar"
            [defaultLabel]="'Seleccionar provincia'"
            [virtualScroll]="true"
            [options]="listaProvincias"
            optionLabel="descripcion"
            formControlName="provincia"
            styleClass="form-control p-0"
          ></p-multiSelect>
        </div>
        <div class="mb-3 col-sm-12 col-lg-4">
          <label for="actividad" class="form-label">Actividad</label>
          <p-multiSelect
            id="actividad"
            styleClass="form-control p-0"
            placeholder="Seleccionar actividad"
            [options]="listaActividades"
            formControlName="actividad"
            optionLabel="descripcion"
            [filter]="true"
          >
          </p-multiSelect>
        </div>
      </div>
      <div class="row">
        <div class="mb-3 col-sm-12 col-lg-4">
          <label for="ambito" class="form-label">Ámbito</label>
          <p-multiSelect
            id="ambito"
            filterPlaceHolder="Buscar"
            [defaultLabel]="'Seleccionar ambito'"
            [virtualScroll]="true"
            [options]="listaAmbitos"
            optionLabel="descripcion"
            formControlName="ambito"
            styleClass="form-control p-0"
          ></p-multiSelect>
        </div>
      </div>

      <div class="mt-2 mb-3 d-flex izquierda">
        <button
          class="btn limpiarBoton customButton fa fa-eraser"
          (click)="reset()"
        >
          Limpiar
        </button>
        <button
          type="submit"
          class="btn btn-outline-primary customButton fa fa-search buscar"
          (click)="buscarFiltro(false)"
        >
          Buscar
        </button>
      </div>
    </form>
  </fieldset>
</div>

import { Component } from '@angular/core';
import { PeticionActuacionFiltros } from '../../../modelos/peticion';

@Component({
  selector: 'app-peticion-actuacion-principal',
  templateUrl: './peticion-actuacion-principal.component.html',
  styleUrls: ['./peticion-actuacion-principal.component.css'],
})
export class PeticionActuacionPrincipalComponent {
  public datosFiltro!: PeticionActuacionFiltros;

  public isLoading = true;

  recibirFiltros(event: any): void {
    this.datosFiltro = event;
  }

  finishLoading(event: any): void {
    this.isLoading = event;
  }
}

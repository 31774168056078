import { Injectable } from '@angular/core';
import { Accion } from '../modelos/accion';
import { Constantes } from './constantes';

@Injectable()
export class AccionPeticionConstantes {
  constructor(private constantes: Constantes) {}

  public readonly ACCIONES: Accion[] = [
    {
      id: this.constantes.ACCION_VISUALIZAR,
      titulo: 'Visualizar',
      descripcion: 'Vemos el contenido de la petición',
      tooltip: 'Ver el contenido de la petición',
      clases: 'btn btn-sm btn-outline-primary visualizar',
      icono: 'fa fa-eye',
    },
    {
      id: this.constantes.ACCION_EDITAR,
      titulo: 'Editar',
      descripcion: 'Modificar datos de la petición',
      tooltip: 'Modificar datos de la petición',
      clases: 'btn btn-sm btn-outline-primary visualizar',
      icono: 'fa fa-pencil',
    },
    {
      id: this.constantes.ACCION_ELIMINAR,
      titulo: 'Eliminar',
      descripcion: 'Eliminar petición',
    },
    {
      id: this.constantes.ACCION_COMENTAR,
      titulo: 'Comentar',
      descripcion: 'Crear un comentario',
      tooltip: 'Crear un comentario',
      clases: 'btn btn-sm btn-outline-primary visualizar',
      icono: 'fa fa-comment',
    },
    {
      id: this.constantes.ACCION_ACLARACION,
      titulo: 'Aclarar',
      descripcion:
        'Hacer aclaraciones. Aclarar una petición por el solicitante',
      tooltip: 'Realizar aclaración',
      clases: 'btn btn-sm btn-outline-primary visualizar',
      icono: 'fa fa-lightbulb-o',
    },
    {
      id: this.constantes.ACCION_RESPONDER,
      titulo: 'Responder',
      descripcion:
        'El destinatario de la solicitud ha iniciado la gestión de la petición, o en un flujo de aut. uno de los estamentos interviene',
      tooltip: 'Cambiar estado En Curso',
      clases: 'btn btn-sm btn-outline-primary visualizar',
      icono: 'fa fa-reply',
    },
    {
      id: this.constantes.ACCION_DEVOLVER,
      titulo: 'Devolver',
      descripcion: 'Solicitar información/aclaración adicional al peticionario',
      tooltip: 'Devolver petición',
      clases: 'btn btn-sm btn-outline-primary visualizar',
      icono: 'fa fa-undo',
    },
    {
      id: this.constantes.ACCION_RECHAZAR,
      titulo: 'Rechazar',
      descripcion: 'Rechazar petición',
      tooltip: 'Rechazar y cerrar',
      clases: 'btn btn-sm btn-outline-danger eliminar',
      icono: 'fa fa-thumbs-down',
    },
    {
      id: this.constantes.ACCION_RESOLVER_CERRAR,
      titulo: 'Resolver y cerrar',
      descripcion: 'Resolver petición por parte del destinatario',
      tooltip: 'Resolver y cerrar',
      clases: 'btn btn-sm btn-outline-success resolver',
      icono: 'fa fa-check-circle',
    },
    {
      id: this.constantes.ACCION_SOLICITAR_AUTORIZACION,
      titulo: 'Solicitar autorización',
      descripcion: 'Enviar petición al autorizante y a los informantes',
      tooltip: 'Solicitar autorización',
      clases: 'btn btn-sm btn-outline-primary visualizar',
      icono: 'fa fa-address-card-o',
    },
    {
      id: this.constantes.ACCION_INFORMAR,
      titulo: 'Informar petición',
      descripcion:
        'Informar la solicitud (flujos de autorización) por parte del estamento que asume la función de autorizante asociada a un flujo de autorización',
      tooltip: 'Informar petición',
      clases: 'btn btn-sm btn-outline-primary visualizar',
      icono: 'fa fa-file-text',
    },
    {
      id: this.constantes.ACCION_AUTORIZAR,
      titulo: 'Autorizar petición',
      descripcion:
        'Aprobar Petición por parte del estamento que asume la función de autorizante en una petición asociada a un fliuo de autorización',
      tooltip: 'Autorizar petición',
      clases: 'btn btn-sm btn-outline-primary visualizar',
      icono: 'fa fa-address-card-o',
    },
    {
      id: this.constantes.ACCION_ARTICA,
      titulo: 'Subir documento a Ártica',
      descripcion:
        'Indicar que se ha subido el Documento a Ártica, Si procede según catálogo de peticiones',
      tooltip: 'Subir documento a Ártica',
      clases: 'btn btn-sm btn-outline-primary visualizar btn-artica',
      icono: 'icono-artica',
    },
    {
      id: this.constantes.ACCION_REABRIR,
      titulo: 'Reabrir peticion',
      descripcion: 'Permite reabrir una petición que estaba cerrada',
      tooltip: 'Reabrir peticion',
      clases: 'btn btn-sm btn-outline-primary visualizar',
      icono: 'fa fa-undo',
    },
    {
      id: this.constantes.ACCION_AS400WEB,
      titulo: 'Acceso a SGC',
      descripcion: 'Permite ir a la Web del AS400 para consultar las nominas de los trabajadores afectados',
      tooltip: 'Acceso a SGC',
      clases: 'btn btn-sm btn-outline-primary visualizar',
      icono: 'fa fa-list-alt',
    },
    {
      id: this.constantes.ACCION_INFORMACION_EMPLEADO,
      titulo: 'Informacion del Empleado SGC',
      descripcion: 'Permite mostar una tabla para consultar informacion de SGC de los empleados afectados',
      tooltip: 'Informacion del Empleado SGC',
      clases: 'btn btn-sm btn-outline-primary visualizar',
      icono: 'fa fa-newspaper-o',
    },
  ];
}

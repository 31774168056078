import { Component, OnInit } from '@angular/core';
import { Empresa } from '../../modelos/empresa';
import { EmpresasService } from '../../servicios/empresas/empresas.service';
import { EmpresaTerritorioService } from '../../servicios/empresa-territorio/empresa-territorio.service';
import { EmpresaNoFlexiplan } from '../../modelos/empresaNoFlexiplan';
import { AmbitoNoFlexiplan } from '../../modelos/ambito-no-flexiplan';
import { FormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-empresas',
  templateUrl: './empresas.component.html',
  styleUrls: ['./empresas.component.scss'],
})
export class EmpresasComponent implements OnInit {
  empresas!: Empresa[];
  ambitosNoFlexiplan!: EmpresaNoFlexiplan[];
  provincias!: string[];
  ambitos: AmbitoNoFlexiplan[][] = [];
  forms!: FormGroup[];

  constructor(
    private empresaService: EmpresasService,
    private empresaTerritorioService: EmpresaTerritorioService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.initEmpresas();
  }

  initEmpresas(): void {
    this.spinner.show();
    this.empresaService.getEmpresas().subscribe((empresas: Empresa[]) => {
      this.empresas = empresas;
      this.initEmpresaTerritorio();
    });
  }

  private initEmpresaTerritorio(): void {
    this.empresaTerritorioService.getEmpresaTerritorio().subscribe((data) => {
      this.ambitosNoFlexiplan = data.empresaNoFlexiplan;
      this.initAmbitos();
    });
  }

  private initAmbitos(): void {
    for (let i = 0; i < this.empresas.length; i++) {
      this.ambitos[i] = [];
    }
    this.spinner.hide();
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalNotificarComponent } from '../../modals/modal-notificar/modal-notificar.component';
import { MensajeComponent } from '../../mensaje/mensaje.component';
import { Aclaracion } from '../../../modelos/aclaracion';
import { PeticionService } from '../../../servicios/peticion/peticion.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FileUpload } from 'primeng/fileupload';
import { Fichero } from '../../../modelos/fichero';
import { NgxSpinnerService } from 'ngx-spinner';
import { forkJoin } from 'rxjs';
import { Peticion } from '../../../modelos/peticion';
import { SesionService } from '../../../servicios/sesion/sesion.service';
import { Constantes } from '../../../utils/constantes';
import { Usuario } from '../../../modelos/usuario';
import Utils from 'src/app/utils/utils';
import { Router } from '@angular/router';
import { ButtonModalInfo, ModalInfo } from 'src/app/modelos/moda-info';
import { ModalInfoComponent } from '../../modals/modal-info/modal-info.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-aclaraciones',
  templateUrl: './aclaraciones.component.html',
  styleUrls: ['./aclaraciones.component.scss'],
})
export class AclaracionesComponent implements OnInit {
  @ViewChild('modalnotificar', { static: false })
  public modalnotificar!: ModalNotificarComponent;

  @ViewChild('mensaje', { static: false })
  public mensaje!: MensajeComponent;

  @ViewChild('fileInput', { static: false })
  fileInput!: FileUpload;

  aclaraciones: Aclaracion[] = [];
  aclaracionesTotales: any;
  aclaracionesBorrador: any;
  idBorrador!: number;
  peticion!: Peticion;
  public uploadedFiles: Fichero[] = [];
  formulario!: FormGroup;
  contador!: number;
  permisoEscritura!: boolean;
  borrador!: boolean;

  constructor(
    private peticionService: PeticionService,
    private fb: FormBuilder,
    private router: Router,
    private spinner: NgxSpinnerService,
    private sesionService: SesionService,
    private constantes: Constantes,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.contador = 0;
    this.loadServices();
    this.initForm();
  }

  private loadServices(): void {
    this.spinner.show();
    const usuarioLogado = this.sesionService.getUser();
    const llamadas = [];
    this.peticion = this.peticionService.getPeticion();

    llamadas.push(
      this.peticionService.obtenerAclaraciones(this.peticion.idPeticion || '')
    );
    forkJoin(llamadas).subscribe(
      (resp: any) => {
        this.aclaraciones = resp[0].filter(
          (element: { borrador: number }) => element.borrador != 1
        );
        this.aclaracionesTotales = resp[0];
        this.aclaracionesBorrador = resp[0].find(
          (element: any) =>
            element.borrador && element.usuario.email === usuarioLogado.email
        );
        if (this.aclaracionesBorrador) {
          this.mostrarAclaracionBorrador();
          if (this.aclaracionesBorrador != null) {
            this.uploadedFiles = this.aclaracionesBorrador.fichero;
          }
        } else {
          this.idBorrador = 0;
        }

        this.permisoEscritura =
          usuarioLogado.email === this.peticion.usuarioDestinatario?.email ||
          usuarioLogado.email === this.peticion.usuarioPeticionario?.email ||
          usuarioLogado.rol?.id === 1 ||
          usuarioLogado.rol?.id === 2;
        this.spinner.hide();
      },
      (error) => {
        console.error(error);
        this.spinner.hide();
      }
    );
  }

  private initForm(): void {
    this.formulario = this.fb.group({
      msg: ['', [Validators.required, Validators.maxLength(3000)]],
    });
  }

  public onKeyUp(event: any): void {
    this.contador = event.target.value.length;
  }

  public onKeyPress(event: any): any {
    if (event.keyCode === 39 || event.keyCode === 96 || event.keyCode === 180) {
      return false;
    }
    return true;
  }

  public limpiar(): void {
    this.formulario.controls.msg.setValue('');
    this.contador = 0;
    this.borrador = false;
  }

  public crearAclaracion(): void {
    if (this.longitudPermitidaFicheros()) {
      this.modalnotificar.mostrarModalAclaracion(
        this.peticion,
        this.constantes.ACCION_ACLARACION,
        'Realizar Aclaración',
        this.formulario.get('msg')?.value,
        this.fileInput ? this.fileInput.files : [],
        this.idBorrador,
        this.borrador
      );
      this.borrador = false;
    }
  }

  private crearAclaracionBorradorRequest(): any {
    return {
      notificados: null,
      usuario: { email: this.sesionService.getUser().email } as Usuario,
      peticion: { idPeticion: this.peticion.idPeticion } as Peticion,
      texto: this.formulario.get('msg')?.value,
      borrador: this.idBorrador,
    };
  }

  public crearAclaracionBorrador(): void {
    if (this.longitudPermitidaFicheros()) {
      this.spinner.show();
      if (this.aclaracionesBorrador) {
        this.peticionService
          .editarAclaracionBorrador(
            this.peticion.idPeticion,
            this.crearAclaracionBorradorRequest(),
            this.fileInput ? this.fileInput.files : []
          )
          .subscribe((resp: any) => {
            this.aclaracionesBorrador = resp;
            this.uploadedFiles = this.aclaracionesBorrador.fichero;
            this.loadServices();
            this.spinner.hide();
          });
      } else {
        this.peticionService
          .aclararPeticionBorrador(
            this.peticion.idPeticion,
            this.crearAclaracionBorradorRequest(),
            this.fileInput ? this.fileInput.files : []
          )
          .subscribe((resp: any) => {
            this.aclaracionesBorrador = resp;
            this.uploadedFiles = this.aclaracionesBorrador.fichero;
            this.loadServices();
            this.spinner.hide();
          });
      }
      this.borrador = true;
      this.fileInput.clear();
      this.router.navigate([
        `/peticiones/${this.peticion.idPeticion}/aclaraciones`,
      ]);
    }
  }

  public eliminarAclaracionBorrador(): void {
    const data = {
      title: 'Eliminar borrador',
      msg: `¿Desea eliminar al borrador?`,
      buttons: [
        { title: 'Aceptar', class: 'btn-success', action: '1' },
        { title: 'Cancelar', class: 'btn-danger' },
      ] as ButtonModalInfo[],
    } as ModalInfo;
    this.dialog
      .open(ModalInfoComponent, { disableClose: true, autoFocus: false, data })
      .afterClosed()
      .subscribe((resp) => {
        if (resp) {
          this.spinner.show();
          this.peticionService
            .eliminarAclararPeticionBorrador(
              this.peticion.idPeticion,
              this.sesionService.getUser().email
            )
            .subscribe(() => {
              this.mensaje.showMensaje(
                'Borrador eliminado satisfactoriamente.'
              );
              this.spinner.hide();
            });
          this.formulario.controls.msg.setValue('');
          this.aclaracionesBorrador = undefined;
          this.fileInput.clear();
          this.borrador = false;
        }
      });
    this.router.navigate([
      `/peticiones/${this.peticion.idPeticion}/aclaraciones`,
    ]);
  }

  public mostrarAclaracionBorrador() {
    if (this.aclaracionesBorrador.borrador != undefined) {
      this.formulario.controls.msg.setValue(this.aclaracionesBorrador.texto);
      this.borrador = true;
      this.idBorrador = this.aclaracionesBorrador.id;
    } else {
      this.formulario.controls.msg.setValue('');
      this.borrador = false;
    }
  }

  private longitudPermitidaFicheros(): boolean {
    if (this.fileInput) {
      for (const fichero of this.fileInput.files) {
        if (
          fichero.name.length > this.constantes.LONIGUTD_MAXIMA_NOMBRE_FICHERO
        ) {
          this.mensaje.showErrorPeticion(
            `El fichero: ${fichero.name} excede de ${this.constantes.LONIGUTD_MAXIMA_NOMBRE_FICHERO} caracteres`
          );
          return false;
        }
      }
    }
    return true;
  }

  public notificadores(idPeticion: string): void {
    if (this.fileInput) {
      this.fileInput.clear();
    }
    this.mensaje.showMensaje('Aclaración creada correctamente');
    this.ngOnInit();
  }

  public volver(): void {
    window.history.back();
  }

  public deshabilitar(): boolean {
    let deshabilitar: any;
    let archivos = 0;
    if (this.fileInput) {
      archivos = this.uploadedFiles.length + this.fileInput.files.length;
    }
    if (this.borrador && archivos <= 5) {
      deshabilitar = false;
    } else {
      deshabilitar = this.deshabilitarBoton();
    }
    return deshabilitar;
  }

  public deshabilitarBoton(): boolean {
    if (
      !this.formulario.valid ||
      this.uploadedFiles.length + this.fileInput.files.length > 5
    ) {
      return true;
    }
    return false;
  }

  public controlForm(): void {
    if (this.formulario.get('msg')?.value === '') {
      this.contador = 0;
    } else {
      this.contador = this.formulario.get('msg')?.value.trim().length;
    }
  }
  public fijarElemento(event: any): void {
    event.preventDefault();
  }

  public descargarFicheroAclaracion(
    idfichero: string,
    nombreFichero: string
  ): void {
    if (
      this.aclaracionesBorrador.peticion.idPeticion &&
      this.aclaracionesBorrador.id &&
      idfichero
    ) {
      this.spinner.show();
      this.peticionService
        .descargarFicheroAclaracion(
          this.aclaracionesBorrador.peticion.idPeticion,
          this.aclaracionesBorrador.id,
          idfichero
        )
        .subscribe(
          (fileReceived: any) => {
            this.spinner.hide();
            let nombre;
            if (nombreFichero) {
              nombre = nombreFichero;
            }
            Utils.base64ToFile(fileReceived.file, nombre || '');
          },
          (errorDescarga: any) => {
            this.mensaje.showErrorPeticion(
              'No se ha podido descargar el fichero ' + errorDescarga.error
            );
            this.spinner.hide();
          }
        );
    }
  }

  public visualizarFicheroAclaracion(
    idfichero: string,
    nombreFichero: string
  ): void {
    if (
      this.aclaracionesBorrador.peticion.idPeticion &&
      this.aclaracionesBorrador.id &&
      idfichero
    ) {

      let base = this.constantes.ENDPOINT_API_PETICION_ACLARAR_VISUALIZAR_FICHERO.replace(
        '{idPeticion}',
        String(this.aclaracionesBorrador.peticion.idPeticion)
      )
        .replace('{idAclaracion}', String(this.aclaracionesBorrador.id))
        .replace('{idFichero}', idfichero);

      let contentType = Utils.calculateMediaType(nombreFichero.split('.')[1]);
      let finalURL = base;
      if(contentType.includes('doc') || contentType.includes('xls')){
        finalURL = "https://view.officeapps.live.com/op/embed.aspx?src=" + base;
      }

      const link = document.createElement('a');
      link.href = finalURL;
      link.target = '_blank';
      link.click();
    }
  }

  eliminarFichero(idFichero: string): void {
    const data = {
      title: 'Eliminar fichero borrador',
      msg: `¿Desea eliminar al fichero?`,
      buttons: [
        { title: 'Aceptar', class: 'btn-success', action: '1' },
        { title: 'Cancelar', class: 'btn-danger' },
      ] as ButtonModalInfo[],
    } as ModalInfo;
    this.dialog
      .open(ModalInfoComponent, { disableClose: true, autoFocus: false, data })
      .afterClosed()
      .subscribe((resp) => {
        if (resp) {
          this.spinner.show();
          this.peticionService
            .eliminarFicheroAclaracion(this.idBorrador.toString(), idFichero)
            .subscribe((resp: any) => {
              this.uploadedFiles = resp;
              this.spinner.hide();
              this.mensaje.showMensaje('Fichero eliminado satisfactoriamente.');
            });
        }
      });
    this.router.navigate([
      `/peticiones/${this.peticion.idPeticion}/aclaraciones`,
    ]);
  }
}

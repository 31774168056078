<!-- Contenedor Principal -->
<div class="row">
  <div class="comments-container">
    <ul id="comments-list"
      class="comments-list"
      *ngIf="aclaraciones">
      <li *ngFor="let aclaracion of aclaraciones">
        <div class="comment-main-level col col-lg-12">
          <div class="comment-box">
            <div class="comment-head">
              <div class="row">
                <div class="col-6">
                  <h6 class="comment-name">{{aclaracion.usuario.nombre}} {{aclaracion.usuario.apellido}}</h6>
                </div>
                <div class="col-6">
                  <span style="float: right">{{aclaracion.fecha | date: 'dd-MM-yyyy HH:mm'}}</span>
                </div>
              </div>
            </div>
            <div class="comment-content">
              <ng-container *ngIf="aclaracion.vistoBueno !== undefined">
                <span class="font-weight-bold">Visto Bueno:</span>
                <ng-container *ngIf="aclaracion.vistoBueno; else noVistoBueno"> SI</ng-container>
                <ng-template #noVistoBueno>
                  NO
                </ng-template>
                <br>
                <span class="font-weight-bold">Comentario: </span>{{aclaracion.texto}}
              </ng-container>
              <ng-container *ngIf="aclaracion.vistoBueno === undefined">
                {{aclaracion.texto}}
              </ng-container>

              <div *ngIf="aclaracion.fichero!==null"
                style="margin-top: 2%;">
                <div *ngFor="let doc of aclaracion.fichero; let i = index">
                  <div class="document-link"
                    (click)="descargarFicheroAclaracion(aclaracion, i)"><span>
                      {{doc.nombre}}&nbsp;</span><em class="fa fa-download"></em></div>
                  <br>
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
    <ng-template #noAclaraciones>
      <div class="no-results-text">
        <p>Aún no se han creado aclaraciones para esta petición...</p>
      </div>
    </ng-template>
  </div>
</div>

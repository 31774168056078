<ngx-spinner></ngx-spinner>

<div class="container espacio">
    <div class="d-flex justify-content-between mb-3">
        <h1>Informe Datastudio</h1>
    </div>

    <ul *ngIf="infomeDatastudio">
        <li><a class="link-informe" *ngIf="infomeDatastudio" (click)="openInfomeDatastudio()">{{infomeDatastudio.descripcion}}</a></li>
    </ul>

    <h2 *ngIf="!infomeDatastudio">El usuario no tiene informe asociados</h2>

</div>
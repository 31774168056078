import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { Table } from 'primeng/table';
import { Puesto } from 'src/app/modelos/puesto';
import { PuestosService } from 'src/app/servicios/puestos/puestos.service';
import { Constantes } from 'src/app/utils/constantes';
import Utils from 'src/app/utils/utils';
import { ModalInfoComponent } from '../modals/modal-info/modal-info.component';

@Component({
  selector: 'app-puestos',
  templateUrl: './puestos.component.html',
  styleUrls: ['./puestos.component.scss']
})
export class PuestosComponent implements OnInit {

  puestos: Puesto[] = [];
  cols!: any[];
  first = 1;
  rows = 25;

  paginasTotales = 0;
  paginaActual = 1;
  resultadosTotales = 0;
  resultadosPorPagina!: number[];

  lastSortField!: any;

  constructor(
    private puestosService: PuestosService,
    private spinner: NgxSpinnerService,
    public dialog: MatDialog,
    private constantes: Constantes
  ) {
    this.cols = [
      {field: 'id', header: 'ID', toolTip: ''},
      {field: 'descripcion', header: 'DESCRIPCIÓN', toolTip: ''},
    ];
    this.resultadosPorPagina = this.constantes.numeroUsuariosPorPagina;
  }

  ngOnInit(): void {
    this.getPuestos(this.first);
  }

  getPuestos(page: number): void {
    this.spinner.show();
    this.puestos = [];
    this.puestosService.getPuestosPaged(page, this.rows).subscribe(
      (data: any) => {
        this.cargarDatosTabla(data);
        this.spinner.hide();
      },
      (error: any) => {
        console.error(error);
        this.spinner.hide();
      }
    );
  }

  private cargarDatosTabla(data: any): any {
    this.puestos = data.results;
    this.paginaActual = data.currentPage;
    this.paginasTotales = data.totalPages;
    this.resultadosTotales = data.totalResults;
    this.customSort(this.lastSortField);
  }

  nextPage(e: any, table: Table): void {
    if (this.rows !== e.rows){
      this.rows = e.rows;
      e.first = 0;
      this.paginaActual = 1;
      const offset = ((e.first) / this.rows) + 1;
      table.first = offset;
      this.first = offset;
      this.rows = e.rows;
      this.getPuestos(offset);
    }else{
      if ((e.sortField && !this.lastSortField) || (e.sortField && this.hasChanged(e.sortField, e.sortOrder))) {
        this.lastSortField = {
          sortField: e.sortField,
          sortOrder: e.sortOrder
        };
        this.customSort(this.lastSortField);
        table.first = (this.paginaActual - 1) * this.rows;
      }else{
        const offset = ((e.first) / this.rows) + 1;
        this.rows = e.rows;
        this.first = offset;
        this.getPuestos(offset);
      }
    }
  }

  private customSort(sort: any): void {
    if (sort && this.puestos) {
      this.puestos.sort((a: Puesto, b: Puesto) => {
        switch (sort.sortField) {
          case this.cols[0].field:
            return Utils.sortNumber(
              a.id || 0,
              b.id || 0,
              sort.sortOrder
            );
          case this.cols[1].field:
            return Utils.sortString(
              a.descripcion || '',
              b.descripcion || '',
              sort.sortOrder
            );
          default:
            return sort.sortOrder;
        }
      });
    }
  }

  private hasChanged(sort: string, order: number): boolean {
    return this.lastSortField.sortField !== sort || this.lastSortField.sortOrder !== order;
  }

  exportarDatos(): void {
    this.spinner.show();
    this.puestosService.exportar().subscribe(
      (data) => {
        // Abrir nueva pantalla con el resultado del exportar
        this.spinner.hide();
        window.open(data.url, '_blank', 'location=yes, height=570, width=520, scrollbars=yes, status=yes');
      },
      (error) => {
        console.error(error);
        this.spinner.hide();
        const data = {
          title: 'Información',
          msg: 'No se ha podido hacer la exportación ...'
        };
        this.dialog.open(ModalInfoComponent, {disableClose: true, autoFocus: false,  data});
      });
  }

}

import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { Table } from 'primeng/table';
import { Constantes } from 'src/app/utils/constantes';
import { Estado } from '../../modelos/estado';
import Utils from '../../utils/utils';
import { EstadosService } from 'src/app/servicios/estados/estados.service';
import { ModalInfoComponent } from '../modals/modal-info/modal-info.component';

@Component({
  selector: 'app-estados',
  templateUrl: './estados.component.html',
  styleUrls: ['./estados.component.scss'],
})
export class EstadosComponent implements OnInit {
  estados: Estado[] = [];
  cols!: any[];
  first = 1;
  rows = 25;

  paginasTotales = 0;
  paginaActual = 1;
  estadosTotales = 0;
  estadosPorPagina!: number[];

  lastSortField!: any;

  constructor(
    private estadosService: EstadosService,
    private spinner: NgxSpinnerService,
    private constantes: Constantes,
    public dialog: MatDialog
  ) {
    this.cols = [
      { field: 'cod_estado', header: 'ID', tool: 'Id del estado' },
      { field: 'estado', header: 'ESTADO', tool: 'Estado' },
      {
        field: 'descripcion',
        header: 'DESCRIPCIÓN',
        tool: 'Qué puede hacer el estado',
      },
      {
        field: 'comentarios',
        header: 'COMENTARIOS',
        tool: 'Comentarios sobre el estado',
      },
    ];
    this.estadosPorPagina = this.constantes.numeroUsuariosPorPagina;
  }

  ngOnInit(): void {
    this.getEstados(this.first);
  }

  getEstados(page: number): void {
    this.spinner.show();
    this.estados = [];
    this.estadosService.getEstados().subscribe(
      (data: any) => {
        this.cargarDatosTabla(data);
        this.spinner.hide();
      },
      (error: any) => {
        console.error(error);
        this.spinner.hide();
      }
    );
  }

  private cargarDatosTabla(data: any): any {
    this.estados = data;
    this.paginaActual = 1;
    this.paginasTotales = 1;
    this.estadosTotales = data.length;
    this.customSort(this.lastSortField);
  }

  nextPage(e: any, table: Table): void {
    if (
      (e.sortField && !this.lastSortField) ||
      (e.sortField && this.hasChanged(e.sortField, e.sortOrder))
    ) {
      this.lastSortField = {
        sortField: e.sortField,
        sortOrder: e.sortOrder,
      };
      this.customSort(this.lastSortField);
      table.first = (this.paginaActual - 1) * this.rows;
    } else {
      const offset = e.first / e.rows;
      this.rows = e.rows;
      this.getEstados(offset);
    }
  }

  private customSort(sort: any): void {
    if (sort && this.estados) {
      this.estados.sort((a: Estado, b: Estado) => {
        switch (sort.sortField) {
          case this.cols[0].field:
            return Utils.sortString(
              a.id.toString(),
              b.id.toString(),
              sort.sortOrder
            );
          case this.cols[1].field:
            return Utils.sortString(
              a.nombre,
              b.nombre,
              sort.sortOrder
            );
          default:
            return sort.sortOrder;
        }
      });
    }
  }

  private hasChanged(sort: string, order: number): boolean {
    return (
      this.lastSortField.sortField !== sort ||
      this.lastSortField.sortOrder !== order
    );
  }

  exportarDatos(): void{
    this.spinner.show();
    this.estadosService.exportar().subscribe(urlRespuesta => {
      if (urlRespuesta){
        // Abrir nueva pantalla con el resultado del exportar
        this.spinner.hide();
        window.open(urlRespuesta.url, '_blank', 'location=yes, height=570, width=520, scrollbars=yes, status=yes');
      }else{
        const data = {
          title: 'Información',
          msg: 'No se ha podido hacer la exportación ...'
        };
        this.dialog.open(ModalInfoComponent, {disableClose: true, autoFocus: false,  data});
      }
    });
  }
}

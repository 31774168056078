import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { forkJoin } from 'rxjs';
import { Informacion } from 'src/app/modelos/informacion';
import { ButtonModalInfo, ModalInfo } from 'src/app/modelos/moda-info';
import { Peticion } from 'src/app/modelos/peticion';
import { PeticionService } from 'src/app/servicios/peticion/peticion.service';
import { SesionService } from 'src/app/servicios/sesion/sesion.service';
import { MensajeComponent } from '../../mensaje/mensaje.component';
import { ModalInfoComponent } from '../../modals/modal-info/modal-info.component';

@Component({
  selector: 'app-informaciones',
  templateUrl: './informaciones.component.html',
})
export class InformacionesComponent implements OnInit {
  @ViewChild('mensaje', { static: false })
  public mensaje!: MensajeComponent;

  vistoBuenoOpciones = ['SI', 'NO'];
  informaciones: Informacion[] = [];
  peticion!: Peticion;
  formulario!: FormGroup;
  contador!: number;
  permisoInformar = false;
  esInformante = false;
  idPeticion!: string;
  informante!: Informacion;
  vBueno = this.vistoBuenoOpciones[0];
  idBorrador!: number;
  informarBorrador: any;
  borrador: boolean = false;

  constructor(
    private peticionService: PeticionService,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private sesionService: SesionService,
    private router: Router,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.contador = 0;
    this.loadServices();
  }

  private loadServices(): void {
    const llamadas = [];
    this.peticion = this.peticionService.getPeticion();

    this.idPeticion = this.peticion.idPeticion || '';
    llamadas.push(this.peticionService.obtenerInformaciones(this.idPeticion));
    llamadas.push(
      this.peticionService.obtenerPeticionInformaciones(this.idPeticion)
    );

    forkJoin(llamadas).subscribe(
      (resp: any) => {
        this.informaciones = resp[0];
        this.informarBorrador = resp[1];
        if (this.informarBorrador && this.informarBorrador.borrador === 1) {
          this.borrador = true;
        }
        if (this.borrador) {
          this.vBueno =
            this.informarBorrador.vistoBueno === '1'
              ? this.vistoBuenoOpciones[0]
              : this.vistoBuenoOpciones[1];
        } else if (this.informaciones.length > 0) {
          this.vBueno =
            this.informaciones[0].vistoBueno === true
              ? this.vistoBuenoOpciones[0]
              : this.vistoBuenoOpciones[1];
        }
        this.calcularPermisos();
        this.initForm();
        if (this.informarBorrador.borrador === 1) {
          this.mostrarInformarBorrador();
        } else if (this.informaciones.length === 0) {
          this.vBueno = this.vistoBuenoOpciones[0];
          this.idBorrador = 0;
        }
        this.spinner.hide();
      },
      (error) => {
        console.error(error);
        this.spinner.hide();
      }
    );
  }

  calcularPermisos(): void {
    const usuarioLogado = this.sesionService.getUser();

    const listaFiltrada = this.informaciones.filter(
      (i) => i.usuario.email === usuarioLogado.email
    );
    this.permisoInformar = listaFiltrada.length === 0;
    if (listaFiltrada.length > 0) {
      this.informante = listaFiltrada[0];
    }
    if (this.peticion.informantes) {
      const informanteUsuario = this.peticion.informantes.filter(
        (i) => i.idInformante === usuarioLogado.email
      );
      this.esInformante =
        informanteUsuario.length > 0 || this.sesionService.isAdmin();
      this.esInformante =
        this.esInformante ||
        usuarioLogado.rol?.id === 3 ||
        usuarioLogado.rol?.id === 4;
      this.permisoInformar = this.permisoInformar && this.esInformante;
    }
    this.sesionService.isAdmin();
  }

  private initForm(): void {
    this.formulario = this.fb.group({
      msg: [
        this.informante
          ? { value: this.informante.texto, disabled: !this.permisoInformar }
          : { value: '', disabled: !this.permisoInformar },
        [Validators.required, Validators.maxLength(3000)],
      ],
      vistoBueno: [
        this.vBueno
          ? {
              value: this.vBueno,
              disabled: !this.permisoInformar,
            }
          : {
              value: this.vistoBuenoOpciones[0],
              disabled: !this.permisoInformar,
            },
        [Validators.required],
      ],
    });
  }

  public onKeyUp(event: any): void {
    this.contador = event.target.value.length;
  }

  public onKeyPress(event: any): any {
    if (event.keyCode === 39 || event.keyCode === 96 || event.keyCode === 180) {
      return false;
    }
    return true;
  }

  public limpiar(): void {
    this.formulario.controls.msg.setValue('');
    this.contador = 0;
  }

  public crearInformacion(): void {
    this.spinner.show();
    const vistoBueno = this.formulario.get('vistoBueno')?.value === 'SI';
    this.peticionService
      .informarPeticion(this.idPeticion, {
        vistoBueno,
        observacion: this.formulario.get('msg')?.value,
      })
      .subscribe(
        () => {
          this.mensaje.showMensaje('Información creada correctamente');
          this.spinner.hide();
          this.router.navigate([`/peticiones/actuaciones`]);
        },
        (error: any) => {
          console.error(error);
          this.mensaje.showErrorPeticion(error.error);
          this.spinner.hide();
        }
      );
  }

  public volver(): void {
    window.history.back();
  }

  public controlForm(): void {
    if (this.formulario.get('msg')?.value === '') {
      this.contador = 0;
    } else {
      this.contador = this.formulario.get('msg')?.value.trim().length;
    }
  }

  public crearInformarBorrador(): void {
    this.spinner.show();
    const vistoBueno = this.formulario.get('vistoBueno')?.value === 'SI';
    this.peticionService
      .autorizarInformacionBorrador(this.idPeticion, {
        vistoBueno,
        observacion: this.formulario.get('msg')?.value,
      })
      .subscribe((resp: any) => {
        this.informarBorrador = resp;
        this.loadServices();
        this.spinner.hide();
      });
    this.borrador = true;
  }

  public eliminarInformarBorrador(): void {
    const data = {
      title: 'Eliminar borrador',
      msg: `¿Desea eliminar al borrador?`,
      buttons: [
        { title: 'Aceptar', class: 'btn-success', action: '1' },
        { title: 'Cancelar', class: 'btn-danger' },
      ] as ButtonModalInfo[],
    } as ModalInfo;
    this.dialog
      .open(ModalInfoComponent, { disableClose: true, autoFocus: false, data })
      .afterClosed()
      .subscribe((resp) => {
        if (resp) {
          this.spinner.show();
          this.peticionService
            .eliminarInformacionPeticionBorrador(this.peticion.idPeticion)
            .subscribe(() => {
              this.mensaje.showMensaje(
                'Borrador eliminado satisfactoriamente.'
              );
              this.loadServices();
              this.spinner.hide();
            });
          this.formulario.controls.msg.setValue('');
          this.informarBorrador = undefined;
          this.vBueno = this.vistoBuenoOpciones[0];
          this.borrador = false;
        }
      });
  }

  public mostrarInformarBorrador() {
    if (this.informarBorrador.borrador === 1) {
      this.formulario.controls.msg.setValue(
        this.informarBorrador.observacionMotivoVistoBueno
      );
      this.borrador = true;
    } else {
      this.informarBorrador.controls.msg.setValue('');
      this.borrador = false;
    }
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Constantes } from 'src/app/utils/constantes';
import { PlantillaCombo } from '../../modelos/plantilla';

@Injectable({
  providedIn: 'root'
})
export class PlantillaService {

  constructor(private http: HttpClient,
              private constantes: Constantes) { }

  public obtenerPlantillaRemplazada(plantillarequest: any): Observable<any> {
    return this.http.post(this.constantes.ENDPOINT_API_PLANTILLAS_REMPLAZAR, plantillarequest);
  }

  public obtenerPlantillas(): Observable<PlantillaCombo[]> {
    return this.http.get<PlantillaCombo[]>(this.constantes.ENDPOINT_API_PLANTILLAS);
  }
}

<app-mensaje #mensaje (customAceptar)="onConfirmModal($event, ruta)">
</app-mensaje>
<app-modal-notificar
  id="modal-notificar"
  #modalnotificar
  (emitirNotificar)="notificadores($event)"
  (emitirCancelar)="cancelarCambioDestinatario()"
></app-modal-notificar>
<ngx-spinner> </ngx-spinner>
<div class="container">
  <app-crear-peticion [activeIndex]="activeIndex"></app-crear-peticion>
  <form [formGroup]="formulario" *ngIf="formulario">
    <p-accordion [multiple]="true">
      <p-accordionTab header="Datos Destino" [selected]="true">
        <div class="row">
          <div class="mb-3 col-sm-12 col-lg-5">
            <label for="area" class="form-label"
              >Área Destino <span class="text-danger">*</span></label
            >
            <p-dropdown
              id="area"
              (onChange)="cambioAreaDestino()"
              (onFocus)="quitarEstiloOB('area')"
              styleClass="form-control p-0"
              [options]="constantes.listaAreaDestino"
              placeholder="Seleccione un área destino"
              formControlName="area"
              [filter]="true"
            >
            </p-dropdown>
          </div>
          <div class="mb-3 col-sm-12 col-lg-5">
            <label for="tipopeticion" class="form-label"
              >Tipo Petición <span class="text-danger">*</span></label
            >
            <p-dropdown
              id="tipopeticion"
              (onFocus)="quitarEstiloOB('tipopeticion')"
              (onChange)="cambioTipoPeticion($event.value)"
              styleClass="form-control p-0"
              placeholder="Seleccione un tipo petición"
              [options]="listaTipoPeticiones"
              formControlName="tipopeticion"
              optionLabel="descripcion"
              [filter]="true"
            >
            </p-dropdown>
          </div>
        </div>
      </p-accordionTab>
      <p-accordionTab header="Datos Basicos">
        <div class="row">
          <div class="mb-3 col-sm-12 col-lg-6">
            <label for="lineasVentas" class="form-labe l"
              >Lineas de venta <span class="text-danger">*</span></label
            >
            <p-dropdown
              id="lineasVentas"
              dataKey="idDescripcion"
              (onFocus)="quitarEstiloOB('lineasVentas')"
              (onChange)="cambioLineaVenta()"
              styleClass="form-control p-0"
              placeholder="Seleccione una linea de venta"
              [options]="listaLineasVenta"
              formControlName="lineasVentas"
              optionLabel="idDescripcion"
              [filter]="true"
            >
            </p-dropdown>
          </div>
          <div class="mb-3 col-sm-12 col-lg-6">
            <label for="actividades" class="form-label"
              >Actividades / Departamentos
              <span class="text-danger">*</span></label
            >
            <p-dropdown
              id="actividades"
              (onFocus)="quitarEstiloOB('actividades')"
              (onChange)="cambioActividad()"
              [options]="listaActividades"
              styleClass="form-control p-0"
              formControlName="actividades"
              optionLabel="idDescripcion"
            >
            </p-dropdown>
          </div>
          <div class="mb-3 col-sm-12 col-lg-6">
            <label for="empresa" class="form-label"
              >Empresa <span class="text-danger">*</span></label
            >
            <p-dropdown
              id="empresa"
              (onFocus)="quitarEstiloOB('empresa')"
              [options]="listaEmpresas"
              styleClass="form-control p-0"
              formControlName="empresa"
              optionLabel="idDescripcion"
            >
            </p-dropdown>
          </div>
        </div>

        <div class="row">
          <div class="mb-3 col-sm-12 col-lg-4">
            <label for="provincias" class="form-label"
              >Provincias <span class="text-danger">*</span></label
            >
            <p-dropdown
              *ngIf="usuario.provincias"
              id="provincias"
              (onFocus)="quitarEstiloOB('provincias')"
              (onChange)="cambioProvincias()"
              styleClass="form-control p-0"
              placeholder="Seleccione una provincia"
              [options]="listaProvincias"
              formControlName="provincias"
              optionLabel="idDescripcion"
              [filter]="true"
            >
            </p-dropdown>
          </div>
          <div class="mb-3 col-sm-12 col-lg-4">
            <label for="ambitos" class="form-label"
              >Ambitos <span class="text-danger">*</span></label
            >
            <p-dropdown
              id="ambitos"
              (onFocus)="quitarEstiloOB('ambitos')"
              (onChange)="cambioAmbitos()"
              [options]="listaAmbitos"
              styleClass="form-control p-0"
              formControlName="ambitos"
              optionLabel="idDescripcion"
            >
            </p-dropdown>
          </div>
          <div class="mb-3 col-sm-12 col-lg-4">
            <label for="zonas" class="form-label"
              >Zonas <span class="text-danger">*</span></label
            >
            <p-dropdown
              id="zonas"
              (onFocus)="quitarEstiloOB('zonas')"
              [options]="listaZonas"
              styleClass="form-control p-0"
              formControlName="zonas"
              optionLabel="idDescripcion"
            >
            </p-dropdown>
          </div>
        </div>

        <div class="row">
          <div class="mb-3 col-sm-12 col-lg-6">
            <p-checkbox
              id="trabajadorAfectado"
              formControlName="checktrabajadorafectado"
              styleClass="p-0"
              [binary]="true"
              label="¿Tiene trabajador afectado?"
              (onChange)="cambioTrabajadorAfectado()"
            ></p-checkbox>
          </div>
        </div>

        <div class="row" *ngIf="marcarcadoTrabajadorAfectado()">
          <div class="mb-3 col-sm-12 col-lg-4">
            <label for="dnitrabajador" class="form-label"
              >DNI/NIE Trabajador Afectado
              <span class="text-danger" *ngIf="marcarcadoTrabajadorAfectado()"
                >*</span
              >
            </label>
            <input
              id="dnitrabajador"
              placeholder="Ej: 00000000X"
              class="form-control"
              (focus)="quitarEstiloOB('dnitrabajador')"
              formControlName="dnitrabajador"
              pattern="^([0-9]{8}[A-Z])|[XYZ][0-9]{7}[A-Z]$"
              trim="blur"
              maxlength="9"
              (change)="cambioDniTrabajador()"
              (keyup)="toUpperCase($event)"
            />
            <small
              class="form-text text-danger"
              *ngIf="
                !formulario.get('dnitrabajador')!.valid &&
                formulario.get('dnitrabajador')!.touched
              "
              >* Formato de Documento incorrecto</small
            >
          </div>
          <div class="mb-3 col-sm-12 col-lg-4">
            <label for="nombretrabajador" class="form-label"
              >Nombre Trabajador Afectado
              <span class="text-danger" *ngIf="marcarcadoTrabajadorAfectado()"
                >*</span
              ></label
            >
            <input
              id="nombretrabajador"
              type="name"
              placeholder="Nombre"
              (focus)="quitarEstiloOB('nombretrabajador')"
              ebppInputTextTrimmer
              formControlName="nombretrabajador"
              class="form-control"
              trim="blur"
              (change)="cambioNombreTrabajador()"
            />
          </div>

          <div class="mb-3 col-sm-12 col-lg-4">
            <label for="apellidostrabajador" class="form-label"
              >Apellidos Trabajador Afectado
              <span class="text-danger" *ngIf="marcarcadoTrabajadorAfectado()"
                >*</span
              ></label
            >
            <input
              id="apellidostrabajador"
              type="name"
              placeholder="Apellidos"
              (focus)="quitarEstiloOB('apellidostrabajador')"
              [disabled]="true"
              ebppInputTextTrimmer
              formControlName="apellidostrabajador"
              class="form-control"
              trim="blur"
              (change)="cambioApellidoTrabajador()"
            />
          </div>
        </div>

        <div
          class="row"
          *ngIf="formulario.get('checktrabajadorafectado')?.value"
        >
          <div class="mb-3 col-sm-12 col-lg-4">
            <label for="email" class="form-label">Email Trabajador</label>
            <input
              class="form-control"
              id="email"
              (focus)="quitarEstiloOB('email')"
              placeholder="usuario@email.com"
              formControlName="email"
              aria-describedby="emailHelp"
              trim="blur"
              (change)="cambioEmailTrabajador()"
            />
            <small
              class="form-text text-danger"
              *ngIf="
                !formulario.get('email')!.valid &&
                formulario.get('email')!.touched &&
                formulario.controls.email.errors?.pattern
              "
              >* Formato de email incorrecto</small
            >
          </div>
        </div>

        <div class="row">
          <div class="mb-3 col-sm-12 col-lg-4">
            <label for="cliente" class="form-label"
              >Cliente / Proyecto
              <span class="text-danger" *ngIf="clienteAfectado">*</span></label
            >
            <input
              id="cliente"
              type="name"
              placeholder="Cliente / Proyecto"
              (focus)="quitarEstiloOB('cliente')"
              [disabled]="true"
              ebppInputTextTrimmer
              formControlName="cliente"
              class="form-control"
              trim="blur"
              maxlength="100"
            />
          </div>

          <div class="mb-3 col-sm-12 col-lg-4">
            <label for="denominacion" class="form-label"
              >Denominación del servicio</label
            >
            <input
              type="name"
              placeholder="Denominación"
              ebppInputTextTrimmer
              formControlName="denominacion"
              class="form-control"
              id="denominacion"
              trim="blur"
              maxlength="100"
            />
          </div>
        </div>

        <div class="row">
          <div class="mb-3 col-sm-12 col-lg-6">
            <label for="convenio" class="form-label"
              >Convenio colectivo de la aplicación</label
            >
            <p-dropdown
              id="row-convenio"
              styleClass="form-control p-0"
              [options]="listaConvenios"
              [style]="{ maxWidth: '600%' }"
              placeholder="Seleccione un convenio"
              formControlName="convenio"
              optionLabel="idDescripcion"
              optionValue="id"
              [filter]="true"
              [showClear]="true"
            >
            </p-dropdown>
          </div>
        </div>
      </p-accordionTab>
      <p-accordionTab header="Otros Datos">
        <div class="row">
          <div class="mb-3 col-sm-12 col-lg-5">
            <label for="area" class="form-label"
              >Urgencia <span class="text-danger">*</span></label
            >
            <p-dropdown
              id="urgencia"
              (onFocus)="quitarEstiloOB('urgencia')"
              styleClass="form-control p-0"
              formControlName="urgencia"
              [options]="listaUrgencias"
              optionLabel="descripcion"
              [filter]="true"
            >
            </p-dropdown>
          </div>
          <div class="mb-3 col-sm-12 col-lg-5">
            <label for="gruposoporte" class="form-label"
              >Grupo Soporte <span class="text-danger">*</span></label
            >
            <p-dropdown
              id="gruposoporte"
              (onFocus)="quitarEstiloOB('gruposoporte')"
              (onChange)="cambiarGrupoSoporte()"
              [options]="listaGruposSoporte"
              styleClass="form-control p-0"
              placeholder="Seleccione un grupo soporte"
              formControlName="gruposoporte"
              optionLabel="idDescripcion"
              [filter]="true"
            >
            </p-dropdown>
          </div>
        </div>
        <div class="row">
          <div class="mb-3 col-sm-12 col-lg-5">
            <label for="area" class="form-label"
              >Destinatario de la solicitud
              <span class="text-danger">*</span></label
            >
            <p-dropdown
              id="destinatariosolicitud"
              (onFocus)="quitarEstiloOB('destinatariosolicitud')"
              styleClass="form-control p-0"
              placeholder="Seleccione un destinatario"
              formControlName="destinatariosolicitud"
              optionLabel="email"
              [options]="listaDestinatarios"
              [filter]="true"
              (onChange)="cambioDestinatario()"
            >
              <label for="destinatariosolicitud" class="form-label"
                >Destinatario de la solicitud</label
              >
            </p-dropdown>
          </div>
          <div
            class="mb-3 col-sm-12 col-lg-5"
            *ngIf="listaDestinatarios && listaDestinatarios.length >= 1"
          >
            <label for="destinatario" class="form-label"
              >Nombre Destinatario</label
            >
            <input
              type="name"
              readonly
              placeholder="Nombre Destinatario"
              ebppInputTextTrimmer
              formControlName="destinatario"
              class="form-control"
              id="destinatario"
              trim="blur"
            />
          </div>
        </div>
        <div *ngIf="mostraFechaResolucion" class="row">
          <div class="mb-3 col-sm-12 col-lg-6">
            <label for="fechatope" class="form-label"
              >Fecha tope resolución</label
            >
            <br />
            <p-calendar
              [yearNavigator]="true"
              yearRange="1900:2060"
              [firstDayOfWeek]="1"
              formControlName="fechatope"
              [showIcon]="true"
              [showButtonBar]="true"
              id="fechatope"
              dateFormat="dd-mm-yy"
              placeholder="dd-mm-aaaa"
              [(ngModel)]="fechaTopeForm"
              (onSelect)="fechaHoyMayorFechaResolucion()"
            ></p-calendar>
            <strong class="form-text text-info" *ngIf="esMenorFechaHoy"
              >La fecha que se seleccione debe ser mayor o igual al día de
              hoy.</strong
            >
          </div>
        </div>
      </p-accordionTab>
    </p-accordion>
    <div class="botones derecha">
      <button
        type="submit"
        *ngIf="peticionService.esModificable()"
        class="btn btn-outline-info"
        (click)="guardarIdentificacion()"
        [disabled]="!peticionService.esModificable()"
      >
        Borrador
      </button>
      <button
        id="siguiente"
        type="submit"
        class="btn customButton"
        (click)="nextPage()"
      >
        Siguiente
      </button>
    </div>
  </form>
</div>

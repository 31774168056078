<ngx-spinner></ngx-spinner>
<app-mensaje #mensaje></app-mensaje>
<app-crear-catalogo-base [activeIndex]="1"></app-crear-catalogo-base>
<div class="container">
  <h5 class="mt-3 mb-4 title-header">Plantillas de Automatismos</h5>
  <form [formGroup]="formulario" *ngIf="formulario">
    <div
      class="row"
      *ngIf="catalogo.tipoFlujo !== 'AUTOMATISMO'; else esAutomatismo"
    >
      <div class="col alert alert-warning text-center" role="alert">
        Este tipo de petición no es de tipo Automatismo
      </div>
    </div>
    <ng-template #esAutomatismo>
      <div class="row" *ngIf="loaded">
        <div
          class="col-sm-12 col-9 alert-warning pb-4 pt-1 help-box"
          style="margin-left: 0.9rem"
          role="alert"
        >
          <p>
            Introducir el identificador de Google Drive de la plantilla del tipo
            de petición según la empresa. El identificador necesario se obtiene
            del enlace de cada una de las plantillas como en el siguiente
            ejemplo:
          </p>
          <div class="sample-link mt-2">
            <span style="white-space: pre-wrap" class="font-weight-bold"
              >https://docs.google.com/document/d/</span
            ><span
              class="font-weight-bold text-danger"
              style="border: 1px solid red; padding: 0.3rem"
              >ID_PLANTILLA</span
            ><span class="font-weight-bold">/edit</span>
          </div>
        </div>
        <div
          class="col-sm-12 col-lg-5 mt-3 item-form-automatismo"
          *ngFor="let empresa of empresas"
        >
          <ng-container *ngIf="existePlantilla(empresa); else noLink">
            <a class="plantilla-link" (click)="abrirPlantilla(empresa)">
              <label
                [for]="'automatismo-empresa-' + empresa.id"
                class="form-label font-weight-bold plantilla-link"
                >{{ empresa.descripcion }} <em class="fa fa-external-link"></em
              ></label>
            </a>
          </ng-container>
          <ng-template #noLink>
            <label
              [for]="'automatismo-empresa-' + empresa.id"
              class="form-label font-weight-bold"
              >{{ empresa.descripcion }}</label
            >
          </ng-template>
          <input
            class="form-control"
            id="'automatismo-empresa-' + e.id"
            class="form-control"
            placeholder="Introduzca ID de Drive para la plantilla"
            [formControlName]="'automatismo-empresa-' + empresa.id"
            trim="blur"
            maxlength="70"
          />
        </div>
      </div>
      <div class="row mt-1">
        <div class="mb-3 col-sm-12 col-lg-3">
          <div class="p-field-checkbox">
            <p-checkbox
              class="customLabel"
              styleClass="p-0"
              [binary]="true"
              formControlName="activo"
              label="Activar en Catálogo"
            ></p-checkbox>
          </div>
        </div>
      </div>
    </ng-template>
  </form>
  <div class="botones espacio mb-5 mt-2">
    <button
      id="siguiente"
      *ngIf="catalogo && catalogo.tipoFlujo === 'AUTOMATISMO'"
      style="float: right"
      type="submit"
      (click)="actualizarPlantillas()"
      class="btn customButton"
      [disabled]="!formulario.dirty || !formulario.valid"
    >
      Guardar
    </button>
    <button
      id="anterior"
      type="submit"
      class="btn customButton"
      (click)="volver()"
    >
      Anterior
    </button>
  </div>
</div>

import { Component, EventEmitter, Output, NgZone } from '@angular/core';
import { MessageService } from 'primeng/api';
import { LoginService } from '../../servicios/login/login.service';
import { HandlerErrorService } from '../../servicios/errores/handler-error.service';
import { ModalError } from '../../modelos/modal-error';
import { Router } from '@angular/router';

@Component({
  selector: 'app-mensaje',
  templateUrl: './mensaje.component.html',
  styleUrls: ['./mensaje.component.scss'],
  providers: [MessageService],
})
export class MensajeComponent {
  public modalMensaje: ModalError;
  block = false;
  @Output()
  public customAceptar: EventEmitter<number>;

  @Output()
  public customCerrar: EventEmitter<number>;
  public error: any;

  constructor(
    private messageService: MessageService,
    public handlerErrorService: HandlerErrorService,
    private loginService: LoginService,
    private router: Router,
    private ngZone: NgZone
  ) {
    this.customAceptar = new EventEmitter<number>();
    this.customCerrar = new EventEmitter<number>();
    this.modalMensaje = {
      mensaje: '',
      title: '',
      code: 0,
      inicio: false,
    };
    this.error = {};
  }

  public showError(datoError: any, ruta?: string): void {
    this.error = datoError;
    this.modalMensaje = this.handlerErrorService.handlerError(datoError, ruta);
    if (datoError.status !== 403) {
      this.messageService.add({ key: 'error', sticky: true, severity: 'warn' });
    }
  }

  public showMensaje(msg: string, ruta?: string): void {
    this.modalMensaje = {
      mensaje: msg,
      title: 'Operacion correcta',
      code: 200,
      inicio: false,
    };
    this.messageService.add({ key: 'ok', severity: 'success' });
    if (ruta !== undefined) {
      this.redirigir(ruta);
    }
  }

  public redirigir(ruta: string): void {
    this.messageService.clearObserver.subscribe(() => {
      this.ngZone.run(() => this.router.navigate([ruta]));
    });
  }

  public onReject(): void {
    this.messageService.clear('error');
    this.block = false;
    this.customAceptar.emit();
  }

  public onConfirm(): void {
    this.block = false;
    this.messageService.clear();
    this.customAceptar.emit(1);
  }

  public showConfirmacionPeticion(msg: string): void {
    this.messageService.clear();
    this.block = true;
    this.modalMensaje = {
      mensaje: msg,
      title: 'Petición',
      code: 200,
      inicio: false,
    };
    this.messageService.add({
      key: 'confirm',
      severity: 'warn',
      sticky: true,
    });
  }

  public showErrorPeticion(msg: string): void {
    this.modalMensaje = {
      mensaje: msg,
      title: 'Operación no válida',
      code: 400,
      inicio: false,
    };
    this.messageService.add({
      key: 'error',
      severity: 'error',
    });
  }

  public cerrar(): void {
    this.block = false;
    this.messageService.clear();
    this.customCerrar.emit(1);
  }

  /*
  public signIn(): void {
    this.loginService.signIn();
    this.onReject();
  }

  public signOut(): void {
    this.loginService.signOut();
    this.onReject();
  }
*/
}

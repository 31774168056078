<ngx-spinner></ngx-spinner>
<app-mensaje #mensaje></app-mensaje>
<div class="container">
  <h1 class="mt-4 mb-4">
    Datos Específicos de la Petición: {{ idTipoPeticion }}
  </h1>

  <form [formGroup]="formulario" *ngIf="formulario">
    <div class="row" *ngIf="catalogo && catalogo.editable !== 1">
      <div class="col alert alert-warning text-center" role="alert">
        Este tipo de petición no se puede Editar.
      </div>
    </div>

    <div *ngFor="let e of datosEspecificosLst; index as i">
      <div
        class="row border rounded mt-4 mb-4"
        [ngClass]="i % 2 === 0 ? 'border-primary' : 'border-success'"
      >
        <div class="mt-3 mb-3 col-sm-12 col-lg-10">
          <div class="row">
            <div class="mb-3 col-sm-14 col-lg-1">
              <label for="orden" class="form-label">Orden</label>
              <input
                class="form-control"
                id="{{ e.idHtml + 'O' }}"
                formControlName="{{ e.idHtml + 'O' }}"
              />
            </div>
            <div class="mb-3 col-sm-12 col-lg-5">
              <label for="descripcion" class="form-label">Descripción</label>
              <input
                class="form-control"
                id="{{ e.idHtml + 'D' }}"
                formControlName="{{ e.idHtml + 'D' }}"
              />
            </div>
            <div class="mb-3 col-sm-12 col-lg-3">
              <label for="tipo" class="form-label">Tipo</label>
              <p-dropdown
                id="{{ e.idHtml + 'T' }}"
                styleClass="form-control p-0"
                [options]="listaTipos"
                formControlName="{{ e.idHtml + 'T' }}"
              >
              </p-dropdown>
            </div>
            <div class="mb-3 col-sm-12 col-lg-3">
              <div class="p-field-checkbox mt-4">
                <p-checkbox
                  styleClass="p-0"
                  [binary]="true"
                  formControlName="{{ e.idHtml + 'Ob' }}"
                  label="Obligatorio"
                ></p-checkbox>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="mb-3 col-sm-12 col-lg-8">
              <label for="ayuda" class="form-label">Ayuda</label>
              <input
                class="form-control"
                id="{{ e.idHtml + 'A' }}"
                formControlName="{{ e.idHtml + 'A' }}"
              />
            </div>
            <div class="mb-3 col-sm-12 col-lg-4">
              <label for="combo" class="form-label">Combo</label>
              <p-dropdown
                id="{{ e.idHtml + 'C' }}"
                styleClass="form-control p-0"
                [options]="combosValores"
                formControlName="{{ e.idHtml + 'C' }}"
              >
              </p-dropdown>
            </div>
          </div>
        </div>

        <div class="mb-3 col-sm-12 col-lg-2 col align-self-center">
          <button
            type="button"
            class="btn btn-sm btn-outline-danger mr-3"
            (click)="eliminarDatoEspecifico(e.id)"
          >
            <em class="fa fa-trash" aria-hidden="true"></em> Eliminar
          </button>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              Crear Dato Específico
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="mb-3 col-sm-12 col-lg-2">
                <label for="orden" class="form-label">Orden</label>
                <input
                  class="form-control"
                  id="orden"
                  formControlName="orden"
                />
              </div>
              <div class="mb-3 col-sm-12 col-lg-6">
                <label for="descripcion" class="form-label">Descripción</label>
                <input
                  class="form-control"
                  id="descripcion"
                  formControlName="descripcion"
                />
              </div>
              <div class="mb-3 col-sm-12 col-lg-4">
                <label for="tipo" class="form-label">Tipo</label>
                <p-dropdown
                  id="tipo"
                  styleClass="form-control p-0"
                  [options]="listaTipos"
                  formControlName="tipo"
                >
                </p-dropdown>
              </div>

              <div class="mb-3 col-sm-12 col-lg-12">
                <label for="ayuda" class="form-label">Ayuda</label>
                <input
                  class="form-control"
                  id="ayuda"
                  formControlName="ayuda"
                />
              </div>
              <div class="mb-3 col-sm-12 col-lg-6">
                <label for="combo" class="form-label">Combo</label>
                <p-dropdown
                  id="combo"
                  styleClass="form-control p-0"
                  [options]="combosValores"
                  formControlName="combo"
                >
                </p-dropdown>
              </div>
              <div class="mb-3 col-sm-12 col-lg-10">
                <div class="p-field-checkbox">
                  <p-checkbox
                    styleClass="p-0"
                    [binary]="true"
                    formControlName="obligatorio"
                    label="Obligatorio"
                  ></p-checkbox>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Cerrar
            </button>
            <button
              type="submit"
              class="btn btn-primary"
              [disabled]="!validadoCombo"
              (click)="callCrearDatoEspecifico()"
            >
              Crear
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="botones d-flex">
      <button
        type="button"
        class="btn btn-outline-primary customButton mr-auto"
        data-toggle="modal"
        data-target="#exampleModal"
        data-whatever="@getbootstrap"
      >
        <em class="fa fa-plus" aria-hidden="true"></em> Crear Dato Especifico
      </button>

      <div class="ml-auto">
        <button
          type="submit"
          class="btn btn-outline-primary customButton mr-3"
          (click)="redirect()"
          [disabled]="false"
        >
          Volver
        </button>

        <button
          type="submit"
          class="btn btn-outline-primary customButton"
          (click)="callModificarDatosEspecificos()"
          [disabled]="!validadoGuardar"
        >
          Guardar
        </button>
      </div>
    </div>
  </form>
</div>

<!-- Modal -->
<div
  class="modal fade"
  id="modalDatoEspecifico"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          {{ modalError.title }}
        </h5>
      </div>
      <div class="modal-body">
        {{ modalError.mensaje }}
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="cerrarModal()">
          Cerrar
        </button>
      </div>
    </div>
  </div>
</div>

<app-mensaje #mensaje>
</app-mensaje>
<ngx-spinner>
</ngx-spinner>
<div class="container">
    <h1 class="mt-3 mb-4">{{title}}</h1>
    <form [formGroup]="formulario" *ngIf="formulario">
        <div class="form-group">
            <div class="mb-3">
                <label for="descripcionInput " class="form-label">Nombre Grupo Soporte</label>
                <input class="inputdesc form-control" id="descripcion" placeholder="Nombre del grupo" formControlName="descripcion" trim="blur">
                <small class="form-text text-danger" *ngIf="!formulario.get('descripcion')!.valid && formulario.get('descripcion')!.touched && formulario.get('descripcion')?.value.length < 50">* El campo descripcion es obligatorio</small>
                <small class="form-text text-danger" *ngIf="formulario.get('descripcion')?.value.length > 50">* El campo excede el tamaño maximo</small>
            </div>
        </div>
        <div class="form-group">
            <div class="row">
                <div class="mb-3 col-sm-12 col-lg-6">
                    <label for="destinatarios" class="form-label">Destinatario Peticiones</label>
                    <p-multiSelect id="destinatarios" filterPlaceHolder="Buscar" [defaultLabel]="'Seleccione puestos destinatarios'" [virtualScroll]="true" [options]="listaPuestos" optionLabel="descripcion" formControlName="destinatarios" styleClass="form-control p-0"></p-multiSelect>
                    <small class="form-text text-danger" *ngIf="!formulario.get('destinatarios')!.valid && formulario.get('destinatarios')!.touched">* El campo destinatarios es obligatorio</small>
                </div>
                <div class="mb-3 col-sm-12 col-lg-6">
                    <label for="otros" class="form-label">Otros Integrantes</label>
                    <p-multiSelect id="otros" filterPlaceHolder="Buscar" [defaultLabel]="'Seleccione puestos destinatarios'" [virtualScroll]="true" [options]="listaPuestos" optionLabel="descripcion" formControlName="otros" styleClass="form-control p-0"></p-multiSelect>
                    <small class="form-text text-danger" *ngIf="!formulario.get('otros')!.valid && formulario.get('otros')!.touched">* El campo otros destinatarios es obligatorio</small>
                </div>
            </div>
        </div>
        <div class="form-group">
            <label for="detalles">Detalles</label>
            <textarea class="form-control" formControlName="detalles" id="detalles" rows="3" trim="blur"></textarea>
            <small class="form-text text-danger" *ngIf="!formulario.get('detalles')!.valid && formulario.get('detalles')!.touched && formulario.get('detalles')?.value.length < 250">* El campo detalles es obligatorio</small>
            <small class="form-text text-danger" *ngIf="formulario.get('detalles')?.value.length > 250">* El campo excede el tamaño maximo</small>
        </div>
        <div class=" mt-2 mb-3 d-flex">
            <button class="btn btn-outline-secondary mr-2" (click)="redirect()">Volver</button>
            <button *ngIf="!edicion" type="submit" class="btn btn-outline-primary customButton" (click)="callCrear()" [disabled]="!formulario.valid">Crear grupo soporte</button>
            <button *ngIf="edicion" type="submit" class="btn btn-outline-primary customButton" (click)="callEditar()" [disabled]="!formulario.valid">Modificar grupo soporte</button>
        </div>
    </form>
</div>

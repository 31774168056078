import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { SesionService } from '../sesion/sesion.service';

@Injectable({
  providedIn: 'root'
})
export class AdministradorGuard implements CanActivate {

  constructor(
    private sesionService: SesionService,
    private router: Router
  ) { }

  public canActivate(): boolean {
    if ((this.sesionService.isGoogleUserSignedIn() && this.sesionService.getPermisos().au === 1 )
    || (this.sesionService.getUser() && !this.sesionService.isGoogleUserSignedIn())) {
      return true;
    } else {
      this.router.navigate(['/']);
      return false;
    }
  }
}

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SubjectsService {
  private sesionCaducadaObserver: Subject<any>;
  private logoObserver: Subject<boolean>;
  private emitirError: Subject<any>;

  constructor() {
    this.sesionCaducadaObserver = new Subject<any>();
    this.logoObserver = new Subject<boolean>();
    this.emitirError = new Subject<any>();
  }

  public getSesionCaducadaSubject(): Subject<any> {
    return this.sesionCaducadaObserver;
  }

  public getEmitirError(): Subject<any> {
    return this.emitirError;
  }

  public emitirEmitirError(error: any): void {
    this.emitirError.next(error);
  }

  public emitirSesionCaducada(error: any): void {
    this.sesionCaducadaObserver.next(error);
  }

  public getLogoSubject(): Subject<any> {
    return this.logoObserver;
  }

  public emitirLogoCambiado(mostrar: boolean): void {
    this.logoObserver.next(mostrar);
  }
}

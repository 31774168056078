import {
  AfterContentInit,
  Component,
  OnInit,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { CatalogosService } from '../../../servicios/catalogos/catalogos.service';
import { Catalogo } from '../../../modelos/catalogo';
import { Constantes } from '../../../utils/constantes';
import { NgxSpinnerService } from 'ngx-spinner';
import { MensajeComponent } from '../../mensaje/mensaje.component';
import { Table } from 'primeng/table';
import Utils from '../../../utils/utils';
import {
  CatalogoFiltros,
  CatalogoFiltrosRequest,
} from '../../../modelos/catalogo-filtros';
import { MatDialog } from '@angular/material/dialog';
import { ModalInfoComponent } from '../../modals/modal-info/modal-info.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-tabla-catalogos',
  templateUrl: './tabla-catalogos.component.html',
  styleUrls: ['./tabla-catalogos.component.scss'],
})
export class TablaCatalogosComponent implements OnInit, AfterContentInit {
  constructor(
    private constantes: Constantes,
    public dialog: MatDialog,
    private catalogoService: CatalogosService,
    private spinner: NgxSpinnerService,
    private router: Router
  ) {}

  catalogos: Catalogo[] = [];
  cols!: any[];
  first = 1;
  rows = 25;
  lastSortField!: any;
  paginasTotales = 0;
  paginaActual = 1;
  resultadosTotales = 0;
  registrosPorPagina!: number[];

  visualSeleccionada = 1;

  private filtros!: CatalogoFiltros;
  filtrosRequest: CatalogoFiltrosRequest = {};

  @ViewChild('mensaje', { static: false })
  public mensaje!: MensajeComponent;

  @ViewChildren('dt1')
  public table!: Table;

  ngOnInit(): void {
    this.loadData(null);
  }

  ngAfterContentInit(): void {
    if (this.catalogoService.getFiltros()) {
      this.first = this.filtros.currentPage;
      this.rows = this.filtros.recordsPerPage;
      this.paginaActual = this.first;
      if (this.table) {
        this.table.first = (this.paginaActual - 1) * this.rows;
      }
      this.refreshCols();
    }
  }

  loadData(filtros: any): void {
    this.filtrosRequest = filtros;
    this.spinner.show();
    this.refreshCols();
    if (this.catalogoService.getFiltros()) {
      this.filtros = this.catalogoService.getFiltros();
      this.first = 1;
      this.rows = this.filtros.recordsPerPage;
      this.visualSeleccionada = this.filtros.visualSeleccionada;
    }
    this.registrosPorPagina = this.constantes.numeroUsuariosPorPagina;
    if (filtros) {
      this.catalogoService
        .getCatalogosListPaged(this.first, this.rows, this.filtrosRequest)
        .subscribe(
          (data: any) => {
            this.cargarDatosTabla(data, true);
            this.spinner.hide();
          },
          () => {
            this.spinner.hide();
          }
        );
    } else {
      this.cargarDatosTabla(
        { results: null, totalPages: 0, totalResults: 0, currentPage: 0 },
        true
      );
      this.spinner.hide();
    }
  }

  private cargarDatosTabla(data: any, refresh = false): any {
    this.catalogos = data.results;
    this.paginaActual = refresh ? 1 : data.currentPage;
    this.paginasTotales = data.totalPages;
    this.resultadosTotales = data.totalResults;
    this.customSort(this.lastSortField);
  }

  public refreshCols(): void {
    this.cols = [
      {
        field: 'tipoPeticion',
        header: 'ID',
        toolTip: '',
        sortable: true,
        visible: true,
        style: 'max-width: 13% !important;',
      },
      {
        field: 'descPeticion',
        header: 'TIPO PETICIÓN',
        toolTip: '',
        sortable: true,
        visible: true,
        style:
          +this.visualSeleccionada === 1
            ? 'max-width: none;'
            : 'min-width: 17% !important;',
      },
      {
        field: 'areaDestino',
        header: 'ÁREA DESTINO',
        toolTip: '',
        sortable: true,
        visible: +this.visualSeleccionada === 1,
        style: 'max-width: 20% !important;',
      },
      {
        field: 'tipoFlujo',
        header: 'TIPO FLUJO',
        toolTip: '',
        sortable: true,
        visible: +this.visualSeleccionada === 1,
        style: '',
      },
      {
        field: 'esfuerzo',
        header: 'ESFUERZO',
        toolTip: '',
        sortable: true,
        visible: +this.visualSeleccionada === 1,
        style: 'max-width: 18% !important;',
      },
      {
        field: 'subirArtica',
        header: 'ÁRTICA',
        toolTip: '',
        sortable: true,
        visible: +this.visualSeleccionada === 1,
        style: 'max-width: 13% !important;',
      },
      {
        field: 'activo',
        header: 'ACTIVO',
        toolTip: '',
        sortable: true,
        visible: +this.visualSeleccionada === 1,
        style: 'max-width: 13% !important;',
      },
      {
        field: 'datosEspecificos',
        header: 'DATOS ESPECÍFICOS',
        toolTip: '',
        sortable: false,
        visible: +this.visualSeleccionada === 2,
        style: 'width: 11% !important;',
      },
      {
        field: 'datosEfectividad',
        header: 'DATOS EFECTIVIDAD',
        toolTip: '',
        sortable: false,
        visible: +this.visualSeleccionada === 2,
        style: 'width: 11% !important;',
      },
      {
        field: 'impacto',
        header: 'IMPACTO',
        toolTip: '',
        sortable: true,
        visible: +this.visualSeleccionada === 2,
        style: 'width: 12% !important;',
      },
      {
        field: 'fechaResolucion',
        header: 'FECHA RESOLUCIÓN',
        toolTip: '',
        sortable: false,
        visible: +this.visualSeleccionada === 2,
        style: 'width: 12% !important;',
      },
      {
        field: 'calcularSLA',
        header: 'SLA',
        toolTip: '',
        sortable: false,
        visible: +this.visualSeleccionada === 2,
        style: 'width: 7% !important;',
      },
      {
        field: 'escalado',
        header: 'ESCALADO',
        toolTip: '',
        sortable: false,
        visible: +this.visualSeleccionada === 2,
        style: 'width: 10% !important;',
      },
      {
        field: 'acciones',
        header: 'ACCIONES',
        sortable: false,
        visible: true,
        style: 'max-width: 12% !important',
      },
    ];
  }

  nextPage(e: any, table: Table): void {
    if (this.rows !== e.rows) {
      this.rows = e.rows;
      e.first = 0;
      this.paginaActual = 1;
      const offset = e.first / this.rows + 1;
      table.first = offset;
      this.first = offset;
      this.rows = e.rows;
      this.getCatalogos(offset);
    } else {
      // verificar si se ha seleccionado un filtro nuevo, en caso contrario, está paginando
      if (
        (e.sortField && !this.lastSortField) ||
        (e.sortField && this.hasChanged(e.sortField, e.sortOrder))
      ) {
        this.lastSortField = {
          sortField: e.sortField,
          sortOrder: e.sortOrder,
        };
        this.customSort(this.lastSortField);
        table.first = (this.paginaActual - 1) * this.rows;
      } else {
        const offset = e.first / this.rows + 1;
        this.rows = e.rows;
        this.first = offset;
        this.getCatalogos(offset);
      }
    }
  }

  private customSort(sort: any): void {
    if (sort && this.catalogos) {
      this.catalogos.sort((a: Catalogo, b: Catalogo) => {
        switch (sort.sortField) {
          case this.cols[0].field:
            return Utils.sortString(
              Utils.stringNotNull(a.id),
              Utils.stringNotNull(b.id),
              sort.sortOrder
            );
          case this.cols[1].field:
            return Utils.sortString(
              Utils.stringNotNull(a.descripcion),
              Utils.stringNotNull(b.descripcion),
              sort.sortOrder
            );
          case this.cols[2].field:
            return Utils.sortString(
              Utils.stringNotNull(a.areaDestino),
              Utils.stringNotNull(b.areaDestino),
              sort.sortOrder
            );
          case this.cols[3].field:
            return Utils.sortString(
              Utils.stringNotNull(a.tipoFlujo),
              Utils.stringNotNull(b.tipoFlujo),
              sort.sortOrder
            );
          case this.cols[4].field:
            return Utils.sortNumber(
              +Utils.stringNotNull(a.tiempoPromedio),
              +Utils.stringNotNull(b.tiempoPromedio),
              sort.sortOrder
            );
          case this.cols[5].field:
            return Utils.sortString(
              Utils.stringNotNull(a.subirArtica?.toString()),
              Utils.stringNotNull(b.subirArtica?.toString()),
              sort.sortOrder
            );
          case this.cols[6].field:
            return Utils.sortString(
              Utils.stringNotNull(a.activo?.toString()),
              Utils.stringNotNull(b.activo?.toString()),
              sort.sortOrder
            );
          case this.cols[9].field:
            return Utils.sortString(
              Utils.stringNotNull(a.impacto?.descripcion),
              Utils.stringNotNull(b.impacto?.descripcion),
              sort.sortOrder
            );
          default:
            return sort.sortOrder;
        }
      });
    }
  }

  private hasChanged(sort: string, order: number): boolean {
    return (
      this.lastSortField.sortField !== sort ||
      this.lastSortField.sortOrder !== order
    );
  }

  public activarVista(visual: number): void {
    this.visualSeleccionada = visual;
    this.catalogoService.setFiltros({
      currentPage: this.first === 0 ? 1 : this.first,
      recordsPerPage: this.rows,
      visualSeleccionada: this.visualSeleccionada,
    });
    this.refreshCols();
  }

  getCatalogos(first: number): void {
    this.catalogoService.setFiltros({
      currentPage: first,
      recordsPerPage: this.rows,
      visualSeleccionada: this.visualSeleccionada,
    });

    this.spinner.show();
    this.catalogoService
      .getCatalogosListPaged(first, this.rows, this.filtrosRequest)
      .subscribe(
        (data: any) => {
          this.cargarDatosTabla(data);
          if (this.table) {
            this.table.first = (this.paginaActual - 1) * this.rows;
          }
          this.spinner.hide();
        },
        (error: any) => {
          console.error(error);
          this.catalogos = [];
          this.spinner.hide();
        }
      );
  }

  exportarDatos(): void {
    this.spinner.show();
    this.catalogoService
      .exportar(this.filtrosRequest)
      .subscribe((urlRespuesta) => {
        if (urlRespuesta) {
          // Abrir nueva pantalla con el resultado del exportar
          this.spinner.hide();
          window.open(
            urlRespuesta.url,
            '_blank',
            'location=yes, height=570, width=520, scrollbars=yes, status=yes'
          );
        } else {
          const data = {
            title: 'Información',
            msg: 'No se ha podido hacer la exportación ...',
          };
          this.dialog.open(ModalInfoComponent, {
            disableClose: true,
            autoFocus: false,
            data,
          });
        }
      });
  }

  crearCatalogo(): void {
    this.router.navigate(['/catalogos/crear']);
  }

  editarCatalogo(id: string): void {
    this.router.navigate([`/catalogos/${id}`]);
  }

  editarDatosEspecificos(id: string): void {
    this.router.navigate([`/catalogos/${id}/datos-especificos`]);
  }
}

import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Peticion } from '../../../modelos/peticion';
import { PeticionService } from '../../../servicios/peticion/peticion.service';
import { Puesto } from '../../../modelos/puesto';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MensajeComponent } from '../../mensaje/mensaje.component';
import { Aclaracion } from '../../../modelos/aclaracion';
import { NgxSpinnerService } from 'ngx-spinner';
import { SesionService } from '../../../servicios/sesion/sesion.service';
import { Usuario } from '../../../modelos/usuario';
import { Constantes } from '../../../utils/constantes';
import { Fichero } from 'src/app/modelos/fichero';
import Utils from 'src/app/utils/utils';
import { UsuariosService } from 'src/app/servicios/usuarios/usuarios.service';
import { Informacion } from '../../../modelos/informacion';
import { FileUpload } from 'primeng/fileupload';

@Component({
  selector: 'app-detalle-peticion',
  templateUrl: './detalle-peticion.component.html',
  styleUrls: ['./detalle-peticion.component.scss'],
})
export class DetallePeticionComponent implements OnInit {
  // tslint:disable-next-line:variable-name
  _peticion!: Peticion;

  public uploadedFiles: Fichero[] = [];
  public contador = 0;
  public datosEfectividad = false;
  public datosEspecificos = false;
  public comentar = false;
  public fechaTextoEfectividad = '';
  public modificable = true;
  public formulario!: FormGroup;
  estado = 1;
  @ViewChild('mensaje', { static: false })
  public mensaje!: MensajeComponent;

  @Input()
  perfiles!: Puesto[];

  @Input()
  aclaraciones!: Aclaracion[];

  @Input()
  informaciones!: Informacion[];

  @Input()
  comentarioModificable!: boolean;

  @ViewChild('fileInput', { static: false })
  fileInput!: FileUpload;

  usuario!: Usuario;
  usuarioPeticionario!: Usuario;

  @Input()
  set peticion(param: Peticion) {
    this._peticion = param;
    if (this.peticion) {
      this.comprobarSeccionesMostrar();
      this.initForm();
    }
  }

  get peticion(): Peticion {
    return this._peticion;
  }

  constructor(
    public peticionService: PeticionService,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private sesionService: SesionService,
    private userService: UsuariosService,
    public constantes: Constantes
  ) {}

  ngOnInit(): void {
    this.initProperties();
  }

  public initForm(): void {
    this.comentarioModificable =
      this.comentarioModificable &&
      (this.usuario.email === this.peticion.usuarioDestinatario?.email ||
        this.usuario.rol?.id === 1 ||
        this.usuario.rol?.id === 2);
    this.formulario = this.fb.group({
      comentarios: [
        this.peticion?.comentarios === null
          ? { value: '', disabled: !this.comentarioModificable }
          : {
              value: this.peticion?.comentarios,
              disabled: !this.comentarioModificable,
            },
        [Validators.maxLength(3000)],
      ],
    });
    if (
      this.formulario.get('comentarios')?.value &&
      this.formulario.get('comentarios')?.value !== null
    ) {
      this.contador = this.formulario.get('comentarios')?.value.length;
    }
  }

  public comprobarSeccionesMostrar(): void {
    if (this.peticion.valoresEspecificos) {
      this.datosEspecificos = false;
    }
    if (this.peticion.catalogo?.datosEfectividad === 1) {
      this.datosEfectividad = true;
      this.buscarCatalogo();
    }
  }

  private initProperties(): void {
    this.usuario = this.sesionService.getUser();
    const aus2 = this.peticion.usuarioPeticionario?.email;
    if (typeof aus2 === 'string') {
      this.userService.getUsuario(aus2).subscribe(
        (data) => {
          this.usuarioPeticionario = data;
        },
        (error) => {
          this.handleError(error);
        }
      );
    }
    if (this.peticion.ficherosComentarios != null) {
      this.uploadedFiles = this.peticion.ficherosComentarios;
    }
    this.modificable = this.peticionService.esModificable();
    if (this.peticion.calcularSLA === 1) {
      this.peticion.porcentajeRespuestaSLA = +(
        (Utils.numberNotNull(this.peticion.tiempoConsumidoRespuestaSimple) /
          Utils.numberNotNull(this.peticion.tiempoTotalRespuestaSimple)) *
        100
      ).toFixed(2);
      this.peticion.porcentajeResolucionSLA = +(
        (Utils.numberNotNull(this.peticion.tiempoConsumidoResolucionSimple) /
          Utils.numberNotNull(this.peticion.tiempoTotalResolucionSimple)) *
        100
      ).toFixed(2);
    }
    this.initForm();
  }

  public onKeyUp(event: any): void {
    this.contador = event.target.value.length;
  }

  public onKeyPress(event: any): any {
    if (event.keyCode === 39 || event.keyCode === 96 || event.keyCode === 180) {
      return false;
    }
    return true;
  }

  public buscarCatalogo(): void {
    this.fechaTextoEfectividad =
      this.peticion.catalogo?.descFechaEfectividad || '';
  }

  public guardarComentario(): void {
    this.spinner.show();
    const comentario = this.formulario.get('comentarios')?.value.trim();
    this.peticionService
      .comentarPeticion(
        this.peticion.idPeticion,
        comentario,
        this.usuario.email,
        this.fileInput ? this.fileInput.files : []
      )
      .subscribe(
        (result: Peticion) => {
          this.peticion = result;
          this.peticionService.setPeticion(this.peticion);
          this.mensaje.showMensaje('Comentario añadido correctamente.');
          if (result.ficherosComentarios != null) {
            this.uploadedFiles = result.ficherosComentarios;
          }
          this.spinner.hide();
        },
        (error) => {
          this.handleError(error);
        }
      );
    this.fileInput.clear();
  }

  private handleError(error: any): void {
    console.error(error);
    this.mensaje.showError(error);
    this.spinner.hide();
  }

  public descargarFicheroResolucion(fichero: Fichero): void {
    this.spinner.show();
    this.peticionService
      .descargarFichero(fichero.idPeticion, fichero.idFichero)
      .subscribe(
        (fileReceived: any) => {
          this.spinner.hide();
          Utils.base64ToFile(fileReceived.file, fichero.nombre || '');
        },
        (errorDescarga: any) => {
          console.error(errorDescarga);
          this.mensaje.showErrorPeticion(
            'No se ha podido descargar el fichero ' +
              errorDescarga.error.message
          );
          this.spinner.hide();
        }
      );
  }

  public controlForm(): void {
    if (
      this.formulario.get('comentarios')?.value === '' ||
      this.formulario.get('comentarios')?.value === null
    ) {
      this.contador = 0;
    } else {
      this.contador = this.formulario.get('comentarios')?.value.trim().length;
    }
  }

  public cierreFichero(codCierre: number): boolean {
    for (const iterator of this.peticion.ficherosResolucion) {
      if (iterator.codCierre === codCierre) {
        return true;
      }
    }

    return false;
  }

  descargarFichero(fichero: Fichero): void {
    this.spinner.show();
    this.peticionService
      .descargarFicheroComentarios(fichero.idPeticion, fichero.idFichero)
      .subscribe(
        (fileReceived: any) => {
          this.spinner.hide();
          Utils.base64ToFile(fileReceived.file, fichero.nombre);
        },
        (errorDescarga: any) => {
          this.mensaje.showErrorPeticion(
            'No se ha podido descargar el fichero ' + errorDescarga.error
          );
          this.spinner.hide();
        }
      );
  }

  visualizarFichero(fichero: Fichero): void {
    let base: string =
      this.constantes.ENDPOINT_API_PETICIONES_VISUALIZAR_FICHERO_COMENTARIOS.replace(
        '{idPeticion}',
        String(fichero.idPeticion)
      ).replace('{idFichero}', fichero.idFichero);

    let contentType = Utils.calculateMediaType(fichero.nombre.split('.')[1]);
    let finalURL = base;
    if (contentType.includes('doc') || contentType.includes('xls')) {
      finalURL = 'https://view.officeapps.live.com/op/embed.aspx?src=' + base;
    }

    const link = document.createElement('a');
    link.href = finalURL;
    link.target = '_blank';
    link.click();
  }

  eliminarFichero(idPeticion: string, idFichero: string): void {
    this.spinner.show();
    this.peticionService
      .eliminarFicheroComentarios(idPeticion, idFichero)
      .subscribe(
        (result: Peticion) => {
          if (result) {
            this.spinner.hide();
            this.mensaje.showMensaje('Fichero eliminado satisfactoriamente.');
            this.peticionService.setPeticion(result);
            this.peticion = result;
            this.uploadedFiles = this.peticion?.ficheros || [];
          }
        },
        (error) => {
          this.spinner.hide();
          this.mensaje.showErrorPeticion(
            'No se ha podido eliminar el fichero. ' + error.error
          );
        }
      );
  }

  public fijarElemento(event: any): void {
    event.preventDefault();
  }
}

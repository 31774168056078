import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { SesionService } from '../../servicios/sesion/sesion.service';

@Component({
  selector: 'app-principal',
  templateUrl: './principal.component.html',
  styleUrls: ['./principal.component.css']
})
export class PrincipalComponent {

  constructor(
    private router: Router,
    public sesionService: SesionService,
  ) {}

  ngOnInit(): void {}

  public newUser(): void {
    this.router.navigate(['/altas']);
  }

  public isLoggedIn(): boolean {
    return this.sesionService.isGoogleUserSignedIn();
  }

}

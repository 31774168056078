<app-mensaje #mensaje> </app-mensaje>
<ngx-spinner></ngx-spinner>
<div class="contenedorModal" *ngIf="verModalArtica">
  <p-toast
    position="center"
    key="accion"
    styleClass="custom-toast-autorizar"
    (onClose)="cerrarModal()"
    [baseZIndex]="1000"
  >
    <ng-template let-ModalAccion pTemplate="modalAccion">
      <div class="p-flex p-flex-column" style="flex: 1; padding: 1rem 2rem">
        <div style="text-align: center">
          <em class="pi pi-question-circle" style="font-size: 3em"></em>
          <h3>Subir Documento a Ártica</h3>
        </div>
        <p class="descripcion-accion">
          1.- Pulsa enlace de acceso.<br />
          2.- Sube los documentos. <br />
          3.- Acepta este mensaje una vez subida la documentación.<br />
          4.- Cancelar para subir la documentación en otro momento.
        </p>
        <a class="link-artica" *ngIf="linkArtica" (click)="openArtica()"
          >Enlace a Ártica</a
        >
        <div class="row mt-5">
          <div class="col botones-modal">
            <button
              style="margin-right: 1rem"
              type="button"
              (click)="cerrarModal()"
              class="btn btn-danger danger"
            >
              Cancelar
            </button>
            <button
              type="submit"
              (click)="aceptarModal()"
              class="btn btn-success success"
            >
              Aceptar
            </button>
          </div>
        </div>
      </div>
    </ng-template>
  </p-toast>
</div>

<app-mensaje #mensaje>
</app-mensaje>
<ngx-spinner>
</ngx-spinner>
<ngx-spinner name="googleload"
  template="<img src='assets/googleload.gif'/>"
  bdColor="rgba(239,239,239,255)"></ngx-spinner>
<div class="container">
  <app-crear-peticion [activeIndex]="activeIndex"></app-crear-peticion>
  <form [formGroup]="formulario"
    *ngIf="formulario">
    <div class="form-group row">
      <label for="gruposoporte"
        class="col-sm-2 col-form-label">Grupo Soporte</label>
      <div class="col-md-4 mb-3 col-form-label">
        <input class="form-control"
          id="gruposoporte"
          formControlName="gruposoporte"
          trim="blur">
      </div>
    </div>
    <div *ngFor="let e of listaNotificadores"
      class="form-group row">
      <div class="col-md-2 mb-2 col-form-label">
        <label for="e.nombrePerfil">{{e.nombrePerfil}}</label>
      </div>
      <div class="col-md-4 mb-3">
        <p-dropdown id="{{'notificador' + e.idForm}}"
          (ngModelChange)="cambioEmailNotificadores()"
          [disabled]="!peticionService.esModificable()"
          placeholder="Seleccione..."
          [options]="e.valores"
          optionLabel="email"
          styleClass="form-control p-0"
          formControlName="{{'notificador' + e.idForm}}"
          [filter]="true"
          [showClear]="true">
        </p-dropdown>
      </div>
      <div class="col-md-4 mb-3 col-form-label">
        <label id="{{'nombre' + e.idForm}}"
          for="e.nombrePerfil">{{ e.valorDefecto !== undefined && e.valorDefecto !== null ? e.valorDefecto.nombre : ''}}</label>
      </div>
    </div>

    <div class="form-group row">
      <label for="destinatario"
        class="col-sm-2 col-form-label">Destinatario</label>
      <div class="col-md-4 mb-3 col-form-label">
        <input class="form-control"
          id="destinatario"
          formControlName="destinatario"
          trim="blur">
      </div>
    </div>

    <div *ngIf="trabajadorAfectado"
      class="form-group row">
      <label for="trabajador"
        class="col-sm-2 col-form-label">Trabajador</label>
      <div class="col-md-4 mb-3 col-form-label">
        <input class="form-control"
          id="trabajador"
          formControlName="trabajador"
          trim="blur"
          [disabled]="!peticionService.esModificable()">
      </div>
    </div>
    <div class="form-group row">
      <label for="trabajador"
        class="col-sm-2 col-form-label">Otros</label>
      <div class="col-sm-10">
        <p-autoComplete [(ngModel)]="listaOtros"
          formControlName="otros"
          [suggestions]="listaEmailsFiltered"
          [minLength]="3"
          [showEmptyMessage]="true"
          [emptyMessage]="messageOtros"
          (completeMethod)="filterOtros($event)"
          [multiple]="true"
          [disabled]="!peticionService.esModificable()"></p-autoComplete>
        <strong class="form-text text-info">Número de emails máximos: 3.</strong>
      </div>
    </div>

    <div class="derecha botones">
      <button type="submit"
        *ngIf="modificable"
        class="btn btn-outline-info"
        (click)="guardarNotificacion()"
        [disabled]="!formulario.valid && peticionService.esModificable()">Borrador</button>
      <button type="submit"
        class="btn customButton"
        (click)="nextPage()"
        [disabled]="!formulario.valid && peticionService.esModificable()">Siguiente</button>
    </div>
    <div class="botones">
      <button type="submit"
        class="btn customButton"
        (click)="previousPage()">Anterior</button>
    </div>
  </form>
</div>

import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { Peticion } from 'src/app/modelos/peticion';
import { PeticionService } from '../../../../servicios/peticion/peticion.service';
import {
  DatoEspecifico,
  ValorDatoGuardado,
} from '../../../../modelos/dato-especifico';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MensajeComponent } from 'src/app/componentes/mensaje/mensaje.component';
import { UtilsForms } from '../../../../utils/utils-forms';
import Utils from 'src/app/utils/utils';
import { ModalError } from '../../../../modelos/modal-error';
import { NgxSpinnerService } from 'ngx-spinner';

declare let $: any;

@Component({
  selector: 'app-crear-peticion-datos-especificos',
  templateUrl: './crear-peticion-datos-especificos.component.html',
  styleUrls: ['./crear-peticion-datos-especificos.component.scss'],
})
export class CrearPeticionDatosEspecificosComponent implements OnInit {
  @ViewChild('mensaje', { static: false })
  public mensaje!: MensajeComponent;

  activeIndex = 2;

  peticion!: Peticion;
  public modificable = true;

  datosEspecificosLst!: DatoEspecifico[];

  peticionCambio: any = null;

  public formulario!: FormGroup;

  public modalError: ModalError;
  blockButtons: boolean = false;
  blockButtonsDates: boolean = false;
  cambioPeticionManual: boolean = false;
  tipoPeticionManual: string = '';
  static readonly titleError: string = 'ERROR Dato Especifico ID: ';
  static readonly msgError: string =
    'Comprobar la sintaxis de la Regla de Visibilidad del Dato Especifico, formula: ';

  static readonly LOCALE_SPAIN: string = 'es-ES';
  static readonly FORMAT_DATE: string = 'yyyy-MM-dd';

  tipoDatosEspecificosEspeciales = ['SEPARADOR', 'LABEL'];

  tipoCombosDatosEspecificosEspeciales = [
    'PROVINCIAS',
    'EMPRESAS',
    'ACTIVIDADES',
    'LV',
  ];

  constructor(
    private router: Router,
    private peticionService: PeticionService,
    private fb: FormBuilder,
    private utilsforms: UtilsForms,
    private spinner: NgxSpinnerService
  ) {
    this.modalError = {
      title: '',
      mensaje: '',
      inicio: false,
      code: 1,
    };
  }

  ngOnInit(): void {
    this.peticion = this.peticionService.getPeticion();
    this.peticionCambio = this.peticion.catalogo?.id;
    this.modificable = this.peticionService.esModificable();
    this.spinner.show();
    this.peticionService
      .getDatosEspecificos(this.peticion?.idPeticion)
      .subscribe(
        (resp) => {
          this.datosEspecificosLst = resp;
          this.spinner.hide();
          this.crearFormulario();
        },
        (error) => {
          console.log(error);
          this.spinner.hide();
          this.mensaje.showError(error);
        }
      );
  }

  crearFormulario(): void {
    this.formulario = this.fb.group({});
    for (const e of this.datosEspecificosLst) {
      e.obligatorioDin = e.obligatorio;
      const identificador = e.idHtml;
      if (
        e.valorGuardado === null &&
        e.tipo !== 'COMBO' &&
        !this.tipoCombosDatosEspecificosEspeciales.includes(e.tipo)
      ) {
        const validaciones = this.validacionesInputs(e);
        this.formulario.addControl(
          identificador,
          new FormControl('', validaciones)
        );
      } else if (this.tipoDatosEspecificosEspeciales.includes(e.tipo)) {
        this.formulario.addControl(identificador, new FormControl('', null));
      } else {
        this.comprobarValoresEspecificos(e, identificador);
      }
      this.annadirSuscripciones(e, identificador);
      this.validacionesDinamicas(e);
    }
    if (!this.peticionService.esModificable()) {
      this.formulario.disable();
    }
  }
  private validacionesDinamicas(e: DatoEspecifico) {
    if (e.validar != null && e.validar.startsWith('fechaMayorQue-')) {
      let fechaMenor = e.validar.split('-')[1];
      this.formulario.get(e.idHtml)?.valueChanges.subscribe((x) => {
        if (!this.cambioPeticionManual) {
          this.compararFechas(e.idHtml, fechaMenor, 1);
        }
      });
    }
    if (e.validar != null && e.validar.startsWith('fechaMenorQue-')) {
      let fechaMayor = e.validar.split('-')[1];
      this.formulario.get(e.idHtml)?.valueChanges.subscribe((x) => {
        if (!this.cambioPeticionManual) {
          this.compararFechas(e.idHtml, fechaMayor, 2);
        }
      });
    }
  }
  private compararFechas(
    idFecha1: string,
    idFecha2: string,
    comprobar: number
  ) {
    let fecha1 = this.formulario.get(idFecha1);
    let fecha2 = this.formulario.get(idFecha2);
    if (fecha2?.value != null) {
      if (comprobar === 1) {
        if (new Date(fecha1?.value) < new Date(fecha2?.value)) {
          this.handleDatoEpecificoError(
            'Verifique las fechas de inicio y fin',
            'La fecha fin debe ser mayor que la fecha de inicio'
          );
        } else {
          this.blockButtons = false;
          this.blockButtonsDates = false;
        }
      } else {
        if (new Date(fecha1?.value) > new Date(fecha2?.value)) {
          this.handleDatoEpecificoError(
            'Verifique en las fechas de inicio y fin',
            'La fecha de inicio debe ser menor que la fecha fin'
          );
        } else {
          this.blockButtons = false;
          this.blockButtonsDates = false;
        }
      }
    }
  }
  private max6Meses(e: DatoEspecifico, visibilidad: string[]): boolean {
    let fechaInit = this.formulario.get(visibilidad[1]);
    let fechaFin = this.formulario.get(visibilidad[2]);
    if (
      Utils.isObjectNotNull(fechaInit) &&
      fechaInit?.value !== '' &&
      Utils.isObjectNotNull(fechaFin) &&
      fechaFin?.value !== ''
    ) {
      let date1 = new Date(fechaInit?.value);
      let date2 = new Date(fechaFin?.value);
      if (date2 > date1) {
        let diffYears = date2.getFullYear() - date1.getFullYear();
        let diffMonths = diffYears * 12 + (date2.getMonth() - date1.getMonth());
        if (diffMonths > 6) {
          return this.checkExpression('true', e);
        }
      }
    }
    return false;
  }
  private menor6Meses(e: DatoEspecifico, visibilidad: string[]): boolean {
    return !this.max6Meses(e, visibilidad);
  }
  private esteAnnio(e: DatoEspecifico, visibilidad: string[]): boolean {
    let fechaInit = this.formulario.get(visibilidad[3]);
    let fechaFin = this.formulario.get(visibilidad[4]);
    let comboAnterior = this.formulario.get(visibilidad[5]);
    if (
      ((Utils.isObjectNotNull(fechaInit) && fechaInit?.value !== '') ||
        (Utils.isObjectNotNull(fechaFin) && fechaFin?.value !== '')) &&
      comboAnterior?.value.id != 1
    ) {
      let date1 = new Date(fechaInit?.value);
      let date2 = new Date(fechaFin?.value);

      if (
        date1.getFullYear() == new Date().getFullYear() ||
        date2.getFullYear() == new Date().getFullYear()
      ) {
        return this.checkExpression('true', e);
      }
    }
    return false;
  }

  private comprobarValoresEspecificos(
    e: DatoEspecifico,
    identificador: string
  ): void {
    if (
      e.valorGuardado === null &&
      (e.tipo === 'COMBO' ||
        this.tipoCombosDatosEspecificosEspeciales.includes(e.tipo))
    ) {
      //e.comboValores.unshift({
      //id: 0,
      //descripcion: '     ',
      //idDescripcion: '0 -      ',
      //});
      //const value = e.obligatorioDin == false ? e.comboValores[0] : undefined;
      this.formulario.addControl(identificador, new FormControl(undefined));
    } else {
      let valor = e.valorGuardado.valor;
      if (e.tipo === 'COMBO') {
        valor = e.comboValores.find(
          (ec) => ec.descripcion === e.valorGuardado.valor
        );
      }
      if (this.tipoCombosDatosEspecificosEspeciales.includes(e.tipo)) {
        valor = e.valorGuardado.valorCombo;
      }
      this.annadirControlAlFormulario(e, identificador, valor);
    }
  }

  private annadirSuscripciones(e: DatoEspecifico, identificador: string): void {
    if (
      Utils.isInformedString(e.calculo) &&
      Utils.isObjectNotNull(this.formulario)
    ) {
      let regla = e.calculo;
      let arr = regla.split(' ');
      arr.shift()!; //descartamos el elemento cabecera, nombre de la funcion
      for (let val of arr) {
        this.formulario.get(val)?.valueChanges.subscribe((x) => {
          this.formulario.get(identificador)?.setValue(this.executeFormula(e));
        });
      }
      this.formulario.get(identificador)?.disable();
    }
  }

  private annadirControlAlFormulario(
    e: DatoEspecifico,
    identificador: string,
    valor: string
  ): void {
    if (e.tipo === 'COMBO') {
      this.formulario.addControl(identificador, new FormControl(valor));
    } else {
      if (e.tipo === 'CALENDARIO') {
        this.formulario.addControl(
          identificador,
          new FormControl(new Date(valor))
        );
      } else if (e.tipo === 'INPUT_NUMBER') {
        const validaciones = this.validacionesInputs(e);
        this.formulario.addControl(
          identificador,
          new FormControl(parseInt(valor, 10), validaciones)
        );
      } else {
        const validaciones = this.validacionesInputs(e);
        this.formulario.addControl(
          identificador,
          new FormControl(valor, validaciones)
        );
      }
    }
  }

  validacionesInputs(e: DatoEspecifico): ValidatorFn[] {
    const validaciones = [];

    if (e.codCombo == 3) {
      validaciones.push(Validators.maxLength(50));
    }

    if (e.codCombo == 4) {
      validaciones.push(Validators.maxLength(200));
    }

    if (e.descripcion === 'DNI') {
      validaciones.push(
        Validators.pattern('^([0-9]{8}[A-Z])|[XYZ][0-9]{7}[A-Z]$')
      );
    }

    if (e.validar === 'menorV' && e.tipo === 'INPUT_NUMBER') {
      validaciones.push(Validators.min(0));
      validaciones.push(Validators.max(5)); //menor igual de 5 años
    }

    if (e.validar === 'menorLX' && e.tipo === 'INPUT_NUMBER') {
      validaciones.push(Validators.min(0));
      validaciones.push(Validators.max(60)); //menor igual de 60 meses, 5 años
    }

    if (e.validar === 'menorMDCCCXXV' && e.tipo === 'INPUT_NUMBER') {
      validaciones.push(Validators.min(0));
      validaciones.push(Validators.max(1825)); //menor igual de 1825 dias, 5 años
    }

    return validaciones;
  }

  checkRuleVisibility(e: DatoEspecifico): boolean {
    let array: string[];
    let sol: boolean = true;
    if (Utils.isObjectNotNull(e.visibilidad)) {
      array = e.visibilidad.split(' ');
      switch (array[0]) {
        case 'annioyDuracion':
          sol = this.annioyDuracion(e, array);
          break;
        case 'menor6Meses':
          sol = this.menor6Meses(e, array);
          break;
        case 'errorSuspension':
          sol = this.errorSuspension(e, array);
          break;
        case 'sinError':
          sol = this.sinError(e, array);
          break;
        case 'sinErroryCont':
          sol =
            this.sinError(e, array.slice(0, 2)) &&
            this.valorCombo(e, array[2], 2) &&
            (this.valorCombo(e, array[3], 1) ||
              this.valorCombo(e, array[3], 2));
          break;
        default:
          sol = this.logicRuleVisibility(e, e.visibilidad);
      }
    }
    if (e.obligatorio) {
      if (sol) {
        e.obligatorioDin = true;
      } else {
        e.obligatorioDin = false;
      }
    }
    return sol;
  }

  private errorSuspension(e: DatoEspecifico, visibilidad: string[]): boolean {
    return (
      this.max6Meses(e, visibilidad) ||
      this.valorCombo(e, visibilidad[3], 1) ||
      this.valorCombo(e, visibilidad[4], 3)
    );
  }

  private valorCombo(
    e: DatoEspecifico,
    idDato: string,
    valor: number
  ): boolean {
    let combo = this.formulario.get(idDato);
    if (Utils.isObjectNotNull(combo) && combo?.value !== '') {
      if (combo?.value?.id == valor) {
        return this.checkExpression('true', e);
      }
    }
    return false;
  }

  private annioyDuracion(e: DatoEspecifico, visibilidad: string[]): boolean {
    return this.max6Meses(e, visibilidad) || this.esteAnnio(e, visibilidad);
  }
  private sinError(e: DatoEspecifico, visibilidad: string[]): boolean {
    let arrFinal: Array<string> = visibilidad.slice(1, visibilidad.length);
    for (const iterator of arrFinal) {
      let dato = this.datosEspecificosLst.find((obj) => {
        return obj.idHtml === iterator;
      });
      if (Utils.isObjectNotNull(dato?.visibilidad)) {
        if (dato != undefined && this.checkRuleVisibility(dato)) {
          return false;
        }
      }
    }
    return this.checkExpression('true', e);
  }

  private logicRuleVisibility(e: DatoEspecifico, visibilidad: string): boolean {
    //dato especifico CON regla de visibilidad
    let rule: string = visibilidad;
    let arr = rule.split(/\s+/);
    const conditions = Math.floor(arr.length / 4);

    let expression: string = '';
    let text: string = '';
    let texto: string = "this.formulario.get(':x:')?.value";
    let textoCombo: string = "this.formulario.get(':x:')?.value.id";
    let pattern: string = ':x:';

    let tupla: [string, boolean];

    if (arr.length < 3) {
      return false; //la condicion esta mal formada
    } else if (arr.length == 3) {
      tupla = this.checkOnlyOneCondition(
        [text, textoCombo, texto, pattern],
        expression,
        arr,
        e
      );
    } else {
      tupla = this.checkManyConditions(
        conditions,
        [text, textoCombo, texto, pattern],
        expression,
        arr,
        e
      );
    }

    if (tupla[1]) {
      return false;
    }

    expression = tupla[0];
    expression = expression.slice(0, -1); //quitamos el ultimo espacio

    return this.checkExpression(expression, e);
  }

  private checkOnlyOneCondition(
    tupla: [text: string, textoCombo: string, texto: string, pattern: string],
    expression: string,
    arr: string[],
    e: DatoEspecifico
  ): [string, boolean] {
    //solo hay una condicion
    let element = document.getElementById(arr[0]);
    if (this.checkHTMLElementExists(element)) {
      let def = this.datosEspecificosLst.find((obj) => {
        return obj.idHtml === arr[0];
      });

      tupla[0] =
        Utils.isObjectNotNull(def) && def?.tipo === 'COMBO'
          ? tupla[1]
          : tupla[2];

      expression = expression + tupla[0].replace(tupla[3], arr[0]) + ' '; //variable
      expression = expression + arr[1] + ' '; //operador logico
      expression = expression + arr[2] + ' '; //valor
    }

    let error: boolean = this.showErrorHTMLElementNotExists(e, element);

    return [expression, error];
  }

  private checkManyConditions(
    conditions: number,
    tupla: [text: string, textoCombo: string, texto: string, pattern: string],
    expression: string,
    arr: string[],
    e: DatoEspecifico
  ): [string, boolean] {
    //hay mas de una condicion
    let aux: [string, boolean] = [expression, false];
    let puntero: number = 0;
    for (let i = 0; i <= conditions; i++) {
      puntero = i * 4;
      let element = document.getElementById(arr[puntero]);
      if (this.checkHTMLElementExists(element)) {
        let def = this.datosEspecificosLst.find((obj) => {
          return obj.idHtml === arr[puntero];
        });

        tupla[0] =
          Utils.isObjectNotNull(def) && def?.tipo === 'COMBO'
            ? tupla[1]
            : tupla[2];

        expression =
          expression + tupla[0].replace(tupla[3], arr[puntero]) + ' '; //variable
        expression = expression + arr[puntero + 1] + ' '; //operador logico
        expression = expression + arr[puntero + 2] + ' '; //valor
        expression =
          i !== conditions
            ? expression + arr[puntero + 3] + ' '
            : expression + '';
      }

      let error: boolean = this.showErrorHTMLElementNotExists(e, element);

      aux[0] = expression;
      aux[1] = error;
    }

    return aux;
  }

  showErrorHTMLElementNotExists(
    e: DatoEspecifico,
    element: HTMLElement | null
  ): boolean {
    if (!this.blockButtons && !this.checkHTMLElementExists(element)) {
      this.handleDatoEpecificoError(
        CrearPeticionDatosEspecificosComponent.titleError + e.idHtml,
        CrearPeticionDatosEspecificosComponent.msgError + e.visibilidad
      );
    }

    return !this.checkHTMLElementExists(element) ? true : false;
  }

  checkHTMLElementExists(element: HTMLElement | null): boolean {
    return typeof element != 'undefined' && element != null ? true : false;
  }

  checkExpression(expression: string, e: DatoEspecifico): boolean {
    let check: boolean = false;
    try {
      if (eval(expression)) {
        this.blockButtons = false;
        if (e.tipo === 'LABEL' && e.cambio != null) {
          this.peticionCambio = e.cambio;
          this.comprobarCalendarios();
        } else {
          this.peticionCambio = this.peticion.catalogo?.id;
        }
        check = true;
      }
    } catch (err) {
      check = false;
    }
    return check;
  }

  comprobarCalendarios() {
    for (const iterator of this.datosEspecificosLst) {
      if (
        iterator.tipo === 'CALENDARIO' &&
        (this.formulario.get(iterator.idHtml)?.value == undefined ||
          this.formulario.get(iterator.idHtml)?.value == null ||
          this.formulario.get(iterator.idHtml)?.value == '' ||
          this.formulario.get(iterator.idHtml)?.value == ' ')
      ) {
        this.formulario.get(iterator.idHtml)?.setValue(new Date());
      } else if (iterator.tipo === 'INPUT_NUMBER') {
        this.formulario.get(iterator.idHtml)?.setValue(0);
      }
    }
  }

  executeFormula(e: DatoEspecifico): any {
    let sol: any;

    let arr: Array<string> = e.calculo.split(' ');

    let functionName: string = arr[0];

    let arrFinal: Array<string> = arr.slice(1, arr.length);

    switch (functionName) {
      case 'calcFechaFinDE':
        sol = this.calcFechaFinDE(arrFinal);
        break;
      case 'calcFechaProrroga':
        sol = this.calcFechaProrroga(arrFinal, e);
        break;
      case 'calcFechaCuidadoMenor':
        sol = this.calcFechaCuidadoMenor(arrFinal, e);
        break;
      case 'calcFechaVuelta':
        sol = this.calcFechaVuelta(arrFinal, e);
        break;
      default:
        console.log('ERROR: autocalculate rule function not found');
    }

    return sol;
  }

  calcFechaVuelta(arr: Array<string>, e: DatoEspecifico): any {
    let fechaFin = this.checkDE(arr[0]);
    if (Utils.isObjectNotNull(fechaFin) && fechaFin !== '') {
      let fechaVuelta = new Date(fechaFin.getTime());
      fechaVuelta.setDate(fechaVuelta.getDate() + 1);
      return fechaVuelta;
    }
    return undefined;
  }

  calcFechaCuidadoMenor(arr: Array<string>, e: DatoEspecifico): any {
    return this.peticionCambio === this.peticion.catalogo?.id
      ? this.checkCalcFechaCuidadoMenor(arr, e)
      : undefined;
  }

  private checkCalcFechaCuidadoMenor(
    arr: Array<string>,
    e: DatoEspecifico
  ): Date | undefined {
    let fechaNacimiento = this.checkDE(arr[0]);
    let fechaInit = this.checkDE(arr[1]);
    let annos = this.checkDE(arr[2]);
    let meses = this.checkDE(arr[3]);
    let dias = this.checkDE(arr[4]);
    let fechaFin = undefined;

    if (
      Utils.isObjectNotNull(fechaInit) &&
      fechaInit !== '' &&
      Utils.isObjectNotNull(fechaNacimiento) &&
      fechaNacimiento !== '' &&
      annos >= 0 &&
      meses >= 0 &&
      dias >= 0
    ) {
      fechaFin = new Date(fechaInit.getTime());
      fechaFin.setDate(fechaFin.getDate() + dias);
      fechaFin.setMonth(fechaFin.getMonth() + meses);
      fechaFin.setFullYear(fechaFin.getFullYear() + annos);

      if (meses >= 1 || annos >= 1) {
        fechaFin.setDate(fechaFin.getDate() - 1);
      }

      if (
        this.checkFechaMorethanNYears(fechaInit, fechaFin, 1) &&
        !this.blockButtonsDates
      ) {
        this.tipoPeticionManual = e.cambio;
        this.cambioPeticionManual = true;
        this.handleDatoEpecificoError(
          'Error en Calculo Fecha de la Excedencia por Cuidado de Menor',
          'Desde la fecha de inicio de la excedencia ' +
            this.convertStandardDate(fechaInit) +
            ' del menor hasta la fecha finalización excedencia ' +
            this.convertStandardDate(fechaFin) +
            ' el tiempo no puede ser superior a 1 año. Por favor corrija la duracion o pase a una peticion Manual'
        );
      }

      if (
        this.checkFechaMorethanNYears(fechaNacimiento, fechaFin, 3) &&
        !this.blockButtonsDates
      ) {
        this.tipoPeticionManual = e.cambio;
        this.cambioPeticionManual = true;
        this.handleDatoEpecificoError(
          'Error en Calculo Fecha de la Excedencia por Cuidado de Menor',
          'Desde la fecha de nacimiento ' +
            this.convertStandardDate(fechaNacimiento) +
            ' del menor hasta la fecha finalización excedencia ' +
            this.convertStandardDate(fechaFin) +
            ' el tiempo no puede ser superior a 3 años. Por favor corrija la duracion o pase a una peticion Manual'
        );
      }

      if (
        !this.checkFechaMorethanNYears(fechaNacimiento, fechaFin, 3) &&
        !this.checkFechaMorethanNYears(fechaInit, fechaFin, 1)
      ) {
        this.blockButtonsDates = false;
      }
    }

    return fechaFin;
  }

  checkFechaMorethanNYears(
    fechaInit: Date,
    fechaFin: Date,
    years: number
  ): boolean {
    let check: boolean = false;
    let fechaLessNYears = new Date(fechaInit.getTime());
    fechaInit.setHours(0, 0, 0, 0);
    fechaFin.setHours(0, 0, 0, 0);
    fechaLessNYears.setHours(0, 0, 0, 0);
    fechaLessNYears.setFullYear(fechaInit.getFullYear() + years); //sumamos N años
    fechaLessNYears.setDate(fechaLessNYears.getDate() - 1);

    if (fechaFin.getTime() > fechaLessNYears.getTime()) {
      check = true;
    }

    return check;
  }

  calcFechaProrroga(arr: Array<string>, e: DatoEspecifico): any {
    return this.peticionCambio === this.peticion.catalogo?.id
      ? this.checkCalcFechaProrroga(arr, e)
      : undefined;
  }

  private checkCalcFechaProrroga(
    arr: Array<string>,
    e: DatoEspecifico
  ): Date | undefined {
    let fechaProrroga = this.checkDE(arr[0]);
    let fechaInit = this.checkDE(arr[1]);
    let annos = this.checkDE(arr[2]);
    let meses = this.checkDE(arr[3]);
    let dias = this.checkDE(arr[4]);
    let fechaFin = undefined;

    this.showErrorFechasProrroga(fechaInit, fechaProrroga);

    if (
      this.checkFechaProrroga(fechaInit, fechaProrroga) &&
      Utils.isObjectNotNull(fechaInit) &&
      fechaInit !== '' &&
      Utils.isObjectNotNull(fechaProrroga) &&
      fechaProrroga !== '' &&
      annos >= 0 &&
      meses >= 0 &&
      dias >= 0
    ) {
      fechaFin = new Date(fechaProrroga.getTime());
      fechaFin.setDate(fechaFin.getDate() + dias);
      fechaFin.setMonth(fechaFin.getMonth() + meses);
      fechaFin.setFullYear(fechaFin.getFullYear() + annos);

      if (meses >= 1 || annos >= 1) {
        fechaFin.setDate(fechaFin.getDate() - 1);
      }

      if (
        this.checkFechaMorethanNYears(fechaInit, fechaFin, 5) &&
        !this.blockButtonsDates
      ) {
        this.tipoPeticionManual = e.cambio;
        this.cambioPeticionManual = true;
        this.handleDatoEpecificoError(
          'Error en Calculo Prorroga Fecha de la Excedencia',
          'La fecha ' +
            this.convertStandardDate(fechaFin) +
            ' supera el periodo maximo de la excendencia (5 años). Por favor corrija la duracion o pase a una peticion Manual'
        );
      }

      if (
        this.checkFechaProrroga(fechaInit, fechaFin) &&
        !this.checkFechaMorethanNYears(fechaInit, fechaFin, 5)
      ) {
        this.blockButtonsDates = false;
      }
    }

    return fechaFin;
  }

  private showErrorFechasProrroga(fechaInit: Date, fechaProrroga: Date): void {
    if (
      Utils.isObjectNotNull(fechaInit) &&
      Utils.isObjectNotNull(fechaProrroga) &&
      !this.checkFechaProrroga(fechaInit, fechaProrroga) &&
      !this.blockButtonsDates
    ) {
      /*this.handleDatoEpecificoError(
        'Error en Calculo Prorroga Fecha de la Excedencia',
        'La fecha inicio prorroga ' +
          this.convertStandardDate(fechaProrroga) +
          ' es inferior o igual a la fecha de inicio de la excedencia ' +
          this.convertStandardDate(fechaInit) +
          ' .'
      );*/

      return undefined;
    }
  }

  convertStandardDate(fecha: Date): string {
    let datePipe = new DatePipe(
      CrearPeticionDatosEspecificosComponent.LOCALE_SPAIN
    );

    let fechaFormat = datePipe.transform(
      fecha,
      CrearPeticionDatosEspecificosComponent.FORMAT_DATE
    );

    return fechaFormat!;
  }

  checkFechaProrroga(fechaInit: Date, fechaProrroga: Date): boolean {
    let check: boolean = false;

    if (fechaProrroga.getTime() > fechaInit.getTime()) {
      check = true;
    }

    return check;
  }

  calcFechaFinDE(arr: Array<string>): any {
    return this.peticionCambio === this.peticion.catalogo?.id
      ? this.checkCalcFechaFinDE(arr)
      : undefined;
  }

  private checkCalcFechaFinDE(arr: Array<string>): Date | undefined {
    let annos = this.checkDE(arr[0]);
    let meses = this.checkDE(arr[1]);
    let dias = this.checkDE(arr[2]);
    let fechaInit = this.checkDE(arr[3]);
    let fechaFin = undefined;

    if (
      Utils.isObjectNotNull(fechaInit) &&
      fechaInit !== '' &&
      annos >= 0 &&
      meses >= 0 &&
      dias >= 0
    ) {
      fechaFin = new Date(fechaInit.getTime());
      fechaFin.setDate(fechaFin.getDate() + dias);
      fechaFin.setMonth(fechaFin.getMonth() + meses);
      fechaFin.setFullYear(fechaFin.getFullYear() + annos);

      if (meses >= 1 || annos >= 1) {
        fechaFin.setDate(fechaFin.getDate() - 1);
      }

      this.showErrorMorethan5Years(fechaInit, fechaFin);
      this.showErrorLess4Months(fechaInit, fechaFin);

      if (
        !this.checkDateLess4Months(fechaInit, fechaFin) &&
        !this.checkFechaMorethanNYears(fechaInit, fechaFin, 5)
      ) {
        this.blockButtonsDates = false;
      }
    }

    return fechaFin;
  }

  private showErrorLess4Months(fechaInit: Date, fechaFin: Date): void {
    if (
      this.checkDateLess4Months(fechaInit, fechaFin) &&
      !this.blockButtonsDates
    ) {
      this.handleDatoEpecificoError(
        'Error en Calculo Fecha de la Excedencia',
        'La fecha ' +
          this.convertStandardDate(fechaFin) +
          ' no supera el periodo minimo de la excendencia (4 meses).'
      );
    }
  }

  private showErrorMorethan5Years(fechaInit: Date, fechaFin: Date): void {
    if (
      this.checkFechaMorethanNYears(fechaInit, fechaFin, 5) &&
      !this.blockButtonsDates
    ) {
      this.handleDatoEpecificoError(
        'Error en Calculo Fecha de la Excedencia',
        'La fecha ' +
          this.convertStandardDate(fechaFin) +
          ' supera el periodo maximo de la excendencia (5 años).'
      );
    }
  }

  checkDateLess4Months(fechaInit: Date, fechaFin: Date): boolean {
    let check = false;
    let fechaFourMonths = new Date(fechaInit.getTime());
    fechaInit.setHours(0, 0, 0, 0);
    fechaFin.setHours(0, 0, 0, 0);
    fechaFourMonths.setHours(0, 0, 0, 0);
    fechaFourMonths.setMonth(fechaInit.getMonth() + 4); //sumamos 4 meses
    fechaFourMonths.setDate(fechaFourMonths.getDate() - 1);

    if (fechaFin.getTime() < fechaFourMonths.getTime()) {
      check = true;
    }

    return check;
  }

  checkDE(aux: string): any {
    let sol;
    let element = document.getElementById(aux);
    if (typeof element != 'undefined' && element != null) {
      let def = this.datosEspecificosLst.find((obj) => {
        return obj.idHtml === aux;
      });

      if (Utils.isObjectNotNull(def) && def?.tipo === 'COMBO') {
        sol = this.formulario.get(aux)?.value.id;
      } else {
        sol = this.formulario.get(aux)?.value;
      }
    }

    return sol;
  }

  nextPage(): void {
    if (!this.peticionService.esModificable()) {
      this.router.navigate(['peticiones/crear/adjuntar']);
    } else {
      if (this.formulario.valid && this.peticion.idPeticion) {
        this.spinner.show();
        this.peticionService
          .guardarDatosEspecificos(
            this.peticion.idPeticion,
            this.obtenerEspecificosFormulario(),
            this.peticionCambio
          )
          .subscribe(
            (resPeticion) => {
              this.spinner.hide();
              if (this.peticionCambio === this.peticion.catalogo?.id) {
                this.router.navigate(['peticiones/crear/adjuntar']);
              } else {
                this.peticion = this.peticionService.getPeticion();
                this.router.navigate([
                  `/peticiones/crear/identificacion/${this.peticion.idPeticion}`,
                ]);
              }
            },
            (error: any) => {
              this.spinner.hide();
              this.mensaje.showError(
                'No se ha podido guardar la petición.' + error.error
              );
            }
          );
      } else {
        this.validarCampoyMarcar();
        this.mensaje.showErrorPeticion(
          'Revisa la petición, hay campos incorrectos o incompletos'
        );
      }
    }
  }

  private obtenerEspecificosFormulario(): ValorDatoGuardado[] {
    const valoresEsp: ValorDatoGuardado[] = [];
    for (const e of this.datosEspecificosLst) {
      this.checkobtenerEspecificosFormulario(e, valoresEsp);
    }
    return valoresEsp;
  }

  private checkobtenerEspecificosFormulario(
    e: DatoEspecifico,
    valoresEsp: ValorDatoGuardado[]
  ): void {
    const identificador = e.idHtml;
    if (
      !this.tipoDatosEspecificosEspeciales.includes(e.tipo) &&
      this.formulario.get(identificador)?.value !== null
    ) {
      this.checkValorGuardado(identificador, e, valoresEsp);
    }
  }

  private checkValorGuardado(
    identificador: string,
    e: DatoEspecifico,
    valoresEsp: ValorDatoGuardado[]
  ): void {
    const valorguardado: ValorDatoGuardado = {
      codDato: e.id,
      valor: this.formulario.get(identificador)?.value,
    };

    if (e.valorGuardado !== null) {
      valorguardado.id = e.valorGuardado.id;
    }

    if (e.tipo === 'COMBO') {
      valorguardado.valor = 'COMBO';
      valorguardado.valorCombo = this.formulario.get(identificador)?.value;
      valoresEsp.push(valorguardado);
    } else if (e.tipo === 'CALENDARIO') {
      valorguardado.valor = this.convertStandardDate(
        this.formulario.get(identificador)?.value
      );
      valoresEsp.push(valorguardado);
    } else {
      if (this.tipoCombosDatosEspecificosEspeciales.includes(e.tipo)) {
        valorguardado.valor = e.tipo;
        valorguardado.valorCombo = this.formulario.get(identificador)?.value;
        valoresEsp.push(valorguardado);
      } else {
        valorguardado.valor = this.formulario.get(identificador)?.value;
        valoresEsp.push(valorguardado);
      }
    }
  }

  previousPage(): void {
    this.router.navigate(['peticiones/crear/observaciones']);
  }

  guardarDatosEspecificos(): void {
    if (this.formulario.valid && this.peticion.idPeticion) {
      this.spinner.show();
      this.peticionService
        .guardarDatosEspecificos(
          this.peticion.idPeticion,
          this.obtenerEspecificosFormulario(),
          this.peticionCambio
        )
        .subscribe(
          (resPeticion) => {
            this.mensaje.showMensaje(
              'La petición se ha actualizado de forma correcta'
            );
            this.spinner.hide();
            if (this.peticionCambio !== this.peticion.catalogo?.id) {
              this.peticion = this.peticionService.getPeticion();
              this.router.navigate([
                `/peticiones/crear/identificacion/${this.peticion.idPeticion}`,
              ]);
            }
          },
          (error: any) => {
            this.spinner.hide();
            this.mensaje.showError(
              'No se ha podido guardar la petición.' + error.error
            );
          }
        );
    } else {
      this.validarCampoyMarcar();
      this.mensaje.showErrorPeticion(
        'Revisa la petición, hay campos incorrectos o incompletos'
      );
    }
  }

  validarCampoyMarcar(): void {
    for (const c of this.datosEspecificosLst) {
      const id = c.idHtml;
      if (!this.formulario.get(id)?.valid) {
        const tipoCampo = this.obtenerTipo(c.tipo);
        this.utilsforms.ponerEstiloObligatorio($, '#' + id, tipoCampo);
      } else {
        this.quitarEstiloOB(id, c.tipo);
      }
    }
  }

  quitarEstiloOB(id: any, tipoCampo: string): void {
    this.utilsforms.quitarEstiloObligatorio($, '#' + id, tipoCampo);
  }

  obtenerTipo(tipoCampo: string): string {
    if (tipoCampo === 'INPUT') {
      return 'textarea';
    } else {
      return 'dropdown';
    }
  }

  handleDatoEpecificoError(title: string, message: any): void {
    this.modalError = {
      title,
      mensaje: message,
      inicio: true,
      code: 1,
    };
    $('#modalDatoEspecifico').modal({ backdrop: false, keyboard: false });
  }

  cerrarModal(): void {
    this.blockButtons = true;
    this.blockButtonsDates = true;
    $('#modalDatoEspecifico').modal('hide');
    this.modalError = {
      title: '',
      mensaje: '',
      inicio: false,
      code: 1,
    };
  }

  redirectToPeticionManual(): void {
    this.cambioPeticionManual = false;
    this.peticionService
      .guardarDatosEspecificos(
        this.peticion.idPeticion,
        this.obtenerEspecificosFormulario(),
        this.tipoPeticionManual
      )
      .subscribe(
        (resPeticion) => {
          this.spinner.hide();
          this.peticion = this.peticionService.getPeticion();
          this.router.navigate([
            `/peticiones/crear/identificacion/${this.peticion.idPeticion}`,
          ]);
        },
        (error: any) => {
          this.spinner.hide();
          this.mensaje.showError(
            'No se ha podido guardar la petición.' + error.error
          );
        }
      );
  }
}

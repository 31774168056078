import { AfterContentInit, Component, OnInit, ViewChildren } from '@angular/core';
import { ApiSgcService } from '../../servicios/api-sgc/api-sgc.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HistoricoContrato } from '../../modelos/historico-contrato';
import { NgxSpinnerService } from 'ngx-spinner';
import { Constantes } from 'src/app/utils/constantes';
import Utils from 'src/app/utils/utils';
import { Table } from 'primeng/table';
import { MatDialog } from '@angular/material/dialog';
import { ModalInfoComponent } from '../modals/modal-info/modal-info.component';

@Component({
  selector: 'app-informacion-empleado',
  templateUrl: './informacion-empleado.component.html',
  styleUrls: ['./informacion-empleado.component.css']
})
export class InformacionEmpleadoComponent implements OnInit, AfterContentInit {

  dniTrabjadorAfectado!:string;

  constructor(
    private sgcService: ApiSgcService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private router: Router,
    private constantes: Constantes,
    public dialog: MatDialog,
  ) {}

  historicosContratos: HistoricoContrato[] = [];
  cols!: any[];
  first = 1;
  rows = 25;
  lastSortField!: any;
  paginasTotales = 0;
  paginaActual = 1;
  resultadosTotales = 0;
  registrosPorPagina!: number[];

  @ViewChildren('dt1')
  public table!: Table;

  ngOnInit(): void {
    this.loadServices();
    this.loadData();
  }

  loadServices(): void {
    const dni = this.route.snapshot.paramMap.get('dni');
    this.dniTrabjadorAfectado = (dni !== null) ? dni : "";
  }

  ngAfterContentInit(): void {
    this.first = this.paginaActual;
    this.paginaActual = this.first;
    if (this.table) {
      this.table.first = (this.paginaActual - 1) * this.rows;
    }
    this.refreshCols();
  }

  loadData(): void {
    this.spinner.show();
    this.refreshCols();
    this.registrosPorPagina = this.constantes.numeroUsuariosPorPagina;
    this.getHistoricosContratos();
    this.spinner.hide();
  }

  refreshCols(): void {
    this.cols = [
      {
        field: 'empresa',
        header: 'EMP',
        toolTip: '',
        sortable: false,
        visible: true,
        style: 'width: 6% !important;',
      },
      {
        field: 'provincia',
        header: 'PROV',
        toolTip: '',
        sortable: false,
        visible: true,
        style: 'width: 7% !important;',
      },
      {
        field: 'dni',
        header: 'DNI',
        toolTip: '',
        sortable: false,
        visible: true,
        style: 'width: 10% !important;',
      },
      {
        field: 'secuencial',
        header: 'SEC',
        toolTip: '',
        sortable: true,
        visible: true,
        style: 'width: 6% !important;',
      },
      {
        field: 'nombre',
        header: 'Nombre',
        toolTip: '',
        sortable: false,
        visible: true,
        style: 'width: 20% !important;',
      },
      {
        field: 'NAF',
        header: 'NAF',
        toolTip: '',
        sortable: false,
        visible: true,
        style: 'width: 13% !important;',
      },
      {
        field: 'fechaAlta',
        header: 'Fecha Alta',
        toolTip: '',
        sortable: false,
        visible: true,
        style: 'width: 12% !important;',
      },
      {
        field: 'fechaBaja',
        header: 'Fecha Baja',
        toolTip: '',
        sortable: false,
        visible: true,
        style: 'width: 12% !important;',
      },
      {
        field: 'MTBJ',
        header: 'MTBJ',
        toolTip: '',
        sortable: false,
        visible: true,
        style: 'width: 7% !important;',
      },
      {
        field: 'CTO',
        header: 'CTO',
        toolTip: '',
        sortable: false,
        visible: true,
        style: 'width: 6% !important;',
      }
    ];
  }

  nextPage(e: any, table: Table): void {
    if (this.rows !== e.rows) {
      this.rows = e.rows;
      e.first = 0;
      this.paginaActual = 1;
      const offset = e.first / this.rows + 1;
      table.first = offset;
      this.first = offset;
      this.rows = e.rows;
      this.getHistoricosContratos();
    } else {
      // verificar si se ha seleccionado un filtro nuevo, en caso contrario, está paginando
      if (
        (e.sortField && !this.lastSortField) ||
        (e.sortField && this.hasChanged(e.sortField, e.sortOrder))
      ) {
        this.lastSortField = {
          sortField: e.sortField,
          sortOrder: e.sortOrder,
        };
        this.customSort(this.lastSortField);
        table.first = (this.paginaActual - 1) * this.rows;
      } else {
        const offset = e.first / this.rows + 1;
        this.rows = e.rows;
        this.first = offset;
        this.getHistoricosContratos();
      }
    }
  }

  private customSort(sort: any): void {
    if (sort && this.historicosContratos) {
      this.historicosContratos.sort((a: HistoricoContrato, b: HistoricoContrato) => {
        switch (sort.sortField) {
          case this.cols[0].field:
            return Utils.sortString(
              Utils.stringNotNull(a.empresa),
              Utils.stringNotNull(b.empresa),
              sort.sortOrder
            );
          case this.cols[1].field:
            return Utils.sortString(
              Utils.stringNotNull(a.provincia),
              Utils.stringNotNull(b.provincia),
              sort.sortOrder
            );
          case this.cols[2].field:
            return Utils.sortString(
              Utils.stringNotNull(a.dni),
              Utils.stringNotNull(b.dni),
              sort.sortOrder
            );
          case this.cols[3].field:
            return Utils.sortString(
              Utils.stringNotNull(a.secuencial),
              Utils.stringNotNull(b.secuencial),
              sort.sortOrder
            );
          case this.cols[4].field:
            return Utils.sortNumber(
              +Utils.stringNotNull(a.nombre),
              +Utils.stringNotNull(b.nombre),
              sort.sortOrder
            );
          case this.cols[5].field:
            return Utils.sortString(
              Utils.stringNotNull(a.naf),
              Utils.stringNotNull(b.naf),
              sort.sortOrder
            );
          case this.cols[6].field:
            return Utils.sortString(
              Utils.stringNotNull(a.fechaAlta),
              Utils.stringNotNull(b.fechaAlta),
              sort.sortOrder
            );
          case this.cols[7].field:
            return Utils.sortString(
              Utils.stringNotNull(a.fechaBaja),
              Utils.stringNotNull(b.fechaBaja),
              sort.sortOrder
            );
          case this.cols[8].field:
            return Utils.sortString(
              Utils.stringNotNull(a.mtbj),
              Utils.stringNotNull(b.mtbj),
              sort.sortOrder
            );
          case this.cols[9].field:
            return Utils.sortString(
              Utils.stringNotNull(a.cto),
              Utils.stringNotNull(b.cto),
              sort.sortOrder
            );
          default:
            return sort.sortOrder;
        }
      });
    }
  }

  private hasChanged(sort: string, order: number): boolean {
    return (
      this.lastSortField.sortField !== sort ||
      this.lastSortField.sortOrder !== order
    );
  }

  getHistoricosContratos(): void {
    this.spinner.show();
    this.sgcService
      .obtenerSecuenciales(this.dniTrabjadorAfectado)
      .subscribe(
        (data: any) => {
          this.historicosContratos = data;
          this.paginaActual = this.first;
          this.paginasTotales = this.first;
          this.resultadosTotales = this.historicosContratos.length;
          this.customSort(this.lastSortField);
          if (this.table) {
            this.table.first = (this.paginaActual - 1) * this.rows;
          }
          this.spinner.hide();
        },
        (error: any) => {
          console.error(error);
          this.historicosContratos = [];
          this.spinner.hide();
        }
      );
  }

  volver(): void {
    this.router.navigate([`/peticiones/actuaciones`]);
  }

  exportarDatos(): void {
    this.spinner.show();
    this.sgcService
      .exportar(this.historicosContratos)
      .subscribe((urlRespuesta) => {
        if (urlRespuesta) {
          // Abrir nueva pantalla con el resultado del exportar
          this.spinner.hide();
          window.open(
            urlRespuesta.url,
            '_blank',
            'location=yes, height=570, width=520, scrollbars=yes, status=yes'
          );
        } else {
          const data = {
            title: 'Información',
            msg: 'No se ha podido hacer la exportación ...',
          };
          this.dialog.open(ModalInfoComponent, {
            disableClose: true,
            autoFocus: false,
            data,
          });
        }
      });
  }
}

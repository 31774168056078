import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { AuditoriaPeticionRequest } from 'src/app/modelos/auditoria';
import { TablaAuditoriaComponent } from '../tabla-auditoria/tabla-auditoria.component';

@Component({
  selector: 'app-auditoria-principal',
  templateUrl: './auditoria-principal.component.html',
  styleUrls: ['./auditoria-principal.component.scss'],
})
export class AuditoriaPrincipalComponent implements OnInit {
  @ViewChild('tabla', { static: true }) tabla!: TablaAuditoriaComponent;

  simple = false;
  filters: AuditoriaPeticionRequest = {};

  constructor(private route: ActivatedRoute, private location: Location) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      if (params['peticion']) {
        this.simple = true;
        this.filters.id = params['peticion'];
        this.search(this.filters);
      }
    });
  }

  search(filters: AuditoriaPeticionRequest): void {
    this.tabla.loadData(filters, true);
  }

  back(): void {
    this.location.back();
  }
}

import {
  Component,
  OnInit,
  Input,
  ViewChildren,
  ViewChild,
  Injector,
} from '@angular/core';
import { AccionPeticionConstantes } from 'src/app/utils/acciones-peticion-constantes';
import {
  PeticionActuacion,
  PeticionActuacionFiltros,
  PeticionActuacionFiltrosRequest,
  PeticionExportFiltro,
} from '../../../modelos/peticion';
import { PeticionService } from '../../../servicios/peticion/peticion.service';
import { SesionService } from '../../../servicios/sesion/sesion.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Table } from 'primeng/table';
import Utils from 'src/app/utils/utils';
import { Accion } from '../../../modelos/accion';
import { Constantes } from '../../../utils/constantes';
import { Router } from '@angular/router';
import { MensajeComponent } from '../../mensaje/mensaje.component';
import { NotificadoAccion } from '../../../modelos/notificado-accion';
import { ModalAccion } from 'src/app/modelos/moda-info';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { ModalNotificarComponent } from '../../modals/modal-notificar/modal-notificar.component';
import { ModalAutorizarComponent } from '../../modals/modal-autorizar/modal-autorizar.component';
import { ModalArticaComponent } from '../../modals/modal-artica/modal-artica.component';
import { ModalInfoComponent } from '../../modals/modal-info/modal-info.component';
import { MatDialog } from '@angular/material/dialog';
import { NotificacionesService } from 'src/app/servicios/notificaciones/notificaciones.service';

declare let $: any;

@Component({
  selector: 'app-tabla-peticiones-actuaciones',
  templateUrl: './tabla-peticiones-actuaciones.component.html',
  styleUrls: ['./tabla-peticiones-actuaciones.component.scss'],
})
export class TablaPeticionesActuacionesComponent implements OnInit {
  peticiones: PeticionActuacion[] = [];
  cols!: any[];
  rows = 25;
  peticionesPorPagina: number[] = [];
  first = 0;
  paginasTotales = 0;
  paginaActual = 1;
  peticionesTotales = 0;
  lastSortField!: any;

  accionNominaSGC: number = 34;
  accionSecuencialSGC: number = 35;

  public formulario!: FormGroup;
  public modalAccion: ModalAccion;
  public idPeticionAccion!: string;
  public listaTotal: string[] = [];
  public notificados: NotificadoAccion[];

  public peticionService!: PeticionService;
  public notificacionService!: NotificacionesService;
  private messageService!: MessageService;
  private sessionService!: SesionService;
  private spinner!: NgxSpinnerService;
  usuarioLogado!: string;

  visualSeleccionada = 1;

  // tslint:disable-next-line:variable-name
  private _datosFiltro!: PeticionActuacionFiltros;

  tablaCargando = true;

  @ViewChildren('dt1')
  public table!: Table;

  @ViewChild('mensaje', { static: false })
  public mensaje!: MensajeComponent;

  @ViewChild('modalnotificar', { static: false })
  public modalnotificar!: ModalNotificarComponent;

  @ViewChild('modalAutorizar', { static: false })
  public modalAutorizar!: ModalAutorizarComponent;

  @ViewChild('modalArtica', { static: false })
  public modalArtica!: ModalArticaComponent;

  @Input()
  set datosFiltro(param: PeticionActuacionFiltros) {
    this._datosFiltro = param;
    this.first = 1;
    if (param) {
      this.getPeticiones(this.first);
    }
  }

  get datosFiltro(): PeticionActuacionFiltros {
    return this._datosFiltro;
  }

  // tslint:disable-next-line:variable-name
  private _isLoading!: boolean;

  @Input()
  set isLoading(param: boolean) {
    this._isLoading = param;
    if (!this.isLoading) {
      this._isLoading = false;
      if (this.tablaCargando === false) {
        this.spinner.hide();
      }
    }
  }

  get isLoading(): boolean {
    return this._isLoading;
  }

  constructor(
    private accionesConstantes: AccionPeticionConstantes,
    public constantes: Constantes,
    private inject: Injector,
    private router: Router,
    private fb: FormBuilder,
    public dialog: MatDialog
  ) {
    this.initServiceProperties();
    this.notificados = [];
    this.modalAccion = {
      mensaje: '',
      titulo: '',
      listaNotificados: [],
      listaAnadidos: [],
      isListInfo: true,
    };
  }

  ngOnInit(): void {
    this.initForm();
    this.initFields();
    if (
      !this.peticionService.getDatosFiltrosActuacion() &&
      this.sessionService.getUser().rol?.id !== this.constantes.ID_EDITOR
    ) {
      this.getPeticiones(this.first);
    } else {
      if (this.peticionService.getDatosFiltrosActuacion()) {
        this.datosFiltro = this.peticionService.getDatosFiltrosActuacion();
        if (this._datosFiltro) {
          this.calcularSiPaginacionPrevia();
          this.calcularVista();
        }
      }
    }
  }

  private calcularVista(): void {
    if (this._datosFiltro.visualSeleccionada) {
      this.visualSeleccionada = this._datosFiltro.visualSeleccionada;
      this.initFields();
    }
  }

  calcularSiPaginacionPrevia(): void {
    if (this._datosFiltro.recordsPerPage) {
      this.rows = this._datosFiltro.recordsPerPage;
    }
    if (this._datosFiltro.currentPage) {
      this.first = this._datosFiltro.currentPage;
      this.paginaActual = this.first;
      if (this.table) {
        this.table.first = (this.paginaActual - 1) * this.rows;
      }
    }
  }

  initServiceProperties(): void {
    this.peticionService = this.inject.get<PeticionService>(PeticionService);
    this.messageService = this.inject.get<MessageService>(MessageService);
    this.sessionService = this.inject.get<SesionService>(SesionService);
    this.spinner = this.inject.get<NgxSpinnerService>(NgxSpinnerService);
  }

  public initForm(): void {
    this.usuarioLogado = this.sessionService.getUser().email;
    this.formulario = this.fb.group({
      notificados: [['']],
      otros: [[]],
    });
  }

  private cargarDatosTabla(data: any): any {
    this.peticiones = data.results;
    if (this.visualSeleccionada === 3) {
      this.calcularPorcentajeSLA(this.peticiones);
    }
    this.eliminarBotonAccionConTrabajadorAfectado(this.accionNominaSGC);
    this.eliminarBotonAccionConTrabajadorAfectado(this.accionSecuencialSGC);
    this.obtenerEstilosAcciones();
    this.paginaActual = data.currentPage;
    this.paginasTotales = data.totalPages;
    this.peticionesTotales = data.totalResults;
    this.customSort(this.lastSortField);
    $('[data-toggle="tooltip"]').tooltip();
  }

  private customSort(sort: any): void {
    if (sort && this.peticiones) {
      this.peticiones.sort((a: PeticionActuacion, b: PeticionActuacion) => {
        switch (sort.sortField) {
          case this.cols[0].field:
            return Utils.sortString(a.idPeticion, b.idPeticion, sort.sortOrder);
          case this.cols[1].field:
            return Utils.sortString(
              a.descTipoPeticion,
              b.descTipoPeticion,
              sort.sortOrder
            );
          case this.cols[2].field:
            return Utils.sortString(a.descEstado, b.descEstado, sort.sortOrder);
          case this.cols[3].field:
            return Utils.sortString(
              a.idDestinatario,
              b.idDestinatario,
              sort.sortOrder
            );
          case this.cols[4].field:
            return Utils.sortString(
              a.idPeticionario,
              b.idPeticionario,
              sort.sortOrder
            );
          case this.cols[5].field:
            return Utils.sortString(a.idPeticion, b.idPeticion, sort.sortOrder);
          case this.cols[6].field:
            return Utils.sortString(
              a.areaDestino,
              b.areaDestino,
              sort.sortOrder
            );
          case this.cols[7].field:
            return Utils.sortString(
              a.descProvincia,
              b.descProvincia,
              sort.sortOrder
            );
          default:
            return sort.sortOrder;
        }
      });
    }
  }

  initFields(): void {
    this.cols = [
      {
        field: 'codigo',
        header: 'ID',
        toolTip: '',
        visible: true,
        sortable: true,
        style:
          +this.visualSeleccionada === 1
            ? 'max-width: none'
            : 'max-width: 20% !important;',
      },
      {
        field: 'tipo',
        header: 'TIPO',
        toolTip: '',
        visible: true,
        sortable: true,
      },
      {
        field: 'estado',
        header: 'ESTADO',
        toolTip: '',
        visible: +this.visualSeleccionada === 1,
        style: '',
        sortable: true,
      },
      {
        field: 'destinatario',
        header: 'DESTINATARIO',
        toolTip: '',
        visible: +this.visualSeleccionada === 1,
        style: '',
        sortable: true,
      },
      {
        field: 'peticionario',
        header: 'SOLICITANTE',
        toolTip: '',
        visible: +this.visualSeleccionada === 1,
        style: '',
        sortable: true,
      },
      {
        field: 'fechaSolicitud',
        header: 'F.SOLICITUD',
        toolTip: '',
        visible: +this.visualSeleccionada === 1,
        sortable: true,
      },

      {
        field: 'areaDestino',
        header: 'ÁREA DESTINO',
        toolTip: '',
        visible: +this.visualSeleccionada === 2,
        style: 'width: 15% !important;',
        sortable: true,
      },
      {
        field: 'descProvincia',
        header: 'PROVINCIA',
        toolTip: '',
        visible: +this.visualSeleccionada === 2,
        style: 'width: 15% !important;',
        sortable: true,
      },

      {
        field: 'tiempoConsumoRespuesta',
        header: 'TIEMPO CONSUMIDO RESPUESTA',
        toolTip: '',
        visible: +this.visualSeleccionada === 3,
        style: 'max-width: 30% !important',
        sortable: false,
      },
      {
        field: 'tiempoConsumoResolucion',
        header: 'TIEMPO CONSUMIDO RESOLUCIÓN',
        toolTip: '',
        visible: +this.visualSeleccionada === 3,
        style: 'max-width: 30% !important',
        sortable: false,
      },
      {
        field: 'acciones',
        header: 'ACCIONES',
        visible: true,
        sortable: false,
        style: 'max-width: 30% !important',
      },
    ];

    this.peticionesPorPagina = this.constantes.numeroUsuariosPorPagina;
    if (this._datosFiltro) {
      this._datosFiltro.visualSeleccionada = this.visualSeleccionada;
    } else {
      this._datosFiltro = {
        destinatario: [],
        anio: new Date().getFullYear(),
        ocultarPeticiones: 1,
        visualSeleccionada: this.visualSeleccionada,
      };
    }
  }

  private obtenerEstilosAcciones(): void {
    this.peticiones.forEach((peticion: PeticionActuacion) => {
      peticion.acciones.forEach((accion: Accion, index: number) => {
        const accionReaded = this.accionesConstantes.ACCIONES.find(
          (a: Accion) => a.id === accion.id
        );
        if (accionReaded) {
          peticion.acciones[index] = accionReaded;
        }
      });
    });
  }

  private eliminarBotonAccionConTrabajadorAfectado(numBoton: number): void {
    this.peticiones.forEach((peticion: PeticionActuacion) => {
      peticion.acciones.forEach((accion: Accion, index: number) => {
        const accionReaded = this.accionesConstantes.ACCIONES.find(
          (a: Accion) => a.id === accion.id
        );
        if (
          accionReaded !== undefined &&
          numBoton == accionReaded.id &&
          this.noTrabajadorAfectado(peticion.dniAfectado)
        ) {
          peticion.acciones.splice(index, 1);
        }
      });
    });
  }

  private noTrabajadorAfectado(dniTrabajador: string | undefined): boolean {
    return dniTrabajador === undefined ||
      dniTrabajador === null ||
      dniTrabajador === ''
      ? true
      : false;
  }

  nextPage(e: any, table: Table): void {
    if (this.rows !== e.rows) {
      this.rows = e.rows;
      e.first = 0;
      this.paginaActual = 1;
      const offset = e.first / this.rows + 1;
      table.first = offset;
      this.rows = e.rows;
      this.datosFiltro.currentPage = offset;
      this.datosFiltro.recordsPerPage = this.rows;
      this.peticionService.setDatosFiltrosActuacion(this.datosFiltro);
      this.getPeticiones(offset);
    } else {
      // verificar si se ha seleccionado un filtro nuevo, en caso contrario, está paginando
      if (
        (e.sortField && !this.lastSortField) ||
        (e.sortField && this.hasChanged(e.sortField, e.sortOrder))
      ) {
        this.lastSortField = {
          sortField: e.sortField,
          sortOrder: e.sortOrder,
        };
        this.customSort(this.lastSortField);
        this.peticionService.setDatosFiltrosActuacion(this._datosFiltro);
        table.first = (this.paginaActual - 1) * this.rows;
      } else {
        const offset = e.first / this.rows + 1;
        this.rows = e.rows;
        this.datosFiltro.currentPage = offset;
        this.datosFiltro.recordsPerPage = this.rows;
        this.peticionService.setDatosFiltrosActuacion(this.datosFiltro);
        this.getPeticiones(offset);
      }
    }
  }

  private hasChanged(sort: string, order: number): boolean {
    return (
      this.lastSortField.sortField !== sort ||
      this.lastSortField.sortOrder !== order
    );
  }

  getPeticiones(first: number): any {
    first = Math.round(first);
    this.peticionService.setDatosFiltrosActuacion({
      destinatario: this._datosFiltro.destinatario,
      anio: this._datosFiltro.anio,
      visualSeleccionada: this.visualSeleccionada,
      currentPage: first,
      recordsPerPage: this.rows,
      areas: this._datosFiltro.areas,
      estados: this._datosFiltro.estados,
      fechaAltaFin: this._datosFiltro.fechaAltaFin,
      fechaAltaInicio: this._datosFiltro.fechaAltaInicio,
      fechaCierreFin: this._datosFiltro.fechaCierreFin,
      fechaCierreInicio: this._datosFiltro.fechaCierreInicio,
      urgencias: this._datosFiltro.urgencias,
      provincias: this._datosFiltro.provincias,
      lineas: this._datosFiltro.lineas,
      actividades: this._datosFiltro.actividades,
      solicitanteEmails: this._datosFiltro.solicitanteEmails,
      tipos: this._datosFiltro.tipos,
      idPeticion: this._datosFiltro.idPeticion,
      zonas: this._datosFiltro.zonas,
      ambitos: this._datosFiltro.ambitos,
      regiones: this._datosFiltro.regiones,
      apellidosTrabajadorAfectado:
        this._datosFiltro.apellidosTrabajadorAfectado,
      dnisTrabajadorAfectado: this._datosFiltro.dnisTrabajadorAfectado,
      nombresTrabajadorAfectado: this._datosFiltro.nombresTrabajadorAfectado,
      cliente: this._datosFiltro.cliente,
      denominacion: this._datosFiltro.denominacion,
      busqueda: this._datosFiltro.busqueda,
      ocultarPeticiones: this._datosFiltro.ocultarPeticiones,
    });

    this.spinner.show();
    this.peticiones = [];

    const request = {
      destinatario: this._datosFiltro.destinatario,
      anio: this._datosFiltro.anio,
      areas: this._datosFiltro.areas,
      tipos: this._datosFiltro.tipos,
      idPeticion: this._datosFiltro.idPeticion,
      estados: this._datosFiltro.estados,
      urgencias: this._datosFiltro.urgencias,
      provincias: this._datosFiltro.provincias,
      lineas: this._datosFiltro.lineas,
      actividades: this._datosFiltro.actividades,
      solicitanteEmails: this._datosFiltro.solicitanteEmails,
      zonas: this._datosFiltro.zonas,
      ambitos: this._datosFiltro.ambitos,
      regiones: this._datosFiltro.regiones,
      fechaAltaInicio: this._datosFiltro.fechaAltaFin,
      fechaAltaFin: this._datosFiltro.fechaAltaFin,
      fechaCierreInicio: this._datosFiltro.fechaCierreInicio,
      fechaCierreFin: this._datosFiltro.fechaCierreFin,
      apellidosTrabajadorAfectado:
        this._datosFiltro.apellidosTrabajadorAfectado,
      dnisTrabajadorAfectado: this._datosFiltro.dnisTrabajadorAfectado,
      nombresTrabajadorAfectado: this._datosFiltro.nombresTrabajadorAfectado,
      cliente: this._datosFiltro.cliente,
      denominacion: this._datosFiltro.denominacion,
      busqueda: this._datosFiltro.busqueda,
      ocultarPeticiones: this._datosFiltro.ocultarPeticiones,
    } as PeticionActuacionFiltrosRequest;

    this.tablaCargando = true;
    this.peticionService
      .getPeticionesActuaciones(first, this.rows, request)
      .subscribe(
        (data: PeticionActuacion[]) => {
          this.cargarDatosTabla(data);
          if (this.table) {
            this.table.first = (this.paginaActual - 1) * this.rows;
          }
          this.tablaCargando = false;
          if (!this.isLoading) {
            this.spinner.hide();
          }
        },
        (error: any) => {
          console.error(error);
          this.peticiones = [];
          this.spinner.hide();
          this.tablaCargando = false;
        }
      );
  }

  public ejecutarAccion(idAccion: number, idPeticion: string): void {
    // se podria realizar un switch para, en funcion del id (ver constantes acciones para más info, realizar la misma - switch(id))
    this.peticionService.setControlActuaciones(true);
    switch (idAccion) {
      case this.constantes.ACCION_VISUALIZAR:
        this.router.navigate([`/peticiones/crear/confirmacion/${idPeticion}`]);
        break;
      case this.constantes.ACCION_EDITAR:
        this.router.navigate([
          `/peticiones/modificar/identificacion/${idPeticion}`,
        ]);
        break;
      case this.constantes.ACCION_COMENTAR:
        this.router.navigate([`/peticiones/${idPeticion}`]);
        break;
      case this.constantes.ACCION_ACLARACION:
        this.router.navigate([`/peticiones/${idPeticion}/aclaraciones`]);
        break;
      case this.constantes.ACCION_RESPONDER:
        this.mostrarModalResponder(idPeticion, idAccion);
        break;
      case this.constantes.ACCION_RECHAZAR:
        this.router.navigate([`/peticiones/${idPeticion}/cierre-peticion/13`]);
        break;
      case this.constantes.ACCION_RESOLVER_CERRAR:
        this.router.navigate([`/peticiones/${idPeticion}/cierre-peticion/14`]);
        break;
      case this.constantes.ACCION_SOLICITAR_AUTORIZACION:
        this.callModalAutorizar(idPeticion);
        break;
      case this.constantes.ACCION_INFORMAR:
        this.router.navigate([`/peticiones/${idPeticion}/informaciones`]);
        break;
      case this.constantes.ACCION_AUTORIZAR:
        this.router.navigate([`/peticiones/${idPeticion}/autorizaciones`]);
        break;
      case this.constantes.ACCION_ARTICA:
        this.mostrarModalArtica(idPeticion);
        break;
      case this.constantes.ACCION_REABRIR:
        this.router.navigate([`reabrir/${idPeticion}`]);
        break;
      case this.constantes.ACCION_AS400WEB:
        window.open(
          'https://as400web.eulen.com/EulenP/entry',
          '_blank',
          'noopener'
        );
        break;
      case this.constantes.ACCION_INFORMACION_EMPLEADO:
        this.router.navigate([
          `/informacion-empleado/${this.getDNIPeticion(idPeticion)}`,
        ]);
        break;
      default:
    }
  }

  private getDNIPeticion(idPeticion: string): string {
    let result = this.peticiones.find((obj) => {
      return obj.idPeticion === idPeticion;
    });

    return result !== undefined ? result?.dniAfectado : '00000000A';
  }

  public mostrarModalArtica(idPeticion: string): void {
    this.modalArtica.mostrarModal(idPeticion);
  }

  public mostrarModalResponder(idPeticion: string, idAccion: number): void {
    this.modalnotificar.mostrarModalResponder(
      idPeticion,
      idAccion,
      'Responder Petición',
      'EN CURSO'
    );
  }

  public callModalAutorizar(idPeticion: string): void {
    this.modalAutorizar.mostrarModal(idPeticion);
  }

  reload(): void {
    const offset = this.first / this.rows + 1;
    this.getPeticiones(offset);
    this.messageService.clear();
    this.initForm();
  }

  public articaRecibida(idPeticion: any): void {
    if (idPeticion) {
      const peticion = this.peticiones.find((p) => p.idPeticion === idPeticion);
      if (peticion) {
        peticion.fechaArtica = new Date();
      }
    }
  }

  private calcularPorcentajeSLA(peticiones: PeticionActuacion[]): void {
    peticiones.forEach((peticion: PeticionActuacion) => {
      if (peticion.calcularSLA === 1) {
        peticion.porcentajeRespuestaSLA = +(
          (peticion.tiempoConsumidoRespuesta / peticion.tiempoTotalRespuesta) *
          100
        ).toFixed(2);
        peticion.porcentajeResolucionSLA = +(
          (peticion.tiempoConsumidoResolucion /
            peticion.tiempoTotalResolucion) *
          100
        ).toFixed(2);
      }
    });
  }

  public activarVista(visual: number): void {
    this.visualSeleccionada = visual;
    this._datosFiltro.visualSeleccionada = this.visualSeleccionada;
    this._datosFiltro.currentPage = this.first === 0 ? 1 : this.first;
    this._datosFiltro.recordsPerPage = this.rows;
    this.peticionService.setDatosFiltrosActuacion(this.datosFiltro);
    this.initFields();
    if (visual === 3) {
      this.calcularPorcentajeSLA(this.peticiones);
    }
  }

  exportarDatos(): void {
    this.spinner.show();
    const request: PeticionExportFiltro = {
      anio: this._datosFiltro.anio,
      areasDestino: this._datosFiltro.areas,
      tiposPeticion: this._datosFiltro.tipos,
      idPeticion: this._datosFiltro.idPeticion,
      estados: this._datosFiltro.estados,
      urgencias: this._datosFiltro.urgencias,
      provincias: this._datosFiltro.provincias,
      lineasVenta: this._datosFiltro.lineas,
      actividades: this._datosFiltro.actividades,
      solicitanteEmails: this._datosFiltro.solicitanteEmails,
      zonas: this._datosFiltro.zonas,
      ambitos: this._datosFiltro.ambitos,
      regiones: this._datosFiltro.regiones,
      fechaAltaInicio: this._datosFiltro.fechaAltaFin,
      fechaAltaFin: this._datosFiltro.fechaAltaFin,
      fechaCierreInicio: this._datosFiltro.fechaCierreInicio,
      fechaCierreFin: this._datosFiltro.fechaCierreFin,
      apellidosTrabajadorAfectado:
        this._datosFiltro.apellidosTrabajadorAfectado,
      dnisTrabajadorAfectado: this._datosFiltro.dnisTrabajadorAfectado,
      nombresTrabajadorAfectado: this._datosFiltro.nombresTrabajadorAfectado,
      destinatarioEmails: this._datosFiltro.destinatario,
      ocultarPeticiones: this._datosFiltro.ocultarPeticiones,
    };
    this.peticionService.exportarControlActuaciones(request).subscribe(
      (urlRespuesta) => {
        if (urlRespuesta) {
          // Abrir nueva pantalla con el resultado del exportar
          this.spinner.hide();
          window.open(
            urlRespuesta.url,
            '_blank',
            'location=yes, height=570, width=520, scrollbars=yes, status=yes'
          );
        } else {
          const data = {
            title: 'Información',
            msg: 'No se ha podido hacer la exportación ...',
          };
          this.spinner.hide();
          this.dialog.open(ModalInfoComponent, {
            disableClose: true,
            autoFocus: false,
            data,
          });
        }
      },
      (error) => {
        this.spinner.hide();
        this.mensaje.showErrorPeticion(
          'No se ha podido exportar los datos a excel. Intentelo más tarde.'
        );
      }
    );
  }
}

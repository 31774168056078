import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Constantes } from 'src/app/utils/constantes';
import { Paginacion } from '../../utils/paginacion';
import { GruposSoporte, GruposSoporteNotificados } from '../../modelos/grupossoporte';

@Injectable({
  providedIn: 'root'
})
export class GrupossoporteService {

  constructor(
    private http: HttpClient,
    private constantes: Constantes,
    private paginacion: Paginacion
  ) { }

  public getGruposSoporte(page: string, size: string, sort?: string, direction?: string,
                          rol?: number, peticion?: string): Observable<GruposSoporte> {
    let params = this.paginacion.annadirParametrosPaginacion(page, size, sort, direction);
    if (rol !== undefined && peticion !== undefined) {
      params = params.append('rol', String(rol));
      params = params.append('peticion', peticion);
    }
    const url: string = this.constantes.ENDPOINT_API_GRUPOS_SOPORTE;
    return this.http.get<GruposSoporte>(url, {params});
  }

  public getGruposSoportebyId(id: string): Observable<GruposSoporte> {
    const url: string = this.constantes.ENDPOINT_API_GRUPOS_SOPORTE + '/' + id;
    return this.http.get<GruposSoporte>(url);
  }

  public crearGrupoSoporte(grupoSoporte: GruposSoporte): Observable<GruposSoporte> {
    const url: string = this.constantes.ENDPOINT_API_GRUPOS_SOPORTE;
    return this.http.post<GruposSoporte>(url, grupoSoporte);
  }

  public editarGrupoSoporte(grupoSoporte: GruposSoporte): Observable<GruposSoporte> {
    const url: string = this.constantes.ENDPOINT_API_GRUPOS_SOPORTE;
    return this.http.put<GruposSoporte>(url, grupoSoporte);
  }

  public deleteGrupoSoporte(id: string, email: string): Observable<any> {
    const url: string = this.constantes.ENDPOINT_API_GRUPOS_SOPORTE + '/' + id;
    const params = new HttpParams().set('email', email);
    return this.http.delete(url, {params, responseType: 'text'});
  }

  public exportar(): Observable<any> {
    return this.http.get(this.constantes.ENDPOINT_API_EXPORTAR + '/exportar-grupossoporte');
  }

  public getGruposSoporteNotificados(): Observable<GruposSoporteNotificados[]> {
    const url: string = this.constantes.ENDPOINT_API_GRUPOS_SOPORTE_NOTIFICADOS;
    return this.http.get<GruposSoporteNotificados[]>(url);
    }

}

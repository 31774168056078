<app-modal-notificar
  id="modal-notificar"
  #modalnotificar
  (emitirNotificar)="notificadores($event)"
></app-modal-notificar>
<ngx-spinner></ngx-spinner>
<app-mensaje #mensaje></app-mensaje>
<div class="container espacio">
  <div class="d-flex justify-content-between mb-3">
    <h1>Aclaraciones</h1>
  </div>

  <div class="row">
    <div class="col-12">
      <form [formGroup]="formulario" *ngIf="formulario">
        <div class="descripcion">
          <div class="col-lg-8 seccion-comentario">
            <div class="row">
              <div class="col-sm-12 col-lg-6">
                <span
                  _ngcontent-sbf-c163
                  class="etiqueta font-weight-bold"
                  style="font-size: 1.1rem"
                  >Petición Nº:
                </span>
                <span
                  _ngcontent-sbf-c163
                  class="etiqueta font-weight-bold"
                  id="idPeticion"
                  class="texto"
                  >{{ peticion.idPeticion }}
                </span>
              </div>
              <div class="col-sm-12 col-lg-6">
                <span
                  _ngcontent-sbf-c163
                  class="etiqueta font-weight-bold"
                  style="font-size: 1rem"
                  >Tipo de petición:
                </span>
                <span
                  _ngcontent-sbf-c163
                  class="etiqueta font-weight-bold"
                  class="catalogo"
                  >{{ peticion.catalogo?.descripcion }}
                </span>
                <br />
              </div>
            </div>
            <hr _ngcontent-ewq-c163 />
            <div>
              <span
                _ngcontent-sbf-c163
                class="etiqueta font-weight-bold"
                style="font-size: 1rem"
                >Solicitante:
              </span>
              <span
                _ngcontent-sbf-c163
                class="etiqueta font-weight-bold"
                class="usuarioPeticionario"
                >{{ peticion.usuarioPeticionario?.email }}
              </span>
              <br />
              <hr _ngcontent-ewq-c163 />
            </div>
            <div>
              <span
                _ngcontent-sbf-c163
                class="etiqueta font-weight-bold"
                style="font-size: 1rem"
                >Descripcion:
              </span>
              <br />
              <span
                _ngcontent-sbf-c163
                class="etiqueta font-weight-bold"
                class="descripcion"
                >{{ peticion.descripcion }}
              </span>
              <br />
              <hr _ngcontent-ewq-c163 />
            </div>
            <div class="row">
              <app-lista-aclaraciones
                style="width: 100%"
                [aclaraciones]="aclaraciones"
                *ngIf="aclaraciones.length > 0"
              ></app-lista-aclaraciones>
              <div
                *ngIf="aclaraciones.length === 0"
                class="col alert alert-warning text-center"
                role="alert"
              >
                Esta petición todavía no ha recibido aclaraciones
              </div>
            </div>
            <div
              class="form-group"
              style="margin-top: 3rem"
              *ngIf="permisoEscritura"
            >
              <div>
                <span *ngIf="borrador" class="badge badge-success"
                  >Borrador</span
                >

                <button
                  type="button"
                  class="btn btn-outline-secondary fa fa-eraser limpiar"
                  style="float: right"
                  (click)="limpiar()"
                >
                  Limpiar
                </button>
              </div>
              <textarea
                id="msg"
                class="form-control"
                formControlName="msg"
                rows="3"
                trim="blur"
                maxlength="3000"
                (keypress)="onKeyPress($event)"
                (keyup)="onKeyUp($event)"
                (blur)="controlForm()"
                required
              ></textarea>
              <div class="input-group">
                <span class="col col-lg-8"
                  >Máximo: 3000. Total: {{ contador }}
                </span>
                <small
                  class="form-text text-danger col col-lg-4 error-msg"
                  *ngIf="
                    !formulario.get('msg')!.valid &&
                    formulario.get('msg')!.touched &&
                    formulario.controls.msg.errors?.required
                  "
                  >* El campo es obligatorio</small
                >
              </div>
              <div class="espacio" *ngIf="uploadedFiles.length > 0 && borrador">
                <p-table [value]="uploadedFiles">
                  <ng-template pTemplate="header">
                    <tr>
                      <th id="nombre">Nombre de los ficheros del borrador</th>
                      <th id="acciones" style="width: 20%">Acciones</th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-file>
                    <tr>
                      <td>{{ file.nombre }}</td>
                      <td class="acciones d-flex justify-content-center">
                        <div class="row">
                          <button
                            pTooltip="Descargar"
                            tooltipPosition="right"
                            class="btn btn-sm customButton mx-1"
                            (pointerdown)="fijarElemento($event)"
                            (click)="
                              descargarFicheroAclaracion(
                                file.idFichero,
                                file.nombre
                              )
                            "
                          >
                            <em class="fa fa-download"></em>
                          </button>
                          <button *ngIf="!file.nombre.includes('.xls')"
                            pTooltip="Visualizar"
                            tooltipPosition="right"
                            class="btn btn-sm btn-outline-info mx-1"
                            (pointerdown)="fijarElemento($event)"
                            (click)="
                              visualizarFicheroAclaracion(
                                file.idFichero,
                                file.nombre
                              )
                            "
                          >
                            <em class="fa fa-file"></em>
                          </button>
                          <button
                            pTooltip="Eliminar"
                            tooltipPosition="right"
                            class="btn btn-sm btn-outline-danger mx-1"
                            (pointerdown)="fijarElemento($event)"
                            (click)="eliminarFichero(file.idFichero)"
                          >
                            <em class="fa fa-trash"></em>
                          </button>
                        </div>
                      </td>
                    </tr>
                    <hr class="item-list-separator" />
                  </ng-template>
                </p-table>
              </div>
              <div>
                <button
                  class="btn btn-outline-primary customButton btn-enviar col col-lg-3"
                  [disabled]="deshabilitar()"
                  (click)="crearAclaracion()"
                >
                  Enviar aclaración
                </button>
                <button
                  class="btn btn-success btn-enviar col col-lg-3"
                  style="margin-right: 12px"
                  [disabled]="deshabilitar()"
                  (click)="crearAclaracionBorrador()"
                >
                  Guardar borrador
                </button>
                <button
                  class="btn btn-danger btn-enviar col col-lg-3"
                  style="margin-right: 12px"
                  [disabled]="deshabilitar()"
                  (click)="eliminarAclaracionBorrador()"
                >
                  Eliminar borrador
                </button>
              </div>

              <div>
                <p-fileUpload
                  #fileInput
                  name="uploadedFiles"
                  [disabled]="
                    uploadedFiles.length + fileInput.files.length >= 5
                  "
                  [ngClass]="{
                    active: uploadedFiles.length + fileInput.files.length >= 5
                  }"
                  [multiple]="true"
                  [fileLimit]="5"
                  [maxFileSize]="15728640"
                  accept="image/*,.xls,.xlsx,.doc,.docx,.pdf"
                  chooseLabel="Adjuntar"
                  cancelLabel="Borrar"
                  uploadLabel="Guardar"
                  [showUploadButton]="false"
                  [showCancelButton]="false"
                  invalidFileLimitMessageSummary="Número máximo de ficheros excedidos: "
                  invalidFileLimitMessageDetail="El límite es 5."
                  invalidFileSizeMessageSummary="Tamaño máximo por fichero: "
                  invalidFileSizeMessageDetail="El máximo es 15 MB por documento."
                >
                </p-fileUpload>
              </div>
            </div>
          </div>
        </div>
      </form>
      <button class="btn btn-outline-secondary" (click)="volver()">
        Volver
      </button>
    </div>
  </div>
</div>

<ngx-spinner></ngx-spinner>

<div class="container espacio">
  <div class="d-flex justify-content-between align-items-center mb-3">
    <h1>Auditoría</h1>
    <button
      *ngIf="simple"
      class="btn volverBoton limpiarBoton customButton"
      (click)="back()"
    >
      Volver
    </button>
  </div>
  <div *ngIf="!simple" class="card">
    <app-filtro-auditoria (buscar)="search($event)"></app-filtro-auditoria>
  </div>
  <div class="card">
    <div class="borde"><app-tabla-auditoria #tabla></app-tabla-auditoria></div>
  </div>
</div>

import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Catalogo } from '../../../modelos/catalogo';
import { CatalogosService } from '../../../servicios/catalogos/catalogos.service';
import { MensajeComponent } from '../../mensaje/mensaje.component';
import {
  DatoEspecifico,
  DatoEspecificoCatalogo,
} from '../../../modelos/dato-especifico';
import { PeticionService } from '../../../servicios/peticion/peticion.service';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { InputComboValor } from 'src/app/modelos/combo-valor';
import { ButtonModalInfo, ModalInfo } from 'src/app/modelos/moda-info';
import { ModalInfoComponent } from '../../modals/modal-info/modal-info.component';
import { MatDialog } from '@angular/material/dialog';
import { ModalError } from 'src/app/modelos/modal-error';

declare let $: any;

@Component({
  selector: 'app-catalogo-datos-especificos',
  templateUrl: './catalogo-datos-especificos.component.html',
  styleUrls: ['./catalogo-datos-especificos.component.css'],
})
export class CatalogoDatosEspecificosComponent implements OnInit {
  idTipoPeticion!: string | null;
  modoEdicion!: boolean;
  comboMarcado: any = 0;
  validadoCombo = false;
  validadoGuardar = true;

  public formulario!: FormGroup;
  public modalError: ModalError;
  blockButtons: boolean = false;
  blockButtonsDates: boolean = false;

  datosEspecificosLst!: DatoEspecifico[];
  datoEspecificoLst!: DatoEspecifico;

  combos!: InputComboValor[];
  combosValores: string[] = [];
  ultimoOrden: any;

  @ViewChild('mensaje', { static: true })
  public mensaje!: MensajeComponent;

  listaTipos = [
    'INPUT',
    'INPUT_NUMBER',
    'COMBO',
    'PROVINCIAS',
    'EMPRESAS',
    'LV',
    'ACTIVIDADES',
    'CALENDARIO',
    'SEPARADOR',
    'LABEL',
  ];

  listaFechaRangos = ['Fecha', 'Rango'];

  listaCatalogos: Catalogo[] = [];
  catalogosFiltrados: string[] = [];

  catalogo!: Catalogo;

  txtBtn = '';

  txtBtnFinal = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private catalogosService: CatalogosService,
    private peticionService: PeticionService,
    public dialog: MatDialog
  ) {
    this.modalError = {
      title: '',
      mensaje: '',
      inicio: false,
      code: 1,
    };
  }

  ngOnInit(): void {
    this.idTipoPeticion = this.route.snapshot.paramMap.get('id');
    this.peticionService
      .getDatosEspecificosConfig(this.idTipoPeticion!)
      .subscribe(
        (resp) => {
          this.datosEspecificosLst = resp;
          if (this.datosEspecificosLst.length > 0) {
            let total = this.datosEspecificosLst.length;
            this.ultimoOrden = this.datosEspecificosLst[total - 1].orden + 1;
            this.loadData();
          } else {
            this.ultimoOrden = 1;
            this.loadData();
          }
          this.spinner.hide();
        },
        (error) => {
          console.log(error);
          this.spinner.hide();
          this.mensaje.showError(error);
        }
      );
  }

  loadData(): void {
    this.spinner.show();
    this.catalogosService.getCombosValores().subscribe((resp) => {
      let values = resp.values();
      for (const value of values) {
        this.combosValores.push(value.valor);
      }
      this.combos = resp;
      this.initForm();
    });
    this.spinner.hide();
  }

  initForm(): void {
    this.formulario = this.fb.group({});
    for (const e of this.datosEspecificosLst) {
      const identificador = e.idHtml;
      const tipoMarcado = this.listaTipos.findIndex(
        (element) => element === e.tipo
      );
      this.comboMarcado = this.combos.findIndex(
        (element) => element.id === e.codCombo
      );
      this.formulario.addControl(
        identificador + 'Id',
        new FormControl(e.id, null)
      );
      this.formulario.addControl(
        identificador + 'O',
        new FormControl(e.orden, null)
      );
      this.formulario.addControl(
        identificador + 'D',
        new FormControl(e.descripcion, null)
      );
      this.formulario.addControl(
        identificador + 'T',
        new FormControl(this.listaTipos[tipoMarcado], [Validators.required])
      );
      this.formulario.addControl(
        identificador + 'C',
        new FormControl(this.combosValores[this.comboMarcado], [
          Validators.required,
        ])
      );
      this.formulario.addControl(
        identificador + 'A',
        new FormControl(e.ayuda, null)
      );
      this.formulario.addControl(
        identificador + 'Ob',
        new FormControl(e.obligatorio, [Validators.required])
      );
      this.validacionTipo(1, identificador);
    }
    this.initFormCrear();
  }

  private initFormCrear(): void {
    this.formulario.addControl(
      'orden',
      new FormControl(this.ultimoOrden, null)
    );
    this.formulario.addControl('descripcion', new FormControl('', null));
    this.formulario.addControl(
      'tipo',
      new FormControl(this.listaTipos[0], [Validators.required])
    );
    this.formulario.addControl(
      'combo',
      new FormControl(this.combosValores[0], [Validators.required])
    );
    this.formulario.addControl('ayuda', new FormControl('', null));
    this.formulario.addControl(
      'obligatorio',
      new FormControl('', [Validators.required])
    );
    this.validacionTipo(2, '');
  }

  private añadirDatoEspecificoFormulario(e: DatoEspecifico) {
    const tipoMarcado = this.listaTipos.findIndex(
      (element) => element === e.tipo
    );
    this.comboMarcado = this.combos.findIndex(
      (element) => element.id === e.codCombo
    );
    this.formulario.addControl(e.idHtml + 'Id', new FormControl(e.id, null));
    this.formulario.addControl(e.idHtml + 'O', new FormControl(e.orden, null));
    this.formulario.addControl(
      e.idHtml + 'D',
      new FormControl(e.descripcion, null)
    );
    this.formulario.addControl(
      e.idHtml + 'T',
      new FormControl(this.listaTipos[tipoMarcado], [Validators.required])
    );
    this.formulario.addControl(
      e.idHtml + 'C',
      new FormControl(this.combosValores[this.comboMarcado], [
        Validators.required,
      ])
    );
    this.formulario.addControl(e.idHtml + 'A', new FormControl(e.ayuda, null));
    this.formulario.addControl(
      e.idHtml + 'Ob',
      new FormControl(e.obligatorio, [Validators.required])
    );
  }

  public callModificarDatosEspecificos(): void {
    this.spinner.show();
    this.catalogosService
      .modificarDatosEspecificos(this.crearDatoEspecificoObject())
      .subscribe(
        () => {
          this.spinner.hide();
          this.mensaje.showMensaje('Operación realizada correctamente...');
        },
        (error) => {
          this.spinner.hide();
          this.mensaje.showErrorPeticion(error.error);
        }
      );
  }

  public callCrearDatoEspecifico(): void {
    this.spinner.show();
    this.catalogosService
      .crearDatoEspecifico(
        this.crearNuevoDatoEspecificoObject(),
        this.idTipoPeticion
      )
      .subscribe(
        (resp) => {
          this.datoEspecificoLst = resp[resp.length - 1];
          this.añadirDatoEspecificoFormulario(this.datoEspecificoLst);
          this.ngOnInit();
          this.spinner.hide();
          this.mensaje.showMensaje('Operación realizada correctamente...');
        },
        (error) => {
          this.spinner.hide();
          this.mensaje.showErrorPeticion(error.error);
        }
      );
    this.router.navigate([
      `/catalogos/${this.idTipoPeticion}/datos-especificos`,
    ]);
  }

  eliminarDatoEspecifico(id: number): void {
    const data = {
      title: 'Eliminar dato especifico',
      msg: `¿Desea eliminar al dato especifico?`,
      buttons: [
        { title: 'Aceptar', class: 'btn-success', action: '1' },
        { title: 'Cancelar', class: 'btn-danger' },
      ] as ButtonModalInfo[],
    } as ModalInfo;
    this.dialog
      .open(ModalInfoComponent, { disableClose: true, autoFocus: false, data })
      .afterClosed()
      .subscribe((resp) => {
        if (resp) {
          this.spinner.show();
          this.catalogosService.eliminarDatosEspecificos(id).subscribe(() => {
            this.ngOnInit();
            this.mensaje.showMensaje(
              'Dato especifico eliminado satisfactoriamente.'
            );
            this.spinner.hide();
          });
        }
      });
    this.router.navigate([
      `/catalogos/${this.idTipoPeticion}/datos-especificos`,
    ]);
  }

  private crearDatoEspecificoObject(): DatoEspecificoCatalogo[] {
    const valoresEsp: DatoEspecificoCatalogo[] = [];
    for (const e of this.datosEspecificosLst) {
      const identificador = e.idHtml;
      const datoEspecificoCatalogo: DatoEspecificoCatalogo = {
        id: this.formulario.get(identificador + 'Id')?.value,
        orden: this.formulario.get(identificador + 'O')?.value,
        descripcion: this.formulario.get(identificador + 'D')?.value,
        tipo: this.formulario.get(identificador + 'T')?.value,
        combo: this.combos.find(
          (element) =>
            element.valor === this.formulario.get(identificador + 'C')?.value
        ),
        ayuda: this.formulario.get(identificador + 'A')?.value,
        obligatorio: this.formulario.get(identificador + 'Ob')?.value,
      };
      valoresEsp.push(datoEspecificoCatalogo);
    }
    return valoresEsp;
  }

  private crearNuevoDatoEspecificoObject(): DatoEspecificoCatalogo {
    const datoEspecificoCatalogo: DatoEspecificoCatalogo = {
      id: '',
      orden: this.formulario.get('orden')?.value,
      descripcion: this.formulario.get('descripcion')?.value,
      tipo: this.formulario.get('tipo')?.value,
      combo: this.combos.find(
        (element) => element.valor === this.formulario.get('combo')?.value
      ),
      ayuda: this.formulario.get('ayuda')?.value,
      obligatorio: this.formulario.get('obligatorio')?.value,
    };
    return datoEspecificoCatalogo;
  }

  private validacionTipo(id: number, identificador: string): void {
    if (id === 1) {
      this.formulario.get(identificador + 'C')?.valueChanges.subscribe((x) => {
        this.comprobarInputs(id, identificador);
      });
    } else {
      this.formulario.get('combo')?.valueChanges.subscribe((x) => {
        this.comprobarInputs(id, identificador);
      });
    }
  }

  comprobarCrearGuardar(id: number, identificador: string): any {
    let dato: any;
    if (id === 1) {
      dato = {
        tipo: this.formulario.get(identificador + 'T')?.value,
        combo: this.combos.find(
          (element) =>
            element.valor === this.formulario.get(identificador + 'C')?.value
        ),
      };
    } else {
      dato = {
        tipo: this.formulario.get('tipo')?.value,
        combo: this.combos.find(
          (element) => element.valor === this.formulario.get('combo')?.value
        ),
      };
    }
    return dato;
  }

  comprobarInputs(id: number, identificador: string): any {
    let dato = this.comprobarCrearGuardar(id, identificador);
    switch (dato.tipo) {
      case 'INPUT':
        this.comprobarTipoInput(dato.tipo, dato.combo.id, id);
        break;
      case 'INPUT_NUMBER':
        this.comprobarTipoInputNumber(dato.tipo, dato.combo.id, id);
        break;
      case 'COMBO':
        this.comprobarTipoCombo(dato.tipo, dato.combo.id, id);
        break;
      case 'PROVINCIAS':
        this.comprobarTipoProvincias(dato.tipo, dato.combo.id, id);
        break;
      case 'EMPRESAS':
        this.comprobarTipoEmpresas(dato.tipo, dato.combo.id, id);
        break;
      case 'LV':
        this.comprobarTipoLV(dato.tipo, dato.combo.id, id);
        break;
      case 'ACTIVIDADES':
        this.comprobarTipoActividad(dato.tipo, dato.combo.id, id);
        break;
      case 'CALENDARIO':
        this.comprobarTipoCalendario(dato.tipo, dato.combo.id, id);
        break;
    }
  }

  comprobarTipoInput(tipo: string, combo: number, id: number): void {
    if (tipo === 'INPUT' && combo !== 3 && combo !== 4) {
      this.handleDatoEpecificoError(
        'Error en elección del combo',
        'El tipo Input solo puede tener combo campo libre 50/200.'
      );
      if (id === 1) {
        this.validadoGuardar = false;
      } else {
        this.validadoCombo = false;
      }
    } else {
      if (id === 1) {
        this.validadoGuardar = true;
      } else {
        this.validadoCombo = true;
      }
    }
  }

  comprobarTipoInputNumber(tipo: string, combo: number, id: number): void {
    if (tipo === 'INPUT_NUMBER' && combo !== 16) {
      this.handleDatoEpecificoError(
        'Error en elección del combo',
        'El tipo Input solo puede tener combo Number.'
      );
      if (id === 1) {
        this.validadoGuardar = false;
      } else {
        this.validadoCombo = false;
      }
    } else {
      if (id === 1) {
        this.validadoGuardar = true;
      } else {
        this.validadoCombo = true;
      }
    }
  }

  comprobarTipoCombo(tipo: string, combo: number, id: number): void {
    if (
      tipo === 'COMBO' &&
      combo !== 1 &&
      combo !== 2 &&
      combo !== 5 &&
      combo !== 6 &&
      combo !== 11 &&
      combo !== 13 &&
      combo !== 14
    ) {
      this.handleDatoEpecificoError(
        'Error en elección del combo',
        'El tipo Combo solo puede tener combo NO/SI, VERDADERO/FALSO, DIURNO/NOCTURNO, P.I/P.O, Candidato en elecciones sindicales reciente,' +
          ' Certificado discapacidad, Contrato bonificado, Litigio reciente, Maternidad o paternidad inferior a 1año, Mujer embarazada, No aplica, Persona en situación de jornada por guarda legal o cuidado de familiares, Persona en situación IT, ' +
          ' Representante Legal de los Trabajadores, Sanciones Previas, EXTRAORDINARIA/ORDINARIA, COMPLETA/PARCIAL.'
      );
      if (id === 1) {
        this.validadoGuardar = false;
      } else {
        this.validadoCombo = false;
      }
    } else {
      if (id === 1) {
        this.validadoGuardar = true;
      } else {
        this.validadoCombo = true;
      }
    }
  }

  comprobarTipoProvincias(tipo: string, combo: number, id: number): void {
    if (tipo === 'PROVINCIAS' && combo !== 7) {
      this.handleDatoEpecificoError(
        'Error en elección del combo',
        'El tipo Provincias solo puede tener combo provincias.'
      );
      if (id === 1) {
        this.validadoGuardar = false;
      } else {
        this.validadoCombo = false;
      }
    } else {
      if (id === 1) {
        this.validadoGuardar = true;
      } else {
        this.validadoCombo = true;
      }
    }
  }

  comprobarTipoEmpresas(tipo: string, combo: number, id: number): void {
    if (tipo === 'EMPRESAS' && combo !== 8) {
      this.handleDatoEpecificoError(
        'Error en elección del combo',
        'El tipo Empresas solo puede tener combo empresas.'
      );
      if (id === 1) {
        this.validadoGuardar = false;
      } else {
        this.validadoCombo = false;
      }
    } else {
      if (id === 1) {
        this.validadoGuardar = true;
      } else {
        this.validadoCombo = true;
      }
    }
  }

  comprobarTipoLV(tipo: string, combo: number, id: number): void {
    if (tipo === 'LV' && combo !== 9) {
      this.handleDatoEpecificoError(
        'Error en elección del combo',
        'El tipo Lineas de Venta solo puede tener combo linea venta.'
      );
      if (id === 1) {
        this.validadoGuardar = false;
      } else {
        this.validadoCombo = false;
      }
    } else {
      if (id === 1) {
        this.validadoGuardar = true;
      } else {
        this.validadoCombo = true;
      }
    }
  }

  comprobarTipoActividad(tipo: string, combo: number, id: number): void {
    if (tipo === 'ACTIVIDADES' && combo !== 10) {
      this.handleDatoEpecificoError(
        'Error en elección del combo',
        'El tipo Actividad solo puede tener combo actividad.'
      );
      if (id === 1) {
        this.validadoGuardar = false;
      } else {
        this.validadoCombo = false;
      }
    } else {
      if (id === 1) {
        this.validadoGuardar = true;
      } else {
        this.validadoCombo = true;
      }
    }
  }

  comprobarTipoCalendario(tipo: string, combo: number, id: number): void {
    if (tipo === 'CALENDARIO' && combo !== 12) {
      this.handleDatoEpecificoError(
        'Error en elección del combo',
        'El tipo Calendario solo puede tener combo calendario.'
      );
      if (id === 1) {
        this.validadoGuardar = false;
      } else {
        this.validadoCombo = false;
      }
    } else {
      if (id === 1) {
        this.validadoGuardar = true;
      } else {
        this.validadoCombo = true;
      }
    }
  }

  redirect(): void {
    this.router.navigate(['/catalogos']);
  }

  handleDatoEpecificoError(title: string, message: any): void {
    this.modalError = {
      title,
      mensaje: message,
      inicio: true,
      code: 1,
    };
    $('#modalDatoEspecifico').modal({ backdrop: false, keyboard: false });
  }

  cerrarModal(): void {
    this.blockButtons = true;
    this.blockButtonsDates = true;
    $('#modalDatoEspecifico').modal('hide');
    this.modalError = {
      title: '',
      mensaje: '',
      inicio: false,
      code: 1,
    };
  }
}

import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { Table } from 'primeng/table';
import { AuditoriaPeticionRequest } from 'src/app/modelos/auditoria';
import { AuditoriaPeticionesService } from 'src/app/servicios/auditorias/auditoria-peticiones.service';
import { Constantes } from 'src/app/utils/constantes';
import Utils from 'src/app/utils/utils';
import { ModalInfoComponent } from '../../modals/modal-info/modal-info.component';

@Component({
  selector: 'app-tabla-auditoria',
  templateUrl: './tabla-auditoria.component.html',
  styleUrls: ['./tabla-auditoria.component.scss'],
})
export class TablaAuditoriaComponent implements OnInit {
  auditorias: any[];
  rows = 25;
  auditoriasTotales = 0;
  cols: any[] = [];
  paginasTotales = 0;
  paginaActual = 1;
  registrosPorPagina: number[] = [];
  constantes: Constantes;
  lastSortField!: any;
  first = 1;
  requestFilters: AuditoriaPeticionRequest = {};
  constructor(
    private spinner: NgxSpinnerService,
    public dialog: MatDialog,
    private service: AuditoriaPeticionesService
  ) {
    this.auditorias = [];
    this.cols = [
      { field: 'idPeticion', header: 'PETICIÓN' },
      { field: 'usuario', header: 'USUARIO' },
      { field: 'fecha', header: 'FECHA' },
      { field: 'accion', header: 'ACCIÓN' },
      { field: 'observaciones', header: 'OBSERVACIONES' },
    ];

    this.constantes = new Constantes();
    this.registrosPorPagina = this.constantes.numeroUsuariosPorPagina;
  }

  ngOnInit(): void {
    this.loadData(this.requestFilters);
  }

  nextPage(e: any, table: Table): void {
    if (this.rows !== e.rows) {
      this.rows = e.rows;
      e.first = 0;
      this.paginaActual = 1;
      const offset = e.first / this.rows + 1;
      table.first = offset;
      this.rows = e.rows;
      this.getAuditorias(offset);
    } else {
      // verificar si se ha seleccionado un filtro nuevo, en caso contrario, está paginando
      if (
        (e.sortField && !this.lastSortField) ||
        (e.sortField && this.hasChanged(e.sortField, e.sortOrder))
      ) {
        this.lastSortField = {
          sortField: e.sortField,
          sortOrder: e.sortOrder,
        };
        this.customSort(this.lastSortField);
        table.first = (this.paginaActual - 1) * this.rows;
      } else {
        const offset = e.first / this.rows + 1;
        this.rows = e.rows;
        this.getAuditorias(offset);
      }
    }
  }

  loadData(filtros: any, refresh = false): void {
    this.requestFilters = filtros;
    this.spinner.show();
    this.registrosPorPagina = this.constantes.numeroUsuariosPorPagina;

    if (refresh) {
      this.first = 1;
      this.paginaActual = 1;
      this.rows = 25;
    }
    if (filtros) {
      this.service
        .getAuditorias(this.requestFilters, this.first, this.rows)
        .subscribe(
          (data: any) => {
            this.cargarDatosTabla(data, true);
            this.spinner.hide();
          },
          () => {
            this.spinner.hide();
          }
        );
    } else {
      this.cargarDatosTabla(
        { results: null, totalPages: 0, totalResults: 0, currentPage: 0 },
        true
      );
      this.spinner.hide();
    }
  }

  getAuditorias(offset: number): any {
    this.spinner.show();

    this.service
      .getAuditorias(this.requestFilters, offset, this.rows)
      .subscribe((res) => {
        this.cargarDatosTabla(res);
        this.spinner.hide();
      });
  }

  private cargarDatosTabla(data: any, refresh = false): any {
    this.auditorias = data.results;
    this.paginaActual = refresh ? 1 : data.currentPage;
    this.paginasTotales = data.totalPages;
    this.auditoriasTotales = data.totalResults;
    this.customSort(this.lastSortField);
  }

  private hasChanged(sort: string, order: number): boolean {
    return (
      this.lastSortField.sortField !== sort ||
      this.lastSortField.sortOrder !== order
    );
  }

  private customSort(sort: any): void {
    if (sort && this.auditorias) {
      this.auditorias.sort((a: any, b: any) => {
        switch (sort.sortField) {
          case this.cols[0].field:
            return Utils.sortString(a.idPeticion, b.idPeticion, sort.sortOrder);
          case this.cols[1].field:
            return Utils.sortString(a.emailActor, b.emailActor, sort.sortOrder);
          case this.cols[2].field:
            return Utils.sortString(a.fechaHora, b.fechaHora, sort.sortOrder);
          case this.cols[3].field:
            return Utils.sortNumber(a.accion, b.accion, sort.sortOrder);
          default:
            return sort.sortOrder;
        }
      });
    }
  }

  mostrarObservaciones(observaciones: any): any {
    const mensaje: string[] = observaciones.split('\n');
    const data = {
      title: 'Modificaciones realizadas',
      msg: mensaje.filter((s) => s !== ''),
      isListInfo: true,
    };
    const dialog = this.dialog.open(ModalInfoComponent, {
      disableClose: true,
      autoFocus: false,
      data,
    });
    dialog.afterClosed().subscribe();
  }

  exportarDatos(): void {
    this.spinner.show();
    this.service.exportar(this.requestFilters).subscribe((urlRespuesta) => {
      if (urlRespuesta) {
        this.spinner.hide();
        window.open(
          urlRespuesta.url,
          '_blank',
          'location=yes, height=570, width=520, scrollbars=yes, status=yes'
        );
      } else {
        const data = {
          title: 'Información',
          msg: 'No se ha podido hacer la exportación ...',
        };
        this.dialog.open(ModalInfoComponent, {
          disableClose: true,
          autoFocus: false,
          data,
        });
      }
    });
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Constantes } from 'src/app/utils/constantes';

@Injectable({
  providedIn: 'root'
})
export class TerritoriosService {

  constructor(
    private http: HttpClient,
    private constantes: Constantes
  ) { }

  public getZonas(): Observable<any> {
    const url: string = this.constantes.ENDPOINT_API_ZONAS;
    return this.http.get(url);
  }

  public getAmbitosZona(idZona: number[]): Observable<any> {
    const url: string = this.constantes.ENDPOINT_API_AMBITOS_ZONA.replace('{idZona}', idZona + '');
    return this.http.get(url);
  }

  public getProvinciasAmbito(idAmbitos: number[]): Observable<any> {
    const url: string = this.constantes.ENDPOINT_API_AMBITOS_PROVINCIA.replace('{idAmbito}', idAmbitos + '');
    return this.http.get(url);
  }

  public getRegiones(): Observable<any> {
    const url: string = this.constantes.ENDPOINT_API_REGIONES;
    return this.http.get(url);
  }

  public getProvincias(): Observable<any> {
    const url: string = this.constantes.ENDPOINT_API_PROVINCIAS;
    return this.http.get(url);
  }

  public getAmbitos(): Observable<any> {
    const url: string = this.constantes.ENDPOINT_API_AMBITOS;
    return this.http.get(url);
  }

  public getProvinciasRegiones(idRegiones: number[]): Observable<any> {
    const url: string = this.constantes.ENDPOINT_API_REGIONES_PROVINCIA.replace('{idRegiones}', idRegiones + '');
    return this.http.get(url);
  }

  public obtenerDetalleProvincia(idProvincia: string, email: string): Observable<any> {
    const url: string = this.constantes.ENDPOINT_API_PROVINCIAS + '/' + idProvincia;
    const params = new HttpParams().set('email', email);
    return this.http.get(url, {params});
  }
}

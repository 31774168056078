import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalInfo } from 'src/app/modelos/moda-info';

@Component({
  selector: 'app-modal-info',
  templateUrl: './modal-info.component.html',
  styleUrls: ['./modal-info.component.css']
})
export class ModalInfoComponent {

  constructor(public dialogRef: MatDialogRef<ModalInfoComponent>, @Inject(MAT_DIALOG_DATA) public data: ModalInfo) { }



  close(): void{
    this.dialogRef.close();
  }

  accion(actionReceived: string): void{
    this.dialogRef.close(actionReceived);
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NotificacionSimple } from 'src/app/modelos/notificacion-simple';
import { Constantes } from 'src/app/utils/constantes';
import HttpClientUtils from 'src/app/utils/http-client-utils';
import {
  NotificacionFiltros,
  NotificacionFiltroRequest,
} from '../../modelos/notificacion-filtros';
import { Notificacion, NotificacionResponse } from '../../modelos/notificacion';

@Injectable({
  providedIn: 'root',
})
export class NotificacionesService {
  datosFiltrosNotificacion!: NotificacionFiltros;

  constructor(private http: HttpClient, private constantes: Constantes) {}

  public setDatosFiltrosNotificacion(datosFiltro: NotificacionFiltros): void {
    this.datosFiltrosNotificacion = datosFiltro;
  }

  public getDatosFiltrosNotificacion(): NotificacionFiltros {
    return this.datosFiltrosNotificacion;
  }

  public obtenerListaNotificadores(idPeticion: any): Observable<any> {
    return this.http.get(
      this.constantes.ENDPOINT_API_NOTIFICACIONES_NOTIFICADORES.replace(
        '{idPeticion}',
        idPeticion
      )
    );
  }

  public obtenerNotificaciones(
    offset: number,
    limit: number,
    filtros: NotificacionFiltroRequest
  ): Observable<NotificacionSimple[]> {
    let params = HttpClientUtils.toHttpParams(filtros);
    params = params.append('offset', String(offset));
    params = params.append('limit', String(limit));
    const url: string = this.constantes.ENDPOINT_API_NOTIFICACIONES;
    return this.http.get<NotificacionSimple[]>(url, { params });
  }

  public obtenerNotificacion(
    idTipoPeticion: string,
    idAccion: number
  ): Observable<NotificacionResponse> {
    return this.http.get<NotificacionResponse>(
      this.constantes.ENDPOINT_API_NOTIFICACIONES_DETALLE.replace(
        '{idTipoPeticion}',
        idTipoPeticion
      ).replace('{idAccion}', String(idAccion))
    );
  }

  public crearNotificacion(
    request: NotificacionResponse
  ): Observable<NotificacionResponse> {
    return this.http.post<NotificacionResponse>(
      this.constantes.ENDPOINT_API_NOTIFICACIONES,
      request
    );
  }

  public editarNotificacion(
    request: NotificacionResponse
  ): Observable<NotificacionResponse> {
    return this.http.put<NotificacionResponse>(
      this.constantes.ENDPOINT_API_NOTIFICACIONES,
      request
    );
  }

  public cambioEstado(request: Notificacion): Observable<any> {
    return this.http.put<any>(
      `${this.constantes.ENDPOINT_API_NOTIFICACIONES}/estado/${request.tipoPeticion}/${request.codAccion}`,
      null
    );
  }

  public exportar(filtros: NotificacionFiltroRequest): Observable<any> {
    let params = HttpClientUtils.toHttpParams(filtros);
    params = params.append('offset', '0');
    return this.http.get(
      this.constantes.ENDPOINT_API_EXPORTAR + '/exportar-notificaciones',
      { params }
    );
  }
}

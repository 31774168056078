<app-mensaje #mensaje>
</app-mensaje>
<ngx-spinner></ngx-spinner>
<nav class="navbar navbar-dark cabecera">
    <div class="dmt-2 mb-2">
        <img class="mr-3 ml-2 header-icon" alt="principal-photo" src="../../../assets/header-icon.jpg" height="60px" (click)="mostrarMenu()" />
        <button class="navbar-toggler custom" type="button" data-toggle="active" data-target="#sidebar" (click)="mostrarMenu()">
          <span class="navbar-toggler-icon">
            <em class="fa fa-bars mt-1" style="color:#fff; font-size: 30px;"></em>
          </span>
    </button>
    </div>
    <div class="tituloversion">
        <h1 class="font-weight-bolder text-white text-center" [class]="estiloTitulo">
            {{ titulo }}
        </h1>
        <a class="font-weight-bolder text-white text-center"> v{{ version }} </a>
    </div>

    <div id="buttonDiv" *ngIf="!isLoggedIn() || sesionService.getSesionCaducada()===true"></div>

    <div class="d-flex align-items-center flex-column mr-4" *ngIf="isLoggedIn() && sesionService.getSesionCaducada()===false">
        <div><a class="navbar-brand mb-1 mt-2" href="#"><em class="fa fa-user"></em> {{usuarioNombre}}</a></div>
        <button class=" btn btn-outline-info botonLogin text-nowrap mb-3" (click)="signOut()">Cerrar sesión</button>
    </div>

</nav>

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Constantes } from 'src/app/utils/constantes';

@Injectable({
  providedIn: 'root'
})
export class PuestosService {

  constructor(
    private http: HttpClient,
    private constantes: Constantes
  ) { }

  public getPuestos(): Observable<any> {
    const url: string = this.constantes.ENDPOINT_API_PUESTOS;
    return this.http.get(url);
  }

  public getPuestosPaged(offset: number, limit: number): Observable<any> {
    const params = new HttpParams().set('offset', String(offset)).append('limit', String(limit));
    return this.http.get(this.constantes.ENDPOINT_API_PUESTOS_LIST, {params});
  }

  exportar(): Observable<any> {
    return this.http.get(this.constantes.ENDPOINT_API_EXPORTAR + '/exportar-puestos');
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Constantes } from 'src/app/utils/constantes';
import { Usuario } from 'src/app/modelos/usuario';
import { UsuarioFiltros } from 'src/app/modelos/usuario-filtros';
import { UsuarioTerritorio } from '../../modelos/usuario';
import { UsuarioFiltrosRequest } from '../../modelos/usuario-filtros';

@Injectable({
  providedIn: 'root',
})
export class UsuariosService {
  constructor(private http: HttpClient, private constantes: Constantes) {}

  private datosFiltros!: UsuarioFiltros;

  public setDatosFiltros(datosFiltros: UsuarioFiltros): void {
    this.datosFiltros = datosFiltros;
  }

  public getDatosFiltros(): UsuarioFiltros {
    return this.datosFiltros;
  }

  public altaUsuario(usuario: Usuario): Observable<Usuario> {
    const url: string = this.constantes.ENDPOINT_API_USUARIOS;
    return this.http.post<Usuario>(url, usuario);
  }

  public peticionAltaUsuario(usuario: Usuario): Observable<Usuario> {
    const url: string = this.constantes.ENDPOINT_API_NEW_USUARIO;
    return this.http.post<Usuario>(url, usuario);
  }

  public validaUsuario(idUser: string): Observable<any> {
    const url: string = this.constantes.ENDPOINT_API_USUARIOS_VALIDAR.replace(
      '{idUser}',
      idUser
    );
    return this.http.get(url, { responseType: 'text' });
  }

  actualizarUsuario(usuario: Usuario): Observable<any> {
    const url: string = this.constantes.ENDPOINT_API_USUARIOS;
    return this.http.put<Usuario>(url, usuario);
  }

  activarUsuario(idUser: string, emailAdmin: string): Observable<any> {
    const url: string = this.constantes.ENDPOINT_API_USUARIOS_ACTIVAR.replace(
      '{idUser}',
      idUser
    );
    const params = new HttpParams().set('emailEditor', emailAdmin);
    return this.http.patch(url, null, { params, responseType: 'text' });
  }

  public getUsuarios(offset: string, limit: string): Observable<any> {
    const params = new HttpParams()
      .set('offset', offset)
      .append('limit', limit);
    return this.http.get(this.constantes.ENDPOINT_API_USUARIOS, { params });
  }

  public getUsuario(email: string): Observable<Usuario> {
    return this.http.get<Usuario>(
      this.constantes.ENDPOINT_API_USUARIOS + '/' + email
    );
  }

  public deleteUsuario(idUser: string, emailAdmin: string): Observable<any> {
    const url: string = this.constantes.ENDPOINT_API_USUARIOS_ID.replace(
      '{idUser}',
      idUser
    );
    const params = new HttpParams().set('emailEditor', emailAdmin);
    return this.http.delete(url, { params, responseType: 'text' });
  }

  public login(email: string): Observable<any> {
    return this.http.get(
      this.constantes.ENDPOINT_API_USUARIOS_LOGIN.replace('{idUser}', email)
    );
  }

  public buscarUsuariosPorFiltro(
    usuarios: UsuarioFiltrosRequest,
    offset: string,
    limit: string
  ): Observable<any> {
    const params = new HttpParams()
      .set('offset', offset)
      .append('limit', limit);
    return this.http.post(
      this.constantes.ENDPOINT_API_USUARIOS_BUSCAR,
      usuarios,
      { params }
    );
  }

  public admingoogledirectory(email: string): Observable<any> {
    return this.http.get(
      this.constantes.ENDPOINT_API_USUARIOS_ADMINGOOGLE.replace(
        '{idUser}',
        email
      )
    );
  }

  public exportar(usuarios: UsuarioFiltros): Observable<any> {
    const params = new HttpParams().set('offset', '0').append('limit', '0');
    return this.http.post(
      this.constantes.ENDPOINT_API_USUARIOS_EXPORTAR,
      usuarios,
      { params, responseType: 'text' }
    );
  }

  buscarDniForm(dni: any): Observable<any> {
    const params = new HttpParams().set('dni', dni);
    return this.http.get(this.constantes.ENDPOINT_API_USUARIOS_DNI, { params });
  }

  buscarNombreForm(nombre: any): Observable<any> {
    const params = new HttpParams().set('nombre', nombre);
    return this.http.get(this.constantes.ENDPOINT_API_USUARIOS_NOMBRE, {
      params,
    });
  }

  buscarApellidoForm(apellido: any): Observable<any> {
    const params = new HttpParams().set('apellido', apellido);
    return this.http.get(this.constantes.ENDPOINT_API_USUARIOS_APELLIDO, {
      params,
    });
  }

  buscarEmailForm(email: any): Observable<any> {
    const params = new HttpParams().set('email', email);
    return this.http.get(this.constantes.ENDPOINT_API_USUARIOS_EMAIL, {
      params,
    });
  }

  public getUsuarioFull(email: any): Observable<Usuario> {
    return this.http.get<Usuario>(
      this.constantes.ENDPOINT_API_USUARIOS_ID_FULL.replace('{idUser}', email)
    );
  }

  public getUsuarioLogin(email: any): Observable<Usuario> {
    return this.http.get<Usuario>(
      this.constantes.ENDPOINT_API_LOGIN.replace('{idUser}', email)
    );
  }

  public buscarDestinatarios(
    zona: number,
    ambito: number,
    region: number,
    provincia: number,
    grupoSoporte: number,
    lineaVenta: number,
    actividad: number
  ): Observable<any> {
    const grupoFinal = String(grupoSoporte);
    const actividadFinal = String(actividad);
    const lineaFinal = String(lineaVenta);
    const provinciaFinal = String(provincia);
    const zonaFinal = String(zona);
    const ambitoFinal = String(ambito);
    const regionFinal = String(region);

    const params = new HttpParams()
      .set('zona', zonaFinal)
      .append('ambito', ambitoFinal)
      .append('region', regionFinal)
      .append('provincia', provinciaFinal)
      .append('grupoSoporte', grupoFinal)
      .append('lineaVenta', lineaFinal)
      .append('actividad', actividadFinal);
    return this.http.get<any>(
      this.constantes.ENDPOINT_API_USUARIOS_BUSCAR_DESTINATARIOS,
      { params }
    );
  }

  public buscarTecnicos(perfil: number, email: string): Observable<any> {
    const params = new HttpParams()
      .set('email', email)
      .append('perfil', String(perfil));
    return this.http.get<any>(this.constantes.API_ENDPOINT_USUARIOS_TECNICOS, {
      params,
    });
  }

  public getTerritorios(email: string): Observable<UsuarioTerritorio[]> {
    return this.http.get<UsuarioTerritorio[]>(
      this.constantes.ENDPOINT_API_USUARIOS + '/' + email + '/territorios'
    );
  }
}

<p-blockUI [blocked]="block"></p-blockUI>
<p-toast [style]="{ marginTop: '80px' }"></p-toast>
<p-toast
  position="center"
  key="error"
  (onClose)="onReject()"
  [baseZIndex]="10000"
>
  <ng-template let-ModalMensaje pTemplate="modalMensaje">
    <div style="width: 80%; text-align: center; padding: 1rem 0">
      <em class="pi pi-exclamation-circle" style="font-size: 3em"></em>
      <h3>{{ modalMensaje.title }}</h3>
      <p>{{ modalMensaje.mensaje }}</p>
    </div>
  </ng-template>
</p-toast>

<p-toast position="center" key="ok" (onClose)="onReject()" [baseZIndex]="10000">
  <ng-template let-ModalMensaje pTemplate="modalMensaje">
    <div style="width: 80%; text-align: center; padding: 1rem 0">
      <em class="pi pi-check" style="font-size: 3em"></em>
      <h3>{{ modalMensaje.title }}</h3>
      <p>{{ modalMensaje.mensaje }}</p>
    </div>
  </ng-template>
</p-toast>

<p-toast
  position="center"
  key="confirm"
  (onClose)="cerrar()"
  [baseZIndex]="10000"
>
  <ng-template let-ModalMensaje pTemplate="modalMensaje">
    <div class="p-flex p-flex-column" style="flex: 1; padding: 1rem 0">
      <div style="text-align: center">
        <em class="pi pi-exclamation-triangle" style="font-size: 3em"></em>
        <h3>{{ modalMensaje.title }}</h3>
        <p>{{ modalMensaje.mensaje }}</p>
      </div>
      <div class="">
        <div class="actions">
          <button
            type="button"
            pButton
            (click)="cerrar()"
            label="No"
            class="ui-button-secondary"
          ></button>
          <button
            type="button"
            pButton
            (click)="onConfirm()"
            label="Sí"
            class="ui-button-success"
          ></button>
        </div>
      </div>
    </div>
  </ng-template>
</p-toast>

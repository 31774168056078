import { Component, OnInit } from '@angular/core';
import { SesionService } from '../../servicios/sesion/sesion.service';
import { InformeDatastudio } from '../../modelos/informesdatastudio';

@Component({
  selector: 'app-informes',
  templateUrl: './informes.component.html',
  styleUrls: ['./informes.component.css']
})
export class InformesComponent implements OnInit {

  constructor( private sesionService: SesionService) { }

  public infomeDatastudio?: InformeDatastudio;

  ngOnInit(): void {
    this.infomeDatastudio = this.sesionService.getUser().informeDatastudio;
  }

  public openInfomeDatastudio(): void {
    window.open(this.infomeDatastudio?.enlace, '_blank', 'location=yes, height=670, width=850, scrollbars=yes, status=yes');

  }


}

<ngx-spinner></ngx-spinner>

<div class="container espacio">
  <div class="d-flex justify-content-between mb-3">
    <h1>Listado de Perfiles</h1>
  </div>

  <div class="card">
    <p-table id="tabla-puestos" #dt1 [value]="puestos" dataKey="id" [showCurrentPageReport]="true" styleClass="p-datatable-responsive-demo" [rows]="rows" [totalRecords]="resultadosTotales" [columns]="cols" [lazy]="true" (onLazyLoad)="nextPage($event, dt1)"
                [paginator]="true" [globalFilterFields]="cols" sortField="id" [sortOrder]="1" currentPageReportTemplate="Página {{paginaActual}} de {{paginasTotales}}" [rowsPerPageOptions]="resultadosPorPagina">
                <ng-template pTemplate="caption" class="caption">
                  <button class="btn customButton boton-excel" (click)="exportarDatos()"><i class="fa fa-file-excel-o" aria-hidden="true"></i>  EXPORTAR</button>
                </ng-template>
                <ng-template pTemplate="header">
                  <tr>
                    <th scope="{{cols[0].field}}" pSortableColumn="{{cols[0].field}}" style="width: 10% !important;">
                      <div class="p-d-flex p-jc-between p-ai-center">
                          {{cols[0].header}}
                          <p-sortIcon ariaLabel="Activar para ordenar" ariaLabelDesc="Activar para ordenar en orden descendiente" ariaLabelAsc="Activar para ordenar en orden ascendiente" field="{{cols[0].field}}"></p-sortIcon>
                      </div>
                  </th>
                  <th scope="{{cols[1].field}}" pSortableColumn="{{cols[1].field}}">
                      <div class="p-d-flex p-jc-between p-ai-center">
                          {{cols[1].header}}
                          <p-sortIcon ariaLabel="Activar para ordenar" ariaLabelDesc="Activar para ordenar en orden descendiente" ariaLabelAsc="Activar para ordenar en orden ascendiente" field="{{cols[1].field}}"></p-sortIcon>
                      </div>
                  </th>
                </tr>
              </ng-template>
      <ng-template pTemplate="body" let-puesto let-i="rowIndex">
        <tr>
          <td><span class="p-column-title">ID</span>{{puesto.id}} </td>
          <td><span class="p-column-title">DESCRIPCIÓN</span> {{puesto.descripcion}}</td>
        </tr>
        <hr class="item-list-separator">
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="7">No hay resultados</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="paginatorleft">
      </ng-template>
      <ng-template pTemplate="paginatorright">
        <p class="pageFooterLeft">Resultados totales: {{resultadosTotales}}</p>
      </ng-template>
    </p-table>
  </div>
</div>

import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { Catalogo } from 'src/app/modelos/catalogo';
import { CatalogoFiltrosRequest } from 'src/app/modelos/catalogo-filtros';
import { CatalogosService } from 'src/app/servicios/catalogos/catalogos.service';
import { ImpactosService } from 'src/app/servicios/impactos/impactos.service';
import { Constantes } from 'src/app/utils/constantes';
import { RouterUtils } from 'src/app/utils/RouterUtils';
@Component({
  selector: 'app-filtro-catalogo',
  templateUrl: './filtro-catalogo.component.html',
  styleUrls: ['./filtro-catalogo.component.scss'],
})
export class FiltroCatalogoComponent implements OnInit {
  @Output() buscar = new EventEmitter<any>();

  filters: FormGroup;

  private readonly TODOS = 3;

  peticionesList: Catalogo[] = [];

  destinosList = [];

  flujoList = ['Genérico', 'Automatismo', 'Autorización'];

  impactosList = [];

  selectOptions = [
    { id: 3, descripcion: 'Todos' },
    { id: 1, descripcion: 'Sí' },
    { id: 0, descripcion: 'No' },
  ];

  constructor(
    private routerUtils: RouterUtils,
    private impactoService: ImpactosService,
    public constantes: Constantes,
    private catalogoService: CatalogosService
  ) {
    const [todos] = this.selectOptions;
    this.filters = new FormGroup({
      tipoPeticion: new FormControl(),
      areaDestino: new FormControl(),
      tipoFlujo: new FormControl(),
      artica: new FormControl(todos.id),
      activo: new FormControl(todos.id),
      datosEspecificos: new FormControl(todos.id),
      datosEfectividad: new FormControl(todos.id),
      impacto: new FormControl(),
      fechaResolucion: new FormControl(todos.id),
      calcularSLA: new FormControl(todos.id),
      escalado: new FormControl(todos.id),
    });
  }
  ngOnInit(): void {

    forkJoin([this.impactoService.getImpactos(), this.catalogoService.obtenerTiposPeticiones()]).subscribe(resp => {
      this.impactosList = resp[0];
      this.peticionesList = resp[1];
    });

    if (this.routerUtils.getPreviousUrl().includes('/catalogos/')) {
      this.loadSaveFilters();
    } else {
      sessionStorage.removeItem('filters');
    }

    this.search();
  }

  loadSaveFilters(): void {
    const filterValues = sessionStorage.getItem('filters');

    if (filterValues) {
      const values: CatalogoFiltrosRequest = JSON.parse(filterValues);
      this.filters.patchValue(values);
    }
  }

  clean(): void {
    this.filters.reset();

    const [todos] = this.selectOptions;
    this.filters.get('artica')?.setValue(todos.id);
    this.filters.get('activo')?.setValue(todos.id);
    this.filters.get('datosEspecificos')?.setValue(todos.id);
    this.filters.get('datosEfectividad')?.setValue(todos.id);
    this.filters.get('fechaResolucion')?.setValue(todos.id);
    this.filters.get('calcularSLA')?.setValue(todos.id);
    this.filters.get('escalado')?.setValue(todos.id);
    this.search();
    sessionStorage.removeItem('filters');

  }

  search(): void {
    const filterValues: CatalogoFiltrosRequest = this.filters.getRawValue();
    sessionStorage.removeItem('filters');
    sessionStorage.setItem('filters', JSON.stringify(filterValues));
    this.formatSelectValues(filterValues);

    this.buscar.emit(filterValues);
  }

  private formatSelectValues(filterValues: CatalogoFiltrosRequest): void {
    filterValues.artica =
      filterValues.artica === this.TODOS ? null : filterValues.artica;
    filterValues.activo =
      filterValues.activo === this.TODOS ? null : filterValues.activo;
    filterValues.datosEspecificos =
      filterValues.datosEspecificos === this.TODOS
        ? null
        : filterValues.datosEspecificos;
    filterValues.datosEfectividad =
      filterValues.datosEfectividad === this.TODOS
        ? null
        : filterValues.datosEfectividad;
    filterValues.fechaResolucion =
      filterValues.fechaResolucion === this.TODOS
        ? null
        : filterValues.fechaResolucion;
    filterValues.calcularSLA =
      filterValues.calcularSLA === this.TODOS ? null : filterValues.calcularSLA;
    filterValues.escalado =
      filterValues.escalado === this.TODOS ? null : filterValues.escalado;
  }
}

import { Component, Injector, OnInit } from '@angular/core';
import { RolesService } from '../../servicios/rol/roles.service';
import { TerritoriosService } from '../../servicios/territorios/territorios.service';
import { Ambito } from '../../modelos/ambito';
import { NgxSpinnerService } from 'ngx-spinner';
import { forkJoin, Observable, of } from 'rxjs';
import { Usuario } from '../../modelos/usuario';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PuestosService } from 'src/app/servicios/puestos/puestos.service';
import { ActividadesService } from 'src/app/servicios/actividades/actividades.service';
import { Actividades } from 'src/app/modelos/actividades';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { SesionService } from 'src/app/servicios/sesion/sesion.service';
import { UsuariosService } from 'src/app/servicios/usuarios/usuarios.service';
import { ModalInfo } from 'src/app/modelos/moda-info';
import { ModalInfoComponent } from '../modals/modal-info/modal-info.component';
import { EmpresasService } from 'src/app/servicios/empresas/empresas.service';
import { Empresa } from 'src/app/modelos/empresa';
import { Region } from 'src/app/modelos/region';
import { Zona } from 'src/app/modelos/zona';
import { Provincia } from 'src/app/modelos/provincia';
import { TipoSolicitanteService } from '../../servicios/tipo-solicitante/tipo-solicitante.service';
import { LineasVenta } from 'src/app/modelos/lineas-venta';
import { LineasVentaService } from '../../servicios/lineas-venta/lineas-venta.service';
import { pairwise, startWith } from 'rxjs/operators';
import { InformesdatastudioService } from '../../servicios/informesdatastudio/informesdatastudio.service';
import { Rol } from 'src/app/modelos/rol';
import { Puesto } from 'src/app/modelos/puesto';
import { TipoSolicitante } from 'src/app/modelos/tipo-solicitante';

@Component({
  selector: 'app-altas',
  templateUrl: './altas.component.html',
  styleUrls: ['./altas.component.css']
})
export class AltasComponent implements OnInit {
  public listaEmpresas: Empresa[] = [];
  public listaZonas: Zona[] = [];
  public listaAmbitos: Ambito[] = [];
  public listaProvincias: Provincia[] = [];
  public cargasCombos: any[] = [];

  public formulario!: FormGroup;

  public usuario!: Usuario;
  public title = 'Registrar nuevo usuario';
  public patronemail!: string;

  public modoEdicion = false;
  public primeraCarga = true;
  public disabledAmbitos = true;
  public disabledProvincias = true;

  private usuariosService!: UsuariosService;
  private empresasService!: EmpresasService;
  private rolesService!: RolesService;
  private territoriosService!: TerritoriosService;
  private puestosService!: PuestosService;
  private actividadesService!: ActividadesService;
  private tipoSolicitanteService!: TipoSolicitanteService;
  private lineasVentaService!: LineasVentaService;
  private sesionService!: SesionService;
  private spinner!: NgxSpinnerService;
  private informesdatastudioService!: InformesdatastudioService;

  constructor(
    private fb: FormBuilder,
    private inject: Injector,
    private router: Router,
    private acRoute: ActivatedRoute,
    public dialog: MatDialog
  ) {
    this.loadInitialProperties();
  }

  loadInitialProperties(): void {
    this.usuariosService = this.inject.get<UsuariosService>(UsuariosService);
    this.empresasService = this.inject.get<EmpresasService>(EmpresasService);
    this.rolesService = this.inject.get<RolesService>(RolesService);
    this.territoriosService =
      this.inject.get<TerritoriosService>(TerritoriosService);
    this.puestosService = this.inject.get<PuestosService>(PuestosService);
    this.actividadesService =
      this.inject.get<ActividadesService>(ActividadesService);
    this.tipoSolicitanteService = this.inject.get<TipoSolicitanteService>(
      TipoSolicitanteService
    );
    this.lineasVentaService =
      this.inject.get<LineasVentaService>(LineasVentaService);
    this.spinner = this.inject.get<NgxSpinnerService>(NgxSpinnerService);
    this.informesdatastudioService = this.inject.get<InformesdatastudioService>(
      InformesdatastudioService
    );
  }

  ngOnInit(): void {
    this.usuario = this.initUsuario();
    this.initForm();
    this.initServices();
  }

  private initUsuario(): Usuario {
    return {
      email: '',
      nombre: '',
      apellido: '',
      dni: '',
      emailEditor: 'PeticionAltaNuevoUsuario@eulen.com',
      actividades: [] as Actividades[],
      provincias: [] as Provincia[],
      ambitos: [] as Ambito[],
      regiones: [] as Region[],
      zonas: [] as Zona[],
      lineasVentas: [] as LineasVenta[],
    };
  }

  initForm(): void {
    this.formulario = this.fb.group({
      email: [
        {
          value: this.usuario.email,
          disabled: false,
        },
        [Validators.required, Validators.pattern(this.patronemail)],
      ],
      nombre: [
        {
          value: this.usuario.nombre,
          disabled: false,
        },
        [Validators.required],
      ],
      apellido: [
        {
          value: this.usuario.apellido,
          disabled: false,
        },
        [Validators.required],
      ],
      dni: [
        { value: this.usuario.dni, disabled: false },
        [
          Validators.pattern('^([0-9]{8}[A-Z])|[XYZ][0-9]{7}[A-Z]$'),
          Validators.required,
        ],
      ],
      empresa: [
        {
          value: this.usuario.empresa,
          disabled: false,
        },
        [Validators.required],
      ],
      zonas: [
        {
          value: this.usuario.zonas,
          disabled: false,
        },
        [Validators.required],
      ],
      ambitos: [
        {
          value: this.usuario.ambitos,
          disabled: false,
        },
      ],
      provincias: [
        {
          value: this.usuario.provincias,
          disabled: false,
        },
      ],
    });
    if (this.formulario) {
      this.formulario.controls.empresa.valueChanges
        .pipe(startWith(null), pairwise())
        .subscribe(([oldValue, newValue]) => {
          this.handleEmpresaValues(oldValue, newValue);
        });
    }
  }

  private handleEmpresaValues(oldValue: any, newValue: any): void {
    console.log("Empresa change event -> oldvalue : " + oldValue + " newvalue : " + newValue);
    this.cambioEmpresa();
  }

  initServices(): void {
    this.spinner.show();
    this.cargasCombos.push(this.empresasService.getEmpresas());
    this.cargasCombos.push(this.territoriosService.getZonas());

    forkJoin(this.cargasCombos).subscribe(
      (resp: any) => {
        this.listaEmpresas = resp[0];
        this.listaZonas = resp[1];
        if (this.formulario) {
            // buscar ambitos
            this.formulario.controls.zonas.setValidators(Validators.required);
            this.callGetAmbitosByZona().subscribe(
              (respAmbitos) => {
                this.handleCallGetAmbitosByZona(respAmbitos);
              },
              (error) => {
                this.spinner.hide();
                console.error(error);
              }
            );
        }
      },
      (error) => {
        console.error(error);
        this.spinner.hide();
      }
    );
    this.spinner.hide();
  }

  private handleCallGetAmbitosByZona(respAmbitos: any[]): void {
    if (respAmbitos) {
      this.listaAmbitos = respAmbitos;
      this.disabledAmbitos = false;
      // buscar provincias
      this.callGetProvinciasByAmbito().subscribe(
        (respProvincias) => {
          this.listaProvincias = respProvincias;
          this.disabledProvincias = false;
          this.primeraCarga = false;
          this.spinner.hide();
        },
        (error) => {
          this.spinner.hide();
          console.log(error);
        }
      );
    }
  }

  cambioEmpresa(): void {
    this.spinner.show();
    if (this.formulario) {
      this.formulario.controls.provincias.setValue([]);
      this.formulario.controls.ambitos.setValue([]);
      this.disabledAmbitos = true;
      this.disabledProvincias = true;
      this.formulario.controls.zonas.setValue([]);
      this.formulario.controls.zonas.setValidators(Validators.required);
      this.formulario.updateValueAndValidity();
      this.territoriosService.getZonas().subscribe(
        (zonas) => {
          this.listaZonas = zonas as Zona[];
          this.formulario.updateValueAndValidity();
          this.spinner.hide();
        },
        () => {
          this.formulario.updateValueAndValidity();
          this.spinner.hide();
        }
      );
    }
  }

  private callGetAmbitosByZona(): Observable<any> {
    const zonas = this.getIdZonasSelected();
    return zonas.length === 0
      ? of(undefined)
      : this.territoriosService.getAmbitosZona(zonas);
  }

  private getIdZonasSelected(): number[] {
    const idsZonas: number[] = [];
    const zonas = this.formulario.get('zonas')?.value as Zona[];
    for (const zona of zonas) {
      idsZonas.push(zona.id);
    }
    return idsZonas;
  }

  cambioZona(): void {
    if (this.formulario) {
      this.spinner.show();
      this.callGetAmbitosByZona().subscribe(
        (ambitos) => {
          if (ambitos) {
            this.listaAmbitos = ambitos;
            this.disabledAmbitos = false;
            this.removeAmbitosZona();
            this.cambioAmbito();
          } else {
            this.listaAmbitos = [];
            this.disabledAmbitos = true;
            this.formulario.controls.ambitos.setValue([]);
            this.formulario.controls.provincias.setValue([]);
          }
          this.spinner.hide();
        },
        (error) => {
          this.spinner.hide();
          console.error(error);
        }
      );
    }
  }

  private removeProvincias(): void {
    if (this.formulario) {
      const provinciasOld = this.formulario.get('provincias')
        ?.value as Provincia[];
      const provinciasNew = [] as Provincia[];
      provinciasOld.forEach((provincia) => {
        const id = provincia.id;
        this.listaProvincias.forEach((item) => {
          if (item.id === id) {
            provinciasNew.push(item);
          }
        });
      });
      this.formulario.controls.provincias.setValue(provinciasNew);
    }
  }

  private removeAmbitosZona(): void {
    if (this.formulario) {
      const ambitosOld = this.formulario.get('ambitos')?.value as Ambito[];
      const ambitosNew = [] as Ambito[];
      ambitosOld.forEach((ambito) => {
        const id = ambito.id;
        this.listaAmbitos.forEach((item) => {
          if (item.id === id) {
            ambitosNew.push(item);
          }
        });
      });
      this.formulario.controls.ambitos.setValue(ambitosNew);
    }
  }

  private callGetProvinciasByAmbito(): Observable<any> {
    const ambitos = this.getAmbitosSelected();
    return ambitos.length === 0
      ? of(undefined)
      : this.territoriosService.getProvinciasAmbito(ambitos);
  }

  private getAmbitosSelected(): number[] {
    const idAbmbitos: number[] = [];
    const ambitos = this.formulario.get('ambitos')?.value as Ambito[];
    for (const value of ambitos) {
      this.listaAmbitos.forEach((ambito) => {
        if (ambito.id === value.id) {
          idAbmbitos.push(value.id);
        }
      });
    }
    return idAbmbitos;
  }

  cambioAmbito(): void {
    if (this.formulario) {
      this.spinner.show();
      this.callGetProvinciasByAmbito().subscribe(
        (res) => {
          if (res) {
            this.listaProvincias = res;
            this.disabledProvincias = false;
            this.spinner.hide();
            console.log(this.formulario);
            this.removeProvincias();
          } else {
            this.listaProvincias = [];
            this.disabledProvincias = true;
            this.formulario.controls.provincias.setValue([]);
          }
          this.spinner.hide();
        },
        (error) => {
          console.error(error);
          this.spinner.hide();
        }
      );
    }
  }

  crearUsuario(): Usuario {
    return {
      email: this.formulario.get('email')?.value,
      nombre: this.formulario.get('nombre')?.value,
      apellido: this.formulario.get('apellido')?.value,
      dni: this.formulario.get('dni')?.value,
      empresa: this.formulario.get('empresa')?.value,
      emailEditor: 'PeticionAltaNuevoUsuario@eulen.com',
      rol: {id: 5, descripcion: "SOLICITANTE"} as Rol,
      puesto: {id: 35, descripcion: "SIN DEFINIR"} as Puesto,
      provincias: this.formulario.get('provincias')?.value,
      regiones: undefined,
      zonas: this.formulario.get('zonas')?.value,
      ambitos: this.formulario.get('ambitos')?.value,
      actividades: undefined,
      tipoSolicitante: {id: 3, descripcion: "SIN DEFINIR"} as TipoSolicitante,
      lineasVentas: undefined,
      informeDatastudio: undefined,
    };
  }

  callCrearUsuario(): void {
    this.formulario.markAllAsTouched();
    if (this.formulario.valid) {
      const usuario = this.crearUsuario();
      console.log("User : ", usuario);
      this.spinner.show();
      this.usuariosService.validaUsuario(usuario.email).subscribe(
        () => {
          this.usuariosService.peticionAltaUsuario(usuario).subscribe(() => {
            this.spinner.hide();
            this.modalOperacionOK();
          });
        },
        (errorValUsuario: any) => {
          this.spinner.hide();
          const customError = {
            title: 'Error',
            msg: '',
          } as ModalInfo;
          switch (errorValUsuario.status) {
            case 400:
              customError.msg =
                "El email del usuario no cumple con el formato esperado: 'usuario@eulen.com'";
              break;
            case 409:
              if (errorValUsuario.error === '1') {
                customError.msg =
                  'El usuario existe en el sistema dado de baja. ¿Desea conservar la configuración que ya tenía o aplicar la nueva seleccionada?';
                customError.buttons = [
                  { title: 'Conservar', class: 'btn-warning', action: '1' },
                  { title: 'Crear Nueva', class: 'btn-primary', action: '2' },
                ];
              } else {
                customError.msg =
                  'No se puede realizar la acción. El email introducido ya existe en la Base de Datos.';
                customError.buttons = [
                  { title: 'Aceptar', class: 'btn-primary' },
                ];
              }
              break;
            default:
              customError.msg =
                'Ha ocurrido un error inesperado. Por favor, inténtelo más tarde.';
          }
          this.dialog
            .open(ModalInfoComponent, {
              disableClose: true,
              autoFocus: false,
              data: customError,
            });
        }
      );
    }
  }

  modalOperacionOK(): void {
    const data = {
      title: 'Información',
      msg: 'Peticion de Alta de Usuario enviada a los Administradores. Espere hasta ser notificado de la activacion de su usuario.',
    };
    const dialog = this.dialog.open(ModalInfoComponent, {
      disableClose: true,
      autoFocus: false,
      data,
    });
    dialog.afterClosed().subscribe((res) => {
      this.router.navigate(['/']);
    });
  }

  redirect(): void {
    this.router.navigate(['/']);
  }

}

import { Component, OnInit, ViewChild } from '@angular/core';
import { version } from 'package.json';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Constantes } from 'src/app/utils/constantes';
import { LoginService } from '../../servicios/login/login.service';
import { SesionService } from '../../servicios/sesion/sesion.service';
import { ModalError } from '../../modelos/modal-error';
import { MensajeComponent } from '../mensaje/mensaje.component';
import { MessageService } from 'primeng/api';
import { environment } from 'src/environments/environment';
import {
  GoogleSignInResponse,
  GoogleCredentials,
} from 'src/app/modelos/google-login';

declare let $: any;
declare let window: any;

@Component({
  selector: 'app-cabecera',
  templateUrl: './cabecera.component.html',
  styleUrls: ['./cabecera.component.css'],
})
export class CabeceraComponent implements OnInit {
  public version: string = version;
  public modalError: ModalError;
  public usuarioNombre: string;
  @ViewChild('mensaje', { static: false })
  public mensaje!: MensajeComponent;

  tokenClient: any;
  accessToken: any;

  titulo = environment.TITULO_MENU;
  estiloTitulo =
    environment.CLASES_TIPO_MENU === 'test' ? 'cabecera-pruebas' : '';

  constructor(
    private loginService: LoginService,
    private router: Router,
    public constantes: Constantes,
    public sesionService: SesionService,
    private spinner: NgxSpinnerService,
    private messageService: MessageService
  ) {
    this.modalError = {
      title: '',
      mensaje: '',
      inicio: false,
      code: 1,
    };
    this.usuarioNombre = '';
  }

  ngOnInit(): void {
    const usuario = this.sesionService.getUser();
    if (usuario != null) {
      this.usuarioNombre = usuario.email.toLowerCase().split('@')[0];
    }
    this.initNEW();
  }

  public signOut(): void {
    this.loginService.signOutLS(false);
    this.usuarioNombre = '';
    $('#sidebar').addClass('active');
    this.router.navigate(['/']);
    console.log('!isLoggedIn() || sesionService.getSesionCaducada()===true');
    console.log(
      !this.isLoggedIn() || this.sesionService.getSesionCaducada() === true
    );
    console.log(document.getElementById('buttonDiv'));
    window.location.reload();
  }

  public newUser(): void {
    this.router.navigate(['/altas']);
  }

  public onReject(): void {
    this.messageService.clear('error');
    this.router.navigate(['/']);
  }

  public isLoggedIn(): boolean {
    return this.sesionService.isGoogleUserSignedIn();
  }

  public getNameUser(): void {
    const googleCredentials: GoogleCredentials =
      this.sesionService.getGoogleCredentials();
    if (googleCredentials.email) {
      this.usuarioNombre = googleCredentials.email
        .toLocaleLowerCase()
        .split('@')[0];
    }
  }

  public mostrarMenu(): void {
    if (this.sesionService.isGoogleUserSignedIn()) {
      $('#sidebar').toggleClass('active');
    }
  }

  initNEW(): void {
    window.onload = () => {
      window.google.accounts.id.initialize({
        client_id: environment.CLIENT_ID,
        callback: (response: GoogleSignInResponse) => {
          this.handleCredentialResponse(response);
        },
      });
      this.googleRenderLoginButton();
    };
  }

  public googleRenderLoginButton(): void {
    window.google.accounts.id.renderButton(
      document.getElementById('buttonDiv'),
      { theme: 'outline', size: 'large' }
    );
  }

  public handleCredentialResponse(response: GoogleSignInResponse): void {
    this.spinner.show();
    this.loginService.signInNEW(response);
    this.getNameUser();
    this.spinner.hide();
    $('#sidebar').addClass('active');
  }
}

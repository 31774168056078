import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Constantes } from 'src/app/utils/constantes';

@Injectable({
  providedIn: 'root',
})
export class AccionesService {
  constructor(private http: HttpClient, private constantes: Constantes) {}

  public getAccionesCombo(): Observable<any> {
    const url: string = this.constantes.ENDPOINT_API_ACCIONES_COMBO;
    return this.http.get(url);
  }

  public getAccionesRoles(offset: number, limit: number): Observable<any> {
    const params = new HttpParams()
      .set('offset', String(offset))
      .append('limit', String(limit));
    return this.http.get(this.constantes.ENDPOINT_API_ACCIONES_ROLES, {
      params,
    });
  }

  exportar(): Observable<any> {
    return this.http.get(
      this.constantes.ENDPOINT_API_EXPORTAR + '/exportar-acciones'
    );
  }
}

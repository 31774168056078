<app-mensaje #mensaje (customAceptar)="aceptar()"> </app-mensaje>
<div class="d-flex justify-content-end mb-3">
  <button
    class="btn btn-outline-primary customButton fa fa-plus-circle"
    (click)="redirectToNotificacion()"
  >
    Crear Notificación
  </button>
</div>
<p-table
  id="tabla-notificacion"
  #dt1
  [value]="notificaciones"
  dataKey="email"
  [showCurrentPageReport]="true"
  styleClass="p-datatable-responsive-demo"
  [rows]="rows"
  [totalRecords]="notificacionesTotales"
  [columns]="cols"
  [lazy]="true"
  (onLazyLoad)="nextPage($event, dt1)"
  [paginator]="true"
  [globalFilterFields]="cols"
  sortField="tipoPeticion"
  [sortOrder]="1"
  currentPageReportTemplate="Página {{ paginaActual }} de {{ paginasTotales }}"
  [rowsPerPageOptions]="notificacionesPorPagina"
  [(first)]="first"
>
  <ng-template pTemplate="caption" class="caption">
    <button class="btn customButton boton-excel" (click)="exportarDatos()">
      <i class="fa fa-file-excel-o" aria-hidden="true"></i> EXPORTAR
    </button>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th
        scope="{{ cols[0].field }}"
        pSortableColumn="{{ cols[0].field }}"
        style="width: 30% !important"
      >
        <div class="p-d-flex p-jc-between p-ai-center">
          {{ cols[0].header }}
          <p-sortIcon
            ariaLabel="Activar para ordenar"
            ariaLabelDesc="Activar para ordenar en orden descendiente"
            ariaLabelAsc="Activar para ordenar en orden ascendiente"
            field="{{ cols[0].field }}"
          ></p-sortIcon>
        </div>
      </th>
      <th
        scope="{{ cols[1].field }}"
        pSortableColumn="{{ cols[1].field }}"
        style="width: 30% !important"
      >
        <div class="p-d-flex p-jc-between p-ai-center">
          {{ cols[1].header }}
          <p-sortIcon
            ariaLabel="Activar para ordenar"
            ariaLabelDesc="Activar para ordenar en orden descendiente"
            ariaLabelAsc="Activar para ordenar en orden ascendiente"
            field="{{ cols[1].field }}"
          ></p-sortIcon>
        </div>
      </th>
      <th scope="{{ cols[2].field }}" pSortableColumn="{{ cols[2].field }}">
        <div class="p-d-flex p-jc-between p-ai-center">
          {{ cols[2].header }}
          <p-sortIcon
            ariaLabel="Activar para ordenar"
            ariaLabelDesc="Activar para ordenar en orden descendiente"
            ariaLabelAsc="Activar para ordenar en orden ascendiente"
            field="{{ cols[2].field }}"
          ></p-sortIcon>
        </div>
      </th>
      <th scope="{{ cols[3].field }}" pSortableColumn="{{ cols[3].field }}">
        <div class="p-d-flex p-jc-between p-ai-center">
          {{ cols[3].header }}
          <p-sortIcon
            ariaLabel="Activar para ordenar"
            ariaLabelDesc="Activar para ordenar en orden descendiente"
            ariaLabelAsc="Activar para ordenar en orden ascendiente"
            field="{{ cols[3].field }}"
          ></p-sortIcon>
        </div>
      </th>
      <th scope="{{ cols[4].field }}" style="width: 10% !important">
        <div
          class="p-d-flex p-jc-between p-ai-center"
          pTooltip="{{ cols[4].toolTip }}"
          tooltipPosition="top"
        >
          {{ cols[4].header }}
        </div>
      </th>
      <th scope="{{ cols[5].field }}" style="width: 10% !important">
        <div
          class="p-d-flex p-jc-between p-ai-center"
          pTooltip="{{ cols[5].toolTip }}"
          tooltipPosition="top"
        >
          {{ cols[5].header }}
        </div>
      </th>
      <th scope="{{ cols[6].field }}" style="width: 10% !important">
        <div
          class="p-d-flex p-jc-between p-ai-center"
          pTooltip="{{ cols[6].toolTip }}"
          tooltipPosition="top"
        >
          {{ cols[6].header }}
        </div>
      </th>
      <th scope="{{ cols[7].field }}" style="width: 10% !important">
        <div
          class="p-d-flex p-jc-between p-ai-center"
          pTooltip="{{ cols[7].toolTip }}"
          tooltipPosition="top"
        >
          {{ cols[7].header }}
        </div>
      </th>
      <th scope="{{ cols[8].field }}" style="width: 10% !important">
        <div
          class="p-d-flex p-jc-between p-ai-center"
          pTooltip="{{ cols[8].toolTip }}"
          tooltipPosition="top"
        >
          {{ cols[8].header }}
        </div>
      </th>
      <th scope="acciones" style="width: 30% !important">
        <div class="p-d-flex p-jc-between p-ai-center">
          {{ cols[9].header }}
        </div>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-notificacion let-i="rowIndex">
    <tr>
      <td>
        <span class="p-column-title">ID TIPO PETICIÓN</span
        >{{ notificacion.tipoPeticion }}
      </td>
      <td>
        <span class="p-column-title">TIPO PETICIÓN</span>
        {{ notificacion.descPeticion }}
      </td>
      <td>
        <span class="p-column-title">ACCIÓN</span> {{ notificacion.accion }}
      </td>
      <td>
        <span class="p-column-title">PLANTILLA</span
        >{{ notificacion.plantilla }}
      </td>
      <td class="simbolos" *ngIf="notificacion.trabajador === 1">
        <span class="p-column-title">TRABAJADOR</span>
        <i class="fa fa-check" aria-hidden="true"></i>
      </td>
      <td class="simbolos" *ngIf="notificacion.trabajador === 0">
        <span class="p-column-title">TRABAJADOR</span>
        <i class="fa fa-times" aria-hidden="true"></i>
      </td>
      <td class="simbolos" *ngIf="notificacion.autorizador === 1">
        <span class="p-column-title">AUTORIZADOR</span>
        <i class="fa fa-check" aria-hidden="true"></i>
      </td>
      <td class="simbolos" *ngIf="notificacion.autorizador === 0">
        <span class="p-column-title">AUTORIZADOR</span>
        <i class="fa fa-times" aria-hidden="true"></i>
      </td>
      <td class="simbolos" *ngIf="notificacion.informantes === 1">
        <span class="p-column-title">INFORMANTES</span>
        <i class="fa fa-check" aria-hidden="true"></i>
      </td>
      <td class="simbolos" *ngIf="notificacion.informantes === 0">
        <span class="p-column-title">INFORMANTES</span>
        <i class="fa fa-times" aria-hidden="true"></i>
      </td>
      <td class="simbolos" *ngIf="notificacion.solicitante === 1">
        <span class="p-column-title">SOLICITANTE</span>
        <i class="fa fa-check" aria-hidden="true"></i>
      </td>
      <td class="simbolos" *ngIf="notificacion.solicitante === 0">
        <span class="p-column-title">SOLICITANTE</span>
        <i class="fa fa-times" aria-hidden="true"></i>
      </td>
      <td class="simbolos" *ngIf="notificacion.destinatario === 1">
        <span class="p-column-title">DESTINATARIO</span>
        <i class="fa fa-check" aria-hidden="true"></i>
      </td>
      <td class="simbolos" *ngIf="notificacion.destinatario === 0">
        <span class="p-column-title">DESTINATARIO</span>
        <i class="fa fa-times" aria-hidden="true"></i>
      </td>
      <td class="acciones text-center">
        <button
          pTooltip="Editar"
          tooltipPosition="right"
          class="btn btn-sm btn-outline-info"
          (click)="
            redirectToNotificacion(
              notificacion.tipoPeticion,
              notificacion.codAccion
            )
          "
        >
          <em class="fa fa-pencil"></em>
        </button>
        <button
          *ngIf="!notificacion.idBaja"
          pTooltip="Desactivar"
          tooltipPosition="right"
          class="btn btn-sm btn-outline-danger"
          (click)="showModal(notificacion)"
        >
          <em class="fa fa-power-off"></em>
        </button>

        <button
          *ngIf="notificacion.idBaja"
          pTooltip="Activar"
          tooltipPosition="right"
          class="btn btn-sm btn-outline-success"
          (click)="showModal(notificacion)"
        >
          <em class="fa fa-reply"></em>
        </button>
      </td>
    </tr>
    <hr class="item-list-separator" />
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="12">No hay notificaciones</td>
    </tr>
  </ng-template>
  <ng-template pTemplate="paginatorleft"> </ng-template>
  <ng-template pTemplate="paginatorright">
    <p class="pageFooterLeft">
      Notificaciones totales: {{ notificacionesTotales }}
    </p>
  </ng-template>
</p-table>

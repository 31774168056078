import { Component, Input, ViewChild } from '@angular/core';
import { Aclaracion } from '../../../modelos/aclaracion';
import { PeticionService } from '../../../servicios/peticion/peticion.service';
import { NgxSpinnerService } from 'ngx-spinner';
import Utils from '../../../utils/utils';
import { MensajeComponent } from '../../mensaje/mensaje.component';

@Component({
  selector: 'app-lista-aclaraciones',
  templateUrl: './lista-aclaraciones.component.html',
  styleUrls: ['./lista-aclaraciones.component.css'],
})
export class ListaAclaracionesComponent {
  constructor(
    private peticionService: PeticionService,
    private spinner: NgxSpinnerService
  ) {}

  @Input()
  aclaraciones: Aclaracion[] = [];

  @ViewChild('mensaje', { static: false })
  public mensaje!: MensajeComponent;

  public descargarFicheroAclaracion(
    aclaracion: Aclaracion,
    ifichero: number
  ): void {
    if (aclaracion.peticion.idPeticion && aclaracion.id && aclaracion.fichero) {
      this.spinner.show();
      this.peticionService
        .descargarFicheroAclaracion(
          aclaracion.peticion.idPeticion,
          aclaracion.id,
          aclaracion.fichero[ifichero].idFichero
        )
        .subscribe(
          (fileReceived: any) => {
            this.spinner.hide();
            let nombre;
            if (aclaracion.fichero) {
              nombre = aclaracion.fichero[ifichero].nombre;
            }
            Utils.base64ToFile(fileReceived.file, nombre || '');
          },
          (errorDescarga: any) => {
            this.mensaje.showErrorPeticion(
              'No se ha podido descargar el fichero ' + errorDescarga.error
            );
            this.spinner.hide();
          }
        );
    }
  }
}

<div class="contenedorModal" *ngIf="verModal">
  <app-mensaje #mensaje> </app-mensaje>
  <ngx-spinner></ngx-spinner>
  <ngx-spinner
    name="googleload"
    template="<img src='assets/googleload.gif'/>"
    bdColor="rgba(239,239,239,255)"
  ></ngx-spinner>
  <p-toast
    position="center"
    key="accion"
    (onClose)="cerrarModal()"
    [baseZIndex]="10000"
  >
    <ng-template let-ModalAccion pTemplate="modalAccion">
      <div class="p-flex p-flex-column" style="flex: 1; padding: 1rem">
        <div style="text-align: center">
          <em class="pi pi-question-circle" style="font-size: 3em"></em>
          <h3>{{ modalAccion.titulo }}</h3>
        </div>
        <p
          class="descripcion-accion"
          [ngClass]="{ 'mr-4': modalAccion.listaNotificados.length === 0 }"
        >
          {{ modalAccion.mensaje }}
        </p>
        <form [formGroup]="formulario" *ngIf="formulario">
          <div
            class="form-group row"
            *ngIf="idAccion !== 13 && idAccion !== 14 && idAccion !== 7"
          >
            <p-checkbox
              class="col-12 font-weight-bold"
              formControlName="seleccionarTodos"
              [binary]="true"
              label="Seleccionar Todos"
              [value]="seleccionarTodos"
              (onChange)="cambioSeleccionarTodos()"
              label="Seleccionar/quitar todo"
            ></p-checkbox>
          </div>
          <div class="form-group row">
            <ng-container *ngIf="idAccion !== 5">
              <ng-container
                *ngIf="
                  modalAccion.listaNotificados.length > 0;
                  else noNotificados
                "
              >
                <div
                  class="col-12"
                  *ngFor="let notificado of modalAccion.listaNotificados"
                >
                  <p-checkbox
                    (onChange)="controlNotificados()"
                    formControlName="notificados"
                    value="notificado.email"
                    [value]="notificado.email"
                    [(ngModel)]="listaTotal"
                    label="{{ notificado.nombrePerfil }} - {{
                      notificado.email
                    }}"
                  ></p-checkbox>
                </div>
              </ng-container>
              <ng-template #noNotificados>
                <p
                  *ngIf="idAccion !== 7"
                  class="descripcion-accion ml-3 text-danger msg-no-notificados"
                >
                  No se ha configurado una notificación para el tipo de petición
                  y la acción seleccionada.
                </p>
              </ng-template>
            </ng-container>
          </div>

          <div
            class="form-group row"
            *ngIf="modalAccion.listaNotificados.length > 0"
          >
            <div *ngIf="divotros" id="divotros" class="col">
              <label for="otros" class="col-12 col-sm-2 col-form-label"
                >Otros:
              </label>
              <div class="col-10" id="autocomp">
                <p-autoComplete
                  [(ngModel)]="listaOtros"
                  formControlName="otros"
                  [suggestions]="listaEmailsFiltered"
                  [minLength]="3"
                  [showEmptyMessage]="true"
                  [emptyMessage]="messageOtros"
                  (completeMethod)="filterOtros($event)"
                  [multiple]="true"
                >
                  <ng-template let-listaOtros pTemplate="selectedItem">
                    <span style="font-size: 14px">{{
                      listaOtros | stringFormatPipeName
                    }}</span>
                  </ng-template>
                </p-autoComplete>
              </div>
              <strong class="form-text">Número de emails máximos: 3.</strong>
            </div>
          </div>
          <div
            class="form-group"
            style="margin-top: 3rem"
            *ngIf="modalAccion.titulo == 'Cambiar destinatario'"
          >
            <div><strong class="form-text">Motivo del cambio</strong></div>
            <textarea
              id="motivo_cambio"
              class="form-control"
              formControlName="motivo_cambio"
              rows="3"
              trim="blur"
              maxlength="3000"
              (keypress)="onKeyPress($event)"
              (keyup)="onKeyUp($event)"
              (blur)="controlForm()"
              required
            ></textarea>
            <div class="input-group">
              <span class="col col-lg-8"
                >Máximo: 3000. Total: {{ contador }}
              </span>
            </div>
          </div>
          <div class="row">
            <div class="col botones-modal">
              <button
                style="float: right; margin-left: 20px"
                type="submit"
                (click)="aceptarModal()"
                class="btn btn-success success"
              >
                Aceptar
              </button>
              <button
                style="float: right"
                type="button"
                (click)="cerrarModal()"
                class="btn btn-danger danger"
              >
                Cancelar
              </button>
            </div>
          </div>
        </form>
      </div>
    </ng-template>
  </p-toast>
</div>

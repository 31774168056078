<app-mensaje #mensaje> </app-mensaje>
<ngx-spinner> </ngx-spinner>
<app-crear-peticion [activeIndex]="activeIndex"> </app-crear-peticion>
<app-modal-artica
  id="modal-artica"
  #modalArtica
  (emitirArtica)="articaRecibida($event)"
></app-modal-artica>
<div class="container">
  <h2>Documentos de la petición<span *ngIf="documentoObligatorio === 1" class="text-danger">*</span></h2>
  <p>
    <span style="color: red">Nota: </span>El número máximo de ficheros por
    petición son 5, y el tamaño máximo por fichero es 15MB.
  </p>
  <p-toast></p-toast>
  <div id="guardar">
    <button
      *ngIf="fileInput.files.length > 0"
      pTooltip="Guardar Archivos"
      (pointerdown)="fijarElemento($event)"
      tooltipPosition="right"
      class="btn customButton"
      [disabled]="!peticionService.esModificable()"
      (click)="subirFicheros()"
    >
      <em class="fa fa-upload"></em> Subir adjuntos
    </button>
  </div>
  <p-fileUpload
    #fileInput
    name="uploadedFiles"
    [disabled]="
      uploadedFiles.length + fileInput.files.length >= 5 ||
      !peticionService.esModificable()
    "
    [multiple]="true"
    [fileLimit]="5"
    [maxFileSize]="15728640"
    accept="image/*,.xls,.xlsx,.doc,.docx,.pdf"
    chooseLabel="Buscar"
    cancelLabel="Borrar"
    uploadLabel="Guardar"
    [showUploadButton]="false"
    [showCancelButton]="false"
    invalidFileLimitMessageSummary="Número máximo de ficheros excedidos: "
    invalidFileLimitMessageDetail="El límite es 5."
    invalidFileSizeMessageSummary="Tamaño máximo por fichero: "
    invalidFileSizeMessageDetail="El máximo es 15 MB por documento."
    class="customButton"
  >
  </p-fileUpload>

  <div class="espacio" *ngIf="uploadedFiles.length > 0">
    <p-table [value]="uploadedFiles">
      <ng-template pTemplate="header">
        <tr>
          <th id="nombre">Nombre</th>
          <th id="acciones" style="width: 20%">Acciones</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-file>
        <tr>
          <td>{{ file.nombre }}</td>
          <td class="acciones d-flex justify-content-center">
            <div class="row">
              <button
                pTooltip="Descargar"
                tooltipPosition="right"
                class="btn btn-sm customButton mx-1"
                (pointerdown)="fijarElemento($event)"
                (click)="descargarFichero(file)"
              >
                <em class="fa fa-download"></em>
              </button>

              <button *ngIf="!file.nombre.includes('.xls')"
                pTooltip="Visualizar"
                tooltipPosition="right"
                class="btn btn-sm btn-outline-info mx-1"
                (pointerdown)="fijarElemento($event)"
                (click)="visualizarFichero(file)"
              >
                <em class="fa fa-file"></em>
              </button>

              <button
                *ngIf="estado === 1"
                pTooltip="Eliminar"
                tooltipPosition="right"
                class="btn btn-sm btn-outline-danger mx-1"
                (pointerdown)="fijarElemento($event)"
                (click)="eliminarFichero(file.idPeticion, file.idFichero)"
              >
                <em class="fa fa-trash"></em>
              </button>
            </div>
          </td>
        </tr>
        <hr class="item-list-separator" />
      </ng-template>
    </p-table>
  </div>

  <p *ngIf="documentoObligatorio === 1 && uploadedFiles.length === 0">
    <span class="text-danger">*</span>
    <span style="color: red">Es obligatorio la subida de ficheros</span>
  </p>

  <button
    *ngIf="
      peticion &&
      peticion.subirArtica === 1 &&
      peticion.estado?.id !== 1 &&
      !esAuditor
    "
    id="anterior"
    type="submit"
    class="btn customButton btn-artica"
    [ngClass]="{
      red: peticion.fechaArtica === null || peticion.fechaArtica === undefined
    }"
    (click)="mostrarModalArtica()"
  >
    Subir Documento a Ártica
  </button>
  <div class="botones espacio">
    <button
      id="siguiente"
      style="float: right"
      type="submit"
      class="btn customButton"
      (click)="nextPage()"
      [disabled]="documentoObligatorio === 1 && uploadedFiles.length === 0"
    >
      Siguiente
    </button>
    <button
      id="anterior"
      type="submit"
      class="btn customButton"
      (click)="previousPage()"
    >
      Anterior
    </button>
  </div>
</div>

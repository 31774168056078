import {
  Component,
  OnInit,
  ViewChild,
  Input,
  ViewChildren,
  Injector,
} from '@angular/core';
import {
  PeticionSimple,
  PeticionFiltos,
  PeticionFiltosRequest,
  PeticionExportFiltro,
} from '../../../modelos/peticion';
import { NgxSpinnerService } from 'ngx-spinner';
import { Constantes } from '../../../utils/constantes';
import { Table } from 'primeng/table';
import { SesionService } from '../../../servicios/sesion/sesion.service';
import { PeticionService } from '../../../servicios/peticion/peticion.service';
import Utils from '../../../utils/utils';
import { Router } from '@angular/router';
import { MensajeComponent } from '../../mensaje/mensaje.component';
import { ButtonModalInfo, ModalInfo } from 'src/app/modelos/moda-info';
import { ModalInfoComponent } from '../../modals/modal-info/modal-info.component';
import { MatDialog } from '@angular/material/dialog';
import { Catalogo } from 'src/app/modelos/catalogo';
import { Estado } from 'src/app/modelos/estado';
import { Urgencia } from 'src/app/modelos/urgencia';
import { LineasVenta } from 'src/app/modelos/lineas-venta';
import { Actividades } from 'src/app/modelos/actividades';
import { ModalArticaComponent } from '../../modals/modal-artica/modal-artica.component';
import { Usuario } from 'src/app/modelos/usuario';

declare let $: any;

@Component({
  selector: 'app-tabla-peticiones',
  templateUrl: './tabla-peticiones.component.html',
  styleUrls: ['./tabla-peticiones.component.scss'],
})
export class TablaPeticionesComponent implements OnInit {
  private sessionService!: SesionService;
  usuario!: Usuario;
  peticiones: PeticionSimple[] = [];
  peticionesPorPagina: number[] = [];
  first = 0;
  rows = 25;
  paginasTotales = 0;
  paginaActual = 1;
  peticionesTotales = 0;
  cols: any[] = [];
  lastSortField!: any;
  peticionReset: any = undefined;

  @ViewChild('mensaje', { static: false })
  public mensaje!: MensajeComponent;

  @ViewChildren('dt1')
  public table!: Table;

  @ViewChild('modalArtica', { static: false })
  public modalArtica!: ModalArticaComponent;

  // tslint:disable-next-line:variable-name
  private _datosFiltro!: PeticionFiltos;

  loading = true;

  @Input()
  set datosFiltro(param: PeticionFiltos) {
    this._datosFiltro = param;
    this.first = 1;
    if (param) {
      this.getPeticiones(this.first);
    }
  }

  get datosFiltro(): PeticionFiltos {
    return this._datosFiltro;
  }

  // tslint:disable-next-line:variable-name
  private _isLoading!: boolean;

  @Input()
  set isLoading(param: boolean) {
    this._isLoading = param;
    if (!this.isLoading) {
      this.loading = false;
      this.spinner.hide();
    }
  }

  get isLoading(): boolean {
    return this._isLoading;
  }

  constructor(
    private peticionService: PeticionService,
    private sesionService: SesionService,
    private spinner: NgxSpinnerService,
    private inject: Injector,
    public constantes: Constantes,
    public dialog: MatDialog,
    private router: Router
  ) {
    this.sessionService = this.inject.get<SesionService>(SesionService);
  }

  ngOnInit(): void {
    this.initFields();
    if (this.peticionService.getDatosFiltrosPeticion()) {
      this.datosFiltro = this.peticionService.getDatosFiltrosPeticion();
      if (this._datosFiltro) {
        if (this._datosFiltro.recordsPerPage) {
          this.rows = this._datosFiltro.recordsPerPage;
        }
        if (this._datosFiltro.currentPage) {
          this.first = this._datosFiltro.currentPage;
          this.paginaActual = this.first;
          if (this.table) {
            this.table.first = (this.paginaActual - 1) * this.rows;
          }
        }
      }
    }
    this.getPeticiones(this.first);
    this.peticionService.setControlActuaciones(false);
    this.usuario = this.sessionService.getUser();
  }

  private initFields(): any {
    this.cols = [
      {
        field: 'codigo',
        header: 'NÚMERO DE PETICIÓN',
        style: 'max-width: 30% !important',
      },
      {
        field: 'tipo',
        header: 'TIPO DE PETICIÓN',
        style: 'max-width: 40% !important',
      },
      { field: 'estado', header: 'ESTADO', style: 'max-width: 20% !important' },
      {
        field: 'destinatario',
        header: 'DESTINATARIO',
        style: 'max-width: 25% !important',
      },
      {
        field: 'linea',
        header: 'LÍNEA DE VENTA',
        style: 'max-width: 30% !important',
      },
      {
        field: 'area_destino',
        header: 'ÁREA DE DESTINO',
        style: 'max-width: 20% !important',
      },
      { field: 'acciones', header: 'ACCIONES' },
    ];
    this.peticionesPorPagina = this.constantes.numeroUsuariosPorPagina;
    this._datosFiltro = {
      peticionario: this.sesionService.getUser().email,
      anio: new Date().getFullYear(),
      ocultarPeticiones: 1,
    };
  }

  private updateFiltroTipos(): string[] {
    return this._datosFiltro && this._datosFiltro.tipos
      ? this._datosFiltro.tipos.map((catalogo: Catalogo) => catalogo.id || '')
      : [];
  }

  private updateFiltroEstados(): number[] {
    return this._datosFiltro && this._datosFiltro.estados
      ? this._datosFiltro.estados.map((estado: Estado) => estado.id)
      : [];
  }

  private updateFiltroUrgencias(): number[] {
    return this._datosFiltro && this._datosFiltro.urgencias
      ? this._datosFiltro.urgencias.map((urgencia: Urgencia) => urgencia.id)
      : [];
  }

  private updateFiltroLineas(): number[] {
    return this._datosFiltro && this._datosFiltro.lineas
      ? this._datosFiltro.lineas.map((linea: LineasVenta) => +linea.id)
      : [];
  }

  private updateFiltroActividades(): number[] {
    return this._datosFiltro && this._datosFiltro.actividades
      ? this._datosFiltro.actividades.map(
          (actividad: Actividades) => actividad.id
        )
      : [];
  }

  getPeticiones(first: number): any {
    const tipos = this.updateFiltroTipos();
    const estados = this.updateFiltroEstados();
    const urgencias = this.updateFiltroUrgencias();
    const lineas = this.updateFiltroLineas();
    const actividades = this.updateFiltroActividades();

    const request = {
      peticionario: this._datosFiltro.peticionario,
      anio: this._datosFiltro.anio,
      areas: this._datosFiltro.areas,
      tipos,
      actividades,
      lineas,
      destinatarioNombres: this._datosFiltro.destinatarioNombres,
      destinatarioEmails: this._datosFiltro.destinatarioEmails,
      destinatarioDNIs: this._datosFiltro.destinatarioDNIs,
      destinatarioApellidos: this._datosFiltro.destinatarioApellidos,
      urgencias,
      estados,
      fechaSolicitudInicio: this._datosFiltro.fechaSolicitudInicio,
      fechaSolicitudFin: this._datosFiltro.fechaSolicitudFin,
      dniTrabjAfec: this._datosFiltro.dniTrabjAfec,
      cliente: this._datosFiltro.cliente,
      denominacion: this._datosFiltro.denominacion,
      idPeticion: this._datosFiltro.idPeticion,
      busqueda: this._datosFiltro.busqueda,
      ocultarPeticiones: this._datosFiltro.ocultarPeticiones,
    } as PeticionFiltosRequest;

    this.spinner.show();
    this.peticionService.obtenerPeticiones(first, this.rows, request).subscribe(
      (data: PeticionSimple[]) => {
        this.cargarDatosTabla(data);
        if (this.table) {
          this.table.first = (this.paginaActual - 1) * this.rows;
        }
        if (!this.loading) {
          this.spinner.hide();
        }
      },
      (error: any) => {
        console.error(error);
        this.peticiones = [];
        this.spinner.hide();
      }
    );
  }

  private cargarDatosTabla(data: any): any {
    this.peticiones = data.results;
    this.paginaActual = data.currentPage;
    this.paginasTotales = data.totalPages;
    this.peticionesTotales = data.totalResults;
    this.customSort(this.lastSortField);
    $('[data-toggle="tooltip"]').tooltip();
  }

  nextPage(e: any, table: Table): void {
    if (this.rows !== e.rows) {
      this.rows = e.rows;
      e.first = 0;
      this.paginaActual = 1;
      const offset = e.first / this.rows + 1;
      table.first = offset;
      this.rows = e.rows;
      this.datosFiltro.currentPage = offset;
      this.datosFiltro.recordsPerPage = this.rows;
      this.peticionService.setDatosFiltrosPeticion(this.datosFiltro);
      this.getPeticiones(offset);
    } else {
      // verificar si se ha seleccionado un filtro nuevo, en caso contrario, está paginando
      if (
        (e.sortField && !this.lastSortField) ||
        (e.sortField && this.hasChanged(e.sortField, e.sortOrder))
      ) {
        this.lastSortField = {
          sortField: e.sortField,
          sortOrder: e.sortOrder,
        };
        this.customSort(this.lastSortField);
        this.peticionService.setDatosFiltrosPeticion(this._datosFiltro);
        table.first = (this.paginaActual - 1) * this.rows;
      } else {
        const offset = e.first / this.rows + 1;
        this.rows = e.rows;
        this.datosFiltro.currentPage = offset;
        this.datosFiltro.recordsPerPage = this.rows;
        this.peticionService.setDatosFiltrosPeticion(this.datosFiltro);
        this.getPeticiones(offset);
      }
    }
  }

  private hasChanged(sort: string, order: number): boolean {
    return (
      this.lastSortField.sortField !== sort ||
      this.lastSortField.sortOrder !== order
    );
  }

  private customSort(sort: any): void {
    if (sort && this.peticiones) {
      this.peticiones.sort((a: PeticionSimple, b: PeticionSimple) => {
        switch (sort.sortField) {
          case this.cols[0].field:
            return Utils.sortString(a.idPeticion, b.idPeticion, sort.sortOrder);
          case this.cols[1].field:
            return Utils.sortString(
              a.descTipoPeticion,
              b.descTipoPeticion,
              sort.sortOrder
            );
          case this.cols[2].field:
            return Utils.sortString(a.descEstado, b.descEstado, sort.sortOrder);
          case this.cols[3].field:
            return Utils.sortString(
              a.idDestinatario,
              b.idDestinatario,
              sort.sortOrder
            );
          case this.cols[4].field:
            return Utils.sortString(
              a.descLineaVenta,
              b.descLineaVenta,
              sort.sortOrder
            );
          case this.cols[5].field:
            return Utils.sortString(
              a.areaDestino,
              b.areaDestino,
              sort.sortOrder
            );
          default:
            return sort.sortOrder;
        }
      });
    }
  }

  redirectToCreatePeticion(): void {
    this.router.navigate(['/peticiones/crear/identificacion']);
    this.peticionService.setPeticion(this.peticionReset);
  }

  redirectToPeticion(idPeticion: string): void {
    this.router.navigate([`/peticiones/crear/identificacion/${idPeticion}`]);
  }

  redirectToPeticionModificable(idPeticion: string): void {
    this.router.navigate([
      `/peticiones/modificar/identificacion/${idPeticion}`,
    ]);
  }

  redirectToAclaracion(idPeticion: string): void {
    this.router.navigate([`/peticiones/${idPeticion}/aclaraciones`]);
  }

  eliminarPeticion(idPeticion: string): void {
    const data = {
      title: 'Eliminar petición',
      msg: `¿Desea eliminar la petición ${idPeticion}?`,
      buttons: [
        { title: 'Aceptar', class: 'btn-success', action: '1' },
        { title: 'Cancelar', class: 'btn-danger' },
      ] as ButtonModalInfo[],
    } as ModalInfo;
    this.dialog
      .open(ModalInfoComponent, { disableClose: true, autoFocus: false, data })
      .afterClosed()
      .subscribe((resp) => {
        if (resp) {
          this.spinner.show();
          this.peticionService.eliminarPeticion(idPeticion).subscribe(
            (result) => {
              if (result) {
                this.mensaje.showMensaje('Petición eliminada.');
                this.initFields();
                this.getPeticiones(this.first);
              }
            },
            (error) => {
              const result = {
                title: 'Información de error',
                msg: 'La petición no se ha podido eliminar. ' + error.error,
              };
              this.dialog.open(ModalInfoComponent, {
                disableClose: true,
                autoFocus: false,
                data: result,
              });
              console.log(error);
              this.spinner.hide();
            }
          );
        }
      });
  }

  public mostrarModalArtica(idPeticion: string): void {
    this.modalArtica.mostrarModal(idPeticion);
  }

  articaRecibida(idPeticion: string): void {
    const peticion = this.peticiones.find((p) => p.idPeticion === idPeticion);
    if (peticion) {
      peticion.fechaArtica = new Date();
    }
  }

  exportarDatos(): void {
    this.spinner.show();
    const tipos = this.updateFiltroTipos();
    const estados = this.updateFiltroEstados();
    const urgencias = this.updateFiltroUrgencias();
    const lineas = this.updateFiltroLineas();
    const actividades = this.updateFiltroActividades();
    const request: PeticionExportFiltro = {
      anio: this._datosFiltro.anio,
      areasDestino: this._datosFiltro.areas,
      tiposPeticion: tipos,
      idPeticion: this._datosFiltro.idPeticion,
      actividades,
      lineasVenta: lineas,
      destinatarioNombres: this._datosFiltro.destinatarioNombres,
      destinatarioEmails: this._datosFiltro.destinatarioEmails,
      destinatarioApellidos: this._datosFiltro.destinatarioApellidos,
      urgencias,
      estados,
      fechaAltaInicio: this._datosFiltro.fechaSolicitudInicio,
      fechaAltaFin: this._datosFiltro.fechaSolicitudFin,
    };
    this.peticionService.exportarGestionPeticiones(request).subscribe(
      (urlRespuesta) => {
        if (urlRespuesta) {
          // Abrir nueva pantalla con el resultado del exportar
          this.spinner.hide();
          window.open(
            urlRespuesta.url,
            '_blank',
            'location=yes, height=570, width=520, scrollbars=yes, status=yes'
          );
        } else {
          const data = {
            title: 'Información',
            msg: 'No se ha podido hacer la exportación ...',
          };
          this.spinner.hide();
          this.dialog.open(ModalInfoComponent, {
            disableClose: true,
            autoFocus: false,
            data,
          });
        }
      },
      (error) => {
        this.spinner.hide();
        this.mensaje.showErrorPeticion(
          'No se ha podido exportar los datos a excel. Intentelo más tarde.'
        );
      }
    );
  }
}

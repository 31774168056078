<app-mensaje #mensaje></app-mensaje>
<div class="wrapper">
    <app-menu></app-menu>
    <div id="content">
        <app-cabecera></app-cabecera>
        <div>
            <router-outlet></router-outlet>
        </div>

    </div>
</div>


<!-- Modal -->
<div class="modal fade" id="modalsession" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">{{modalError.title}}</h5>
            </div>
            <div class="modal-body">
                {{modalError.mensaje}}
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" (click)="cerrar()">Cerrar</button>
            </div>
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>

<div class="container espacio">
    <div class="d-flex justify-content-between mb-3">
        <h1>Control de Actuaciones</h1>
    </div>

    <div class="card">

        <div class="borde">
            <!-- Filtro de la tabla peticiones -->
            <app-filtro-peticion-actuacion (filtrosPeticion)="recibirFiltros($event)" (loading)="finishLoading($event)"></app-filtro-peticion-actuacion>
            <!-- Tabla de peticiones -->
            <app-tabla-peticiones-actuaciones [datosFiltro]="datosFiltro" [isLoading]="isLoading"></app-tabla-peticiones-actuaciones>
        </div>
    </div>
</div>
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { UsuarioFiltros } from 'src/app/modelos/usuario-filtros';

@Component({
  selector: 'app-usuarios-principal',
  templateUrl: './usuarios-principal.component.html',
  styleUrls: ['./usuarios-principal.component.css'],
})
export class UsuariosPrincipalComponent implements OnInit {
  public items: MenuItem[];
  public activeItem: MenuItem;

  public mostrarUsuario = true;
  // tslint:disable-next-line:variable-name
  public datosDelFiltro!: UsuarioFiltros;
  public emailUsaurio!: string;

  constructor(private router: Router, private acRoute: ActivatedRoute) {
    this.items = [];
    this.activeItem = this.items[0];
  }

  ngOnInit(): any {
    this.items = [
      {
        label: 'Usuarios',
        icon: 'fa fa-user',
        command: (event: any) => {
          this.mostrarUsuario = true;
          this.router.navigate(['/usuarios'], {
            queryParams: { auditoria: null, usuario: null },
            queryParamsHandling: 'merge',
          });
          this.emailUsaurio = '';
        },
      },
      {
        label: 'Auditoría',
        icon: 'fa fa-calendar',
        command: (event: any) => {
          this.mostrarUsuario = false;
        },
      },
    ];

    this.acRoute.queryParams.subscribe((params) => {
      if (params.auditoria) {
        this.mostrarUsuario = false;
        this.activeItem = this.items[1];
        this.emailUsaurio = params.usuario;
      } else {
        this.mostrarUsuario = true;
        this.activeItem = this.items[0];
        this.emailUsaurio = '';
      }
    });
  }

  irAuditoria(usuario: any): void {
    this.mostrarUsuario = false;
    this.activeItem = this.items[1];
    this.emailUsaurio = usuario;
  }

  recibirFiltros(event: any): any {
    // Con esta sentencia pasamos la información del componente filtro-usuario al componente
    // tabla-usuario pasando por el padre que es usuarios-principal
    this.datosDelFiltro = event;
  }
}

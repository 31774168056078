import { Component, EventEmitter, Injector, Output, Inject, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from 'primeng/api';
import { PeticionService } from 'src/app/servicios/peticion/peticion.service';
import { DOCUMENT } from '@angular/common';
import { MensajeComponent } from '../../mensaje/mensaje.component';
import { FormGroup } from '@angular/forms';
import { SubjectsService } from 'src/app/servicios/subjects/subjects.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-modal-artica',
  templateUrl: './modal-artica.component.html',
  styleUrls: ['./modal-artica.component.scss']
})
export class ModalArticaComponent {

  @ViewChild('mensaje', { static: false })
  public mensaje!: MensajeComponent;

  public idPeticion!: string;
  public verModalArtica = false;
  formulario!: FormGroup;

  @Output()
  emitirArtica = new EventEmitter();

  public linkArtica!: string;


  constructor(private inject: Injector,
              @Inject(DOCUMENT) private document: Document,
              private spinner: NgxSpinnerService,
              private peticionService: PeticionService,
              private messageService: MessageService,
              private subjectsService: SubjectsService) {

    this.initServiceProperties();

  }


  initServiceProperties(): void {
    this.peticionService = this.inject.get<PeticionService>(PeticionService);
    this.messageService = this.inject.get<MessageService>(MessageService);
    this.spinner = this.inject.get<NgxSpinnerService>(NgxSpinnerService);
  }

  public mostrarModal(idPeticion: string): void{
    this.idPeticion = idPeticion;
    this.messageService.clear();
    this.spinner.show();
    this.document.body.style.overflowY = 'hidden';
    this.verModalArtica = true;

    this.peticionService.obtenerEnlaceArticaPeticion(idPeticion).subscribe((resp: string) => {
      this.linkArtica = resp;
      this.initForm();
    }, (error: HttpErrorResponse) => {
      console.error(error);
      this.spinner.hide();
      this.mensaje.showErrorPeticion(error.error);
      this.cerrarModal();
    });
  }

  private initForm(): void{
    this.spinner.hide();
    this.messageService.add({ key: 'accion', severity: 'info', sticky: true});
  }

  public cerrarModal(): void{
    this.verModalArtica = false;
    this.document.body.style.overflowY = 'auto';
    this.messageService.clear();

  }

  public aceptarModal(): void{
    this.spinner.show();
    this.peticionService.subirArticaPeticion(this.idPeticion).subscribe(() => {
      this.spinner.hide();
      this.emitirArtica.emit(this.idPeticion);
      this.cerrarModal();

    }, error => {
      this.mensaje.showError(error);
      this.spinner.hide();
      this.cerrarModal();
    });

  }

  public openArtica(): void {
    window.open(this.linkArtica, '_blank', 'location=yes, height=670, width=850, scrollbars=yes, status=yes');

  }

}

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Constantes } from 'src/app/utils/constantes';

@Injectable({
  providedIn: 'root'
})
export class ActividadesService {

  constructor(
    private http: HttpClient,
    private constantes: Constantes
  ) { }

  public getActividades(descriptionMode?: boolean): Observable<any> {
    const url: string = this.constantes.ENDPOINT_API_ACTIVIDADES;
    const params = new HttpParams().set('descriptionMode', (descriptionMode === undefined) ? '' : String(descriptionMode));
    return this.http.get(url, {params});
  }
}

import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { ModalNotificarComponent } from '../../modals/modal-notificar/modal-notificar.component';
import { MensajeComponent } from '../../mensaje/mensaje.component';
import { PeticionService } from '../../../servicios/peticion/peticion.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FileUpload } from 'primeng/fileupload';
import { Fichero } from '../../../modelos/fichero';
import { NgxSpinnerService } from 'ngx-spinner';
import { forkJoin } from 'rxjs';
import { Peticion } from '../../../modelos/peticion';
import { SesionService } from '../../../servicios/sesion/sesion.service';
import { Constantes } from '../../../utils/constantes';
import { ActivatedRoute } from '@angular/router';
import Utils from 'src/app/utils/utils';
import { Usuario } from 'src/app/modelos/usuario';
import { ButtonModalInfo, ModalInfo } from 'src/app/modelos/moda-info';
import { ModalInfoComponent } from '../../modals/modal-info/modal-info.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-cierre-peticion',
  templateUrl: './cierre-peticion.component.html',
  styleUrls: ['./cierre-peticion.component.scss'],
})
export class CierrePeticionComponent implements OnInit {
  @ViewChild('modalnotificar', { static: false })
  public modalnotificar!: ModalNotificarComponent;

  @ViewChild('mensaje', { static: false })
  public mensaje!: MensajeComponent;

  @ViewChild('fileInput', { static: false })
  public fileInput!: FileUpload;

  public peticionService!: PeticionService;
  private sessionService!: SesionService;
  private spinner!: NgxSpinnerService;

  public peticion!: Peticion;
  public uploadedFiles: Fichero[] = [];
  public formulario!: FormGroup;
  public contador!: number;
  public permisoEscritura!: boolean;
  public motivoCierre!: string;
  public accionCierre!: number;
  public textoMotivo!: string;
  public estaInformada: boolean;
  idBorrador!: number;
  cierreBorrador: any;
  borrador!: boolean;
  rechazo!: boolean;
  fichero!: any;

  constructor(
    private route: ActivatedRoute,
    private inject: Injector,
    private sesionService: SesionService,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private constantes: Constantes
  ) {
    this.initServiceProperties();
    this.estaInformada = false;
  }

  ngOnInit(): void {
    this.contador = 0;
    this.loadServices();
    this.initForm();
    this.comprobarAccionCierre();
    this.comprobarInformada();
  }

  initServiceProperties(): void {
    this.peticionService = this.inject.get<PeticionService>(PeticionService);
    this.sessionService = this.inject.get<SesionService>(SesionService);
    this.spinner = this.inject.get<NgxSpinnerService>(NgxSpinnerService);
  }

  private loadServices(): void {
    this.spinner.show();
    const usuarioLogado = this.sessionService.getUser();
    const llamadas = [];
    this.peticion = this.peticionService.getPeticion();

    llamadas.push(
      this.peticionService.obtenerAclaraciones(this.peticion.idPeticion || '')
    );
    llamadas.push(
      this.peticionService.obtenerCierres(this.peticion.idPeticion || '')
    );
    forkJoin(llamadas).subscribe(
      (resp: any) => {
        this.cierreBorrador = resp[1].find(
          (element: any) => element.borradorCierre
        );
        if (this.cierreBorrador) {
          this.mostrarCierreBorrador();
          if (this.cierreBorrador != null) {
            this.uploadedFiles = this.cierreBorrador.fichero;
          }
        } else {
          this.idBorrador = 0;
        }
        this.permisoEscritura =
          usuarioLogado.email === this.peticion.usuarioDestinatario?.email ||
          usuarioLogado.email === this.peticion.usuarioPeticionario?.email ||
          usuarioLogado.rol?.id === 1 ||
          usuarioLogado.rol?.id === 2;
        this.spinner.hide();
      },
      (error) => {
        console.error(error);
        this.spinner.hide();
      }
    );
  }

  private initForm(): void {
    this.formulario = this.fb.group({
      msg: this.comprobarObligatorio(),
    });
  }

  public comprobarInformada(): boolean {
    if (
      this.peticion.catalogo?.tipoFlujo === 'Autorización' &&
      this.peticion.informantes?.filter((i) => i.fechaVistoBueno !== null)
        .length === 0
    ) {
      this.estaInformada = true;
    }
    return this.estaInformada;
  }

  public comprobarObligatorio(): any {
    if (this.accionCierre === this.constantes.ACCION_RESOLVER_CERRAR) {
      return ['', [Validators.maxLength(3000)]];
    } else {
      return ['', [Validators.required, Validators.maxLength(3000)]];
    }
  }

  public comprobarAccionCierre(): void {
    this.motivoCierre = this.route.snapshot.paramMap.get('motivo') as string;
    if (
      this.motivoCierre === this.constantes.ACCION_RESOLVER_CERRAR.toString()
    ) {
      this.accionCierre = this.constantes.ACCION_RESOLVER_CERRAR;
      this.textoMotivo = 'Resolver y Cerrar';
      this.rechazo = false;
    } else {
      this.accionCierre = this.constantes.ACCION_RECHAZAR;
      this.textoMotivo = 'Rechazar y Cerrar';
      this.rechazo = true;
    }
  }

  public onKeyUp(event: any): void {
    this.contador = event.target.value.length;
  }

  public onKeyPress(event: any): any {
    if (event.keyCode === 39 || event.keyCode === 96 || event.keyCode === 180) {
      return false;
    }
    return true;
  }

  public limpiar(): void {
    this.formulario.controls.msg.setValue('');
    this.contador = 0;
  }

  public cerrarPeticion(): void {
    if (this.longitudPermitidaFicheros()) {
      this.modalnotificar.mostrarModalMotivoCierre(
        this.peticion,
        this.accionCierre,
        'Cierre de la petición',
        this.formulario.get('msg')?.value,
        this.fileInput ? this.fileInput.files : [],
        [this.idBorrador, this.rechazo, this.borrador]
      );
    }
  }

  private longitudPermitidaFicheros(): boolean {
    if (this.fileInput) {
      for (const fichero of this.fileInput.files) {
        if (
          fichero.name.length > this.constantes.LONIGUTD_MAXIMA_NOMBRE_FICHERO
        ) {
          this.mensaje.showErrorPeticion(
            `El fichero: ${fichero.name} excede de ${this.constantes.LONIGUTD_MAXIMA_NOMBRE_FICHERO} caracteres`
          );
          return false;
        }
      }
    }
    return true;
  }

  public notificadores(idPeticion: string): void {
    if (this.fileInput) {
      this.fileInput.clear();
    }
    this.mensaje.showMensaje('Comentario realizado correctamente.');
    this.ngOnInit();
  }

  public volver(): void {
    window.history.back();
  }

  public deshabilitarBoton(): boolean {
    if (
      (!this.formulario.valid && this.motivoCierre === '13') ||
      this.uploadedFiles.length + this.fileInput?.files?.length > 5
    ) {
      return true;
    }
    return false;
  }

  public controlForm(): void {
    if (this.formulario.get('msg')?.value === '') {
      this.contador = 0;
    } else {
      this.contador = this.formulario.get('msg')?.value.trim().length;
    }
  }

  public descargarFicheroResolucion(fichero: Fichero): void {
    this.spinner.show();
    this.peticionService
      .descargarFichero(fichero.idPeticion, fichero.idFichero)
      .subscribe(
        (fileReceived: any) => {
          this.spinner.hide();
          Utils.base64ToFile(fileReceived.file, fichero.nombre || '');
        },
        (errorDescarga: any) => {
          console.error(errorDescarga);
          this.mensaje.showErrorPeticion(
            'No se ha podido descargar el fichero ' +
              errorDescarga.error.message
          );
          this.spinner.hide();
        }
      );
  }

  public visualizarFicheroResolucion(fichero: Fichero): void {
    let base: string =
      this.constantes.ENDPOINT_API_PETICIONES_VISUALIZAR_FICHERO.replace(
        '{idPeticion}',
        String(fichero.idPeticion)
      ).replace('{idFichero}', fichero.idFichero);

    let contentType = Utils.calculateMediaType(fichero.nombre.split('.')[1]);
    let finalURL = base;
    if(contentType.includes('doc') || contentType.includes('xls')){
      finalURL = "https://view.officeapps.live.com/op/embed.aspx?src=" + base;
    }

    const link = document.createElement('a');
    link.href = finalURL;
    link.target = '_blank';
    link.click();
  }

  public cierreFichero(codCierre: number): boolean {
    for (const iterator of this.peticion.ficherosResolucion) {
      if (iterator.codCierre === codCierre) {
        return true;
      }
    }

    return false;
  }
  private crearAccionRequestCierre(): any {
    return {
      notificados: null,
      usuario: { email: this.sesionService.getUser().email } as Usuario,
      peticion: { idPeticion: this.peticion.idPeticion } as Peticion,
      texto: this.formulario.get('msg')?.value,
      fecha: '',
      borrador: this.idBorrador,
      esRechazo: this.rechazo,
    };
  }
  public crearCierreBorrador(): void {
    this.spinner.show();
    if (this.cierreBorrador) {
      this.peticionService
        .editarCierreBorrador(
          this.peticion.idPeticion,
          this.crearAccionRequestCierre(),
          this.fileInput ? this.fileInput.files : []
        )
        .subscribe((resp: any) => {
          this.cierreBorrador = resp;
          this.uploadedFiles = this.cierreBorrador.fichero;
          this.loadServices();
          this.spinner.hide();
        });
    } else {
      this.peticionService
        .cierrePeticionBorrador(
          this.peticion.idPeticion,
          this.crearAccionRequestCierre(),
          this.fileInput ? this.fileInput.files : []
        )
        .subscribe((resp: any) => {
          this.cierreBorrador = resp;
          this.uploadedFiles = this.cierreBorrador.fichero;
          this.loadServices();
          this.spinner.hide();
        });
    }
    this.borrador = true;
    this.fileInput.clear();
  }

  public eliminarCierreBorrador(): void {
    const data = {
      title: 'Eliminar borrador',
      msg: `¿Desea eliminar al borrador?`,
      buttons: [
        { title: 'Aceptar', class: 'btn-success', action: '1' },
        { title: 'Cancelar', class: 'btn-danger' },
      ] as ButtonModalInfo[],
    } as ModalInfo;
    this.dialog
      .open(ModalInfoComponent, { disableClose: true, autoFocus: false, data })
      .afterClosed()
      .subscribe((resp) => {
        if (resp) {
          this.spinner.show();
          this.peticionService
            .eliminarCierrePeticionBorrador(
              this.peticion.idPeticion,
              this.idBorrador.toString()
            )
            .subscribe(() => {
              this.mensaje.showMensaje(
                'Borrador eliminado satisfactoriamente.'
              );
              this.spinner.hide();
            });
          this.formulario.controls.msg.setValue('');
          this.cierreBorrador = undefined;
          this.fileInput.clear();
          this.borrador = false;
        }
      });
  }

  public mostrarCierreBorrador() {
    if (this.cierreBorrador.borradorCierre != undefined) {
      this.formulario.controls.msg.setValue(
        this.cierreBorrador.observacionCierre
      );
      this.borrador = true;
      this.idBorrador = this.cierreBorrador.id;
    } else {
      this.formulario.controls.msg.setValue('');
      this.borrador = false;
    }
  }

  public fijarElemento(event: any): void {
    event.preventDefault();
  }

  eliminarFichero(idFichero: string): void {
    const data = {
      title: 'Eliminar fichero borrador',
      msg: `¿Desea eliminar al fichero?`,
      buttons: [
        { title: 'Aceptar', class: 'btn-success', action: '1' },
        { title: 'Cancelar', class: 'btn-danger' },
      ] as ButtonModalInfo[],
    } as ModalInfo;
    this.dialog
      .open(ModalInfoComponent, { disableClose: true, autoFocus: false, data })
      .afterClosed()
      .subscribe((resp) => {
        if (resp) {
          this.spinner.show();
          this.peticionService
            .eliminarFicheroCierre(this.idBorrador.toString(), idFichero)
            .subscribe((resp: any) => {
              this.uploadedFiles = resp;
              this.spinner.hide();
              this.mensaje.showMensaje('Fichero eliminado satisfactoriamente.');
            });
        }
      });
  }
}

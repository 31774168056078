import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  UrlSegment,
  Router,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { PeticionService } from '../peticion/peticion.service';
import { SesionService } from '../sesion/sesion.service';
import { Peticion, PeticionInformante } from '../../modelos/peticion';
import { catchError, map } from 'rxjs/operators';
import { SubjectsService } from '../subjects/subjects.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Constantes } from '../../utils/constantes';
import { Provincia } from 'src/app/modelos/provincia';

@Injectable({
  providedIn: 'root',
})
export class PeticionGuard implements CanActivate {
  private urlEmail:string = "";

  constructor(
    private peticionService: PeticionService,
    private sesionService: SesionService,
    private subjectsService: SubjectsService,
    private spinnerService: NgxSpinnerService,
    private constantes: Constantes,
    private router: Router
  ) {}

  public canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const email = route.queryParams.email;
    this.urlEmail = (email !== null && email !== undefined) ? String(email) : "";

    if (this.urlEmail !== "") {
      this.peticionService.setFromUrlEmail(true);
    }

    if (
      this.sesionService.getUser() &&
      !this.sesionService.isGoogleUserSignedIn()
    ) {
      return of(true);
    }

    if (
      this.sesionService.getUser() === null ||
      this.sesionService.getUser() === undefined
    ) {
      this.router.navigate(['/']);
      return of(false);
    }

    this.peticionService.setRutaModificable(false);
    route.url.forEach((segmentoUrl: UrlSegment) => {
      if (segmentoUrl.path === 'modificar') {
        this.peticionService.setRutaModificable(true);
      }
    });

    const idPeticion = route.params.id;
    return this.peticionService.obtenerPeticion(idPeticion).pipe(
      map((resp) => this.validarEmails(resp, route)),
      catchError((error) => this.returnHandleError(error))
    );
  }

  returnHandleError(error: any): Observable<boolean> {
    return of(error.status === 403);
  }

  validarEmails(peticion: Peticion, route: ActivatedRouteSnapshot): boolean {
    if (
      !this.controlarExistePeticion(peticion) ||
      !this.controlAutorizacion(peticion, route)
    ) {
      return false;
    }

    this.controlarSesionCaducada();

    if (this.evalCondRolPuesto(peticion)) {
      this.peticionService.setPeticion(peticion);
      if (this.sesionService.getSesionCaducada() === true) {
        this.spinnerService.hide();
      }
      return true;
    }
    const emailSession = this.sesionService.getUser().email;
    const listaEmailsValidar = [];
    listaEmailsValidar.push(peticion.usuarioDestinatario?.email);
    listaEmailsValidar.push(peticion.usuarioPeticionario?.email);
    if (peticion.notificadoresLst !== undefined) {
      for (const notificado of peticion.notificadoresLst) {
        listaEmailsValidar.push(notificado.usuario?.email);
        if (notificado.emailOtros !== null) {
          listaEmailsValidar.push(notificado.emailOtros);
        }
      }
    }
    if (listaEmailsValidar.includes(emailSession)) {
      this.peticionService.setPeticion(peticion);
      if (this.sesionService.getSesionCaducada() === true) {
        this.spinnerService.hide();
      }
      return true;
    } else {
      const error = {
        error: {
          message: 'No tiene privilegios para acceder a esta petición',
          error: 'Error Peticion',
          status: 500,
        },
      };
      this.subjectsService.emitirEmitirError(error);
      this.spinnerService.hide();
      return false;
    }
  }

  controlarExistePeticion(peticion: Peticion): boolean {
    if (!peticion || peticion === null) {
      this.spinnerService.hide();
      const error = {
        error: {
          message: 'Petición no encontrada',
          error: 'Error Petición',
          status: 404,
        },
      };
      this.subjectsService.emitirEmitirError(error);
      this.router.navigate(['/']);
      return false;
    } else {
      return true;
    }
  }

  controlAutorizacion(
    peticion: Peticion,
    route: ActivatedRouteSnapshot
  ): boolean {
    let errAutorizacion = false;

    route.url.forEach((segmentoUrl: UrlSegment) => {
      if (
        peticion.catalogo &&
        peticion.catalogo.tipoFlujo !== 'AUTORIZACION' &&
        segmentoUrl.path === 'autorizaciones'
      ) {
        const error = {
          error: {
            message: 'La petición actual no requiere autorización',
            error: 'Error Peticion',
            status: 403,
          },
        };
        this.sesionService.setSesionCaducada(true);
        this.subjectsService.emitirEmitirError(error);
        errAutorizacion = true;
      }
    });

    return !errAutorizacion;
  }

  controlarSesionCaducada(): void {
    if (this.sesionService.getSesionCaducada() === false) {
      this.spinnerService.show();
    }
  }

  evalCondRolPuesto(peticion: Peticion): boolean {
    const usuarioLogado = this.sesionService.getUser();
    const rol = usuarioLogado.rol?.id;
    const puesto = usuarioLogado.puesto?.id;
    const territorios = this.sesionService.getTerritorios();

    const provinciasUsuario = territorios
      .filter((t) => t.provincia !== undefined && t.provincia !== null)
      .map((t) => t.provincia) as Provincia[];

    switch (rol) {
      case this.constantes.ID_ADMINISTRADOR:
        return true;
      case this.constantes.ID_ADMINISTRADOR_LOCAL:
        return this.evaluarZonasRegiones(provinciasUsuario, peticion);
      case this.constantes.ROL_AUDITOR:
        return true;
      case this.constantes.ID_INFORMANTE:
        return (
          this.evaluarRolInformante(peticion) ||
          this.evaluarZonasRegiones(provinciasUsuario, peticion)
        );
      case this.constantes.ID_AUTORIZANTE:
        return (
          this.evaluarRolAutorizante(peticion) ||
          this.evaluarZonasRegiones(provinciasUsuario, peticion)
        );
      default:
        if (
          puesto === this.constantes.listaPuestosTecnicos[0].id ||
          puesto === this.constantes.listaPuestosTecnicos[1].id
        ) {
          return this.evaluarZonasRegiones(provinciasUsuario, peticion);
        } else {
          return false;
        }
    }
  }

  evaluarRolInformante(peticion: Peticion): boolean {
    if (peticion.informantes) {
      return (
        peticion.informantes?.filter(
          (informante: PeticionInformante) =>
            informante.idInformante === this.sesionService.getUser().email
        ).length > 0
      );
    } else {
      return false;
    }
  }

  evaluarRolAutorizante(peticion: Peticion): boolean {
    return (
      peticion.idAutorizante !== null &&
      peticion.idAutorizante === this.sesionService.getUser().email
    );
  }

  evaluarZonasRegiones(
    provinciasUsuario: Provincia[],
    peticion: Peticion
  ): boolean {
    return (
      this.evaluarZonas(provinciasUsuario, peticion) ||
      this.evaluarRegiones(provinciasUsuario, peticion)
    );
  }

  evaluarZonas(provinciasUsuario: Provincia[], peticion: Peticion): boolean {
    if (peticion.zona && peticion.zona.id) {
      for (const provincia of provinciasUsuario) {
        if (provincia.id !== null && provincia.id === peticion.provincia?.id) {
          return true;
        }
      }
    }
    return false;
  }

  evaluarRegiones(provinciasUsuario: Provincia[], peticion: Peticion): boolean {
    if (peticion.region && peticion.region.id) {
      for (const provincia of provinciasUsuario) {
        if (provincia.id !== null && provincia.id === peticion.provincia?.id) {
          return true;
        }
      }
      return false;
    }
    return false;
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { PeticionService } from '../../../../servicios/peticion/peticion.service';
import { Peticion } from '../../../../modelos/peticion';
import { MensajeComponent } from 'src/app/componentes/mensaje/mensaje.component';
import { Constantes } from 'src/app/utils/constantes';
import { Puesto } from 'src/app/modelos/puesto';
import { NgxSpinnerService } from 'ngx-spinner';
import { Aclaracion } from '../../../../modelos/aclaracion';
import { forkJoin } from 'rxjs';
import { ModalArticaComponent } from '../../../modals/modal-artica/modal-artica.component';
import { Informacion } from 'src/app/modelos/informacion';
import { SesionService } from 'src/app/servicios/sesion/sesion.service';
import { Accion } from 'src/app/modelos/accion';
import { AccionPeticionConstantes } from 'src/app/utils/acciones-peticion-constantes';
import { ModalNotificarComponent } from 'src/app/componentes/modals/modal-notificar/modal-notificar.component';
import { ModalAutorizarComponent } from 'src/app/componentes/modals/modal-autorizar/modal-autorizar.component';

@Component({
  selector: 'app-crear-peticion-confirmacion',
  templateUrl: './crear-peticion-confirmacion.component.html',
  styleUrls: ['./crear-peticion-confirmacion.component.css'],
})
export class CrearPeticionConfirmacionComponent implements OnInit {
  activeIndex = 5;

  peticion!: Peticion;
  fechaResolucion = '';
  perfiles: Puesto[];
  modificable = true;
  esAuditor = false;
  aclaraciones: Aclaracion[] = [];
  informaciones: Informacion[] = [];
  public textoToolTipEnviar = 'Enviar a pendiente de valoración';

  acciones: Accion[] = [];
  accionesDetalle: Accion[] = [];

  accionNominaSGC:number = 34;
  accionSecuencialSGC:number = 35;

  @ViewChild('mensaje', { static: false })
  public mensaje!: MensajeComponent;

  @ViewChild('modalnotificar', { static: false })
  public modalnotificar!: ModalNotificarComponent;

  @ViewChild('modalArtica', { static: false })
  public modalArtica!: ModalArticaComponent;

  @ViewChild('modalAutorizar', { static: false })
  public modalAutorizar!: ModalAutorizarComponent;

  constructor(
    private router: Router,
    public constantes: Constantes,
    public peticionService: PeticionService,
    private spinner: NgxSpinnerService,
    private sesionService: SesionService,
    private accionesConstantes: AccionPeticionConstantes
  ) {
    this.perfiles = [];
  }

  ngOnInit(): void {
    this.loadInfo();
  }

  private loadInfo(): void {
    this.peticion = this.peticionService.getPeticion();
    if (this.peticion) {
      if (this.peticion.catalogo?.tipoFlujo === this.constantes.AUTOMATISMO) {
        this.textoToolTipEnviar = 'Generar PDF y Cerrar Petición';
      }
      this.modificable = this.peticionService.esModificable();

      this.esAuditor =
        this.sesionService.getPermisos().ga === 1 && !this.modificable;
      if (this.peticion.idPeticion && this.peticion.grupoSoporte?.id) {
        this.spinner.show();
        const llamadas = [];
        llamadas.push(
          this.peticionService.getPerfilesNotificacion(
            this.peticion.idPeticion,
            this.peticion.grupoSoporte.id
          )
        );
        llamadas.push(
          this.peticionService.obtenerAclaraciones(this.peticion.idPeticion)
        );
        llamadas.push(
          this.peticionService.obtenerInformaciones(this.peticion.idPeticion)
        );
        llamadas.push(
          this.peticionService.getPeticionActuacion(
            this.peticion.idPeticion,
            this.sesionService.getUser().email
          )
        );
        forkJoin(llamadas).subscribe(
          (resp) => {
            if (resp[0]) {
              this.perfiles = resp[0];
            }
            console.log(resp);
            this.aclaraciones = resp[1].filter(
              (element: { borrador: number }) => element.borrador != 1
            );
            this.informaciones = resp[2];
            this.acciones = resp[3];
            this.eliminarBotonAccionConTrabajadorAfectado(this.accionNominaSGC);
            this.eliminarBotonAccionConTrabajadorAfectado(this.accionSecuencialSGC);
            this.obtenerEstilosAccionesDetalle();
            this.spinner.hide();
          },
          (error) => {
            this.spinner.hide();
            this.mensaje.showErrorPeticion(error);
          }
        );
      }
    }
  }

  previousPage(): void {
    this.router.navigate(['peticiones/crear/notificaciones']);
  }

  public borrador(): void {
    this.spinner.show();
    this.peticionService.borradorPeticion(this.peticion.idPeticion).subscribe(
      (result: Peticion) => {
        this.spinner.hide();
        if (result) {
          this.mensaje.showMensaje(
            'Petición en Estado Borrador',
            '/peticiones'
          );
        }
      },
      (error) => {
        this.spinner.hide();
        this.mensaje.showErrorPeticion(
          'La petición no ha podido cambiar a estado Borrador.' + error.error
        );
      }
    );
  }

  public enviar(): void {
    this.spinner.show();
    this.peticionService.enviarPeticion(this.peticion.idPeticion).subscribe(
      (result: Peticion) => {
        this.spinner.hide();
        if (result) {
          let mensajeResult = 'Petición en estado Pendiente de Valoración';
          if (
            this.peticion.catalogo?.tipoFlujo === this.constantes.AUTOMATISMO
          ) {
            mensajeResult = 'Documento PDF enviado y petición Cerrada';
          }
          this.mensaje.showMensaje(mensajeResult, '/peticiones');
        }
      },
      (error) => {
        this.spinner.hide();
        this.mensaje.showErrorPeticion(
          'La petición no ha podido enviarse. ' + error.error
        );
      }
    );
  }

  articaRecibida(idPeticion?: string): void {
    if (idPeticion) {
      this.peticion.fechaArtica = new Date();
    }
  }

  auditar() {
    this.router.navigateByUrl(`/auditoria/${this.peticion.idPeticion}`);
  }

  public ejecutarAccion(idAccion: number): void {
    this.peticionService.setControlActuaciones(true);
    switch (idAccion) {
      case this.constantes.ACCION_VISUALIZAR:
        this.router.navigate([
          `/peticiones/crear/confirmacion/${this.peticion.idPeticion}`,
        ]);
        break;
      case this.constantes.ACCION_EDITAR:
        this.router.navigate([
          `/peticiones/modificar/identificacion/${this.peticion.idPeticion}`,
        ]);
        break;
      case this.constantes.ACCION_COMENTAR:
        this.router.navigate([`/peticiones/${this.peticion.idPeticion}`]);
        break;
      case this.constantes.ACCION_ACLARACION:
        this.router.navigate([
          `/peticiones/${this.peticion.idPeticion}/aclaraciones`,
        ]);
        break;
      case this.constantes.ACCION_RESPONDER:
        this.mostrarModalResp(
          this.peticion.idPeticion ? this.peticion.idPeticion : '',
          idAccion
        );
        break;
      case this.constantes.ACCION_RECHAZAR:
        this.router.navigate([
          `/peticiones/${this.peticion.idPeticion}/cierre-peticion/13`,
        ]);
        break;
      case this.constantes.ACCION_RESOLVER_CERRAR:
        this.router.navigate([
          `/peticiones/${this.peticion.idPeticion}/cierre-peticion/14`,
        ]);
        break;
      case this.constantes.ACCION_SOLICITAR_AUTORIZACION:
        this.mostrarModalAutorizar(this.peticion.idPeticion || '');
        break;
      case this.constantes.ACCION_INFORMAR:
        this.router.navigate([
          `/peticiones/${this.peticion.idPeticion}/informaciones`,
        ]);
        break;
      case this.constantes.ACCION_AUTORIZAR:
        this.router.navigate([
          `/peticiones/${this.peticion.idPeticion}/autorizaciones`,
        ]);
        break;
      case this.constantes.ACCION_ARTICA:
        this.mostrarModalArtica(this.peticion.idPeticion || '');
        break;
      case this.constantes.ACCION_REABRIR:
        this.router.navigate([`reabrir/${this.peticion.idPeticion}`]);
        break;
      case this.constantes.ACCION_AS400WEB:
        window.open("https://as400web.eulen.com/EulenP/entry", "_blank", "noopener");
        break;
      case this.constantes.ACCION_INFORMACION_EMPLEADO:
        this.router.navigate([`/informacion-empleado/${this.peticion.dniTrabajador}`]);
        break;
      default:
    }
  }

  private eliminarBotonAccionConTrabajadorAfectado(numBoton:number): void {
    if(this.acciones) {
      this.acciones.forEach((accion: Accion, index: number) => {
        const accionReaded = this.accionesConstantes.ACCIONES.find(
          (a: Accion) => a.id === accion.id
        );
        if (accionReaded !== undefined && numBoton == accionReaded.id && this.noTrabajadorAfectado(this.peticion.dniTrabajador)) {
          this.acciones.splice(index, 1);
        }
      });
    }
  }

  private noTrabajadorAfectado(dniTrabajador:string | undefined): boolean {
    return (dniTrabajador === undefined || dniTrabajador === null || dniTrabajador === '') ? true : false;
  }

  private obtenerEstilosAccionesDetalle(): void {
    if (this.acciones) {
      this.accionesDetalle = [];
      this.acciones.forEach((accion: Accion) => {
        const accionReaded = this.accionesConstantes.ACCIONES.find(
          (a: Accion) => a.id === accion.id
        );
        if (accionReaded) {
          if (
            accionReaded.id === this.constantes.ACCION_ARTICA &&
            this.peticion.fechaArtica === null
          ) {
            accionReaded.clases = accionReaded.clases?.concat(' red');
          }
          this.accionesDetalle.push(accionReaded);
        }
      });
    }
  }

  public mostrarModalArtica(idPeticion: string): void {
    this.modalArtica.mostrarModal(idPeticion);
  }

  public mostrarModalResp(idPeticion: string, idAccion: number): void {
    this.modalnotificar.mostrarModalResponder(
      idPeticion,
      idAccion,
      'Responder Petición',
      'CURSO'
    );
  }

  public mostrarModalAutorizar(idPeticion: string): void {
    this.modalAutorizar.mostrarModal(idPeticion);
  }

  reload(): void {
    this.ngOnInit();
  }

  notificadores(idPeticion: string): void {
    console.log(idPeticion);
    this.loadInfo();
  }
}

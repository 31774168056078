<ngx-spinner></ngx-spinner>

<div class="container espacio">
    <div class="d-flex justify-content-between mb-3">
        <h1>Gestión de notificaciones</h1>
    </div>
    <div class="card">
        <div class="borde">
            <!-- Filtro de la tabla notificaciones -->
            <app-filtro-notificacion (desdeFiltroNotificacion)="recibirFiltros($event)"></app-filtro-notificacion>
            <hr>
            <!-- Tabla de notificaciones -->
            <app-tabla-notificaciones [datosFiltro]="datosDelFiltro"></app-tabla-notificaciones>
        </div>
    </div>
</div>
import { Component } from '@angular/core';
import { NotificacionFiltros } from 'src/app/modelos/notificacion-filtros';

@Component({
  selector: 'app-notificaciones-principal',
  templateUrl: './notificaciones-principal.component.html',
  styleUrls: ['./notificaciones-principal.component.scss']
})
export class NotificacionesPrincipalComponent{

  public datosDelFiltro!: NotificacionFiltros;


  recibirFiltros(event: any): any{
    // Con esta sentencia pasamos la información del componente filtro-usuario al componente
    // tabla-usuario pasando por el padre que es usuarios-principal
    this.datosDelFiltro = event;
  }
}

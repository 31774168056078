<app-mensaje #mensaje>
</app-mensaje>
<app-modal-autorizar id="modal-autorizar" #modalAutorizar (emitirAutorizar)="reload()"></app-modal-autorizar>
<app-modal-notificar id="modal-notificar" #modalnotificar (emitirNotificar)="notificadores($event)"></app-modal-notificar>
<app-modal-artica id="modal-artica" #modalArtica (emitirArtica)="articaRecibida($event)"></app-modal-artica>
<ngx-spinner>
</ngx-spinner>
<div class="container mt-5">
    <div class="acciones">
        <button class="btn btn-outline-secondary" pTooltip="{{textoBotonVolver}}" (click)="volver()">Volver</button>
    </div>
    <div class="row">
        <div class="col-12 col-sm-6">
            <h2>Resumen de la petición</h2>
        </div>
        <div class="col-12 col-sm-6" *ngIf="accionesDetalle">
            <ng-container *ngFor="let accion of accionesDetalle">
                <button *ngIf="(accion.id !== constantes.ACCION_COMENTAR && accion.id !== constantes.ACCION_ELIMINAR)" pTooltip={{accion.tooltip}} [class]="accion.clases || ''" [ngClass]="{'red' : peticion.fechaArtica === null}" (click)="ejecutarAccion(accion.id)"><em class={{accion.icono}}></em></button>
            </ng-container>
        </div>
    </div>
    <app-detalle-peticion [peticion]="peticion" [perfiles]="perfiles" [informaciones]="informaciones" [aclaraciones]="aclaraciones" [comentarioModificable]="true"></app-detalle-peticion>
</div>

<ngx-spinner></ngx-spinner>

<div class="container-fluid espacio">
    <div class="d-flex justify-content-between mb-3">
        <h1>Gestión de Roles</h1>
    </div>

    <div class="card">
        <p-table id="tabla-roles" #dt1 [style]="{'overflow':'auto!important'}" [resizableColumns]="true" columnResizeMode="expand" [responsive]="true" [value]="roles" [showCurrentPageReport]="true" dataKey="id" styleClass="p-datatable-responsive-demo" [rows]="rows"
            [totalRecords]="rolesTotales" [columns]="cols" [autoLayout]="true" [lazy]="true" (onLazyLoad)="nextPage($event, dt1)" [paginator]="true" [globalFilterFields]="cols" sortField="descripcion" [sortOrder]="1" currentPageReportTemplate="Página {{paginaActual}} de {{paginasTotales}}"
            [rowsPerPageOptions]="rolesPorPagina">
            <ng-template pTemplate="caption">
              <button class="btn boton-excel" (click)="exportarDatos()"><i class="fa fa-file-excel-o" aria-hidden="true"></i> EXPORTAR</button>
            </ng-template>
            <ng-template pTemplate="header">
                <colgroup span="1"></colgroup>
                <colgroup span="1"></colgroup>
                <colgroup span="1"></colgroup>
                <colgroup span="6"></colgroup>
                <tr>
                    <th scope="rol" class="text-center" colspan="1">ROL</th>
                    <th scope="observaciones" class="text-center" colspan="1">PERMISOS</th>
                    <th scope="grupos" class="text-center" colspan="1">Permisos Grupos de Soporte</th>
                    <th scope="menu" class="text-center" colspan="6">Permisos sobre el Menú</th>
                </tr>
                <tr>
                    <th *ngFor="let col of cols" scope="{{col.field}}" pSortableColumn="{{col.field}}">
                        <div class="p-d-flex p-jc-between p-ai-center" pTooltip="{{col.tool}}" tooltipPosition="top">
                            {{col.header}}
                            <p-sortIcon *ngIf="col.field==='descripcion'" field="{{col.field}}"></p-sortIcon>
                        </div>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rol let-i="rowIndex">
                <tr>
                    <td><span class="p-column-title">DESCRIPCION</span> {{rol.descripcion}}</td>
                    <td><span class="p-column-title">OBSERVACIONES</span>
                        <button pTooltip="¿Qué pueden hacer?" tooltipPosition="right" class="btn btn-sm btn-outline-info" (click)="mostrarObservaciones(rol.observaciones)"><em class="fa fa-eye"></em></button>
                    </td>

                    <!-- Permisos por Grupos de Soporte -->
                    <td>
                        <span class="p-column-title">GRUPOS-SOPORTE</span>
                        <p-multiSelect id="zonas" appendTo="body" filterPlaceHolder="Buscar" [defaultLabel]="'Seleccione grupos...'" [virtualScroll]="true" [(ngModel)]="rol.gruposSoporte" [options]="listaGrupos" optionLabel="descripcion" styleClass="form-control p-0" (onChange)="cambioPermisos(i, rol)"></p-multiSelect>
                    </td>

                    <!-- Permisos sobre el Menú de la APP -->
                    <td>
                        <span class="p-column-title">AU</span>
                        <p-selectButton [disabled]="rol.id === 1" [options]="opcionesBoton" optionLabel="label" [(ngModel)]="rol.au" optionValue="value" (onChange)="cambioPermisos(i, rol)"></p-selectButton>
                    </td>
                    <td>
                        <span class="p-column-title">GP</span>
                        <p-selectButton [disabled]="rol.id === 1" [options]="opcionesBoton" optionLabel="label" [(ngModel)]="rol.gp" optionValue="value" (onChange)="cambioPermisos(i, rol)"></p-selectButton>
                    </td>
                    <td>
                        <span class="p-column-title">PS</span>
                        <p-selectButton [disabled]="rol.id === 1" [options]="opcionesBoton" optionLabel="label" [(ngModel)]="rol.ps" optionValue="value" (onChange)="cambioPermisos(i, rol)"></p-selectButton>
                    </td>
                    <td>
                        <span class="p-column-title">GA</span>
                        <p-selectButton [disabled]="rol.id === 1" [options]="opcionesBoton" optionLabel="label" [(ngModel)]="rol.ga" optionValue="value" (onChange)="cambioPermisos(i, rol)"></p-selectButton>
                    </td>
                    <td>
                        <span class="p-column-title">KPI</span>
                        <p-selectButton [disabled]="rol.id === 1" [options]="opcionesBoton" optionLabel="label" [(ngModel)]="rol.kpi" optionValue="value" (onChange)="cambioPermisos(i, rol)"></p-selectButton>
                    </td>
                    <td>
                        <span class="p-column-title">AG</span>
                        <p-selectButton [disabled]="rol.id === 1" [options]="opcionesBoton" optionLabel="label" [(ngModel)]="rol.ag" optionValue="value" (onChange)="cambioPermisos(i, rol)"></p-selectButton>
                    </td>

                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="12">No hay roles</td>
                </tr>
            </ng-template>
            <ng-template pTemplate="paginatorleft">
            </ng-template>
            <ng-template pTemplate="paginatorright">
                <p class="pageFooterLeft">Roles totales: {{rolesTotales}}</p>
            </ng-template>
        </p-table>
    </div>
</div>
import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'dateFormatPipe',
})
export class DateFormatPipe implements PipeTransform {
  transform(value: any): any {
    const datePipe = new DatePipe('en-EN');
    return datePipe.transform(value, 'dd-MM-yyyy HH:mm:ss');
  }
}

@Pipe({
  name: 'dateFormatPipeYear',
})
export class DateFormatPipeYear implements PipeTransform {
  transform(value: any): any {
    const datePipe = new DatePipe('en-EN');
    return datePipe.transform(value, 'dd-MM-yyyy');
  }
}

@Pipe({
  name: 'stringFormatPipeName',
})
export class StringFormatPipeName implements PipeTransform {
  transform(value: string): string {
    const separador = '@';
    const arrayDeCadenas = value.split(separador);
    return arrayDeCadenas[0];
  }
}

@Pipe({
  name: 'hoursToDaysPipe',
})
export class HoursToDaysPipe implements PipeTransform {
  transform(value: number): number {
    let days: number;
    days = Math.trunc(value / 24);
    return days;
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { EmpresasService } from '../../../servicios/empresas/empresas.service';
import { CatalogosService } from '../../../servicios/catalogos/catalogos.service';
import { forkJoin } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { MensajeComponent } from '../../mensaje/mensaje.component';
import { Catalogo } from '../../../modelos/catalogo';
import { PlantillaAutomatismo } from '../../../modelos/plantilla-automatismo';
import { Empresa } from '../../../modelos/empresa';
import { FormGroup, FormBuilder } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { Constantes } from 'src/app/utils/constantes';

@Component({
  selector: 'app-automatismos',
  templateUrl: './automatismos.component.html',
  styleUrls: ['./automatismos.component.css']
})
export class AutomatismosComponent implements OnInit {

  @ViewChild('mensaje', { static: true })
  public mensaje!: MensajeComponent;

  catalogo!: Catalogo;

  plantillasCatalogo: PlantillaAutomatismo[] = [];

  empresas: Empresa[] = [];

  formulario!: FormGroup;

  loaded = false;

  constructor(private route: ActivatedRoute, private router: Router,
              private catalogoService: CatalogosService, private empresasService: EmpresasService,
              private fb: FormBuilder, private spinner: NgxSpinnerService,
              private constantes: Constantes) { }

  ngOnInit(): void {
    this.loadServices();
  }

  private loadServices(): void {
    const llamadas = [];
    const catalogoId = this.route.snapshot.paramMap.get('id');
    if (catalogoId === null) {
      this.router.navigate(['/catalogos/crear']);
    }
    llamadas.push(this.catalogoService.getCatalogo(catalogoId || ''));
    llamadas.push(this.catalogoService.getCatalogoAutomatismos(catalogoId || ''));
    llamadas.push(this.empresasService.getEmpresas());
    forkJoin(llamadas).subscribe((resp: any) => {
      this.catalogo = resp [0];
      if (this.catalogo === null){
        this.router.navigate(['/catalogos/crear']);
      }
      this.plantillasCatalogo = resp[1];
      this.empresas = resp[2];
      this.initForm();
    });
  }

  private initForm(): void {
    this.formulario = this.fb.group({
      activo: [{value: this.catalogo.activo, disabled: this.catalogo.editable !== 1}]
    });
    this.empresas.forEach(e => {
      this.formulario.addControl('automatismo-empresa-' + e.id,
      this.fb.control({value: this.obtenerPlantillaCatalago(e.id), disabled: this.catalogo.editable !== 1}));
    });
    this.loaded = true;
  }


  private obtenerPlantillaCatalago(id: number): string {
    const plantilla = this.plantillasCatalogo.find(p => p.empresa.id === id);
    return plantilla?.id || '';
  }

  public volver(): void {
    this.router.navigate([`/catalogos/${this.catalogo.id}`]);
  }

  public existePlantilla(empresa: Empresa): PlantillaAutomatismo | undefined {
    return this.plantillasCatalogo.find(p => p.empresa.id === empresa.id);
  }

  public abrirPlantilla(empresa: Empresa): void {
    let urlRespuesta;
    if (this.catalogo.id === this.constantes.TIPO_CATALOGO_CHECKLIST_CESION_ILEGAL){
      urlRespuesta = `https://docs.google.com/spreadsheets/d/${this.existePlantilla(empresa)?.id}/edit`;
    } else {
      urlRespuesta = `https://docs.google.com/document/d/${this.existePlantilla(empresa)?.id}/edit`;
    }
    window.open(urlRespuesta, '_blank', 'location=yes, height=570, width=520, scrollbars=yes, status=yes');
  }

  public actualizarPlantillas(): void {
    const automatismos = [];
    for (const key in this.formulario.controls){
      if (key.includes('automatismo-empresa-') &&  this.formulario.controls[key].value !== '' &&
         this.formulario.controls[key].value !== null){
          automatismos.push({
            id: this.formulario.controls[key].value,
            empresa: this.empresas.find(e => e.id === +key.split('-')[2]),
            catalogo: this.catalogo
           } as PlantillaAutomatismo
         );
      }
    }
    this.spinner.show();
    this.catalogoService.actualizarPlantillas(this.catalogo.id || '',
    {plantillas : automatismos, activo : this.formulario.get('activo')?.value}).subscribe(() => {
      this.spinner.hide();
      this.mensaje.showMensaje('Cambios guardados correctamente', '/catalogos');
    }, error => {
      this.spinner.hide();
      this.mensaje.showErrorPeticion(error.error);
    });
  }


}

<div class="row principal-content">
  <div class="col-md-12 col-lg-6">
    <img
      class="rounded mx-auto d-block img-principal"
      src="../../../assets/principal-photo.png"
      alt="principal-photo"
      height="90%"
      width="auto"
    />
  </div>
  <div class="col-md-12 col-lg-6">
    <div class="right-content">
      <h1>
        Bienvenido <br />
        a la transformación digital <br />
        en la gestión de personas
      </h1>
      <p class="mb-5 mt-5">
        Una aplicación que conecta personas. Estás entrando en un espacio de
        gestión común, que estandariza y automatiza procesos y mejora la
        eficiencia y la comunicación entre Negocio y Recursos Humanos.
      </p>
      <h2 class="mb-5 mt-5">Una solución digital que nos conecta</h2>

      <button *ngIf="!isLoggedIn() || sesionService.getSesionCaducada()===true" type="button" class="mb-1 mt-1 btn btn-danger btn-xl" (click)="newUser()">Regístrate como usuario</button>

    </div>
  </div>
</div>

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Constantes } from 'src/app/utils/constantes';

@Injectable({
  providedIn: 'root'
})
export class LineasVentaService {

  constructor(
    private http: HttpClient,
    private constantes: Constantes
  ) { }

  public getLineasVentaPorActividad(actividad: number[]): Observable<any> {
    const url: string = this.constantes.ENDPOINT_API_LINEAS_VENTA_ACT.replace('{idActividad}', actividad + '');
    return this.http.get(url);
  }

  public getLineasVenta(descriptionMode?: boolean): Observable<any>{
    const url: string = this.constantes.ENDPOINT_API_LINEAS_VENTA;
    const params = new HttpParams().set('descriptionMode', (descriptionMode === undefined) ? '' : String(descriptionMode));

    return this.http.get(url, {params});
  }

  public obtenerDetallesLineaVenta(idLinea: number): Observable<any> {
    const url: string = this.constantes.ENDPOINT_API_LINEAS_VENTA + '/' + idLinea;
    return this.http.get(url);
  }

}

import { Provincia } from './../../../modelos/provincia';
import {
  ChangeDetectorRef,
  Component,
  Injector,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { Convenio } from 'src/app/modelos/convenio';
import { TerritoriosService } from 'src/app/servicios/territorios/territorios.service';
import { Actividades } from 'src/app/modelos/actividades';
import { ActividadesService } from 'src/app/servicios/actividades/actividades.service';
import { ConveniosService } from 'src/app/servicios/convenios/convenios.service';
import { MensajeComponent } from '../../mensaje/mensaje.component';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-crearconvenio',
  templateUrl: './crearconvenio.component.html',
  styleUrls: ['./crearconvenio.component.css'],
})
export class CrearConvenioComponent implements OnInit {
  private spinner!: NgxSpinnerService;
  public title = '';
  public formulario!: FormGroup;
  public convenio!: Convenio;
  public listaAmbitos: any[] = [];
  public listaProvincias!: Provincia[];
  public listaActividades!: Actividades[];
  private conveniosService!: ConveniosService;
  public mostrarProvinciaFlag = true;
  public modoEdicion = false;
  public provinciasService!: TerritoriosService;
  public actividadesService!: ActividadesService;

  constructor(
    private fb: FormBuilder,
    private inject: Injector,
    public dialog: MatDialog,
    private router: Router,
    protected changeDetectorRef: ChangeDetectorRef,
    private acRoute: ActivatedRoute
  ) {
    this.loadInitialProperties();
  }

  @ViewChild('mensaje', { static: true })
  public mensaje!: MensajeComponent;

  loadInitialProperties(): void {
    this.spinner = this.inject.get<NgxSpinnerService>(NgxSpinnerService);
    this.conveniosService = this.inject.get<ConveniosService>(ConveniosService);

    this.provinciasService =
      this.inject.get<TerritoriosService>(TerritoriosService);

    this.actividadesService =
      this.inject.get<ActividadesService>(ActividadesService);
  }

  ngOnInit(): void {
    this.title = 'Crear Convenio';
    this.listaAmbitos = [
      { id: 1, descripcion: 'ESTATAL' },
      { id: 2, descripcion: 'AUTONÓMICO' },
      { id: 3, descripcion: 'PROVINCIAL' },
      { id: 4, descripcion: 'INTERPROVINCIAL' },
      { id: 5, descripcion: 'LOCAL' },
    ];
    this.convenio = this.initConvenio();
    this.formulario = this.initForm();
    const idConvenio = this.acRoute.snapshot.paramMap.get('idConvenio');
    if (idConvenio) {
      this.title = 'Editar Convenio';
      this.modoEdicion = true;
    }
    this.initServices(idConvenio);
  }

  initConvenio(): Convenio {
    return {
      descripcion: '',
      ambito: '',
      provincias: [],
      actividades: [],
    };
  }

  initForm(): FormGroup {
    return this.fb.group({
      descripcion: [
        this.convenio.descripcion,
        [Validators.required, Validators.maxLength(255)],
      ],
      ambito: [this.convenio.ambito, Validators.required],
      provincias: [this.convenio.provincias, Validators.required],
      actividades: [this.convenio.actividades, Validators.required],
    });
  }

  private initServices(idConvenio: string | null): void {
    const llamadas = [];
    llamadas.push(this.provinciasService.getProvincias());
    llamadas.push(this.actividadesService.getActividades());
    if (idConvenio) {
      llamadas.push(this.conveniosService.obtenerConvenio(idConvenio));
    }
    this.spinner.show();
    forkJoin(llamadas).subscribe(
      (resp) => {
        this.listaProvincias = resp[0] as Provincia[];
        this.listaActividades = resp[1] as Actividades[];
        if (this.modoEdicion) {
          this.convenio = resp[2] as Convenio;
          this.convenio.provincias = this.convenio.provincias?.map(
            (provincia) => {
              provincia.idDescripcion =
                provincia.id + ' - ' + provincia.descripcion;
              return provincia;
            }
          );
          this.formulario.controls.descripcion.setValue(
            this.convenio.descripcion
          );
          this.formulario.controls.ambito.setValue(
            this.listaAmbitos[Number(this.convenio.ambito) - 1]
          );
          this.formulario.controls.provincias.setValue(
            this.convenio.provincias
          );
          this.formulario.controls.actividades.setValue(
            this.convenio.actividades
          );
          this.mostrarProvincia();
          console.log(this.formulario);
        }
        this.spinner.hide();
      },
      () => {
        this.spinner.hide();
      }
    );
  }

  mostrarProvincia(): void {
    const ambito = this.formulario.get('ambito')?.value?.descripcion;
    if (ambito === 'ESTATAL') {
      this.mostrarProvinciaFlag = false;
      this.formulario.controls.provincias.clearValidators();
      this.formulario.controls.provincias.reset();
    } else {
      this.mostrarProvinciaFlag = true;
      this.formulario.controls.provincias.setValidators(Validators.required);
      this.formulario.controls.provincias.markAsUntouched();
      this.changeDetectorRef.detectChanges();
    }
  }

  volverListadoConvenios(): void {
    this.router.navigate(['/convenios']);
  }

  crearConvenio(): void {
    this.formulario.markAllAsTouched();
    if (this.formulario.valid) {
      const convenio = this.crearObjetoConvenio();
      this.spinner.show();
      const llamadas = [];
      if (this.modoEdicion) {
        llamadas.push(this.conveniosService.editarConvenio(convenio));
      } else {
        llamadas.push(this.conveniosService.altaConvenio(convenio));
      }
      forkJoin(llamadas).subscribe(
        (data: any) => {
          this.spinner.hide();
          this.mensaje.showMensaje(
            'Operación realizada correctamente...',
            this.redirigir()
          );
        },
        (error) => {
          this.spinner.hide();
          this.mensaje.showErrorPeticion(error.error);
        }
      );
    }
  }

  private redirigir(): string {
    return '/convenios';
  }

  private crearObjetoConvenio(): Convenio {
    return {
      id: this.convenio.id,
      descripcion: this.formulario.get('descripcion')?.value,
      ambito: this.formulario.get('ambito')?.value,
      provincias: this.formulario.get('provincias')?.value,
      actividades: this.formulario.get('actividades')?.value,
    };
  }
}

<div class="container">
  <fieldset class="filters">
    <legend>Buscar por ...</legend>
    <form [formGroup]="filters">
      <div class="row">
        <div class="mb-3 col-sm-12 col-lg-4">
          <label for="peticion" class="form-label">Tipo Petición</label>
          <p-multiSelect
            id="peticion"
            filterPlaceHolder="Buscar"
            [defaultLabel]="'Seleccionar petición'"
            [virtualScroll]="true"
            [options]="peticionesList"
            optionLabel="descripcion"
            optionValue="id"
            formControlName="tipoPeticion"
            styleClass="form-control p-0"
          ></p-multiSelect>
        </div>
        <div class="mb-3 col-sm-12 col-lg-4">
          <label for="destino" class="form-label">Area destino</label>
          <p-multiSelect
            id="destino"
            filterPlaceHolder="Buscar"
            [defaultLabel]="'Seleccionar destino'"
            [options]="constantes.listaAreaDestino"
            formControlName="areaDestino"
            [filter]="false"
            styleClass="form-control p-0"
          ></p-multiSelect>
        </div>
        <div class="mb-3 col-sm-12 col-lg-4">
          <label for="flujo" class="form-label">Tipo Flujo</label>
          <p-multiSelect
            id="flujo"
            filterPlaceHolder="Buscar"
            [defaultLabel]="'Seleccionar flujo'"
            [options]="flujoList"
            [filter]="false"
            formControlName="tipoFlujo"
            styleClass="form-control p-0"
          ></p-multiSelect>
        </div>
      </div>

      <div class="row">
        <div class="mb-3 col-sm-6 col-lg-3">
          <label for="artica" class="form-label">Ártica</label>
          <p-dropdown
            id="artica"
            [options]="selectOptions"
            optionLabel="descripcion"
            optionValue="id"
            formControlName="artica"
            styleClass="form-control p-0"
          ></p-dropdown>
        </div>
        <div class="mb-3 col-sm-6 col-lg-3">
          <label for="activo" class="form-label">Activo</label>
          <p-dropdown
            id="activo"
            [options]="selectOptions"
            optionLabel="descripcion"
            optionValue="id"
            formControlName="activo"
            styleClass="form-control p-0"
          ></p-dropdown>
        </div>
        <div class="mb-3 col-sm-6 col-lg-3">
          <label for="especifico" class="form-label">Datos especificos</label>
          <p-dropdown
            id="especifico"
            [options]="selectOptions"
            optionLabel="descripcion"
            optionValue="id"
            formControlName="datosEspecificos"
            styleClass="form-control p-0"
          ></p-dropdown>
        </div>
        <div class="mb-3 col-sm-6 col-lg-3">
          <label for="efectividad" class="form-label">Datos efectividad</label>
          <p-dropdown
            id="efectividad"
            [options]="selectOptions"
            optionLabel="descripcion"
            optionValue="id"
            formControlName="datosEfectividad"
            styleClass="form-control p-0"
          ></p-dropdown>
        </div>
      </div>

      <div class="row">
        <div class="mb-3 col-sm-6 col-lg-3">
          <label for="impacto" class="form-label">Impacto</label>
          <p-multiSelect
            id="impacto"
            filterPlaceHolder="Buscar"
            [defaultLabel]="'Seleccionar impacto'"
            [options]="impactosList"
            optionLabel="descripcion"
            optionValue="id"
            [filter]="false"
            formControlName="impacto"
            styleClass="form-control p-0"
          ></p-multiSelect>
        </div>
        <div class="mb-3 col-sm-6 col-lg-3">
          <label for="fecha" class="form-label"
            >Fecha estimada resolución</label
          >
          <p-dropdown
            id="fecha"
            [options]="selectOptions"
            optionLabel="descripcion"
            optionValue="id"
            formControlName="fechaResolucion"
            styleClass="form-control p-0"
          ></p-dropdown>
        </div>
        <div class="mb-3 col-sm-6 col-lg-3">
          <label for="sla" class="form-label">Calcular SLA</label>
          <p-dropdown
            id="sla"
            [options]="selectOptions"
            optionLabel="descripcion"
            optionValue="id"
            formControlName="calcularSLA"
            styleClass="form-control p-0"
          ></p-dropdown>
        </div>
        <div class="mb-3 col-sm-6 col-lg-3">
          <label for="escalado" class="form-label">Escalado</label>
          <p-dropdown
            id="escalado"
            [options]="selectOptions"
            optionLabel="descripcion"
            optionValue="id"
            formControlName="escalado"
            styleClass="form-control p-0"
          ></p-dropdown>
        </div>
      </div>
    </form>
  </fieldset>
  <div class="actions">
    <button
      class="btn limpiarBoton customButton fa fa-eraser"
      (click)="clean()"
    >
      Limpiar
    </button>
    <button
      type="submit"
      class="btn btn-outline-primary customButton fa fa-search buscar"
      (click)="search()"
    >
      Buscar
    </button>
  </div>
</div>

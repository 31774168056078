import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { CrearusuarioComponent } from './componentes/usuarios/crearusuario/crearusuario.component';
import { UsuariosPrincipalComponent } from './componentes/usuarios/usuarios-principal/usuarios-principal.component';
import { TablaGrupossoporteComponent } from './componentes/grupossoporte/tabla-grupossoporte/tabla-grupossoporte.component';
import { AdministradorGuard } from './servicios/auth-guard/administrador-guard.guard';
import { CreargruposoporteComponent } from './componentes/grupossoporte/creargruposoporte/creargruposoporte.component';
import { CrearPeticionIdentificacionComponent } from './componentes/peticiones/crear-peticion/crear-peticion-identificacion/crear-peticion-identificacion.component';
import { CrearPeticionAdjuntarFicherosComponent } from './componentes/peticiones/crear-peticion/crear-peticion-adjuntar-ficheros/crear-peticion-adjuntar-ficheros.component';
import { CrearPeticionObservacionesComponent } from './componentes/peticiones/crear-peticion/crear-peticion-observaciones/crear-peticion-observaciones.component';
import { CrearPeticionDatosEspecificosComponent } from './componentes/peticiones/crear-peticion/crear-peticion-datos-especificos/crear-peticion-datos-especificos.component';
import { CrearPeticionNotificacionesComponent } from './componentes/peticiones/crear-peticion/crear-peticion-notificaciones/crear-peticion-notificaciones.component';
import { CrearPeticionConfirmacionComponent } from './componentes/peticiones/crear-peticion/crear-peticion-confirmacion/crear-peticion-confirmacion.component';
import { RolesComponent } from './componentes/roles/roles.component';
import { EmpresasComponent } from './componentes/empresas/empresas.component';
import { PrincipalComponent } from './componentes/principal/principal.component';
import { PeticionesPrincipalComponent } from './componentes/peticiones/peticiones-principal/peticiones-principal.component';
import { PeticionActuacionPrincipalComponent } from './componentes/peticion-actuacion/peticion-actuacion-principal/peticion-actuacion-principal.component';
import { PeticionGuard } from './servicios/peticion-guard/peticion.guard';
import { LandpagePeticionComponent } from './componentes/peticiones/landpage-peticion/landpage-peticion.component';
import { AclaracionesComponent } from './componentes/peticiones/aclaraciones/aclaraciones.component';
import { CierrePeticionComponent } from './componentes/peticiones/cierre-peticion/cierre-peticion.component';
import { ReabrirPeticionComponent } from './componentes/peticiones/reabrir-peticion/reabrir-peticion.component';
import { AutorizacionesComponent } from './componentes/peticiones/autorizaciones/autorizaciones.component';
import { AdministracionGeneralGuard } from './servicios/administracion-general-guard/administrador-general.guard';
import { AdministradorPeticionesGuard } from './servicios/administrador-peticiones.guard/administrador-peticiones.guard';
import { IndicadoresRedimientoGuard } from './servicios/indicadores-rendimiento-guard/indicadores-rendimiento-guard';
import { NotificacionesPrincipalComponent } from './componentes/notificaciones/notificaciones-principal/notificaciones-principal.component';
import { EstadosComponent } from './componentes/estados/estados.component';
import { CrearNotificacionComponent } from './componentes/notificaciones/crear-notificacion/crear-notificacion.component';
import { TablaCatalogosComponent } from './componentes/catalogos/tabla-catalogos/tabla-catalogos.component';
import { CrearCatalogoComponent } from './componentes/catalogos/crear-catalogo/crear-catalogo.component';
import { CatalogoDatosEspecificosComponent } from './componentes/catalogos/catalogo-datos-especificos/catalogo-datos-especificos.component';
import { AccionesComponent } from './componentes/acciones/acciones.component';
import { PuestosComponent } from './componentes/puestos/puestos.component';
import { InformacionesComponent } from './componentes/peticiones/informaciones/informaciones.component';
import { AutomatismosComponent } from './componentes/catalogos/automatismos/automatismos.component';
import { InformesComponent } from './componentes/informes/informes.component';
import { ConveniosPrincipalComponent } from './componentes/convenios/convenios-principal/convenios-principal.component';
import { CrearConvenioComponent } from './componentes/convenios/crearconvenio/crearconvenio.component';
import { AuditoriaPrincipalComponent } from './componentes/auditoria/auditoria-principal/auditoria-principal.component';
import { AuditoriaGuard } from './servicios/auditoria-guard/auditoria.guard';
import { SlaPrincipalComponent } from './componentes/sla/sla-principal/sla-principal.component';
import { AltasComponent } from './componentes/nuevo-usuario/altas.component';
import { InformacionEmpleadoComponent } from './componentes/informacion-empleado/informacion-empleado.component';

export const routes: Routes = [
  { path: '', component: PrincipalComponent },
  { path: 'altas', component: AltasComponent },
  {
    path: 'usuarios',
    component: UsuariosPrincipalComponent,
    canActivate: [AdministradorGuard],
  },
  {
    path: 'usuarios/crear',
    component: CrearusuarioComponent,
    canActivate: [AdministradorGuard],
  },
  {
    path: 'usuarios/actualizar/:email',
    component: CrearusuarioComponent,
    canActivate: [AdministradorGuard],
  },
  {
    path: 'grupos-soporte',
    component: TablaGrupossoporteComponent,
    canActivate: [AdministracionGeneralGuard],
  },
  {
    path: 'grupos-soporte/crear',
    component: CreargruposoporteComponent,
    canActivate: [AdministracionGeneralGuard],
  },
  {
    path: 'grupos-soporte/editar/:id',
    component: CreargruposoporteComponent,
    canActivate: [AdministracionGeneralGuard],
  },
  {
    path: 'empresas',
    component: EmpresasComponent,
    canActivate: [AdministracionGeneralGuard],
  },
  {
    path: 'peticiones',
    component: PeticionesPrincipalComponent,
    canActivate: [AdministradorPeticionesGuard],
  },
  {
    path: 'peticiones/crear/identificacion',
    component: CrearPeticionIdentificacionComponent,
    canActivate: [AdministradorPeticionesGuard],
  },
  {
    path: 'peticiones/crear/identificacion/:id',
    component: CrearPeticionIdentificacionComponent,
    canActivate: [PeticionGuard],
  },
  {
    path: 'peticiones/modificar/identificacion/:id',
    component: CrearPeticionIdentificacionComponent,
    canActivate: [PeticionGuard],
  },
  {
    path: 'peticiones/crear/observaciones',
    component: CrearPeticionObservacionesComponent,
    canActivate: [AdministradorPeticionesGuard],
  },
  {
    path: 'peticiones/crear/datos',
    component: CrearPeticionDatosEspecificosComponent,
    canActivate: [AdministradorPeticionesGuard],
  },
  {
    path: 'peticiones/crear/adjuntar',
    component: CrearPeticionAdjuntarFicherosComponent,
    canActivate: [AdministradorPeticionesGuard],
  },
  {
    path: 'peticiones/crear/notificaciones',
    component: CrearPeticionNotificacionesComponent,
    canActivate: [AdministradorPeticionesGuard],
  },
  {
    path: 'peticiones/crear/confirmacion',
    component: CrearPeticionConfirmacionComponent,
    canActivate: [AdministradorPeticionesGuard],
  },
  {
    path: 'peticiones/crear/confirmacion/:id',
    component: CrearPeticionConfirmacionComponent,
    canActivate: [PeticionGuard],
  },
  {
    path: 'roles',
    component: RolesComponent,
    canActivate: [AdministracionGeneralGuard],
  },
  {
    path: 'estados',
    component: EstadosComponent,
    canActivate: [AdministracionGeneralGuard],
  },
  {
    path: 'peticiones/actuaciones',
    component: PeticionActuacionPrincipalComponent,
    canActivate: [AdministradorPeticionesGuard],
  },
  {
    path: 'peticiones/:id',
    component: LandpagePeticionComponent,
    canActivate: [PeticionGuard],
  },
  {
    path: 'peticiones/:id/aclaraciones',
    component: AclaracionesComponent,
    canActivate: [PeticionGuard],
  },
  {
    path: 'peticiones/:id/cierre-peticion/:motivo',
    component: CierrePeticionComponent,
    canActivate: [PeticionGuard],
  },
  {
    path: 'reabrir/:id',
    component: ReabrirPeticionComponent,
    canActivate: [PeticionGuard],
  },
  {
    path: 'peticiones/:id/autorizaciones',
    component: AutorizacionesComponent,
    canActivate: [PeticionGuard],
  },
  {
    path: 'peticiones/:id/informaciones',
    component: InformacionesComponent,
    canActivate: [PeticionGuard],
  },
  {
    path: 'notificaciones',
    component: NotificacionesPrincipalComponent,
    canActivate: [AdministracionGeneralGuard],
  },
  {
    path: 'notificaciones/crear',
    component: CrearNotificacionComponent,
    canActivate: [AdministracionGeneralGuard],
  },
  {
    path: 'notificaciones/:idTipoPeticion/acciones/:idAccion',
    component: CrearNotificacionComponent,
    canActivate: [AdministracionGeneralGuard],
  },
  {
    path: 'catalogos',
    component: TablaCatalogosComponent,
    canActivate: [AdministracionGeneralGuard],
  },
  {
    path: 'catalogos/crear',
    component: CrearCatalogoComponent,
    canActivate: [AdministracionGeneralGuard],
  },
  {
    path: 'catalogos/:id',
    component: CrearCatalogoComponent,
    canActivate: [AdministracionGeneralGuard],
  },
  {
    path: 'catalogos/:id/automatismos',
    component: AutomatismosComponent,
    canActivate: [AdministracionGeneralGuard],
  },
  {
    path: 'catalogos/:id/datos-especificos',
    component: CatalogoDatosEspecificosComponent,
    canActivate: [AdministracionGeneralGuard],
  },
  {
    path: 'acciones',
    component: AccionesComponent,
    canActivate: [AdministracionGeneralGuard],
  },
  {
    path: 'puestos',
    component: PuestosComponent,
    canActivate: [AdministracionGeneralGuard],
  },
  {
    path: 'informes',
    component: InformesComponent,
    canActivate: [IndicadoresRedimientoGuard],
  },
  {
    path: 'sla',
    component: SlaPrincipalComponent,
    canActivate: [AdministracionGeneralGuard],
  },
  {
    path: 'convenios',
    component: ConveniosPrincipalComponent,
    canActivate: [AdministracionGeneralGuard],
  },
  {
    path: 'convenios/crear',
    component: CrearConvenioComponent,
    canActivate: [AdministracionGeneralGuard],
  },
  {
    path: 'convenios/:idConvenio',
    component: CrearConvenioComponent,
    canActivate: [AdministracionGeneralGuard],
  },
  {
    path: 'informacion-empleado/:dni',
    component: InformacionEmpleadoComponent,
    canActivate: [AdministracionGeneralGuard],
  },
  {
    path: 'auditoria',
    component: AuditoriaPrincipalComponent,
    canActivate: [AuditoriaGuard],
  },
  {
    path: 'auditoria/:peticion',
    component: AuditoriaPrincipalComponent,
    canActivate: [AuditoriaGuard],
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy }],
})
export class AppRoutingModule {}

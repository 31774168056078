import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Peticion } from 'src/app/modelos/peticion';
import { PeticionService } from 'src/app/servicios/peticion/peticion.service';
import { MensajeComponent } from '../../mensaje/mensaje.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { SesionService } from '../../../servicios/sesion/sesion.service';
import { Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { Informacion } from '../../../modelos/informacion';
import { ButtonModalInfo, ModalInfo } from 'src/app/modelos/moda-info';
import { ModalInfoComponent } from '../../modals/modal-info/modal-info.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-autorizaciones',
  templateUrl: './autorizaciones.component.html',
})
export class AutorizacionesComponent implements OnInit {
  @ViewChild('mensaje', { static: false })
  public mensaje!: MensajeComponent;

  motivosAutorizaciones = ['AUTORIZADA', 'DENEGADA'];
  informaciones: Informacion[] = [];
  peticion!: Peticion;
  formularioAutorizar!: FormGroup;
  contadorAutorizar!: number;
  esAutorizante = false;
  permisoAutorizar = false;
  idPeticion!: string;
  textoAutorizar = 'Autorizar';

  idBorrador!: number;
  autorizarBorrador: any;
  borrador!: boolean;

  constructor(
    private peticionService: PeticionService,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private sesionService: SesionService,
    private router: Router,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.contadorAutorizar = 0;
    this.loadServices();
  }

  private loadServices(): void {
    const llamadas = [];
    this.peticion = this.peticionService.getPeticion();

    this.idPeticion = this.peticion.idPeticion || '';
    llamadas.push(this.peticionService.obtenerInformaciones(this.idPeticion));
    llamadas.push(
      this.peticionService.obtenerPeticion(this.peticion.idPeticion || '')
    );

    forkJoin(llamadas).subscribe(
      (resp: any) => {
        this.informaciones = resp[0];
        this.calcularPermisos();
        this.initForm();
        this.autorizarBorrador = resp[1];
        if (this.autorizarBorrador.borradorAutorizacion === 1) {
          this.mostrarAutorizarBorrador();
        } else {
          this.idBorrador = 0;
        }

        this.spinner.hide();
      },
      (error) => {
        console.error(error);
        this.spinner.hide();
      }
    );
  }

  calcularPermisos(): void {
    const usuarioLogado = this.sesionService.getUser();

    this.esAutorizante =
      (this.peticion.idAutorizante !== null &&
        this.peticion.idAutorizante === usuarioLogado.email) ||
      this.sesionService.isAdmin();
    this.esAutorizante = this.esAutorizante || usuarioLogado.rol?.id === 3;
    if (this.peticion.borradorAutorizacion != 1) {
      this.permisoAutorizar = this.peticion.fechaAutorizacion === null;
    } else {
      this.permisoAutorizar = true;
    }
  }

  private initForm(): void {
    this.formularioAutorizar = this.fb.group({
      msg: [
        this.permisoAutorizar === true
          ? { value: '', disabled: false }
          : { value: this.peticion.observacionAutorizacion, disabled: true },
        [Validators.required, Validators.maxLength(3000)],
      ],
      motivo: [
        this.peticion.fechaAutorizacion === null
          ? this.motivosAutorizaciones[0]
          : this.peticion.motivoAutorizacion,
        [Validators.required],
      ],
    });

    if (
      this.formularioAutorizar.get('msg')?.value &&
      this.formularioAutorizar.get('msg')?.value !== null
    ) {
      this.contadorAutorizar =
        this.formularioAutorizar.get('msg')?.value.length;
    }
  }

  public onKeyUp(event: any): void {
    this.contadorAutorizar = event.target.value.length;
  }

  public onKeyPress(event: any): any {
    if (event.keyCode === 39 || event.keyCode === 96 || event.keyCode === 180) {
      return false;
    }
    return true;
  }

  public limpiar(): void {
    this.formularioAutorizar.controls.msg.setValue('');
    this.contadorAutorizar = 0;
  }

  public autorizar(): void {
    this.spinner.show();
    this.peticionService
      .autorizarPeticion(this.idPeticion, {
        motivoAutorizacion: this.formularioAutorizar.get('motivo')?.value,
        observacion: this.formularioAutorizar.get('msg')?.value,
      })
      .subscribe(
        () => {
          this.mensaje.showMensaje('Autorización realizada correctamente');
          this.spinner.hide();
          this.router.navigate([`/peticiones/actuaciones`]);
        },
        (errorAutorizar: any) => {
          console.error(errorAutorizar);
          this.mensaje.showError(errorAutorizar);
          this.spinner.hide();
        }
      );
  }

  public cambiarTextoBoton(): void {
    if (this.formularioAutorizar.get('motivo')?.value === 'AUTORIZADA') {
      this.textoAutorizar = 'Autorizar';
    } else {
      this.textoAutorizar = 'Denegar';
    }
  }

  public volver(): void {
    window.history.back();
  }

  public controlFormAutorizar(): void {
    if (this.formularioAutorizar.get('msg')?.value === '') {
      this.contadorAutorizar = 0;
    } else {
      this.contadorAutorizar = this.formularioAutorizar
        .get('msg')
        ?.value.trim().length;
    }
  }

  public crearAutorizarBorrador(): void {
    this.spinner.show();
    this.peticionService
      .autorizarPeticionBorrador(this.idPeticion, {
        motivoAutorizacion: this.formularioAutorizar.get('motivo')?.value,
        observacion: this.formularioAutorizar.get('msg')?.value,
      })
      .subscribe((resp: any) => {
        this.autorizarBorrador = resp;
        this.loadServices();
        this.spinner.hide();
      });
    this.borrador = true;
  }

  public eliminarAutorizarBorrador(): void {
    const data = {
      title: 'Eliminar borrador',
      msg: `¿Desea eliminar al borrador?`,
      buttons: [
        { title: 'Aceptar', class: 'btn-success', action: '1' },
        { title: 'Cancelar', class: 'btn-danger' },
      ] as ButtonModalInfo[],
    } as ModalInfo;
    this.dialog
      .open(ModalInfoComponent, { disableClose: true, autoFocus: false, data })
      .afterClosed()
      .subscribe((resp) => {
        if (resp) {
          this.spinner.show();
          this.peticionService
            .eliminarAutorizarPeticionBorrador(this.peticion.idPeticion)
            .subscribe(() => {
              this.mensaje.showMensaje(
                'Borrador eliminado satisfactoriamente.'
              );
              this.loadServices();
              this.spinner.hide();
            });
          this.formularioAutorizar.controls.msg.setValue('');
          this.autorizarBorrador = undefined;
          this.borrador = false;
        }
      });
  }

  public mostrarAutorizarBorrador() {
    if (this.autorizarBorrador.borradorAutorizacion === 1) {
      this.formularioAutorizar.controls.msg.setValue(
        this.autorizarBorrador.observacionAutorizacion
      );
      this.formularioAutorizar.controls.motivo.setValue(
        this.autorizarBorrador.motivoAutorizacion
      );
      this.borrador = true;
    } else {
      this.formularioAutorizar.controls.msg.setValue('');
      this.borrador = false;
    }
  }
}

<ngx-spinner></ngx-spinner>

<div class="container espacio">
    <div class="d-flex justify-content-between mb-3">
        <h1>Gestión de Estados</h1>
    </div>

    <div class="card">
        <p-table id="tabla-estados" #dt1 [style]="{'overflow':'auto!important'}" [resizableColumns]="true" columnResizeMode="expand" [responsive]="true" [value]="estados" [showCurrentPageReport]="true" dataKey="id" styleClass="p-datatable-responsive-demo" [rows]="rows"
            [totalRecords]="estadosTotales" [columns]="cols" [autoLayout]="true" [lazy]="true" (onLazyLoad)="nextPage($event, dt1)" [paginator]="true" [globalFilterFields]="cols" sortField="id" currentPageReportTemplate="Página {{paginaActual}} de {{paginasTotales}}"
            [rowsPerPageOptions]="estadosPorPagina">


            <ng-template pTemplate="caption" class="caption">
              <button class="btn customButton boton-excel" (click)="exportarDatos()"><i class="fa fa-file-excel-o" aria-hidden="true"></i>  EXPORTAR</button>
            </ng-template>

            <ng-template pTemplate="header">
              <tr>
                  <th scope="{{cols[0].field}}" pSortableColumn="{{cols[0].field}}">
                    <div class="p-d-flex p-jc-between p-ai-center" pTooltip="{{cols[0].tool}}" tooltipPosition="top">
                        {{cols[0].header}}
                        <p-sortIcon ariaLabel="Activar para ordenar" ariaLabelDesc="Activar para ordenar en orden descendiente" ariaLabelAsc="Activar para ordenar en orden ascendiente" field="{{cols[0].field}}"></p-sortIcon>
                    </div>
                </th>
                <th scope="{{cols[1].field}}" pSortableColumn="{{cols[1].field}}">
                  <div class="p-d-flex p-jc-between p-ai-center" pTooltip="{{cols[1].tool}}" tooltipPosition="top">
                      {{cols[1].header}}
                      <p-sortIcon ariaLabel="Activar para ordenar" ariaLabelDesc="Activar para ordenar en orden descendiente" ariaLabelAsc="Activar para ordenar en orden ascendiente" field="{{cols[1].field}}"></p-sortIcon>
                  </div>
              </th>
              <th scope="{{cols[2].field}}" pSortableColumn="{{cols[2].field}}">
                <div class="p-d-flex p-jc-between p-ai-center" pTooltip="{{cols[2].tool}}" tooltipPosition="top">
                    {{cols[2].header}}
                </div>
            </th>
            <th scope="{{cols[3].field}}" pSortableColumn="{{cols[3].field}}">
              <div class="p-d-flex p-jc-between p-ai-center" pTooltip="{{cols[3].tool}}" tooltipPosition="top">
                  {{cols[3].header}}
              </div>
          </th>
              </tr>
          </ng-template>
          <ng-template pTemplate="body" let-estado let-i="rowIndex">
              <tr>
                  <td><span class="p-column-title">ID</span> {{estado.id}}</td>
                  <td><span class="p-column-title">ESTADO</span> {{estado.nombre}}</td>
                  <td><span class="p-column-title">DESCRIPCIÓN</span> {{estado.descripcion}}</td>
                  <td><span class="p-column-title">COMENTARIOS</span> {{estado.comentarios}}</td>
              </tr>
              <hr class="item-list-separator">
          </ng-template>
          <ng-template pTemplate="emptymessage">
              <tr>
                  <td colspan="7">No hay estados</td>
              </tr>
          </ng-template>
          <ng-template pTemplate="paginatorleft">
          </ng-template>
          <ng-template pTemplate="paginatorright">
              <p class="pageFooterLeft">Estados totales: {{estadosTotales}}</p>
          </ng-template>

        </p-table>
    </div>
</div>

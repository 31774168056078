import { Injectable } from '@angular/core';
import { Usuario } from 'src/app/modelos/usuario';
import { Constantes } from 'src/app/utils/constantes';
import { RolPermisos } from '../../modelos/rol-permisos';
import { SubjectsService } from '../subjects/subjects.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { UsuarioTerritorio } from '../../modelos/usuario';
import { GoogleCredentials } from 'src/app/modelos/google-login';
@Injectable({
  providedIn: 'root',
})
export class SesionService {
  public static readonly SESSION_STORAGE_KEY: string = 'idToken';
  public static readonly USER_STORAGE_KEY: string = 'usuario';
  public static readonly LOGGED_STORAGE_KEY: string = 'logged';
  public static readonly PERMISO_STORAGE_KEY: string = 'permiso';
  public static readonly TERRITORIOS_STORAGE_KEY: string = 'territorios';

  public static readonly GOOGLE_CREDENTIALS_STORAGE_KEY: string =
    'googleCredentials';

  private sesionCaducada = true;

  constructor(
    private constantes: Constantes,
    private subjectsService: SubjectsService
  ) {}

  public setUser(user: Usuario): void {
    localStorage.setItem(SesionService.USER_STORAGE_KEY, JSON.stringify(user));
  }

  public getUser(spinner?: NgxSpinnerService): Usuario {
    const usuario = JSON.parse(
      localStorage.getItem(SesionService.USER_STORAGE_KEY) + ''
    );
    if (this.sesionCaducada === true && usuario) {
      this.sesionCaducada = true;
      this.subjectsService.emitirSesionCaducada({
        error: 'Su sesión ha caducado. Tiene que inciar sesión de nuevo',
        status: 403,
      });
      spinner?.hide();
    }
    return usuario;
  }

  public setSesionCaducada(sesionCaducada: boolean): void {
    this.sesionCaducada = sesionCaducada;
  }

  public getSesionCaducada(): boolean {
    return this.sesionCaducada;
  }

  public setPermisos(permiso: RolPermisos): void {
    localStorage.setItem(
      SesionService.PERMISO_STORAGE_KEY,
      JSON.stringify(permiso)
    );
  }

  public getPermisos(): RolPermisos {
    return JSON.parse(
      localStorage.getItem(SesionService.PERMISO_STORAGE_KEY) + ''
    );
  }

  public setTerritorios(territorios: UsuarioTerritorio[]): void {
    localStorage.setItem(
      SesionService.TERRITORIOS_STORAGE_KEY,
      JSON.stringify(territorios)
    );
  }

  public getTerritorios(): UsuarioTerritorio[] {
    return JSON.parse(
      localStorage.getItem(SesionService.TERRITORIOS_STORAGE_KEY) + ''
    );
  }

  public setIdToken(idToken: string): void {
    localStorage.setItem(SesionService.SESSION_STORAGE_KEY, idToken);
  }

  public getIdToken(): string {
    return localStorage.getItem(SesionService.SESSION_STORAGE_KEY) + '';
  }

  public setGoogleCredentials(googleCredentials: GoogleCredentials): void {
    localStorage.setItem(
      SesionService.GOOGLE_CREDENTIALS_STORAGE_KEY,
      JSON.stringify(googleCredentials)
    );
  }
  public getGoogleCredentials(): GoogleCredentials {
    return JSON.parse(
      localStorage.getItem(SesionService.GOOGLE_CREDENTIALS_STORAGE_KEY) + ''
    );
  }

  public isGoogleUserSignedIn(): boolean {
    return localStorage.getItem(SesionService.LOGGED_STORAGE_KEY) === 'true';
  }

  public setGoogleUserSignedIn(signed: boolean): void {
    localStorage.setItem(SesionService.LOGGED_STORAGE_KEY, signed + '');
  }

  public isAdmin(): boolean {
    return (
      this.getUser() != null &&
      (this.getUser().rol?.id === this.constantes.ID_ADMINISTRADOR ||
        this.getUser().rol?.id === this.constantes.ID_ADMINISTRADOR_LOCAL)
    );
  }
}

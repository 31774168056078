<ngx-spinner></ngx-spinner>

<div class="container espacio">
  <div class="d-flex justify-content-between mb-3">
    <h1>Listado de SLAs</h1>
  </div>
  <div class="card">
    <div class="borde">
      <!-- Tabla de sla -->
      <app-sla></app-sla>
    </div>
  </div>
  <hr />
  <div class="d-flex justify-content-between mb-3">
    <h1>Listado de Prioridades</h1>
  </div>
  <div class="card">
    <div class="borde">
      <!-- Tabla de prioridades -->
      <app-prioridades></app-prioridades>
    </div>
  </div>
</div>

<div class="container">
  <fieldset>
    <legend>Buscar por ...</legend>
    <form [formGroup]="formulario" *ngIf="formulario">
      <div class="row">
        <div class="mb-3 col-sm-12 col-lg-6">
          <div>
            <label for="idpeticion" class="form-label">ID Petición</label>
            <div class="col-sm-12 col-lg-7 p-0">
              <input
                type="text"
                maxlength="15"
                autocomplete="off"
                class="form-control"
                id="idpeticion"
                name="idpeticion"
                formControlName="idPeticion"
                placeholder="Introduzca ID de Peticion"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="mb-3 col-sm-12 col-lg-4">
          <label for="anio" class="form-label">Año</label>
          <p-dropdown
            id="anio"
            [options]="listaAnios"
            styleClass="form-control p-0"
            formControlName="anio"
          >
          </p-dropdown>
        </div>
        <div class="mb-3 col-sm-12 col-lg-4">
          <label for="area" class="form-label">Área Destino</label>
          <p-multiSelect
            id="area"
            filterPlaceHolder="Buscar"
            [defaultLabel]="'Selección de áreas'"
            [virtualScroll]="true"
            [options]="listaAreas"
            formControlName="areas"
            styleClass="form-control p-0"
          ></p-multiSelect>
        </div>
        <div class="mb-3 col-sm-12 col-lg-4">
          <label for="area" class="form-label">Tipos Petición</label>
          <p-multiSelect
            id="area"
            filterPlaceHolder="Buscar"
            [defaultLabel]="'Selección de tipos de peticiones'"
            [virtualScroll]="true"
            [options]="listaTiposPeticiones"
            formControlName="tipos"
            optionLabel="descripcion"
            [filter]="true"
            styleClass="form-control p-0"
          ></p-multiSelect>
        </div>
      </div>
      <div class="row">
        <div class="mb-3 col-sm-12 col-lg-4">
          <label for="estados" class="form-label">Estados</label>
          <p-multiSelect
            id="estados"
            filterPlaceHolder="Buscar"
            [defaultLabel]="'Selección de estados'"
            [virtualScroll]="true"
            [options]="listaEstados"
            formControlName="estados"
            optionLabel="nombre"
            [filter]="true"
            styleClass="form-control p-0"
          ></p-multiSelect>
        </div>
        <div class="mb-3 mt-1 col-sm-12 col-lg-8">
          <label for="email" class="form-label">Email Destinatarios</label>
          <mat-chip-list #chipList aria-label="Selección Emails">
            <div class="col-sm-12 col-lg-7 p-0">
              <input
                type="text"
                autocomplete="off"
                class="form-control"
                id="email"
                name="email"
                formControlName="email"
                placeholder="Introduzca Emails a buscar"
                (input)="buscarEmails($event)"
              />
              <small
                class="form-text text-danger"
                *ngIf="
                  listaEmails.length === 0 &&
                  emailsBuscados &&
                  this.formulario.get('emails')?.value.length === 0 &&
                  this.formulario.get('email')?.value !== '' &&
                  this.formulario.get('email')?.value.length > 3
                "
                >* No se han encontrado resultados</small
              >
            </div>
            <div class="col-sm-12 col-lg-12 mb-4">
              <mat-chip
                class="selected-chip"
                *ngFor="
                  let emailInput of this.formulario.get('emails')?.value;
                  let i = index
                "
                [removable]="true"
                (removed)="removeEmailForm(emailInput, i)"
              >
                {{ emailInput }}
                <mat-icon matChipRemove>X</mat-icon>
              </mat-chip>
              <small
                class="form-text text-danger"
                *ngIf="
                  listaEmails.length === 0 &&
                  emailsBuscados &&
                  this.formulario.get('emails')?.value.length > 0 &&
                  this.formulario.get('email')?.value !== '' &&
                  this.formulario.get('email')?.value.length > 3
                "
                >* No se han encontrado resultados</small
              >
            </div>
            <div class="col-sm-12 col-lg-12">
              <mat-chip-list>
                <mat-chip
                  *ngFor="let email of listaEmails; let i = index"
                  [selected]="email.state"
                  selectable
                  multiple
                  [removable]="true"
                  (removed)="removeEmail(i)"
                  [value]="email"
                  [multiple]="true"
                  (click)="email.state = !email.state; addEmail(email, i)"
                >
                  {{ email.email }}
                  <mat-icon matChipRemove>X</mat-icon>
                </mat-chip>
              </mat-chip-list>
            </div>
          </mat-chip-list>
        </div>
      </div>
      <div class="row">
        <div class="ml-auto">
          <div class="mb-3 col-sm-12 col-lg-12">
            <div class="p-field-checkbox">
              <p-checkbox
                styleClass="p-0"
                [binary]="true"
                formControlName="ocultarPeticiones"
                id="ocultarPeticiones"
                name="ocultarPeticiones"
                ngDefaultControl
                label="Ocultar Peticiones Cerradas"
              ></p-checkbox>
            </div>
          </div>
        </div>
      </div>
      <ng-container *ngIf="avanzado">
        <div class="row">
          <div class="mb-3 col-sm-12 col-lg-4">
            <label for="actividades" class="form-label"
              >Actividades / Departamentos</label
            >
            <p-multiSelect
              id="actividades"
              filterPlaceHolder="Buscar"
              [defaultLabel]="'Selección de actividades'"
              [virtualScroll]="true"
              [options]="listaActividades"
              formControlName="actividades"
              optionLabel="idDescripcion"
              [filter]="true"
              styleClass="form-control p-0"
            ></p-multiSelect>
          </div>
          <div class="mb-3 col-sm-12 col-lg-4">
            <label for="lineas" class="form-label">Líneas de Venta</label>
            <p-multiSelect
              id="lineas"
              filterPlaceHolder="Buscar"
              [defaultLabel]="'Selección de líneas de venta'"
              [virtualScroll]="true"
              [options]="listaLineasVenta"
              formControlName="lineas"
              optionLabel="idDescripcion"
              [filter]="true"
              styleClass="form-control p-0"
            ></p-multiSelect>
          </div>
          <div class="mb-3 col-sm-12 col-lg-4">
            <label for="urgencias" class="form-label">Urgencias</label>
            <p-multiSelect
              id="urgencias"
              filterPlaceHolder="Buscar"
              [defaultLabel]="'Selección de urgencias'"
              [virtualScroll]="true"
              [options]="listaUrgencias"
              formControlName="urgencias"
              optionLabel="descripcion"
              [filter]="true"
              styleClass="form-control p-0"
            ></p-multiSelect>
          </div>
        </div>

        <div class="row">
          <div class="mb-3 col-sm-12 col-lg-6">
            <div>
              <label for="nombre" class="form-label"
                >Nombre Destinatarios</label
              >
              <mat-chip-list #chipList aria-label="Selección Nombres">
                <div class="col-sm-12 col-lg-7 p-0">
                  <input
                    type="text"
                    autocomplete="off"
                    class="form-control"
                    id="nombre"
                    name="nombre"
                    formControlName="nombre"
                    placeholder="Introduzca Nombres a buscar"
                    (input)="buscarNombres($event)"
                  />
                  <small
                    class="form-text text-danger"
                    *ngIf="
                      listaNombres.length === 0 &&
                      nombresBuscados &&
                      this.formulario.get('nombres')?.value.length === 0 &&
                      this.formulario.get('nombre')?.value !== '' &&
                      this.formulario.get('nombre')?.value.length > 3
                    "
                    >* No se han encontrado resultados</small
                  >
                </div>
                <div class="col-sm-12 col-lg-12 mb-4">
                  <mat-chip
                    class="selected-chip"
                    *ngFor="
                      let nombreInput of this.formulario.get('nombres')?.value;
                      let i = index
                    "
                    [removable]="true"
                    (removed)="removeNombreForm(nombreInput, i)"
                  >
                    {{ nombreInput }}
                    <mat-icon matChipRemove>X</mat-icon>
                  </mat-chip>
                  <small
                    class="form-text text-danger"
                    *ngIf="
                      listaNombres.length === 0 &&
                      nombresBuscados &&
                      this.formulario.get('nombres')?.value.length > 0 &&
                      this.formulario.get('nombre')?.value !== '' &&
                      this.formulario.get('nombre')?.value.length > 3
                    "
                    >* No se han encontrado resultados</small
                  >
                </div>
                <div class="col-sm-12 col-lg-12">
                  <mat-chip-list>
                    <mat-chip
                      *ngFor="let nombre of listaNombres; let i = index"
                      [selected]="nombre.state"
                      selectable
                      multiple
                      [removable]="true"
                      (removed)="removeNombre(i)"
                      [value]="nombre"
                      [multiple]="true"
                      (click)="
                        nombre.state = !nombre.state; addNombre(nombre, i)
                      "
                    >
                      {{ nombre.nombre }}
                      <mat-icon matChipRemove>X</mat-icon>
                    </mat-chip>
                  </mat-chip-list>
                </div>
              </mat-chip-list>
            </div>
          </div>
          <div class="mb-3 col-sm-12 col-lg-6">
            <div>
              <label for="apellido" class="form-label"
                >Apellido Destinatarios</label
              >
              <mat-chip-list #chipList aria-label="Selección Apellidos">
                <div class="col-sm-12 col-lg-7 p-0">
                  <input
                    type="text"
                    autocomplete="off"
                    class="form-control"
                    id="apellido"
                    name="apellido"
                    formControlName="apellido"
                    placeholder="Introduzca Apellidos a buscar"
                    (input)="buscarApellido($event)"
                  />
                  <small
                    class="form-text text-danger"
                    *ngIf="
                      listaApellidos.length === 0 &&
                      apellidosBuscados &&
                      this.formulario.get('apellidos')?.value.length === 0 &&
                      this.formulario.get('apellido')?.value !== '' &&
                      this.formulario.get('apellido')?.value.length > 3
                    "
                    >* No se han encontrado resultados</small
                  >
                </div>
                <div class="col-sm-12 col-lg-12 mb-4">
                  <mat-chip
                    class="selected-chip"
                    *ngFor="
                      let apellidoInput of this.formulario.get('apellidos')
                        ?.value;
                      let i = index
                    "
                    [removable]="true"
                    (removed)="removeApellidoForm(apellidoInput, i)"
                  >
                    {{ apellidoInput }}
                    <mat-icon matChipRemove>X</mat-icon>
                  </mat-chip>
                  <small
                    class="form-text text-danger"
                    *ngIf="
                      listaApellidos.length === 0 &&
                      apellidosBuscados &&
                      this.formulario.get('apellidos')?.value.length > 0 &&
                      this.formulario.get('apellido')?.value !== '' &&
                      this.formulario.get('apellido')?.value.length > 3
                    "
                    >* No se han encontrado resultados</small
                  >
                </div>
                <div class="col-sm-12 col-lg-12">
                  <mat-chip-list>
                    <mat-chip
                      *ngFor="let apellido of listaApellidos; let i = index"
                      [selected]="apellido.state"
                      selectable
                      multiple
                      [removable]="true"
                      (removed)="removeApellido(i)"
                      [value]="apellido"
                      [multiple]="true"
                      (click)="
                        apellido.state = !apellido.state;
                        addApellido(apellido, i)
                      "
                    >
                      {{ apellido.apellido }}
                      <mat-icon matChipRemove>X</mat-icon>
                    </mat-chip>
                  </mat-chip-list>
                </div>
              </mat-chip-list>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="mb-3 col-sm-12 col-lg-4">
            <label for="cliente" class="form-label">Cliente / Proyecto</label>
            <input
              type="name"
              placeholder="Cliente"
              formControlName="cliente"
              class="form-control"
              id="cliente"
              trim="blur"
              maxlength="100"
            />
          </div>

          <div class="mb-3 col-sm-12 col-lg-4">
            <label for="denominacion" class="form-label"
              >Denominación del servicio</label
            >
            <input
              type="name"
              placeholder="Denominación"
              formControlName="denominacion"
              class="form-control"
              id="denominacion"
              trim="blur"
              maxlength="100"
            />
          </div>

          <div class="mb-3 col-sm-12 col-lg-4">
            <label for="dniTrabjAfec" class="form-label"
              >DNI/NIE Trabajador Afectado</label
            >
            <input
              type="name"
              placeholder="DNI/NIE Trabajador Afectado"
              formControlName="dniTrabjAfec"
              class="form-control"
              id="dniTrabjAfec"
              trim="blur"
              maxlength="100"
            />
          </div>
        </div>

        <div class="row">
          <div class="mb-3 col-sm-12 col-lg-6">
            <label for="fechaSolicitudInicio" class="form-label"
              >Fecha alta inicio</label
            >
            <input
              class="form-control"
              type="date"
              value=""
              id="fechaSolicitudInicio"
              formControlName="fechaSolicitudInicio"
              trim="blur"
            />
          </div>
          <div class="mb-3 col-sm-12 col-lg-6">
            <label for="fechaSolicitudFin" class="form-label"
              >Fecha alta fin</label
            >
            <input
              class="form-control"
              type="date"
              value=""
              id="fechaSolicitudFin"
              formControlName="fechaSolicitudFin"
              trim="blur"
            />
            <small
              class="form-text text-danger"
              *ngIf="
                formulario.get('fechaSolicitudFin')!.touched &&
                validarFechas(
                  formulario.get('fechaSolicitudInicio')?.value,
                  formulario.get('fechaSolicitudFin')?.value
                )
              "
              >* La fecha solicitud fin debe ser mayor que la fecha de
              inicio</small
            >
          </div>
        </div>
      </ng-container>
      <button
        class="btn btn-outline-info customButton fa fa-filter filtros mb-2"
        (click)="mostrarAvanzado()"
      >
        {{ textoBoton }}
      </button>
      <div class="mt-2 mb-3 d-flex">
        <button class="btn limpiarBoton fa fa-eraser mr-3" (click)="reset()">
          Limpiar
        </button>
        <button
          type="submit"
          class="btn btn-outline-primary customButton fa fa-search buscar"
          (click)="buscarFiltro()"
        >
          Buscar
        </button>
      </div>
    </form>
  </fieldset>

  <fieldset class="my-4">
    <legend>
      Buscador rapido
      <i
        class="fa fa-info-circle"
        style="color: #002664"
        pTooltip="Busca la palabra indicada en la descripción, los comentarios, las aclaraciones, los cierres, las reaperturas, las autorizaciones y las informaciones de las peticiones"
        aria-hidden="true"
      ></i>
    </legend>
    <form [formGroup]="formulario" *ngIf="formulario">
      <div class="row">
        <div class="mb-3 col-sm-12 col-lg-8">
          <input
            type="search"
            maxlength="250"
            autocomplete="off"
            class="form-control"
            id="busqueda"
            name="busqueda"
            formControlName="busqueda"
            placeholder="Buscar por ..."
            aria-label="busqueda"
            aria-describedby="search-addon"
          />
        </div>
        <div class="mb-3 col-sm-12 col-lg-4">
          <button
            type="submit"
            class="btn btn-outline-primary btn-lg customButton fa fa-search buscar"
            (click)="buscarFiltro()"
          >
            Buscar
          </button>
        </div>
      </div>
    </form>
  </fieldset>
</div>

import {
  Component,
  Injector,
  Input,
  OnInit,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Table } from 'primeng/table';
import { Actividades } from 'src/app/modelos/actividades';
import { AmbitoConvenio } from 'src/app/modelos/ambitoConvenio';
import { Convenio } from 'src/app/modelos/convenio';
import {
  ConvenioFiltro,
  ConvenioFiltroRequest,
} from 'src/app/modelos/convenio-filtro';
import { Provincia } from 'src/app/modelos/provincia';
import { ConveniosService } from 'src/app/servicios/convenios/convenios.service';
import { Constantes } from 'src/app/utils/constantes';
import Utils from 'src/app/utils/utils';
import { MensajeComponent } from '../../mensaje/mensaje.component';
import { ModalInfoComponent } from '../../modals/modal-info/modal-info.component';

@Component({
  selector: 'app-convenios',
  templateUrl: './convenios.component.html',
  styleUrls: ['./convenios.component.scss'],
})
export class ConveniosComponent implements OnInit {
  convenios: Convenio[] = [];
  selectedConvenio: Convenio | null = null;
  cols!: any[];
  first = 1;
  rows = 25;

  ambitos = [
    { id: 1, descripcion: 'Estatal' },
    { id: 2, descripcion: 'Autonomico' },
    { id: 3, descripcion: 'Provincial' },
    { id: 4, descripcion: 'Interprovincial' },
    { id: 5, descripcion: 'Local' },
  ];

  paginasTotales = 0;
  paginaActual = 1;
  resultadosTotales = 0;
  resultadosPorPagina!: number[];

  lastSortField!: any;

  @ViewChildren('dt1')
  public table!: Table;

  @ViewChild('mensaje', { static: false })
  public mensaje!: MensajeComponent;

  private conveniosService!: ConveniosService;

  // tslint:disable-next-line:variable-name
  private _datosFiltro!: ConvenioFiltro;

  @Input()
  set datosFiltro(param: ConvenioFiltro) {
    this._datosFiltro = param;
    this.first = 1;
    if (param && this.conveniosService) {
      this.rows = this.datosFiltro.recordsPerPage || this.rows;
      this.getConvenios(this.datosFiltro.currentPage || this.first);
    }
  }

  get datosFiltro(): ConvenioFiltro {
    return this._datosFiltro;
  }

  constructor(
    private inject: Injector,
    private spinner: NgxSpinnerService,
    private constantes: Constantes,
    public dialog: MatDialog,
    private router: Router
  ) {
    this.cols = [
      {
        field: 'denominacion',
        header: 'DENOMINACIÓN',
        toolTip: 'Nombre del convenio',
      },
      { field: 'ambitoTerritorial', header: 'AMBITO TERRITORIAL' },
      { field: 'provincias', header: 'PROVINCIAS' },
      { field: 'actividades', header: 'ACTIVIDADES' },
      {
        field: 'acciones',
        header: 'ACCIONES',
        toolTip: 'Acciones disponibles',
      },
    ];
    this.resultadosPorPagina = this.constantes.numeroUsuariosPorPagina;
  }

  ngOnInit(): void {
    this.initServices();
    if (this.conveniosService.getFiltros()) {
      this.datosFiltro = this.conveniosService.getFiltros();
    }
    if (this._datosFiltro) {
      if (this._datosFiltro.recordsPerPage) {
        this.rows = this._datosFiltro.recordsPerPage;
      }
      if (this._datosFiltro.currentPage) {
        this.first = this._datosFiltro.currentPage;
        this.paginaActual = this.first;
        if (this.table) {
          this.table.first = (this.paginaActual - 1) * this.rows;
        }
      }
      this.getConvenios(this.first);
    } else {
      this.callConveniosNoFiltros(this.first);
    }
  }

  private initServices(): void {
    this.conveniosService = this.inject.get<ConveniosService>(ConveniosService);
  }

  getConvenios(page: number): void {
    this.spinner.show();
    this.convenios = [];
    this.datosFiltro.currentPage = page;
    this.datosFiltro.recordsPerPage = this.rows;
    const provincia = this.updateFiltroProvincia();
    const actividad = this.updateFiltroActividad();
    const convenio = this.updateFiltroConvenio();
    const ambito = this.updateFiltroAmbito();
    const request = {
      convenio,
      ambito,
      provincia,
      actividad,
      activo: this._datosFiltro.activo === 'activos' ? true : false,
    } as ConvenioFiltroRequest;
    this.conveniosService
      .obtenerConvenios(String(page), String(this.rows), request)
      .subscribe(
        (data: any) => {
          this.cargarDatosTabla(data);
          this.spinner.hide();
        },
        (error: any) => {
          console.error(error);
          this.spinner.hide();
        }
      );
  }

  callConveniosNoFiltros(page: number): void {
    this.spinner.show();
    this.convenios = [];
    const request = {
      activo: true,
    } as ConvenioFiltroRequest;
    this.conveniosService
      .obtenerConvenios(String(page), String(this.rows), request)
      .subscribe(
        (data: any) => {
          this.cargarDatosTabla(data);
          this.spinner.hide();
        },
        (error: any) => {
          console.error(error);
          this.spinner.hide();
        }
      );
  }

  private cargarDatosTabla(data: any): any {
    this.convenios =
      data.results?.map((convenio: Convenio) =>
        this.setTodasProvincias(convenio)
      ) || [];
    this.paginaActual = data.currentPage;
    this.paginasTotales = data.totalPages;
    this.resultadosTotales = data.totalResults;
    this.customSort(this.lastSortField);
  }

  private inicializarFiltro(): void {
    if (!this.datosFiltro) {
      this._datosFiltro = { activo: 'activos' };
    }
  }

  nextPage(e: any, table: Table): void {
    this.inicializarFiltro();
    if (this.rows !== e.rows) {
      this.rows = e.rows;
      e.first = 0;
      this.paginaActual = 1;
      const offset = e.first / this.rows + 1;
      table.first = offset;
      this.first = offset;
      this.rows = e.rows;
      this.getConvenios(offset);
    } else {
      if (
        (e.sortField && !this.lastSortField) ||
        (e.sortField && this.hasChanged(e.sortField, e.sortOrder))
      ) {
        this.lastSortField = {
          sortField: e.sortField,
          sortOrder: e.sortOrder,
        };
        this.customSort(this.lastSortField);
        table.first = (this.paginaActual - 1) * this.rows;
        this.conveniosService.setFiltros(this.datosFiltro);
      } else {
        const offset = e.first / this.rows + 1;
        this.rows = e.rows;
        this.getConvenios(offset);
      }
    }
  }

  private customSort(sort: any): void {
    if (sort && this.convenios) {
      this.convenios.sort((a: Convenio, b: Convenio) => {
        switch (sort.sortField) {
          case this.cols[0].field:
            return Utils.sortString(
              Utils.stringNotNull(a.descripcion),
              Utils.stringNotNull(b.descripcion),
              sort.sortOrder
            );
          case this.cols[1].field:
            return Utils.sortString(
              this.ambitos[+a.ambito - 1].descripcion,
              this.ambitos[+b.ambito - 1].descripcion,
              sort.sortOrder
            );
          case this.cols[2].field:
            return Utils.sortListField(
              a?.provincias?.map((provincia) => provincia.descripcion) || [''],
              b?.provincias?.map((provincia) => provincia.descripcion) || [''],
              sort.sortOrder
            );
          case this.cols[3].field:
            return Utils.sortListField(
              a?.actividades?.map((actividad) => actividad.descripcion) || [''],
              b?.actividades?.map((actividad) => actividad.descripcion) || [''],
              sort.sortOrder
            );
          default:
            return sort.sortOrder;
        }
      });
    }
  }

  private hasChanged(sort: string, order: number): boolean {
    return (
      this.lastSortField.sortField !== sort ||
      this.lastSortField.sortOrder !== order
    );
  }

  private updateFiltroProvincia(): number[] {
    return this._datosFiltro && this._datosFiltro.provincia
      ? this._datosFiltro.provincia?.map((provincia: Provincia) => provincia.id)
      : [];
  }

  private updateFiltroAmbito(): number[] {
    return this._datosFiltro && this._datosFiltro.ambito
      ? this._datosFiltro.ambito?.map((ambito: AmbitoConvenio) => ambito.id)
      : [];
  }

  private updateFiltroActividad(): number[] {
    return this._datosFiltro && this._datosFiltro.actividad
      ? this._datosFiltro.actividad?.map(
          (actividad: Actividades) => actividad.id
        )
      : [];
  }

  private updateFiltroConvenio(): string[] {
    return this._datosFiltro && this._datosFiltro.convenio
      ? this._datosFiltro.convenio
      : [];
  }

  showModal(convenio: Convenio): void {
    console.log(convenio);
    this.selectedConvenio = convenio;
    this.mensaje.showConfirmacionPeticion('¿Desea realizar la acción?');
  }

  aceptar(): void {
    if (this.selectedConvenio) {
      this.spinner.show();
      this.conveniosService.cambioEstado(this.selectedConvenio).subscribe(
        () => {
          this.spinner.hide();
          this.selectedConvenio = null;
          this.first = 1;
          this.getConvenios(this.first);
        },
        (error) => {
          console.log(error.message);
          this.selectedConvenio = null;
          this.spinner.hide();
          this.mensaje.showErrorPeticion(
            'No se ha podido realizar la petición.'
          );
        }
      );
    }
  }

  exportarDatos(): void {
    this.spinner.show();
    const provincia = this.updateFiltroProvincia();
    const actividad = this.updateFiltroActividad();
    const convenio = this.updateFiltroConvenio();
    const ambito = this.updateFiltroAmbito();
    const request = {
      convenio,
      ambito,
      provincia,
      actividad,
    } as ConvenioFiltroRequest;
    this.conveniosService.exportar(request).subscribe(
      (urlRespuesta) => {
        if (urlRespuesta) {
          // Abrir nueva pantalla con el resultado del exportar
          this.spinner.hide();
          window.open(
            urlRespuesta.url,
            '_blank',
            'location=yes, height=570, width=520, scrollbars=yes, status=yes'
          );
        } else {
          const data = {
            title: 'Información',
            msg: 'No se ha podido hacer la exportación ...',
          };
          this.spinner.hide();
          this.dialog.open(ModalInfoComponent, {
            disableClose: true,
            autoFocus: false,
            data,
          });
        }
      },
      (error) => {
        this.spinner.hide();
        this.mensaje.showErrorPeticion(
          'No se ha podido exportar los datos a excel. Intentelo más tarde.'
        );
      }
    );
  }
  private setTodasProvincias(convenio: Convenio): Convenio {
    if (convenio.provincias?.length === 0) {
      convenio.provincias = [
        { id: 0, descripcion: 'TODAS LAS PROVINCIAS' } as Provincia,
      ];
    }
    return convenio;
  }

  createConvenio(): void {
    this.router.navigate(['/convenios/crear']);
  }

  editarConvenio(id: number): void {
    this.router.navigate([`/convenios/${id}`]);
  }
}

<ngx-spinner
  name="googleload"
  template="<img src='assets/googleload.gif'/>"
  bdColor="rgba(239,239,239,255)"
>
</ngx-spinner>
<ngx-spinner></ngx-spinner>
<app-mensaje #mensaje></app-mensaje>
<div class="container">
  <h5 class="mt-3 mb-4">{{ title }}</h5>
  <form [formGroup]="formulario" *ngIf="formulario">
    <div class="form-group">
      <div class="mb-3">
        <label for="descripcion " class="form-label"
          >Denominación<span class="text-danger">*</span></label
        >
        <textarea
          id="descripcion"
          class="form-control"
          formControlName="descripcion"
          rows="2"
          trim="blur"
          required
        ></textarea>
        <small
          class="form-text text-danger"
          *ngIf="
            !formulario.get('descripcion')!.valid &&
            formulario.get('descripcion')!.touched &&
            formulario.get('descripcion')?.value.length < 255
          "
          >* El campo denominación es obligatorio</small
        >
        <small
          class="form-text text-danger"
          *ngIf="formulario.get('descripcion')?.value.length > 255"
          >* El campo excede el tamaño máximo</small
        >
      </div>
      <div class="row">
        <div class="mb-3 col-sm-12 col-lg-4">
          <label for="descripcion " class="form-label"
            >Ámbito Territorial<span class="text-danger">*</span>
          </label>
          <p-dropdown
            id="ambito"
            styleClass="form-control p-0"
            placeholder="Seleccione ambito"
            [options]="listaAmbitos"
            formControlName="ambito"
            optionLabel="descripcion"
            [filter]="true"
            (onChange)="mostrarProvincia()"
          >
          </p-dropdown>
          <small
            class="form-text text-danger"
            *ngIf="
              !formulario.get('ambito')!.valid &&
              formulario.get('ambito')!.touched
            "
            >* El campo ámbito territorial es obligatorio</small
          >
        </div>
        <div class="mb-3 col-sm-12 col-lg-4" *ngIf="mostrarProvinciaFlag">
          <label for="descripcion " class="form-label"
            >Provincias<span class="text-danger">*</span></label
          >
          <p-multiSelect
            id="provincia"
            filterPlaceHolder="Buscar"
            [defaultLabel]="'Seleccionar provincia'"
            [virtualScroll]="true"
            [options]="listaProvincias"
            optionLabel="descripcion"
            formControlName="provincias"
            styleClass="form-control p-0"
          ></p-multiSelect>
          <small
            class="form-text text-danger"
            *ngIf="
              !formulario.get('provincias')!.valid &&
              formulario.get('provincias')!.touched
            "
            >* El campo provincias es obligatorio</small
          >
        </div>
        <div class="mb-3 col-sm-12 col-lg-4">
          <label for="descripcion " class="form-label"
            >Actividades<span class="text-danger">*</span>
          </label>
          <p-multiSelect
            id="actividad"
            styleClass="form-control p-0"
            placeholder="Seleccionar actividad"
            [options]="listaActividades"
            formControlName="actividades"
            optionLabel="descripcion"
            [filter]="true"
          >
          </p-multiSelect>

          <small
            class="form-text text-danger"
            *ngIf="
              !formulario.get('actividades')!.valid &&
              formulario.get('actividades')!.touched
            "
            >* El campo actividades es obligatorio</small
          >
        </div>
      </div>
    </div>
  </form>
  <div class="mt-2 mb-3 d-flex justify-content-center">
    <button
      class="btn btn-outline-secondary mr-2"
      (click)="volverListadoConvenios()"
    >
      Volver
    </button>
    <button
      type="submit"
      class="btn btn-outline-primary customButton"
      (click)="crearConvenio()"
      [disabled]="formulario.invalid || !formulario.touched"
    >
      {{ modoEdicion ? "Editar convenio" : "Crear convenio" }}
    </button>
  </div>
</div>

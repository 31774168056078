import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { MensajeComponent } from 'src/app/componentes/mensaje/mensaje.component';
import { Catalogo } from 'src/app/modelos/catalogo';
import { CatalogosService } from 'src/app/servicios/catalogos/catalogos.service';
import { Peticion } from '../../../../modelos/peticion';
import { PeticionService } from '../../../../servicios/peticion/peticion.service';

@Component({
  selector: 'app-crear-peticion-observaciones',
  templateUrl: './crear-peticion-observaciones.component.html',
  styleUrls: ['./crear-peticion-observaciones.component.css']
})
export class CrearPeticionObservacionesComponent implements OnInit {

  public activeIndex = 1;
  public peticion!: Peticion;
  public contador = 0;
  public formulario!: FormGroup;
  public fechaTexto = '';
  public datosEfectividad = false;
  public rango = false;
  public fecha = false;
  public fechaForm!: Date;
  public fechaDesdeForm!: Date;
  public fechaHastaForm!: Date;
  public modificable = true;
  public fechaHastaObligatorio = false;
  public fechaDesdeObligatorio = false;
  public fechaEfectividadObligatorio = false;

  @ViewChild('mensaje', { static: false })
  public mensaje!: MensajeComponent;

  constructor(private router: Router,
              private fb: FormBuilder,
              public peticionService: PeticionService,
              public catalogosService: CatalogosService,
              private spinner: NgxSpinnerService) {
              }

  ngOnInit(): void {
    this.spinner.show();
    this.peticion = this.peticionService.getPeticion();
    this.modificable = this.peticionService.esModificable();
    this.buscarDatosCatalogo();
    this.fechasEfectividadForm();
    if (this.peticion && this.peticion.descripcion){
      this.contador = this.peticion.descripcion.length;
    }

    this.formulario = this.fb.group({
      observaciones: [this.peticion?.descripcion, [Validators.required, Validators.maxLength(3000)]],
      fechaDesde: (this.peticion?.fechaDesde) ? this.comprobarObligatoriedadFechas(this.fechaDesdeObligatorio, this.fechaDesdeForm) : null,
      fechaHasta: (this.peticion?.fechaHasta) ? this.comprobarObligatoriedadFechas(this.fechaHastaObligatorio, this.fechaHastaForm) : null,
      fechaEfectividad: (this.peticion?.fechaEfectividad) ?
          this.comprobarObligatoriedadFechas(this.fechaEfectividadObligatorio, this.fechaForm) : null
    });

    if (!this.peticionService.esModificable()){
      this.formulario.disable();
    }
    this.spinner.hide();
  }

  private comprobarObligatoriedadFechas(fechaObligatoria: boolean, fechaForm: Date): any{
    if (fechaObligatoria){
      return [new Date(fechaForm), Validators.required];
    }else{
      return [new Date(fechaForm)];
    }
  }


  private fechasEfectividadForm(): void {
    if (this.peticion && this.peticion.fechaEfectividad) {
      this.fechaForm = this.peticion.fechaEfectividad;
    }
    if (this.peticion && this.peticion.fechaDesde) {
      this.fechaDesdeForm = this.peticion.fechaDesde;
    }
    if (this.peticion && this.peticion.fechaHasta) {
      this.fechaHastaForm = this.peticion.fechaHasta;
    }
  }

  public buscarDatosCatalogo(): void {
    if (this.peticion.catalogo?.id) {
      this.catalogosService.getCatalogo(this.peticion.catalogo.id).subscribe((result: Catalogo) => {
        if (result) {
          this.fechaTexto = result.descFechaEfectividad;
          this.datosEfectividad = (result.datosEfectividad === 1) ? true : false;
          this.esRangoOFecha(result);
          this.comprobarFechasObligatorias(result);
        }
      });
    }
  }

  private esRangoOFecha(result: Catalogo): void {
    this.rango = (result.tipoFechaEfectividad === 'Rango') ? true : false;
    this.fecha = (result.tipoFechaEfectividad === 'Fecha') ? true : false;
  }

  private comprobarFechasObligatorias(result: Catalogo): void {
    this.fechaDesdeObligatorio = (result.obligatorioDesde === 1) ? true : false;
    this.fechaHastaObligatorio = (result.obligatorioHasta === 1) ? true : false;
    this.fechaEfectividadObligatorio = (result.obligatorioFecha === 1) ? true : false;
  }

  public nextPage(): void {
    if (this.peticion){
      if (this.formulario.valid && this.formulario.dirty){
        this.mostrarModalConfirmacion('peticiones/crear/datos');
      }else{
        this.router.navigate(['peticiones/crear/datos']);
      }
    }else{
      this.router.navigate(['peticiones/crear/datos']);
    }

  }

  public previousPage(): void {
    if (this.peticion){
      if (this.formulario.valid && this.formulario.dirty){
        this.mostrarModalConfirmacion('peticiones/crear/identificacion');
      }else{
        this.router.navigate(['peticiones/crear/identificacion']);
      }
    }else{
      this.router.navigate(['peticiones/crear/identificacion']);
    }

  }


  public guardarObservaciones(): void{
    if (this.peticion){
      if (this.formulario.valid && this.formulario.dirty){
        this.onConfirmModal(1, 'peticiones/crear/observaciones');
      }else{
        this.mensaje.showMensaje('Está todo actualizado.');
      }
    }

  }

  public mostrarModalConfirmacion(ruta: string): void{
    if (this.formulario.valid || this.peticionService.esModificable()){
      if (this.peticion?.descripcion !== this.formulario.get('observaciones')?.value || this.comprobarCambiosFechas()){
        this.onConfirmModal(1, ruta);
      }else{
        this.router.navigate([ruta]);
      }
    }else{
      this.mensaje.showErrorPeticion('Revisa la petición, hay campos incorrectos o incompletos');
    }
  }

  public comprobarCambiosFechas(): boolean{
    let cambio = false;
    if (this.datosEfectividad &&
      (this.peticion.fechaDesde !== this.formulario.get('fechaDesde')?.value ||
      this.peticion.fechaHasta !== this.formulario.get('fechaHasta')?.value ||
      this.peticion.fechaEfectividad !== this.formulario.get('fechaEfectividad')?.value)){
        cambio = true;
    }
    return cambio;
  }

  public onConfirmModal(event: any, ruta: string): void{
    this.mensaje.cerrar();
    if (event){
      this.peticion.descripcion = this.formulario.get('observaciones')?.value;
      this.peticion.fechaDesde = this.formulario.get('fechaDesde')?.value;
      this.peticion.fechaHasta = this.formulario.get('fechaHasta')?.value;
      this.peticion.fechaEfectividad = this.formulario.get('fechaEfectividad')?.value;
      this.peticionService.setPeticion(this.peticion);
      this.spinner.show();
      this.guardarPeticion(ruta);
    }
  }

  public guardarPeticion(ruta: string): void {
    this.peticionService.actualizarPeticion(this.peticionService.getPeticion()).subscribe((result: Peticion) => {
      this.spinner.hide();
      if (result) {
        this.mensaje.showMensaje('La petición se ha actualizado de forma correcta', ruta);
        this.peticionService.setPeticion(result);
      } else {
        this.mensaje.showError('No se ha podido actualizar la petición');
      }
    },
      error => {
        this.spinner.hide();
        this.mensaje.showError(error);
      }
    );
  }

  public onKeyUp(event: any): void{
    this.contador = event.target.value.length;
  }

  public onKeyPress(event: any): any{
    if (event.keyCode === 39 || event.keyCode === 96 || event.keyCode === 180){
        return false;
    }
    return true;
  }

  public limpiar(): void{
    this.formulario.get('observaciones')?.setValue(null);
    this.formulario.get('fechaDesde')?.setValue(null);
    this.formulario.get('fechaHasta')?.setValue(null);
    this.formulario.get('fechaEfectividad')?.setValue(null);
    document.getElementById('anterior')?.removeAttribute('disabled');
    this.contador = 0;
  }

  public validarFechas(fechaInicio: string, fechaFin: string): boolean{
    if (fechaInicio && fechaFin){
      if (new Date(fechaInicio).getTime() > new Date(fechaFin).getTime()){
        document.getElementById('guardar')?.setAttribute('disabled', 'true');
        document.getElementById('siguiente')?.setAttribute('disabled', 'true');
        document.getElementById('anterior')?.setAttribute('disabled', 'true');
        return true;
      }else{
        if (this.formulario.valid && this.formulario.get('observaciones')?.value != null){
          document.getElementById('guardar')?.removeAttribute('disabled');
          document.getElementById('siguiente')?.removeAttribute('disabled');
        }else{
          document.getElementById('guardar')?.setAttribute('disabled', 'true');
          document.getElementById('siguiente')?.setAttribute('disabled', 'true');
        }
      }
    }
    document.getElementById('anterior')?.removeAttribute('disabled');
    return false;
  }


  public deshabilitarBotones(): boolean{

    if (this.formulario.valid && this.peticion.catalogo?.datosEfectividad === 0){
      return false;
    }
    if (this.formulario.valid && this.peticion.catalogo?.datosEfectividad === 1){
      return false;
    }
    return true;
  }

  public controlForm(): void {
    if (this.formulario.get('observaciones')?.value === '' || this.formulario.get('observaciones')?.value === null) {
      this.contador = 0;
    } else {
      this.contador = this.formulario.get('observaciones')?.value.trim().length;
    }
  }


}

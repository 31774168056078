<ngx-spinner
  name="googleload"
  template="<img src='assets/googleload.gif'/>"
  bdColor="rgba(239,239,239,255)"
>
</ngx-spinner>
<ngx-spinner></ngx-spinner>
<div class="container">
  <h2 class="mt-5 mb-5">{{ title }}</h2>
  <form [formGroup]="formulario">
    <div class="row">
      <div class="mb-3 col-sm-12 col-lg-4">
        <label for="nombre" class="form-label"
          >Nombre <span class="text-danger">*</span></label
        >
        <input
          type="name"
          placeholder="Nombre"
          ebppInputTextTrimmer
          formControlName="nombre"
          class="form-control"
          id="nombre"
          trim="blur"
        />
        <small
          class="form-text text-danger"
          *ngIf="
            !formulario.get('nombre')!.valid &&
            formulario.get('nombre')!.touched
          "
          >* El campo nombre es obligatorio</small
        >
      </div>
      <div class="mb-3 col-sm-12 col-lg-4">
        <label for="apellido" class="form-label"
          >Apellidos <span class="text-danger">*</span></label
        >
        <input
          type="givename"
          placeholder="Apellidos"
          class="form-control"
          id="apellido"
          formControlName="apellido"
          trim="blur"
        />
        <small
          class="form-text text-danger"
          *ngIf="
            !formulario.get('apellido')!.valid &&
            formulario.get('apellido')!.touched
          "
          >* El campo apellidos es obligatorio</small
        >
      </div>
      <div class="mb-3 col-sm-12 col-lg-4">
        <label for="dni" class="form-label"
          >DNI/NIE <span class="text-danger">*</span></label
        >
        <input
          type="givename"
          placeholder="DNI"
          class="form-control"
          id="dni"
          formControlName="dni"
          trim="blur"
          maxlength="9"
        />
        <small
          class="form-text text-danger"
          *ngIf="
            !formulario.get('dni')!.valid &&
            formulario.get('dni')!.touched &&
            formulario.controls.dni.errors?.required
          "
          >* El campo DNI/NIE es obligatorio</small
        >
        <small
          class="form-text text-danger"
          *ngIf="
            !formulario.get('dni')!.valid &&
            formulario.get('dni')!.touched &&
            formulario.controls.dni.errors?.pattern
          "
          >* Formato de Documento incorrecto</small
        >
      </div>
    </div>
    <div class="row">
      <div class="mb-3 col-sm-12 col-lg-6">
        <label for="emailInput " class="form-label"
          >Email <span class="text-danger">*</span></label
        >
        <input
          class="form-control"
          id="email"
          placeholder="usuario@eulen.com"
          formControlName="email"
          aria-describedby="emailHelp"
          trim="blur"
        />
        <small
          class="form-text text-danger"
          *ngIf="
            !formulario.get('email')!.valid &&
            formulario.get('email')!.touched &&
            formulario.controls.email.errors?.required
          "
          >* El campo email es obligatorio</small
        >
        <small
          class="form-text text-danger"
          *ngIf="
            !formulario.get('email')!.valid &&
            formulario.get('email')!.touched &&
            formulario.controls.email.errors?.pattern
          "
          >* Formato de email incorrecto</small
        >
      </div>
      <div class="mb-3 col-sm-12 col-lg-6">
        <label for="empresa" class="form-label"
          >Empresa <span class="text-danger">*</span></label
        >
        <p-dropdown
          id="row"
          styleClass="form-control p-0"
          placeholder="Seleccione una empresa"
          [options]="listaEmpresas"
          formControlName="empresa"
          optionLabel="descripcion"
          [filter]="true"
        >
        </p-dropdown>
        <small
          class="form-text text-danger"
          *ngIf="
            !formulario.get('empresa')!.valid &&
            formulario.get('empresa')!.touched
          "
          >* El campo empresa es obligatorio</small
        >
      </div>
    </div>
    <!-- Caso Provincias para Flexiplan-->
    <ng-container>
      <div class="row">
        <div class="mb-3 col-sm-12 col-lg-4">
          <label for="zonas" class="form-label"
            >Zonas <span class="text-danger">*</span></label
          >
          <p-multiSelect
            id="zonas"
            filterPlaceHolder="Buscar"
            [defaultLabel]="'Seleccione zonas'"
            [virtualScroll]="true"
            [options]="listaZonas"
            optionLabel="descripcion"
            formControlName="zonas"
            styleClass="form-control p-0"
            (onChange)="cambioZona()"
          ></p-multiSelect>
          <small
            class="form-text text-danger"
            *ngIf="
              !formulario.get('zonas')!.valid &&
              formulario.get('zonas')!.touched
            "
            >* El campo zonas es obligatorio</small
          >
        </div>
        <div class="mb-3 col-sm-12 col-lg-4">
          <label for="ambitos" class="form-label">Ámbitos</label>
          <p-multiSelect
            [disabled]="disabledAmbitos"
            id="ambitos"
            filterPlaceHolder="Buscar"
            [defaultLabel]="'Seleccione ámbitos'"
            [virtualScroll]="true"
            [options]="listaAmbitos"
            optionLabel="descripcion"
            formControlName="ambitos"
            styleClass="form-control p-0"
            (onChange)="cambioAmbito()"
          ></p-multiSelect>
        </div>
        <div class="mb-3 col-sm-12 col-lg-4">
          <label for="provincias" class="form-label">Provincias</label>
          <p-multiSelect
            [disabled]="
              disabledAmbitos || disabledProvincias
            "
            id="provincias"
            filterPlaceHolder="Buscar"
            [defaultLabel]="'Seleccione provincias'"
            [virtualScroll]="true"
            [options]="listaProvincias"
            optionLabel="descripcion"
            formControlName="provincias"
            styleClass="form-control p-0"
          ></p-multiSelect>
        </div>
      </div>
    </ng-container>

    <div class="mt-5 mb-5 d-flex justify-content-center">
      <button class="btn btn-outline-secondary mr-2" (click)="redirect()">
        Volver
      </button>

      <button
        type="submit"
        class="btn btn-outline-primary customButton"
        (click)="callCrearUsuario()"
        [disabled]="!formulario.valid"
      >
        Enviar alta
      </button>
    </div>

  </form>
</div>

<div class="container">
  <fieldset>
    <legend>Buscar por...</legend>
    <form [formGroup]="formulario" *ngIf="formulario">
      <div class="row">
        <div class="mb-3 col-sm-12 col-lg-6">
          <label for="tecnicos" class="form-label">Destinatarios</label>
          <p-multiSelect
            id="tecnicos"
            filterPlaceHolder="Buscar"
            [defaultLabel]="'Selección de destinatarios'"
            [virtualScroll]="true"
            [options]="listaTecnicos"
            [disabled]="listaTecnicos.length === 0"
            formControlName="tecnico"
            optionValue="email"
            optionLabel="email"
            [filter]="true"
            styleClass="form-control p-0"
            [(ngModel)]="editordefault"
          >
          </p-multiSelect>
        </div>
        <div class="mb-3 col-sm-12 col-lg-6">
          <label for="tiposPeticiones" class="form-label">ID Petición</label>
          <input
            type="text"
            maxlength="15"
            autocomplete="off"
            class="form-control"
            id="idpeticion"
            name="idpeticion"
            formControlName="idPeticion"
            placeholder="Introduzca ID de Peticion"
          />
        </div>
      </div>
      <div *ngIf="!esAuditor">
        <div class="row">
          <div class="mb-3 col-sm-12 col-lg-3">
            <label for="anio" class="form-label">Año</label>
            <p-dropdown
              id="anio"
              [options]="listaAnios"
              styleClass="form-control p-0"
              formControlName="anio"
            >
            </p-dropdown>
            <small
              class="form-text text-danger"
              *ngIf="
                !formulario.get('anio')!.valid &&
                formulario.get('anio')!.touched
              "
              >* El campo año destinatarios es obligatorio</small
            >
          </div>
          <div class="mb-3 col-sm-12 col-lg-3">
            <label for="area" class="form-label">Área Destino</label>
            <p-multiSelect
              id="area"
              filterPlaceHolder="Buscar"
              [defaultLabel]="'Selección de áreas'"
              [virtualScroll]="true"
              [options]="listaAreas"
              formControlName="areas"
              styleClass="form-control p-0"
            ></p-multiSelect>
          </div>
          <div class="mb-3 col-sm-12 col-lg-6">
            <label for="tiposPeticiones" class="form-label"
              >Tipos de Petición</label
            >
            <p-multiSelect
              id="tiposPeticiones"
              filterPlaceHolder="Buscar"
              [defaultLabel]="'Selección de tipos de peticiones'"
              [virtualScroll]="true"
              [options]="listaTiposPeticiones"
              optionValue="id"
              formControlName="tipos"
              optionLabel="descripcion"
              [filter]="true"
              styleClass="form-control p-0"
            ></p-multiSelect>
          </div>
        </div>
        <div class="row">
          <div class="mb-3 col-sm-12 col-lg-4">
            <label for="estados" class="form-label">Estados</label>
            <p-multiSelect
              id="estados"
              filterPlaceHolder="Buscar"
              [defaultLabel]="'Selección de estados'"
              [virtualScroll]="true"
              [options]="listaEstados"
              optionValue="id"
              formControlName="estados"
              optionLabel="nombre"
              [filter]="true"
              styleClass="form-control
                        p-0"
            ></p-multiSelect>
          </div>
          <div class="mb-3 col-sm-12 col-lg-4">
            <label for="urgencias" class="form-label">Urgencias</label>
            <p-multiSelect
              id="urgencias"
              filterPlaceHolder="Buscar"
              [defaultLabel]="'Selección de urgencias'"
              [virtualScroll]="true"
              [options]="listaUrgencias"
              optionValue="id"
              formControlName="urgencias"
              optionLabel="descripcion"
              [filter]="true"
              styleClass="form-control p-0"
            ></p-multiSelect>
          </div>
          <div class="mb-3 col-sm-12 col-lg-4">
            <label for="provincias" class="form-label">Provincias </label>
            <p-multiSelect
              id="provincias"
              styleClass="form-control p-0"
              placeholder="Seleccione una provincia"
              [options]="listaProvincias"
              optionValue="id"
              formControlName="provincias"
              optionLabel="idDescripcion"
              [filter]="true"
            >
            </p-multiSelect>
          </div>
        </div>
        <div class="row">
          <div class="ml-auto">
            <div class="mb-3 col-sm-12 col-lg-12">
              <div class="p-field-checkbox">
                <p-checkbox
                  styleClass="p-0"
                  [binary]="true"
                  formControlName="ocultarPeticiones"
                  id="ocultarPeticiones"
                  name="ocultarPeticiones"
                  ngDefaultControl
                  label="Ocultar Peticiones Cerradas"
                ></p-checkbox>
              </div>
            </div>
          </div>
        </div>
        <ng-container *ngIf="avanzado">
          <div class="row">
            <div class="mb-3 col-sm-12 col-lg-6">
              <label for="lineas" class="form-label">Líneas de Venta</label>
              <p-multiSelect
                id="lineas"
                filterPlaceHolder="Buscar"
                [defaultLabel]="'Selección de líneas de venta'"
                [virtualScroll]="true"
                [options]="listaLineasVenta"
                optionValue="id"
                formControlName="lineas"
                optionLabel="idDescripcion"
                [filter]="true"
                styleClass="form-control p-0"
              ></p-multiSelect>
            </div>
            <div class="mb-3 col-sm-12 col-lg-6">
              <label for="actividades" class="form-label"
                >Actividades / Departamentos</label
              >
              <p-multiSelect
                id="actividades"
                filterPlaceHolder="Buscar"
                [defaultLabel]="'Selección de Actividades'"
                [virtualScroll]="true"
                [options]="listaActividades"
                optionValue="id"
                formControlName="actividades"
                optionLabel="idDescripcion"
                [filter]="true"
                styleClass="form-control p-0"
              ></p-multiSelect>
            </div>
          </div>
          <div class="row">
            <div class="mb-3 col-sm-12 col-lg-8">
              <label for="emails" class="form-label">Email Solicitante</label>
              <mat-chip-list #chipList aria-label="Selección Emails">
                <div class="col-sm-12 col-lg-7 p-0">
                  <input
                    type="text"
                    autocomplete="off"
                    class="form-control"
                    id="email"
                    name="email"
                    formControlName="email"
                    placeholder="Introduzca Emails a buscar"
                    (input)="buscarEmails($event)"
                  />
                  <small
                    class="form-text text-danger"
                    *ngIf="
                      listaEmails.length === 0 &&
                      emailsBuscados &&
                      this.formulario.get('emails')?.value.length === 0 &&
                      this.formulario.get('email')?.value !== '' &&
                      this.formulario.get('email')?.value.length > 3
                    "
                    >* No se han encontrado resultados</small
                  >
                </div>
                <div class="col-sm-12 col-lg-12 mb-4">
                  <mat-chip
                    class="selected-chip"
                    *ngFor="
                      let emailInput of this.formulario.get('emails')?.value;
                      let i = index
                    "
                    [removable]="true"
                    (removed)="removeEmailForm(emailInput, i)"
                  >
                    {{ emailInput }}
                    <mat-icon matChipRemove>X</mat-icon>
                  </mat-chip>
                  <small
                    class="form-text text-danger"
                    *ngIf="
                      listaEmails.length === 0 &&
                      emailsBuscados &&
                      this.formulario.get('emails')?.value.length > 0 &&
                      this.formulario.get('email')?.value !== '' &&
                      this.formulario.get('email')?.value.length > 3
                    "
                    >* No se han encontrado resultados</small
                  >
                </div>
                <div class="col-sm-12 col-lg-12">
                  <mat-chip-list>
                    <mat-chip
                      *ngFor="let email of listaEmails; let i = index"
                      [selected]="email.state"
                      selectable
                      multiple
                      [removable]="true"
                      (removed)="removeEmail(i)"
                      [value]="email"
                      [multiple]="true"
                      (click)="email.state = !email.state; addEmail(email, i)"
                    >
                      {{ email.email }}
                      <mat-icon matChipRemove>X</mat-icon>
                    </mat-chip>
                  </mat-chip-list>
                </div>
              </mat-chip-list>
            </div>
          </div>
          <div class="row">
            <div class="mb-3 col-sm-12 col-lg-4">
              <label for="nombre" class="form-label"
                >Nombre Trabajador Afectado
              </label>
              <mat-chip-list #chipList aria-label="Selección Apellidos">
                <div class="col-sm-12 col-lg-12 p-0">
                  <input
                    type="text"
                    autocomplete="off"
                    class="form-control"
                    id="nombre"
                    name="nombre"
                    formControlName="nombre"
                    placeholder="Introduzca Nombres a buscar"
                    (input)="buscarNombre($event)"
                  />
                  <small
                    class="form-text text-danger"
                    *ngIf="
                      listaNombres.length === 0 &&
                      !esNamePrimeraBusqueda &&
                      this.formulario.get('variosNombres')?.value.length === 0
                    "
                    >* No se han encontrado resultados</small
                  >
                </div>
                <div class="col-sm-12 col-lg-12 mb-4">
                  <mat-chip
                    class="selected-chip"
                    *ngFor="
                      let nombreInput of this.formulario.get('variosNombres')
                        ?.value;
                      let i = index
                    "
                    [removable]="removable"
                    (removed)="removeNombreForm(nombreInput, i)"
                  >
                    {{ nombreInput }}
                    <mat-icon matChipRemove *ngIf="removable">X</mat-icon>
                  </mat-chip>
                </div>
                <div class="col-sm-12 col-lg-12">
                  <mat-chip-list>
                    <mat-chip
                      *ngFor="let nombre of listaNombres; let i = index"
                      [selected]="nombre.state"
                      selectable
                      multiple
                      [removable]="removable"
                      (removed)="removeNombre(i)"
                      [value]="nombre"
                      [multiple]="true"
                      (click)="
                        nombre.state = !nombre.state; addNombre(nombre, i)
                      "
                    >
                      {{ nombre.nombre }}
                      <mat-icon matChipRemove *ngIf="removable">X</mat-icon>
                    </mat-chip>
                  </mat-chip-list>
                </div>
              </mat-chip-list>
            </div>
            <div class="mb-3 col-sm-12 col-lg-4">
              <label for="apellido" class="form-label"
                >Apellidos Trabajador Afectado
              </label>
              <mat-chip-list #chipList aria-label="Selección Apellidos">
                <div class="col-sm-12 col-lg-12 p-0">
                  <input
                    type="text"
                    autocomplete="off"
                    class="form-control apellido"
                    id="apellido"
                    name="apellido"
                    formControlName="apellido"
                    placeholder="Introduzca Apellidos a buscar"
                    (input)="buscarApellido($event)"
                  />
                  <small
                    class="form-text text-danger"
                    *ngIf="
                      listaApellidos.length === 0 &&
                      !esApePrimeraBusqueda &&
                      this.formulario.get('variosApellidos')?.value.length === 0
                    "
                    >* No se han encontrado resultados</small
                  >
                </div>
                <div class="col-sm-12 col-lg-12 mb-4">
                  <mat-chip
                    class="selected-chip"
                    *ngFor="
                      let apellidoInput of this.formulario.get(
                        'variosApellidos'
                      )?.value;
                      let i = index
                    "
                    [removable]="removable"
                    (removed)="removeApellidoForm(apellidoInput, i)"
                  >
                    {{ apellidoInput }}
                    <mat-icon matChipRemove *ngIf="removable">X</mat-icon>
                  </mat-chip>
                </div>
                <div class="col-sm-12 col-lg-12">
                  <mat-chip-list>
                    <mat-chip
                      *ngFor="let apellido of listaApellidos; let i = index"
                      [selected]="apellido.state"
                      selectable
                      multiple
                      [removable]="removable"
                      (removed)="removeApellido(i)"
                      [value]="apellido"
                      [multiple]="true"
                      (click)="
                        apellido.state = !apellido.state;
                        addApellido(apellido, i)
                      "
                    >
                      {{ apellido.apellido }}
                      <mat-icon matChipRemove *ngIf="removable">X</mat-icon>
                    </mat-chip>
                  </mat-chip-list>
                </div>
              </mat-chip-list>
            </div>
            <div class="mb-3 col-sm-12 col-lg-4">
              <label for="apellido" class="form-label"
                >DNI/NIE Trabajador Afectado</label
              >
              <mat-chip-list #chipList aria-label="Selección DNIs">
                <div class="col-sm-12 col-lg-12 p-0">
                  <input
                    type="text"
                    autocomplete="off"
                    class="form-control apellido"
                    id="dni"
                    name="dni"
                    formControlName="dni"
                    placeholder="Introduzca DNIs a buscar"
                    (input)="buscarDni($event)"
                    maxlength="9"
                  />
                  <small
                    class="form-text text-danger"
                    *ngIf="
                      listaDNIs.length === 0 &&
                      !esDniPrimeraBusqueda &&
                      this.formulario.get('variosDNIs')?.value.length === 0
                    "
                    >* No se han encontrado resultados</small
                  >
                </div>
                <div class="col-sm-12 col-lg-12 mb-4">
                  <mat-chip
                    class="selected-chip"
                    *ngFor="
                      let dniInput of this.formulario.get('variosDNIs')?.value;
                      let i = index
                    "
                    [removable]="removable"
                    (removed)="removeDniForm(dniInput, i)"
                  >
                    {{ dniInput }}
                    <mat-icon matChipRemove *ngIf="removable">X</mat-icon>
                  </mat-chip>
                </div>
                <div class="col-sm-12 col-lg-12">
                  <mat-chip-list>
                    <mat-chip
                      *ngFor="let dni of listaDNIs; let i = index"
                      [selected]="dni.state"
                      selectable
                      multiple
                      [removable]="removable"
                      (removed)="removeDNI(i)"
                      [value]="dni"
                      [multiple]="true"
                      (click)="dni.state = !dni.state; addDNI(dni, i)"
                    >
                      {{ dni.dni }}
                      <mat-icon matChipRemove *ngIf="removable">X</mat-icon>
                    </mat-chip>
                  </mat-chip-list>
                </div>
              </mat-chip-list>
            </div>
          </div>

          <div class="row">
            <div class="mb-3 col-sm-12 col-lg-4">
              <label for="cliente" class="form-label">Cliente / Proyecto</label>
              <input
                type="name"
                placeholder="Cliente"
                formControlName="cliente"
                class="form-control"
                id="cliente"
                trim="blur"
                maxlength="100"
              />
            </div>

            <div class="mb-3 col-sm-12 col-lg-4">
              <label for="denominacion" class="form-label"
                >Denominación del servicio</label
              >
              <input
                type="name"
                placeholder="Denominación"
                formControlName="denominacion"
                class="form-control"
                id="denominacion"
                trim="blur"
                maxlength="100"
              />
            </div>
          </div>

          <div class="row">
            <div class="mb-3 col-sm-12 col-lg-4">
              <label for="zonas" class="form-label">Zonas</label>
              <p-multiSelect
                id="zonas"
                filterPlaceHolder="Buscar"
                [defaultLabel]="'Seleccione zonas'"
                [virtualScroll]="true"
                [options]="listaZonas"
                optionLabel="descripcion"
                [optionValue]="'id'"
                formControlName="zonas"
                styleClass="form-control p-0"
              ></p-multiSelect>
            </div>
            <div class="mb-3 col-sm-12 col-lg-4">
              <label for="ambitos" class="form-label">Ámbitos</label>
              <p-multiSelect
                id="ambitos"
                filterPlaceHolder="Buscar"
                [defaultLabel]="'Seleccione ámbitos'"
                [virtualScroll]="true"
                [options]="listaAmbitos"
                optionLabel="descripcion"
                [optionValue]="'id'"
                formControlName="ambitos"
                styleClass="form-control p-0"
              ></p-multiSelect>
            </div>
          </div>
          <div class="row">
            <div class="mb-3 col-sm-12 col-lg-6">
              <label for="fechaAltaInicio" class="form-label"
                >Fecha alta inicio</label
              >
              <input
                class="form-control"
                type="date"
                value=""
                id="fechaAltaInicio"
                formControlName="fechaAltaInicio"
                trim="blur"
              />
            </div>
            <div class="mb-3 col-sm-12 col-lg-6">
              <label for="fechaAltaFin" class="form-label"
                >Fecha alta fin</label
              >
              <input
                class="form-control"
                type="date"
                value=""
                id="fechaAltaFin"
                formControlName="fechaAltaFin"
                trim="blur"
              />
              <small
                class="form-text text-danger"
                *ngIf="
                  formulario.get('fechaAltaFin')!.touched &&
                  validarFechas(
                    formulario.get('fechaAltaInicio')?.value,
                    formulario.get('fechaAltaFin')?.value
                  )
                "
                >* La fecha alta fin debe ser mayor que la fecha de
                inicio</small
              >
            </div>
          </div>
          <div class="row">
            <div class="mb-3 col-sm-12 col-lg-6">
              <label for="fechaCierreInicio" class="form-label"
                >Fecha cierre inicio</label
              >
              <input
                class="form-control"
                type="date"
                value=""
                id="fechaCierreInicio"
                formControlName="fechaCierreInicio"
                trim="blur"
              />
            </div>
            <div class="mb-3 col-sm-12 col-lg-6">
              <label for="fechaCierreFin" class="form-label"
                >Fecha cierre fin</label
              >
              <input
                class="form-control"
                type="date"
                value=""
                id="fechaCierreFin"
                formControlName="fechaCierreFin"
                trim="blur"
              />
              <small
                class="form-text text-danger"
                *ngIf="
                  formulario.get('fechaCierreFin')!.touched &&
                  validarFechas(
                    formulario.get('fechaCierreInicio')?.value,
                    formulario.get('fechaCierreFin')?.value
                  )
                "
                >* La fecha cierre fin debe ser mayor que la fecha de
                inicio</small
              >
            </div>
          </div>
        </ng-container>
        <button
          class="btn btn-outline-info customButton fa fa-filter filtros mb-2"
          (click)="mostrarAvanzado()"
        >
          {{ textoBoton }}
        </button>
      </div>
      <div class="mt-2 mb-3 d-flex">
        <button
          class="btn limpiarBoton customButton fa fa-eraser mr-3"
          (click)="reset()"
        >
          Limpiar
        </button>
        <button
          type="submit"
          class="btn btn-outline-primary customButton fa fa-search buscar"
          (click)="buscarFiltro()"
        >
          Buscar
        </button>
      </div>
    </form>
  </fieldset>

  <fieldset class="my-4">
    <legend>
      Buscador rapido
      <i
        class="fa fa-info-circle"
        style="color: #002664"
        pTooltip="Busca la palabra indicada en la descripción, los comentarios, las aclaraciones, los cierres, las reaperturas, las autorizaciones y las informaciones de las peticiones"
        aria-hidden="true"
      ></i>
    </legend>
    <form [formGroup]="formulario" *ngIf="formulario">
      <div class="row">
        <div class="mb-3 col-sm-12 col-lg-8">
          <input
            type="search"
            maxlength="250"
            autocomplete="off"
            class="form-control"
            id="busqueda"
            name="busqueda"
            formControlName="busqueda"
            placeholder="Buscar por ..."
            aria-label="busqueda"
            aria-describedby="search-addon"
          />
        </div>
        <div class="mb-3 col-sm-12 col-lg-4">
          <button
            type="submit"
            class="btn btn-outline-primary btn-lg customButton fa fa-search buscar"
            (click)="buscarFiltro()"
          >
            Buscar
          </button>
        </div>
      </div>
    </form>
  </fieldset>
</div>

<app-mensaje #mensaje (customAceptar)="onConfirmModal($event, '')">
</app-mensaje>
<ngx-spinner>
</ngx-spinner>
<app-crear-peticion [activeIndex]="activeIndex">
</app-crear-peticion>
<div class="container">
    <form [formGroup]="formulario" *ngIf="formulario">
        <div class="col-12">
            <div *ngIf="datosEfectividad">
                <h3>Datos Efectividad</h3>
                <div class="descripcion">
                    <div *ngIf="fecha">
                        <div class="mb-3 col-sm-12 col-lg-4">
                            <label for="fechaEfectividad" class="form-label">{{fechaTexto}} <span style="color:red;" *ngIf="fechaEfectividadObligatorio">*</span></label>
                            <br>
                            <p-calendar [yearNavigator]="true" yearRange="1900:2060" [firstDayOfWeek]="1" [showIcon]="true" [showButtonBar]="true" dateFormat="dd-mm-yy" formControlName="fechaEfectividad" id="fechaEfectividad" [required]="true"></p-calendar>
                            <small class="form-text text-danger" *ngIf="!formulario.get('fechaEfectividad')!.valid && formulario.get('fechaEfectividad')!.touched && formulario.controls.fechaEfectividad.errors?.required">* El campo {{fechaTexto}} es obligatorio</small>
                        </div>
                    </div>

                    <div *ngIf="rango">
                        <div class="row">
                            <div class="mb-3 col-sm-12 col-lg-4">
                                <label for="fechaDesde" class="form-label">Fecha Desde <span style="color:red;" *ngIf="fechaDesdeObligatorio">*</span></label>
                                <br>
                                <p-calendar [yearNavigator]="true" yearRange="1900:2060" [firstDayOfWeek]="1" [showIcon]="true" [showButtonBar]="true" dateFormat="dd-mm-yy" formControlName="fechaDesde" id="fechaDesde" [required]="true"></p-calendar>
                                <small class="form-text text-danger" *ngIf="formulario.get('fechaDesde')!.touched && validarFechas(formulario.get('fechaDesde')?.value,formulario.get('fechaHasta')?.value) ">* La fecha Desde debe ser menor que la fecha Hasta</small>
                                <small class="form-text text-danger" *ngIf="!formulario.get('fechaDesde')!.valid && formulario.get('fechaDesde')!.touched && formulario.controls.fechaDesde.errors?.required">* El campo Fecha Desde es obligatorio</small>
                            </div>
                            <div class="mb-3 col-sm-12 col-lg-4">
                                <label for="fechaHasta" class="form-label">Fecha Hasta <span style="color:red;" *ngIf="fechaHastaObligatorio">*</span></label>
                                <br>
                                <p-calendar [yearNavigator]="true" yearRange="1900:2060" [firstDayOfWeek]="1" [showIcon]="true" [showButtonBar]="true" dateFormat="dd-mm-yy" formControlName="fechaHasta" id="fechaHasta" [required]="fechaHastaObligatorio"></p-calendar>
                                <small class="form-text text-danger" *ngIf="formulario.get('fechaHasta')!.touched && validarFechas(formulario.get('fechaDesde')?.value,formulario.get('fechaHasta')?.value) ">* La fecha Hasta debe ser mayor que la fecha Desde</small>
                                <small class="form-text text-danger" *ngIf="formulario.get('fechaHasta')!.touched && formulario.controls.fechaHasta.errors?.required">* La fecha Hasta es Obligatoria</small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="espacio"></div>
        <div class="col-12">
            <h3>Descripción de la solicitud o consulta <span class="text-danger">*</span></h3>
            <div class="descripcion">
                <div class="col-lg-12">
                    <button type="button" class="btn btn-outline-secondary fa fa-eraser limpiarBoton" style="float: right;" (click)="limpiar()" [disabled]="!peticionService.esModificable()"> Limpiar</button>
                    <div class="form-group">
                        <textarea id="observaciones" class="form-control" formControlName="observaciones" rows="5" trim="blur" maxlength="3000" (keypress)="onKeyPress($event)" (keyup)="onKeyUp($event)" required (blur)="controlForm()"></textarea>
                        <span>Máximo: 3000. Total: {{contador}} </span>
                        <small class="form-text text-danger" *ngIf="!formulario.get('observaciones')!.valid && formulario.get('observaciones')!.touched && formulario.controls.observaciones.errors?.required">* El campo observaciones es obligatorio</small>
                    </div>
                </div>
            </div>
        </div>
    </form>

    <div class="derecha botones">
        <button id="guardar" type="submit" *ngIf="modificable" class="btn btn-outline-info" (click)="guardarObservaciones()" [disabled]="deshabilitarBotones() && peticionService.esModificable()">Borrador</button>
        <button id="siguiente" type="submit" class="btn customButton" (click)="nextPage()" [disabled]="deshabilitarBotones() && peticionService.esModificable()">Siguiente</button>
    </div>
    <div class="botones">
        <button id="anterior" type="submit" class="btn customButton" (click)="previousPage()">Anterior</button>
    </div>
</div>